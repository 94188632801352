import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { offerRoutes } from '../../offerRoutes';

interface InfoModalProps {
  offerId: string;
  onRequestDismiss: () => void;
}

export default function InfoModal({ onRequestDismiss, offerId }: InfoModalProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleConfirm() {
    navigate(offerRoutes.edit.generatePath({ offerId }));
  }

  return (
    <AlertDialog
      open
      title={t('infoModal.title')}
      description={t('infoModal.description')}
      cancelButtonText={t('infoModal.dismissButton')}
      confirmButtonText={t('infoModal.confirmButton')}
      confirmButtonEndIcon={<Icon name="settings" />}
      onRequestClose={onRequestDismiss}
      onCancel={onRequestDismiss}
      onConfirm={handleConfirm}
    />
  );
}
