import { Button, Icon, Menu, MenuItem } from '@hs-baumappe/legacy-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNavigator from '../../../../../../../hooks/useNavigator';

interface AcceptanceReportFormImageAttachmentMenuProps {
  imagesAdded: boolean;
  onUploadImageButtonClick: () => void;
  onSelectImageButtonClick: () => void;
  loading: boolean;
}

export default function AcceptanceReportFormImageAttachmentMenu({
  imagesAdded,
  onUploadImageButtonClick,
  onSelectImageButtonClick,
  loading,
}: AcceptanceReportFormImageAttachmentMenuProps): JSX.Element {
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const { tabletDevice, mobileDevice } = useNavigator();

  function handleMenuItemClick(handler: () => void) {
    setMenuOpen(false);

    handler();
  }

  return (
    <Menu
      open={menuOpen}
      onRequestClose={() => setMenuOpen(false)}
      placement="bottom-fit"
      renderTrigger={(triggerProps) => (
        <Button
          {...triggerProps}
          onClick={() => setMenuOpen(!menuOpen)}
          color={menuOpen ? 'primary' : 'default'}
          endIcon={<Icon name={imagesAdded ? 'add' : 'add-a-photo'} />}
          type="button"
          fullWidth
          loading={loading}
        >
          {t(
            imagesAdded
              ? 'acceptanceReport.imageAttachments.addAnotherImageButton'
              : 'acceptanceReport.imageAttachments.addMultipleImagesButton',
          )}
        </Button>
      )}
    >
      <MenuItem
        leftSection={<Icon name="smartphone" color="primary" size={24} />}
        onClick={() => handleMenuItemClick(onUploadImageButtonClick)}
        disabled={!(tabletDevice || mobileDevice)}
      >
        {t('acceptanceReport.imageAttachments.menu.uploadImageButton')}
      </MenuItem>

      <MenuItem
        leftSection={<Icon name="photo" color="primary" size={24} />}
        onClick={() => handleMenuItemClick(onSelectImageButtonClick)}
      >
        {t('acceptanceReport.imageAttachments.menu.selectImageButton')}
      </MenuItem>
    </Menu>
  );
}
