import {
  Alert,
  Box,
  Container,
  Dialog,
  FlexGrid,
  FlexGridColumn,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { lazy, Suspense, useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import { ProjectLocationState } from '../Project.route.types';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import GetCreateSivProjectDetailQuery from './graphql/GetCreateSivProjectDetail.query';
import { projectRoutes } from '../../routes';
import ErrorBoundary from '../../../ErrorBoundary';

const CreateSivBase = lazy(
  // eslint-disable-next-line import/no-unresolved
  () => import('sivWeb/CreateSiv'),
);

export default function CreateSiv(): JSX.Element {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const location = useLocation<ProjectLocationState>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const { data, loading, error } = useQuery(GetCreateSivProjectDetailQuery, {
    variables: {
      projectId,
    },
  });

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(projectRoutes.detail.generatePath({ projectId }));
  }, [location.state, navigate, projectId]);

  const project = data?.project;
  if (!loading && project?.draft) {
    return <Navigate to={projectRoutes.detail.generatePath({ projectId })} replace />;
  }

  return (
    <Dialog
      animationType="slide-up"
      onClose={handleDialogClose}
      onRequestClose={() => setOpen(false)}
      open={open}
      size="fullscreen"
    >
      <Container stylex={{ paddingEnds: 'medium' }}>
        <FlexGrid>
          <FlexGridColumn xs>
            <Text color="muted" component="div">
              {project?.description}
            </Text>
            <Text variant="title-small">{t('projectDetail.createSiv')}</Text>
          </FlexGridColumn>
          <FlexGridColumn xs="auto">
            <IconButton icon={<Icon name="close" />} onClick={() => setOpen(false)} type="button" />
          </FlexGridColumn>
        </FlexGrid>

        <Box stylex={{ marginTop: 'medium' }}>
          {loading && !data && <LayoutLoading />}
          {error && <ErrorRenderer apolloError={error} />}
          {data && (
            <ErrorBoundary fallback={<Alert color="error">{t('sivMFLoadError')}</Alert>}>
              <Suspense fallback={<LayoutLoading />}>
                <CreateSivBase projectId={projectId} />
              </Suspense>
            </ErrorBoundary>
          )}
        </Box>
      </Container>
    </Dialog>
  );
}
