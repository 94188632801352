import { gql } from '@apollo/client';

export const HsProjects = gql`
  fragment HsProject on HsProject {
    id
    projectNumber
    name
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const SEARCH_HS_PROJECTS = gql`
  query SearchHsProjects($term: String!) {
    searchHsProjects(term: $term) {
      projects {
        ...HsProject
      }

      continuationToken
    }
  }

  ${HsProjects}
`;
