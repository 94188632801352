import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from '@hs-baumappe/legacy-ui';
import { TextProps } from '@hs-baumappe/legacy-ui/dist/components/Text';
import { differenceInCalendarDays } from 'date-fns';
import { gql } from '@apollo/client';
import cx from 'classnames';

import { QsstDetailsQsstForExternalFragment } from './__generated__/Details';

import styles from './details.module.scss';

export const detailsFragments = {
  qsstForExternal: gql`
    fragment QsstDetailsQsstForExternalFragment on QsstForExternal {
      hsContactInfo {
        firstName
        lastName
        email
        phone
      }

      hsProjectNumber
      projectName
      projectAdditionalName
      accessDueDate
    }
  `,
};

interface DetailsProps {
  qsstForExternal: QsstDetailsQsstForExternalFragment;
}

const Details: FC<DetailsProps> = ({ qsstForExternal }) => {
  const { t } = useTranslation();

  const { hsContactInfo, accessDueDate } = qsstForExternal;

  const textVariant: TextProps['variant'] = 'body-small';

  const firstBoxClassName = cx(['col col--sm-4', styles.separator]);

  const remainingDays = differenceInCalendarDays(new Date(accessDueDate), new Date());

  return (
    <div className="row u-margin-top-large">
      {/* contact info for customer */}
      <Box className={firstBoxClassName}>
        <Text variant={textVariant} color="primary">
          {t('qsstSign.form.details.hsContact.title')}
        </Text>

        <br />

        <Text variant={textVariant}>{`${hsContactInfo.firstName} ${hsContactInfo.lastName}`}</Text>

        {hsContactInfo.email && (
          <>
            <br />
            <Text variant={textVariant}>
              {t('qsstSign.form.details.hsContact.mailLabel')} {hsContactInfo.email}
            </Text>
          </>
        )}

        {hsContactInfo.phone && (
          <>
            <br />
            <Text variant={textVariant}>
              {t('qsstSign.form.details.hsContact.phoneLabel')} {hsContactInfo.phone}
            </Text>
          </>
        )}
      </Box>

      {/* project details */}
      <Box className="col col--sm-4">
        <Text variant={textVariant} color="primary">
          {t('qsstSign.form.details.projectDetails.title')}
        </Text>

        <br />

        <Text variant={textVariant}>
          {`${qsstForExternal.hsProjectNumber} ${qsstForExternal.projectName}`}
        </Text>

        <br />

        <Text variant={textVariant}>{qsstForExternal.projectAdditionalName || ''}</Text>
      </Box>

      {/* remaining access days */}
      {accessDueDate && (
        <Box className="col col--sm-4" stylex={{ textAlign: 'right' }}>
          <Text variant="title-small" color="primary">
            <Trans
              i18nKey="qsstSign.form.details.remainingDays.title"
              values={{ days: remainingDays }}
              components={{ daysComponent: <Text variant="headline-medium" color="primary" /> }}
            />
          </Text>

          <br />

          <Text variant="label-large" color="muted">
            {t('qsstSign.form.details.remainingDays.description')}
          </Text>
        </Box>
      )}
    </div>
  );
};

export { Details };
