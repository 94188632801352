import { FC, ReactNode } from 'react';
import { connect, getIn, FieldProps, FormikContextType } from 'formik';

import FieldLayoutBase from '../../form/FieldLayout';
import { ChildrenProps, Props as FieldLayoutProps } from '../../form/FieldLayout/FieldLayout';

type InputProps = ChildrenProps & FieldProps['field'];

interface OutterProps
  extends Pick<FieldLayoutProps, 'className' | 'label' | 'labelHtmlFor' | 'feedback' | 'status'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (props: InputProps, formik: FormikContextType<any>) => ReactNode;
}

interface Props extends OutterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikContextType<any>;
}

const FieldLayout: FC<Props> = ({ children, name, formik, feedback, ...otherProps }) => {
  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const onChange = formik.handleChange(name);
  const onBlur = formik.handleBlur(name);

  const validStatus = error ? 'invalid' : 'valid';
  const fieldStatus = touched ? validStatus : 'idle';

  return (
    <FieldLayoutBase
      status={fieldStatus}
      feedback={{ invalid: error, ...feedback }}
      {...otherProps}
    >
      {({ status }) => {
        return children({ name, status, value, onChange, onBlur }, formik);
      }}
    </FieldLayoutBase>
  );
};

export default connect<OutterProps>(FieldLayout);
