import { useEffect, useState } from 'react';
import { FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';
import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import QsstDetailHeader from './components/QsstDetailHeader';
import { QsstDetailAlerts } from './components/QsstDetailAlerts/QsstDetailAlerts';
import PDFViewer from '../../../components/PDFViewer';
import { QsstDetailSidebarTop } from './components/QsstDetailSidebarTop/QsstDetailSidebarTop';
import QsstDetailSidebarBottom from './components/QsstDetailSidebarBottom';
import { QsstLogs } from './components/QsstLogs/QsstLogs';
import { Qsst } from '../graphql/__generated__/Qsst.fragment';
import { QsstProject } from '../graphql/__generated__/QsstProject.fragment';
import { QsstScopes } from '../Qsst.types';

interface QsstDetailProps {
  qsst: Qsst;
  project: QsstProject;
  scopes: QsstScopes;
}

export default function QsstDetail({ qsst, project, scopes }: QsstDetailProps): JSX.Element {
  const [showUploadedPdf, setShowUploadedPdf] = useState(false);

  const { parentFolders } = useDriveDocumentParentFolders(qsst.id);

  useEffect(() => {
    setShowUploadedPdf(!!qsst.manuallySignedPdfInfo);
  }, [qsst.manuallySignedPdfInfo]);

  const versionedUrl =
    (showUploadedPdf ? qsst.manuallySignedPdfInfo?.versionedUrl : qsst.pdfInfo.versionedUrl) || '';

  const downloadPermitted = scopes.downloadQsstDocument.isPermitted;

  return (
    <PageLayoutWithStickySidebar
      header={<QsstDetailHeader qsst={qsst} project={project} parentFolders={parentFolders} />}
      sidebarTop={
        <QsstDetailSidebarTop
          qsst={qsst}
          scopes={scopes}
          projectId={project.id}
          versionedUrl={versionedUrl}
        />
      }
      sidebarBottom={
        <QsstDetailSidebarBottom
          qsst={qsst}
          scopes={scopes}
          projectId={project.id}
          showUploadedPdf={showUploadedPdf}
          setShowUploadedPdf={setShowUploadedPdf}
        />
      }
      footer={
        <FlexGrid>
          <FlexGridColumn md={8}>
            <QsstLogs qsstId={qsst.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <QsstDetailAlerts isManuallySigned={!!qsst.manuallySignedPdfInfo} />

        {versionedUrl && (
          <PDFViewer
            document={versionedUrl}
            downloadPermitted={downloadPermitted}
            key={versionedUrl}
          />
        )}
      </Stack>
    </PageLayoutWithStickySidebar>
  );
}

export { QsstDetail };
