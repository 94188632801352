import { lazy, Suspense } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { LaunchDarklyFlagSet } from '../../../../launchdarkly/launchDarklyFlagSet';
import { ProjectsTableProps } from '../ProjectsTable/ProjectsTable';
import { ProjectsOldTableProps } from '../ProjectsOldTable/ProjectsOldTable';

type ProjectsTableDeciderProps = ProjectsTableProps | ProjectsOldTableProps;

const ProjectsTable = lazy(() => import('../ProjectsTable/ProjectsTable'));
const ProjectsOldTable = lazy(() => import('../ProjectsOldTable/ProjectsOldTable'));

export default function ProjectsTableDecider(props: ProjectsTableDeciderProps): JSX.Element {
  const { sivKeyFiguresInProjectList } = useFlags<LaunchDarklyFlagSet>();

  return (
    <Suspense fallback={<LayoutLoading />}>
      {sivKeyFiguresInProjectList ? <ProjectsTable {...props} /> : <ProjectsOldTable {...props} />}
    </Suspense>
  );
}
