import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import FolderForm from '../../containers/FolderForm';
import useOfferFolderCreateForFreeform, {
  OfferFolderCreateForFreeformProps,
} from './useOfferFolderCreateForFreeform';
import { BaseValues } from '../../containers/FolderForm/values';
import ErrorState from '../../../../../containers/ErrorState';
import { OfferMode } from '../../../../../globalTypes';

interface Props {
  onSuccess: OfferFolderCreateForFreeformProps['onSuccess'];
  parentId?: OfferFolderCreateForFreeformProps['parentId'];
  offerId: OfferFolderCreateForFreeformProps['offerId'];
  initialValues?: Partial<BaseValues>;
  offerMode: OfferMode;
  onClickAliasRightIcon: () => void;
}

const OfferFolderCreateForFreeform: FC<Props> = ({
  onSuccess,
  parentId,
  offerId,
  initialValues,
  offerMode,
  onClickAliasRightIcon,
}) => {
  const { t } = useTranslation();
  const { createFolder, error } = useOfferFolderCreateForFreeform({
    onSuccess,
    parentId,
    offerId,
    offerMode,
  });

  return (
    <Fragment>
      {error && <ErrorState error={error} />}
      <FolderForm
        categories={[]}
        offerModeSystemNumber={offerMode === OfferMode.SYSTEM_NUMBER}
        onClickAliasRightIcon={onClickAliasRightIcon}
        parentId={parentId}
        onSubmit={createFolder}
        buttonText={t(
          parentId
            ? 'createOfferFolderForFreedom.forSubgroup.submitButton'
            : 'createOfferFolderForFreedom.forGroup.submitButton',
        )}
        buttonIcon="add"
        initialValues={initialValues}
      />
    </Fragment>
  );
};

export default OfferFolderCreateForFreeform;
