import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import DriveDocumentNoteContainer from '../../../../../components/DriveDocumentNoteContainer';
import { ConcernScopes } from '../../../Concern.types';
import { Concern } from '../../../graphql/__generated__/Concern.fragment';

interface ConcernDetailSidebarTopProps {
  concern: Concern;
  scopes: ConcernScopes;
  onEditButtonClick: () => void;
  onSendEmailButtonClick: () => void;
  onDownloadButtonClick: () => void;
}

export default function ConcernDetailSidebarTop({
  concern,
  scopes,
  onEditButtonClick,
  onSendEmailButtonClick,
  onDownloadButtonClick,
}: ConcernDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateConcernDocument.isPermitted;
  const sendPermitted = scopes.sendConcernDocumentAttachment.isPermitted;
  const downloadPermitted = scopes.downloadConcernDocument.isPermitted;

  return (
    <Stack gap="small-3x">
      <DriveDocumentNoteContainer id={concern.id} name={concern.name}>
        {updatePermitted && (
          <Button endIcon={<Icon name="create" />} onClick={onEditButtonClick}>
            {t('concern.editButton')}
          </Button>
        )}

        {sendPermitted && (
          <Button endIcon={<Icon name="email" />} onClick={onSendEmailButtonClick}>
            {t('concern.sendPDFButton')}
          </Button>
        )}

        {downloadPermitted && (
          <Button endIcon={<Icon name="cloud-download" />} onClick={onDownloadButtonClick}>
            {t('concern.downloadPDFButton')}
          </Button>
        )}
      </DriveDocumentNoteContainer>
    </Stack>
  );
}
