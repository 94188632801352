import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DuplicatingQsstDetail,
  DuplicatingQsstDetailVariables,
} from './__generated__/DuplicatingQsstDetail.query';

const DuplicatingQsstDetailQuery: TypedDocumentNode<
  DuplicatingQsstDetail,
  DuplicatingQsstDetailVariables
> = gql`
  query DuplicatingQsstDetail($qsstId: ID!) {
    qsst(id: $qsstId) {
      id
      name
      clientName
      contractDate

      onsideEmployee {
        fullName
      }
    }
  }
`;

export default DuplicatingQsstDetailQuery;
