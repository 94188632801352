import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ConcernEditDetail,
  ConcernEditDetailVariables,
} from './__generated__/ConcernEditDetail.query';

const ConcernEditDetailQuery: TypedDocumentNode<ConcernEditDetail, ConcernEditDetailVariables> =
  gql`
    query ConcernEditDetail($documentId: ID!, $thumbnailWidth: Int!, $thumbnailHeight: Int!) {
      concern(id: $documentId) {
        id

        date
        endDate
        professions
        clientName

        quality {
          name
          description
        }

        execution

        contractor {
          name
          description
        }

        images {
          id

          label
          url
          thumbnail(width: $thumbnailWidth, height: $thumbnailHeight)
        }

        project {
          id
          hsProjectNumber

          client {
            name
          }
        }
      }
    }
  `;

export default ConcernEditDetailQuery;
