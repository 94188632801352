import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import LogEmails from '../LogEmails';
import { ConstructionNoteLog as ConstructionNoteLogType } from '../../graphql/__generated__/ConstructionNoteLog.fragment';
import { ConstructionNoteType } from '../../../../globalTypes';
import { assertUnknownLog, getActor } from '../Log.utils';

interface ConstructionNoteLogProps extends ComponentProps<typeof Text> {
  log: ConstructionNoteLogType;
}

export default function ConstructionNoteLog({
  log,
  ...props
}: ConstructionNoteLogProps): JSX.Element {
  const { t } = useTranslation();

  switch (log.__typename) {
    case 'ConstructionNoteArchivedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.constructionNoteArchived"
            values={{ constructionNoteName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ConstructionNoteCreatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.constructionNoteCreated.text"
            values={{
              constructionNoteName: log.name,
              constructionNoteTypeAdjective:
                !log.constructionNoteType ||
                log.constructionNoteType === ConstructionNoteType.FREE_DRAWING
                  ? ''
                  : t('logs.constructionNoteCreated.adjectives.file'),
            }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ConstructionNoteDeletedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.constructionNoteDeleted"
            values={{ constructionNoteName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ConstructionNoteEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.constructionNoteEmailSent"
            values={{ constructionNoteName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
