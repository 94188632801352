export default async function urlToBase64(url: string): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
        return;
      }

      reject(
        new Error(`Unhandled result could not be resolved. Received type: ${typeof reader.result}`),
      );
    };

    reader.readAsDataURL(blob);
  });
}
