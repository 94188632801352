import { ApolloError, useQuery } from '@apollo/client';
import QsstEditDetailQuery from '../../graphql/QsstEditDetail.query';
import { QsstEditDetail } from '../../graphql/__generated__/QsstEditDetail.query';

interface UseQsstEditDetailResponse {
  qsst?: QsstEditDetail['qsst'];
  project?: NonNullable<QsstEditDetail['qsst']>['project'];
  loading: boolean;
  error?: ApolloError;
}

export default function useQsstEditDetail(qsstId: string): UseQsstEditDetailResponse {
  const { data, loading, error } = useQuery(QsstEditDetailQuery, {
    variables: {
      qsstId,
    },
    fetchPolicy: 'network-only',
  });

  const qsst = data?.qsst;
  const project = qsst?.project;

  return {
    qsst,
    project,
    loading,
    error,
  };
}
