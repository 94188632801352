import { PropsWithChildren, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import useCanvasData from '../../hooks/useCanvasData';
import NoteEditorLayout from '../../../../components/note-editor/NoteEditorLayout';
import Canvas from '../../Canvas';
import NoteEditorToolbar from '../../../../components/note-editor/NoteEditorToolbar';
import ArchiveAction from '../../actions/ArchiveAction';
import DownloadAction from '../../actions/DownloadAction';
import MailAction from '../../actions/MailAction';
import AssignAction from '../../actions/AssignAction';
import RemoveFreeDrawingConstructionNoteAction from '../../actions/RemoveFreeDrawingConstructionNoteAction';
import ZoomActions from '../../actions/ZoomActions';
import EditAction from '../../actions/EditAction';
import { GetConstructionNoteDetail } from '../../graphql/__generated__/GetConstructionNoteDetail.query';
import { LaunchDarklyFlagSet } from '../../../../launchdarkly/launchDarklyFlagSet';

type ConstructionNote = GetConstructionNoteDetail['constructionNote'];

interface ConstructionNoteDetailFreeDrawingNoteEditorProps {
  constructionNote: ConstructionNote;
  projectId: string;
}

export default function ConstructionNoteDetailFreeDrawingNoteEditor({
  constructionNote,
  projectId,
}: PropsWithChildren<ConstructionNoteDetailFreeDrawingNoteEditorProps>): JSX.Element {
  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();
  const { viewer } = useViewer();
  const { t } = useTranslation();
  const [fetchData, { loading, data, error }] = useCanvasData();

  const canvasAreaRef = useRef<HTMLDivElement | null>(null);
  const { status, name, viewerCanRemove, viewerCanArchive, viewerCanAssign } = constructionNote;

  useEffect(() => {
    if (constructionNote && constructionNote && constructionNote.path) {
      fetchData(constructionNote.path);
    }
  }, [constructionNote, fetchData]);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error || !data) {
    return <Alert color="error">{t('constructionNote.dataLoadError')}</Alert>;
  }

  const scopes = viewer?.scopes;
  const viewerHasUpdateAccess = !!scopes?.updateConstructionNoteDocument.isPermitted;
  const viewerHasRemoveAccess =
    !!scopes?.removeDocumentsSimple.isPermitted || !!scopes?.removeDocumentsAdvanced.isPermitted;
  const viewerHasDownloadAccess = !!scopes?.downloadConstructionNoteDocument.isPermitted;
  const viewerHasSendAccess = !!scopes?.sendConstructionNoteDocumentAttachment.isPermitted;

  return (
    <NoteEditorLayout
      className="u-height-100%"
      canvasAreaRef={canvasAreaRef}
      canvasArea={<Canvas readOnly initialState={data} canvasAreaRef={canvasAreaRef} />}
      firstToolbarArea={
        <NoteEditorToolbar type="tool" directionModifier="column@md-down">
          {viewerCanArchive && archiveEnabled && <ArchiveAction />}
          {viewerHasDownloadAccess && <DownloadAction name={name} />}
          {viewerHasSendAccess && <MailAction />}
          {viewerCanAssign && (
            <AssignAction constructionNoteId={constructionNote.id} projectId={projectId} />
          )}
          {viewerCanRemove && viewerHasRemoveAccess && (
            <RemoveFreeDrawingConstructionNoteAction
              constructionNote={constructionNote}
              projectId={projectId}
            />
          )}
        </NoteEditorToolbar>
      }
      secondToolbarArea={<ZoomActions />}
      thirdToolbarArea={
        viewerHasUpdateAccess && (
          <NoteEditorToolbar directionModifier="column">
            <EditAction status={status} name={name} />
          </NoteEditorToolbar>
        )
      }
    />
  );
}
