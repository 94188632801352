import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { ObstructionStatus } from '../../../../../globalTypes';
import { getStatusColorOnObstructionSendEmail } from './ObstructionSendEmailModalTitle.utils';

interface ObstructionSendEmailModalTitleProps {
  name: string;
  status: ObstructionStatus;
  projectDescription: string;
}

export default function ObstructionSendEmailModalTitle({
  projectDescription,
  name,
  status,
}: ObstructionSendEmailModalTitleProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body-medium" component="div" color="muted">
        {projectDescription}
      </Text>
      <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
        {t('obstructionSendEmail.title', { name })}
      </Text>
      <Text
        variant="title-small"
        component="div"
        color="muted"
        className={getStatusColorOnObstructionSendEmail(status)}
      >
        {t('obstructionSendEmail.status', { status: t(status) })}
      </Text>
    </>
  );
}
