import { CatalogueFragment } from './__generated__/graphql';
import { Catalog, CatalogCategory } from '../../OfferFolderOperationCreate/OperationSearch/types';
import TreeStorage from '../../../../../utils/TreeStorage';

type CatalogResponse = CatalogueFragment;
type CategoryResponse = CatalogueFragment['allCategories'][number];

const mapCategories =
  (getSubCategories: (id: string) => CategoryResponse[], partNoTree: string[] = []) =>
  (category: CategoryResponse): CatalogCategory => {
    const subCategories = getSubCategories(category.id);
    const newPartNoTree = [...partNoTree, category.partNo];
    const subCategoryMapper = mapCategories(getSubCategories, newPartNoTree);

    return {
      id: category.id,
      partNo: category.partNo,
      partNoTree: newPartNoTree,
      name: category.name || undefined,
      subCategories: subCategories.slice().map(subCategoryMapper),
      operations: [],
    };
  };

const buildCategories = (categories: CategoryResponse[]): CatalogCategory[] => {
  const categoryStorage = new TreeStorage({
    data: categories,
    connector: (category) => ({
      id: category.id,
      parentID: category.parent ? category.parent.id : undefined,
    }),
  });

  return categoryStorage.getRoots().map(mapCategories(categoryStorage.getConnections));
};

const catalogueMapper = (catalogs: CatalogResponse[]): Catalog[] => {
  return catalogs
    .map<Catalog>((catalog) => ({
      ...catalog,
      categories: buildCategories(catalog.allCategories),
    }))
    .filter((c) => c.categories.length);
};

export default catalogueMapper;
