import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import ConcernArchiveMutation from '../../graphql/ConcernArchive.mutation';
import ConcernDetailQuery from '../../../hooks/useConcern/graphql/ConcernDetail.query';
import { concernRoutes } from '../../../concernRoutes';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import { DriveDocumentArchiveInput } from '../../../../../globalTypes';
import {
  ConcernDetailAlert,
  ConcernDetailSearchParams,
} from '../../../ConcernDetail/ConcernDetail.utils';
import { ArchiveFormValues } from '../../../../../containers/ArchiveForm';

interface UseConcernArchiveResponse {
  archive: (values: ArchiveFormValues, formikHelpers: FormikHelpers<ArchiveFormValues>) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useConcernArchive(concernId: string): UseConcernArchiveResponse {
  const navigate = useNavigate();
  const { setSapCredentials } = useSapCredentials();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutation, { loading }] = useMutation(ConcernArchiveMutation, {
    refetchQueries: [ConcernDetailQuery],
  });

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    if (loading) {
      return;
    }

    setError(undefined);

    try {
      const archiveMutationResponse = await mutation({
        variables: {
          input: {
            id: concernId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
      });

      if (archiveMutationResponse.data) {
        setSapCredentials({ username: values.username, password: values.password });
      }

      const searchParams = new URLSearchParams({
        [ConcernDetailSearchParams.ALERT]: ConcernDetailAlert.ARCHIVED,
      });

      navigate(
        createPath({
          pathname: concernRoutes.detail.generatePath({ concernId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(error);
    }
  }

  return {
    archive,
    loading,
    error,
  };
}
