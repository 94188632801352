import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { fabric } from '@hs-baumappe/fabric';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';
import useSelectedTool from '../../hooks/useSelectedTool';
import useCanvasMover from '../../hooks/useCanvasMover';
import NoteEditorColorPicker from '../../../../components/note-editor/NoteEditorColorPicker';

const swatches = ['#BD2130', '#FFC500', '#0089BF', '#FFFFFF', '#28A745', '#000000'];

export default function PenTool(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(swatches[1]);
  const { canvas } = useCanvas();
  const { selectedTool, setSelectedTool } = useSelectedTool();
  const { endMoving } = useCanvasMover();

  const togglePenTool = useCallback(() => {
    if (!canvas) {
      return;
    }

    setSelectedTool('pen');
    setOpen(!open);
  }, [canvas, setSelectedTool, open]);

  const setFreeDrawingBrush = useCallback(() => {
    if (!canvas) {
      return;
    }

    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.color = selectedColor;
    canvas.freeDrawingBrush.width = 4;
    canvas.freeDrawingCursor = 'crosshair';
  }, [canvas, selectedColor]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    setFreeDrawingBrush();
  }, [canvas, selectedColor, setFreeDrawingBrush]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (selectedTool !== 'pen') {
      setOpen(false);
    }
  }, [canvas, selectedTool]);

  useEffect(() => {
    if (!canvas || selectedTool !== 'pen') {
      return;
    }

    setFreeDrawingBrush();
  }, [canvas, selectedTool, setFreeDrawingBrush]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.isDrawingMode = selectedTool === 'eraser' ? true : open;

    if (open) {
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      endMoving();
    }
  }, [open, canvas, endMoving, setSelectedTool, selectedTool]);

  useHotkeys(
    'p',
    () => {
      setOpen(!open);
    },
    [open],
  );

  const handleColorChange = (next: string) => {
    setSelectedColor(next);
  };

  return (
    <NoteEditorColorPicker
      open={open}
      renderTrigger={(triggerProps) => (
        <Button
          color={open ? 'warning' : undefined}
          contentClassName="u-display-flex u-align-items-center"
          onClick={togglePenTool}
          {...triggerProps}
        >
          <Icon name="gesture" size={24} />
        </Button>
      )}
      swatches={swatches}
      color={selectedColor}
      onChange={handleColorChange}
    />
  );
}
