import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_FOLDER_MUTATION = gql`
  mutation RemoveFolder($input: RemoveFolderInput!) {
    removeFolder(input: $input) {
      status
    }
  }
`;
