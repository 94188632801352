import { ApolloError, useQuery } from '@apollo/client';
import { CREATE_NEW_OVERTIME_GET_PROJECT_DETAIL } from './graphql';
import { CreateNewOvertimeGetProjectDetail } from './__generated__/graphql';

interface UseCreateOvertimeDetailResponse {
  project?: CreateNewOvertimeGetProjectDetail['project'];
  loading: boolean;
  error?: ApolloError;
}

export default function useCreateOvertimeDetail(
  projectId: string,
): UseCreateOvertimeDetailResponse {
  const { data, loading, error } = useQuery(CREATE_NEW_OVERTIME_GET_PROJECT_DETAIL, {
    variables: {
      id: projectId,
    },
  });

  const project = data?.project;

  return {
    project,
    loading,
    error,
  };
}
