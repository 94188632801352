import { RiskAssessmentLog } from '../../graphql/__generated__/RiskAssessmentLog.fragment';
import { Log } from '../Log.types';

export const RISK_ASSESSMENT_LOG_TYPES = [
  'RiskAssessmentArchivedLog',
  'RiskAssessmentCreatedLog',
  'RiskAssessmentDeletedLog',
  'RiskAssessmentEmailSentLog',
  'RiskAssessmentSignedByCreatorLog',
  'RiskAssessmentSignedByEmployeeLog',
];

export function isRiskAssessmentLog(log: Log): log is RiskAssessmentLog {
  return RISK_ASSESSMENT_LOG_TYPES.includes(log.__typename);
}
