import { gql } from '@apollo/client';
import ContactPersonFragment from '../../../ProjectDetail/components/ProjectClientInformation/graphql/ContactPerson.fragment';

export default gql`
  mutation ContactPersonAdd($input: ContactPersonAddInput!) {
    contactPersonAdd(input: $input) {
      ...ContactPerson

      project {
        id

        contactPeople {
          id
        }
      }
    }
  }

  ${ContactPersonFragment}
`;
