import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import LogEmails from '../LogEmails';
import { RiskAssessmentLog as RiskAssessmentLogType } from '../../graphql/__generated__/RiskAssessmentLog.fragment';
import { assertUnknownLog, getActor } from '../Log.utils';

interface RiskAssessmentLogProps extends ComponentProps<typeof Text> {
  log: RiskAssessmentLogType;
}

export default function RiskAssessmentLog({ log, ...props }: RiskAssessmentLogProps): JSX.Element {
  switch (log.__typename) {
    case 'RiskAssessmentArchivedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.riskAssessmentArchived"
            values={{ riskAssessmentName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'RiskAssessmentCreatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.riskAssessmentCreated"
            values={{ riskAssessmentName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'RiskAssessmentDeletedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.riskAssessmentDeleted"
            values={{ riskAssessmentName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'RiskAssessmentEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.riskAssessmentEmailSent"
            values={{ riskAssessmentName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'RiskAssessmentSignedByCreatorLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.riskAssessmentSignedByCreator"
            values={{ riskAssessmentName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'RiskAssessmentSignedByEmployeeLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.riskAssessmentSignedByEmployee"
            values={{
              employeeName: log.employeeName,
              riskAssessmentName: log.name,
            }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
