import { gql, TypedDocumentNode } from '@apollo/client';
import RiskAssessmentFragment from '../../graphql/RiskAssessment.fragment';
import {
  RiskAssessmentSign,
  RiskAssessmentSignVariables,
} from './__generated__/RiskAssessmentSign.mutation';

const RiskAssessmentSignMutation: TypedDocumentNode<
  RiskAssessmentSign,
  RiskAssessmentSignVariables
> = gql`
  mutation RiskAssessmentSign($input: UploadRiskAssessmentSignatureInput!, $file: Upload!) {
    uploadRiskAssessmentSignature(input: $input, file: $file) {
      id

      type
      imagePath

      riskAssessment {
        ...RiskAssessment
      }
    }
  }

  ${RiskAssessmentFragment}
`;

export default RiskAssessmentSignMutation;
