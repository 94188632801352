import { ConstructionNoteLog } from '../../graphql/__generated__/ConstructionNoteLog.fragment';
import { Log } from '../Log.types';

export const CONSTRUCTION_NOTE_LOG_TYPES = [
  'ConstructionNoteArchivedLog',
  'ConstructionNoteCreatedLog',
  'ConstructionNoteDeletedLog',
  'ConstructionNoteEmailSentLog',
];

export function isConstructionNoteLog(log: Log): log is ConstructionNoteLog {
  return CONSTRUCTION_NOTE_LOG_TYPES.includes(log.__typename);
}
