import { SchemaOf } from 'yup';
import yup, { InferObjectToYupShape } from '../../../../yup';
import * as yupPresets from '../../../../utils/validation/presets';

import { ClientMetaData, CreateNewOfferFormValues } from './values';
import { OfferType } from '../../../../globalTypes';
import { CreateNewOfferGetCatalogues } from '../__generated__/graphql';
import i18n from '../../../../i18n';

type Catalogues = CreateNewOfferGetCatalogues['catalogues'];

const clientMetaDataValidationSchema = yup.object().shape<InferObjectToYupShape<ClientMetaData>>({
  name: yup.string().max(250),
  address: yup.string().max(250),
  city: yup.string().max(250),
  zip: yup
    .string()
    .length(5, i18n.t('validation.postCode.invalid.text'))
    .matches(/^[0-9]+$/, i18n.t('validation.postCode.invalid.text')),
});

export const validationSchema = (catalogues?: Catalogues): SchemaOf<CreateNewOfferFormValues> =>
  yup.object().shape({
    name: yup.string().max(255).required(),
    type: yup.string().required(),
    catalogueId: yup.string().when('type', {
      is: OfferType.FRAMEWORK,
      then: yup.string().required(),
    }),
    catalogueFile: yupPresets.catalogueFile().when('type', {
      is: OfferType.FRAMEWORK && !!catalogues?.length,
      then: yup.string().required(),
    }),
    client: clientMetaDataValidationSchema,
    constructionSite: clientMetaDataValidationSchema,
  });

export const defaultValues = (
  initialValues?: Partial<CreateNewOfferFormValues>,
): CreateNewOfferFormValues => {
  return {
    name: '',
    type: '',
    catalogueId: '',
    ...initialValues,
  };
};
