import { FC, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import FolderForm from '../../containers/FolderForm';

import useOfferFolderEditForFramework, {
  FolderEditForFrameworkProps,
} from './useOfferFolderEditForFramework';
import { BaseValues } from '../../containers/FolderForm/values';
import {
  UpdateFrameworkFolderCategories,
  UpdateFrameworkFolderCategoriesVariables,
} from './__generated__/graphql';
import { UPDATE_FRAMEWORK_FOLDER_CATEGORIES } from './graphql';
import categoryMapper from '../../containers/categoryMapper';
import ErrorState from '../../../../../containers/ErrorState';
import useOffer from '../../../hooks/offer';

interface Props {
  onRemoveFolderButtonClick: () => void;
  folderRemoving?: boolean;
  onSuccess: FolderEditForFrameworkProps['onSuccess'];
  folderId: FolderEditForFrameworkProps['folderId'];
  offerId: FolderEditForFrameworkProps['offerId'];
  parentId?: string;
  initialValues?: Partial<BaseValues>;
}

const OfferFolderEditForFramework: FC<Props> = ({
  onRemoveFolderButtonClick,
  folderRemoving,
  onSuccess,
  folderId,
  offerId,
  parentId,
  initialValues,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<
    UpdateFrameworkFolderCategories,
    UpdateFrameworkFolderCategoriesVariables
  >(UPDATE_FRAMEWORK_FOLDER_CATEGORIES, {
    variables: {
      parentId,
      offerId,
    },
  });

  const { editFolder, error } = useOfferFolderEditForFramework({ folderId, offerId, onSuccess });
  const { getFolderById } = useOffer();
  const folder = getFolderById(folderId);

  if (!folder) {
    return null;
  }

  const { viewerCanUpdateFolder } = folder;

  if (!data || loading) {
    return <LayoutLoading />;
  }

  const { listAvailableCategoriesForFolderCreation } = data;

  const categories = categoryMapper(listAvailableCategoriesForFolderCreation);

  return (
    <Fragment>
      {error && <ErrorState error={error} />}
      <FolderForm
        folderRemoving={folderRemoving}
        framework
        categories={categories}
        parentId={parentId}
        onSubmit={editFolder}
        buttonText={t('editOfferFolderForFramework.submitButton')}
        buttonIcon="save"
        initialValues={initialValues}
        onRemoveFolderButtonClick={onRemoveFolderButtonClick}
        renderSubmitButton={viewerCanUpdateFolder}
      />
    </Fragment>
  );
};

export default OfferFolderEditForFramework;
