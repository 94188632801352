import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { ObstructionSaveInput } from '../../../../globalTypes';
import ObstructionSaveMutation from './graphql/ObstructionSaveMutation';
import { getErrorsFromServerError } from '../../../../apollo/errors';
import { ObstructionFormValues } from '../../forms/ObstructionForm/ObstructionForm.form';
import { createObstructionSaveInput } from './useObstructionSave.utils';
import obstructionRoutes from '../../obstructionRoutes';

interface UseObstructionSaveResponse {
  save: (
    formValues: ObstructionFormValues,
    formikHelpers: FormikHelpers<ObstructionFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useObstructionSave(obstructionId: string): UseObstructionSaveResponse {
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(ObstructionSaveMutation);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function save(
    formValues: ObstructionFormValues,
    formikHelpers: FormikHelpers<ObstructionFormValues>,
  ) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: createObstructionSaveInput({ formValues, obstructionId }),
        },
      });

      navigate(obstructionRoutes.detail.generatePath({ obstructionId: obstructionId }));
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ObstructionFormValues>,
        ObstructionSaveInput
      >(e, (errors) => errors);

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return { save, loading, error };
}
