import { forwardRef } from 'react';

export type ClickableDivElementProps = JSX.IntrinsicElements['div'];

export default forwardRef<HTMLDivElement, ClickableDivElementProps>(function ClickableDivElement(
  { children, onKeyPress, onClick, ...otherProps },
  ref,
) {
  const handleKeyPress: JSX.IntrinsicElements['div']['onKeyPress'] = (event) => {
    // trigger click event when the enter or space key pressed
    if (event.key === 'Enter' || event.key === ' ') {
      event.currentTarget.click();

      if (event.key === ' ') {
        event.preventDefault();
      }
    }

    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  return (
    <div
      ref={ref}
      {...(onClick
        ? {
            onClick,
            role: 'button',
            tabIndex: 0,
            onKeyPress: handleKeyPress,
          }
        : null)}
      {...otherProps}
    >
      {children}
    </div>
  );
});
