import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface AcceptanceReportHasNotSignedDialogProps {
  open: boolean;
  onRequestClose: () => void;
  onSignNowButtonClick: () => void;
  onSendWithoutSignatureButtonClick: () => void;
}

export default function AcceptanceReportHasNotSignedDialog({
  open,
  onRequestClose,
  onSignNowButtonClick,
  onSendWithoutSignatureButtonClick,
}: AcceptanceReportHasNotSignedDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      onRequestClose={onRequestClose}
      title={t('acceptanceReport.pdfHasNotSignedYetModal.title')}
      description={t('acceptanceReport.pdfHasNotSignedYetModal.body')}
      cancelButtonText={t(
        'acceptanceReport.pdfHasNotSignedYetModal.sendPdfWithoutSignatureButtonText',
      )}
      confirmButtonText={t('acceptanceReport.pdfHasNotSignedYetModal.signNowButtonText')}
      confirmButtonEndIcon={<Icon name="border-color" />}
      onConfirm={onSignNowButtonClick}
      onCancel={onSendWithoutSignatureButtonClick}
    />
  );
}
