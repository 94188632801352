import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import AcceptanceReportSign from './AcceptanceReportSign';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import {
  AcceptanceReportLocationState,
  AcceptanceReportRouteParams,
} from './AcceptanceReport.route.types';
import AcceptanceReportDetail from './AcceptanceReportDetail';
import AcceptanceReportSendEmail from './AcceptanceReportSendEmail';
import AcceptanceReportArchive from './AcceptanceReportArchive';
import AcceptanceReportSendExternalLinkEmail from './AcceptanceReportSendExternalLinkEmail';
import AcceptanceReportUploadManuallySignedDocument from './AcceptanceReportUploadManuallySignedDocument';
import AcceptanceReportEdit from './AcceptanceReportEdit';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';
import useAcceptanceReport from './hooks/useAcceptanceReport';
import DocumentDetailLoadingLayout from '../components/DocumentDetailLoadingLayout';

export default function AcceptanceReport(): JSX.Element {
  const { acceptanceReportId } = useParams<AcceptanceReportRouteParams>();
  const location = useLocation<AcceptanceReportLocationState>();
  const backgroundLocation = location.state && location.state.backgroundLocation;
  const { data, loading, error } = useAcceptanceReport(acceptanceReportId);

  useUpdateDriveDocumentLastUsed({
    id: acceptanceReportId,
    skip: !data?.acceptanceReport,
  });

  if (loading && !data) {
    return <DocumentDetailLoadingLayout />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<AcceptanceReportDetail {...data} />} />
      </Routes>

      <Routes>
        <Route path="edit" element={<AcceptanceReportEdit {...data} />} />
        <Route path="sign" element={<AcceptanceReportSign {...data} />} />
        <Route path="send-email" element={<AcceptanceReportSendEmail {...data} />} />
        <Route path="archive" element={<AcceptanceReportArchive {...data} />} />
        <Route
          path="send-external-email"
          element={<AcceptanceReportSendExternalLinkEmail {...data} />}
        />
        <Route
          path="upload-manually-signed-report"
          element={<AcceptanceReportUploadManuallySignedDocument {...data} />}
        />
      </Routes>
    </>
  );
}
