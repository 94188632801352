import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface SendConcernPdfDialogProps {
  open: boolean;
  onRequestClose: () => void;
  onSendWithoutSignatureClick: () => void;
  onSignNowClick: () => void;
}

export default function SendConcernPdfDialog({
  open,
  onRequestClose,
  onSendWithoutSignatureClick,
  onSignNowClick,
}: SendConcernPdfDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      title={t('concern.pdfHasNotSignedYetModal.title')}
      description={t('concern.pdfHasNotSignedYetModal.body')}
      cancelButtonText={t('concern.pdfHasNotSignedYetModal.sendPdfWithoutSignatureButtonText')}
      confirmButtonText={t('concern.pdfHasNotSignedYetModal.signNowButtonText')}
      confirmButtonEndIcon={<Icon name="border-color" />}
      onConfirm={onSignNowClick}
      onCancel={onSendWithoutSignatureClick}
      onRequestClose={onRequestClose}
    />
  );
}
