import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EXPORT_OFFER_GAEB_MUTATION = gql`
  mutation ExportOffer($input: ExportOfferInput!) {
    exportOffer(input: $input) {
      status
    }
  }
`;
