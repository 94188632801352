import { gql, TypedDocumentNode } from '@apollo/client';
import { ConcernArchive, ConcernArchiveVariables } from './__generated__/ConcernArchive.mutation';

const ConcernArchiveMutation: TypedDocumentNode<ConcernArchive, ConcernArchiveVariables> = gql`
  mutation ConcernArchive($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
    }
  }
`;

export default ConcernArchiveMutation;
