import { gql } from '@apollo/client';

const ObstructionFragment = gql`
  fragment Obstruction on Obstruction {
    id

    name
    status
    pdfPath

    signatures {
      imagePath
    }

    viewerCanUpdate
    viewerCanAssign
    viewerCanSign
    viewerCanArchive
    viewerCanRemove
  }
`;

export default ObstructionFragment;
