import { OperationUnit } from '../../../../globalTypes';
import { SelectOption } from '../../../../components/form/Control/Select';

const MeasurementTypeOptions: SelectOption[] = [
  {
    label: 'operationUnit.SQUARE_METER.label',
    value: OperationUnit.SQUARE_METER,
  },
  {
    label: 'operationUnit.METER.label',
    value: OperationUnit.METER,
  },
  {
    label: 'operationUnit.CUBIC_METER.label',
    value: OperationUnit.CUBIC_METER,
  },
  {
    label: 'operationUnit.LITER.label',
    value: OperationUnit.LITER,
  },
  {
    label: 'operationUnit.KILOGRAM.label',
    value: OperationUnit.KILOGRAM,
  },
  {
    label: 'operationUnit.DAY.label',
    value: OperationUnit.DAY,
  },
  {
    label: 'operationUnit.HOUR.label',
    value: OperationUnit.HOUR,
  },
  {
    label: 'operationUnit.QUANTITY.label',
    value: OperationUnit.QUANTITY,
  },
  {
    label: 'operationUnit.FLAT_RATE.label',
    value: OperationUnit.FLAT_RATE,
  },
];

export default MeasurementTypeOptions;
