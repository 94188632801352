import { TypedDocumentNode, gql } from '@apollo/client';
import { DriveFolderLog } from './__generated__/DriveFolderLog.fragment';

const DriveFolderLogFragment: TypedDocumentNode<DriveFolderLog> = gql`
  fragment DriveFolderLog on DriveFolderLog {
    ... on DriveFolderCreatedLog {
      id
      projectId
      driveFolderId
      name
      actorId
      actorFirstName
      actorLastName
      date
    }

    ... on DriveFolderDeletedLog {
      id
      driveFolderId
      name
      actorId
      actorFirstName
      actorLastName
      date
    }

    ... on DriveFolderUpdatedLog {
      id
      projectId
      driveFolderId
      oldName
      name
      actorId
      actorFirstName
      actorLastName
      date
    }
  }
`;

export default DriveFolderLogFragment;
