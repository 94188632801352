import { ReactNode, useRef } from 'react';
import { Box, Button, FlexGrid, FlexGridColumn, Icon, uniqueId } from '@hs-baumappe/legacy-ui';
import {
  createEmailFormValidationSchema,
  EmailForm as BaseEmailForm,
  EmailFormProps as BaseEmailFormProps,
} from '@hs-baumappe/forms';
import { RichTextEditorInstance } from '@hs-baumappe/rich-text-editor';
import { useTranslation } from 'react-i18next';
import useEmailFormSuggestions from './hooks/useEmailFormSuggestions';
import useEmailFormEditorUploadImage from './hooks/useEmailFormEditorUploadImage';
import yup from '../../yup';

type EmailFormBaseProps = Omit<
  BaseEmailFormProps,
  | 'id'
  | 'suggestions'
  | 'toEmailsInputProps'
  | 'ccEmailsInputProps'
  | 'subjectInputProps'
  | 'contentInputProps'
>;

interface EmailFormProps extends EmailFormBaseProps {
  projectId: string;
  loading: boolean;
  hideSubjectInput?: boolean;
  submitButtonText?: ReactNode;
}

export default function EmailForm({
  projectId,
  loading,
  hideSubjectInput,
  submitButtonText,
  ...otherProps
}: EmailFormProps): JSX.Element {
  const { t } = useTranslation();
  const id = useRef(uniqueId('email-form-'));
  const editorRef = useRef<RichTextEditorInstance>(null);
  const emailSuggestions = useEmailFormSuggestions(projectId);
  const uploadImage = useEmailFormEditorUploadImage(editorRef);

  return (
    <FlexGrid>
      <FlexGridColumn sm={8}>
        <BaseEmailForm
          {...otherProps}
          id={id.current}
          suggestions={emailSuggestions}
          validationSchema={createEmailFormValidationSchema(yup)}
          toEmailsInputProps={{
            label: t('emailForm.fields.toEmail.label'),
            placeholder: t('emailForm.fields.toEmail.placeholder'),
          }}
          ccEmailsInputProps={{
            label: t('emailForm.fields.ccEmail.label'),
            placeholder: t('emailForm.fields.ccEmail.placeholder'),
          }}
          subjectInputProps={{
            label: t('emailForm.fields.subject.label'),
            placeholder: t('emailForm.fields.subject.placeholder'),
            type: hideSubjectInput ? 'hidden' : undefined,
          }}
          contentInputProps={{
            label: t('emailForm.fields.content.label'),
            editorRef,
            enableResizeImage: true,
            onImageSelect: uploadImage,
            onImageDrop: uploadImage,
          }}
        />
      </FlexGridColumn>

      <FlexGridColumn sm={4}>
        <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'small-x' }}>
          <Button
            form={id.current}
            type="submit"
            color="primary"
            endIcon={<Icon name="email" />}
            loading={loading}
            fullWidth
            className="qa-email-submit-button"
          >
            {submitButtonText || t('emailForm.submitButtonText')}
          </Button>
        </Box>
      </FlexGridColumn>
    </FlexGrid>
  );
}
