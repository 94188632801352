import { Viewer } from '@hs-baumappe/web-auth';
import { OfferData } from '../../../Detail/__generated__/graphql';
import createViewerSignature from '../../../../../containers/forms/createViewerSignature';

export const createExportFormHTMLContentGaeb = (
  project: OfferData['offer']['project'],
  viewer?: Viewer,
): string => {
  if (!viewer) return '';

  return `
  <div>
    Sehr geehrter Empfänger,<br /><br />
  </div>
  <div>
    Im Anhang finden Sie die GAEB-Dateien zum in AVO erstellten Angebot ${project.city || ''} ${
      project.street || project.addressLine || ''
    } ${project.name || ''} ${project.postCode || ''}.
    Diese können Sie innerhalb des entsprechenden Projektes bei RIB iTWOrun importieren, um dort das Angebot zu erstellen.<br /><br />
  </div>
  ${createViewerSignature(viewer)}
  `;
};
