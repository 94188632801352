import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_OFFER_DATA } from '../../Detail/graphql';
import { EDIT_TAX_AMOUNT } from './graphql';
import { UpdateTaxRate, UpdateTaxRateVariables } from './__generated__/graphql';
import { offerRoutes } from '../../offerRoutes';

interface UseOfferSummaryEditParams {
  error?: ApolloError;
  edit: (value?: string) => Promise<void>;
}

const useOfferSummaryEdit = (offerId: string): UseOfferSummaryEditParams => {
  const navigate = useNavigate();

  const [updateTaxRate, { loading, error }] = useMutation<UpdateTaxRate, UpdateTaxRateVariables>(
    EDIT_TAX_AMOUNT,
  );

  const edit = async (value?: string) => {
    if (loading) {
      return;
    }

    await updateTaxRate({
      variables: {
        input: {
          offer: offerId,
          taxRate: value ? parseFloat(value) / 100 : 0,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    navigate(offerRoutes.detail.generatePath({ offerId }));
  };

  return {
    error,
    edit,
  };
};

export default useOfferSummaryEdit;
