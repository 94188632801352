import { TypedDocumentNode, gql } from '@apollo/client';
import { CustomFileLog } from './__generated__/CustomFileLog.fragment';

const CustomFileLogFragment: TypedDocumentNode<CustomFileLog> = gql`
  fragment CustomFileLog on CustomFileLog {
    ... on CustomFileArchivedLog {
      id
      name
      date

      customFileId

      actorId
      actorFirstName
      actorLastName

      folderId
      folderName
      projectId
    }

    ... on CustomFileCreatedLog {
      id
      name
      date

      customFileId

      actorId
      actorFirstName
      actorLastName

      folderId
      folderName
      projectId
      source
    }

    ... on CustomFileDeletedLog {
      id
      name
      date

      actorId
      actorFirstName
      actorLastName

      folderId
      folderName
      projectId
    }

    ... on CustomFileDuplicatedForManipulationLog {
      id
      name
      duplicatedFromName
      date

      customFileId

      actorId
      actorFirstName
      actorLastName

      # When getting folderId and folderName with other logs,
      # these fields conflict because some logs have them as optional
      definedFolderId: folderId
      definedFolderName: folderName
      projectId
    }

    ... on CustomFileEmailSentLog {
      id
      name
      date

      customFileId

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on CustomFileManipulatedLog {
      id
      name
      date

      customFileId

      actorId
      actorFirstName
      actorLastName

      # When getting folderId and folderName with other logs,
      # these fields conflict because some logs have them as optional
      definedFolderId: folderId
      definedFolderName: folderName
      projectId
    }

    ... on CustomFileRenamedLog {
      id
      oldName
      name
      date

      customFileId

      actorId
      actorFirstName
      actorLastName

      folderId
      folderName
      projectId
    }
  }
`;

export default CustomFileLogFragment;
