import { ApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import UploadManuallySignedAcceptanceReportMutation from '../../graphql/UploadManuallySignedAcceptanceReport.mutation';
import { AcceptanceReportDetailAlert } from '../../../AcceptanceReportDetail/AcceptanceReportDetail.utils';
import { AcceptanceReportManuallySignedDocumentFormValues } from '../../components/AcceptanceReportUploadManuallySignedDocumentForm';
import { acceptanceReportRoutes } from '../../../acceptanceReportRoutes';

interface UseAcceptanceReportManualSignResponse {
  sign: (values: AcceptanceReportManuallySignedDocumentFormValues) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useAcceptanceReportManualSign(
  acceptanceReportId: string,
): UseAcceptanceReportManualSignResponse {
  const navigate = useNavigate();
  const [mutate, { loading, error }] = useMutation(UploadManuallySignedAcceptanceReportMutation);

  async function sign({ file }: AcceptanceReportManuallySignedDocumentFormValues) {
    if (!file) {
      return;
    }

    try {
      await mutate({
        variables: {
          file,
          input: {
            acceptanceReport: acceptanceReportId,
          },
        },
      });

      const searchParams = new URLSearchParams({
        alert: AcceptanceReportDetailAlert.SIGNED_MANUALLY,
      });

      navigate(
        createPath({
          pathname: acceptanceReportRoutes.detail.generatePath({
            acceptanceReportId,
          }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {}
  }

  return {
    sign,
    loading,
    error,
  };
}
