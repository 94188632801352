import { SchemaOf } from 'yup';
import yup from '../../../../yup';
import { Equipment } from './graphql/__generated__/Equipment.fragment';
import { EquipmentsFormValues } from './values';

const getErrorMessageFromIconName = (iconName: string) => {
  switch (iconName) {
    case 'footProtection':
      return 'Fußschutz ist verpflichtend';
    case 'eyeProtection':
      return 'Augen-und Gesichtsschutz ist verpflichtend, aufgrund der Auswahl in Schritt 2';
    case 'protectiveEquipmentAgainstFall':
      return 'Schutzausrüstung gegen Absturz ist verpflichtend, aufgrund der Auswahl in Schritt 2';
    case 'respiratoryProtection':
      return 'Atemschutz ist verpflichtend, aufgrund der Auswahl in Schritt 2';
    case 'headProtection':
      return 'Kopfschutz ist verpflichtend, aufgrund der Auswahl in Schritt 2';
    default:
      return '';
  }
};

export const validationSchema = (equipments: Equipment[]): SchemaOf<EquipmentsFormValues> => {
  return yup.object({
    equipments: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required(),
          required: yup.boolean().required(),
          value: yup
            .boolean()
            .required()
            .when(['label', 'required'], (label: unknown, required: unknown) => {
              if (required) {
                return yup.boolean().test(
                  'only-true',
                  () => {
                    const equipment = equipments.find((f) => f.id === label);
                    if (!equipment) {
                      return undefined;
                    }

                    return getErrorMessageFromIconName(equipment.icon);
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (value: any) => {
                    return value;
                  },
                );
              }

              return yup.boolean();
            }),
        }),
      )
      .defined()
      .default([]),
  });
};

export const defaultValue = (
  initialValues?: Partial<EquipmentsFormValues>,
): EquipmentsFormValues => {
  return {
    equipments: [],
    ...initialValues,
  };
};
