import { gql } from '@apollo/client';

const RegionalSecurityManagerFragment = gql`
  fragment RegionalSecurityManager on RegionalSecurityManager {
    fullName
    phone
  }
`;

export default RegionalSecurityManagerFragment;
