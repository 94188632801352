import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import ErrorState from '../../../../containers/ErrorState';

import useRemoveDocument, { Document } from './useRemoveDocument';

interface TriggerProps {
  loading?: boolean;

  onClick?(): void;
}

export interface RemoveDocumentProps {
  document: Document;
  renderTrigger?: (props: TriggerProps) => ReactNode;
  onRemoveSuccess: (id: string) => void;
}

export default function RemoveDocument({
  document,
  onRemoveSuccess,
  renderTrigger,
}: RemoveDocumentProps): JSX.Element {
  const { name, type } = document;

  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { remove, loading, error } = useRemoveDocument({
    document,
    onRemoveSuccess,
  });

  return (
    <>
      {error && <ErrorState error={error} />}
      {renderTrigger && renderTrigger({ loading, onClick: () => setDialogOpen(true) })}
      <AlertDialog
        destructive
        open={dialogOpen}
        title={name}
        description={t(`removeDocumentModal.content.${type}` as ParseKeys<'translation'>)}
        cancelButtonText={t('removeDocumentModal.cancelButton')}
        confirmButtonText={t('removeDocumentModal.submitButton', {
          documentType: t(`documentType.${type}` as ParseKeys<'translation'>).toString(),
        })}
        confirmButtonEndIcon={<Icon name="delete" />}
        onConfirm={() => {
          setDialogOpen(false);
          remove();
        }}
        onRequestClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  );
}
