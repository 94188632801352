import { ProjectFilterProfitCenter } from '../../../graphql/__generated__/ProjectFilterProfitCenter.fragment';
import TreeStorage from '../../../../../../../../utils/TreeStorage';

export interface ProjectFilterProfitCenterWithChildren extends ProjectFilterProfitCenter {
  children: ProjectFilterProfitCenterWithChildren[];
}

function profitCenterMapper(
  profitCenter: ProjectFilterProfitCenter,
  childrenGetter: (id: string) => ProjectFilterProfitCenter[],
): ProjectFilterProfitCenterWithChildren {
  return {
    ...profitCenter,
    children: childrenGetter(profitCenter.id).map((child) =>
      profitCenterMapper(child, childrenGetter),
    ),
  };
}

export function profitCenterHierarchyBuilder(
  profitCenters: ProjectFilterProfitCenter[],
): ProjectFilterProfitCenterWithChildren[] {
  const treeStorage = new TreeStorage({
    data: profitCenters,
    connector: (profitCenter) => ({
      id: profitCenter.id,
      parentID: profitCenter.parent ? profitCenter.parent.id : undefined,
    }),
  });

  const roots = treeStorage.getRoots();

  return roots.map((root) => profitCenterMapper(root, treeStorage.getConnections));
}

export function getDeepestChildrenIdsOfProfitCenter(
  parentProfitCenter: ProjectFilterProfitCenterWithChildren,
): string[] {
  const result: string[] = [];

  function profitCenterIdPusher(childProfitCenter: ProjectFilterProfitCenterWithChildren) {
    result.push(childProfitCenter.id);

    if (childProfitCenter.children.length > 0) {
      childProfitCenter.children.filter((f) => f.projectCount).forEach(profitCenterIdPusher);
    }
  }

  parentProfitCenter.children.filter((f) => f.projectCount).forEach(profitCenterIdPusher);

  return result;
}
