import { TypedDocumentNode, gql } from '@apollo/client';
import {
  DriveDocumentTagDetails,
  DriveDocumentTagDetailsVariables,
} from './__generated__/DriveDocumentTagDetails.query';

const DriveDocumentTagDetailsQuery: TypedDocumentNode<
  DriveDocumentTagDetails,
  DriveDocumentTagDetailsVariables
> = gql`
  query DriveDocumentTagDetails($documentId: ID!) {
    driveDocument(id: $documentId) {
      id

      name
      size
    }
  }
`;

export default DriveDocumentTagDetailsQuery;
