import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { Dialog, Icon, IconButton } from '@hs-baumappe/legacy-ui';
import { AssignDocumentToProjectImportRouteParams } from './AssignDocumentToProjectImport.route.types';
import ProjectSearch from './ProjectSearch';
import useAssignDocumentToProjectImport from './useAssignDocumentToProjectImport';
import ErrorState from '../../../../containers/ErrorState';
import { AssignDocumentLocationState } from '../AssignDocumentToProject/AssignDocumentToProject.route.types';
import { DocumentType } from '../../../../globalTypes';

const AssignDocumentToProjectImport = (): JSX.Element => {
  const location = useLocation<AssignDocumentLocationState>();
  const navigate = useNavigate();
  const { documentId } = useParams<AssignDocumentToProjectImportRouteParams>();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const urlParams = new URLSearchParams(location.search);
  const documentType = (urlParams.get('document') as DocumentType) || undefined;
  const returnPath = urlParams.get('returnPath') || undefined;

  const { projectImport, error } = useAssignDocumentToProjectImport({
    documentType,
    returnPath,
    documentId,
  });

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation || !returnPath) {
      navigate(-1);
      return;
    }

    navigate(returnPath);
  }, [navigate, location.state, returnPath]);

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex">
          <IconButton
            icon={<Icon name="close" />}
            className="u-margin-left-auto"
            onClick={handleDialogRequestClose}
          />
        </div>

        <div className="u-padding-top">
          <ProjectSearch onClickProjectSelect={projectImport} />
        </div>
      </div>
    </Dialog>
  );
};

export default AssignDocumentToProjectImport;
