import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Container, FlexGrid, FlexGridColumn, Icon, Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { ErrorRendererPage } from '@hs-baumappe/redkit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import useProjectFilter from './ProjectFilter/useProjectFilter';
import ProjectsLoadingLayout from './ProjectsLoadingLayout';
import ProjectFilter from './ProjectFilter/ProjectFilter';
import { ProjectsRouteLocationState } from '../Projects.routes';
import routes from '../../routes';
import { ProjectSorting } from '../../../globalTypes';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';
import useProjectsInfiniteLoader from './useProjectsInfiniteLoader';
import ProjectsTableDecider from './ProjectsTableDecider';

export default function Detail(): JSX.Element | null {
  const { t } = useTranslation();
  const { sivKeyFiguresInProjectList, highlightVendocProjects } = useFlags<LaunchDarklyFlagSet>();
  const location = useLocation();

  const { viewer } = useViewer();
  const importProjectPermitted = !!viewer?.scopes.importProject.isPermitted;
  const viewSIVOverviewPermitted = !!viewer?.scopes.viewSIVOverview.isPermitted;
  const viewSIVPricesPermitted = !!viewer?.scopes.viewSIVPrices.isPermitted;
  const accessSIVPerformanceRecordingPermitted =
    !!viewer?.scopes.accessSIVPerformanceRecording.isPermitted;
  const transmitKeyDataToSapPermitted = !!viewer?.scopes.transmitKeyDataToSap.isPermitted;

  const [firstRender, setFirstRender] = useState(false);
  const {
    projects,
    error,
    loading,
    nextPageLoading,
    hasNextPage,
    searchProjects,
    projectStatuses,
    setProjectStatuses,
    profitCenters,
    setProfitCenters,
    searchQuery,
    onlyFollowed,
    setOnlyFollowed,
    fetchNextPage,
    filterOutProject,
    meta,
    sortingField,
    setSortingField,
    sortingDirection,
    setSortingDirection,
  } = useProjectFilter({
    viewSIVOverviewPermitted,
    viewSIVPricesPermitted,
    sivKeyFiguresInProjectList,
  });

  useProjectsInfiniteLoader({ loading, nextPageLoading, hasNextPage, fetchNextPage });

  const [searchTerm, setSearchTerm] = useState(searchQuery ?? '');

  useEffect(() => {
    const mainContainer = document.getElementById('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setFirstRender(true);
    }
  }, [loading]);

  function handleTableSortingChange(nextSorting: ProjectSorting) {
    setSortingField(nextSorting.field);
    setSortingDirection(nextSorting.direction);
  }

  function handleFollowProjectToggled(projectId: string, followed: boolean) {
    if (followed || !onlyFollowed) {
      return;
    }

    filterOutProject(projectId);
  }

  function handleSearchTermChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
    searchProjects(event.target.value || undefined);
  }

  const showSIVTransmitButton = transmitKeyDataToSapPermitted;

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!firstRender && loading) {
    return (
      <ProjectsLoadingLayout
        sortingField={sortingField}
        sortingDirection={sortingDirection}
        viewSIVOverviewPermitted={viewSIVOverviewPermitted}
        viewSIVPricesPermitted={viewSIVPricesPermitted}
        accessSIVPerformanceRecordingPermitted={accessSIVPerformanceRecordingPermitted}
        onlyFollowed={onlyFollowed}
        showSIVTransmitButton={showSIVTransmitButton}
      />
    );
  }

  if (!projects) {
    return null;
  }

  return (
    <Container stylex={{ paddingEnds: 'large' }}>
      <AppHeader
        title={
          <Text component="h1" variant="body-large" color="muted" stylex={{ marginBottom: '0' }}>
            {t('projectListPage.pageTitle')}
          </Text>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />

      <FlexGrid stylex={{ marginTop: 'large-2x', rowGap: 'small-3x' }}>
        <FlexGridColumn sm={6} lg={4}>
          <Button
            component={Link}
            className="qa-project-import-button"
            color="primary"
            to="import"
            state={{ backgroundLocation: location } as ProjectsRouteLocationState}
            endIcon={<Icon name="cloud-download" />}
            disabled={!importProjectPermitted}
            fullWidth
          >
            {t('projectListPage.importHsProjectButton')}
          </Button>
        </FlexGridColumn>

        {showSIVTransmitButton && (
          <FlexGridColumn sm={6} lg={{ size: 4, offset: 'auto' }}>
            <Button
              component={Link}
              to="sap-transmit"
              state={{ backgroundLocation: location } as ProjectsRouteLocationState}
              endIcon={<Icon name="forward" />}
              fullWidth
            >
              {t('projectListPage.sapTransmitButton')}
            </Button>
          </FlexGridColumn>
        )}
      </FlexGrid>

      <ProjectFilter
        meta={meta}
        projectStatuses={projectStatuses}
        profitCenters={profitCenters}
        searchTerm={searchTerm}
        onlyFollowed={onlyFollowed}
        onFollowFilterChange={(followed) => setOnlyFollowed(followed)}
        onProjectStatusesChange={(statuses) => setProjectStatuses(statuses)}
        onProfitCentersChange={(nextProfitCenters) => setProfitCenters(nextProfitCenters)}
        onSearchTermChange={handleSearchTermChange}
      />

      <ProjectsTableDecider
        projects={projects}
        sorting={{ field: sortingField, direction: sortingDirection }}
        loading={loading}
        nextPageLoading={nextPageLoading}
        showsFollowedProjects={onlyFollowed}
        highlightVendocProjects={highlightVendocProjects}
        viewSIVOverviewPermitted={viewSIVOverviewPermitted}
        viewSIVPricesPermitted={viewSIVPricesPermitted}
        accessSIVPerformanceRecordingPermitted={accessSIVPerformanceRecordingPermitted}
        onSortingChange={handleTableSortingChange}
        onProjectFollowButtonClick={handleFollowProjectToggled}
      />
    </Container>
  );
}
