import { gql, TypedDocumentNode } from '@apollo/client';
import {
  SAPTransmissionProjects,
  SAPTransmissionProjectsVariables,
} from './__generated__/SAPTransmissionProjects.query';
import SAPTransmissionProjectFragment from './SAPTransmissionProject.fragment';
import SAPTransmissionSelectedProfitCenterFragment from './SAPTransmissionSelectedProfitCenter.fragment';

export const SAPTransmissionProjectsQuery: TypedDocumentNode<
  SAPTransmissionProjects,
  SAPTransmissionProjectsVariables
> = gql`
  query SAPTransmissionProjects(
    $profitCenters: [ID!]
    $projectStatuses: [ProjectStatus!]
    $limit: Int
    $page: Int
  ) {
    sapTransmissionProjects(
      profitCenters: $profitCenters
      statuses: $projectStatuses
      limit: $limit
      page: $page
    ) {
      projects {
        ...SAPTransmissionProject
      }

      selectedProfitCenters {
        ...SAPTransmissionSelectedProfitCenter
      }

      selectedStatuses {
        status
        count
      }

      pagination {
        current
        hasNextPage
      }
    }
  }

  ${SAPTransmissionProjectFragment}
  ${SAPTransmissionSelectedProfitCenterFragment}
`;
