import { useMutation } from '@apollo/client';
import { Icon, IconButton } from '@hs-baumappe/legacy-ui';

import ErrorState from '../../../../../containers/ErrorState';

import FollowProjectToggleMutation from './graphql/FollowProjectToggle.mutation';
import {
  FollowProjectToggle,
  FollowProjectToggleVariables,
} from './graphql/__generated__/FollowProjectToggle.mutation';
import GetProjectDetailQuery from '../../graphql/GetProjectDetail.query';

interface FollowProjectProps {
  followed: boolean;
  projectId: string;
}

export default function FollowProject({ followed, projectId }: FollowProjectProps): JSX.Element {
  const [followProjectToggleMutation, { loading, error }] = useMutation<
    FollowProjectToggle,
    FollowProjectToggleVariables
  >(FollowProjectToggleMutation);

  async function followProject() {
    if (loading) {
      return;
    }

    await followProjectToggleMutation({
      variables: {
        input: {
          projectId,
        },
      },
      refetchQueries: [{ query: GetProjectDetailQuery, variables: { projectId } }],
    });
  }

  return (
    <>
      {error && <ErrorState error={error} />}

      <div className={followed ? 'u-color-primary' : 'u-color-muted'}>
        <IconButton
          icon={<Icon name={followed ? 'notifications-on' : 'notifications-off'} />}
          onClick={followProject}
          disabled={loading}
          color="inherit"
          pressed={followed}
        />
      </div>
    </>
  );
}
