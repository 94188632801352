import { TFunction } from 'i18next';
import { Log } from '../Log.types';
import { ProjectLog } from './ProjectLog.types';
import { ProjectLog_ProjectExternalStatusChangedLog_ } from '../../graphql/__generated__/ProjectLog.fragment';
import { ProjectExternalStatus, ProjectImportSourceSystem } from '../../../../globalTypes';

export const PROJECT_LOG_TYPES = [
  'ProjectAddressChangedLog',
  'ProjectClientAddressChangedLog',
  'ProjectClientNameChangedLog',
  'ProjectContractDateChangedLog',
  'ProjectImportedLog',
  'ProjectNameChangedLog',
  'ProjectOfferNumberChangedLog',
  'ProjectOrderNumberChangedLog',
  'ProjectProfitCenterChangedLog',
  'ProjectStatusUpdatedLog',
  'ProjectSyncedLog',
  'ProjectExternalStatusChangedLog',
];

export function isProjectLog(log: Log): log is ProjectLog {
  return PROJECT_LOG_TYPES.includes(log.__typename);
}

export function getProjectTypeOfExternalStatusChangedLog(
  t: TFunction,
  log: ProjectLog_ProjectExternalStatusChangedLog_,
): string {
  if (log.projectImportSourceSystem !== ProjectImportSourceSystem.VENDOC) {
    return t('logs.projectExternalStatusChangedUpdated.projectType.project');
  }

  return log.projectIsOrdered
    ? t('logs.projectExternalStatusChangedUpdated.projectType.ordered')
    : t('logs.projectExternalStatusChangedUpdated.projectType.offered');
}

export function formatProjectExternalStatus(
  t: TFunction,
  projectExternalStatus?: ProjectExternalStatus,
): string {
  return projectExternalStatus ? t(`projectExternalStatus.${projectExternalStatus}`) : '-';
}

export function getLogProjectImportSystem(
  t: TFunction,
  projectImportSourceSystem: ProjectImportSourceSystem,
): string {
  return t(`projectSourceSystem.${projectImportSourceSystem}`);
}
