import { gql, TypedDocumentNode } from '@apollo/client';
import { ProjectLog } from './__generated__/ProjectLog.fragment';

const ProjectLogFragment: TypedDocumentNode<ProjectLog> = gql`
  fragment ProjectLog on ProjectLog {
    ... on ProjectAddressChangedLog {
      id
      date
      address
    }

    ... on ProjectClientAddressChangedLog {
      id
      date
      clientAddress
    }

    ... on ProjectClientNameChangedLog {
      id
      date
      oldClientName
      clientName
    }

    ... on ProjectContractDateChangedLog {
      id
      date
      contractDate
    }

    ... on ProjectImportedLog {
      id
      date

      actorFirstName
      actorLastName

      projectNumber
      projectName
      sourceSystem
    }

    ... on ProjectNameChangedLog {
      id
      date
      name
    }

    ... on ProjectOfferNumberChangedLog {
      id
      date
      oldOfferNumber
      offerNumber
    }

    ... on ProjectOrderNumberChangedLog {
      id
      date
      offerNumber
      orderNumber
    }

    ... on ProjectProfitCenterChangedLog {
      id
      date
      profitCenterId
      profitCenterName
    }

    ... on ProjectStatusUpdatedLog {
      id
      from
      to
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on ProjectExternalStatusChangedLog {
      id
      date

      oldExternalStatus
      externalStatus
      projectImportSourceSystem
      projectIsOrdered
    }

    ... on ProjectSyncedLog {
      id
      date

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default ProjectLogFragment;
