import { ChangeEvent, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Button, Icon, Paper } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import PrecautionCategoriesQuery from './graphql/PrecautionCategories.query';
import PrecautionCategoryHeader from '../../../../components/PrecautionCategoryHeader';
import PrecautionCheck from '../../../../components/PrecautionCheck';
import AddElementInput from './AddElementInput';
import { Precaution } from './graphql/__generated__/Precaution.fragment';
import { PrecautionValues } from './values';
import { defaultValue, validationSchema } from './form';

function orderPrecautionsCategories(precaution: Precaution[]): Precaution[] {
  return [...precaution].sort((a, b) => a.sort - b.sort);
}

interface PrecautionsProps {
  initialValues?: Partial<PrecautionValues>;
  onSubmit: (values: PrecautionValues) => void;
  onDirtyStateChange: (dirty: boolean) => void;
}

export default function Precautions({
  onSubmit,
  initialValues,
  onDirtyStateChange,
}: PrecautionsProps): JSX.Element | null {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(PrecautionCategoriesQuery);
  const formik = useFormik({
    validationSchema,
    initialValues: defaultValue(initialValues),
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit,
  });

  useEffect(() => {
    onDirtyStateChange(formik.dirty);
  }, [formik.dirty, onDirtyStateChange]);

  if (loading && !data) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!data) {
    return null;
  }

  const { values, setFieldValue } = formik;
  const { precautionCategories } = data;
  const { specialPrecautions, precautions } = values;

  const isValid = !!specialPrecautions.length || !!precautions.length;

  function handlePrecautionToggle(precaution: Precaution) {
    return function (event: ChangeEvent<HTMLInputElement>) {
      const { checked } = event.currentTarget;
      const selections = new Set(values.precautions);

      if (checked) {
        selections.add(precaution.id);
      } else {
        selections.delete(precaution.id);
      }

      setFieldValue('precautions', Array.from(selections), true);
    };
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box className="row">
          <Box className="col col--md-8">
            {precautionCategories.map((category) => {
              const orderedPrecautions = orderPrecautionsCategories(category.precautions);

              return (
                <Box key={category.id} stylex={{ marginTop: 'medium' }}>
                  <PrecautionCategoryHeader title={category.title} iconName={category.icon} />

                  <Paper stylex={{ marginTop: 'medium' }}>
                    {orderedPrecautions.map((precaution) => (
                      <PrecautionCheck
                        key={precaution.id}
                        id={`precaution-${precaution.id}`}
                        name="precautions"
                        label={precaution.title}
                        value={precaution.id}
                        checked={precautions.includes(precaution.id)}
                        onChange={handlePrecautionToggle(precaution)}
                      />
                    ))}

                    {specialPrecautions.map((specialPrecaution, index) => {
                      if (specialPrecaution.precautionCategory === category.id) {
                        return (
                          <PrecautionCheck
                            key={`${specialPrecaution.title}-${index}`}
                            id={`specialPrecautions-${specialPrecaution.title}`}
                            name={`specialPrecautions-${specialPrecaution.title}`}
                            value={specialPrecaution.title}
                            label={specialPrecaution.title}
                            onClick={() => {
                              const selections = specialPrecautions;
                              selections.splice(index, 1);

                              setFieldValue('specialPrecautions', [...selections], true);
                            }}
                            checked
                          />
                        );
                      }

                      return null;
                    })}
                  </Paper>

                  <AddElementInput
                    id={category.id}
                    className="u-margin-top"
                    onAdd={(title: string) => {
                      const updatedSpecialPrecautions = [
                        ...specialPrecautions,
                        { precautionCategory: category.id, title },
                      ];

                      setFieldValue('specialPrecautions', updatedSpecialPrecautions, true);
                    }}
                  />
                </Box>
              );
            })}
          </Box>

          <Box className="col col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                disabled={!isValid}
                endIcon={<Icon name="arrow-right" />}
                fullWidth
              >
                {t('createAndUpdateRiskAssessmentForm.precaution.nextStep.button')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
