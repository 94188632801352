import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import RouteDialog from '../../../containers/RouteDialog';
import { QsstSendMailModalTitle } from './QsstSendMailModalTitle/QsstSendMailModalTitle';
import QsstSendMailBody from './QsstSendMailBody/QsstSendMailBody';
import { Qsst } from '../graphql/__generated__/Qsst.fragment';
import { QsstProject } from '../graphql/__generated__/QsstProject.fragment';
import { qsstRoutes } from '../qsstRoutes';
import { QsstLocationState } from '../Qsst.route.types';
import { QsstScopes } from '../Qsst.types';

export enum QsstSendMailMethod {
  LINK = 'link',
  ATTACHMENT = 'attachment',
}

interface QsstSendMailProps {
  qsst: Qsst;
  scopes: QsstScopes;
  project: QsstProject;
}

export function QsstSendMail({ qsst, scopes, project }: QsstSendMailProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<QsstLocationState>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isAttachDocument = searchParams.get('method') === QsstSendMailMethod.ATTACHMENT;

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { driveDocument } = useDriveDocumentTagDetails(qsst.id);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(qsstRoutes.detail.generatePath({ qsstId: qsst.id }));
  }, [location.state, navigate, qsst.id]);

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  const permitted = isAttachDocument
    ? scopes.sendQsstDocumentAttachment.isPermitted
    : scopes.sendQsstDocumentSignatureMail.isPermitted;
  const missingScopes = isAttachDocument
    ? scopes.sendQsstDocumentAttachment.missingScopeNames
    : scopes.sendQsstDocumentSignatureMail.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <QsstSendMailModalTitle
          qsstName={qsst.name}
          qsstStatus={qsst.status}
          projectDescription={project.description}
        />
      }
    >
      {permitted ? (
        <QsstSendMailBody
          projectId={project.id}
          projectName={project.name}
          qsstId={qsst.id}
          qsstName={qsst.name}
          qsstPdfSize={driveDocument?.size ?? undefined}
          attachDocument={isAttachDocument}
          onDocumentIdsValueChange={() => setDialogOpen(false)}
          onDirtyStateChange={setFormDirty}
        />
      ) : (
        <MissingScopeError missingScopes={missingScopes} />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('qsstSendEmail.confirmationModal.title', {
          qsstName: qsst.name,
        })}
        description={t('qsstSendEmail.confirmationModal.body')}
        confirmButtonText={t('qsstSendEmail.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('qsstSendEmail.confirmationModal.dismissButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
