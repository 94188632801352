import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';

import ErrorState from '../../../../../containers/ErrorState';
import { FolderOperation } from '../../../Detail/types';
import useFolderOperationRemove from './useFolderOperationRemove';

interface FolderOperationRemoveModalProps {
  open: boolean;
  operation: FolderOperation;
  onRequestClose?: () => void;
  onSuccess: () => void;
}

export default function FolderOperationRemoveModal({
  open,
  operation,
  onRequestClose,
  onSuccess,
}: FolderOperationRemoveModalProps): JSX.Element {
  const { t } = useTranslation();
  const { error, removeFolderOperation } = useFolderOperationRemove(operation.id, onSuccess);

  return (
    <Dialog open={open} onRequestClose={onRequestClose} size="xsmall" role="alertdialog">
      {error && <ErrorState error={error} />}
      <DialogHeader
        rightSlot={<IconButton onClick={onRequestClose} icon={<Icon name="close" />} />}
      >
        <Text variant="title-small">
          {t('removeFolderOperation.title', {
            partNo: operation.partNoTree.join('.'),
            name: operation.name,
          })}
        </Text>
      </DialogHeader>
      <DialogBody>
        <Text variant="body-medium">{t('removeFolderOperation.text')}</Text>
      </DialogBody>
      <DialogActions direction="column">
        <Button onClick={onRequestClose}>{t('removeFolderOperation.cancelButton')}</Button>
        <Button color="error" onClick={removeFolderOperation} endIcon={<Icon name="delete" />}>
          {t('removeFolderOperation.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
