import EarProtectionIcon from '../../../../static/img/equipmentIcons/earProtection.svg';
import EyeProtectionIcon from '../../../../static/img/equipmentIcons/eyeProtection.svg';
import FootProtectionIcon from '../../../../static/img/equipmentIcons/footProtection.svg';
import HeadProtectionIcon from '../../../../static/img/equipmentIcons/headProtection.svg';
import ProtectiveClothingIcon from '../../../../static/img/equipmentIcons/protectiveClothing.svg';
import ProtectiveEquipmentAgainstFallIcon from '../../../../static/img/equipmentIcons/protectiveEquipmentAgainstFall.svg';
import ProtectiveGlovesIcon from '../../../../static/img/equipmentIcons/protectiveGloves.svg';
import RespiratoryProtectionIcon from '../../../../static/img/equipmentIcons/respiratoryProtection.svg';
import VisibilityJacketIcon from '../../../../static/img/equipmentIcons/visibilityJacket.svg';

export type EquipmentIconName = keyof typeof EquipmentIcons;

export const EquipmentIcons = {
  earProtection: EarProtectionIcon,
  eyeProtection: EyeProtectionIcon,
  footProtection: FootProtectionIcon,
  headProtection: HeadProtectionIcon,
  protectiveClothing: ProtectiveClothingIcon,
  protectiveEquipmentAgainstFall: ProtectiveEquipmentAgainstFallIcon,
  protectiveGloves: ProtectiveGlovesIcon,
  respiratoryProtection: RespiratoryProtectionIcon,
  visibilityJacket: VisibilityJacketIcon,
} as const;
