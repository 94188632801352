import { useState } from 'react';
import { FormikHelpers } from 'formik';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import ObstructionDetailQuery from '../../../graphql/ObstructionDetail.query';
import {
  ObstructionDetailAlert,
  ObstructionDetailSearchParams,
} from '../../../ObstructionDetail/ObstructionDetail.utils';
import obstructionRoutes from '../../../obstructionRoutes';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import { FormikErrors } from 'formik/dist/types';
import { DriveDocumentArchiveInput } from '../../../../../globalTypes';
import ObstructionArchiveMutation from './graphql/ObstructionArchive.mutation';
import { ArchiveFormValues } from '../../../../../containers/ArchiveForm';

interface UseObstructionArchiveResponse {
  archive: (
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useObstructionArchive(
  obstructionId: string,
): UseObstructionArchiveResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const { setSapCredentials } = useSapCredentials();
  const [mutate, { loading }] = useMutation(ObstructionArchiveMutation, {
    refetchQueries: [ObstructionDetailQuery],
  });

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    setError(undefined);

    try {
      const archiveMutationResponse = await mutate({
        variables: {
          input: {
            sapPassword: values.password,
            sapUsername: values.username,
            id: obstructionId,
          },
        },
      });

      const searchParams = new URLSearchParams({
        [ObstructionDetailSearchParams.ALERT]: ObstructionDetailAlert.ARCHIVED,
      });

      if (archiveMutationResponse.data) {
        setSapCredentials({ username: values.username, password: values.password });
      }

      navigate(
        createPath({
          pathname: obstructionRoutes.detail.generatePath({ obstructionId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    archive,
    loading,
    error,
  };
}
