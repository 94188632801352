import { FC } from 'react';
import cx from 'classnames';

type Props = JSX.IntrinsicElements['thead'];

const Thead: FC<Props> = ({ className, children, ...otherProps }) => (
  <thead className={cx('c-table__thead', className)} {...otherProps}>
    {children}
  </thead>
);

export default Thead;
