import { useState } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { AutocompleteOption } from '@hs-baumappe/legacy-ui';
import { FormikAutocompleteField } from '@hs-baumappe/forms';
import { doesIncludeTerm } from '../../utils/string';

interface FormikProfessionsFieldProps {
  name: string;
  professions: string[];
}

export default function FormikProfessionsField({
  name,
  professions,
}: FormikProfessionsFieldProps): JSX.Element {
  const { t } = useTranslation();
  const [, { value }] = useField<string[]>(name);

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const searchedProfessions = inputValue
    ? professions.filter((profession) => doesIncludeTerm(profession, inputValue))
    : professions;

  return (
    <FormikAutocompleteField
      open={searchedProfessions.length === 0 ? false : open}
      name={name}
      label={t('professionsFormField.label')}
      placeholder={value.length === 0 ? t('professionsFormField.placeholder') : undefined}
      allowArbitraryValues
      multiple
      onInputValueChange={setInputValue}
      onRequestOpen={() => setOpen(true)}
      onRequestClose={() => setOpen(false)}
    >
      {searchedProfessions.map((profession) => (
        <AutocompleteOption value={profession} key={profession}>
          {profession}
        </AutocompleteOption>
      ))}
    </FormikAutocompleteField>
  );
}
