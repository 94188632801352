import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { MeasurementsValues } from '../../measurement/MeasurementsSection/values';
import { BaseValues } from '../containers/FolderForm/values';
import { castGqlForMeasurement } from '../containers/FolderForm/form';
import { OfferType } from '../../../../globalTypes';
import OfferFolderCloneForFramework from './OfferFolderCloneForFramework';
import OfferFolderCloneForFreeform from './OfferFolderCloneForFreeform';
import { OfferLocationState } from '../../Offer.route.types';
import { OfferFolderCloneRouteParams } from './route';
import useOffer from '../../hooks/offer';
import { Folder } from '../../Detail/types';
import InfoModal from '../../components/InfoModal';
import { offerRoutes } from '../../offerRoutes';

const OfferFolderClone = (): JSX.Element | null => {
  const { t } = useTranslation();
  const [aliasInfoModal, setAliasInfoModal] = useState(false);
  const { getFolderById, getOffer, setSelectedFolder } = useOffer();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId, folderId } = useParams<OfferFolderCloneRouteParams>();

  const folder = getFolderById(folderId);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location.state?.backgroundLocation, navigate, offerId]);

  if (!folder) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
    return null;
  }

  const { viewerCanCloneFolder } = folder;

  if (!viewerCanCloneFolder) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
  }

  const urlParams = new URLSearchParams(location.search);

  const parentId = urlParams.get('parentId') || undefined;

  const initialValues = (): BaseValues => {
    const note = folder.note || '';
    const measurements =
      folder.measurements && folder.measurements.length > 0 && folder.unit
        ? (castGqlForMeasurement(folder.measurements, folder.unit) as MeasurementsValues)
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any);

    return {
      category: folder.category?.id,
      alias: folder.partNoComputed,
      name: `Kopie von ${folder.name}`,
      note,
      approximate: folder.approximate,
      measurements,
    };
  };

  const handleSuccess = (value: string) => {
    setSelectedFolder(value);
    setRouteModalOpen(false);
  };

  const renderFolderCloneFormByType = () => {
    const { type, mode } = getOffer();

    if (type === OfferType.FRAMEWORK) {
      return (
        <OfferFolderCloneForFramework
          initialValues={initialValues()}
          parentId={parentId}
          offerId={offerId}
          onSuccess={handleSuccess}
          folderId={folderId}
        />
      );
    }

    return (
      <OfferFolderCloneForFreeform
        offerMode={mode}
        initialValues={initialValues()}
        parentId={parentId}
        offerId={offerId}
        onClickAliasRightIcon={() => {
          setAliasInfoModal(true);
        }}
        onSuccess={handleSuccess}
        folderId={folderId}
      />
    );
  };

  function renderTitle(f: Folder) {
    const { partNoTree } = f;

    return t('cloneOfferFolder.title', {
      partNoReference: `${partNoTree.join('.')}`,
      folderName: f.name,
    });
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {renderTitle(folder)}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">{renderFolderCloneFormByType()}</div>
      </div>

      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Dialog>
  );
};

export default OfferFolderClone;
