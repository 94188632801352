import { TypedDocumentNode, gql } from '@apollo/client';
import LogFragment from '../../../../../../containers/LogContainer/graphql/Log.fragment';
import {
  AcceptanceReportLogs,
  AcceptanceReportLogsVariables,
} from './__generated__/AcceptanceReportLog.query';

const AcceptanceReportLogsQuery: TypedDocumentNode<
  AcceptanceReportLogs,
  AcceptanceReportLogsVariables
> = gql`
  query AcceptanceReportLogs($id: ID!, $page: Int, $limit: Int) {
    acceptanceReport(id: $id) {
      id

      logs(page: $page, limit: $limit) {
        ...LogFragment
      }
    }
  }

  ${LogFragment}
`;

export default AcceptanceReportLogsQuery;
