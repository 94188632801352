import { gql } from '@apollo/client';

const RiskAssessmentFragment = gql`
  fragment RiskAssessment on RiskAssessment {
    id
    no
    name
    pdfPath
    status

    signatures {
      id
      type
    }

    viewerCanArchive
    viewerCanAssign
    viewerCanRemove
    viewerCanSendRiskAssessmentPDFEmail
    viewerCanSign
    viewerCanUpdate
  }
`;

export default RiskAssessmentFragment;
