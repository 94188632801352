import yup, { InferObjectToYupShape } from '../../../../yup';
import { PrecautionValues, SpecialPrecaution } from './values';

export const validationSchema = yup.object().shape<InferObjectToYupShape<PrecautionValues>>({
  precautions: yup
    .array()
    .defined()
    .default([])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when('specialPrecautions', (specialPrecautions: SpecialPrecaution[], schema: any) => {
      return specialPrecautions.length === 0 ? schema.required() : schema;
    }),
  specialPrecautions: yup.array<SpecialPrecaution>().defined().default([]),
});

export const defaultValue = (initialValues?: Partial<PrecautionValues>): PrecautionValues => {
  return {
    precautions: [],
    specialPrecautions: [],
    ...initialValues,
  };
};
