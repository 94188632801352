import { ApolloError, useQuery } from '@apollo/client';
import ConcernEditDetailQuery from '../../graphql/ConcernEditDetail.query';
import { ConcernEditDetail } from '../../graphql/__generated__/ConcernEditDetail.query';

const CONCERN_IMAGE_THUMBNAIL_WIDTH = 58 * 2;
const CONCERN_IMAGE_THUMBNAIL_HEIGHT = 48 * 2;

interface ConcernEditDetailQueryResponse {
  concern?: ConcernEditDetail['concern'];
  clientName?: string;
  loading: boolean;
  error?: ApolloError;
}

export default function useConcernEditDetail(concernId: string): ConcernEditDetailQueryResponse {
  const { data, loading, error } = useQuery(ConcernEditDetailQuery, {
    variables: {
      documentId: concernId,
      thumbnailHeight: CONCERN_IMAGE_THUMBNAIL_HEIGHT,
      thumbnailWidth: CONCERN_IMAGE_THUMBNAIL_WIDTH,
    },
  });

  return {
    concern: data?.concern ?? undefined,
    loading,
    error,
  };
}
