import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import ObstructionLogsQuery from '../../graphql/ObstructionLogsQuery';
import LogContainer, { Log } from '../../../../../containers/LogContainer';
import logsDateMapper from '../../../../../containers/LogContainer/LogContainer.utils';
import ErrorState from '../../../../../containers/ErrorState';

interface ObstructionLogsDetailProps {
  obstructionId: string;
}

export default function ObstructionLogsDetail({
  obstructionId,
}: ObstructionLogsDetailProps): JSX.Element {
  const [logs, setLogs] = useState<Log[]>([]);
  const { data, error, loading, refetch } = useQuery(ObstructionLogsQuery, {
    variables: { obstructionId, page: 1, limit: 10 },
  });

  useEffect(() => {
    if (!data || !data.obstruction?.logs) {
      return;
    }

    const rawData = data.obstruction.logs.logs;

    if (!rawData.length) {
      setLogs([]);
      return;
    }

    if (!logs.length) {
      setLogs([...data.obstruction.logs.logs]);
      return;
    }

    const currentPage = data.obstruction.logs.pagination.current;
    const result = currentPage > 1 ? logs : [];

    rawData.forEach((log) => {
      if (!result.find((f) => f.id === log.id)) {
        result.push(log);
      }
    });

    setLogs(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMoreDataClick = async () => {
    if (!data?.obstruction?.logs) {
      return;
    }

    await refetch({
      page: data.obstruction.logs.pagination.current + 1,
      limit: 10,
    });
  };

  const logsDateData = logsDateMapper(logs);
  const hasNextPage =
    !loading && !!data?.obstruction?.logs && data?.obstruction.logs?.pagination.hasNextPage;

  if (error) {
    return <ErrorState error={error} />;
  }

  return (
    <LogContainer
      loading={loading}
      logs={logsDateData}
      hasNextPage={hasNextPage}
      onMoreDataClick={handleMoreDataClick}
    />
  );
}
