import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DuplicateConstructionNote,
  DuplicateConstructionNoteVariables,
} from './__generated__/DuplicateConstructionNote.mutation';

const DuplicateConstructionNoteMutation: TypedDocumentNode<
  DuplicateConstructionNote,
  DuplicateConstructionNoteVariables
> = gql`
  mutation DuplicateConstructionNote($input: DuplicateConstructionNoteInput!) {
    duplicateConstructionNote(input: $input) {
      id
    }
  }
`;

export default DuplicateConstructionNoteMutation;
