import { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import RiskAssessmentSignDetailQuery from '../../graphql/RiskAssessmentSignDetail.query';
import { RiskAssessmentSignDetail } from '../../graphql/__generated__/RiskAssessmentSignDetail.query';
import RiskAssessmentSignGetEmployeeNamesQuery from '../../graphql/RiskAssessmentSignGetEmployeeNames.query';
import { RiskAssessmentSignatureType } from '../../../../../globalTypes';
import urlToBase64 from '../../../../../utils/urlToBase64';

type RiskAssessmentSignature = RiskAssessmentSignDetail['riskAssessment']['signatures'][number];

interface UseRiskAssessmentSignDetailResponse {
  riskAssessment?: RiskAssessmentSignDetail['riskAssessment'];
  viewerSignatureBase64?: string;
  managerSignature?: RiskAssessmentSignature;
  employeeSignature?: RiskAssessmentSignature;
  employeeNames?: string[];
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentSignDetail(
  riskAssessmentId: string,
): UseRiskAssessmentSignDetailResponse {
  const { data, loading, error } = useQuery(RiskAssessmentSignDetailQuery, {
    variables: {
      riskAssessmentId,
    },
  });

  const riskAssessment = data?.riskAssessment;
  const projectId = riskAssessment?.project.id;
  const signatures = riskAssessment?.signatures ?? [];

  const managerSignature = signatures.find(
    (signature) => signature.type === RiskAssessmentSignatureType.MANAGER,
  );

  const employeeSignature = signatures.find(
    (signature) => signature.type === RiskAssessmentSignatureType.EMPLOYEE,
  );

  const { data: employeeNamesData } = useQuery(RiskAssessmentSignGetEmployeeNamesQuery, {
    variables: {
      projectId: projectId || '',
    },
    skip: !projectId || !managerSignature,
  });

  const employeeNames = employeeNamesData?.employeesForProject
    ? employeeNamesData.employeesForProject.map((employee) => employee.name)
    : [];

  const viewerSignatureUrl = data?.viewer.signature ?? undefined;
  const [viewerSignatureBase64, setViewerSignatureBase64] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (viewerSignatureUrl) {
      urlToBase64(viewerSignatureUrl).then((result) => setViewerSignatureBase64(result));
    }
  }, [viewerSignatureUrl]);

  return {
    riskAssessment,
    viewerSignatureBase64,
    managerSignature,
    employeeSignature,
    employeeNames,
    loading,
    error,
  };
}
