import { useCallback, useEffect, useRef } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import useNavigator from '../../../../../hooks/useNavigator/useNavigator';
import useCanvas from '../../../hooks/useCanvas';
import useSelectedTool from '../../../hooks/useSelectedTool';
import { CreateStandardTextBoxParameters } from './textboxes/createStandardTextBox';
import { createFullBlockTextBox, createStandardTextBox } from './textboxes';
import { countTextBoxObjects } from './utils';

interface UseAddTextBox {
  addTextBox: () => void;
}

interface UseAddTextBoxParameters {
  createMode: boolean;
}

interface AddFullBlockTextBoxOptions {
  enterEditAfterAdded: boolean;
}

export default function useAddTextBox({ createMode }: UseAddTextBoxParameters): UseAddTextBox {
  const { iosDevice } = useNavigator();
  const { canvas, moveModeEnabled } = useCanvas();
  const { selectedTool, setSelectedTool } = useSelectedTool();
  const selectionCleared = useRef(false);

  const addFullBlockTextBox = useCallback(
    (options: AddFullBlockTextBoxOptions = { enterEditAfterAdded: true }) => {
      if (!canvas) {
        return;
      }

      const textBox = createFullBlockTextBox({
        canvasWidth: canvas.getWidth(),
      });

      canvas.add(textBox);
      textBox.centerH();
      canvas.setActiveObject(textBox);

      if (options.enterEditAfterAdded) {
        textBox.enterEditing();
      }
    },
    [canvas],
  );

  const addStandardTextBlock = useCallback(
    (args?: CreateStandardTextBoxParameters) => {
      if (!canvas) {
        return;
      }
      const textBox = createStandardTextBox(args || {});

      canvas.add(textBox);

      if (!args) {
        textBox.center();
      }

      canvas.setActiveObject(textBox);
      textBox.enterEditing();
    },
    [canvas],
  );

  function addTextBox() {
    if (!canvas) {
      return;
    }

    const textBoxCount = countTextBoxObjects(canvas.getObjects());

    if (textBoxCount === 0) {
      addFullBlockTextBox();
    } else {
      addStandardTextBlock();
    }
  }

  useEffect(() => {
    if (!canvas || !createMode) {
      return;
    }

    /**
     * Due to async useEffect execution
     * Wait for the next tick to ensure with fabric registered all events to their state.
     * So after the next tick, the fabric could observe the first block textbox on `object:added`
     */
    const ticker = setTimeout(() =>
      addFullBlockTextBox({
        enterEditAfterAdded: !iosDevice,
      }),
    );

    setSelectedTool('text');

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(ticker);
    };
  }, [canvas, createMode, setSelectedTool, addFullBlockTextBox, iosDevice]);

  const handleCanvasSelectionCleared = useCallback(() => {
    selectionCleared.current = true;
  }, [selectionCleared]);

  const handleCanvasMouseDown = useCallback(
    (event: fabric.IEvent) => {
      /*
       * The selectionCleared stands for detect to second click after any object blurred.
       * */
      if (!canvas || selectionCleared.current || moveModeEnabled || selectedTool || event.target) {
        selectionCleared.current = false;

        return;
      }

      /*
       * Many properties of the fabric.IEvent declared as optional property but they filling on every event fires.
       * The reason for these optional properties is can be undefined canvas possibility.
       * But we're guarantee the canvas to be defined.
       */
      const { y, x } = event.absolutePointer || {};

      addStandardTextBlock({
        top: y,
        left: x,
      });

      setSelectedTool('text');
      selectionCleared.current = false;
    },
    [canvas, moveModeEnabled, selectedTool, setSelectedTool, addStandardTextBlock],
  );

  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.on('mouse:down', handleCanvasMouseDown);
    canvas.on('selection:cleared', handleCanvasSelectionCleared);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.off('mouse:down', handleCanvasMouseDown);
      canvas.off('selection:cleared', handleCanvasSelectionCleared);
    };
  }, [canvas, handleCanvasMouseDown, handleCanvasSelectionCleared]);

  return {
    addTextBox,
  };
}
