import { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useNavigator from '../../../../../../hooks/useNavigator';

interface ImageToolMenuProps {
  onUploadImageButtonClick: () => void;
  onSelectImageButtonClick: () => void;
  onMenuButtonClick: () => void;
  loading: boolean;
}

export default function ImageToolMenu({
  onUploadImageButtonClick,
  onSelectImageButtonClick,
  onMenuButtonClick,
  loading,
}: ImageToolMenuProps): JSX.Element {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { tabletDevice, mobileDevice } = useNavigator();

  function handleMenuItemClick(handler: () => void) {
    return () => {
      setMenuOpen(false);

      handler();
    };
  }

  function onTriggerButtonClick() {
    setMenuOpen(!menuOpen);
    onMenuButtonClick();
  }

  return (
    <Menu
      open={menuOpen}
      onRequestClose={() => setMenuOpen(false)}
      placement="left-start"
      renderTrigger={(triggerProps) => (
        <Button
          {...triggerProps}
          contentClassName="u-display-flex u-align-items-center"
          onClick={onTriggerButtonClick}
          color={menuOpen ? 'warning' : 'default'}
          loading={loading}
        >
          <Icon name="photo-camera" size={24} />
        </Button>
      )}
    >
      <MenuItem
        leftSection={<Icon name="smartphone" color="primary" size={24} />}
        onClick={handleMenuItemClick(onUploadImageButtonClick)}
        disabled={!(tabletDevice || mobileDevice)}
      >
        <Text>{t('constructionNote.imageTool.menu.uploadImageButton')}</Text>
      </MenuItem>

      <MenuItem
        leftSection={<Icon name="photo" color="primary" size={24} />}
        onClick={handleMenuItemClick(onSelectImageButtonClick)}
      >
        <Text>{t('constructionNote.imageTool.menu.selectImageButton')}</Text>
      </MenuItem>
    </Menu>
  );
}
