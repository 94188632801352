import { FC, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import FolderForm from '../../containers/FolderForm';
import { BaseValues } from '../../containers/FolderForm/values';
import useOfferFolderCreateForFramework, {
  OfferFolderCreateForFrameworkProps,
} from './useOfferFolderCreateForFramework';
import ErrorState from '../../../../../containers/ErrorState';
import categoryMapper from '../../containers/categoryMapper';
import { FOLDER_CREATE_DATA } from './graphql';
import { FolderCreateData, FolderCreateDataVariables } from './__generated__/graphql';

interface Props {
  onSuccess: OfferFolderCreateForFrameworkProps['onSuccess'];
  parentId?: OfferFolderCreateForFrameworkProps['parentId'];
  offerId: OfferFolderCreateForFrameworkProps['offerId'];
  initialValues?: Partial<BaseValues>;
}

const OfferFolderCreateForFramework: FC<Props> = ({
  onSuccess,
  parentId,
  offerId,
  initialValues,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<FolderCreateData, FolderCreateDataVariables>(
    FOLDER_CREATE_DATA,
    {
      variables: {
        parentId,
        offerId,
      },
    },
  );
  const { createFolder, error } = useOfferFolderCreateForFramework({
    onSuccess,
    parentId,
    offerId,
  });

  if (!data || loading) {
    return <LayoutLoading />;
  }

  const { listAvailableCategoriesForFolderCreation } = data;

  const categories = categoryMapper(listAvailableCategoriesForFolderCreation);

  return (
    <Fragment>
      {error && <ErrorState error={error} />}

      <FolderForm
        framework
        categories={categories}
        parentId={parentId}
        onSubmit={createFolder}
        buttonText={t(
          parentId
            ? 'createOfferFolderForFramework.forSubgroup.submitButton'
            : 'createOfferFolderForFramework.forGroup.submitButton',
        )}
        buttonIcon="add"
        initialValues={initialValues}
      />
    </Fragment>
  );
};

export default OfferFolderCreateForFramework;
