export enum DriveSpecialDocumentMimeTypes {
  OBSTRUCTION = 'application/vnd.baumappe-apps.obstruction',
  CONCERN = 'application/vnd.baumappe-apps.concern',
  OVERTIME = 'application/vnd.baumappe-apps.overtime',
  OFFER = 'application/vnd.baumappe-apps.offer',
  ACCEPTANCE_REPORT = 'application/vnd.baumappe-apps.acceptance-report',
  RISK_ASSESSMENT = 'application/vnd.baumappe-apps.risk-assessment',
  CONSTRUCTION_NOTE = 'application/vnd.baumappe-apps.construction-note',
  QSST = 'application/vnd.baumappe-apps.qsst',
  SIV_BILLING_ATTACHMENT = 'application/vnd.baumappe-apps.siv-billing-attachment',
}
