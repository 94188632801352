import { ArraySchema, SchemaOf } from 'yup';
import { InCharge, InformationValues } from './values';
import { InChargeRole } from '../../../../globalTypes';
import yup, { InferObjectToYupShape } from '../../../../yup';
import i18n from '../../../../i18n';

const personInChargeSchema: SchemaOf<InCharge> = yup
  .object()
  .shape({
    fullName: yup.string().required(),
    phone: yup.string(),
    role: yup.mixed().oneOf(Object.values(InChargeRole)).required(),
  })
  .required();

const regionalSecurityManagerSchema: ArraySchema<typeof personInChargeSchema> = yup
  .array()
  .defined()
  .default([])
  .of(
    yup.object().shape({
      fullName: yup.string().required(),
      phone: yup.string(),
      role: yup.mixed().oneOf(Object.values(InChargeRole)).required(),
    }),
  );

const validationSchema = yup.object().shape<InferObjectToYupShape<InformationValues>>({
  date: yup.lazy(() =>
    yup
      .date()
      .typeError(i18n.t('validation.date.typeError'))
      .required()
      .max(new Date(), i18n.t('validation.date.future')),
  ),
  projectNr: yup.string(),
  customerName: yup.string(),
  description: yup.string().required(),
  securityManager: personInChargeSchema,
  regionalSecurityManagers: regionalSecurityManagerSchema,
  additionalRegionalSecurityManagers: yup
    .array()
    .defined()
    .default([])
    .when('regionalSecurityManagers', {
      is: (value: InCharge[]) => value.length === 1,
      then: yup.array().min(1).max(2),
    })
    .when('regionalSecurityManagers', {
      is: (value: InCharge[]) => value.length === 2,
      then: yup.array().min(2).max(2),
    })
    .of(
      yup.object().shape({
        fullName: yup.string(),
        phone: yup.string(),
        role: yup.mixed<InChargeRole>().oneOf(Object.values(InChargeRole)).required(),
      }),
    ),
  operationManager: yup.object().shape({
    fullName: yup.string(),
    phone: yup.string(),
    role: yup.mixed<InChargeRole>().oneOf(Object.values(InChargeRole)).required(),
  }),
  sideManager: personInChargeSchema,
  firstAider: personInChargeSchema,
});

export const personInChargeDefaultValue = ({ role }: { role: InChargeRole }): InCharge => {
  return {
    fullName: '',
    phone: '',
    role,
  };
};

export const defaultValue = (initialValues?: Partial<InformationValues>): InformationValues => {
  return {
    date: '',
    projectNr: '-',
    customerName: '-',
    description: '',
    securityManager: personInChargeDefaultValue({ role: InChargeRole.SECURITY_MANAGER }),
    firstAider: personInChargeDefaultValue({ role: InChargeRole.FIRST_AIDER }),
    operationManager: personInChargeDefaultValue({ role: InChargeRole.OPERATION_MANAGER }),
    regionalSecurityManagers: [],
    additionalRegionalSecurityManagers: [
      personInChargeDefaultValue({ role: InChargeRole.REGION_SECURITY_MANAGER }),
      personInChargeDefaultValue({ role: InChargeRole.REGION_SECURITY_MANAGER }),
    ],
    sideManager: personInChargeDefaultValue({ role: InChargeRole.SIDE_MANAGER }),
    ...initialValues,
  };
};

export default validationSchema;
