import { Fragment } from 'react';
import cx from 'classnames';

import Control, { SharedProps as ControlProps } from '../Control';

export interface SelectOption {
  disabled?: boolean;
  label: string;
  value?: string | number | SelectOption[];
  className?: string;
}

function displayLabelWithDepth(label: string, depth: number) {
  if (depth < 1) {
    return label;
  }

  // The space used below is a special character, it is different from the normal space.
  // We use it because, the normal space character does not display in the browser
  // when used it inside an option.
  //
  // Four-per-em Space:
  //   HTML Entity: &emsp14;
  //   Unicode: U+2005
  return label.padStart(label.length + depth * 4, ' ');
}

type SelectProps = Omit<JSX.IntrinsicElements['select'], 'disabled'>;

export type Props = SelectProps &
  Omit<ControlProps, 'rightIconName' | 'onRightIconClick'> & {
    placeholder?: string;
    options: SelectOption[];
    innerRef?: SelectProps['ref'];
  };

function renderOption(option: SelectOption, depth = 0) {
  const { label, value, disabled, className } = option;
  if (Array.isArray(value)) {
    return (
      <Fragment key={`${label}`}>
        <option disabled>{displayLabelWithDepth(label, depth)}</option>
        {value.map((item) => renderOption(item, depth + 1))}
      </Fragment>
    );
  }

  return (
    <option value={value} disabled={disabled} key={`${option.value}`} className={className}>
      {displayLabelWithDepth(label, depth)}
    </option>
  );
}

const Select = ({
  status,
  label,
  onFocus,
  onBlur,
  innerRef,
  className,
  innerClassName,
  placeholder,
  options,
  dataTestId,
  ...otherProps
}: Props): JSX.Element => (
  <Control
    status={status}
    rightIconName="arrow-drop-down"
    label={label}
    isFilled
    className={className}
  >
    {({ onElementFocus, onElementBlur, isElementDisabled, elementClassName }) => (
      <select
        data-testid={dataTestId}
        ref={innerRef}
        className={cx(elementClassName, innerClassName, 'c-form-control__control--select')}
        disabled={isElementDisabled}
        onFocus={(e) => {
          onElementFocus(e);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          onElementBlur(e);
          if (onBlur) {
            onBlur(e);
          }
        }}
        autoComplete="off"
        {...otherProps}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => renderOption(option))}
      </select>
    )}
  </Control>
);

export default Select;
