import { ApolloError, useQuery } from '@apollo/client';
import DriveDocumentTagDetailsQuery from './graphql/DriveDocumentTagDetails.query';
import { DriveDocumentTagDetails } from './graphql/__generated__/DriveDocumentTagDetails.query';

interface UseDriveDocumentTagDetailsResponse {
  driveDocument?: DriveDocumentTagDetails['driveDocument'];
  loading: boolean;
  error?: ApolloError;
}

export default function useDriveDocumentTagDetails(
  documentId: string,
): UseDriveDocumentTagDetailsResponse {
  const { data, loading, error } = useQuery(DriveDocumentTagDetailsQuery, {
    variables: {
      documentId,
    },
  });

  return {
    driveDocument: data?.driveDocument ?? undefined,
    loading,
    error,
  };
}
