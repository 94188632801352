import { gql, TypedDocumentNode } from '@apollo/client';
import { ObstructionSignDetail } from './__generated__/ObstructionSignDetail.query';

const ObstructionSignDetailQuery: TypedDocumentNode<ObstructionSignDetail> = gql`
  query ObstructionSignDetail {
    viewer {
      id

      signature
    }
  }
`;
export default ObstructionSignDetailQuery;
