import { FC } from 'react';
import { Details, Divider, Summary, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { QsstLogsDetail } from './QsstLogsDetail/QsstLogsDetail';

interface QsstLogsProps {
  qsstId: string;
}

const QsstLogs: FC<QsstLogsProps> = ({ qsstId }) => {
  const { t } = useTranslation();

  return (
    <Details
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="label-large">{t('documentLogsDetail.title')}</Text>
        </Summary>
      )}
    >
      <Divider role="none" />

      <QsstLogsDetail qsstId={qsstId} />
    </Details>
  );
};

export { QsstLogs };
