import { RefObject, useEffect, useRef, useState } from 'react';
import { createViewState, getDefaultToolbarItems, usePSPDFKit } from '@hs-baumappe/pspdfkit';
import { useMutation } from '@apollo/client';
import { Icon, IconButton, useSnackbar } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import SaveAnnotationsMutation from '../../graphql/SaveAnnotations.mutation';
import config from '../../../../../../config';
import { LayoutFullScreenTool } from './usePSPDFKitManipulator.utils';

interface UsePDFManipulatorResponse {
  containerRef: RefObject<HTMLDivElement>;
  save: () => Promise<string | undefined>;
  saving: boolean;
  hasUnsavedChanges: boolean;
  error?: Error;
}

interface UsePDFManipulatorOptions {
  documentId: string;
  token?: string;
  readOnly: boolean;
  viewerCanDownload: boolean;
  onFullScreenToolClick: () => void;
}

export default function usePSPDFKitManipulator({
  documentId,
  token,
  readOnly,
  viewerCanDownload,
  onFullScreenToolClick,
}: UsePDFManipulatorOptions): UsePDFManipulatorResponse {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { instanceRef, load, unload, error } = usePSPDFKit();
  const { open: openSnackbar, close: closeSnackbar } = useSnackbar();
  const [saveAnnotationsMutations] = useMutation(SaveAnnotationsMutation);
  const fullScreenToolbarItem = useRef(
    new LayoutFullScreenTool(i18n.language, onFullScreenToolClick),
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !token) {
      return;
    }

    const toolbarItems = [
      ...getDefaultToolbarItems({ enableHistory: true }),
      fullScreenToolbarItem.current.toTool(),
    ];
    const initialViewState = createViewState({
      readOnly,
      disableExport: !viewerCanDownload,
      disablePrinting: !viewerCanDownload,
    });

    load({
      container,
      documentId,
      baseUrl: config.PSPDFKIT_ASSET_URL,
      serverUrl: config.PSPDFKIT_SERVER_URL,
      locale: i18n.language.toLowerCase(),
      toolbarItems,
      initialViewState,
      authPayload: {
        jwt: token,
      },
      styleSheets: config.PSPDFKIT_STYLESHEETS,
      enableHistory: true,
      instant: false,
      autoSaveMode: 'DISABLED',
      printMode: 'EXPORT_PDF',
    }).then((instance) => {
      if (!instance) {
        return;
      }

      instance.addEventListener('document.saveStateChange', (event) =>
        setHasUnsavedChanges(event.hasUnsavedChanges),
      );
    });

    return () => {
      unload();
    };
  }, [documentId, i18n.language, load, readOnly, token, unload, viewerCanDownload]);

  async function save(): Promise<string | undefined> {
    if (!instanceRef.current) {
      return;
    }

    setSaving(true);

    try {
      await instanceRef.current.save();
      const result = await saveAnnotationsMutations({
        variables: {
          input: {
            documentId,
          },
        },
      });

      return result.data?.saveAnnotations.id;
    } catch (e) {
      openSnackbar(t('documentManipulate.pdf.savingError').toString(), {
        type: 'error',
        action: (snackbarId) => (
          <IconButton
            icon={<Icon name="close" />}
            color="inherit"
            onClick={() => closeSnackbar(snackbarId)}
          />
        ),
      });
    } finally {
      setSaving(false);
    }

    return;
  }

  return {
    containerRef,
    save,
    saving,
    hasUnsavedChanges,
    error,
  };
}
