import { ApolloError, useQuery } from '@apollo/client';
import ProfessionsQuery from './graphql/Professions.query';

interface UseProfessionsResponse {
  professions: string[];
  loading: boolean;
  error?: ApolloError;
}

export default function useProfessions(): UseProfessionsResponse {
  const { data, loading, error } = useQuery(ProfessionsQuery);

  return {
    professions: data?.professions ?? [],
    loading,
    error,
  };
}
