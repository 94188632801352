import { Equipment } from './graphql/__generated__/Equipment.fragment';
import { EquipmentSelection } from './values';

export function getRequiredEquipments(equipments: Equipment[]): Equipment[] {
  return equipments.filter((equipment) => equipment.required);
}

export function getRequiredEquipmentsIds(equipments: Equipment[]): string[] {
  return getRequiredEquipments(equipments).map((equipment) => equipment.id);
}

export function getInvalidEquipmentsIds(
  equipments: Equipment[],
  formEquipments: EquipmentSelection[],
): string[] {
  const requiredEquipmentsId = getRequiredEquipmentsIds(equipments);

  return requiredEquipmentsId.filter((equipmentId) =>
    formEquipments.some((equipment) => equipment.label === equipmentId),
  );
}
