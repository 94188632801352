import { ApolloError, useQuery } from '@apollo/client';
import QsstSaveProjectDetailQuery from '../../graphql/QsstSaveProjectDetail.query';
import { QsstSaveProjectDetail } from '../../graphql/__generated__/QsstSaveProjectDetail.query';
import DuplicatingQsstDetailQuery from '../../graphql/DuplicatingQsstDetail.query';
import { DuplicatingQsstDetail } from '../../graphql/__generated__/DuplicatingQsstDetail.query';

interface UseCreateQsstDetailOptions {
  projectId: string;
  duplicatingQsstId?: string;
}

interface UseCreateQsstDetailResponse {
  project?: QsstSaveProjectDetail['project'];
  duplicatingQsst?: NonNullable<DuplicatingQsstDetail['qsst']>;
  loading: boolean;
  error?: ApolloError;
}

export default function useCreateQsstDetail({
  projectId,
  duplicatingQsstId,
}: UseCreateQsstDetailOptions): UseCreateQsstDetailResponse {
  const { data, loading, error } = useQuery(QsstSaveProjectDetailQuery, {
    variables: {
      projectId,
    },
  });

  const project = data?.project;

  const {
    data: duplicatingQsstData,
    loading: duplicatingQsstLoading,
    error: duplicatingQsstError,
  } = useQuery(DuplicatingQsstDetailQuery, {
    variables: {
      qsstId: duplicatingQsstId!,
    },
    skip: !duplicatingQsstId,
  });

  const duplicatingQsst = duplicatingQsstData?.qsst ?? undefined;

  return {
    project,
    duplicatingQsst,
    loading: loading || duplicatingQsstLoading,
    error: error || duplicatingQsstError,
  };
}
