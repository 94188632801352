import { lazy, ReactNode, Suspense } from 'react';
import ErrorBoundary from './ErrorBoundary';

// eslint-disable-next-line import/no-unresolved
const BulkActionsManagerProvider = lazy(() => import('driveWeb/BulkActionsProvider'));

interface DriveProvidersProps {
  children: ReactNode;
}

function DriveProviders({ children }: DriveProvidersProps): JSX.Element {
  return (
    <ErrorBoundary fallback={children}>
      <Suspense fallback={null}>
        <BulkActionsManagerProvider>{children}</BulkActionsManagerProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default DriveProviders;
