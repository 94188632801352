import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { OfferFolderOperationCreateFromTlkRouteParams } from './route';
import CreateNewOperationFromTLKForm from './CreateNewOperationFromTLKForm';
import useFolderOperationCreateFromTlk from './useFolderOperationCreateFromTlk';
import useOffer from '../../hooks/offer';
import { castGqlForMeasurement } from '../../folder/containers/FolderForm/form';
import { MeasurementsValues } from '../../measurement/MeasurementsSection/values';
import { initialMeasurementValues } from '../../measurement/MeasurementSection/values';
import { GET_CATALOG_OPERATION } from './graphql';
import { GetCatalogOperation, GetCatalogOperationVariables } from './__generated__/graphql';
import { OperationValues } from './CreateNewOperationFromTLKForm/values';
import ErrorState from '../../../../containers/ErrorState';
import { OfferMode } from '../../../../globalTypes';
import { pad } from '../../folder/OfferFolderCreate/offerFolderCreate.utils';
import InfoModal from '../../components/InfoModal';
import { offerRoutes } from '../../offerRoutes';
import { OfferLocationState } from '../../Offer.route.types';

const OfferFolderOperationCreateFromTlk = (): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId, folderId, operationId } =
    useParams<OfferFolderOperationCreateFromTlkRouteParams>();
  const { getFolderById, selectedCatalogueOperation, getOffer } = useOffer();
  const { mode } = getOffer();

  const { create, error } = useFolderOperationCreateFromTlk({
    offerId,
    folderId,
    offerMode: mode,
  });
  const { data, loading } = useQuery<GetCatalogOperation, GetCatalogOperationVariables>(
    GET_CATALOG_OPERATION,
    {
      variables: {
        operationId,
      },
    },
  );

  const [aliasInfoModal, setAliasInfoModal] = useState(false);
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location.state?.backgroundLocation, navigate, offerId]);

  const folder = getFolderById(folderId);
  if (!folder) {
    return null;
  }

  const initialValues = (getCatalogOperation: GetCatalogOperation): Partial<OperationValues> => {
    const { operation } = getCatalogOperation;

    return {
      alias: pad(folder.operations.length + 1, 4),
      name: operation.name || undefined,
      price: operation.price || undefined,
      description: operation.description || undefined,
      unit: operation.unit || undefined,
      measurements:
        // eslint-disable-next-line no-nested-ternary
        folder.measurements && folder.unit
          ? (castGqlForMeasurement(
              folder.measurements,
              folder.unit,
              operation.unit || undefined,
            ) as MeasurementsValues)
          : operation.unit
            ? ([
                {
                  type: operation.unit,
                  values: {
                    ...initialMeasurementValues(),
                    name: 'Menge Nr. 1',
                  },
                },
              ] as never)
            : ([] as never),
    };
  };

  function renderTitle() {
    if (!selectedCatalogueOperation) {
      return null;
    }
    return `${selectedCatalogueOperation.partNoTree.join('.')} ${selectedCatalogueOperation.name}`;
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}

      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {renderTitle()}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          {loading && <LayoutLoading />}
          {data && !loading && (
            <CreateNewOperationFromTLKForm
              offerModeSystemNumber={mode === OfferMode.SYSTEM_NUMBER}
              onClickAliasRightIcon={() => setAliasInfoModal(true)}
              onSubmit={(v) => create(v, data?.operation)}
              operationUnit={data.operation.unit || undefined}
              initialValues={initialValues(data)}
            />
          )}
        </div>
      </div>

      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Dialog>
  );
};

export default OfferFolderOperationCreateFromTlk;
