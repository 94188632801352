import { PropsWithChildren } from 'react';
import cx from 'classnames';
import { Icon, Text } from '@hs-baumappe/legacy-ui';

import './c-card-checkbox.scss';

export type CardCheckboxProps = JSX.IntrinsicElements['input'] & {
  label: string;
  imageSrc?: JSX.IntrinsicElements['img']['src'];
  invalid?: boolean;
};

export default function CardCheckbox({
  className,
  imageSrc,
  label,
  invalid,
  ...otherProps
}: PropsWithChildren<CardCheckboxProps>): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={cx('c-card-checkbox', className)}>
      <input
        className={cx('c-card-checkbox__input', { 'is-invalid': invalid })}
        type="checkbox"
        aria-label={label}
        data-testid="input"
        {...otherProps}
      />
      <div className="c-card-checkbox__mask" aria-hidden="true">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        {imageSrc && <img className="c-card-checkbox__image" src={imageSrc} data-testid="image" />}
        <Text component="div" className="c-card-checkbox__label" align="center">
          {label}
        </Text>
        <Icon className="c-card-checkbox__checkmark" name="check" />
      </div>
    </label>
  );
}
