import { ReactNode, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';
import { FormikHelpers } from 'formik/dist/types';
import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Box, Button, Divider, Icon, Text } from '@hs-baumappe/legacy-ui';

import InformationFormSection from './ConcernFormFields/InformationFormSection';
import ExecutionFormSection from './ConcernFormFields/ExecutionFormSection';
import QualityFormSection from './ConcernFormFields/QualityFormSection';
import ContractorsFormSection from './ConcernFormFields/ContractorsFormSection';
import ImageAttachmentFormSection from './ConcernFormFields/ImageAttachmentFormSection';
import { ConcernFormValues } from './ConcernForm.types';
import { concernFormValidationSchema } from './concernFormValidationSchema';
import { createConcernFormInitialValues } from './ConcernForm.utils';

interface ConcernFormProps {
  initialValues?: Partial<ConcernFormValues>;
  projectId: string;
  draft: boolean;
  professions: string[];
  submitButtonText: ReactNode;
  onSubmit: (values: ConcernFormValues, formikHelpers: FormikHelpers<ConcernFormValues>) => void;
  loading?: boolean;
  onDirtyStateChange: (dirty: boolean) => void;
}

export default function ConcernForm({
  initialValues,
  projectId,
  draft,
  professions,
  submitButtonText,
  onSubmit,
  loading,
  onDirtyStateChange,
}: ConcernFormProps): JSX.Element {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: createConcernFormInitialValues(initialValues),
    validationSchema: concernFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit,
  });

  const { values, dirty } = formik;

  useEffect(() => {
    onDirtyStateChange(dirty);
  }, [onDirtyStateChange, dirty]);

  const rawEndDate = values.endDate;

  const key = rawEndDate
    ? 'concern.create.form.deadline.date.info.fill'
    : 'concern.create.form.deadline.date.info.empty';

  const endDate = rawEndDate && format(new Date(rawEndDate), 'dd.MM.yyyy');

  function getEndDateTranslationComponent() {
    if (!endDate) {
      return (
        <Text key="concern-create-form-deadline-date-info-empty" color="muted" variant="inherit" />
      );
    }

    return <strong key="concern-create-form-deadline-date-info-fill" />;
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box className="row">
          <Box className="col col--md-8">
            <InformationFormSection professions={professions} draft={draft} />

            <Divider component="hr" role="none" />

            <Box className="row">
              <Box className="col">
                <Text component="div" variant="title-small" color="gray-700">
                  {t('concern.create.form.misgivings.title')}
                </Text>

                <Text component="div" color="gray-700" stylex={{ marginTop: 'small-x' }}>
                  {t('concern.create.form.misgivings.info')}
                </Text>

                <ExecutionFormSection />

                <QualityFormSection />

                <ContractorsFormSection />
              </Box>
            </Box>

            <Divider component="hr" />

            <ImageAttachmentFormSection projectId={projectId} />

            <Divider component="hr" />

            <Box className="row">
              <Box className="col col--sm-6">
                <Text component="div" color="gray-700" variant="title-small">
                  {t('concern.create.form.deadline.title')}
                </Text>

                <FormikFormField
                  type="date"
                  name="endDate"
                  label={t('concern.create.form.deadline.date.label')}
                  className="u-margin-top-small"
                  placeholder={t('concern.create.form.deadline.date.placeholder')}
                />
              </Box>
            </Box>
            <Text
              component="div"
              color="gray-700"
              stylex={{ marginTop: 'small-x', marginBottom: 'large-x' }}
            >
              <Trans
                i18nKey={key}
                components={[getEndDateTranslationComponent()]}
                tOptions={endDate ? { endDate } : undefined}
              />
            </Text>
          </Box>
          <Box className="col col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                endIcon={<Icon name="add" />}
                loading={loading}
                fullWidth
              >
                {submitButtonText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
