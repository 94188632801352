import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Dialog, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useArchiveConstructionNote from './useArchiveConstructionNote';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import {
  ConstructionNoteLocationState,
  ConstructionNoteRouteParams,
} from '../ConstructionNote.route.types';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';

export default function ConstructionNoteArchive(): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const location = useLocation<ConstructionNoteLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(constructionNoteId);
  const { archive, loading: archiving, error } = useArchiveConstructionNote({ constructionNoteId });

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }));
  }, [navigate, location.state, constructionNoteId]);

  if (!archiveEnabled) {
    return <Navigate to={constructionNoteRoutes.detail.generatePath({ constructionNoteId })} />;
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = driveDocument
    ? [
        {
          id: driveDocument.id,
          name: driveDocument.name,
          size:
            typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
        },
      ]
    : undefined;

  return (
    <Dialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}

      <Box className="container" stylex={{ width: '100%', paddingEnds: 'large' }}>
        <Flex alignItems="center">
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0', flexGrow: '1' }}>
            {t('constructionNote.archive.title')}
          </Text>

          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </Flex>

        <Box stylex={{ paddingTop: 'large' }}>
          <ArchiveForm
            leftSection={<Text variant="body-small">{t('archiveModals.informationText')}</Text>}
            rightSection={<Text variant="body-small">{t('archiveModals.warningText')}</Text>}
            initialValues={{
              username: sapCredentials?.username,
              password: sapCredentials?.password,
              documents,
            }}
            onDocumentsValueChange={handleDialogRequestClose}
            onSubmit={archive}
            loading={archiving}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
