import { SchemaOf } from 'yup';
import yup from '../../../../../../../../yup';
import ObstructionFormFactValue from './ObstructionFormFact.type';

const obstructionFormFactValidation: SchemaOf<ObstructionFormFactValue> = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
});

export default obstructionFormFactValidation;
