import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ProjectStatus } from '../../../../../globalTypes';
import ProjectFilterTargetedProjectsQuery from './graphql/ProjectFilterTargetedProjects.query';
import { SelectedFilters } from '../ProjectFilter.types';

interface UseProjectFilterTargeterResponse {
  matchCount: number;
  statuses: SelectedFilters['status'];
  profitCenters: SelectedFilters['profitCenter'];
  loading: boolean;
  changeTargets: (projectStatuses: ProjectStatus[], profitCenterIds: string[]) => void;
}

export default function useProjectFilterTargeter(): UseProjectFilterTargeterResponse {
  const [fetchTargetedProjects, { data, loading }] = useLazyQuery(
    ProjectFilterTargetedProjectsQuery,
  );

  const changeTargets = useCallback(
    (projectStatuses: ProjectStatus[], profitCenterIds: string[]) => {
      fetchTargetedProjects({
        variables: {
          includeStatus: projectStatuses,
          profitCenters: profitCenterIds,
        },
      });
    },
    [fetchTargetedProjects],
  );

  const meta = data?.projects.meta;
  const matchCount = meta?.totalCount || 0;
  const statuses = meta?.selectedFilters.status || [];
  const profitCenters = meta?.selectedFilters.profitCenter || [];

  return {
    matchCount,
    statuses,
    profitCenters,
    loading,
    changeTargets,
  };
}
