import { lazy, Suspense } from 'react';
import { Alert, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import ErrorBoundary from '../../../../ErrorBoundary';

// eslint-disable-next-line import/no-unresolved
const DriveDetails = lazy(() => import('driveWeb/Details'));

export default function DriveWebContainer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <ErrorBoundary
      fallback={
        <Alert color="error">
          <Text>{t('driveMFLoadError')}</Text>
        </Alert>
      }
    >
      <Suspense fallback={<LayoutLoading />}>
        <DriveDetails />
      </Suspense>
    </ErrorBoundary>
  );
}
