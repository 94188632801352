import { useState } from 'react';
import { createPath, useLocation, useNavigate } from 'react-router-dom';
import { FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';
import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import ConcernDetailHeader from './components/ConcernDetailHeader';
import ConcernDetailAlert from './components/ConcernDetailAlert';
import PDFViewer from '../../../components/PDFViewer';
import ConcernDetailSidebarTop from './components/ConcernDetailSidebarTop';
import ConcernDetailSidebarBottom from './components/ConcernDetailSidebarBottom/ConcernDetailSidebarBottom';
import ConcernLogs from './components/ConcernLogs';
import SendConcernPdfDialog from './components/SendConcernPdfDialog';
import DuplicateConcernDialog from './components/DuplicateConcernDialog';
import { projectRoutes } from '../../routes';
import { concernRoutes } from '../concernRoutes';
import { ConcernLocationState } from '../Concern.route.types';
import { createConcernLocation } from '../Concern.utils';
import { Concern } from '../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../graphql/__generated__/ConcernProject.fragment';
import { ConcernScopes } from '../Concern.types';

interface ConcernDetailProps {
  concern: Concern;
  project: ConcernProject;
  scopes: ConcernScopes;
}

export default function ConcernDetail({
  concern,
  project,
  scopes,
}: ConcernDetailProps): JSX.Element {
  const location = useLocation<ConcernLocationState>();
  const navigate = useNavigate();

  const [showHasNotSignedDialog, setShowHasNotSignedDialog] = useState(false);
  const [showDuplicationDialog, setShowDuplicationDialog] = useState(false);

  const { name, pdfPath, signatures, viewerCanUpdate } = concern;

  const signed = signatures.length > 0;
  const downloadPermitted = scopes.downloadConcernDocument.isPermitted;

  const { parentFolders } = useDriveDocumentParentFolders(concern.id);

  function handleEditButtonClick() {
    if (!viewerCanUpdate) {
      setShowDuplicationDialog(true);
      return;
    }

    navigate(concernRoutes.edit.generatePath({ concernId: concern.id }), {
      state: {
        backgroundLocation: location,
      },
    });
  }

  function handleSendEmailButtonClick() {
    if (!signed) {
      setShowHasNotSignedDialog(true);
      return;
    }

    navigate(concernRoutes.sendEmail.generatePath({ concernId: concern.id }), {
      state: createConcernLocation(location),
    });
  }

  function handleDownloadButtonClick() {
    if (!pdfPath) {
      return;
    }

    window.open(pdfPath, 'blank');
  }

  function handleRemoveSuccess() {
    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId: project.id,
        }),
        search: `?removedDocumentName=${name}`,
      }),
    );
  }

  function handleSignNowClick() {
    setShowHasNotSignedDialog(false);

    navigate(concernRoutes.sign.generatePath({ concernId: concern.id }));
  }

  function handleSendWithoutSignatureClick() {
    setShowHasNotSignedDialog(false);

    navigate(concernRoutes.sendEmail.generatePath({ concernId: concern.id }));
  }

  function handleDuplicationSuccess(duplicatedConcernId: string) {
    setShowDuplicationDialog(false);

    navigate(concernRoutes.detail.generatePath({ concernId: duplicatedConcernId }));
  }

  return (
    <PageLayoutWithStickySidebar
      header={
        <ConcernDetailHeader
          name={concern.name}
          status={concern.status}
          project={project}
          parentFolders={parentFolders}
        />
      }
      sidebarTop={
        <ConcernDetailSidebarTop
          concern={concern}
          scopes={scopes}
          onEditButtonClick={handleEditButtonClick}
          onSendEmailButtonClick={handleSendEmailButtonClick}
          onDownloadButtonClick={handleDownloadButtonClick}
        />
      }
      sidebarBottom={
        <ConcernDetailSidebarBottom
          concern={concern}
          projectId={project.id}
          scopes={scopes}
          onRemoveSuccess={handleRemoveSuccess}
        />
      }
      footer={
        <FlexGrid>
          <FlexGridColumn sm={8}>
            <ConcernLogs concernId={concern.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <ConcernDetailAlert />

        {pdfPath && (
          <PDFViewer document={pdfPath} downloadPermitted={downloadPermitted} key={pdfPath} />
        )}
      </Stack>

      <SendConcernPdfDialog
        open={showHasNotSignedDialog}
        onSignNowClick={handleSignNowClick}
        onSendWithoutSignatureClick={handleSendWithoutSignatureClick}
        onRequestClose={() => setShowHasNotSignedDialog(false)}
      />

      <DuplicateConcernDialog
        open={showDuplicationDialog}
        concernId={concern.id}
        onRequestClose={() => setShowDuplicationDialog(false)}
        onSubmitSuccess={handleDuplicationSuccess}
      />
    </PageLayoutWithStickySidebar>
  );
}
