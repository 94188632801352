import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { MissingScopeError } from '@hs-baumappe/redkit';
import RouteDialog from '../../../containers/RouteDialog';
import RiskAssessmentSignBody from './components/RiskAssessmentSignBody';
import { riskAssessmentRoutes } from '../riskAssessmentRoutes';
import { RiskAssessmentLocationState } from '../RiskAssessment.route.type';
import { RiskAssessment } from '../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentScopes } from '../RiskAssessmentDetail/types';
import { isRiskAssessmentSignable } from '../RiskAssessment.utils';

interface RiskAssessmentSignProps {
  riskAssessment: RiskAssessment;
  scopes: RiskAssessmentScopes;
}

export default function RiskAssessmentSign({
  riskAssessment,
  scopes,
}: RiskAssessmentSignProps): JSX.Element {
  const location = useLocation<RiskAssessmentLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);

  const { t } = useTranslation();

  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setShowConfirmationDialog(true);
      return;
    }

    setDialogOpen(false);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(riskAssessmentRoutes.detail.generatePath({ riskAssessmentId: riskAssessment.id }));
  }, [location.state, navigate, riskAssessment]);

  function handleConfirmationDialogConfirm() {
    setShowConfirmationDialog(false);
    setDialogOpen(false);
  }

  const permitted = scopes.signRiskAssessmentDocument.isPermitted;
  const missingScopes = scopes.signRiskAssessmentDocument.missingScopeNames;
  const signable = isRiskAssessmentSignable({
    riskAssessment,
    signPermitted: permitted,
  });

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('signRiskAssessmentModal.title', { riskAssessmentNo: riskAssessment.no })}
        </Text>
      }
    >
      {!signable ? (
        <MissingScopeError missingScopes={missingScopes} />
      ) : (
        <RiskAssessmentSignBody
          riskAssessmentId={riskAssessment.id}
          managerSignPermitted={permitted}
          onFormDirty={() => setFormDirty(true)}
        />
      )}

      <AlertDialog
        open={showConfirmationDialog}
        title={t('signRiskAssessmentModal.confirmationModal.title')}
        description={t('signRiskAssessmentModal.confirmationModal.content')}
        confirmButtonText={t('signRiskAssessmentModal.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('signRiskAssessmentModal.confirmationModal.cancelButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setShowConfirmationDialog(false)}
        onRequestClose={() => setShowConfirmationDialog(false)}
        destructive
      />
    </RouteDialog>
  );
}
