import { ApolloError, useMutation } from '@apollo/client';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UPLOAD_CATALOGUE_MUTATION } from './graphql';
import { CreateNewOfferFormValues } from './CreateNewOfferForm/values';
import { OfferMode, OfferType } from '../../../globalTypes';
import { UploadOfferCatalogue, UploadOfferCatalogueVariables } from './__generated__/graphql';
import useOfferFrameworkSave from './useOfferFrameworkSave';
import useOfferFreeformSave from './useOfferFreeformSave';
import { offerRoutes } from '../../Offer/offerRoutes';

interface UseCreateNewOffer {
  createOffer: (projectId: string, values: CreateNewOfferFormValues) => void;
  error?: ApolloError;
  loading: boolean;
}

const useCreateNewOffer = (documentId?: string): UseCreateNewOffer => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [offerFrameworkSave, { error: FrameworkError, loading: FrameworkLoading }] =
    useOfferFrameworkSave();
  const [offerFreeformSave, { error: FreeformError, loading: FreeformLoading }] =
    useOfferFreeformSave();
  const [uploadCatalogueMutation] = useMutation<
    UploadOfferCatalogue,
    UploadOfferCatalogueVariables
  >(UPLOAD_CATALOGUE_MUTATION);

  const error = FrameworkError || FreeformError;
  const loading = FrameworkLoading || FreeformLoading;

  const createOffer = async (projectId: string, values: CreateNewOfferFormValues) => {
    if (!documentId) return;

    if (values.type === OfferType.FRAMEWORK) {
      if (!values.catalogueId && values.catalogueFile) {
        const uploadedCatalogue = await uploadCatalogueMutation({
          variables: { file: values.catalogueFile },
        });

        if (!uploadedCatalogue.data) {
          // Handle Error
          return;
        }

        const { data } = await offerFrameworkSave({
          variables: {
            input: {
              meta: {
                draftClient: values.client,
                draftConstructionSite: values.constructionSite,
              },
              name: values.name,
              mode: OfferMode.SYSTEM_NUMBER,
              documentId,
              catalogueId: uploadedCatalogue.data.uploadCatalogue.id,
            },
          },
        });

        if (!data) {
          alert(t('common.error'));
          return;
        }

        navigate(offerRoutes.detail.generatePath({ offerId: data.offerFrameworkSave.id }));
      }

      if (values.catalogueId) {
        const { data } = await offerFrameworkSave({
          variables: {
            input: {
              meta: {
                draftClient: values.client,
                draftConstructionSite: values.constructionSite,
              },
              name: values.name,
              mode: OfferMode.SYSTEM_NUMBER,
              documentId,
              catalogueId: values.catalogueId,
            },
          },
        });

        if (!data) {
          alert(t('common.error'));
          return;
        }

        navigate(offerRoutes.detail.generatePath({ offerId: data.offerFrameworkSave.id }));
      }
    }

    const { data } = await offerFreeformSave({
      variables: {
        input: {
          meta: {
            draftClient: values.client,
            draftConstructionSite: values.constructionSite,
          },
          mode: OfferMode.SYSTEM_NUMBER,
          documentId,
          name: values.name,
        },
      },
    });

    if (!data) {
      alert(t('common.error'));
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId: data.offerFreeFormSave.id }));
  };

  return { createOffer, error, loading };
};

export default useCreateNewOffer;
