import { useMutation } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';

import { OfferFreeformSave as OfferFreeformSaveQuery } from './graphql';
import { OfferFreeformSave, OfferFreeformSaveVariables } from './__generated__/graphql';

export default function useOfferFreeformSave(): MutationTuple<
  OfferFreeformSave,
  OfferFreeformSaveVariables
> {
  return useMutation<OfferFreeformSave, OfferFreeformSaveVariables>(OfferFreeformSaveQuery);
}
