import { TypedDocumentNode, gql } from '@apollo/client';
import { Professions, ProfessionsVariables } from './__generated__/Professions.query';

const ProfessionsQuery: TypedDocumentNode<Professions, ProfessionsVariables> = gql`
  query Professions {
    professions
  }
`;

export default ProfessionsQuery;
