import { gql, TypedDocumentNode } from '@apollo/client';
import {
  AssignableProjectList,
  AssignableProjectListVariables,
} from './__generated__/AssignableProjectList.query';

export const AssignableProjectListQuery: TypedDocumentNode<
  AssignableProjectList,
  AssignableProjectListVariables
> = gql`
  query AssignableProjectList($term: String, $excludeDraft: Boolean, $page: Int, $limit: Int) {
    projects(term: $term, excludeDraft: $excludeDraft, page: $page, limit: $limit) {
      projects {
        id
        projectNumber
        name
      }

      pagination {
        current
        hasNextPage
      }
    }
  }
`;
