import { ComponentProps } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';

type Props = ComponentProps<typeof Text<'label'>>;

const FormLabel = ({ children, htmlFor, ...otherProps }: Props): JSX.Element => (
  <Text
    stylex={{ display: 'inline-block' }}
    component="label"
    htmlFor={htmlFor}
    color="gray-700"
    {...otherProps}
  >
    {children}
  </Text>
);

export default FormLabel;
