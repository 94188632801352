import { createContext, ReactNode, useState } from 'react';

export type SelectedTool = 'text' | 'pen' | 'image' | 'eraser';

export interface SelectedToolContextType {
  selectedTool?: SelectedTool;
  setSelectedTool: (next: SelectedTool | undefined) => void;
}

export const SelectedToolContext = createContext<SelectedToolContextType>({
  selectedTool: undefined,
  setSelectedTool: () => {},
});

interface SelectedToolContextProviderProps {
  children: ReactNode;
}

export function SelectedToolContextProvider({
  children,
}: SelectedToolContextProviderProps): JSX.Element {
  const [selectedTool, setSelectedTool] = useState<SelectedTool | undefined>(undefined);

  return (
    <SelectedToolContext.Provider value={{ selectedTool, setSelectedTool }}>
      {children}
    </SelectedToolContext.Provider>
  );
}
