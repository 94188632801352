import { PropsWithChildren, ReactNode } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';

interface AcceptanceReportFormSectionProps {
  title: ReactNode;
  className?: string;
}

export default function AcceptanceReportFormSection({
  title,
  className,
  children,
}: PropsWithChildren<AcceptanceReportFormSectionProps>): JSX.Element {
  return (
    <div className={className}>
      <Text component="div" variant="label-large" color="muted">
        {title}
      </Text>
      <div className="u-margin-top">{children}</div>
      <hr />
    </div>
  );
}
