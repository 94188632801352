import { SearchedProject } from './__generated__/graphql';
import { ProjectFilterSelection } from './ProjectFilter.types';

export function getFilterCountFromMeta(meta: SearchedProject['projects']['meta']): number {
  const profitCenterWithProjectCount = meta.selectedFilters.profitCenter.filter(
    (prc) => prc.count > 0,
  ).length;

  const statusesWithProjectCount = meta.selectedFilters.status.filter(
    (status) => status.count > 0,
  ).length;

  return profitCenterWithProjectCount + statusesWithProjectCount;
}

export function getFilterCountFromSelection(selection: ProjectFilterSelection): number {
  const statusCount = selection.statuses.reduce(
    (result, statusFilter) => (statusFilter.count > 0 ? result + 1 : result),
    0,
  );

  const profitCenterCount = selection.profitCenters.reduce(
    (result, profitCenterFilter) => (profitCenterFilter.count > 0 ? result + 1 : result),
    0,
  );

  return statusCount + profitCenterCount;
}
