import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import { useQsstArchive } from './useQsstArchive';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm/ArchiveForm';
import { Qsst } from '../graphql/__generated__/Qsst.fragment';
import { QsstProject } from '../graphql/__generated__/QsstProject.fragment';
import { qsstRoutes } from '../qsstRoutes';
import { QsstLocationState } from '../Qsst.route.types';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';

interface QsstArchiveProps {
  qsst: Qsst;
  project: QsstProject;
}

export function QsstArchive({ qsst, project }: QsstArchiveProps): JSX.Element {
  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const { t } = useTranslation();

  const location = useLocation<QsstLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(qsst.id);
  const { archive, loading: archiving, error } = useQsstArchive(qsst.id);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(qsstRoutes.detail.generatePath({ qsstId: qsst.id }));
  }, [navigate, location.state, qsst.id]);

  if (!qsst.viewerCanArchive || !archiveEnabled) {
    return <Navigate to={qsstRoutes.detail.generatePath({ qsstId: qsst.id })} replace />;
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = [
    {
      id: qsst.id,
      name: qsst.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" className="u-margin-bottom-0">
            {t('qsstArchive.modalTitle', { qsstName: project.description })}
          </Text>
        </>
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('qsstArchiveForm.description')}</Text>}
        rightSection={<Text variant="body-small">{t('qsstArchiveForm.infoText')}</Text>}
        initialValues={{
          username: sapCredentials?.username,
          password: sapCredentials?.password,
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
