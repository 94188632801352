import { ApolloError, useMutation } from '@apollo/client';
import { UPDATE_FOLDER_OPERATION_MUTATION } from './graphql';
import { UpdateFolderOperation, UpdateFolderOperationVariables } from './__generated__/graphql';
import { OperationValues } from './EditOperationForm/values';
import {
  cast,
  clearEmptyTotal,
  createFlatRateMeasurmentValue,
} from '../../measurement/MeasurementSection/values';
import { OfferMode, OperationUnit } from '../../../../globalTypes';
import { FolderOperation } from '../../Detail/types';
import { castNote } from '../../folder/containers/FolderForm/form';
import { castPaymentToAddOperationToFolder } from '../PaymentForm/paymentMutationValidationSchema';
import { GET_OFFER_DATA } from '../../Detail/graphql';
import { validationSchema } from './EditOperationForm/form';

interface OfferFolderOperationEditProps {
  offerId: string;
  offerMode: OfferMode;
}

interface UseOfferFolderOperationEditParams {
  error?: ApolloError;
  edit: (v: OperationValues, o: FolderOperation) => Promise<void>;
}

const useOfferFolderOperationEdit = ({
  offerId,
  offerMode,
}: OfferFolderOperationEditProps): UseOfferFolderOperationEditParams => {
  const [mutation, { loading, error }] = useMutation<
    UpdateFolderOperation,
    UpdateFolderOperationVariables
  >(UPDATE_FOLDER_OPERATION_MUTATION);

  const edit = async (values: OperationValues, operation: FolderOperation) => {
    if (loading) {
      return;
    }

    const v = validationSchema().validateSync(values);
    if (!v) {
      return;
    }

    const { measurements } = values;

    let castedValues = clearEmptyTotal(cast(measurements));

    if (castedValues.length === 0 && operation.unit === OperationUnit.FLAT_RATE) {
      castedValues = [createFlatRateMeasurmentValue()];
    }

    await mutation({
      variables: {
        input: {
          partNoAlias: offerMode === OfferMode.ALIAS_NUMBER ? values.alias : undefined,
          operation: operation.id,
          name: v.name,
          description: v.description,
          price: v.price,
          approximate: v.approximate,
          note: castNote(values.note),
          unit: (v.unit as OperationUnit) || operation.unit,
          payment: castPaymentToAddOperationToFolder(v.payment),
          measurements: castedValues || [],
          optional: v.optional,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });
  };

  return {
    error,
    edit,
  };
};

export default useOfferFolderOperationEdit;
