import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import ObstructionSendEmailModalTitle from './components/ObstructionSendEmailModalTitle';
import ObstructionSendEmailBody from './components/ObstructionSendEmailBody';
import obstructionRoutes from '../obstructionRoutes';
import { ObstructionLocationState } from '../obstruction.route.types';
import RouteDialog from '../../../containers/RouteDialog';
import { Obstruction } from '../graphql/__generated__/Obstruction.fragment';
import { ObstructionProject } from '../graphql/__generated__/ObstructionProject.fragment';
import { ObstructionScopes } from '../Obstruction.types';

interface ObstructionSendEmailProps {
  obstruction: Obstruction;
  project: ObstructionProject;
  scopes: ObstructionScopes;
}

export default function ObstructionSendEmail({
  obstruction,
  project,
  scopes,
}: ObstructionSendEmailProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation<ObstructionLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(obstructionRoutes.detail.generatePath({ obstructionId: obstruction.id }));
  }, [location.state, navigate, obstruction.id]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  const permitted = scopes.sendObstructionDocumentAttachment.isPermitted;
  const missingScopeNames = scopes.sendObstructionDocumentAttachment.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <ObstructionSendEmailModalTitle
          name={obstruction.name}
          status={obstruction.status}
          projectDescription={project.description}
        />
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <ObstructionSendEmailBody
          obstruction={obstruction}
          project={project}
          onRequestClose={() => setDialogOpen(false)}
          onDirtyStateChange={setFormDirty}
        />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('obstructionSendEmail.confirmationModal.title', {
          obstructionName: obstruction.name,
        })}
        description={t('obstructionSendEmail.confirmationModal.body')}
        confirmButtonText={t('obstructionSendEmail.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('obstructionSendEmail.confirmationModal.dismissButton')}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setDialogOpen(false);
        }}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
