import { CustomFileLog } from '../../graphql/__generated__/CustomFileLog.fragment';
import { Log } from '../Log.types';

export const CUSTOM_FILE_LOG_TYPES = [
  'CustomFileArchivedLog',
  'CustomFileCreatedLog',
  'CustomFileDeletedLog',
  'CustomFileDuplicatedForManipulationLog',
  'CustomFileEmailSentLog',
  'CustomFileManipulatedLog',
  'CustomFileRenamedLog',
];

export function isCustomFileLog(log: Log): log is CustomFileLog {
  return CUSTOM_FILE_LOG_TYPES.includes(log.__typename);
}
