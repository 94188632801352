import cx from 'classnames';

import './c-image-thumbnail.scss';

export type ImageThumbnailProps = JSX.IntrinsicElements['img'];

export default function ImageThumbnail({
  alt,
  className,
  ...otherProps
}: ImageThumbnailProps): JSX.Element {
  return <img alt={alt} className={cx('c-image-thumbnail', className)} {...otherProps} />;
}
