import { StringSchema } from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import yup from '../../yup';
import i18n from '../../i18n';

const FileExtensionRegx = /^.*\.(x81|x82)$/i;
const MAX_FILE_SIZE = 100 * 1024 * 1024;

export const catalogueFile = (): MixedSchema =>
  yup
    .mixed<File>()
    .test('extension', i18n.t('validation.catalogFile.extension.invalid.alert'), (value) =>
      value ? FileExtensionRegx.test(value.name) : true,
    )
    .test('size', i18n.t('validation.catalogFile.extension.invalid.alert'), (value) =>
      value ? value.size <= MAX_FILE_SIZE : true,
    );

export const RTEContentRequired = (): StringSchema<string | undefined> =>
  yup
    .string()
    .required()
    .test('empty', i18n.t('validation.required'), (htmlString) => {
      if (!htmlString) {
        return true;
      }
      const parser = new DOMParser();
      const RTEHTML = parser.parseFromString(htmlString, 'text/html').documentElement;

      if (RTEHTML.querySelector('img')) {
        return true;
      }

      if (RTEHTML.textContent) {
        return !!RTEHTML.textContent.trim();
      }

      return !!RTEHTML.textContent;
    });

export const validNumberString = (errorMessage = i18n.t('validation.format')): yup.NumberSchema =>
  yup
    .number()
    .transform((_, v) => parseFloat(v.replace(/,/g, '.')))
    .typeError(errorMessage);
