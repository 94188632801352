import { TypedDocumentNode, gql } from '@apollo/client';
import {
  SaveAnnotations,
  SaveAnnotationsVariables,
} from './__generated__/SaveAnnotations.mutation';

const SaveAnnotationsMutation: TypedDocumentNode<SaveAnnotations, SaveAnnotationsVariables> = gql`
  mutation SaveAnnotations($input: SaveAnnotationsInput!) {
    saveAnnotations(input: $input) {
      id
    }
  }
`;

export default SaveAnnotationsMutation;
