import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ObstructionEditDetail,
  ObstructionEditDetailVariables,
} from './__generated__/ObstructionEditDetail.query';

const ObstructionEditDetailQuery: TypedDocumentNode<
  ObstructionEditDetail,
  ObstructionEditDetailVariables
> = gql`
  query ObstructionEditDetail($obstructionId: ID!, $thumbnailWidth: Int!, $thumbnailHeight: Int!) {
    obstruction(id: $obstructionId) {
      id

      clientName
      date
      professions
      viewerCanUpdate

      facts {
        title
        description
      }

      images {
        id

        label
        url
        thumbnail(width: $thumbnailWidth, height: $thumbnailHeight)
      }
    }
  }
`;

export default ObstructionEditDetailQuery;
