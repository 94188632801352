import { ApolloError, useQuery } from '@apollo/client';

import RiskAssessmentEditDetailQuery from '../../graphql/RiskAssessmentEditDetail.query';
import RiskAssessmentEmployeesQuery from '../../../RiskAssessmentForm/Information/graphql/RiskAssessmentEmployees.query';
import { RiskAssessmentEditDetail } from '../../graphql/__generated__/RiskAssessmentEditDetail.query';
import { RegionalSecurityManager } from '../../../graphql/__generated__/RegionalSecurityManager.fragment';
import { RiskAssessmentEmployees } from '../../../RiskAssessmentForm/Information/graphql/__generated__/RiskAssessmentEmployees.query';

interface UseRiskAssessmentEditDetailParams {
  riskAssessmentId: string;
  projectId: string;
}
interface UseRiskAssessmentEditDetailResponse {
  riskAssessment?: RiskAssessmentEditDetail['riskAssessment'];
  employees: RiskAssessmentEmployees['employeesForProject'];
  regionalSecurityManagers: RegionalSecurityManager[];
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentEditDetail({
  riskAssessmentId,
  projectId,
}: UseRiskAssessmentEditDetailParams): UseRiskAssessmentEditDetailResponse {
  const { data, loading, error } = useQuery(RiskAssessmentEditDetailQuery, {
    variables: { riskAssessmentId },
    fetchPolicy: 'network-only',
  });

  const riskAssessment = data?.riskAssessment;
  const regionalSecurityManagers = data?.regionalSecurityManagers ?? [];

  const { data: employeeData } = useQuery(RiskAssessmentEmployeesQuery, {
    variables: {
      projectId,
    },
  });

  const employees = employeeData?.employeesForProject || [];

  return {
    riskAssessment,
    employees,
    regionalSecurityManagers,
    loading,
    error,
  };
}
