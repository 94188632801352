import { gql, TypedDocumentNode } from '@apollo/client';
import {
  QsstSaveProjectDetail,
  QsstSaveProjectDetailVariables,
} from './__generated__/QsstSaveProjectDetail.query';

const QsstSaveProjectDetailQuery: TypedDocumentNode<
  QsstSaveProjectDetail,
  QsstSaveProjectDetailVariables
> = gql`
  query QsstSaveProjectDetail($projectId: ID!) {
    project(id: $projectId) {
      id

      draft

      date
      description
      hsProjectNumber
      additionalName

      client {
        id

        name
      }
    }
  }
`;

export default QsstSaveProjectDetailQuery;
