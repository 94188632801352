import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import DriveDocumentNoteContainer from '../../../../../components/DriveDocumentNoteContainer';
import { ObstructionScopes } from '../../../Obstruction.types';
import { Obstruction } from '../../../graphql/__generated__/Obstruction.fragment';

interface ObstructionDetailSidebarTopProps {
  obstruction: Obstruction;
  scopes: ObstructionScopes;
  onEditButtonClick: () => void;
  onSendEmailButtonClick: () => void;
}

export default function ObstructionDetailSidebarTop({
  obstruction,
  scopes,
  onEditButtonClick,
  onSendEmailButtonClick,
}: ObstructionDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateObstructionDocument.isPermitted;
  const sendPermitted = scopes.sendObstructionDocumentAttachment.isPermitted;
  const downloadPermitted = scopes.downloadObstructionDocument.isPermitted;

  return (
    <Stack gap="small-3x">
      <DriveDocumentNoteContainer id={obstruction.id} name={obstruction.name}>
        {updatePermitted && (
          <Button endIcon={<Icon name="create" />} onClick={onEditButtonClick}>
            {t('obstruction.editButton')}
          </Button>
        )}

        {sendPermitted && (
          <Button endIcon={<Icon name="email" />} onClick={onSendEmailButtonClick}>
            {t('obstruction.sendEmailButton')}
          </Button>
        )}

        {downloadPermitted && (
          <Button
            component="a"
            href={obstruction.pdfPath}
            target="_blank"
            endIcon={<Icon name="cloud-download" />}
          >
            {t('obstruction.downloadPDFButton')}
          </Button>
        )}
      </DriveDocumentNoteContainer>
    </Stack>
  );
}
