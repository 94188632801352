import { useCallback, useReducer } from 'react';

type State = {
  loading: boolean;
  data?: string;
  error?: Error;
};

type RequestAction = {
  type: 'request';
};

type ResponseAction = {
  type: 'response';
  data: string;
};

type ErrorAction = {
  type: 'error';
  error: Error;
};

type Action = RequestAction | ResponseAction | ErrorAction;

type CanvasData = [(url: string) => void, State];

const initialState: State = {
  loading: false,
  data: undefined,
  error: undefined,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'request':
      return { loading: true, data: undefined, error: undefined };

    case 'response':
      return { loading: false, data: action.data, error: undefined };

    case 'error':
      return { loading: false, data: undefined, error: action.error };

    default:
      return initialState;
  }
}

export default function useCanvasData(): CanvasData {
  const [state, dispatch] = useReducer(reducer, initialState);

  const request = useCallback(
    (url: string) => {
      dispatch({ type: 'request' });

      fetch(url, { cache: 'no-cache' })
        .then((response) => response.text())
        .then((data) => {
          dispatch({ type: 'response', data });
        })
        .catch((error) => {
          dispatch({ type: 'error', error });
        });
    },
    [dispatch],
  );

  return [request, state];
}
