import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text, useSnackbar } from '@hs-baumappe/legacy-ui';
import { useViewer } from '@hs-baumappe/web-auth';
import { ErrorRenderer, MissingScopeError } from '@hs-baumappe/redkit';
import { useTranslation } from 'react-i18next';
import useSAPTransmitDetail from './useSAPTransmitDetail';
import RouteDialog from '../../../containers/RouteDialog';
import SAPTransmitLoadingLayout from './SAPTransmitLoadingLayout';
import SAPTransmitModalBody from './SAPTransmitModalBody';
import { navigateToLocation } from '../../routes.utils';
import { ProjectsRouteLocationState } from '../Projects.routes';
import { projectsRoutes } from '../../routes';

export default function SAPTransmitModal(): JSX.Element {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation<ProjectsRouteLocationState>();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { close } = useSnackbar();

  const { viewer } = useViewer();
  const transmitKeyDataToSapPermitted = !!viewer?.scopes.transmitKeyDataToSap.isPermitted;
  const missingScopeNames = viewer ? viewer.scopes.transmitKeyDataToSap.missingScopeNames : [];

  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);

  const {
    projects,
    selectedProfitCenters,
    selectedProjectStatuses,
    fetchNextPage,
    hasNextPage,
    loading,
    fetching,
    error,
  } = useSAPTransmitDetail({
    skip: !transmitKeyDataToSapPermitted,
    onProjectLoad: (loadedProjects) => {
      setSelectedProjectIds((prev) => [
        ...prev,
        ...loadedProjects.filter((project) => !project.isSynced).map((project) => project.id),
      ]);
    },
  });

  const handleOnClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: projectsRoutes.detail.generatePath({}),
    });
  }, [navigate, location.state]);

  async function handleClickLoadMoreButton() {
    fetchNextPage();
  }

  function renderContent() {
    if (!transmitKeyDataToSapPermitted) {
      return <MissingScopeError missingScopes={missingScopeNames} />;
    }

    if (loading) {
      return <SAPTransmitLoadingLayout className="qa-sap-transmit-modal-loading" />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    return (
      <SAPTransmitModalBody
        selectedProjectIds={selectedProjectIds}
        setSelectedProjectIds={setSelectedProjectIds}
        sapTransmissionProjects={projects}
        selectedProfitCenters={selectedProfitCenters}
        selectedProjectStatuses={selectedProjectStatuses}
        hasNextPage={hasNextPage}
        fetching={fetching}
        onLoadMore={handleClickLoadMoreButton}
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onClose={handleOnClose}
      onRequestClose={() => {
        close();
        setDialogOpen(false);
      }}
      header={
        <Text component="h2" variant="title-small" stylex={{ marginBottom: '0' }}>
          {t('transmit.title')}
        </Text>
      }
    >
      {renderContent()}
    </RouteDialog>
  );
}
