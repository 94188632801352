import { FC, PropsWithChildren } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';
import cx from 'classnames';
import Tr from '../Tr';
import Th from '../Th';

import './c-row-heading.scss';

interface Props {
  className?: string;
  colSpan: number;
}

const RowHeading: FC<PropsWithChildren<Props>> = ({ className, colSpan, children }) => (
  <Tr className={cx('c-row-heading', className)}>
    <Th className="c-row-heading__th" colSpan={colSpan}>
      <Text variant="body-large">{children}</Text>
    </Th>
  </Tr>
);

export default RowHeading;
