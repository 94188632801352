import { gql, TypedDocumentNode } from '@apollo/client';
import GetProjectDetailFragment from '../../../graphql/GetProjectDetailFragment';
import { ProjectSync, ProjectSyncVariables } from './__generated__/ProjectSync.mutation';

const ProjectSyncMutation: TypedDocumentNode<ProjectSync, ProjectSyncVariables> = gql`
  mutation ProjectSync($input: SyncProjectInput!) {
    syncProject(input: $input) {
      ...GetProjectDetailFragment
    }
  }

  ${GetProjectDetailFragment}
`;

export default ProjectSyncMutation;
