import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DriveDocumentPreview,
  DriveDocumentPreviewVariables,
} from './__generated__/DriveDocumentPreviewQuery';

const DriveDocumentPreviewQuery: TypedDocumentNode<
  DriveDocumentPreview,
  DriveDocumentPreviewVariables
> = gql`
  query DriveDocumentPreview($documentId: ID!) {
    driveDocument(id: $documentId) {
      id

      name
      mimeType

      preview {
        url
        mimeType
      }

      download {
        url
        name
      }

      project {
        id
      }
    }
  }
`;

export default DriveDocumentPreviewQuery;
