import { ObstructionStatus } from '../../../../../globalTypes';

// eslint-disable-next-line import/prefer-default-export
export function getStatusColorOnObstructionSendEmail(status: ObstructionStatus): string {
  switch (status) {
    case ObstructionStatus.SIGNED:
      return 'u-color-success';
    default:
      return 'u-color-muted';
  }
}
