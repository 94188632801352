import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ConstructionNoteArchive,
  ConstructionNoteArchiveVariables,
} from './__generated__/ConstructionNoteArchive.mutation';

const ConstructionNoteArchiveMutation: TypedDocumentNode<
  ConstructionNoteArchive,
  ConstructionNoteArchiveVariables
> = gql`
  mutation ConstructionNoteArchive($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
      status
    }
  }
`;

export default ConstructionNoteArchiveMutation;
