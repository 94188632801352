import { gql, TypedDocumentNode } from '@apollo/client';
import CustomFileDocumentFragment from './CustomFileDocument.fragment';
import CustomFileDocumentProjectFragment from './CustomFileDocumentProject.fragment';
import {
  CustomFileDocumentDetail,
  CustomFileDocumentDetailVariables,
} from './__generated__/CustomFileDocumentDetail.query';

const CustomFileDocumentDetailQuery: TypedDocumentNode<
  CustomFileDocumentDetail,
  CustomFileDocumentDetailVariables
> = gql`
  query CustomFileDocumentDetail($id: ID!) {
    driveDocument(id: $id) {
      ...CustomFileDocument

      project {
        ...CustomFileDocumentProject
      }
    }
  }

  ${CustomFileDocumentFragment}
  ${CustomFileDocumentProjectFragment}
`;

export default CustomFileDocumentDetailQuery;
