import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AcceptanceReportDetailAlert,
  AcceptanceReportDetailSearchParams,
} from '../../../../AcceptanceReportDetail.utils';
import { getEnumFromValue } from '../../../../../../../utils/enumUtils';

interface UseAcceptanceReportDetailAlertResponse {
  alert?: AcceptanceReportDetailAlert;
  clearAlert: () => void;
}

export default function useAcceptanceReportDetailAlert(): UseAcceptanceReportDetailAlertResponse {
  const [searchParams, setSearchParams] = useSearchParams();

  const alertParam = searchParams.get(AcceptanceReportDetailSearchParams.ALERT);
  const alert = alertParam ? getEnumFromValue(alertParam, AcceptanceReportDetailAlert) : undefined;

  const clearAlert = useCallback(() => {
    setSearchParams(
      (prev) => {
        prev.delete(AcceptanceReportDetailSearchParams.ALERT);
        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return {
    alert,
    clearAlert,
  };
}
