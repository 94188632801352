import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { format } from 'date-fns';
import { ProjectLog as ProjectLogType } from './ProjectLog.types';
import LogActor from '../LogActor';
import { assertUnknownLog, getActor } from '../Log.utils';
import {
  formatProjectExternalStatus,
  getLogProjectImportSystem,
  getProjectTypeOfExternalStatusChangedLog,
} from './ProjectLog.utils';

interface ProjectLogProps extends ComponentProps<typeof Text> {
  log: ProjectLogType;
}

export default function ProjectLog({ log, ...props }: ProjectLogProps): JSX.Element {
  const { t } = useTranslation();

  switch (log.__typename) {
    case 'ProjectAddressChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectAddressChangedLog"
            values={{ address: log.address }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectClientAddressChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectClientAddressChanged"
            values={{ clientAddress: log.clientAddress ?? '-' }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectClientNameChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectClientNameChanged"
            values={{ oldClientName: log.oldClientName ?? '-', clientName: log.clientName }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectContractDateChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectContractDateChangedLog"
            values={{ contractDate: format(new Date(log.contractDate), 'dd.MM.yyyy') }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectImportedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectImported"
            values={{
              projectNumber: log.projectNumber,
              projectName: log.projectName,
              sourceSystem: t(`projectSourceSystem.${log.sourceSystem}`),
            }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ProjectNameChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectNameChangedLog"
            values={{ projectName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectOfferNumberChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectOfferNumberChanged"
            values={{ offerNumber: log.offerNumber }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectOrderNumberChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectOrderNumberChanged"
            values={{ offerNumber: log.offerNumber, orderNumber: log.orderNumber }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectProfitCenterChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectProfitCenterChanged"
            values={{
              profitCenterId: log.profitCenterId,
              profitCenterName: log.profitCenterName,
            }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'ProjectStatusUpdatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectStatusUpdated"
            values={{
              from: t(`projectStatus.${log.from}`),
              to: t(`projectStatus.${log.to}`),
            }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ProjectExternalStatusChangedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectExternalStatusChangedUpdated.text"
            values={{
              projectType: getProjectTypeOfExternalStatusChangedLog(t, log),
              projectImportSource: getLogProjectImportSystem(t, log.projectImportSourceSystem),
              oldExternalStatus: formatProjectExternalStatus(t, log.oldExternalStatus ?? undefined),
              externalStatus: formatProjectExternalStatus(t, log.externalStatus),
            }}
            components={{
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ProjectSyncedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.projectSynced"
            components={{ actor: <LogActor actor={getActor(log)} /> }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
