import { Popover } from '@hs-baumappe/legacy-ui';
import cx from 'classnames';
import { ComponentProps } from 'react';

import './c-note-editor-color-picker.scss';

export interface NoteEditorColorPickerProps {
  className?: string;
  color?: string;
  open?: boolean;
  renderTrigger: ComponentProps<typeof Popover>['renderTrigger'];
  swatches: Array<string>;
  onChange?: (color: string) => void;
}

export default function NoteEditorColorPicker({
  open,
  className,
  color,
  swatches,
  renderTrigger,
  onChange,
}: NoteEditorColorPickerProps): JSX.Element {
  return (
    <Popover
      open={open}
      className={cx('c-note-editor-color-picker', className)}
      contentClassName="c-note-editor-color-picker__panel"
      placement="left-start"
      renderTrigger={renderTrigger}
      content={
        <div className="c-note-editor-color-picker__picker">
          <div
            className="c-note-editor-color-picker__preview"
            style={{ backgroundColor: color }}
            data-testid="note-editor-color-picker-preview"
          />
          <div className={cx('c-note-editor-color-picker__swatches', className)}>
            {swatches.map((swatch) => (
              <button
                type="button"
                className={cx('c-note-editor-color-picker__swatch', {
                  'is-selected': swatch === color,
                })}
                aria-label={`Swatch ${swatch}`}
                key={`swatch-${swatch}`}
                style={{ color: swatch }}
                onClick={onChange ? () => onChange(swatch) : undefined}
              />
            ))}
          </div>
        </div>
      }
    />
  );
}
