import { fabric } from '@hs-baumappe/fabric';
import { useHotkeys } from 'react-hotkeys-hook';
import { Icon, IconButton } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';

function removeObjects(canvas: fabric.Canvas | undefined) {
  if (!canvas) {
    return;
  }

  canvas.remove(...canvas.getActiveObjects());
  canvas.discardActiveObject();
}

export default function DeleteAction(): JSX.Element {
  const { canvas, activeObjects } = useCanvas();
  const disabled = activeObjects.length === 0;

  useHotkeys(
    'backspace',
    () => {
      removeObjects(canvas);
    },
    [canvas],
  );

  function handleClickDelete() {
    removeObjects(canvas);
  }

  return (
    <IconButton
      data-testid="canvas-delete-action-button"
      icon={<Icon name="delete" />}
      onClick={handleClickDelete}
      disabled={disabled}
    />
  );
}
