import { FC, ReactNode } from 'react';
import { Flex, Icon, Text, VisuallyHidden } from '@hs-baumappe/legacy-ui';

type Props = JSX.IntrinsicElements['input'] & {
  label: ReactNode;
  labelClassName?: string;
  checkmarkIconTestId?: string;
};

const PrecautionCheck: FC<Props> = ({
  className,
  label,
  labelClassName,
  checkmarkIconTestId,
  ...otherProps
}) => {
  const checkmarkIcon = otherProps.checked ? 'check' : 'add';

  return (
    <Flex
      component="label"
      alignItems="center"
      htmlFor={otherProps.id}
      stylex={{
        padding: 'small-x',
        borderBottom: '1px-solid-gray-300',
        backgroundColor: otherProps.checked ? 'warning' : undefined,
      }}
      className={className}
    >
      <VisuallyHidden
        component="input"
        type="checkbox"
        onChange={otherProps.onChange}
        {...otherProps}
      />
      <Text
        className={labelClassName}
        color="black"
        stylex={{ marginRight: 'large-x', flexGrow: '1' }}
      >
        {label}
      </Text>
      {(!otherProps.readOnly || (otherProps.readOnly && otherProps.checked)) && (
        <Icon
          name={checkmarkIcon}
          size={20}
          color="black"
          stylex={{ cursor: 'pointer' }}
          data-testid={checkmarkIconTestId}
        />
      )}
    </Flex>
  );
};

export default PrecautionCheck;
