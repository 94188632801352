import { FocusEvent, KeyboardEvent, ReactNode } from 'react';
import {
  composeEventHandlers,
  FlexGrid,
  FlexGridColumn,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
} from '@hs-baumappe/legacy-ui';
import { FormControlProps } from '@hs-baumappe/legacy-ui/dist/components/FormControl';
import { NumberInput } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../../../../i18n';
import yup from '../../../../../../../../yup';
import { formatNumber } from '../../../../../../../../utils/number';

interface SIVDiscountInputProps extends FormControlProps<typeof NumberInput> {
  discountPriceSection: ReactNode;
  helperText?: string;
}

const INPUT_ID = 'siv-discount-input';
const VALIDATION_MESSAGE = i18n.t(
  'projectDetail.projectInformation.sivDiscount.discountInput.validationMessage',
);
export const validationSchema = yup
  .number()
  .nullable()
  .transform((_, v) => (v ? parseFloat(v.replace(/,/g, '.')) : null))
  .typeError(VALIDATION_MESSAGE)
  .min(-100, VALIDATION_MESSAGE)
  .max(100, VALIDATION_MESSAGE);

export default function SIVDiscountInput({
  discountPriceSection,
  helperText,
  ...otherProps
}: SIVDiscountInputProps): JSX.Element {
  const { t } = useTranslation();

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    const { currentTarget } = event;

    currentTarget.value = formatNumber(currentTarget.value);
  }

  function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    const { currentTarget } = event;

    currentTarget.value = currentTarget.value.replace('.', ',');
  }

  return (
    <Stack gap="small-4x">
      <FormLabel htmlFor={INPUT_ID}>
        {t('projectDetail.projectInformation.sivDiscount.discountInput.label')}
      </FormLabel>

      <FlexGrid gutter="small-3x">
        <FlexGridColumn xs>
          <FormControl
            placeholder={t(
              'projectDetail.projectInformation.sivDiscount.discountInput.placeholder',
            )}
            id={INPUT_ID}
            {...otherProps}
            onKeyUp={composeEventHandlers(handleKeyUp, otherProps.onKeyUp)}
            onBlur={composeEventHandlers(handleBlur, otherProps.onBlur)}
          />
        </FlexGridColumn>
        <FlexGridColumn xs>{discountPriceSection}</FlexGridColumn>
      </FlexGrid>

      {helperText && <FormHelperText color="warning">{helperText}</FormHelperText>}
    </Stack>
  );
}
