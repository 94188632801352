import { GetPartProjectsInformation } from '../graphql/__generated__/GetPartProjectsInformation.query';

type PartProjects = GetPartProjectsInformation['project']['partProjects'];

export function getTotalHoursOfEmployees(
  mainProjectTotalHourOfEmployees: number,
  partProjects: PartProjects,
): number {
  const partProjectsTotalHoursOfEmployees = partProjects.reduce((total, partProject) => {
    const partProjectTotalHourOfEmployees = partProject.siv?.recording?.totalHourOfEmployees || 0;

    return total + partProjectTotalHourOfEmployees;
  }, 0);

  return mainProjectTotalHourOfEmployees + partProjectsTotalHoursOfEmployees;
}

export function getTotalHoursToBeDistributed(
  mainProjectTotalHoursToBeDistributed: number,
  partProjects: PartProjects,
): number {
  const partProjectsTotalHoursToBeDistributed = partProjects.reduce((total, partProject) => {
    const partProjectTotalHourToBeDistributed =
      partProject.siv?.recording?.totalHoursToBeDistributed || 0;

    return total + partProjectTotalHourToBeDistributed;
  }, 0);

  return mainProjectTotalHoursToBeDistributed + partProjectsTotalHoursToBeDistributed;
}

export function getTotalCostRecorded(
  mainProjectTotalCostRecorded: number,
  partProjects: PartProjects,
): number {
  const partProjectsTotalCostRecorded = partProjects.reduce((total, partProject) => {
    const partProjectTotalCostRecorded = partProject?.siv?.cockpit.totalCost.recorded || 0;

    return total + partProjectTotalCostRecorded;
  }, 0);

  return mainProjectTotalCostRecorded + partProjectsTotalCostRecorded;
}

export function getTotalPerformanceCostRecorded(
  mainProjectTotalPerformanceCostRecorded: number,
  partProjects: PartProjects,
): number {
  const partProjectsTotalPerformanceCostRecorded = partProjects.reduce((total, partProject) => {
    const partProjectTotalPerformanceCostRecorded =
      partProject?.siv?.cockpit.totalPerformanceCost.recorded || 0;

    return total + partProjectTotalPerformanceCostRecorded;
  }, 0);

  return mainProjectTotalPerformanceCostRecorded + partProjectsTotalPerformanceCostRecorded;
}

const PART_PROJECT_PROJECT_NUMBER_PATTERN = /^[^\/]+\/[A-Za-z](\d+)$/; // Matches with: 11605400001/T3
export function sortPartProjectsByProjectNumber(partProjects: PartProjects): PartProjects {
  return partProjects
    .map((partProject) => {
      const projectNumber = partProject.projectNumber || '';
      const match = projectNumber.match(PART_PROJECT_PROJECT_NUMBER_PATTERN);
      const partNumber = match ? Number(match[1]) : NaN;

      return { ...partProject, partNumber };
    })
    .sort((a, b) => a.partNumber - b.partNumber);
}
