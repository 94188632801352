import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import DuplicateConcernMutation from './graphql/DuplicateConcern.mutation';
import ErrorState from '../../../../../containers/ErrorState';

interface DuplicateConcernDialogProps {
  open: boolean;
  concernId: string;
  onRequestClose: () => void;
  onSubmitSuccess: (id: string) => void;
}

export default function DuplicateConcernDialog({
  open,
  concernId,
  onRequestClose,
  onSubmitSuccess,
}: DuplicateConcernDialogProps): JSX.Element {
  const { t } = useTranslation();

  const [duplicate, { loading, error }] = useMutation(DuplicateConcernMutation);

  async function handleConcernDuplication() {
    if (loading) return;

    const { data } = await duplicate({
      variables: {
        input: {
          documentId: concernId,
        },
      },
    });

    if (!data) return;

    onSubmitSuccess(data.concernDuplicate.id);
  }

  return (
    <>
      {error && <ErrorState error={error} />}

      <AlertDialog
        open={open}
        title={t('concern.duplicate.title')}
        description={t('concern.duplicate.body')}
        confirmButtonText={t('concern.duplicate.submitButton')}
        confirmButtonEndIcon={<Icon name="border-color" />}
        cancelButtonText={t('concern.duplicate.dismissButton')}
        onConfirm={handleConcernDuplication}
        onCancel={onRequestClose}
        onRequestClose={onRequestClose}
      />
    </>
  );
}
