import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DuplicateRiskAssessment,
  DuplicateRiskAssessmentVariables,
} from './__generated__/DuplicateRiskAssessment.mutation';

const DuplicateRiskAssessmentMutation: TypedDocumentNode<
  DuplicateRiskAssessment,
  DuplicateRiskAssessmentVariables
> = gql`
  mutation DuplicateRiskAssessment($input: DuplicateRiskAssessmentInput!) {
    duplicateRiskAssessment(input: $input) {
      id
    }
  }
`;
export default DuplicateRiskAssessmentMutation;
