import { Box, Checkbox, Divider, Flex, Stack, Text } from '@hs-baumappe/legacy-ui';
import { ParseKeys } from 'i18next';
import useProjectFilterFormStatuses from './hooks/useProjectFilterFormStatuses';
import { useTranslation } from 'react-i18next';
import { ProjectFilterFormPreset } from '../../ProjectFilterForm.form';
import { ProjectFilterViewerProfitCenters } from '../../../graphql/__generated__/ProjectFilterViewerProfitCenters.query';

interface ProjectFilterFormStatusesProps {
  presets: ProjectFilterFormPreset[];
  projectCountsByStatus: ProjectFilterViewerProfitCenters['viewer']['projectCountsByStatus'];
}

export default function ProjectFilterFormStatuses({
  presets,
  projectCountsByStatus,
}: ProjectFilterFormStatusesProps): JSX.Element {
  const { t } = useTranslation();
  const { statuses, areStatusesChecked, setCheckedProjectStatuses, setCheckedProjectStatus } =
    useProjectFilterFormStatuses();

  return (
    <>
      <Text variant="label-large" color="gray-700">
        {t('projectFilterForm.presets.title')}
      </Text>

      <Stack stylex={{ marginTop: 'small-3x' }}>
        {presets.map((preset, index) => (
          // Due to a Safari bug about rendering grid elements, we had to wrap the checkboxes with the Flex component
          <Flex key={`presets.${index}`}>
            <Checkbox
              name={`presets.${index}`}
              variant="filled"
              label={t(preset.label as ParseKeys<'translation'>)}
              checked={areStatusesChecked(preset.statuses)}
              onChange={({ target }) =>
                target.checked
                  ? setCheckedProjectStatuses(preset.statuses)
                  : setCheckedProjectStatuses([])
              }
              disableExtraPressableArea
            />
          </Flex>
        ))}
      </Stack>

      <Divider component="hr" role="none" className="u-display-none@sm-up" />

      <Box stylex={{ marginTop: 'small-x' }}>
        <Text variant="label-large" color="gray-700">
          {t('projectFilterForm.statuses.title')}
        </Text>

        <Stack gap="small-2x" stylex={{ marginTop: 'small-3x' }}>
          {statuses.map((projectStatusFilter, index) => {
            const statusCount =
              projectCountsByStatus.find((item) => item.status === projectStatusFilter.value)
                ?.count || 0;

            return (
              <Flex key={`filters.${index}`}>
                <Checkbox
                  name={`filters.${index}`}
                  variant="filled"
                  label={t(
                    `projectFilterStatus.${projectStatusFilter.value}` as ParseKeys<'translation'>,
                    {
                      projectCount: statusCount,
                    },
                  )}
                  checked={projectStatusFilter.checked}
                  onChange={({ target }) =>
                    setCheckedProjectStatus(projectStatusFilter.value, target.checked)
                  }
                  disabled={statusCount === 0}
                  disableExtraPressableArea
                />
              </Flex>
            );
          })}
        </Stack>
      </Box>

      <Divider component="hr" role="none" className="u-display-none@sm-up" />
    </>
  );
}
