import { TypedDocumentNode, gql } from '@apollo/client';
import LogFragment from '../../../../../../containers/LogContainer/graphql/Log.fragment';
import { Logs, LogsVariables } from './__generated__/LogsQuery';

const LogsQuery: TypedDocumentNode<Logs, LogsVariables> = gql`
  query Logs($projectId: ID!, $page: Int, $limit: Int) {
    logs(projectId: $projectId, page: $page, limit: $limit) {
      ...LogFragment
    }
  }

  ${LogFragment}
`;

export default LogsQuery;
