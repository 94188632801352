import { useNavigate } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { SignatureFormValues } from '@hs-baumappe/forms';
import RiskAssessmentSignMutation from '../../graphql/RiskAssessmentSign.mutation';
import { riskAssessmentRoutes } from '../../../riskAssessmentRoutes';
import b64toBlob from '../../../../../utils/b64toBlob';
import { RiskAssessmentSignatureType } from '../../../../../globalTypes';

interface RiskAssessmentSignDto {
  type: RiskAssessmentSignatureType;
  values: SignatureFormValues;
}

interface UseRiskAssessmentSignResponse {
  sign: (data: RiskAssessmentSignDto) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentSign(
  riskAssessmentId: string,
): UseRiskAssessmentSignResponse {
  const navigate = useNavigate();
  const [mutate, { loading, error }] = useMutation(RiskAssessmentSignMutation);

  async function sign({ type, values }: RiskAssessmentSignDto) {
    if (loading) {
      return;
    }

    await mutate({
      variables: {
        input: {
          type,
          fullName: values.name,
          riskAssessment: riskAssessmentId,
        },
        file: b64toBlob(values.signature.replace(/^[^,]+,/, ''), 'image/png'),
      },
    });

    navigate(riskAssessmentRoutes.detail.generatePath({ riskAssessmentId }));
  }

  return {
    sign,
    loading,
    error,
  };
}
