import { memo } from 'react';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import useSync from './useSync';
import ErrorState from '../../../../../containers/ErrorState';

interface SyncButtonProps {
  projectId: string;
  projectOrdered: boolean;
  onProjectSync: () => void;
  disabled?: boolean;
}

function ProjectSyncButton({
  projectId,
  onProjectSync,
  projectOrdered,
  disabled,
}: SyncButtonProps): JSX.Element {
  const { t } = useTranslation();
  const { projectSync, error, loading } = useSync({ projectId, projectOrdered, onProjectSync });

  return (
    <>
      {error && <ErrorState error={error} />}
      <Button
        color="primary"
        className="u-width-100%"
        onClick={projectSync}
        endIcon={<Icon name="cached" />}
        data-testid="project-sync-button"
        loading={loading}
        disabled={disabled}
      >
        {t('projectDetail.sync.button')}
      </Button>
    </>
  );
}

export default memo(ProjectSyncButton);
