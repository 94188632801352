import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import ErrorState from '../../../containers/ErrorState/ErrorState';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { AcceptanceReportLocationState } from '../AcceptanceReport.route.types';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import RouteDialog from '../../../containers/RouteDialog';
import { getAcceptanceReportMeta } from '../AcceptanceReport.utils';
import useAcceptanceReportManualSign from './hooks/useAcceptanceReportManualSign/useAcceptanceReportManualSign';
import AcceptanceReportUploadManuallySignedDocumentForm from './components/AcceptanceReportUploadManuallySignedDocumentForm';

interface AcceptanceReportUploadManuallySignedDocumentProps {
  acceptanceReport: AcceptanceReport;
}

export default function AcceptanceReportUploadManuallySignedDocument({
  acceptanceReport,
}: AcceptanceReportUploadManuallySignedDocumentProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<AcceptanceReportLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { sign, loading, error } = useAcceptanceReportManualSign(acceptanceReport.id);
  const { signable } = getAcceptanceReportMeta(acceptanceReport);

  const handleDialogAfterClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
    }

    navigate(
      acceptanceReportRoutes.detail.generatePath({
        acceptanceReportId: acceptanceReport.id,
      }),
    );
  }, [acceptanceReport.id, location.state, navigate]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  if (!acceptanceReport.viewerCanUploadManualPdf || !signable) {
    return (
      <Navigate
        to={acceptanceReportRoutes.detail.generatePath({ acceptanceReportId: acceptanceReport.id })}
        replace
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogAfterClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('uploadManuallySignedAcceptanceReportModal.title')}
        </Text>
      }
    >
      {error && <ErrorState error={error} />}

      <AcceptanceReportUploadManuallySignedDocumentForm
        loading={loading}
        onSubmit={sign}
        onDirty={() => setFormDirty(true)}
      />

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('uploadManuallySignedAcceptanceReportModal.confirmationDialog.title')}
        description={t('uploadManuallySignedAcceptanceReportModal.confirmationDialog.description')}
        confirmButtonText={t(
          'uploadManuallySignedAcceptanceReportModal.confirmationDialog.confirmButtonText',
        )}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t(
          'uploadManuallySignedAcceptanceReportModal.confirmationDialog.cancelButtonText',
        )}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setDialogOpen(false);
        }}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
