import { parseFromTimeZone } from 'date-fns-timezone';
import {
  AcceptanceReportAttendeeSaveInput,
  AcceptanceReportDefectMetaSaveInput,
  AcceptanceReportDefectSaveInput,
  AcceptanceReportDescriptionSaveInput,
  AcceptanceReportImageInput,
  AcceptanceReportSaveInput,
} from '../../../../globalTypes';
import {
  Acceptance,
  AcceptanceReportFormValues,
  Defect,
} from '../../forms/AcceptanceReportForm/AcceptanceReportFormValues';

const TIMEZONE_CONFIG = { timeZone: 'Europe/Berlin' };

export function createAcceptanceReportSaveInput({
  formValues,
  acceptanceReportId,
}: {
  formValues: AcceptanceReportFormValues;
  acceptanceReportId: string;
}): AcceptanceReportSaveInput {
  const date = parseFromTimeZone(formValues.date, TIMEZONE_CONFIG);
  const dueDate = parseFromTimeZone(formValues.defectDueDate, TIMEZONE_CONFIG);

  const acceptance = formValues.acceptance === Acceptance.ACCEPT;
  const hasDefect = formValues.defect === Defect.HAS_DEFECT;

  const acceptanceDescription: AcceptanceReportDescriptionSaveInput = {
    text: formValues.acceptanceDescriptionText,
    reference: formValues.acceptanceDescriptionReference,
  };

  const metaData = formValues.defectMeta.reduce<AcceptanceReportDefectMetaSaveInput[]>(
    (result, currentDefectMeta) => {
      if (currentDefectMeta && (currentDefectMeta.imageId || currentDefectMeta.label)) {
        const item: AcceptanceReportDefectMetaSaveInput = {
          description: currentDefectMeta.label || '',
          imageId: currentDefectMeta.imageId || undefined,
        };

        return [...result, item];
      }

      return result;
    },
    [],
  );

  const defect: AcceptanceReportDefectSaveInput = {
    metaData: !metaData.length ? undefined : metaData,
    dueDate,
    reference: formValues.defectReferenceFile || undefined,
    agreed: formValues.defectConfirmation,
    note: formValues.defectNote,
  };

  const attendeesTemp = formValues.attendees.filter((attendee) => attendee.fullName);
  const attendees = attendeesTemp.map<AcceptanceReportAttendeeSaveInput>((attendee) => {
    return {
      ...attendee,
      fullName: attendee.fullName || '',
      position: attendee.position || undefined,
    };
  });

  const images = formValues.images.reduce<AcceptanceReportImageInput[]>(
    (result, { imageId, label }) => {
      if (imageId) {
        const input: AcceptanceReportImageInput = {
          imageId,
          label,
        };

        return [...result, input];
      }

      return result;
    },
    [],
  );

  return {
    documentId: acceptanceReportId,
    projectName: formValues.name,
    description: formValues.description,
    draftProjectNumber: formValues.projectNumber,
    date,
    clientName: formValues.client,
    contractor: formValues.contractor,
    professions: formValues.toProfessions,
    attendees,
    acceptance,
    acceptanceDescription: !acceptance ? acceptanceDescription : undefined,
    hasDefect,
    defect: hasDefect ? defect : undefined,
    attachImagesToPdf: formValues.attachImagesToPdf,
    images,
  };
}
