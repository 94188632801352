import { gql, TypedDocumentNode } from '@apollo/client';
import ObstructionFragment from './Obstruction.fragment';
import ObstructionProjectFragment from './ObstructionProject.fragment';
import {
  ObstructionDetail,
  ObstructionDetailVariables,
} from './__generated__/ObstructionDetail.query';

const ObstructionDetailQuery: TypedDocumentNode<ObstructionDetail, ObstructionDetailVariables> =
  gql`
    query ObstructionDetail($obstructionId: ID!) {
      obstruction(id: $obstructionId) {
        ...Obstruction

        project {
          ...ObstructionProject
        }
      }
    }

    ${ObstructionFragment}
    ${ObstructionProjectFragment}
  `;

export default ObstructionDetailQuery;
