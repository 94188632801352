import { gql, TypedDocumentNode } from '@apollo/client';
import {
  EmailFormImageUpload,
  EmailFormImageUploadVariables,
} from './__generated__/EmailFormImageUploadMutation';

const EmailFormImageUploadMutation: TypedDocumentNode<
  EmailFormImageUpload,
  EmailFormImageUploadVariables
> = gql`
  mutation EmailFormImageUpload($file: Upload!) {
    uploadEditorImage(file: $file) {
      id
      path
    }
  }
`;

export default EmailFormImageUploadMutation;
