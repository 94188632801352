import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DuplicateConcern,
  DuplicateConcernVariables,
} from './__generated__/DuplicateConcern.mutation';

const DuplicateConcernMutation: TypedDocumentNode<DuplicateConcern, DuplicateConcernVariables> =
  gql`
    mutation DuplicateConcern($input: ConcernDuplicateInput!) {
      concernDuplicate(input: $input) {
        id
      }
    }
  `;
export default DuplicateConcernMutation;
