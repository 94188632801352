import { DriveFolderLog } from '../../graphql/__generated__/DriveFolderLog.fragment';
import { Log } from '../Log.types';

const DRIVE_FOLDER_LOG_TYPES = [
  'DriveFolderCreatedLog',
  'DriveFolderDeletedLog',
  'DriveFolderUpdatedLog',
];

export function isDriveFolderLog(log: Log): log is DriveFolderLog {
  return DRIVE_FOLDER_LOG_TYPES.includes(log.__typename);
}
