import { gql, TypedDocumentNode } from '@apollo/client';
import QsstFragment from '../../graphql/Qsst.fragment';
import {
  UploadManuallySignedQsst,
  UploadManuallySignedQsstVariables,
} from './__generated__/UploadManuallySignedQsst.mutation';

const UploadManuallySignedQsstMutation: TypedDocumentNode<
  UploadManuallySignedQsst,
  UploadManuallySignedQsstVariables
> = gql`
  mutation UploadManuallySignedQsst($input: QsstUploadPdfInput!) {
    qsstUploadPdf(input: $input) {
      ...Qsst
    }
  }

  ${QsstFragment}
`;

export default UploadManuallySignedQsstMutation;
