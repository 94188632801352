import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import ErrorState from '../../../../../containers/ErrorState/ErrorState';
import useObstructionSignDetail from '../../hooks/useObstructionSignDetail';
import useObstructionSign from '../../hooks/useObstructionSign/useObstructionSign';
import SignatureForm from '../../../../../components/SignatureForm';

interface ObstructionSignBodyProps {
  obstructionId: string;
  onFormDirty: (dirty: boolean) => void;
}

export default function ObstructionSignBody({
  obstructionId,
  onFormDirty,
}: ObstructionSignBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { viewerSignatureBase64, loading } = useObstructionSignDetail();
  const { sign, loading: signing, error } = useObstructionSign(obstructionId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (!viewer) {
    return null;
  }

  return (
    <>
      {error && <ErrorState error={error} />}

      <SignatureForm
        initialValues={{
          name: `${viewer.firstName} ${viewer.lastName}`,
        }}
        nameInputLabel={t('obstructionSignForm.name.label')}
        nameInputPlaceholder={t('obstructionSignForm.name.placeholder')}
        signatureInputLabel={t('obstructionSignForm.signature.label')}
        lastSignature={viewerSignatureBase64}
        loading={signing}
        onSubmit={sign}
        onDirtyStateChange={onFormDirty}
      />
    </>
  );
}
