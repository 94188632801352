import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ConcernConvertImageAttachments,
  ConcernConvertImageAttachmentsVariables,
} from './__generated__/ConcernConvertImageAttachmentsMutation';

const ConcernConvertImageAttachmentsMutation: TypedDocumentNode<
  ConcernConvertImageAttachments,
  ConcernConvertImageAttachmentsVariables
> = gql`
  mutation ConcernConvertImageAttachments($input: [ID!]!) {
    convertCustomFilesToImages(input: $input) {
      id

      url
    }
  }
`;

export default ConcernConvertImageAttachmentsMutation;
