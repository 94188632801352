import { ApolloQueryResult } from '@apollo/client/core/types';
import { ApolloError, useQuery } from '@apollo/client';
import {
  GetProjectDetail,
  GetProjectDetailVariables,
} from './graphql/__generated__/GetProjectDetail.query';
import ProjectDetailQuery from './graphql/GetProjectDetail.query';

interface UseProjectDetailResponse {
  data?: GetProjectDetail;
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?: Partial<GetProjectDetailVariables>,
  ) => Promise<ApolloQueryResult<GetProjectDetail>>;
}

export default function useProjectDetail(projectId: string): UseProjectDetailResponse {
  const variables: GetProjectDetailVariables = { projectId };
  const { data, loading, error, refetch } = useQuery<GetProjectDetail, GetProjectDetailVariables>(
    ProjectDetailQuery,
    {
      variables,
    },
  );

  return {
    data,
    loading,
    error,
    refetch,
  };
}
