import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Text } from '@hs-baumappe/legacy-ui';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';

import useCustomFileDocumentArchive from './hooks/useCustomFileDocumentArchive';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';
import { CustomFileDocument } from '../graphql/__generated__/CustomFileDocument.fragment';
import { DocumentLocationState } from '../document.route.types';
import documentRoutes from '../documentRoutes';

interface DocumentArchiveProps {
  document: CustomFileDocument;
}

export default function DocumentArchive({ document }: DocumentArchiveProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const navigate = useNavigate();
  const location = useLocation<DocumentLocationState>();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { archive, loading: archiving, error } = useCustomFileDocumentArchive(document.id);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(documentRoutes.manipulate.generatePath({ documentId: document.id }));
  }, [location.state?.backgroundLocation, navigate, document.id]);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  if (!document.viewerCanArchive || !archiveEnabled) {
    return (
      <Navigate to={documentRoutes.manipulate.generatePath({ documentId: document.id })} replace />
    );
  }

  const documents = [
    {
      id: document.id,
      name: document.name,
      size: typeof document.size === 'number' ? formatBytes(document.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('customFile.archive.title')}
        </Text>
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('archiveModals.informationText')}</Text>}
        rightSection={<Text variant="body-small">{t('archiveModals.warningText')}</Text>}
        initialValues={{
          username: sapCredentials?.username ?? '',
          password: sapCredentials?.password ?? '',
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
