import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertDialog, Box, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useConcernCreateDetail from './hooks/useConcernCreateDetail';
import useProfessions from '../../../hooks/useProfessions';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import ConcernForm from '../../Concern/forms/ConcernForm';
import { ProjectDetailWithFolderRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';
import useConcernSave from '../../Concern/hooks/useConcernSave';
import { mapProjectToConcernFormValues } from './CreateConcern.utils';

interface CreateConcernProps {
  documentId: string;
}

function CreateConcern({ documentId }: CreateConcernProps): JSX.Element | null {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailWithFolderRouteParams>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { professions } = useProfessions();
  const { project, loading, error } = useConcernCreateDetail(projectId);
  const { save, loading: saving, error: saveError } = useConcernSave(documentId);

  const handleDialogRequestClose = useCallback(() => {
    if (!formDirty) {
      setDialogOpen(false);
      return;
    }

    setShowConfirmationDialog(true);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function renderContent() {
    if (!project && loading) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!project) {
      return null;
    }

    return (
      <ConcernForm
        initialValues={mapProjectToConcernFormValues(project)}
        projectId={project.id}
        draft={project.draft}
        professions={professions}
        submitButtonText={t('concern.create.form.submitButton')}
        onSubmit={save}
        onDirtyStateChange={setFormDirty}
        loading={saving}
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project?.description}
            {project?.additionalName && ' | ' + project.additionalName}
          </Text>
          <Text variant="title-small" component="h2" className="u-margin-bottom-0">
            {t('concern.create.route.subtitle')}
          </Text>
        </>
      }
    >
      {saveError && <ErrorState error={saveError} />}

      <Box>{renderContent()}</Box>

      <AlertDialog
        open={showConfirmationDialog}
        title={t('concern.create.form.cancelModal.title')}
        description={t('concern.create.form.cancelModal.info')}
        confirmButtonText={t('concern.create.form.cancelModal.cancelButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('concern.create.form.cancelModal.dismissButton')}
        onConfirm={() => {
          setShowConfirmationDialog(false);
          setDialogOpen(false);
        }}
        onCancel={() => setShowConfirmationDialog(false)}
        onRequestClose={() => setShowConfirmationDialog(false)}
        destructive
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateConcern, {
  mimeType: DriveSpecialDocumentMimeTypes.CONCERN,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
