import { IconButton, Icon } from '@hs-baumappe/legacy-ui';
import useCanvasZoom from '../../../hooks/useCanvasZoom';

const ZOOM_IN_INCREMENT = 0.25;

export default function ZoomInAction(): JSX.Element {
  const { zoomIn, canZoomIn } = useCanvasZoom();

  function handleZoomInActionClick() {
    zoomIn(ZOOM_IN_INCREMENT);
  }

  return (
    <IconButton
      data-testid="zoom-in-action-button"
      icon={<Icon name="zoom-in" />}
      onClick={handleZoomInActionClick}
      disabled={!canZoomIn}
    />
  );
}
