import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ErrorRendererPage, NotFoundErrorPage, UnknownErrorPage } from '@hs-baumappe/redkit';

import useCustomFileDocument from './hooks/useCustomFileDocument';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';
import DocumentDetailLoadingLayout from '../components/DocumentDetailLoadingLayout';
import DocumentDetail from './DocumentDetail';
import DocumentArchive from './DocumentArchive';
import DocumentSendEmail from './DocumentSendEmail/DocumentSendEmail';
import { DocumentLocationState, DocumentRouteParams } from './document.route.types';
import { MIME_TYPES } from '../../utils/mimeTypes';

export default function Document(): JSX.Element {
  const { documentId } = useParams<DocumentRouteParams>();
  const location = useLocation<DocumentLocationState>();
  const backgroundLocation = location.state?.backgroundLocation;

  const { data, loading, error } = useCustomFileDocument(documentId);

  useUpdateDriveDocumentLastUsed({
    id: documentId,
    skip: !data?.document,
  });

  if (loading) {
    return <DocumentDetailLoadingLayout />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return <NotFoundErrorPage />;
  }

  if (!MIME_TYPES.PDF.includes(data.document.mimeType)) {
    return <UnknownErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="manipulate" element={<DocumentDetail {...data} />} />
        <Route path="*" element={<NotFoundErrorPage />} />
      </Routes>

      <Routes>
        <Route path="archive" element={<DocumentArchive {...data} />} />
        <Route path="send-email" element={<DocumentSendEmail {...data} />} />
      </Routes>
    </>
  );
}
