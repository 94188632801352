import { AcceptanceReportAttendeeRole } from '../../../../../globalTypes';

export default function getAttendeeFullNameLabel(role: AcceptanceReportAttendeeRole): string {
  switch (role) {
    case AcceptanceReportAttendeeRole.HEINRICH_SCHMID:
      return 'acceptanceReport.attendee.role.hs.label';
    case AcceptanceReportAttendeeRole.CLIENT:
      return 'acceptanceReport.attendee.role.client.label';
    case AcceptanceReportAttendeeRole.OTHER:
      return 'acceptanceReport.attendee.role.other.label';
    default:
      return 'acceptanceReport.attendee.role.other.label';
  }
}

export function getAttendeeFullNamePlaceholder(role: AcceptanceReportAttendeeRole): string {
  switch (role) {
    case AcceptanceReportAttendeeRole.HEINRICH_SCHMID:
      return 'acceptanceReport.attendee.role.hs.placeholder';
    case AcceptanceReportAttendeeRole.CLIENT:
      return 'acceptanceReport.attendee.role.client.placeholder';
    case AcceptanceReportAttendeeRole.OTHER:
      return 'acceptanceReport.attendee.role.other.placeholder';
    default:
      return 'acceptanceReport.attendee.role.other.placeholder';
  }
}
