import { ApolloError, useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import ProjectEmailSuggestionsQuery from './graphql/ProjectEmailSuggestions.query';
import {
  ProjectEmailSuggestions,
  ProjectEmailSuggestionsVariables,
} from './graphql/__generated__/ProjectEmailSuggestions.query';

export interface UseProjectEmailSuggestionsResponse {
  emailSuggestions: ProjectEmailSuggestions['emailSuggestions'];
  loading: boolean;
  error?: ApolloError;
}

const EMPTY_EMAIL_SUGGESTIONS: ProjectEmailSuggestions['emailSuggestions'] = {
  contactPeople: [],
  recipients: [],
  __typename: 'EmailSuggestions',
};

export default function useProjectEmailSuggestions(
  projectId: string,
  options?: Omit<
    QueryHookOptions<ProjectEmailSuggestions, ProjectEmailSuggestionsVariables>,
    'variables'
  >,
): UseProjectEmailSuggestionsResponse {
  const { data, loading, error } = useQuery(ProjectEmailSuggestionsQuery, {
    variables: {
      projectId,
    },
    ...options,
  });

  return { emailSuggestions: data?.emailSuggestions || EMPTY_EMAIL_SUGGESTIONS, loading, error };
}
