import { ApolloError, useQuery } from '@apollo/client';
import ProjectFilterViewerProfitCentersQuery from './graphql/ProjectFilterViewerProfitCenters.query';
import { ProjectFilterProfitCenter } from './graphql/__generated__/ProjectFilterProfitCenter.fragment';
import { ProjectFilterViewerProfitCenters } from './graphql/__generated__/ProjectFilterViewerProfitCenters.query';

interface UseViewerProfitCentersResponse {
  profitCenters: ProjectFilterProfitCenter[];
  projectCountsByStatus: ProjectFilterViewerProfitCenters['viewer']['projectCountsByStatus'];
  loading: boolean;
  error?: ApolloError;
}

export function useViewerProfitCenters(): UseViewerProfitCentersResponse {
  const { data, loading, error } = useQuery(ProjectFilterViewerProfitCentersQuery, {
    nextFetchPolicy: 'cache-first',
  });

  const viewer = data?.viewer;
  const profitCenters = viewer?.profitCenters ?? [];
  const projectCountsByStatus = viewer?.projectCountsByStatus ?? [];

  return {
    profitCenters,
    projectCountsByStatus,
    loading,
    error,
  };
}
