import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPLOAD_EDITOR_IMAGE = gql`
  mutation UploadEditorImage($file: Upload!) {
    uploadEditorImage(file: $file) {
      id
      path
    }
  }
`;
