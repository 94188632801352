import { useEffect } from 'react';
import { Alert, Icon, useTimer } from '@hs-baumappe/legacy-ui';
import useDocumentDetailAlert from './hooks/useDocumentDetailAlert';
import { getAlertContent } from './DocumentDetailAlerts.utils';

interface DocumentDetailAlertsProps {
  name: string;
}

export default function DocumentDetailAlerts({
  name,
}: DocumentDetailAlertsProps): JSX.Element | null {
  const { alert, clearAlert } = useDocumentDetailAlert();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  useEffect(() => {
    if (!alert) {
      return;
    }

    setTimeoutTimer(clearAlert, 5000);

    return () => clearAllTimers();
  }, [alert, clearAlert, clearAllTimers, setTimeoutTimer]);

  if (!alert) {
    return null;
  }

  return (
    <Alert color="success" className="qa-alert-message" endSection={<Icon name="check" />}>
      {getAlertContent(alert, name)}
    </Alert>
  );
}
