/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogOperation, CatalogCategory } from './types';
import Choice from '../../../../../components/form/Choice';
import { currencyFormat } from '../../../../../utils/locale';
import { Td, Tr } from '../../../../../components/Table';
import { Text } from '@hs-baumappe/legacy-ui';

type Item = CatalogCategory | CatalogOperation;

interface Props {
  item: Item;
  selectedItems?: string[];
  onChangeChoice?: (v: string) => void;
  onClick?: () => void;
}

const isOperation = (item: Item): item is CatalogOperation => {
  return 'unit' in item;
};

const CatalogRow: FC<Props> = ({ item, onClick, onChangeChoice, selectedItems }) => {
  const { t } = useTranslation();

  return (
    <Tr>
      <Td onClick={onClick} style={{ ['--table-row-depth' as any]: item.partNoTree.length - 1 }}>
        <Text variant="body-large">{item.partNoTree.join('.')}</Text>
      </Td>
      <Td onClick={onClick}>{item.name}</Td>
      {isOperation(item) ? (
        <>
          <Td onClick={onClick}>
            {item.unit && <Text variant="body-large">{t(`operationUnit.${item.unit}.short`)}</Text>}
          </Td>
          <Td onClick={onClick} className="u-white-space-nowrap">
            {item.price && <Text variant="body-large">{`${currencyFormat(item.price)} €`}</Text>}
          </Td>
          <Td>
            {onChangeChoice && (
              <Choice
                theme="secondary"
                disabled={!item.viewerCanSelectAsMultiple}
                readOnly={!item.viewerCanSelectAsMultiple}
                type="checkbox"
                onChange={() => {
                  onChangeChoice(item.id);
                }}
                checked={selectedItems?.length ? selectedItems.includes(item.id) : false}
              />
            )}
          </Td>
        </>
      ) : (
        <>
          <Td />
          <Td />
          <Td />
        </>
      )}
    </Tr>
  );
};

export default CatalogRow;
/* eslint-enable @typescript-eslint/no-explicit-any */
