import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentCreate,
  RiskAssessmentCreateVariables,
} from './__generated__/RiskAssessmentCreate.mutation';

const RiskAssessmentCreateMutation: TypedDocumentNode<
  RiskAssessmentCreate,
  RiskAssessmentCreateVariables
> = gql`
  mutation RiskAssessmentCreate($input: RiskAssessmentSaveInput!) {
    riskAssessmentSave(input: $input) {
      id
      name
    }
  }
`;

export default RiskAssessmentCreateMutation;
