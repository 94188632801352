import { ReactNode, Ref } from 'react';
import cx from 'classnames';

import './o-note-editor-layout.scss';

interface NoteEditorLayoutProps {
  className?: string;
  canvasArea: ReactNode;
  firstToolbarArea?: ReactNode;
  secondToolbarArea?: ReactNode;
  thirdToolbarArea?: ReactNode;
  containerRef?: Ref<HTMLDivElement>;
  canvasAreaRef?: Ref<HTMLDivElement>;
}

export default function NoteEditorLayout({
  className,
  canvasArea,
  containerRef,
  firstToolbarArea,
  secondToolbarArea,
  thirdToolbarArea,
  canvasAreaRef,
}: NoteEditorLayoutProps): JSX.Element {
  return (
    <div ref={containerRef} className={cx('o-note-editor-layout', className)}>
      <div className="o-note-editor-layout__canvas-area" ref={canvasAreaRef}>
        {canvasArea}
      </div>
      {firstToolbarArea && (
        <div className="o-note-editor-layout__first-toolbar-area">{firstToolbarArea}</div>
      )}
      {secondToolbarArea && (
        <div className="o-note-editor-layout__second-toolbar-area">{secondToolbarArea}</div>
      )}
      {thirdToolbarArea && (
        <div className="o-note-editor-layout__third-toolbar-area">{thirdToolbarArea}</div>
      )}
    </div>
  );
}
