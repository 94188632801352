import { useNavigate } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import RiskAssessmentEditMutation from '../../graphql/RiskAssessmentEdit.mutation';
import { RiskAssessmentFormValues } from '../../../RiskAssessmentForm/RiskAssessmentForm';
import { createRiskAssessmentSaveInput } from '../../../RiskAssessment.utils';
import { riskAssessmentRoutes } from '../../../riskAssessmentRoutes';

interface UseRiskAssessmentSaveResponse {
  edit: (values: RiskAssessmentFormValues, draftProject: boolean) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentSave(
  riskAssessmentId: string,
): UseRiskAssessmentSaveResponse {
  const navigate = useNavigate();

  const [mutate, { loading, error }] = useMutation(RiskAssessmentEditMutation);

  async function edit(values: RiskAssessmentFormValues, draftProject: boolean) {
    if (loading) {
      return;
    }

    await mutate({
      variables: {
        input: createRiskAssessmentSaveInput({
          documentId: riskAssessmentId,
          draft: draftProject,
          values,
        }),
      },
    });

    navigate(riskAssessmentRoutes.detail.generatePath({ riskAssessmentId }));
  }

  return {
    edit,
    error,
    loading,
  };
}
