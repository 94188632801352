import { ApolloError, useQuery } from '@apollo/client';
import CreateConcernProjectDetailQuery from '../../graphql/ConcernCreateDetail.query';
import { ConcernCreateDetail } from '../../graphql/__generated__/ConcernCreateDetail.query';

interface UseConcernCreateDetailResponse {
  project?: ConcernCreateDetail['project'];
  loading: boolean;
  error?: ApolloError;
}

export default function useConcernCreateDetail(projectId: string): UseConcernCreateDetailResponse {
  const { data, loading, error } = useQuery(CreateConcernProjectDetailQuery, {
    variables: {
      projectId,
    },
  });

  const project = data?.project;

  return {
    project,
    loading,
    error,
  };
}
