import { addMonths } from 'date-fns';
import { LocalStorageCache } from '../../../../utils/cache';
import yup from '../../../../yup';
import { ProjectSortingField, ProjectStatus, SortingDirection } from '../../../../globalTypes';

export interface CachedProjectFilters {
  searchQuery?: string;
  projectStatuses: ProjectStatus[];
  profitCenters: string[];
  onlyFollowed: boolean;
  sortingField: ProjectSortingField;
  sortingDirection: SortingDirection;
}

/*
 * We've added this invalidation date because of our DataHub migration
 * ID's of the Profit Centers has been changed. If the user storages ID's with old ones Projects List doesn't work.
 * To fix broken Projects List page, we invalidate the cache that exceeds that date.
 * */
const invalidationDate = addMonths(new Date(2024, 4, 27), 1);

const projectFilterCacheTTL = 24 * 30 * 60 * 60 * 1000; // 30 day
const projectFilterCacheKey = 'projectFilters';
const projectFilterCache = new LocalStorageCache<CachedProjectFilters>({
  validationSchema: yup.object({
    searchQuery: yup.string(),
    projectStatuses: yup.array().of(yup.mixed().oneOf(Object.values(ProjectStatus))),
    profitCenters: yup.array().of(yup.string().defined()).defined(),
    onlyFollowed: yup.boolean().defined(),
    sortingField: yup.mixed().oneOf(Object.values(ProjectSortingField)),
    sortingDirection: yup.mixed().oneOf(Object.values(SortingDirection)),
  }),
});

export function setProjectFilterCache(value: CachedProjectFilters): void {
  return projectFilterCache.set(projectFilterCacheKey, value, projectFilterCacheTTL);
}

export function getProjectFilterCache(): CachedProjectFilters | undefined {
  const cache = projectFilterCache.get(projectFilterCacheKey);
  const expiresAt = cache?.expiresAt;

  if (expiresAt && expiresAt < invalidationDate) {
    projectFilterCache.remove(projectFilterCacheKey);

    return undefined;
  }

  return cache;
}

export function removeProjectFilterCache(): void {
  projectFilterCache.remove(projectFilterCacheKey);
}
