import { ReactNode } from 'react';
import PartProjectHoursTableRow from '../PartProjectHoursTableRow';
import { Link, Text } from '@hs-baumappe/legacy-ui';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { projectRoutes } from '../../../../../../../../../routes';
import PartProjectHoursTableHour from '../PartProjectHoursTableHour';
import { formatPriceEur } from '../../../../../../../../../../utils/number';

interface PartProjectHoursTableProjectRowProps {
  projectId: string;
  projectCode: ReactNode;
  projectNumber: string;
  totalHourOfEmployees: number;
  totalHoursToBeDistributed: number;
  totalCostRecorded: number;
  totalPerformanceCostRecorded: number;
  showCostColumns: boolean;
}

export default function PartProjectHoursTableProjectRow({
  projectId,
  projectCode,
  projectNumber,
  totalHourOfEmployees,
  totalHoursToBeDistributed,
  totalCostRecorded,
  totalPerformanceCostRecorded,
  showCostColumns,
}: PartProjectHoursTableProjectRowProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <PartProjectHoursTableRow
      codeColumn={
        <Text color="blue-gray-200" variant="label-small">
          {projectCode}
        </Text>
      }
      numberColumn={
        <Link
          component={RouterLink}
          to={projectRoutes.detail.generatePath({ projectId })}
          color="primary"
          variant="label-medium"
        >
          {projectNumber}
        </Link>
      }
      totalHourOfEmployeesColumn={
        <PartProjectHoursTableHour
          hours={totalHourOfEmployees}
          label={t('projectDetail.projectInformation.partProjects.hoursFrom')}
        />
      }
      totalHoursToBeDistributedColumn={
        <PartProjectHoursTableHour
          hours={totalHoursToBeDistributed}
          label={t('projectDetail.projectInformation.partProjects.hoursNotAssigned')}
        />
      }
      showCostColumns={showCostColumns}
      totalCostRecordedPrice={
        <Text color="blue-gray-800" variant="body-small">
          {formatPriceEur(totalCostRecorded)}
        </Text>
      }
      performanceCostRecordedPrice={
        <Text color="blue-gray-800" variant="body-small">
          {formatPriceEur(totalPerformanceCostRecorded)}
        </Text>
      }
    />
  );
}
