import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import { useGenerateExternalQsstLinks } from '../../utils/useGenerateExternalQsstLink/useGenerateExternalQsstLinks';
import ErrorState from '../../../../containers/ErrorState';
import EmailForm from '../../../../containers/Email/EmailForm';
import { getEmailFormInitialValues } from '../QsstSendMail.utils';
import useQsstSendEmail from '../hooks/useQsstSendEmail';
import { formatBytes } from '../../../../utils/number';

interface QsstSendMailBodyProps {
  projectId: string;
  projectName: string;
  qsstId: string;
  qsstName: string;
  qsstPdfSize?: number;
  attachDocument: boolean;
  onDocumentIdsValueChange: () => void;
  onDirtyStateChange: (dirty: boolean) => void;
}

export default function QsstSendMailBody({
  projectId,
  projectName,
  qsstId,
  qsstName,
  qsstPdfSize,
  attachDocument,
  onDocumentIdsValueChange,
  onDirtyStateChange,
}: QsstSendMailBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const {
    sendEmail,
    loading: sending,
    error: sendError,
  } = useQsstSendEmail({ qsstId, attachDocument });
  const { externalQsstLinks, loading, error } = useGenerateExternalQsstLinks({
    qsstIds: [qsstId],
  });

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!viewer || !externalQsstLinks?.[qsstId]) {
    return null;
  }

  return (
    <>
      {sendError && <ErrorState error={sendError} />}

      <EmailForm
        projectId={projectId}
        initialValues={getEmailFormInitialValues(
          {
            qsstId,
            projectName,
            qsstName,
            attachDocument,
            externalLink: externalQsstLinks?.[qsstId],
            viewer,
          },
          t,
        )}
        documents={
          attachDocument
            ? [
                {
                  id: qsstId,
                  name: qsstName,
                  size: typeof qsstPdfSize === 'number' ? formatBytes(qsstPdfSize) : undefined,
                },
              ]
            : undefined
        }
        loading={sending}
        onSubmit={sendEmail}
        onDocumentIdsValueChange={onDocumentIdsValueChange}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
