import { Form, FormikProvider, useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { ReactNode, useEffect } from 'react';
import { Box, Button, Icon } from '@hs-baumappe/legacy-ui';

import ObstructionFormDetailSection from './components/ObstructionFormDetailSection';
import ObstructionFormFactsSection from './components/ObstructionFormFactsSection';
import ObstructionFormImageAttachmentSection from './components/ObstructionFormImageAttachmentSection';
import obstructionFormValidationSchema from './ObstructionForm.validation';
import { obstructionFormInitialValues, ObstructionFormValues } from './ObstructionForm.form';

interface ObstructionFormProps {
  initialValues?: Partial<ObstructionFormValues>;
  projectId: string;
  professions: string[];
  submitButtonText: ReactNode;
  onSubmit: (
    values: ObstructionFormValues,
    formikHelpers: FormikHelpers<ObstructionFormValues>,
  ) => void;
  onDirtyStateChange?: (dirty: boolean) => void;
  loading?: boolean;
}

export default function ObstructionForm({
  initialValues,
  projectId,
  professions,
  submitButtonText,
  onSubmit,
  onDirtyStateChange,
  loading,
}: ObstructionFormProps): JSX.Element {
  const formik = useFormik<ObstructionFormValues>({
    initialValues: obstructionFormInitialValues(initialValues),
    validationSchema: obstructionFormValidationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });
  const { dirty } = formik;

  useEffect(() => {
    if (onDirtyStateChange) {
      onDirtyStateChange(dirty);
    }
  }, [dirty, onDirtyStateChange]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="row">
          <div className="col col--md-8">
            <ObstructionFormDetailSection professions={professions} />

            <hr />

            <ObstructionFormFactsSection />

            <hr />

            <ObstructionFormImageAttachmentSection projectId={projectId} />
          </div>

          <div className="col col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                endIcon={<Icon name="add" />}
                fullWidth
                loading={loading}
              >
                {submitButtonText}
              </Button>
            </Box>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}
