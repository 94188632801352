import { ReactNode } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { numberFixer } from '../../../../../../../../../../utils/number';

import styles from './PartProjectHoursTableHour.module.scss';

interface PartProjectHoursTableHourProps {
  hours: number;
  color?: 'primary' | 'blue-gray-800';
  label: ReactNode;
  variant?: 'body-small' | 'label-medium';
}

export default function PartProjectHoursTableHour({
  hours,
  color = 'blue-gray-800',
  label,
  variant = 'body-small',
}: PartProjectHoursTableHourProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div>
      <Text
        variant={variant}
        stylex={{ paddingRight: 'small-3x' }}
        color={color}
        className={styles.hours}
      >
        {t('projectDetail.projectInformation.partProjects.hours', { hours: numberFixer(hours, 1) })}
      </Text>
      <Text variant="label-small" color="blue-gray-200">
        {label}
      </Text>
    </div>
  );
}
