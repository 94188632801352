import { Checkbox, Icon } from '@hs-baumappe/legacy-ui';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface ProjectFollowFilterProps {
  onlyFollowed: boolean;
  onFollowFilterChange: (value: boolean) => void;
}

export default function ProjectFollowFilter({
  onlyFollowed,
  onFollowFilterChange,
}: ProjectFollowFilterProps): JSX.Element {
  const { t } = useTranslation();

  function handleProjectFollowFilter() {
    onFollowFilterChange(!onlyFollowed);
  }

  return (
    <>
      <Checkbox
        variant="filled"
        checked={onlyFollowed}
        onChange={handleProjectFollowFilter}
        label={t('projectListPage.filterFollow')}
      />
      <Icon
        name={onlyFollowed ? 'notifications-on' : 'notifications-off'}
        size="24"
        className={cx('u-margin-left-small', {
          'u-color-primary': onlyFollowed,
          'u-color-muted': !onlyFollowed,
        })}
      />
    </>
  );
}
