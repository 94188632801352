import { useEffect } from 'react';
import { Alert, Icon, Stack, useTimer } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useAcceptanceReportDetailAlert from './hooks/useAcceptanceReportDetailAlert';
import { getAlertContent } from './AcceptanceReportDetailAlerts.utils';

interface AcceptanceReportDetailAlertsProps {
  name: string;
  signedManually: boolean;
}

export default function AcceptanceReportDetailAlerts({
  name,
  signedManually,
}: AcceptanceReportDetailAlertsProps): JSX.Element | null {
  const { t } = useTranslation();

  const { alert, clearAlert } = useAcceptanceReportDetailAlert();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  useEffect(() => {
    if (!alert) {
      return;
    }

    setTimeoutTimer(clearAlert, 5000);

    return () => clearAllTimers();
  }, [alert, clearAlert, clearAllTimers, setTimeoutTimer]);

  if (!alert && !signedManually) {
    return null;
  }

  return (
    <Stack gap="medium">
      {alert && (
        <Alert color="success" className="qa-alert-message" endSection={<Icon name="check" />}>
          {getAlertContent(alert, name)}
        </Alert>
      )}

      {signedManually && (
        <Alert color="warning" className="qa-alert-message">
          {t('acceptanceReport.manuallySignedPdf.signed')}
        </Alert>
      )}
    </Stack>
  );
}
