import cx from 'classnames';
import { Box, Flex, Skeleton } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import NoteEditorLayout from '../../../components/note-editor/NoteEditorLayout';
import NoteEditorToolbar from '../../../components/note-editor/NoteEditorToolbar';
import routes from '../../routes';

import '../o-construction-note-editor-container.scss';

interface ConstructionNoteCreateLoadingLayoutProps {
  className?: string;
}

export default function ConstructionNoteCreateLoadingLayout({
  className,
}: ConstructionNoteCreateLoadingLayoutProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <Box className={cx('container', className)} stylex={{ paddingEnds: 'large' }}>
      <Flex alignItems="center" stylex={{ marginBottom: 'small-x' }}>
        <Skeleton width={74} height={20} radius={8} />
        <Skeleton className="u-margin-left-small" width={124} height={20} radius={8} />
      </Flex>
      <AppHeader
        title={<Skeleton width={160} height={20} radius={8} />}
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
      <Box className="o-construction-note-editor-container" stylex={{ marginTop: 'large-x' }}>
        <NoteEditorLayout
          className="u-height-100%"
          canvasArea={<Skeleton width="100%" height="100%" radius={8} />}
          firstToolbarArea={
            <NoteEditorToolbar directionModifier="column@md-down">
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
            </NoteEditorToolbar>
          }
          secondToolbarArea={
            <NoteEditorToolbar>
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
              <Skeleton width={40} height={40} radius={8} />
            </NoteEditorToolbar>
          }
          thirdToolbarArea={
            <NoteEditorToolbar type="tool" directionModifier="column">
              <Skeleton width={56} height={40} radius={8} />
              <Skeleton width={56} height={40} radius={8} />
              <Skeleton width={56} height={40} radius={8} />
              <Skeleton width={56} height={40} radius={8} />
              <Skeleton width={56} height={40} radius={8} />
            </NoteEditorToolbar>
          }
        />
      </Box>
    </Box>
  );
}
