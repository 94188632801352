import { Text } from '@hs-baumappe/legacy-ui';
import LogDate from './LogDate';
import { renderLogMessage } from './Log.utils';
import { Log as LogType } from './Log.types';

interface LogProps {
  log: LogType;
  showDate?: boolean;
}

export default function Log({ log, showDate }: LogProps): JSX.Element {
  return (
    <>
      <Text variant="label-medium" align="right">
        <LogDate date={log.date} showDate={showDate} />
      </Text>

      {renderLogMessage(log)}
    </>
  );
}
