import { Box, Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import routes from '../../../../routes';
import { ObstructionProject } from '../../../graphql/__generated__/ObstructionProject.fragment';
import { Obstruction } from '../../../graphql/__generated__/Obstruction.fragment';
import { getStatusColorOfObstruction } from './ObstructionDetailHeader.utils';
import DocumentDetailBreadcrumbs from '../../../../../components/DocumentDetailBreadcrumbs';
import { DriveDocumentParentFolders } from '../../../../../hooks/useDriveDocumentParentFolders';

interface ObstructionDetailHeaderProps {
  obstruction: Obstruction;
  project: ObstructionProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function ObstructionDetailHeader({
  obstruction,
  project,
  parentFolders,
}: ObstructionDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={
          <Box>
            <Text component="h1" variant="body-large" color="muted" stylex={{ marginBottom: '0' }}>
              {obstruction.name}
            </Text>
            <Text color={getStatusColorOfObstruction(obstruction.status)}>
              {t('obstruction.status', { status: t(obstruction.status) })}
            </Text>
          </Box>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
