import { ReactNode } from 'react';
import cx from 'classnames';
import { Icon, IconProps, Text } from '@hs-baumappe/legacy-ui';

import { FieldStatus } from '../fieldStatus';

import './c-choice.scss';

type ChoiceType = 'checkbox' | 'radio';

export type ChoiceProps = JSX.IntrinsicElements['input'] & {
  type: ChoiceType;
  labelClassName?: string;
  label?: ReactNode;
  status?: FieldStatus;
  theme?: 'primary' | 'secondary';
  dataTestId?: string;
  indeterminate?: boolean;
};

function getCheckmarkIconName(type: ChoiceType, indeterminate: boolean): IconProps['name'] {
  switch (type) {
    case 'radio':
      return 'circle-filled';

    default:
      return indeterminate ? 'remove' : 'check';
  }
}

const Choice = ({
  type,
  className,
  status = 'idle',
  label,
  labelClassName,
  theme = 'primary',
  dataTestId,
  indeterminate = false,
  ...otherProps
}: ChoiceProps): JSX.Element => (
  <label
    htmlFor={otherProps.id}
    className={cx(
      'c-choice',
      { [`is-${status}`]: status !== 'idle' },
      { 'is-checked': otherProps.checked || indeterminate },
      { [`c-choice--${theme}`]: theme },
      { 'is-disabled': otherProps.disabled },
      className,
    )}
  >
    {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
    <input
      className="c-choice__input"
      type={type}
      disabled={status === 'disabled'}
      data-testid={dataTestId}
      aria-checked={indeterminate ? 'mixed' : undefined}
      {...otherProps}
    />
    <span className={cx('c-choice__mask', `c-choice__mask--${type}`)}>
      <Icon className="c-choice__checkmark" name={getCheckmarkIconName(type, indeterminate)} />
    </span>
    {!!label && <Text className={cx('c-choice__label', labelClassName)}>{label}</Text>}
  </label>
);

export default Choice;
