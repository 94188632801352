import { createPath } from 'react-router-dom';
import { DocumentType } from '../../../globalTypes';
import { projectRoutes } from '../../routes';
import { concernRoutes } from '../concernRoutes';
import { Concern } from '../graphql/__generated__/Concern.fragment';

export enum ConcernDetailSearchParams {
  ALERT = 'alert',
}

export enum ConcernDetailAlert {
  SEND = 'send',
  ARCHIVED = 'archived',
}

export function createDocumentAssignPath(concern: Concern, projectId: string): string {
  const searchParams = new URLSearchParams({
    document: DocumentType.CONCERN,
    returnPath: concernRoutes.detail.generatePath({
      concernId: concern.id,
    }),
  });

  return createPath({
    pathname: projectRoutes.assignDocumentToProject.generatePath({
      projectId,
      documentId: concern.id,
    }),
    search: searchParams.toString(),
  });
}
