import { Fragment, useEffect, useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';

import MeasurementTypeOptions from '../MeasurementSection/MeasurementTypeOptions';
import Select, { SelectOption } from '../../../../components/form/Control/Select';
import MeasurementSection from '../MeasurementSection';
import FieldLayout from '../../../../components/formik/FieldLayout/FieldLayout';
import { OperationUnit } from '../../../../globalTypes';
import { evalValue, initialMeasurementValues } from '../MeasurementSection/values';
import { numberFixer } from '../../../../utils/number';

interface Props {
  operation?: boolean;
  parent?: boolean;
  required?: boolean;
  operationUnit?: OperationUnit;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeProxy?: (e: React.ChangeEvent<any>) => void;
  readonlyType?: boolean;
}

const MeasurementsSections = ({
  required,
  readonlyType,
  parent,
  operation,
  operationUnit,
  handleChangeProxy,
}: Props): JSX.Element => {
  const [total, setTotal] = useState<number | undefined>(undefined);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue, setFieldTouched, values, errors, handleChange } = useFormikContext<any>();

  const { measurements } = values;

  const errorFeedback = (name: string) => {
    const err = getIn(errors, name);

    return err && t(err);
  };

  const handleClickNewMeasurement = () => {
    setFieldValue('measurements', [
      ...measurements,
      {
        type: measurements[0] ? measurements[0].type : operationUnit,
        values: {
          ...initialMeasurementValues(),
          subtraction: false,
          name: `Menge Nr. ${measurements.length + 1}`,
        },
      },
    ]);
  };

  const showMeasurementSection = operation
    ? measurements && !!measurements.length && operationUnit
    : measurements && !!measurements.length;

  useEffect(() => {
    let measurementTotal = 0;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!measurements && !measurements.length) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    measurements.forEach((m: any) => {
      if (m.type === OperationUnit.FLAT_RATE) {
        const multiplier = m.values.subtraction ? m.values.multiplier * -1 : m.values.multiplier;
        measurementTotal += evalValue(`${multiplier}`);
        setTotal(numberFixer(measurementTotal, 2));
        return;
      }

      if (m.values.total) {
        const totalValue = Math.abs(evalValue(m.values.total)) || 0;
        const sum = m.values.subtraction ? totalValue * -1 : totalValue;
        measurementTotal += evalValue(`${sum * m.values.multiplier}`);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (measurements.find((f: any) => f.values.total)) {
      setTotal(numberFixer(measurementTotal, 2));
    }
  }, [measurements]);

  return (
    <Fragment>
      <div className="row u-margin-bottom-xsmall">
        <div className="col col--sm-6">
          <FieldLayout
            name="measurements[0].type"
            label={t('measurementsForm.measurementType.label')}
            labelHtmlFor="select-measurement-type"
            feedback={{ invalid: errorFeedback('unit') }}
            status={errorFeedback('unit') ? 'invalid' : 'idle'}
          >
            {(inputProps) => {
              const placeholder = t('measurementsForm.measurementType.placeholder', {
                optional: required && t('optional'),
              });
              const selectedOption = inputProps.value
                ? MeasurementTypeOptions.find((m) => m.value === inputProps.value)
                : undefined;

              const options: SelectOption[] = MeasurementTypeOptions.map((m) => {
                return {
                  ...m,
                  label: t(m.label as ParseKeys<'translation'>) as string,
                };
              });

              return readonlyType ? (
                <Text className="u-margin-top-0">
                  {selectedOption ? selectedOption.label : placeholder}
                </Text>
              ) : (
                <Select
                  id="select-measurement-type"
                  options={options}
                  placeholder={placeholder}
                  dataTestId="select-measurement-type"
                  {...inputProps}
                  value={
                    values.measurements[0]
                      ? values.measurements[0].type
                      : operationUnit || placeholder
                  }
                  onChange={(e) => {
                    if (e.currentTarget.value) {
                      setFieldValue('measurements', [
                        {
                          type: e.currentTarget.value,
                          values: {
                            ...initialMeasurementValues(),
                            name: 'Menge Nr. 1',
                          },
                        },
                      ]);

                      setTotal(undefined);
                    } else {
                      setFieldValue('measurements', []);
                      setFieldValue('unit', undefined);
                      return;
                    }

                    setFieldTouched('measurements[0].values.multiplier', false);
                    setFieldTouched('measurements[0].values.total', false);
                    setFieldTouched('measurements[0].values.value', false);
                    setFieldTouched('unit', false);
                    handleChange(e);
                    if (handleChangeProxy) handleChangeProxy(e);
                  }}
                />
              );
            }}
          </FieldLayout>
        </div>
        <div className="col col--sm-6 u-margin-top u-display-flex u-align-items-center">
          {/* eslint-disable-next-line no-nested-ternary */}
          {measurements && !!measurements.length && !!measurements[0].type ? (
            <Fragment>
              <Text color="muted" variant="body-small" className="u-margin-right">
                {t('measurementsForm.total')}
              </Text>
              <Text variant="title-small">
                {total?.toString().replace('.', ',')}{' '}
                {total
                  ? t(`operationUnit.${measurements[0].type}.short` as ParseKeys<'translation'>)
                  : ''}
              </Text>
            </Fragment>
          ) : operation && operationUnit ? (
            <Button
              type="button"
              endIcon={<Icon name="add" />}
              onClick={handleClickNewMeasurement}
              fullWidth
              className="u-margin-bottom-small"
            >
              {t('measurementsForm.addNewMeasurement.button')}
            </Button>
          ) : null}
        </div>
      </div>
      {showMeasurementSection &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        measurements.map((m: any, i: number) => (
          <MeasurementSection
            key={`measurements[${i}]`}
            onClickRemoveMeasurement={(index: number) => {
              const subtraction = values.measurements[index].values.subtraction ? -1 : 1;

              const removeItem = values.measurements;
              if (total) {
                setTotal(total - values.measurements[index].values.total * subtraction);
              }

              removeItem.splice(index, 1);

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const reOrderedMeasurement = removeItem.map((newMeasurement: any) => {
                return {
                  ...newMeasurement,
                  values: {
                    ...newMeasurement.values,
                  },
                };
              });

              if (reOrderedMeasurement.length === 0) {
                setTotal(undefined);
              }

              setFieldValue('measurements', reOrderedMeasurement);
            }}
            index={i}
            name={`measurements[${i}]`}
            type={measurements[0].type as OperationUnit}
          />
        ))}
      {operation && (!measurements || measurements.length === 0) && (
        <div className="row u-margin-top">
          <div className="col col--sm-6">
            <div className="u-display-flex">
              <Icon
                name="warning"
                className="u-font-size-large u-color-warning u-margin-right-xsmall"
              />
              <Text color="warning">{t('measurementsForm.measurement.message.title')}</Text>
            </div>
            <Text component="div" className="u-margin-top-small" color="warning">
              {t('measurementsForm.measurement.message.description')}
            </Text>
          </div>
        </div>
      )}
      {measurements && !!measurements.length && measurements[0].type && (
        <div className="row u-margin-top">
          <div className="col col--sm-6">
            <Text>
              {t('measurementsForm.measurement.message.subDescription', {
                value: operation ? t('operation') : !parent ? t('group') : t('subGroup'),
              })}
            </Text>
          </div>
          <div className="col col--sm-6">
            <Button
              type="button"
              fullWidth
              endIcon={<Icon name="add" />}
              onClick={handleClickNewMeasurement}
            >
              {t('measurementsForm.addNewMeasurement.button')}
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MeasurementsSections;
