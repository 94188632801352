import { TypedDocumentNode, gql } from '@apollo/client';
import LogFragment from '../../../../../../containers/LogContainer/graphql/Log.fragment';
import { CustomFileLogs, CustomFileLogsVariables } from './__generated__/CustomFileLogs.query';

const CustomFileLogsQuery: TypedDocumentNode<CustomFileLogs, CustomFileLogsVariables> = gql`
  query CustomFileLogs($id: ID!, $page: Int, $limit: Int) {
    customFile(id: $id) {
      id

      logs(page: $page, limit: $limit) {
        ...LogFragment
      }
    }
  }

  ${LogFragment}
`;

export default CustomFileLogsQuery;
