import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import ErrorState from '../../../../../../containers/ErrorState';
import LogContainer, { Log } from '../../../../../../containers/LogContainer';
import logsDateMapper from '../../../../../../containers/LogContainer/LogContainer.utils';
import QsstLogsQuery from './graphql/QsstLogsDetailQuery';

interface QsstLogsDetailProps {
  qsstId: string;
}

const QsstLogsDetail: FC<QsstLogsDetailProps> = ({ qsstId }) => {
  const [logs, setLogs] = useState<Log[]>([]);

  const { data, error, loading, refetch } = useQuery(QsstLogsQuery, {
    variables: { qsstId, page: 1, limit: 10 },
  });

  useEffect(() => {
    if (loading || !data) return;

    const dataLogs = data?.qsst?.logs?.logs || [];

    const logIdSet = new Set(logs.map((log) => log.id));

    const tmpLogs = [...logs];

    dataLogs.forEach((log) => {
      if (!logIdSet.has(log.id)) {
        tmpLogs.push(log);
      }
    });

    if (tmpLogs.length !== logs.length) {
      setLogs(tmpLogs);
    }
  }, [data, loading, logs]);

  if (error) {
    return <ErrorState error={error} />;
  }

  const logsDateData = logsDateMapper(logs);

  const handleMoreDataClick = async () => {
    if (!data?.qsst?.logs) {
      return;
    }

    await refetch({
      page: data.qsst.logs.pagination.current + 1,
      limit: 10,
    });
  };

  return (
    <LogContainer
      loading={loading}
      logs={logsDateData}
      hasNextPage={data?.qsst?.logs?.pagination.hasNextPage}
      onMoreDataClick={handleMoreDataClick}
    />
  );
};

export { QsstLogsDetail };
