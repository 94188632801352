import { gql, TypedDocumentNode } from '@apollo/client';
import {
  CreateConstructionNote,
  CreateConstructionNoteVariables,
} from './__generated__/CreateConstructionNote.mutation';

const CreateConstructionNoteMutation: TypedDocumentNode<
  CreateConstructionNote,
  CreateConstructionNoteVariables
> = gql`
  mutation CreateConstructionNote(
    $file: Upload!
    $imagePresentation: Upload!
    $input: ConstructionNoteCreateInput!
  ) {
    constructionNoteCreate(file: $file, imagePresentation: $imagePresentation, input: $input) {
      id
    }
  }
`;

export default CreateConstructionNoteMutation;
