import { ArraySchema, NumberSchema, StringSchema } from 'yup';
import { CubicMeter, evalValue } from './values';
import { OperationUnit } from '../../../../globalTypes';
import yup, { InferObjectToYupShape } from '../../../../yup';

export const dimensionByType = (type: OperationUnit): number => {
  switch (type) {
    case OperationUnit.CUBIC_METER:
      return 3;
    case OperationUnit.SQUARE_METER:
      return 2;
    case OperationUnit.DAY:
    case OperationUnit.HOUR:
    case OperationUnit.KILOGRAM:
    case OperationUnit.LITER:
    case OperationUnit.METER:
      return 1;
    default:
      return 0;
  }
};

const multiplierSchema: NumberSchema<number | null | undefined> = yup
  .number()
  .min(1)
  .nullable(true)
  .transform((value, orginal) => (orginal === '' ? 1 : value));

export const dimensionSchema: StringSchema<string | undefined> = yup
  .string()
  .test('format', 'validation.format', (value) => {
    if (!value) {
      return true;
    }
    const result = evalValue(value);
    return result !== Infinity;
  })
  .test('mathExp', 'validation.required', (value) => {
    if (!value) {
      return true;
    }
    const result = evalValue(value);

    return !Number.isNaN(result);
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const valueValidationSchemaFactory = (
  dimension: number,
  strict: boolean,
): ArraySchema<typeof dimensionSchema> => {
  return yup
    .array()
    .of(dimensionSchema)
    .test('length', 'validation.required', (arr) => {
      if (!arr) {
        return false;
      }

      const cleanArr = arr.filter((a) => !!a);
      const isOk = cleanArr.length === dimension;
      const isEmpty = cleanArr.length === 0;

      if (strict) {
        return isOk;
      }

      return isOk || isEmpty;
    });
};

const measurementValidationSchemaFactory = (dimension: number) => {
  return yup.object().shape<InferObjectToYupShape<CubicMeter>>({
    id: yup.string().optional(),
    name: yup.string().required(),
    subtraction: yup.boolean(),
    multiplier: multiplierSchema,
    value: valueValidationSchemaFactory(dimension, false),
    total: yup
      .string()
      .defined()
      .default('')
      .test('format', 'validation.format', (value) => {
        if (!value) {
          return true;
        }
        const result = evalValue(value);
        return result !== Infinity;
      })
      .test('mathExp', 'validation.required', (value) => {
        if (!value) {
          return true;
        }
        const result = evalValue(value);

        return !Number.isNaN(result);
      })
      .when('value', (value: string[], schema) => {
        if (value[0] !== undefined || value[1] !== undefined || value[2] !== undefined)
          return schema.required();

        return schema;
      }),
  });
};

const flatRateValidationSchema = yup.object().shape<InferObjectToYupShape<{ total?: number }>>({
  total: yup.number().transform(() => 1),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchemaByType = (type: OperationUnit) => {
  if (type === OperationUnit.FLAT_RATE) return flatRateValidationSchema;
  return measurementValidationSchemaFactory(dimensionByType(type));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MeasurementValidationSchema = (required: boolean) => {
  let typeSchema = yup.mixed<OperationUnit>().oneOf(Object.values(OperationUnit));

  if (required) {
    typeSchema = typeSchema.required('validation.required');
  }

  return yup.object().shape({
    type: typeSchema,
    values: yup.object().when('type', (type, schema) => {
      if (!type) return schema;

      const valuesSchema = validationSchemaByType(type);

      return valuesSchema || schema;
    }),
  });
};

export default MeasurementValidationSchema;
