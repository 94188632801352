import { ComponentType, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ApolloError } from '@apollo/client';
import useGenerateDocumentId from './useGenerateDocumentId';
import { ProjectDetailWithFolderRouteParams } from '../../routes/Project/ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../../routes/Project/drive/drive.types';

interface ComponentProps {
  documentId: string;
}

interface WithGenerateDocumentIdOptions {
  mimeType: DriveSpecialDocumentMimeTypes;
  errorRenderer: (error: ApolloError) => JSX.Element;
}

export type GeneratedDocumentIdParams<Props> = (props: Props) => JSX.Element | null;

export default function withGenerateDocumentId<Props>(
  Component: ComponentType<ComponentProps & Props>,
  { mimeType, errorRenderer }: WithGenerateDocumentIdOptions,
): GeneratedDocumentIdParams<Props> {
  // eslint-disable-next-line react/display-name
  return (props: Props): JSX.Element | null => {
    const { folderId } = useParams<ProjectDetailWithFolderRouteParams>();

    const { generate, documentId, loading, error } = useGenerateDocumentId(folderId, mimeType);
    const generateId = useCallback(async () => {
      await generate();
    }, [generate]);

    useEffect(() => {
      generateId();
    }, [generateId]);

    if (loading) {
      return <LayoutLoading />;
    }

    if (error) {
      return errorRenderer(error);
    }

    if (!documentId) {
      return null;
    }

    return <Component documentId={documentId} {...props} />;
  };
}
