import { PropsWithChildren, ReactNode, useState } from 'react';
import { Details, Summary } from '@hs-baumappe/legacy-ui';

interface ProjectDetailDetailsProps {
  className?: string;
  initiallyOpen?: boolean;
  title: ReactNode;
  subTitle?: ReactNode;
  onOpen?: () => void;
}

export default function ProjectDetailDetails({
  className,
  subTitle,
  initiallyOpen,
  title,
  children,
  onOpen,
}: PropsWithChildren<ProjectDetailDetailsProps>): JSX.Element {
  const [open, setOpen] = useState(initiallyOpen);

  const handleDetailsToggle = () => {
    if (!open && onOpen) {
      onOpen();
    }

    setOpen(!open);
  };

  return (
    <Details
      className={className}
      open={open}
      onToggle={handleDetailsToggle}
      renderSummary={({ onClick }) => (
        <Summary contentClassName="u-display-flex u-align-items-center" onClick={onClick}>
          {title}
          {!open && subTitle}
        </Summary>
      )}
    >
      {children}
    </Details>
  );
}
