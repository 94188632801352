import { Table, TableBody, TableCell, TableHead, TableRow, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { GetProjectInformation } from '../../../graphql/__generated__/GetProjectInformation.query';
import { GetPartProjectsInformation } from '../../../graphql/__generated__/GetPartProjectsInformation.query';
import PartProjectHoursTableCell from './components/PartProjectHoursTableCell';
import PartProjectHoursTableHour from './components/PartProjectHoursTableHour';
import PartProjectHoursTableProjectRow from './components/PartProjectHoursTableProjectRow';
import PartProjectHoursTableRow from './components/PartProjectHoursTableRow';
import {
  getTotalCostRecorded,
  getTotalHoursOfEmployees,
  getTotalHoursToBeDistributed,
  getTotalPerformanceCostRecorded,
} from '../../PartProjects.utils';
import { formatPriceEur } from '../../../../../../../../utils/number';

interface PartProjectHoursTableProps {
  project: GetProjectInformation['project'];
  projectSIV?: NonNullable<GetPartProjectsInformation['project']['siv']>;
  partProjects: GetPartProjectsInformation['project']['partProjects'];
  showCostColumns: boolean;
}

export default function PartProjectHoursTable({
  project,
  partProjects,
  projectSIV,
  showCostColumns,
}: PartProjectHoursTableProps): JSX.Element {
  const { t } = useTranslation();

  const mainProjectTotalHourOfEmployees = projectSIV?.recording.totalHourOfEmployees || 0;
  const mainProjectTotalHoursToBeDistributed = projectSIV?.recording.totalHoursToBeDistributed || 0;
  const mainProjectTotalCostRecorded = projectSIV?.cockpit.totalCost.recorded || 0;
  const mainProjectTotalPerformanceCostRecorded =
    projectSIV?.cockpit.totalPerformanceCost.recorded || 0;
  const totalHoursOfEmployees = getTotalHoursOfEmployees(
    mainProjectTotalHourOfEmployees,
    partProjects,
  );
  const totalHoursToBeDistributed = getTotalHoursToBeDistributed(
    mainProjectTotalHoursToBeDistributed,
    partProjects,
  );
  const totalCostRecorded = getTotalCostRecorded(mainProjectTotalCostRecorded, partProjects);
  const totalPerformanceCostRecorded = getTotalPerformanceCostRecorded(
    mainProjectTotalPerformanceCostRecorded,
    partProjects,
  );

  return (
    <Table borderless stylex={{ width: undefined }}>
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        {showCostColumns && (
          <>
            <col />
            <col />
          </>
        )}
      </colgroup>

      <TableHead>
        <TableRow>
          <TableCell component="th" />
          <TableCell component="th" />
          <PartProjectHoursTableCell component="th" colSpan={2}>
            <Text color="blue-gray-500" variant="label-small">
              {t('projectDetail.projectInformation.partProjects.table.hoursColumn')}
            </Text>
          </PartProjectHoursTableCell>
          {showCostColumns && (
            <>
              <PartProjectHoursTableCell
                component="th"
                stylex={{
                  borderLeft: '1px-solid-gray-300',
                  textAlign: 'right',
                  paddingRight: 'large',
                }}
              >
                <Text color="blue-gray-500" variant="label-small">
                  {t('projectDetail.projectInformation.partProjects.table.costPriceColumn')}
                </Text>
              </PartProjectHoursTableCell>
              <PartProjectHoursTableCell
                component="th"
                stylex={{
                  borderLeft: '1px-solid-gray-300',
                  paddingLeft: 'large',
                  textAlign: 'right',
                }}
              >
                <Text color="blue-gray-500" variant="label-small">
                  {t('projectDetail.projectInformation.partProjects.table.performancePriceColumn')}
                </Text>
              </PartProjectHoursTableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <PartProjectHoursTableProjectRow
          projectCode={t('projectDetail.projectInformation.partProjects.mainProject')}
          projectNumber={project.projectNumber}
          projectId={project.id}
          totalHourOfEmployees={mainProjectTotalHourOfEmployees}
          totalHoursToBeDistributed={mainProjectTotalHoursToBeDistributed}
          totalCostRecorded={mainProjectTotalCostRecorded}
          totalPerformanceCostRecorded={mainProjectTotalPerformanceCostRecorded}
          showCostColumns={showCostColumns}
        />

        {partProjects.map((partProject, index) => (
          <PartProjectHoursTableProjectRow
            key={partProject.id}
            projectCode={t('projectDetail.projectInformation.partProjects.partProject', {
              number: index + 1,
            })}
            projectNumber={partProject.projectNumber || '-'}
            projectId={partProject.id}
            totalHourOfEmployees={partProject.siv?.recording.totalHourOfEmployees || 0}
            totalHoursToBeDistributed={partProject.siv?.recording.totalHoursToBeDistributed || 0}
            totalCostRecorded={partProject?.siv?.cockpit.totalCost.recorded || 0}
            totalPerformanceCostRecorded={
              partProject?.siv?.cockpit.totalPerformanceCost.recorded || 0
            }
            showCostColumns={showCostColumns}
          />
        ))}
      </TableBody>
      <tfoot>
        <PartProjectHoursTableRow
          stylex={{ borderTop: '1px-solid-gray-300' }}
          totalHourOfEmployeesColumn={
            <PartProjectHoursTableHour
              hours={totalHoursOfEmployees}
              label={t('projectDetail.projectInformation.partProjects.hoursFrom')}
              variant="label-medium"
            />
          }
          totalHoursToBeDistributedColumn={
            <PartProjectHoursTableHour
              hours={totalHoursToBeDistributed}
              label={t('projectDetail.projectInformation.partProjects.hoursNotAssigned')}
              variant="label-medium"
            />
          }
          showCostColumns={showCostColumns}
          totalCostRecordedPrice={
            <Text color="blue-gray-800" variant="label-medium">
              {formatPriceEur(totalCostRecorded)}
            </Text>
          }
          performanceCostRecordedPrice={
            <Text color="blue-gray-800" variant="label-medium">
              {formatPriceEur(totalPerformanceCostRecorded)}
            </Text>
          }
        />
      </tfoot>
    </Table>
  );
}
