import { gql, TypedDocumentNode } from '@apollo/client';
import EquipmentFragment from './Equipment.fragment';
import { Equipments, EquipmentsVariables } from './__generated__/Equipments.query';

const EquipmentsQuery: TypedDocumentNode<Equipments, EquipmentsVariables> = gql`
  query Equipments($precautions: [ID!]) {
    equipments(precautions: $precautions) {
      ...Equipment
    }
  }

  ${EquipmentFragment}
`;

export default EquipmentsQuery;
