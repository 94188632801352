import { useState } from 'react';
import cx from 'classnames';

import { Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import FormFeedbackInfoModal from './FormFeedbackInfoModal';

import './c-form-feedback.scss';

export type FormFeedbackTheme = 'default' | 'valid' | 'invalid';

type FormFeedbackProps = Omit<JSX.IntrinsicElements['div'], 'children'> & {
  theme?: FormFeedbackTheme;
  children: string;
};

const MAX_LENGTH = 30;

export default function FormFeedback({
  className,
  theme = 'default',
  children,
  ...otherProps
}: FormFeedbackProps): JSX.Element {
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const infoButtonVisible = children && children.length > MAX_LENGTH;

  return (
    <>
      <div
        className={cx(
          'c-form-feedback',
          { [`c-form-feedback--theme-${theme}`]: theme !== 'default' },
          className,
        )}
        {...otherProps}
      >
        <Text variant="body-small" className="c-form-feedback__inner" truncate>
          {children}
        </Text>
        {infoButtonVisible && (
          <IconButton
            data-testid="info-button"
            className="c-form-feedback__info-button"
            size="tiny"
            icon={<Icon name="help" />}
            type="button"
            onClick={() => setInfoModalVisible(true)}
          />
        )}
      </div>
      {infoButtonVisible && (
        <FormFeedbackInfoModal
          visible={infoModalVisible}
          message={children}
          onRequestClose={() => setInfoModalVisible(false)}
        />
      )}
    </>
  );
}
