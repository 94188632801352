import { gql, TypedDocumentNode } from '@apollo/client';
import {
  GetCreateSivProjectDetail,
  GetCreateSivProjectDetailVariables,
} from './__generated__/GetCreateSivProjectDetail.query';

const GetCreateSivProjectDetailQuery: TypedDocumentNode<
  GetCreateSivProjectDetail,
  GetCreateSivProjectDetailVariables
> = gql`
  query GetCreateSivProjectDetail($projectId: ID!) {
    project(id: $projectId) {
      id
      draft
      description
    }
  }
`;

export default GetCreateSivProjectDetailQuery;
