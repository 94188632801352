import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { useTranslation } from 'react-i18next';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { AcceptanceReportLocationState } from '../AcceptanceReport.route.types';
import { navigateToLocation } from '../../routes.utils';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportScopes } from '../AcceptanceReport.types';
import RouteDialog from '../../../containers/RouteDialog';
import AcceptanceReportEditBody from './components/AcceptanceReportEditBody';
import { AcceptanceReportProject } from '../graphql/__generated__/AcceptanceReportProject.fragment';

interface AcceptanceReportEditProps {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  scopes: AcceptanceReportScopes;
}

export default function AcceptanceReportEdit({
  acceptanceReport,
  project,
  scopes,
}: AcceptanceReportEditProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<AcceptanceReportLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: acceptanceReportRoutes.detail.generatePath({
        acceptanceReportId: acceptanceReport.id,
      }),
    });
  }, [navigate, location.state, acceptanceReport.id]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleAlertDialogCancel() {
    setConfirmationDialogOpen(false);
  }

  function handleAlertDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  const permitted = scopes.updateAcceptanceReportDocument.isPermitted;
  const missingScopeNames = scopes.updateAcceptanceReportDocument.missingScopeNames;

  if (!acceptanceReport.viewerCanUpdate) {
    return (
      <Navigate
        to={acceptanceReportRoutes.detail.generatePath({ acceptanceReportId: acceptanceReport.id })}
        replace
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('acceptanceReport.edit.header.title')}
          </Text>
        </>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <AcceptanceReportEditBody
          acceptanceReportId={acceptanceReport.id}
          project={project}
          formDirty={formDirty}
          onFormDirtyStateChange={setFormDirty}
          onRequestClose={() => setDialogOpen(false)}
        />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={acceptanceReport.name}
        description={t('editAcceptanceReport.confirmationModal.body')}
        confirmButtonText={t('editAcceptanceReport.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('editAcceptanceReport.confirmationModal.dismissButton')}
        onConfirm={handleAlertDialogConfirm}
        onCancel={handleAlertDialogCancel}
        onRequestClose={handleAlertDialogCancel}
        destructive
      />
    </RouteDialog>
  );
}
