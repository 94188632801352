import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { OfferLocationState } from '../../Offer.route.types';
import { OfferFolderOperationCloneRouteParams } from '../../operation/OfferFolderOperationClone/route';
import useOffer from '../../hooks/offer';
import OfferEditForm from './OfferEditForm';
import useOfferEdit from './useOfferEdit';
import { offerRoutes } from '../../offerRoutes';
import { navigateToLocation } from '../../../routes.utils';

const OfferEdit = (): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId } = useParams<OfferFolderOperationCloneRouteParams>();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const { getOffer } = useOffer();
  const { name, mode, meta, project, catalogue, type } = getOffer();

  const { edit } = useOfferEdit({
    offerId,
    offerType: type,
    catalogueId: catalogue?.id,
  });

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: offerRoutes.detail.generatePath({ offerId }),
    });
  }, [location.state, navigate, offerId]);

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {name} {t('offerEditModal.title')}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          <OfferEditForm
            draft={project.draft}
            initialValues={{
              name,
              mode,
              client:
                meta && meta.draftClient
                  ? {
                      name: meta.draftClient.name || undefined,
                      address: meta.draftClient.address || undefined,
                      city: meta.draftClient.city || undefined,
                      zip: meta.draftClient.zip || undefined,
                    }
                  : undefined,
              constructionSite:
                meta && meta.draftConstructionSite
                  ? {
                      name: meta.draftConstructionSite.name || undefined,
                      address: meta.draftConstructionSite.address || undefined,
                      city: meta.draftConstructionSite.city || undefined,
                      zip: meta.draftConstructionSite.zip || undefined,
                    }
                  : undefined,
            }}
            onSubmit={edit}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default OfferEdit;
