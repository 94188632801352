import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { Link, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportScopes } from '../../../AcceptanceReport.types';
import { createDocumentAssignPath, PDFSource } from '../../AcceptanceReportDetail.utils';
import { acceptanceReportRoutes } from '../../../acceptanceReportRoutes';
import { createAcceptanceReportLocation } from '../../../AcceptanceReport.utils';
import { DocumentType } from '../../../../../globalTypes';
import RemoveDocument from '../../../../Project/document/RemoveDocument';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';

interface AcceptanceReportDetailSidebarBottomProps {
  acceptanceReport: AcceptanceReport;
  scopes: AcceptanceReportScopes;
  projectId: string;
  signable: boolean;
  signedManually: boolean;
  pdfSource: PDFSource;
  onTogglePdfButtonClick: () => void;
  onRemoveManualPdfButtonClick: () => void;
  onRemoveSuccess: () => void;
}

export default function AcceptanceReportDetailSidebarBottom({
  acceptanceReport,
  scopes,
  projectId,
  signable,
  signedManually,
  pdfSource,
  onTogglePdfButtonClick,
  onRemoveManualPdfButtonClick,
  onRemoveSuccess,
}: AcceptanceReportDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();
  const location = useLocation();

  const signPermitted = scopes.signAcceptanceReportDocument.isPermitted;
  const sendForSignaturePermitted = scopes.sendAcceptanceReportDocumentSignatureMail.isPermitted;
  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;

  return (
    <Stack gap="small-3x">
      {acceptanceReport.viewerCanAssign && (
        <Button
          component={Link}
          to={createDocumentAssignPath(acceptanceReport, projectId)}
          className="qa-acceptance-report-assign"
          endIcon={<Icon name="folder" />}
        >
          {t('acceptanceReport.assignProjectButton')}
        </Button>
      )}

      {signable && (
        <Button
          component={Link}
          to={acceptanceReportRoutes.uploadManuallySignedReport.generatePath({
            acceptanceReportId: acceptanceReport.id,
          })}
          state={createAcceptanceReportLocation(location)}
          className="qa-acceptance-report-manually-upload"
          endIcon={<Icon name="cloud-upload" />}
        >
          {t('acceptanceReport.uploadManuallySignedReportButtonText')}
        </Button>
      )}

      {signedManually && (
        <Button
          fullWidth
          endIcon={<Icon name="undo" />}
          onClick={onTogglePdfButtonClick}
          className={
            pdfSource === 'signed-manually'
              ? 'qa-acceptance-report-show-original-pdf'
              : 'qa-overtime-detail-signed-report-button'
          }
        >
          {pdfSource === 'signed-manually'
            ? t('acceptanceReport.showOriginalPdfButtonText')
            : t('acceptanceReport.showManuallySignedReportButton')}
        </Button>
      )}

      {acceptanceReport.viewerCanSign && signPermitted && (
        <Button
          component={Link}
          to={acceptanceReportRoutes.sign.generatePath({
            acceptanceReportId: acceptanceReport.id,
          })}
          state={createAcceptanceReportLocation(location)}
          color="primary"
          className="qa-acceptance-report-sign"
          endIcon={<Icon name="border-color" />}
        >
          {t('acceptanceReport.signButtonText')}
        </Button>
      )}

      {acceptanceReport.viewerCanArchive && archiveEnabled && (
        <Button
          component={Link}
          to={acceptanceReportRoutes.archive.generatePath({
            acceptanceReportId: acceptanceReport.id,
          })}
          state={createAcceptanceReportLocation(location)}
          color="primary"
          className="qa-acceptance-report-archive"
          endIcon={<Icon name="archive" />}
        >
          {t('acceptanceReport.archiveButton')}
        </Button>
      )}

      {acceptanceReport.viewerCanSendExternalLink && sendForSignaturePermitted && (
        <Button
          component={Link}
          to={acceptanceReportRoutes.sendExternalEmail.generatePath({
            acceptanceReportId: acceptanceReport.id,
          })}
          state={createAcceptanceReportLocation(location)}
          color="primary"
          className="qa-acceptance-report-send-external-email"
          endIcon={<Icon name="email" />}
        >
          {t('acceptanceReportSendExternalEmailButton')}
        </Button>
      )}

      {acceptanceReport.viewerCanRemove && removePermitted && (
        <RemoveDocument
          document={{
            name: acceptanceReport.name,
            type: DocumentType.ACCEPTANCE_REPORT,
            id: acceptanceReport.id,
          }}
          onRemoveSuccess={onRemoveSuccess}
          renderTrigger={(triggerProps) => (
            <Button
              color="error"
              variant="text"
              className="qa-acceptance-report-remove"
              {...triggerProps}
            >
              {t('acceptanceReport.removeButton')}
            </Button>
          )}
        />
      )}

      {acceptanceReport.viewerCanRemoveManualPdf &&
        pdfSource === 'signed-manually' &&
        removePermitted && (
          <Button
            variant="text"
            color="error"
            className="qa-acceptance-report-manually-remove"
            onClick={onRemoveManualPdfButtonClick}
          >
            {t('acceptanceReport.removeManuallyUploadedReportButton')}
          </Button>
        )}
    </Stack>
  );
}
