import { ReactNode } from 'react';
import { Flex, Text } from '@hs-baumappe/legacy-ui';

interface NoteEditorZoomToolbarProps {
  className?: string;
  zoomOutAction: ReactNode;
  zoomInAction: ReactNode;
  zoomValue: string;
}

export default function NoteEditorZoomToolbar({
  className,
  zoomOutAction,
  zoomInAction,
  zoomValue,
}: NoteEditorZoomToolbarProps): JSX.Element {
  return (
    <Flex className={className} alignItems="center">
      {zoomOutAction}
      <Text variant="label-medium" stylex={{ marginSides: 'small-x' }}>
        {zoomValue}
      </Text>
      {zoomInAction}
    </Flex>
  );
}
