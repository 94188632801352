import ObstructionFormFactValue from './components/ObstructionFormFactsSection/components/ObstructionFormFact/ObstructionFormFact.type';
import { ImageUploadWithLabelFormValues } from '../../../../containers/forms/ImageUploadWithLabel';

export interface ObstructionFormValues {
  projectNumber: string;
  clientName: string;
  date?: string;
  professions: string[];
  facts: ObstructionFormFactValue[];
  images: ImageUploadWithLabelFormValues[];
}

export function obstructionFormInitialValues(
  values?: Partial<ObstructionFormValues>,
): ObstructionFormValues {
  return {
    projectNumber: '-',
    clientName: '',
    date: undefined,
    professions: [],
    facts: [
      {
        title: '',
        description: '',
      },
    ],
    images: [],
    ...values,
  };
}
