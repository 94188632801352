import { PropsWithChildren, useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { Box, Button, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';
import useUserCanSave from '../../hooks/useUserCanSave';
import useCreateConstructionNote from './useCreateConstructionNote';
import TitleModal from './TitleModal';
import ErrorState from '../../../../containers/ErrorState';
import { constructionNoteRoutes } from '../../constructionNoteRoutes';
import { CreateConstructionNoteValues } from './values';

interface Props {
  initialTitle: string;
  documentId: string;
}

export default function CreateTool({
  initialTitle,
  documentId,
}: PropsWithChildren<Props>): JSX.Element {
  const navigate = useNavigate();

  const { canvas, exportAsJSON, exportAsImage } = useCanvas();
  const userCanSave = useUserCanSave();
  const { createConstructionNote, loading, error } = useCreateConstructionNote();

  const [modalVisible, setModalVisible] = useState(false);

  const handleSuccess = async (values: CreateConstructionNoteValues) => {
    if (!canvas) {
      return;
    }

    const file = exportAsJSON();
    const imagePresentation = exportAsImage();

    if (!file || !imagePresentation) {
      return;
    }

    const response = await createConstructionNote({
      file,
      imagePresentation,
      input: {
        name: values.title,
        documentId,
      },
    });

    if (!response || !response.data) {
      return;
    }

    navigate(
      createPath({
        pathname: constructionNoteRoutes.detailFreeDrawing.generatePath({
          constructionNoteId: response.data.constructionNoteCreate.id,
        }),
        search: `?noteCreated=true`,
      }),
    );
  };

  return (
    <Box>
      <Button
        contentClassName="u-display-flex u-align-items-center"
        color="primary"
        onClick={() => setModalVisible(true)}
        disabled={!userCanSave}
      >
        <Icon name="save" size={24} />
      </Button>

      <TitleModal
        open={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        initialTitle={initialTitle}
        loading={loading}
        onSuccess={handleSuccess}
      />

      {error && <ErrorState error={error} />}
    </Box>
  );
}
