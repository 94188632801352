import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { useCallback, useState } from 'react';
import { createPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import { ProjectLocationState } from '../../Project.route.types';
import { projectRoutes } from '../../../routes';
import useContactPersonService from './useContactPersonService';
import AddContactPersonForm from '../AddContactPeople/AddContactPersonForm';
import { UpdateContactPeopleRouteParams } from './UpdateContactPeople.route.types';
import ContactPersonConfirmationModal from '../components/ContactPersonConfirmationModal';

import GetContactPersonQuery from './graphql/GetContactPerson.query';
import {
  GetContactPerson,
  GetContactPersonVariables,
} from './graphql/__generated__/GetContactPerson.query';

export default function UpdateContactPeople(): JSX.Element {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);

  const { projectId, contactPersonId } = useParams<UpdateContactPeopleRouteParams>();
  const location = useLocation<ProjectLocationState>();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<GetContactPerson, GetContactPersonVariables>(
    GetContactPersonQuery,
    {
      variables: {
        id: contactPersonId,
      },
    },
  );

  const { updateContactPerson, removeContactPerson } = useContactPersonService({
    contactPersonId,
    onSuccess: () => setOpen(false),
  });

  const handleDialogRequestClose = useCallback(() => {
    if (!formDirty) {
      setConfirmationModalVisible(false);
      setOpen(false);
      return;
    }

    setConfirmationModalVisible(true);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    const searchParams = new URLSearchParams({ tab: 'information' });

    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({ projectId }),
        search: searchParams.toString(),
      }),
    );
  }, [location.state, navigate, projectId]);

  function handleContactPersonRemoveButtonClick() {
    setRemoveModalVisible(true);
  }

  return (
    <Dialog
      animationType="slide-up"
      onClose={handleDialogClose}
      onRequestClose={handleDialogRequestClose}
      open={open}
      size="fullscreen"
    >
      {loading && !data && <LayoutLoading />}
      {error && <ErrorRenderer apolloError={error} />}
      {data && data.contactPerson && !loading && (
        <div className="container u-width-100% u-padding-ends-small">
          <div className="u-display-flex u-align-items-center">
            <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
              {t('updateContactPeople.title')}
            </Text>
            <IconButton
              icon={<Icon name="close" />}
              onClick={handleDialogRequestClose}
              type="button"
            />
          </div>

          <AddContactPersonForm
            onSubmit={updateContactPerson}
            submitButtonText={t('updateContactPeople.submitButton')}
            submitButtonIcon={<Icon name="save" />}
            onFormDirty={() => setFormDirty(true)}
            initialValues={{
              name: data.contactPerson.name || '',
              phone: data.contactPerson.phone || '',
              note: data.contactPerson.note || '',
              email: data.contactPerson.email || '',
            }}
            onRemoveButtonClick={handleContactPersonRemoveButtonClick}
          />

          <ContactPersonConfirmationModal
            title={t('updateContactPeople.confirmationModal.title')}
            body={t('updateContactPeople.confirmationModal.body')}
            dismissButtonText={t('updateContactPeople.confirmationModal.dismissButton')}
            confirmButtonText={t('updateContactPeople.confirmationModal.confirmButton')}
            open={confirmationModalVisible}
            onRequestClose={() => setOpen(false)}
            onDismissButtonClick={() => setConfirmationModalVisible(false)}
          />

          <ContactPersonConfirmationModal
            title={t('removeContactPeople.confirmationModal.title')}
            body={t('removeContactPeople.confirmationModal.body')}
            dismissButtonText={t('removeContactPeople.confirmationModal.dismissButton')}
            confirmButtonText={t('removeContactPeople.confirmationModal.confirmButton')}
            open={removeModalVisible}
            onRequestClose={removeContactPerson}
            onDismissButtonClick={() => setRemoveModalVisible(false)}
          />
        </div>
      )}
    </Dialog>
  );
}
