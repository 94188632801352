import { Details, Divider, Summary, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import AcceptanceReportLogsListDetail from './AcceptanceReportLogsListDetail';

interface AcceptanceReportLogListProps {
  acceptanceReportId: string;
}

export default function AcceptanceReportLogList({
  acceptanceReportId,
}: AcceptanceReportLogListProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Details
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="label-large">{t('documentLogsDetail.title')}</Text>
        </Summary>
      )}
    >
      <Divider role="none" />
      <AcceptanceReportLogsListDetail acceptanceReportId={acceptanceReportId} />
    </Details>
  );
}
