import { Box, Tab, Tabs, Text } from '@hs-baumappe/legacy-ui';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useRiskAssessmentSignDetail from '../../hooks/useRiskAssessmentSignDetail/useRiskAssessmentSignDetail';
import useRiskAssessmentSign from '../../hooks/useRiskAssessmentSign/useRiskAssessmentSign';
import ErrorState from '../../../../../containers/ErrorState';
import { RiskAssessmentSignatureType } from '../../../../../globalTypes';
import SignatureForm from '../../../../../components/SignatureForm';

interface RiskAssessmentSignBodyProps {
  riskAssessmentId: string;
  managerSignPermitted: boolean;
  onFormDirty: () => void;
}

export default function RiskAssessmentSignBody({
  riskAssessmentId,
  managerSignPermitted,
  onFormDirty,
}: RiskAssessmentSignBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { sign, loading: signing, error: signError } = useRiskAssessmentSign(riskAssessmentId);
  const {
    viewerSignatureBase64,
    managerSignature,
    employeeSignature,
    employeeNames,
    loading,
    error,
  } = useRiskAssessmentSignDetail(riskAssessmentId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  const managerSignatureFullName = managerSignature ? managerSignature.fullName : undefined;
  const managerResignable = !!managerSignature && !employeeSignature;
  const viewerFullName = viewer ? `${viewer.firstName} ${viewer.lastName}` : undefined;
  const defaultSelectedTabId =
    !managerSignature && managerSignPermitted
      ? RiskAssessmentSignatureType.MANAGER
      : RiskAssessmentSignatureType.EMPLOYEE;

  return (
    <>
      {signError && <ErrorState error={signError} />}

      <Tabs defaultSelectedTabId={defaultSelectedTabId}>
        <Tab
          id={RiskAssessmentSignatureType.MANAGER}
          label={t('signRiskAssessmentModal.tab.manager')}
          disabled={!managerSignPermitted}
          panel={
            <Box stylex={{ marginTop: 'small-x' }}>
              <SignatureForm
                initialValues={{
                  name: managerSignatureFullName || viewerFullName || '',
                  signature: managerSignature?.imagePath ?? '',
                }}
                leftSection={
                  <Text component="div" variant="body-small">
                    {t('signRiskAssessmentModal.manager.description')}
                    <Text component="strong" variant="body-small">
                      {t('signRiskAssessmentModal.manager.important')}
                    </Text>
                  </Text>
                }
                lastSignature={viewerSignatureBase64}
                readOnly={!!managerSignature}
                resignable={managerResignable}
                loading={signing}
                onSubmit={(values) =>
                  sign({
                    type: RiskAssessmentSignatureType.MANAGER,
                    values,
                  })
                }
                onDirtyStateChange={onFormDirty}
              />
            </Box>
          }
        />

        <Tab
          id={RiskAssessmentSignatureType.EMPLOYEE}
          label={t('signRiskAssessmentModal.tab.employee')}
          disabled={!managerSignature}
          panel={
            <Box stylex={{ marginTop: 'small-x' }}>
              <SignatureForm
                leftSection={
                  <Text component="div" variant="body-small" stylex={{ marginTop: 'medium' }}>
                    {t('signRiskAssessmentModal.employee.description')}
                  </Text>
                }
                signatureInputLabel={t('riskAssessmentSignatureForm.employeeSignature.label')}
                names={employeeNames}
                loading={signing}
                onSubmit={(values) =>
                  sign({
                    type: RiskAssessmentSignatureType.EMPLOYEE,
                    values,
                  })
                }
                onDirtyStateChange={onFormDirty}
              />
            </Box>
          }
        />
      </Tabs>
    </>
  );
}
