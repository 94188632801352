import { ApolloError, useMutation } from '@apollo/client';

import { UPDATE_FRAMEWORK_FOLDER_MUTATION } from './graphql';
import { GET_OFFER_DATA } from '../../../Detail/graphql';

import { BaseValues } from '../../containers/FolderForm/values';
import { cast, clearEmptyTotal } from '../../../measurement/MeasurementSection/values';
import { castNote } from '../../containers/FolderForm/form';

export interface FolderEditForFrameworkProps {
  folderId: string;
  offerId: string;
  onSuccess: () => void;
}

interface UseOfferFolderEditForFrameworkParams {
  error?: ApolloError;
  editFolder: (values: BaseValues) => Promise<void>;
}

const useOfferFolderEditForFramework = ({
  folderId,
  offerId,
  onSuccess,
}: FolderEditForFrameworkProps): UseOfferFolderEditForFrameworkParams => {
  const [mutation, { loading, error }] = useMutation(UPDATE_FRAMEWORK_FOLDER_MUTATION);

  const editFolder = async (values: BaseValues) => {
    if (loading) {
      return;
    }

    const { measurements, note } = values;
    const unit = measurements?.length ? measurements[0].type : undefined;

    const castedMeasurements = clearEmptyTotal(cast(measurements));
    const castedNotes = castNote(note);

    await mutation({
      variables: {
        input: {
          folder: folderId,
          name: values.name,
          note: castedNotes,
          approximate: values.approximate,
          unit,
          measurements: castedMeasurements,
          category: values.category,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    onSuccess();
  };

  return { error, editFolder };
};

export default useOfferFolderEditForFramework;
