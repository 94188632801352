import { useCallback, useEffect, useRef, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import useNavigator from '../../../hooks/useNavigator/useNavigator';
import useCanvas from './useCanvas';
import useSelectedTool from './useSelectedTool';

type UseCanvasMover = {
  startMoving: () => void;
  endMoving: () => void;
  canMove: boolean;
  moving: boolean;
};

function useCanvasMover(): UseCanvasMover {
  const { touchScreen } = useNavigator();
  const { canvas, setReadOnly, setMoveModeEnabled } = useCanvas();
  const { setSelectedTool, selectedTool } = useSelectedTool();
  const [canMove, setCanMove] = useState(false);
  const moving = useRef(false);
  const lastClientPoint = useRef(new fabric.Point(0, 0));

  const startMoving = useCallback(() => {
    if (!canvas) {
      return;
    }

    setCanMove(true);
    setReadOnly(true);
    setSelectedTool(undefined);
    setMoveModeEnabled(true);

    canvas.selection = false;
    canvas.isDrawingMode = false;
    canvas.discardActiveObject();
  }, [setCanMove, canvas, setReadOnly, setSelectedTool, setMoveModeEnabled]);

  const endMoving = useCallback(() => {
    if (!canvas) {
      return;
    }

    setCanMove(false);
    setMoveModeEnabled(false);

    canvas.setCursor('default');
    canvas.defaultCursor = 'default';
  }, [setCanMove, canvas, setMoveModeEnabled]);

  useEffect(() => {
    if (moving.current && selectedTool) {
      endMoving();
    }
  }, [moving, selectedTool, endMoving]);

  useEffect(() => {
    if (selectedTool) {
      endMoving();
    }
  }, [endMoving, setSelectedTool, selectedTool]);

  const handleCanvasMouseDown = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || !canMove) {
        return;
      }

      const mouseEvent = event.e as MouseEvent;

      moving.current = true;
      lastClientPoint.current = new fabric.Point(mouseEvent.clientX, mouseEvent.clientY);
      canvas.defaultCursor = 'grabbing';
      canvas.selection = false;
    },
    [canvas, moving, canMove],
  );

  const handleCanvasMouseUp = useCallback(() => {
    if (!canvas || !canMove) {
      return;
    }

    canvas.selection = true;
    canvas.defaultCursor = 'grab';
    moving.current = false;
  }, [canvas, moving, canMove]);

  const handleCanvasMouseMove = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || !moving.current) {
        return;
      }

      const { clientX, clientY } = event.e as MouseEvent;
      const delta = new fabric.Point(0, 0);
      const { x, y } = lastClientPoint.current;

      delta.setX(clientX - x);
      delta.setY(clientY - y);

      lastClientPoint.current = new fabric.Point(clientX, clientY);

      canvas.relativePan(delta);
    },
    [canvas, moving],
  );

  // To change move gestures for touch screens see useCanvasGestures.
  useEffect(() => {
    if (!canvas || touchScreen) {
      return;
    }

    canvas.on('mouse:down', handleCanvasMouseDown);
    canvas.on('mouse:up', handleCanvasMouseUp);
    canvas.on('mouse:move', handleCanvasMouseMove);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.off('mouse:down', handleCanvasMouseDown);
      canvas.off('mouse:up', handleCanvasMouseUp);
      canvas.off('mouse:move', handleCanvasMouseMove);
    };
  }, [canvas, handleCanvasMouseDown, handleCanvasMouseUp, handleCanvasMouseMove, touchScreen]);

  return {
    startMoving,
    endMoving,
    moving: moving.current,
    canMove,
  };
}

export default useCanvasMover;
