import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentSignDetail,
  RiskAssessmentSignDetailVariables,
} from './__generated__/RiskAssessmentSignDetail.query';

const RiskAssessmentSignDetailQuery: TypedDocumentNode<
  RiskAssessmentSignDetail,
  RiskAssessmentSignDetailVariables
> = gql`
  query RiskAssessmentSignDetail($riskAssessmentId: ID!) {
    riskAssessment(id: $riskAssessmentId) {
      id

      no

      signatures {
        id

        type
        fullName
        imagePath
        createdAt
      }

      project {
        id
      }
    }

    viewer {
      id

      signature
    }
  }
`;

export default RiskAssessmentSignDetailQuery;
