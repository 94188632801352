import { ApolloError, useQuery } from '@apollo/client';
import GetDocumentPSPDFKitTokenQuery from '../../graphql/GetDocumentPSPDFKitToken.query';

interface UsePDFManipulatorTokenOptions {
  documentId: string;
  readOnly: boolean;
}

interface UsePDFManipulatorTokenResponse {
  token?: string;
  loading: boolean;
  error?: ApolloError;
  refresh: () => Promise<string>;
}

export default function usePDFManipulatorToken({
  documentId,
  readOnly,
}: UsePDFManipulatorTokenOptions): UsePDFManipulatorTokenResponse {
  const { data, loading, error, refetch } = useQuery(GetDocumentPSPDFKitTokenQuery, {
    variables: {
      input: {
        documentId,
        requestEdit: !readOnly,
      },
    },
    fetchPolicy: 'network-only',
  });

  async function refresh() {
    const { data: newData } = await refetch();
    if (!newData) {
      throw new Error('Token can not be refreshed, see Apollo error for details.');
    }

    return newData.getPspdfkitAuthToken.pspdfkitAuthToken;
  }

  return {
    token: data?.getPspdfkitAuthToken.pspdfkitAuthToken,
    loading,
    error,
    refresh,
  };
}
