import { PropsWithChildren, useRef } from 'react';
import { Alert } from '@hs-baumappe/legacy-ui';
import useCanvasAlert from '../hooks/useCanvasAlert';
import NoteEditorLayout from '../../../components/note-editor/NoteEditorLayout';
import Canvas from '../Canvas';
import NoteEditorToolbar from '../../../components/note-editor/NoteEditorToolbar';
import UndoAction from '../actions/UndoAction';
import RedoAction from '../actions/RedoAction';
import RotateAction from '../actions/RotateAction';
import BackwardAction from '../actions/BackwardAction';
import ForwardAction from '../actions/ForwardAction';
import DeleteAction from '../actions/DeleteAction';
import ZoomActions from '../actions/ZoomActions';
import CreateTool from '../tools/CreateTool';
import TextTool from '../tools/TextTool';
import PenTool from '../tools/PenTool';
import EraseTool from '../tools/EraseTool';
import ImageTool from '../tools/ImageTool';

interface CreateLayoutProps {
  initialTitle: string;
  documentId: string;
  projectId: string;
}

export default function CreateLayout({
  initialTitle,
  documentId,
  projectId,
}: PropsWithChildren<CreateLayoutProps>): JSX.Element {
  const canvasAreaRef = useRef<HTMLDivElement | null>(null);

  const { alertMessage, alertColor } = useCanvasAlert();

  return (
    <>
      {alertMessage && (
        <Alert color={alertColor} stylex={{ marginBottom: 'medium' }}>
          {alertMessage}
        </Alert>
      )}

      <NoteEditorLayout
        className="u-height-100%"
        canvasAreaRef={canvasAreaRef}
        canvasArea={<Canvas canvasAreaRef={canvasAreaRef} />}
        firstToolbarArea={
          <NoteEditorToolbar directionModifier="column@md-down">
            <UndoAction />
            <RedoAction />
            <RotateAction />
            <BackwardAction />
            <ForwardAction />
            <DeleteAction />
          </NoteEditorToolbar>
        }
        secondToolbarArea={<ZoomActions />}
        thirdToolbarArea={
          <NoteEditorToolbar type="tool" directionModifier="column">
            <CreateTool initialTitle={initialTitle} documentId={documentId} />
            <TextTool createMode />
            <PenTool />
            <EraseTool />
            <ImageTool projectId={projectId} />
          </NoteEditorToolbar>
        }
      />
    </>
  );
}
