import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import LogContainer, { Log } from '../../../../../containers/LogContainer';

import ErrorState from '../../../../../containers/ErrorState';
import logsDateMapper from '../../../../../containers/LogContainer/LogContainer.utils';
import RiskAssessmentLogsQuery from '../../../graphql/RiskAssessmentLogs.query';

interface RiskAssessmentLogsDetailProps {
  riskAssessmentId: string;
}

export default function RiskAssessmentLogsDetail({
  riskAssessmentId,
}: RiskAssessmentLogsDetailProps): JSX.Element {
  const [logs, setLogs] = useState<Log[]>([]);
  const { data, error, loading, refetch } = useQuery(RiskAssessmentLogsQuery, {
    variables: { riskAssessmentId, page: 1, limit: 10 },
  });

  useEffect(() => {
    if (!data || !data.riskAssessment.logs) {
      return;
    }

    const logsData = data.riskAssessment.logs.logs;

    if (!logsData.length) {
      setLogs([]);
      return;
    }

    if (!logs.length) {
      setLogs([...data.riskAssessment.logs.logs]);
      return;
    }

    const currentPage = data.riskAssessment.logs.pagination.current;
    const result = currentPage > 1 ? logs : [];

    logsData.forEach((log) => {
      if (!result.find((f) => f.id === log.id)) {
        result.push(log);
      }
    });

    setLogs(Array.from(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMoreDataClick = async () => {
    if (!data?.riskAssessment.logs) {
      return;
    }

    await refetch({
      page: data?.riskAssessment.logs.pagination.current + 1,
      limit: 10,
    });
  };

  const logsDateData = logsDateMapper(logs);
  const hasNextPage =
    !loading && !!data?.riskAssessment.logs && data?.riskAssessment.logs.pagination.hasNextPage;

  if (error) {
    return <ErrorState error={error} />;
  }

  return (
    <LogContainer
      loading={loading}
      logs={logsDateData}
      hasNextPage={hasNextPage}
      onMoreDataClick={handleMoreDataClick}
    />
  );
}
