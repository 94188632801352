import { createPath } from 'react-router-dom';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { DocumentType } from '../../../globalTypes';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { projectRoutes } from '../../routes';

export type PDFSource = 'original' | 'signed-manually';

export enum AcceptanceReportDetailAlert {
  SEND_EMAIL = 'send',
  ARCHIVE = 'archived',
  SIGNED_MANUALLY = 'signedManually',
}

export enum AcceptanceReportDetailSearchParams {
  ALERT = 'alert',
  SHOW_ORIGINAL_PDF = 'showOriginalPdf',
}

export function createDocumentAssignPath(
  acceptanceReport: AcceptanceReport,
  projectId: string,
): string {
  const assignRouteSearchParams = new URLSearchParams({
    document: DocumentType.ACCEPTANCE_REPORT,
    returnPath: acceptanceReportRoutes.detail.generatePath({
      acceptanceReportId: acceptanceReport.id,
    }),
  });

  return createPath({
    pathname: projectRoutes.assignDocumentToProject.generatePath({
      projectId,
      documentId: acceptanceReport.id,
    }),
    search: assignRouteSearchParams.toString(),
  });
}
