import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Text,
} from '@hs-baumappe/legacy-ui';
import routes, { projectRoutes } from '../../routes/routes';
import { ProjectDetailLocationState } from '../../routes/Project/ProjectDetail/ProjectDetail.route.types';
import { DriveDocumentParentFolders } from '../../hooks/useDriveDocumentParentFolders';

import styles from './DocumentDetailBreadcrumbs.module.scss';

interface DocumentDetailBreadcrumbsProps {
  projectId: string;
  projectNumber: string;
  parentFolders: DriveDocumentParentFolders;
}

export default function DocumentDetailBreadcrumbs({
  projectId,
  projectNumber,
  parentFolders,
}: DocumentDetailBreadcrumbsProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [directParentFolder, ...folderParentFolders] = parentFolders.toSorted(
    (a, b) => b.depth - a.depth,
  );

  return (
    <Breadcrumb>
      <BreadcrumbItem component={NavLink} to={routes.projects.generatePath({})}>
        {t('breadcrumb.project')}
      </BreadcrumbItem>

      <BreadcrumbItem component={NavLink} to={projectRoutes.detail.generatePath({ projectId })}>
        {projectNumber}
      </BreadcrumbItem>

      {folderParentFolders.length > 0 && (
        <BreadcrumbItem component="div">
          <Menu
            open={expanded}
            renderTrigger={(triggerProps) => (
              <IconButton
                {...triggerProps}
                size="small"
                onClick={() => setExpanded(!expanded)}
                icon={<Icon name="more-horiz" aria-hidden="true" />}
              />
            )}
            onRequestClose={() => setExpanded(false)}
          >
            {folderParentFolders.map(({ id: folderId, name }) => (
              <MenuItem
                key={folderId}
                onClick={() =>
                  navigate(projectRoutes.driveWithFolder.generatePath({ projectId, folderId }), {
                    state: { scrollToDrive: true } satisfies ProjectDetailLocationState,
                  })
                }
                leftSection={<Icon name="folder" size={20} />}
              >
                <Text variant="label-medium" title={name} lineClamp={2}>
                  {name}
                </Text>
              </MenuItem>
            ))}
          </Menu>
        </BreadcrumbItem>
      )}

      {directParentFolder && (
        <BreadcrumbItem
          className={styles['document-detail-breadcrumbs__folder-breadcrumb']}
          component={NavLink}
          to={projectRoutes.driveWithFolder.generatePath({
            projectId,
            folderId: directParentFolder.id,
          })}
          state={{ scrollToDrive: true } satisfies ProjectDetailLocationState}
        >
          <Text component="div" variant="label-medium" title={directParentFolder.name} truncate>
            {directParentFolder.name}
          </Text>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}
