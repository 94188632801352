import { ApolloError, useQuery } from '@apollo/client';
import SIVDiscountDetailQuery from './graphql/SIVDiscountDetail.query';

interface SIVDiscount {
  amount?: number;
  active: boolean;
}

interface UseSIVDiscountDetailResponse {
  discount: SIVDiscount;
  totalPerformanceCostPlannedRaw: number;
  totalPerformanceCostPlanned: number;
  totalPerformanceCostRecorded: number;
  loading: boolean;
  error?: ApolloError;
}

interface UseSIVDiscountDetailOptions {
  projectId: string;
  skip?: boolean;
}

export default function useSIVDiscountDetail({
  projectId,
  skip,
}: UseSIVDiscountDetailOptions): UseSIVDiscountDetailResponse {
  const { data, loading, error } = useQuery(SIVDiscountDetailQuery, {
    variables: {
      projectId,
    },
    skip,
  });

  const siv = data?.project.siv;
  const totalPerformanceCostPlannedRaw = siv?.cockpit?.totalPerformanceCost?.plannedRaw ?? 0;
  const totalPerformanceCostPlanned = siv?.cockpit?.totalPerformanceCost?.planned ?? 0;
  const totalPerformanceCostRecorded = siv?.cockpit?.totalPerformanceCost?.recorded ?? 0;

  return {
    discount: {
      amount: siv?.discount.amount ?? undefined,
      active: siv?.discount.active ?? false,
    },
    totalPerformanceCostPlannedRaw,
    totalPerformanceCostPlanned,
    totalPerformanceCostRecorded,
    loading,
    error,
  };
}
