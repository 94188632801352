import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { ObstructionStatus } from '../../../../../globalTypes';

interface ObstructionSignModalTitleProps {
  obstructionName: string;
  projectDescription: string;
  status: ObstructionStatus;
}

export default function ObstructionSignModalTitle({
  projectDescription,
  obstructionName,
  status,
}: ObstructionSignModalTitleProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body-medium" component="div" color="muted">
        {projectDescription}
      </Text>
      <Text variant="title-small" component="h2" className="u-margin-bottom-0">
        {t('obstructionSign.modalTitle', { obstructionName })}
      </Text>

      <Text variant="title-small" component="div" color="muted">
        {t('obstructionSign.status', { status: t(status) })}
      </Text>
    </>
  );
}
