import { ApolloError, useQuery } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import AcceptanceReportDetailQuery from './graphql/AcceptanceReportDetail.query';
import { AcceptanceReport } from '../../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportScopes } from '../../AcceptanceReport.types';
import { AcceptanceReportProject } from '../../graphql/__generated__/AcceptanceReportProject.fragment';

interface UseAcceptanceReportDataResponse {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  scopes: AcceptanceReportScopes;
}

interface UseAcceptanceReportResponse {
  data?: UseAcceptanceReportDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useAcceptanceReport(
  acceptanceReportId: string,
): UseAcceptanceReportResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(AcceptanceReportDetailQuery, {
    variables: {
      acceptanceReportId,
    },
  });

  const acceptanceReport = data?.acceptanceReport;
  const project = data?.acceptanceReport.project;
  const scopes = viewer?.scopes;

  return {
    data: acceptanceReport && project && scopes ? { acceptanceReport, project, scopes } : undefined,
    loading,
    error,
  };
}
