import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useAcceptanceReportEditDetail from '../../hooks/useAcceptanceReportEditDetail';
import useProfessions from '../../../../../hooks/useProfessions';
import ErrorState from '../../../../../containers/ErrorState';
import AcceptanceReportForm from '../../../forms/AcceptanceReportForm';
import { AcceptanceReportProject } from '../../../graphql/__generated__/AcceptanceReportProject.fragment';
import { AcceptanceReportFormValues } from '../../../forms/AcceptanceReportForm/AcceptanceReportFormValues';
import { getAcceptanceReportInitialFormValues } from '../../AcceptanceReportEdit.utils';
import useAcceptanceReportSave from '../../../hooks/useAcceptanceReportSave';

interface AcceptanceReportEditBodyProps {
  acceptanceReportId: string;
  project: AcceptanceReportProject;
  formDirty: boolean;
  onFormDirtyStateChange: (dirty: boolean) => void;
  onRequestClose: () => void;
}

export default function AcceptanceReportEditBody({
  acceptanceReportId,
  project,
  formDirty,
  onFormDirtyStateChange,
  onRequestClose,
}: AcceptanceReportEditBodyProps): JSX.Element | null {
  const { t } = useTranslation();

  const { acceptanceReport, contactPeople, loading, error } =
    useAcceptanceReportEditDetail(acceptanceReportId);
  const { professions } = useProfessions();
  const { save, loading: saving, error: saveError } = useAcceptanceReportSave(acceptanceReportId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!acceptanceReport) {
    return null;
  }

  function handleFormSubmit(
    values: AcceptanceReportFormValues,
    helpers: FormikHelpers<AcceptanceReportFormValues>,
  ) {
    if (!formDirty) {
      onRequestClose();
      return;
    }

    save(values, helpers);
  }

  return (
    <>
      {saveError && <ErrorState error={saveError} />}

      <AcceptanceReportForm
        initialValues={getAcceptanceReportInitialFormValues(acceptanceReport)}
        projectId={project.id}
        draft={project.draft}
        contactPeople={contactPeople}
        professions={professions}
        loading={saving}
        submitButtonLabel={t('acceptanceReport.edit.submitButton')}
        onSubmit={handleFormSubmit}
        onDirtyStateChange={onFormDirtyStateChange}
      />
    </>
  );
}
