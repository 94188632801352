import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CATALOGUE_FRAGMENT = gql`
  fragment CatalogueFragment on Catalogue {
    id
    name
    allCategories {
      id
      name
      partNo
      parent {
        id
      }
    }
  }
`;
