import { AbstractFullScreenTool } from '@hs-baumappe/pspdfkit';

export class LayoutFullScreenTool extends AbstractFullScreenTool {
  constructor(
    locale: string,
    private handler: () => void,
  ) {
    super(locale);
  }

  protected handlePress(): void {
    this.handler();
  }
}
