import { SchemaOf } from 'yup';
import { PaymentFormValues } from './values';
import { DiscountOrSurchargeValueType, OperationPaymentInput } from '../../../../globalTypes';
import { evalValue } from '../../measurement/MeasurementSection/values';
import yup from '../../../../yup';

export const paymentMutationValidationSchema = (): SchemaOf<PaymentFormValues> =>
  yup.object({
    totalPrice: yup.string(),
    flat: yup.boolean().required().default(false),
    discountOrSurchargeValue: yup.string(),
    discountOrSurchargeValueType: yup
      .mixed()
      .default(DiscountOrSurchargeValueType.PERCENTAGE)
      .oneOf([DiscountOrSurchargeValueType.AMOUNT, DiscountOrSurchargeValueType.PERCENTAGE]),
  });

export const castPaymentToAddOperationToFolder = (
  payment?: PaymentFormValues,
): OperationPaymentInput | null | undefined => {
  if (!payment) {
    return null;
  }

  const { flat, totalPrice, discountOrSurchargeValue, discountOrSurchargeValueType } = payment;

  if (!totalPrice && !discountOrSurchargeValue) {
    return null;
  }

  if (flat) {
    return {
      flat,
      totalPrice: parseFloat(totalPrice || '0'),
    };
  }

  if (discountOrSurchargeValue) {
    return {
      flat,
      discountOrSurchargeValue: evalValue(discountOrSurchargeValue),
      discountOrSurchargeValueType,
    };
  }

  return undefined;
};
