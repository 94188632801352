import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { AcceptanceReportLocationState } from '../AcceptanceReport.route.types';
import RouteDialog from '../../../containers/RouteDialog';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportScopes } from '../AcceptanceReport.types';
import AcceptanceReportSendExternalLinkEmailBody from './components/AcceptanceReportSendExternalLinkEmailBody';
import { AcceptanceReportProject } from '../graphql/__generated__/AcceptanceReportProject.fragment';

interface AcceptanceReportSendExternalLinkEmailProps {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  scopes: AcceptanceReportScopes;
}

export default function AcceptanceReportSendExternalLinkEmail({
  acceptanceReport,
  project,
  scopes,
}: AcceptanceReportSendExternalLinkEmailProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<AcceptanceReportLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(
      acceptanceReportRoutes.detail.generatePath({ acceptanceReportId: acceptanceReport.id }),
    );
  }, [acceptanceReport.id, location.state?.backgroundLocation, navigate]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  const permitted = scopes.sendAcceptanceReportDocumentSignatureMail.isPermitted;
  const missingScopeNames = scopes.sendAcceptanceReportDocumentSignatureMail.missingScopeNames;

  if (!acceptanceReport.viewerCanSendExternalLink) {
    return (
      <Navigate
        to={acceptanceReportRoutes.detail.generatePath({ acceptanceReportId: acceptanceReport.id })}
        replace
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {acceptanceReport.name}
        </Text>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <AcceptanceReportSendExternalLinkEmailBody
          acceptanceReport={acceptanceReport}
          project={project}
          onFormDirty={() => setFormDirty(true)}
          onDocumentIdsValueChange={() => setDialogOpen(false)}
        />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('acceptanceReportSendExternalEmail.confirmationDialog.title')}
        description={t('acceptanceReportSendExternalEmail.confirmationDialog.body')}
        confirmButtonText={t('acceptanceReportSendExternalEmail.confirmationDialog.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('acceptanceReportSendExternalEmail.confirmationDialog.cancelButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
