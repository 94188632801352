import { lazy, Suspense, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import useCreateOvertimeDetail from './useCreateOvertimeDetail';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorBoundary from '../../../ErrorBoundary';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';

interface CreateNewOvertimeProps {
  documentId: string;
}

// eslint-disable-next-line import/no-unresolved
const OvertimeCreate = lazy(() => import('overtimeWeb/OvertimeCreate'));

function CreateNewOvertime({ documentId }: CreateNewOvertimeProps): JSX.Element {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const { project } = useCreateOvertimeDetail(projectId);

  const handleDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          {project && (
            <Text variant="body-medium" component="div" color="muted">
              {project.description}
              {project.additionalName && ' | ' + project.additionalName}
            </Text>
          )}
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('createNewOvertime.title')}
          </Text>
        </>
      }
    >
      <ErrorBoundary fallback={<Alert color="error">{t('overtimeMFLoadError')}</Alert>}>
        <Suspense fallback={<LayoutLoading />}>
          <OvertimeCreate
            projectId={projectId}
            documentId={documentId}
            onDirtyStateChange={setFormDirty}
          />
        </Suspense>
      </ErrorBoundary>

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('createNewOvertime.closeModal.title')}
        description={
          <>
            <Text component="div">{t('createNewOvertime.closeModal.content.question.info')}</Text>
            <Text component="div">{t('createNewOvertime.closeModal.content.question.p')}</Text>
          </>
        }
        confirmButtonText={t('createNewOvertime.closeModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('createNewOvertime.closeModal.cancelButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateNewOvertime, {
  mimeType: DriveSpecialDocumentMimeTypes.OVERTIME,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
