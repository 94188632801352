import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Search, Text } from '@hs-baumappe/legacy-ui';

import ProjectsTable from './ProjectsTable';
import useHsProjectSearch from './useHsProjectSearch';
import { HsProject } from './__generated__/graphql';

interface Props {
  onClickProjectSelect: (project?: HsProject) => void;
}

const ProjectSearch: FC<Props> = ({ onClickProjectSelect }) => {
  const { t } = useTranslation();
  const { searchProject, projects } = useHsProjectSearch();
  const [activeProject, setActiveProject] = useState<HsProject>();
  const [activeProjectIcon, setActiveProjectIcon] = useState<HsProject>();

  const EMPTY_TEXT = t('assignOfferToProjectImport.projectSearch.emptyText');

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    searchProject(event.target.value);
  }

  const handleClickProject = (project: HsProject) => {
    if (activeProject === project) {
      setActiveProject(undefined);
      return;
    }

    setActiveProject(project);
    setActiveProjectIcon(project);
  };

  return (
    <div className="row">
      <div className="col col--sm-8">
        <div className="row">
          <div className="col col--sm-6">
            <Text>{t('assignOfferToProjectImport.projectSearch.description')}</Text>
          </div>
        </div>
        <div className="u-margin-top-large">
          <Search
            id="assign-offer-to-project-import-search"
            fieldProps={{
              label: t('assignOfferToProjectImport.search.label'),
              placeholder: t('assignOfferToProjectImport.search.placeholder'),
              onChange: handleSearchChange,
            }}
          />

          <ProjectsTable
            emptyText={EMPTY_TEXT}
            activeProject={activeProject}
            activeProjectIcon={activeProjectIcon}
            projects={projects || []}
            onClickProject={handleClickProject}
          />
        </div>
      </div>
      <div className="col col--sm-4">
        <Button
          id="project-import-submit-button"
          disabled={!activeProject}
          onClick={() => onClickProjectSelect(activeProject)}
          color="primary"
          fullWidth
          endIcon={<Icon name="save" />}
        >
          {t('assignOfferToProjectImport.importProject')}
        </Button>
      </div>
    </div>
  );
};

export default ProjectSearch;
