import { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert, useTimer } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { GetConstructionNoteDetail } from '../graphql/__generated__/GetConstructionNoteDetail.query';

interface UseConstructionNoteDetailAlertsResponse {
  alerts: ReactNode[];
}

interface SuccessAlertMessage {
  display: boolean;
  key: string;
  message: ReactNode;
}

function renderSuccessAlerts(successAlertMessages: SuccessAlertMessage[]) {
  return successAlertMessages
    .filter((item) => item.display)
    .map(({ message, key }) => (
      <Alert key={key} stylex={{ marginBottom: 'medium' }} color="success">
        {message}
      </Alert>
    ));
}

export function useConstructionNoteDetailAlerts(
  constructionNote?: GetConstructionNoteDetail['constructionNote'],
): UseConstructionNoteDetailAlertsResponse {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  const archive = searchParams.get('archive');
  const sendMail = searchParams.get('send-mail');
  const noteCreated = searchParams.get('noteCreated');
  const noteEdited = searchParams.get('noteEdited');

  const alertIsShown = archive || sendMail || noteCreated || noteEdited;

  useEffect(() => {
    if (alertIsShown) {
      setTimeoutTimer(() => setSearchParams(new URLSearchParams(), { replace: true }), 5000);
    }

    return () => {
      clearAllTimers();
    };
  }, [alertIsShown, clearAllTimers, setSearchParams, setTimeoutTimer]);

  const successAlertMessages: SuccessAlertMessage[] = constructionNote
    ? [
        {
          key: 'archive',
          display: !!archive,
          message: t('constructionNote.archive.successMessage', { name: constructionNote.name }),
        },
        {
          key: 'send-mail',
          display: !!sendMail,
          message: t('constructionNote.email.successMessage'),
        },
        {
          key: 'note-created-or-edited',
          display: !!noteCreated || !!noteEdited,
          message: t('constructionNote.successMessageOnCreatedOrEdited'),
        },
      ]
    : [];

  return {
    alerts: renderSuccessAlerts(successAlertMessages),
  };
}
