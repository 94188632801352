import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';

import CustomFileDocumentEmailSendMutation from './graphql/CustomFileDocumentEmailSend.mutation';
import { CustomFileDocumentSendEmailVariables } from './graphql/__generated__/CustomFileDocumentEmailSend.mutation';
import { DocumentDetailAlert } from '../../../DocumentDetail/DocumentDetail.utils';
import { EmailFormValues } from '../../../../../containers/Email';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import documentRoutes from '../../../documentRoutes';
import CustomFileDocumentDetailQuery from '../../../graphql/CustomFileDocumentDetail.query';

interface UseDocumentSendEmailResponse {
  sendEmail: (
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useCustomFileDocumentSendEmail(
  documentId: string,
): UseDocumentSendEmailResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError>();
  const [mutation, { loading }] = useMutation(CustomFileDocumentEmailSendMutation, {
    refetchQueries: [{ query: CustomFileDocumentDetailQuery, variables: { id: documentId } }],
  });

  async function sendEmail(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    if (loading) {
      return;
    }

    try {
      await mutation({
        variables: {
          input: {
            id: documentId,
            to: values.toEmails,
            subject: values.subject,
            content: values.content,
            cc: values.ccEmails,
          },
        },
      });

      const searchParams = new URLSearchParams({
        alert: DocumentDetailAlert.SEND_EMAIL,
      });

      navigate(
        createPath({
          pathname: documentRoutes.manipulate.generatePath({ documentId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        CustomFileDocumentSendEmailVariables['input']
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.to,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendEmail,
    loading,
    error,
  };
}
