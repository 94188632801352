import { forwardRef } from 'react';
import cx from 'classnames';

export type TbodyProps = JSX.IntrinsicElements['tbody'] & {
  dataTestId?: string;
};

export default forwardRef<HTMLTableSectionElement, TbodyProps>(function Tbody(
  { className, children, dataTestId, ...otherProps },
  ref,
) {
  return (
    <tbody
      className={cx('c-table__tbody', className)}
      data-testid={dataTestId}
      {...otherProps}
      ref={ref}
    >
      {children}
    </tbody>
  );
});
