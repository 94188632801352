import {
  Box,
  Divider,
  FlexGrid,
  FlexGridColumn,
  Paper,
  Text,
  Toolbar,
} from '@hs-baumappe/legacy-ui';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import ProjectDetailDetails from '../ProjectDetailDetails';
import ProjectClientInformation from '../ProjectClientInformation/ProjectClientInformation';
import ProjectInformation from '../ProjectInformation';
import ProjectStatusSelect from '../ProjectStatusSelect';
import ProjectSyncButton from '../ProjectSyncButton/ProjectSyncButton';
import { GetProjectDetail } from '../../graphql/__generated__/GetProjectDetail.query';
import ProjectLog from '../ProjectLog';
import FollowProject from '../ProjectFollow';
import { GetPartProjectsInformation } from '../ProjectInformation/graphql/__generated__/GetPartProjectsInformation.query';
import { ProjectDetailTab } from '../../ProjectDetail.utils';

interface ProjectDetailInformationProps {
  project: GetProjectDetail['project'];
  partProjects: GetPartProjectsInformation['project']['partProjects'];
  partProjectSIV?: NonNullable<GetPartProjectsInformation['project']['siv']>;
  initiallyOpenTab?: ProjectDetailTab;
  onProjectSync: () => void;
  onRequestUnreadLogCount: () => void;
}

export default function ProjectDetailInformation({
  project,
  partProjects,
  partProjectSIV,
  initiallyOpenTab,
  onProjectSync,
  onRequestUnreadLogCount,
}: ProjectDetailInformationProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  if (!viewer) {
    return null;
  }

  const scopes = viewer?.scopes;
  const updateProjectStatusPermitted = scopes.updateProjectStatus.isPermitted;
  const updateProjectPermitted = scopes.updateProject.isPermitted;

  const viewSIVPartProjectsPermitted = scopes.viewSIVPartProjects.isPermitted;
  const updateSIVPartProjectsPermitted = scopes.updateSIVPartProjects.isPermitted;
  const viewSIVPricesPermitted = scopes.viewSIVPrices.isPermitted;
  const updateSIVDiscountPermitted = scopes.updateSIVDiscount.isPermitted;
  const viewSIVDiscountPermitted = scopes.viewSIVDiscount.isPermitted;

  return (
    <Box>
      <FlexGrid>
        <FlexGridColumn sm={3}>
          <ProjectStatusSelect project={project} disabled={!updateProjectStatusPermitted} />
        </FlexGridColumn>

        <FlexGridColumn md={{ size: 4, offset: 5 }} sm={{ size: 7, offset: 2 }}>
          <ProjectSyncButton
            projectId={project.id}
            projectOrdered={project.ordered}
            onProjectSync={onProjectSync}
            disabled={!project.syncable}
          />
        </FlexGridColumn>
      </FlexGrid>

      <Paper stylex={{ marginTop: 'small-2x' }}>
        <Toolbar
          rightSection={
            <FollowProject projectId={project.id} followed={project.viewerFollowsProject} />
          }
        >
          <Text
            component="div"
            variant="title-small"
          >{`${project.projectNumber} ${project.name}`}</Text>
        </Toolbar>

        <Divider component="hr" stylex={{ marginEnds: '0' }} />

        <Box stylex={{ padding: 'small-x' }}>
          <ProjectDetailDetails
            initiallyOpen={initiallyOpenTab === ProjectDetailTab.INFORMATION}
            title={
              <Text
                variant="title-small"
                color="muted"
                component="h3"
                stylex={{ marginBottom: '0' }}
              >
                {t('client.title')}
              </Text>
            }
            subTitle={
              project.client?.name && (
                <Text variant="body-small" color="black" stylex={{ marginLeft: 'small-x' }}>
                  {project.client.name}
                </Text>
              )
            }
          >
            <ProjectClientInformation
              projectId={project.id}
              viewerCanChangeProjectData={updateProjectPermitted}
            />
          </ProjectDetailDetails>
        </Box>

        <Divider component="hr" stylex={{ marginEnds: '0' }} />

        <Box stylex={{ padding: 'small-x' }}>
          <ProjectDetailDetails
            title={
              <Text
                variant="title-small"
                color="muted"
                component="h3"
                style={{ marginBottom: '0' }}
              >
                {t('projectDetail.projectInformation.title')}
              </Text>
            }
            subTitle={
              <Text variant="body-small" color="black" stylex={{ marginLeft: 'small-x' }}>
                {project.name}
              </Text>
            }
            className="qa-project-information-details"
          >
            <ProjectInformation
              projectId={project.id}
              sivId={project.siv?.id}
              updateProjectPermitted={updateProjectPermitted}
              updateSIVPartProjectsPermitted={updateSIVPartProjectsPermitted}
              viewSIVPartProjectsPermitted={viewSIVPartProjectsPermitted}
              updateSIVDiscountPermitted={updateSIVDiscountPermitted}
              viewSIVDiscountPermitted={viewSIVDiscountPermitted}
              viewSIVPricesPermitted={viewSIVPricesPermitted}
              partProjects={partProjects}
              siv={partProjectSIV}
            />
          </ProjectDetailDetails>
        </Box>

        <Divider component="hr" stylex={{ marginEnds: '0' }} />

        <Box stylex={{ padding: 'small-x' }}>
          <ProjectLog
            projectId={project.id}
            viewerLogUnreadCount={project.viewerLogUnreadCount}
            initiallyOpen={initiallyOpenTab === ProjectDetailTab.ACTIVITY_LOGS}
            onRequestReset={onRequestUnreadLogCount}
          />
        </Box>
      </Paper>
    </Box>
  );
}
