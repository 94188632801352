import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useRiskAssessmentArchive from './hooks/useRiskAssessmentArchive';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm';
import { RiskAssessment } from '../graphql/__generated__/RiskAssessment.fragment';
import { riskAssessmentRoutes } from '../riskAssessmentRoutes';
import { RiskAssessmentLocationState } from '../RiskAssessment.route.type';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';

interface RiskAssessmentArchiveProps {
  riskAssessment: RiskAssessment;
}

export default function RiskAssessmentArchive({
  riskAssessment,
}: RiskAssessmentArchiveProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const location = useLocation<RiskAssessmentLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(riskAssessment.id);
  const { archive, loading: archiving, error } = useRiskAssessmentArchive(riskAssessment.id);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(riskAssessmentRoutes.detail.generatePath({ riskAssessmentId: riskAssessment.id }));
  }, [location.state?.backgroundLocation, navigate, riskAssessment.id]);

  if (!riskAssessment.viewerCanArchive || !archiveEnabled) {
    return (
      <Navigate
        to={riskAssessmentRoutes.detail.generatePath({ riskAssessmentId: riskAssessment.id })}
        replace
      />
    );
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = [
    {
      id: riskAssessment.id,
      name: riskAssessment.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
          {t('riskAssessmentArchiveModal.title')}
        </Text>
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('archiveModals.informationText')}</Text>}
        rightSection={<Text variant="body-small">{t('archiveModals.warningText')}</Text>}
        submitButtonText={t('riskAssessmentArchiveForm.archive.button')}
        initialValues={{
          username: sapCredentials?.username,
          password: sapCredentials?.password,
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
