import { useMutation } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';
import { OfferFrameworkSave as OfferFrameworkSaveQuery } from './graphql';
import { OfferFrameworkSave, OfferFrameworkSaveVariables } from './__generated__/graphql';

export default function useOfferFrameworkSave(): MutationTuple<
  OfferFrameworkSave,
  OfferFrameworkSaveVariables
> {
  return useMutation<OfferFrameworkSave, OfferFrameworkSaveVariables>(OfferFrameworkSaveQuery);
}
