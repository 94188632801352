import { gql, TypedDocumentNode } from '@apollo/client';
import {
  QsstArchiveMutation as QsstArchiveMutationData,
  QsstArchiveMutationVariables,
} from './__generated__/qsstArchiveMutation';

const QsstArchiveMutation: TypedDocumentNode<
  QsstArchiveMutationData,
  QsstArchiveMutationVariables
> = gql`
  mutation QsstArchiveMutation($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
    }
  }
`;

export default QsstArchiveMutation;
