import { QueryResult, useQuery } from '@apollo/client';
import GetConstructionNoteDetailQuery from '../graphql/GetConstructionNoteDetail.query';
import {
  GetConstructionNoteDetail,
  GetConstructionNoteDetailVariables,
} from '../graphql/__generated__/GetConstructionNoteDetail.query';

export default function useConstructionNoteDetail(
  id: string,
): QueryResult<GetConstructionNoteDetail, GetConstructionNoteDetailVariables> {
  return useQuery(GetConstructionNoteDetailQuery, {
    variables: {
      id,
    },
  });
}
