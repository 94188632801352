import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertDialog, Box, Icon, Text } from '@hs-baumappe/legacy-ui';
import { MissingScopeError } from '@hs-baumappe/redkit';
import RouteDialog from '../../../containers/RouteDialog';
import SignConcernBody from './ConcernSignBody';
import { concernRoutes } from '../concernRoutes';
import { ConcernLocationState } from '../Concern.route.types';
import { Concern } from '../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../graphql/__generated__/ConcernProject.fragment';
import { ConcernScopes } from '../Concern.types';

interface ConcernSignProps {
  concern: Concern;
  project: ConcernProject;
  scopes: ConcernScopes;
}

export default function ConcernSign({ concern, project, scopes }: ConcernSignProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<ConcernLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  function handleDialogRequestClose() {
    if (!formDirty) {
      setDialogOpen(false);
      return;
    }

    setShowConfirmationDialog(true);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(concernRoutes.detail.generatePath({ concernId: concern.id }));
  }, [concern.id, location.state, navigate]);

  function handleConfirmationDialogConfirm() {
    setShowConfirmationDialog(false);
    setDialogOpen(false);
  }

  if (!concern.viewerCanSign) {
    return <Navigate to={concernRoutes.detail.generatePath({ concernId: concern.id })} replace />;
  }

  const permitted = scopes.signConcernDocument.isPermitted;
  const missingScopeNames = scopes.signConcernDocument.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Box stylex={{ flexGrow: '1' }}>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('concern.sign.route.subtitle', { name: concern.name })}
          </Text>
          <Text variant="title-small" component="div" color="muted">
            {t('concernSign.status', { status: t(concern.status) })}
          </Text>
        </Box>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <SignConcernBody concern={concern} onFormDirtyStatusChange={setFormDirty} />
      )}

      <AlertDialog
        open={showConfirmationDialog}
        title={t('concern.sign.form.cancelModal.title')}
        description={t('concern.sign.form.cancelModal.info')}
        confirmButtonText={t('concern.sign.form.cancelModal.submitButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('concern.sign.form.cancelModal.dismissButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setShowConfirmationDialog(false)}
        onRequestClose={() => setShowConfirmationDialog(false)}
        destructive
      />
    </RouteDialog>
  );
}
