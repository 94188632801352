import { gql } from '@apollo/client';

const CustomFileDocumentProjectFragment = gql`
  fragment CustomFileDocumentProject on Project {
    id

    name
    additionalName
    projectNumber
  }
`;

export default CustomFileDocumentProjectFragment;
