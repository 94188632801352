import { createPath, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AlertDialog, FlexGrid, FlexGridColumn, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import { ObstructionLocationState } from '../obstruction.route.types';
import useObstructionDuplicate from './hooks/useObstructionDuplicate/useObstructionDuplicate';
import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import obstructionRoutes from '../obstructionRoutes';
import { projectRoutes } from '../../routes';
import ObstructionLogs from './components/ObstructionLogs';
import PDFViewer from '../../../components/PDFViewer';
import { Obstruction } from '../graphql/__generated__/Obstruction.fragment';
import { ObstructionScopes } from '../Obstruction.types';
import { ObstructionProject } from '../graphql/__generated__/ObstructionProject.fragment';
import { createObstructionLocation } from '../Obstruction.utils';
import ObstructionDetailHeader from './components/ObstructionDetailHeader';
import ObstructionDetailAlert from './components/ObstructionDetailAlert';
import ObstructionDetailSidebarTop from './components/ObstructionDetailSidebarTop';
import ObstructionDetailSidebarBottom from './components/ObstructionDetailSidebarBottom';

interface ObstructionDetailProps {
  obstruction: Obstruction;
  project: ObstructionProject;
  scopes: ObstructionScopes;
}

export default function ObstructionDetail({
  obstruction,
  project,
  scopes,
}: ObstructionDetailProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<ObstructionLocationState>();

  const [showDuplicationDialog, setShowDuplicationDialog] = useState(false);
  const [showHasNotSignedDialog, setShowHasNotSignedDialog] = useState(false);

  const { duplicate } = useObstructionDuplicate(obstruction.id);
  const { parentFolders } = useDriveDocumentParentFolders(obstruction.id);

  const signed = obstruction.signatures.length > 0;
  const downloadPermitted = scopes.downloadObstructionDocument.isPermitted;

  function handleSendEmailButtonClick() {
    if (!signed) {
      setShowHasNotSignedDialog(true);
      return;
    }

    navigate(obstructionRoutes.sendEmail.generatePath({ obstructionId: obstruction.id }), {
      state: createObstructionLocation(location),
    });
  }

  function handleHasNotSignedDialogCancel() {
    setShowHasNotSignedDialog(false);

    navigate(obstructionRoutes.sendEmail.generatePath({ obstructionId: obstruction.id }), {
      state: createObstructionLocation(location),
    });
  }

  function handleHasNotSignedDialogConfirm() {
    setShowHasNotSignedDialog(false);

    navigate(obstructionRoutes.sign.generatePath({ obstructionId: obstruction.id }), {
      state: createObstructionLocation(location),
    });
  }

  function handleEditButtonClick() {
    if (!obstruction.viewerCanUpdate) {
      setShowDuplicationDialog(true);
      return;
    }

    navigate(obstructionRoutes.edit.generatePath({ obstructionId: obstruction.id }), {
      state: createObstructionLocation(location),
    });
  }

  async function handleDuplicationDialogConfirm() {
    setShowDuplicationDialog(false);

    const duplicatedObstructionId = await duplicate();
    if (duplicatedObstructionId) {
      navigate(obstructionRoutes.detail.generatePath({ obstructionId: duplicatedObstructionId }));
    }
  }

  function handleRemoveSuccess() {
    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId: project.id,
        }),
        search: `?removedDocumentName=${obstruction.name}`,
      }),
    );
  }

  return (
    <PageLayoutWithStickySidebar
      header={
        <ObstructionDetailHeader
          obstruction={obstruction}
          project={project}
          parentFolders={parentFolders}
        />
      }
      sidebarTop={
        <ObstructionDetailSidebarTop
          obstruction={obstruction}
          scopes={scopes}
          onEditButtonClick={handleEditButtonClick}
          onSendEmailButtonClick={handleSendEmailButtonClick}
        />
      }
      sidebarBottom={
        <ObstructionDetailSidebarBottom
          obstruction={obstruction}
          scopes={scopes}
          projectId={project.id}
          onRemoveSuccess={handleRemoveSuccess}
        />
      }
      footer={
        <FlexGrid>
          <FlexGridColumn sm={8}>
            <ObstructionLogs obstructionId={obstruction.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <ObstructionDetailAlert />

        <PDFViewer
          document={obstruction.pdfPath}
          downloadPermitted={downloadPermitted}
          key={obstruction.pdfPath}
        />
      </Stack>

      <AlertDialog
        open={showDuplicationDialog}
        title={obstruction.name}
        description={t('obstructionDuplicationConfirmationModal.body')}
        confirmButtonText={t('obstructionDuplicationConfirmationModal.confirmButton')}
        cancelButtonText={t('obstructionDuplicationConfirmationModal.dismissButton')}
        confirmButtonEndIcon={<Icon name="file-copy" />}
        onConfirm={handleDuplicationDialogConfirm}
        onRequestClose={() => setShowDuplicationDialog(false)}
        onCancel={() => setShowDuplicationDialog(false)}
      />

      <AlertDialog
        open={showHasNotSignedDialog}
        title={t('obstructionSignBeforeSendConfirmationModal.title')}
        description={t('obstructionSignBeforeSendConfirmationModal.body')}
        confirmButtonText={t('obstructionSignBeforeSendConfirmationModal.confirmButton')}
        cancelButtonText={t(
          'obstructionSignBeforeSendConfirmationModal.sendWithoutSignatureButton',
        )}
        confirmButtonEndIcon={<Icon name="create" />}
        onConfirm={handleHasNotSignedDialogConfirm}
        onRequestClose={() => setShowHasNotSignedDialog(false)}
        onCancel={handleHasNotSignedDialogCancel}
      />
    </PageLayoutWithStickySidebar>
  );
}
