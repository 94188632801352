import { gql, TypedDocumentNode } from '@apollo/client';
import ProjectFilterCenterFragment from './ProjectFilterProfitCenter.fragment';
import { ProjectFilterViewerProfitCenters } from './__generated__/ProjectFilterViewerProfitCenters.query';

const ProjectfilterViewerProfitCentersQuery: TypedDocumentNode<ProjectFilterViewerProfitCenters> = gql`
  query ProjectFilterViewerProfitCenters {
    viewer {
      id

      projectCountsByStatus {
        status
        count
      }

      profitCenters {
        ...ProjectFilterProfitCenter
      }
    }
  }

  ${ProjectFilterCenterFragment}
`;

export default ProjectfilterViewerProfitCentersQuery;
