import { MouseEvent } from 'react';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';

interface DownloadActionProps {
  name: string;
}

export default function DownloadAction({ name }: DownloadActionProps): JSX.Element | null {
  const { canvas } = useCanvas();

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    if (!canvas) {
      event.preventDefault();
      return;
    }

    event.currentTarget.setAttribute(
      'href',
      canvas.toDataURL({
        format: 'jpeg',
        quality: 1,
        enableRetinaScaling: true,
        multiplier: 2,
      }),
    );
  }

  return (
    <Button
      component="a"
      download={`${name}.jpeg`}
      onClick={handleClick}
      contentClassName="u-display-flex"
    >
      <Icon name="cloud-download" size={24} />
    </Button>
  );
}
