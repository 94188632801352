import { DiscountOrSurchargeValueType } from '../../../../globalTypes';

import { SelectOption } from '../../../../components/form/Control/Select';

const DiscountOrSurchargeValueTypeOptions: SelectOption[] = [
  {
    label: 'discountOrSurchargeValueType.percentage',
    value: DiscountOrSurchargeValueType.PERCENTAGE,
  },
  {
    label: 'discountOrSurchargeValueType.amount',
    value: DiscountOrSurchargeValueType.AMOUNT,
  },
];

export default DiscountOrSurchargeValueTypeOptions;
