import { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import SIVDiscountSetDiscountAmountMutation from './graphql/SIVDiscountSetDiscountAmountMutation';
import SIVDiscountToggleDiscountActiveMutation from './graphql/SIVDiscountToggleDiscountActiveMutation';

interface UseSIVDiscountResponse {
  setDiscountAmount: (amount?: number) => Promise<void>;
  toggleDiscountActive: () => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useSIVDiscount(sivId?: string): UseSIVDiscountResponse {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutateDiscountAmount, { loading: discountSetting }] = useMutation(
    SIVDiscountSetDiscountAmountMutation,
  );
  const [mutateDiscountActiveToggle, { loading: discountToggling }] = useMutation(
    SIVDiscountToggleDiscountActiveMutation,
  );

  async function setDiscountAmount(discount?: number) {
    if (!sivId) {
      return;
    }

    setError(undefined);

    try {
      await mutateDiscountAmount({
        variables: {
          input: {
            discount,
            sivId,
          },
        },
      });
    } catch (e) {
      if (e instanceof ApolloError) {
        setError(e);
      }
    }
  }

  async function toggleDiscountActive() {
    if (!sivId) {
      return;
    }

    setError(undefined);

    try {
      await mutateDiscountActiveToggle({
        variables: {
          input: {
            sivId,
          },
        },
      });
    } catch (e) {
      if (e instanceof ApolloError) {
        setError(e);
      }
    }
  }

  return {
    setDiscountAmount,
    toggleDiscountActive,
    loading: discountSetting || discountToggling,
    error,
  };
}
