import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import { Box, Dialog, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import { ErrorRenderer, MissingScopeError, NotFoundError } from '@hs-baumappe/redkit';
import useConstructionNoteDetail from '../hooks/useConstructionNoteDetail';
import ConstructionNoteUpdateMutation from './graphql/ConstructionNoteUpdate.mutation';
import ErrorState from '../../../containers/ErrorState';
import ConstructionNoteEditTitleForm from './ConstructionNoteEditTitleForm/ConstructionNoteEditTitleForm';
import { projectsRoutes } from '../../routes';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import {
  ConstructionNoteLocationState,
  ConstructionNoteRouteParams,
} from '../ConstructionNote.route.types';
import { ConstructionNoteEditTitleFormValues } from './ConstructionNoteEditTitleForm/values';
import { navigateToLocation } from '../../routes.utils';

export default function ConstructionNoteEditTitle(): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const location = useLocation<ConstructionNoteLocationState>();
  const navigate = useNavigate();

  const { data, loading, error } = useConstructionNoteDetail(constructionNoteId);
  const [renameNote, { error: renameError }] = useMutation(ConstructionNoteUpdateMutation);

  const handleDialogClose = useCallback(() => {
    if (error) {
      navigate(projectsRoutes.detail.generatePath({}));
      return;
    }

    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }),
    });
  }, [navigate, location.state, error, constructionNoteId]);

  async function handleSubmit(value: ConstructionNoteEditTitleFormValues) {
    if (loading) {
      return;
    }

    const { data: renameData } = await renameNote({
      variables: {
        input: {
          documentId: constructionNoteId,
          name: value.name,
        },
      },
    });

    if (!renameData) {
      return;
    }

    navigate(constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }));
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  function renderContent() {
    if (loading && !data) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!data?.constructionNote || !viewer) {
      return <NotFoundError />;
    }

    const { constructionNote } = data;
    const updateConstructionNoteDocument = viewer.scopes.updateConstructionNoteDocument;
    if (!updateConstructionNoteDocument.isPermitted) {
      return <MissingScopeError missingScopes={updateConstructionNoteDocument.missingScopeNames} />;
    }

    return (
      <Box stylex={{ paddingTop: 'large' }}>
        <ConstructionNoteEditTitleForm
          initialValues={{ name: constructionNote.name }}
          onSubmit={handleSubmit}
        />
      </Box>
    );
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {renameError && <ErrorState error={renameError} />}

      <Box className="container" stylex={{ width: '100%', paddingEnds: 'large' }}>
        <Flex alignItems="center">
          <Box stylex={{ flexGrow: '1' }}>
            {data?.constructionNote && (
              <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
                {t('constructionNote.noteTitleModal.editTitle', {
                  noteTitle: data?.constructionNote.name,
                })}
              </Text>
            )}
          </Box>

          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </Flex>

        <Box>{renderContent()}</Box>
      </Box>
    </Dialog>
  );
}
