import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AlertDialog, Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { OfferFolderEditRouteParams } from './route';
import { OfferLocationState } from '../../Offer.route.types';
import { OfferType } from '../../../../globalTypes';
import OfferFolderEditForFramework from './OfferFolderEditForFramework';
import OfferFolderEditForFreeform from './OfferFolderEditForFreeform';
import { MeasurementsValues } from '../../measurement/MeasurementsSection/values';
import { BaseValues } from '../containers/FolderForm/values';
import { castGqlForMeasurement } from '../containers/FolderForm/form';
import useOffer from '../../hooks/offer';
import InfoModal from '../../components/InfoModal';
import { offerRoutes } from '../../offerRoutes';
import useRemoveFolder from '../../hooks/useRemoveFolder';
import ErrorState from '../../../../containers/ErrorState';

const OfferFolderEdit = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { getFolderById, getOffer, refetchOfferData } = useOffer();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [removeFolderAlertDialogOpen, setRemoveFolderAlertDialogOpen] = useState<boolean>(false);
  const [aliasInfoModal, setAliasInfoModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId, folderId } = useParams<OfferFolderEditRouteParams>();

  const folder = getFolderById(folderId);

  const { removeFolder, error: removeFolderError, loading: folderRemoving } = useRemoveFolder();

  if (!folder) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
    return null;
  }

  const { viewerCanUpdateFolder, viewerCanRemoveFolder } = folder;

  if (!viewerCanUpdateFolder) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
  }

  const urlParams = new URLSearchParams(location.search);
  const parentId = urlParams.get('parentId') || undefined;

  const initialValues = (): BaseValues => {
    const note = folder.note || '';
    const measurements =
      folder.measurements && folder.measurements.length > 0 && folder.unit
        ? (castGqlForMeasurement(folder.measurements, folder.unit) as MeasurementsValues)
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any);

    return {
      category: folder.category?.id,
      alias: folder.partNoComputed,
      name: folder.name,
      note,
      approximate: folder.approximate,
      measurements,
    };
  };

  function goBackToOfferDetail() {
    if (location?.state.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }

  async function handleRemoveFolderAlertDialogConfirm() {
    setRemoveFolderAlertDialogOpen(false);

    const removed = await removeFolder(folderId);

    if (removed) {
      refetchOfferData();
      goBackToOfferDetail();
    }
  }

  function handleRemoveFolderAlertDialogCancel() {
    setRemoveFolderAlertDialogOpen(false);
  }

  const renderFolderEditFormByType = () => {
    const { type, mode } = getOffer();

    if (type === OfferType.FRAMEWORK) {
      return (
        <OfferFolderEditForFramework
          onRemoveFolderButtonClick={() => setRemoveFolderAlertDialogOpen(true)}
          folderRemoving={folderRemoving}
          onSuccess={() => setDialogOpen(false)}
          parentId={parentId}
          offerId={offerId}
          folderId={folderId}
          initialValues={initialValues()}
        />
      );
    }

    return (
      <OfferFolderEditForFreeform
        offerMode={mode}
        onClickAliasRightIcon={() => setAliasInfoModal(true)}
        onRemoveFolderButtonClick={() => setRemoveFolderAlertDialogOpen(true)}
        folderRemoving={folderRemoving}
        onSuccess={() => setDialogOpen(false)}
        parentId={parentId}
        offerId={offerId}
        folderId={folderId}
        initialValues={initialValues()}
      />
    );
  };

  const renderTitle = (): string => {
    const { partNoTree } = folder;

    return t('editOfferFolder.title', {
      partNo: `${partNoTree.join('.')}`,
      folderName: folder.name,
    });
  };

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  function handleDialogClose() {
    goBackToOfferDetail();
  }

  return (
    <Dialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {renderTitle()}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">{renderFolderEditFormByType()}</div>
      </div>
      {viewerCanRemoveFolder && (
        <AlertDialog
          destructive
          open={removeFolderAlertDialogOpen}
          title={t('removeFolder.title', {
            partNo: folder.partNoTree.join('.'),
            name: folder.name,
          })}
          description={t('removeFolder.subTitle', {
            group: typeof folder.parentID !== 'undefined' ? t('subGroup') : t('group'),
          })}
          confirmButtonText={t('removeFolder.confirmButton', {
            group: typeof folder.parentID !== 'undefined' ? t('subGroup') : t('group'),
          })}
          cancelButtonText={t('removeFolder.cancelButton')}
          confirmButtonEndIcon={<Icon name="delete" />}
          onCancel={handleRemoveFolderAlertDialogCancel}
          onRequestClose={handleRemoveFolderAlertDialogCancel}
          onConfirm={handleRemoveFolderAlertDialogConfirm}
        />
      )}
      {removeFolderError && <ErrorState error={removeFolderError} />}
      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Dialog>
  );
};

export default OfferFolderEdit;
