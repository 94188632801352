import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import {
  ConstructionNoteLocationState,
  ConstructionNoteRouteParams,
} from '../../ConstructionNote.route.types';
import { constructionNoteRoutes } from '../../constructionNoteRoutes';

export default function ArchiveAction(): JSX.Element {
  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const location = useLocation<ConstructionNoteLocationState>();
  const navigate = useNavigate();

  function handleClickArchive() {
    navigate(
      constructionNoteRoutes.archive.generatePath({
        constructionNoteId,
      }),
      {
        state: {
          backgroundLocation: location,
        } as ConstructionNoteLocationState,
      },
    );
  }

  return (
    <Button contentClassName="u-display-flex" onClick={handleClickArchive}>
      <Icon name="archive" size={24} />
    </Button>
  );
}
