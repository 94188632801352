import { createPath, useNavigate } from 'react-router-dom';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik';
import { getErrorsFromServerError } from '../../../apollo/errors';
import { DriveDocumentArchiveInput } from '../../../globalTypes';
import { QsstDetailAlertType } from '../QsstDetail/QsstDetail.utils';
import { qsstRoutes } from '../qsstRoutes';
import qsstArchiveMutation from './graphql/qsstArchiveMutation';
import QsstDetailQuery from '../hooks/useQsst/graphql/QsstDetail.query';
import { useState } from 'react';
import { ArchiveFormValues } from '../../../containers/ArchiveForm';

interface UseQsstArchiveResponse {
  archive: (
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
}

export function useQsstArchive(qsstId: string): UseQsstArchiveResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>();
  const [archiveQsst, { loading }] = useMutation(qsstArchiveMutation, {
    refetchQueries: [{ query: QsstDetailQuery, variables: { qsstId } }],
  });

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    setError(undefined);

    try {
      await archiveQsst({
        variables: {
          input: {
            id: qsstId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
      });

      const searchParams = new URLSearchParams({
        alert: QsstDetailAlertType.ARCHIVED,
      });

      navigate(
        createPath({
          pathname: qsstRoutes.detail.generatePath({ qsstId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    archive,
    loading,
    error,
  };
}
