import { gql } from '@apollo/client';

export default gql`
  query GetCustomFileDetail($documentId: ID!) {
    customFile(id: $documentId) {
      id
      url
    }
  }
`;
