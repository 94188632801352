import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import { assertUnknownLog, getActor } from '../Log.utils';
import { OfferLog as OfferLogType } from '../../graphql/__generated__/OfferLog.fragment';
import LogEmails from '../LogEmails';

interface OfferLogProps extends ComponentProps<typeof Text> {
  log: OfferLogType;
}

export default function OfferLog({ log, ...props }: OfferLogProps): JSX.Element {
  switch (log.__typename) {
    case 'OfferCreatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.offerCreated"
            values={{ offerName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OfferDeletedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.offerDeleted"
            values={{ offerName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OfferEmailSentLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.offerEmailSent"
            values={{ offerName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'OfferExportedToBotLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.offerExportedToBot"
            values={{ offerName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
