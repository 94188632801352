import { gql, TypedDocumentNode } from '@apollo/client';
import {
  QsstSendMailBody,
  QsstSendMailBodyVariables,
} from './__generated__/QsstSendMailBody.mutation';
import QsstFragment from '../../../graphql/Qsst.fragment';

const QsstSendMailBodyMutation: TypedDocumentNode<QsstSendMailBody, QsstSendMailBodyVariables> =
  gql`
    mutation QsstSendMailBody($input: QsstSendMailInput!) {
      qsstSendMail(input: $input) {
        ...Qsst
      }
    }

    ${QsstFragment}
  `;

export default QsstSendMailBodyMutation;
