import { TypedDocumentNode, gql } from '@apollo/client';
import { DocumentLog } from './__generated__/DocumentLog.fragment';

const DocumentLogFragment: TypedDocumentNode<DocumentLog> = gql`
  fragment DocumentLog on DocumentLog {
    ... on DocumentMovedLog {
      id
      name
      date

      projectId
      documentId

      sourceFolder {
        id
        name
      }

      targetFolder {
        id
        name
      }

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default DocumentLogFragment;
