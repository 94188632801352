import { gql } from '@apollo/client/core';
import { TypedDocumentNode } from '@apollo/client';
import {
  AcceptanceReportEmployees,
  AcceptanceReportEmployeesVariables,
} from './__generated__/AcceptanceReportEmployees.query';

const AcceptanceReportEmployeesQuery: TypedDocumentNode<
  AcceptanceReportEmployees,
  AcceptanceReportEmployeesVariables
> = gql`
  query AcceptanceReportEmployees($projectId: ID!) {
    employeesForProject(id: $projectId) {
      name
    }
  }
`;

export default AcceptanceReportEmployeesQuery;
