import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentSendEmail,
  RiskAssessmentSendEmailVariables,
} from './__generated__/RiskAssessmentSendEmail.mutation';

const RiskAssessmentSendEmailMutation: TypedDocumentNode<
  RiskAssessmentSendEmail,
  RiskAssessmentSendEmailVariables
> = gql`
  mutation RiskAssessmentSendEmail($input: SendRiskAssessmentEmailInput!) {
    sendRiskAssessmentEmail(input: $input) {
      status
    }
  }
`;

export default RiskAssessmentSendEmailMutation;
