import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import routes from '../../../../routes';
import { QsstDetailTitle } from '../QsstDetailTitle/QsstDetailTitle';
import { Qsst } from '../../../graphql/__generated__/Qsst.fragment';
import { QsstProject } from '../../../graphql/__generated__/QsstProject.fragment';
import DocumentDetailBreadcrumbs from '../../../../../components/DocumentDetailBreadcrumbs';
import { DriveDocumentParentFolders } from '../../../../../hooks/useDriveDocumentParentFolders';

interface QsstDetailHeaderProps {
  qsst: Qsst;
  project: QsstProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function QsstDetailHeader({
  qsst,
  project,
  parentFolders,
}: QsstDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={<QsstDetailTitle name={qsst.name} status={qsst.status} />}
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
