import { FC, useRef } from 'react';

import NumberInput, { Props as InputProps } from '../../../../components/form/Control/NumberInput';

type Props = Omit<InputProps, 'innerRef'>;

const MeasurementInput: FC<Props> = (props) => {
  const input = useRef<HTMLInputElement>(null);

  return <NumberInput {...props} innerRef={input} />;
};

export default MeasurementInput;
