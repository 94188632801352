import { gql } from '@apollo/client';

export default gql`
  mutation FollowProjectToggle($input: FollowProjectToggleInput!) {
    followProjectToggle(input: $input) {
      id
      viewerFollowsProject
    }
  }
`;
