import { Paper, Skeleton, Toolbar } from '@hs-baumappe/legacy-ui';

import ProjectDetailHeader from '../ProjectDetailHeader';
import '../ProjectDetailNavigation/o-project-detail-navigation.scss';

export default function ProjectDetailLoadingLayout(): JSX.Element {
  return (
    <div className="container u-padding-ends">
      <ProjectDetailHeader />
      <div className="row u-margin-top-large">
        <div className="col col--sm-3">
          <Skeleton width="100%" radius={8} height={48} />
        </div>
        <div className="col col--md-4 col--sm-7 col--offset-sm-2 col--offset-md-5">
          <Skeleton width="100%" radius={8} height={40} />
        </div>
      </div>
      <div className="u-margin-top-small">
        <Paper>
          <Toolbar rightSection={<Skeleton width={40} radius={500} height={40} />}>
            <Skeleton width={300} radius={8} height={16} />
          </Toolbar>
          <div className="u-padding-xsmall">
            <Skeleton width={200} radius={8} height={24} />
          </div>
          <hr className="u-margin-ends-0 u-width-100%" />
          <div className="u-padding-xsmall">
            <Skeleton width={200} radius={8} height={24} />
          </div>
          <hr className="u-margin-ends-0 u-width-100%" />
          <div className="u-padding-xsmall">
            <Skeleton width={200} radius={8} height={24} />
          </div>
        </Paper>
      </div>
      <div className="u-margin-top">
        <nav className="o-project-detail-navigation">
          <ul className="o-project-detail-navigation__menu">
            <Skeleton width={144} radius={8} height={180} />
            <Skeleton width={144} radius={8} height={180} />
            <Skeleton width={144} radius={8} height={180} />
            <Skeleton width={144} radius={8} height={180} />
            <Skeleton width={144} radius={8} height={180} />
            <Skeleton width={144} radius={8} height={180} />
            <Skeleton width={144} radius={8} height={180} />
          </ul>
        </nav>
      </div>
      <div className="u-margin-top">
        <Skeleton width="100%" radius={8} height={52} />
      </div>
    </div>
  );
}
