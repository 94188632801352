import { FC } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface CompProps {
  test?: boolean;
}

const Intro: FC<CompProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="row u-margin-top-large">
      <div className="col col--sm-12">
        <Text variant="title-medium">{t('qsstSign.form.intro.title')}</Text>

        <br />

        <div className="u-margin-top-small">
          <Text variant="body-small">{t('qsstSign.form.intro.contentLine0')}</Text>

          <br />

          <Text variant="body-small">{t('qsstSign.form.intro.contentLine1')}</Text>
        </div>
      </div>
    </div>
  );
};

export { Intro };
