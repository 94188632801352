import Point from '../Point/Point';

class TouchPair {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public firstTouch: Touch,
    public secondTouch: Touch,
  ) {}

  get distance(): number {
    const firstTouchPoint = new Point(this.firstTouch.clientX, this.firstTouch.clientY);
    const secondTouchPoint = new Point(this.secondTouch.clientX, this.secondTouch.clientY);

    return firstTouchPoint.distanceFrom(secondTouchPoint);
  }
}

export default TouchPair;
