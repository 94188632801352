import { gql } from '@apollo/client/core';
import { TypedDocumentNode } from '@apollo/client';
import {
  ArchiveAcceptanceReport,
  ArchiveAcceptanceReportVariables,
} from './__generated__/AcceptanceReportArchiveMutation';

const AcceptanceReportArchiveMutation: TypedDocumentNode<
  ArchiveAcceptanceReport,
  ArchiveAcceptanceReportVariables
> = gql`
  mutation ArchiveAcceptanceReport($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
    }
  }
`;

export default AcceptanceReportArchiveMutation;
