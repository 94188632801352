import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button, Icon, Search } from '@hs-baumappe/legacy-ui';
import stylex from '@hs-baumappe/legacy-stylex';
import CatalogSelect from './CatalogSelect';
import CatalogsTable from './CatalogsTable';

import useOperationSearch from './useOperationSearch';

import { CatalogOperation } from './types';

import { ADD_BULK_OPERATION_TO_FOLDER } from './graphql';
import {
  AddBulkOperationToFolder,
  AddBulkOperationToFolderVariables,
} from './__generated__/graphql';
import ErrorState from '../../../../../containers/ErrorState';
import SidebarLayout from '../../../../../components/layouts/SidebarLayout';
import { GET_OFFER_DATA } from '../../../Detail/graphql';

interface Props {
  offerId: string;
  freeform?: boolean;
  folderId: string;
  categoryID?: string;
  initialCatalog?: string;
  onOperationSelect: (operation: CatalogOperation, category?: string) => void;
  selectCatalog?: (value: string) => void;
  onSuccess: () => void;
}

const OperationSearch: FC<Props> = ({
  offerId,
  freeform,
  folderId,
  categoryID,
  initialCatalog,
  onOperationSelect,
  onSuccess,
  selectCatalog,
}) => {
  const { t } = useTranslation();
  const [addBulkOperation, { loading, error }] = useMutation<
    AddBulkOperationToFolder,
    AddBulkOperationToFolderVariables
  >(ADD_BULK_OPERATION_TO_FOLDER);

  const [selectedOperations, setSelectedOperations] = useState<string[]>([]);
  const [catalogID, setCatalogID] = useState<string | undefined>(initialCatalog);
  const {
    catalogs,
    search,
    noResult,
    catalogList,
    searchAndSet,
    loading: catalogsLoading,
  } = useOperationSearch({
    categoryID,
    catalogID,
  });

  const emptyText = noResult
    ? t('offer.createNewOperation.search.operationSearch.noResult')
    : undefined;

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    search(event.target.value);
  }

  const handleAddBulkOperationToFolder = async () => {
    if (loading) {
      return;
    }
    try {
      await addBulkOperation({
        variables: {
          input: {
            folder: folderId,
            operations: selectedOperations,
          },
        },
        refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
      });

      onSuccess();
    } catch (e) {
      alert(t('common.error'));
    }
  };

  return (
    <Fragment>
      {error && <ErrorState error={error} />}
      <div className="row u-margin-top">
        <div className="col col--sm-8">
          {freeform && (
            <div className="u-width-50% u-margin-bottom">
              <CatalogSelect
                defaultValue={initialCatalog}
                catalogs={catalogList.map((c) => {
                  return { label: c.name, value: c.id };
                })}
                onChange={(value) => {
                  setCatalogID(value);
                  if (selectCatalog) selectCatalog(value);
                  if (!value) {
                    searchAndSet(undefined, value, true);
                    return;
                  }

                  searchAndSet(undefined, value);
                }}
              />
            </div>
          )}
          <Search
            id="operation-search"
            className={cx(stylex.create({ marginBottom: 'small' }))}
            fieldProps={{
              onChange: handleSearchChange,
              label: t('offer.createNewOperation.search.searchInput.input.label'),
              placeholder: t('offer.createNewOperation.search.searchInput.input.placeholder'),
            }}
          />
          <CatalogsTable
            loading={catalogsLoading}
            emptyText={emptyText}
            catalogs={catalogs}
            selectedItems={selectedOperations}
            onOperationClick={(operation) => onOperationSelect(operation, categoryID)}
            onChangeChoice={(id) => {
              const selections = new Set(selectedOperations);

              const result = selectedOperations.includes(id);
              if (!result) {
                selections.add(id);
              } else {
                selections.delete(id);
              }

              setSelectedOperations(Array.from(selections));
            }}
          />
        </div>
        <div className="col col--sm-4">
          {!!selectedOperations.length && (
            <SidebarLayout>
              <Button
                color="primary"
                onClick={handleAddBulkOperationToFolder}
                endIcon={<Icon name="add" />}
                fullWidth
              >
                {t('offer.createNewOperation.search.operationSearch.addButton')}
              </Button>
            </SidebarLayout>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OperationSearch;
