import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentEditDetail,
  RiskAssessmentEditDetailVariables,
} from './__generated__/RiskAssessmentEditDetail.query';
import RegionalSecurityManagerFragment from '../../graphql/RegionalSecurityManager.fragment';

const RiskAssessmentEditDetailQuery: TypedDocumentNode<
  RiskAssessmentEditDetail,
  RiskAssessmentEditDetailVariables
> = gql`
  query RiskAssessmentEditDetail($riskAssessmentId: ID!) {
    riskAssessment(id: $riskAssessmentId) {
      id

      date
      draftProjectNumber
      clientNameComputed
      description

      inCharges {
        id

        fullName
        phone
        role
      }

      precautions {
        id
      }

      specialPrecautions {
        id

        title

        precautionCategory {
          id
        }
      }

      equipments {
        id
      }
    }

    regionalSecurityManagers {
      ...RegionalSecurityManager
    }
  }

  ${RegionalSecurityManagerFragment}
`;

export default RiskAssessmentEditDetailQuery;
