import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useFolderClone, { FolderCloneProps } from '../useFolderClone';
import { BaseValues } from '../../containers/FolderForm/values';
import FolderForm from '../../containers/FolderForm';
import ErrorState from '../../../../../containers/ErrorState';
import { OfferMode } from '../../../../../globalTypes';

interface Props {
  parentId?: string;
  folderId: FolderCloneProps['folderId'];
  offerId: FolderCloneProps['offerId'];
  onSuccess: FolderCloneProps['onSuccess'];
  initialValues?: Partial<BaseValues>;
  offerMode: OfferMode;
  onClickAliasRightIcon: () => void;
}

const OfferFolderCloneForFreeform: FC<Props> = ({
  parentId,
  offerId,
  folderId,
  initialValues,
  onSuccess,
  offerMode,
  onClickAliasRightIcon,
}) => {
  const { t } = useTranslation();
  const { cloneFolder, error } = useFolderClone({ onSuccess, folderId, offerId, offerMode });

  return (
    <Fragment>
      {error && <ErrorState error={error} />}

      <FolderForm
        categories={[]}
        offerModeSystemNumber={offerMode === OfferMode.SYSTEM_NUMBER}
        parentId={parentId}
        onClickAliasRightIcon={onClickAliasRightIcon}
        onSubmit={cloneFolder}
        buttonText={t(
          parentId
            ? 'cloneOfferFolderForFreeform.forSubGroup.submitButton'
            : 'cloneOfferFolderForFreeform.forGroup.submitButton',
        )}
        buttonIcon="save"
        initialValues={initialValues}
      />
    </Fragment>
  );
};

export default OfferFolderCloneForFreeform;
