import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';

import FormikProfessionsField from '../../../../../../components/FormikProfessionsField';
import { ConcernFormValues } from '../../ConcernForm.types';

interface InformationFormSectionProps {
  professions: string[];
  draft: boolean;
}

export default function InformationFormSection({
  professions,
  draft,
}: InformationFormSectionProps): JSX.Element | null {
  const { t } = useTranslation();
  const { values } = useFormikContext<ConcernFormValues>();

  return (
    <div className="row">
      {!draft && (
        <div className="col col--sm-6 u-margin-top-small">
          <FormikFormField
            name="projectNumber"
            label={t('concern.create.information.projectNumber.label')}
            className="u-flex-basis-100%"
            placeholder={t('concern.create.information.projectNumber.placeholder')}
            disabled
          />
        </div>
      )}
      <div className="col col--sm-6 u-margin-top-small">
        <FormikFormField
          name="clientName"
          label={t('client.title')}
          className="u-flex-basis-100%"
          placeholder={t('client.title')}
        />
      </div>
      <div className="col col--sm-6 u-margin-top-small">
        <FormikFormField
          type="date"
          name="date"
          label={t('concern.create.information.date.label')}
          placeholder={t('concern.create.information.date.placeholder')}
          value={values.date || ''}
        />
      </div>
      <div className="col col--sm-6 u-margin-top-small">
        <FormikProfessionsField name="professions" professions={professions} />
      </div>
    </div>
  );
}
