import { ApolloError, useQuery } from '@apollo/client';
import DriveDocumentParentFoldersQuery from './graphql/DriveDocumentParentFolders.query';
import { DriveDocumentParentFolders as DriveDocumentParentFoldersResponse } from './graphql/__generated__/DriveDocumentParentFolders.query';
import { DriveFolderType } from '../../globalTypes';

export type DriveDocumentParentFolders = NonNullable<
  DriveDocumentParentFoldersResponse['driveDocument']
>['parentFolders'];

interface UseDriveDocumentParentFoldersResponse {
  parentFolders: DriveDocumentParentFolders;
  loading: boolean;
  error?: ApolloError;
}

export default function useDriveDocumentParentFolders(
  documentId: string,
): UseDriveDocumentParentFoldersResponse {
  const { data, loading, error } = useQuery(DriveDocumentParentFoldersQuery, {
    variables: { documentId },
  });

  const parentFolders = data?.driveDocument?.parentFolders ?? [];
  const parentFoldersExcludingRoot = parentFolders.filter(
    (folder) => folder.type !== DriveFolderType.ROOT,
  );

  return {
    parentFolders: parentFoldersExcludingRoot,
    loading,
    error,
  };
}
