import { useEffect, useState } from 'react';
import { IconButton, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';
import { getObjectPosition } from '../../canvasUtils';

export default function BackwardAction(): JSX.Element {
  const { canvas, activeObjects, objects } = useCanvas();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(activeObjects.length !== 1 || getObjectPosition(activeObjects[0], objects) === 0);
  }, [activeObjects, objects, setDisabled]);

  const handleBackward = () => {
    if (!canvas) {
      return;
    }

    canvas.getActiveObjects().forEach((object) => {
      canvas.sendBackwards(object);
    });

    canvas.fire('index:changed');
  };

  return (
    <IconButton icon={<Icon name="flip-to-back" />} onClick={handleBackward} disabled={disabled} />
  );
}
