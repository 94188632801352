import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Flex, Radio, RadioGroup, Text } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import cx from 'classnames';
import stylex from '@hs-baumappe/legacy-stylex';
import AcceptanceReportFormSection from '../components/AcceptanceReportFormSection';
import { Acceptance, AcceptanceReportFormValues } from '../AcceptanceReportFormValues';

export default function AcceptSection(): JSX.Element {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AcceptanceReportFormValues>();

  return (
    <AcceptanceReportFormSection title={t('acceptanceReport.acceptance.title')}>
      <RadioGroup
        value={values.acceptance}
        onChange={(value) => setFieldValue('acceptance', value)}
        className={cx(stylex.create({ marginTop: 'medium', gap: 'small-3x' }))}
      >
        <Flex>
          <Radio
            value={Acceptance.ACCEPT}
            label={t('acceptanceReport.acceptance.accept.placeholder')}
            disableExtraPressableArea
          />
        </Flex>

        <Flex>
          <Radio
            value={Acceptance.NOT_ACCEPT}
            label={t('acceptanceReport.acceptance.notAccept.placeholder')}
            disableExtraPressableArea
          />
        </Flex>
      </RadioGroup>

      {values.acceptance === Acceptance.NOT_ACCEPT && (
        <>
          <Text component="div" color="primary" className="u-margin-top">
            {t('acceptanceReport.acceptance.info')}
          </Text>

          <FormikFormField
            name="acceptanceDescriptionText"
            placeholder={t('acceptanceReport.acceptance.descriptionText.placeholder')}
            className="u-margin-top"
          />

          <FormikFormField
            name="acceptanceDescriptionReference"
            label={t('acceptanceReport.acceptance.descriptionReference.label')}
            placeholder={t('acceptanceReport.acceptance.descriptionReference.placeholder')}
            className="u-margin-top"
          />
        </>
      )}
    </AcceptanceReportFormSection>
  );
}
