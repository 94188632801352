import { ReactNode, useEffect } from 'react';
import { Navigate, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { AppHeader, LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer, MissingScopeError } from '@hs-baumappe/redkit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import useCanvasData from '../hooks/useCanvasData';
import useCanvasAlert from '../hooks/useCanvasAlert';
import useConstructionNoteDetail from '../hooks/useConstructionNoteDetail';
import EditLayout from '../layouts/EditLayout';
import routes, { projectRoutes } from '../../routes';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import {
  ConstructionNoteLocationState,
  ConstructionNoteRouteParams,
} from '../ConstructionNote.route.types';
import { ConstructionNoteStatus } from '../../../globalTypes';

export default function ConstructionNoteEdit(): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const location = useLocation<ConstructionNoteLocationState>();
  const navigate = useNavigate();

  const [fetchData, { loading: canvasDataLoading, data: canvasData, error: canvasDataError }] =
    useCanvasData();
  const { alertMessage, alertColor } = useCanvasAlert();
  const { loading, data, error } = useConstructionNoteDetail(constructionNoteId);

  useEffect(() => {
    if (data && data.constructionNote && data.constructionNote.path) {
      fetchData(data.constructionNote.path);
    }
  }, [data, fetchData]);

  if (loading || canvasDataLoading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!data || !viewer) {
    return null;
  }

  if (canvasDataError || !canvasData) {
    return <Alert color="error">{t('constructionNote.dataLoadError')}</Alert>;
  }

  const { constructionNote } = data;
  const updateConstructionNoteDocument = viewer.scopes.updateConstructionNoteDocument;

  if (constructionNote.status === ConstructionNoteStatus.ARCHIVED) {
    return (
      <Navigate
        to={constructionNoteRoutes.detailFreeDrawing.generatePath({
          constructionNoteId,
        })}
      />
    );
  }

  function handleEditTitleClick() {
    navigate(
      constructionNoteRoutes.editTitle.generatePath({
        constructionNoteId,
      }),
      {
        state: {
          backgroundLocation: location,
        } as ConstructionNoteLocationState,
      },
    );
  }

  function renderTitle(): ReactNode {
    return (
      <Flex alignItems="center" gap="small-4x">
        <Text component="h1" variant="body-large" color="muted" stylex={{ marginBottom: '0' }}>
          {constructionNote.name}
        </Text>

        <IconButton icon={<Icon name="create" />} onClick={handleEditTitleClick} />
      </Flex>
    );
  }

  return (
    <Box className="container" stylex={{ paddingEnds: 'large' }}>
      <Breadcrumb>
        <BreadcrumbItem component={NavLink} to={routes.projects.generatePath({})}>
          {t('breadcrumb.project')}
        </BreadcrumbItem>

        <BreadcrumbItem
          component={NavLink}
          to={projectRoutes.detail.generatePath({ projectId: constructionNote.project.id })}
        >
          {constructionNote.project.projectNumber}
        </BreadcrumbItem>
      </Breadcrumb>

      <AppHeader
        title={renderTitle()}
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />

      <Box className="o-construction-note-editor-container" stylex={{ marginTop: 'large-x' }}>
        {alertMessage && (
          <Alert color={alertColor} stylex={{ marginBottom: 'medium' }}>
            {alertMessage}
          </Alert>
        )}

        {!updateConstructionNoteDocument.isPermitted && (
          <MissingScopeError missingScopes={updateConstructionNoteDocument.missingScopeNames} />
        )}

        {updateConstructionNoteDocument.isPermitted && (
          <EditLayout data={canvasData} projectId={constructionNote.project.id} />
        )}
      </Box>
    </Box>
  );
}
