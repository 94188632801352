import { ObstructionStatus } from '../../../../../globalTypes';

export function getStatusColorOfObstruction(status: ObstructionStatus): 'muted' | 'success' {
  switch (status) {
    case ObstructionStatus.SIGNED:
    case ObstructionStatus.SENT:
    case ObstructionStatus.ARCHIVED:
      return 'success';
    default:
      return 'muted';
  }
}
