import mexp from 'math-expression-evaluator';
import { OperationUnit } from '../../../../globalTypes';

interface Multiplierable {
  id?: string;
  name?: string;
  subtraction?: boolean;
  multiplier?: number | null;
}

interface Totalable {
  total: string;
}

type OneDimensionValue = string[];

type TwoDimensionValue = OneDimensionValue;

type ThreeDimensionValue = TwoDimensionValue;

export interface SquareMeter extends Multiplierable, Totalable {
  value: TwoDimensionValue;
}

interface SquareMeterValues {
  type: OperationUnit.SQUARE_METER;
  values: SquareMeter;
}

export interface Meter extends Multiplierable, Totalable {
  value: OneDimensionValue;
}

interface MeterValues {
  type: OperationUnit.METER;
  values: Meter;
}

export interface CubicMeter extends Multiplierable, Totalable {
  value: ThreeDimensionValue;
}

interface CubicMeterValues {
  values: CubicMeter;
  type: OperationUnit.CUBIC_METER;
}

export interface Liter extends Multiplierable, Totalable {
  value: OneDimensionValue;
}

interface LiterValues {
  values: Liter;
  type: OperationUnit.LITER;
}

export interface Kilogram extends Multiplierable, Totalable {
  value: OneDimensionValue;
}

interface KilogramValues {
  values: Kilogram;
  type: OperationUnit.KILOGRAM;
}

export interface Day extends Multiplierable, Totalable {
  value: OneDimensionValue;
}

interface DayValues {
  values: Day;
  type: OperationUnit.DAY;
}

export interface Hour extends Multiplierable, Totalable {
  value: OneDimensionValue;
}

interface HourValues {
  values: Hour;
  type: OperationUnit.HOUR;
}

export interface Piece {
  value: OneDimensionValue;
}

interface PieceValues {
  values: Totalable;
  type: OperationUnit.QUANTITY;
}

interface FixRateValues {
  values: Multiplierable;
  type: OperationUnit.FLAT_RATE;
}

export type SubValues = Meter | CubicMeter | Liter | SquareMeter | Kilogram | Day | Hour;

export type MeasurementValues =
  | SquareMeterValues
  | MeterValues
  | CubicMeterValues
  | LiterValues
  | KilogramValues
  | DayValues
  | HourValues
  | PieceValues
  | FixRateValues;

export const evalValue = (exp: string): number => {
  try {
    const result = mexp.eval(exp);

    return parseFloat(result);
  } catch (e) {
    return NaN;
  }
};

interface InitialMeasurmentValues {
  multiplier: number;
  value: string[];
  total: string;
  name: string;
  subtraction: boolean;
}

export const initialMeasurementValues = (): InitialMeasurmentValues => {
  return { multiplier: 1, value: ['', '', ''], total: '', name: '', subtraction: false };
};

interface FlatRateMeasurmentValue {
  id?: string;
  multiplier: number;
  meta: string[];
  total: number;
  totalMeta: string;
  name: string;
  subtraction: boolean;
}

export const createFlatRateMeasurmentValue = (): FlatRateMeasurmentValue => ({
  id: undefined,
  meta: ['', '', ''],
  name: 'Menge Nr. 1',
  subtraction: false,
  multiplier: 1,
  total: 1,
  totalMeta: '1',
});

export const hasMultiplier = (v: MeasurementValues['values']): v is Multiplierable => {
  return 'multiplier' in v;
};

export const hasValue = (v: MeasurementValues['values']): v is SubValues => {
  return 'value' in v;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const hasTotal = (v: any): v is Totalable => {
  return 'total' in v;
};

export const reduceValues = (values: string[]): number | undefined => {
  if (!values.length) return undefined;

  const metas = values.filter((v) => !!v);
  return metas.reduce((initialValue, value) => evalValue(value) * initialValue, 1);
};

export const cast = (values?: MeasurementValues[]): FlatRateMeasurmentValue[] => {
  if (!values) {
    return [];
  }

  if (!values.length) {
    return [];
  }

  return values.map((m) => {
    const metas = hasValue(m.values) ? m.values.value : [];
    const multiplier = hasMultiplier(m.values) ? m.values.multiplier : 1;
    const t = hasTotal(m.values) ? m.values.total : '';
    const name = hasMultiplier(m.values) ? m.values.name : '';
    const subtraction = hasMultiplier(m.values) ? m.values.subtraction : false;
    const id = hasMultiplier(m.values) ? m.values.id : undefined;

    const tResult = evalValue(t);

    return {
      id,
      meta: metas,
      name: name || '',
      subtraction: subtraction || false,
      multiplier: parseFloat(`${multiplier}`) || 1,
      total: tResult,
      totalMeta: `${tResult}`,
    };
  });
};

export function clearEmptyTotal(
  measurements: FlatRateMeasurmentValue[],
): FlatRateMeasurmentValue[] {
  return measurements.filter((f) => f.total);
}
