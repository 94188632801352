import TouchPair from './TouchPair';

/*
 * The scale factor is the ratio between the first touchpoints distance and the current touchpoints distance.
 * The calculation is based on this formula:
 * scale(t) = distanceBetweenTouches(t) / distanceBetweenTouches(t0)
 */
// eslint-disable-next-line import/prefer-default-export
export function calculateScaleFactor(
  currentTouchPair: TouchPair,
  firstTouchPair: TouchPair,
): number {
  const currentTouchPairDistance = currentTouchPair.distance;
  const firstTouchPairDistance = firstTouchPair.distance;

  return currentTouchPairDistance / firstTouchPairDistance;
}
