import { RefObject, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { fabric } from '@hs-baumappe/fabric';
import useCanvas from './hooks/useCanvas';
import useSelectedTool from './hooks/useSelectedTool';
import useCanvasGestures from './hooks/useCanvasGestures';
import NoteEditorCanvas from '../../components/note-editor/NoteEditorCanvas';
import { deselectAll, selectAllObjects } from './canvasUtils';

interface CanvasProps {
  initialState?: string;
  readOnly?: boolean;
  canvasAreaRef: RefObject<HTMLDivElement>;
}

export default function Canvas({
  canvasAreaRef,
  initialState,
  readOnly = false,
}: CanvasProps): JSX.Element {
  const element = useRef<HTMLCanvasElement>(null);
  const { canvas, initCanvas, loadData, setReadOnly, activeObjects, moveModeEnabled } = useCanvas();
  const { setSelectedTool, selectedTool } = useSelectedTool();

  useCanvasGestures(canvasAreaRef);

  useEffect(() => {
    if (!element.current || !canvasAreaRef.current) {
      return;
    }

    initCanvas(element.current, {
      preserveObjectStacking: true,
      backgroundColor: 'white',
      width: canvasAreaRef.current.clientWidth,
      height: canvasAreaRef.current.clientHeight,
    });
  }, [element, initCanvas, canvasAreaRef]);

  useEffect(() => {
    if (!canvas || !initialState) {
      return;
    }

    loadData(initialState);
  }, [canvas, loadData, initialState]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (!readOnly && moveModeEnabled) {
      setReadOnly(true);
    } else {
      setReadOnly(readOnly);
    }
  }, [canvas, loadData, setReadOnly, readOnly, moveModeEnabled]);

  useEffect(() => {
    const handleResize = () => {
      if (!canvasAreaRef.current || !canvas) {
        return;
      }

      canvas.setWidth(canvasAreaRef.current.clientWidth);
      canvas.setHeight(canvasAreaRef.current.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvas, canvasAreaRef]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (activeObjects.length === 1 && activeObjects[0] instanceof fabric.Textbox) {
      setSelectedTool('text');
      return;
    }

    if (canvas.isDrawingMode) {
      const { freeDrawingBrush } = canvas;

      if (freeDrawingBrush instanceof fabric.EraserBrush) {
        setSelectedTool('eraser');
        return;
      }

      setSelectedTool('pen');
      return;
    }

    setSelectedTool(undefined);
  }, [canvas, setSelectedTool, activeObjects]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (selectedTool !== 'pen' && selectedTool !== 'eraser') {
      canvas.isDrawingMode = false;
    }
  }, [canvas, selectedTool, setSelectedTool]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    function handleCanvasObjectAdded(event: fabric.IEvent) {
      const { target } = event;

      if (target instanceof fabric.Textbox) {
        target.set({ erasable: false });
      }

      if (target instanceof fabric.Image) {
        target.set({ erasable: false });
      }
    }

    canvas.on('object:added', handleCanvasObjectAdded);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.off('object:added', handleCanvasObjectAdded);
    };
  }, [canvas]);

  useHotkeys(
    'esc',
    () => {
      if (!canvas) {
        return;
      }

      deselectAll(canvas);
      setSelectedTool(undefined);
    },
    [canvas],
  );

  useHotkeys(
    'ctrl+a, command+a',
    (event) => {
      if (!canvas || readOnly) {
        return;
      }

      event.preventDefault();
      selectAllObjects(canvas);
    },
    [canvas],
  );

  return <NoteEditorCanvas ref={element} />;
}
