import { FC } from 'react';
import { Link, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface CompProps {
  hsContactEmail: string;
}

const LinkExpired: FC<CompProps> = ({ hsContactEmail }) => {
  const { t } = useTranslation();

  return (
    <div className="u-display-flex u-align-items-center u-margin-top-xlarge u-flex-direction-column">
      <Text variant="title-large" className="u-margin-bottom-small">
        {t('qsstSign.customerLinkExpired.title')}
      </Text>

      <br />

      <Text variant="body-medium">{t('qsstSign.customerLinkExpired.content1')}</Text>

      <Text variant="body-medium">{t('qsstSign.customerLinkExpired.content2')}</Text>

      <br />

      <Text component="p" variant="label-large">
        {t('qsstSign.customerLinkExpired.content3')}
      </Text>

      <Link href={`mailto:${hsContactEmail}`} variant="body-medium" color="primary">
        {hsContactEmail}
      </Link>
    </div>
  );
};

export { LinkExpired };
