import {
  Flex,
  Icon,
  IconButton,
  JobProgressWidget,
  List,
  ListItem,
  Portal,
  Spinner,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import styles from './sap-transmit-widget.module.scss';

interface SAPTransmitModalLoadingProps {
  selectedProjectIds: string[];
}

export default function SAPTransmitModalLoading({
  selectedProjectIds,
}: SAPTransmitModalLoadingProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Portal>
      <div className={styles['sap-transmit-widget']}>
        <JobProgressWidget
          defaultOpen
          title={t('transmit.widget.title', {
            selectedProjectIdsLength: selectedProjectIds.length,
          })}
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            stylex={{
              paddingEnds: 'small-3x',
              paddingSides: 'small-x',
              backgroundColor: 'gray-300',
              color: 'error',
            }}
          >
            <Text>{t('transmit.widget.text')}</Text>
            <IconButton
              type="button"
              size="small"
              color="inherit"
              onClick={() => undefined}
              aria-label="Cancel"
              icon={<Icon name="close" />}
            />
          </Flex>
          <List>
            <ListItem rightSection={<Spinner size="24" />}>
              <Text truncate>{t('transmit.widget.info')}</Text>
            </ListItem>
          </List>
        </JobProgressWidget>
      </div>
    </Portal>
  );
}
