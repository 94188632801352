import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_FREEFORM_FOLDER_MUTATION = gql`
  mutation UpdateFreeformFolder($input: UpdateFolderForFreeFormInput!) {
    updateFolderForFreeForm(input: $input) {
      id
    }
  }
`;
