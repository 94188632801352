import { ConcernImageInput, ConcernSaveInput } from '../../../../globalTypes';
import { ConcernEditDetail } from '../../ConcernEdit/graphql/__generated__/ConcernEditDetail.query';
import { ConcernFormValues, Misgivings } from './ConcernForm.types';

type ConcernImage = NonNullable<ConcernEditDetail['concern']>['images'][number];

export function castConcernImageAttachmentsToFormValues(
  input: ConcernImage[],
): ConcernFormValues['images'] {
  return input.map(({ id, label, thumbnail, url }) => ({
    orderId: Math.random().toString(36).slice(2),
    imageId: id,
    label: label || undefined,
    imageThumbnailUrl: thumbnail,
    imageUrl: url,
  }));
}

export function createConcernFormInitialValues(
  initialValues?: Partial<ConcernFormValues>,
): ConcernFormValues {
  return {
    projectNumber: '',
    clientName: '',
    date: undefined,
    professions: [],
    misgivings: [],
    execution: undefined,
    qualities: [],
    contractorsName: '',
    contractorsDescription: '',
    endDate: '',
    images: [],
    ...initialValues,
  };
}

export function createConcernSaveExecutionInput({
  misgivings,
  execution,
}: ConcernFormValues): ConcernSaveInput['execution'] {
  return misgivings.includes(Misgivings.EXECUTION) ? execution : undefined;
}

export function createConcernSaveQualityInput({
  misgivings,
  qualities,
}: ConcernFormValues): ConcernSaveInput['quality'] {
  const hasQuality = misgivings.includes(Misgivings.QUALITY);
  if (!hasQuality || !qualities) {
    return undefined;
  }

  return qualities.map((quality) => ({
    name: quality.name,
    description: quality.description,
  }));
}

export function createConcernSaveContractorInput({
  misgivings,
  contractorsName,
  contractorsDescription,
}: ConcernFormValues): ConcernSaveInput['contractor'] {
  const hasContractor = misgivings.includes(Misgivings.CONTRACTORS);
  if (!hasContractor || !contractorsName || !contractorsDescription) {
    return undefined;
  }

  return {
    name: contractorsName,
    description: contractorsDescription,
  };
}

export function createConcernSaveImageInput({
  images,
}: ConcernFormValues): ConcernSaveInput['images'] {
  return images.reduce<ConcernImageInput[]>((result, { imageId, label }) => {
    if (!imageId) {
      return result;
    }

    return [...result, { imageId, label }];
  }, []);
}

export function createConcernSaveInput({
  formValues,
  concernId,
}: {
  formValues: ConcernFormValues;
  concernId: string;
}): ConcernSaveInput {
  return {
    documentId: concernId,
    clientName: formValues.clientName,
    professions: formValues.professions,
    date: formValues.date,
    endDate: formValues.endDate,
    execution: createConcernSaveExecutionInput(formValues),
    quality: createConcernSaveQualityInput(formValues),
    contractor: createConcernSaveContractorInput(formValues),
    images: createConcernSaveImageInput(formValues),
  };
}
