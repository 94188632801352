import { FC } from 'react';
import cx from 'classnames';
import { Text } from '@hs-baumappe/legacy-ui';

import exclamationMarkIcon from '../../static/img/precautionIcons/exclamationMark.svg';
import explosionIcon from '../../static/img/precautionIcons/explosion.svg';
import fallingPartsIcon from '../../static/img/precautionIcons/fallingParts.svg';
import fallingRiskIcon from '../../static/img/precautionIcons/fallingRisk.svg';
import hazardousIcon from '../../static/img/precautionIcons/hazardous.svg';
import lightningIcon from '../../static/img/precautionIcons/lightning.svg';
import movingPartsIcon from '../../static/img/precautionIcons/movingParts.svg';
import slipperyGroundIcon from '../../static/img/precautionIcons/slipperyGround.svg';
import mobilePlatformIcon from '../../static/img/precautionIcons/mobilePlatform.svg';
import liftPlatformIcon from '../../static/img/precautionIcons/liftPlatform.svg';
import ladderIcon from '../../static/img/precautionIcons/ladder.svg';
import scaffoldIcon from '../../static/img/precautionIcons/scaffold.svg';

import './c-precaution-category-header.scss';

interface Props {
  iconName: string;
  title: string;
  size?: 'normal' | 'small';
}

const categoryIcons: { [key: string]: string } = {
  exclamationMark: exclamationMarkIcon,
  explosion: explosionIcon,
  fallingParts: fallingPartsIcon,
  fallingRisk: fallingRiskIcon,
  hazardous: hazardousIcon,
  lightning: lightningIcon,
  movingParts: movingPartsIcon,
  slipperyGround: slipperyGroundIcon,
  mobilePlatform: mobilePlatformIcon,
  liftPlatform: liftPlatformIcon,
  ladder: ladderIcon,
  scaffold: scaffoldIcon,
};

const PrecautionCategoryHeader: FC<Props> = ({ iconName, title, size = 'normal' }) => (
  <div
    className={cx('c-precaution-category-header', {
      'c-precaution-category-header--small': size === 'small',
    })}
  >
    <div className="c-precaution-category-header__iconContainer">
      <img
        className="u-max-width-100%"
        src={categoryIcons[iconName] || exclamationMarkIcon}
        alt={title}
      />
    </div>
    <Text
      component="h3"
      variant={size === 'small' ? 'body-medium' : 'body-large'}
      color="muted"
      stylex={{ marginEnds: '0' }}
    >
      {title}
    </Text>
  </div>
);

export default PrecautionCategoryHeader;
