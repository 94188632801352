import { Box, FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { ReactNode } from 'react';

interface ProjectInformationLayoutProps {
  nameSection: ReactNode;
  additionalNameSection: ReactNode;
  addressSection: ReactNode;
  citySection: ReactNode;
  postCodeSection: ReactNode;
  dateSection: ReactNode;
}

export default function ProjectInformationLayout({
  nameSection,
  additionalNameSection,
  addressSection,
  citySection,
  postCodeSection,
  dateSection,
}: ProjectInformationLayoutProps): JSX.Element {
  return (
    <Box
      stylex={{
        backgroundColor: 'gray-100',
        borderRadius: 'medium',
        marginTop: 'medium',
        padding: 'small-2x',
      }}
    >
      <FlexGrid>
        <FlexGridColumn xs stylex={{ borderRight: '1px-solid-gray-300' }}>
          <Stack gap="small-2x">
            <Box>{nameSection}</Box>
            <Box>{additionalNameSection}</Box>
          </Stack>
        </FlexGridColumn>

        <FlexGridColumn xs="auto" stylex={{ borderRight: '1px-solid-gray-300' }}>
          <Stack gap="small-2x" stylex={{ paddingSides: 'medium' }}>
            <Stack gap="small-2x">
              <Box>{addressSection}</Box>

              <FlexGrid>
                <FlexGridColumn xs={8} stylex={{ borderRight: '1px-solid-gray-300' }}>
                  {citySection}
                </FlexGridColumn>
                <FlexGridColumn xs={4}>{postCodeSection}</FlexGridColumn>
              </FlexGrid>
            </Stack>
          </Stack>
        </FlexGridColumn>

        <FlexGridColumn xs={5}>
          <FlexGrid>
            <FlexGridColumn xs={7}>{dateSection}</FlexGridColumn>
          </FlexGrid>
        </FlexGridColumn>
      </FlexGrid>
    </Box>
  );
}
