import { useField } from 'formik';
import { Details, Summary, Text } from '@hs-baumappe/legacy-ui';
import { ChangeEvent, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ObstructionFormValues } from '../../ObstructionForm.form';
import { createEmptyImageWithLabel } from '../../../../../../containers/forms/ImageUploadWithLabel';
import ObstructionFormImageAttachment from './components/ObstructionFormImageAttachment';
import ObstructionFormImageAttachmentMenu from './components/ObstructionFormImageAttachmentMenu';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../../../../components/DriveImageGalleryContainer';
import ObstructionConvertImageAttachmentsMutation from './graphql/ObstructionConvertImageAttachments.mutation';
import ErrorState from '../../../../../../containers/ErrorState';

interface ObstructionFormImageAttachmentSectionProps {
  projectId: string;
}

export default function ObstructionFormImageAttachmentSection({
  projectId,
}: ObstructionFormImageAttachmentSectionProps): JSX.Element {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState<boolean>(false);
  const [imageAttachmentsField, imageAttachmentsFieldMeta, imageAttachmentsFieldHelpers] =
    useField<ObstructionFormValues['images']>('images');
  const [convertDriveImagesMutation, { loading, error }] = useMutation(
    ObstructionConvertImageAttachmentsMutation,
  );

  function removeImageAttachmentByIndex(index: number) {
    const imageAttachments = [...imageAttachmentsField.value];

    imageAttachments.splice(index, 1);

    imageAttachmentsFieldHelpers.setValue(imageAttachments);
  }

  function handleAddImagesFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const files = Array.from(event.target.files);
    const newImages = files.map((file) => createEmptyImageWithLabel({ file }));

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    imageAttachmentsFieldHelpers.setValue([...imageAttachmentsField.value, ...newImages]);
  }

  async function convertImages(selectedDocuments: string[]) {
    const { data } = await convertDriveImagesMutation({
      variables: {
        input: selectedDocuments,
      },
    });

    if (!data) {
      return;
    }

    return data.convertCustomFilesToImages;
  }

  async function handleDocumentsConverted(selectedDocuments: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    const documentIds = selectedDocuments.map(({ id }) => id);
    const convertedImages = await convertImages(documentIds);

    if (!convertedImages) {
      return;
    }

    const imageAttachments: ObstructionFormValues['images'] = convertedImages.map(
      (convertedImage, index) => {
        const selectedDocument = selectedDocuments[index];

        return createEmptyImageWithLabel({
          imageId: convertedImage.id,
          label: selectedDocument?.name || '',
          imageUrl: convertedImage.url,
          imageThumbnailUrl: convertedImage.url,
        });
      },
    );

    imageAttachmentsFieldHelpers.setValue([...imageAttachmentsField.value, ...imageAttachments]);
  }

  function handleUploadImageButtonClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const hasImageAttachments = imageAttachmentsField.value.length > 0;
  const showRemoveButton = imageAttachmentsField.value.length > 1;
  const hasValidImageAttachmentOnInitial = imageAttachmentsFieldMeta.initialValue
    ? imageAttachmentsFieldMeta.initialValue.some((imageAttachment) => !!imageAttachment.imageId)
    : false;

  return (
    <Details
      defaultOpen={hasValidImageAttachmentOnInitial}
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="title-small" color="muted" component="h3" className="u-margin-bottom-0">
            {t('obstructionForm.imageAttachment.title')}
          </Text>
        </Summary>
      )}
    >
      {error && <ErrorState error={error} />}

      <div className="row">
        <div className="col col--sm-5">
          <Text component="p">{t('obstructionForm.imageAttachment.helperText')}</Text>
        </div>
      </div>
      <div className="u-margin-top">
        {imageAttachmentsField.value.map((imageAttachment, index) => {
          return (
            <ObstructionFormImageAttachment
              key={imageAttachment.orderId}
              name={`images.${index}`}
              onRequestRemove={() => removeImageAttachmentByIndex(index)}
              showRemoveButton={showRemoveButton}
            />
          );
        })}
        <div className="row">
          <div className="col col--sm-5">
            <ObstructionFormImageAttachmentMenu
              hasImageAttachments={hasImageAttachments}
              onUploadImageButtonClick={handleUploadImageButtonClick}
              onSelectImageButtonClick={() => setDriveImageGalleryOpen(true)}
              loading={loading}
            />

            <input
              ref={fileInputRef}
              type="file"
              className="u-visually-hidden"
              accept="image/jpeg, image/png, image/jpg"
              multiple
              onChange={handleAddImagesFileInputChange}
            />
          </div>
        </div>
      </div>

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsConverted}
      />
    </Details>
  );
}
