import { gql } from '@apollo/client';

const SIVDiscountSIVFragment = gql`
  fragment SIVDiscountSIV on SIV {
    id

    discount {
      amount
      active
    }

    cockpit {
      totalPerformanceCost {
        planned
        plannedRaw
        recorded
      }
    }
  }
`;

export default SIVDiscountSIVFragment;
