import { SchemaOf } from 'yup';
import { OperationValues } from './values';
import { paymentMutationValidationSchema } from '../../PaymentForm/paymentMutationValidationSchema';
import { MeasurementsSectionValidationSchema } from '../../../measurement/MeasurementsSection/validationSchema';
import { OfferMode, OperationUnit } from '../../../../../globalTypes';
import yup from '../../../../../yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (offerMode?: OfferMode): SchemaOf<OperationValues | undefined> => {
  const alias = yup
    .string()
    .max(4)
    .min(4)
    .test('is-int', 'validation.required', (v) => {
      if (!v) {
        return true;
      }

      return Number(v) % 1 === 0;
    });

  const aliasValidation = offerMode === OfferMode.SYSTEM_NUMBER ? alias : alias.required();

  return yup.object({
    alias: aliasValidation,
    name: yup.string().max(255).required(),
    description: yup.string(),
    price: yup.number().required().typeError('validation.required'),
    payment: paymentMutationValidationSchema().optional(),
    note: yup.string(),
    optional: yup.boolean(),
    measurements: MeasurementsSectionValidationSchema(false).defined().default([]),
    unit: yup.mixed<OperationUnit>().oneOf(Object.values(OperationUnit)).required(),
    approximate: yup.boolean().required(),
  });
};

export const defaultValues = (initialValues?: Partial<OperationValues>): OperationValues => {
  return {
    description: '',
    name: '',
    note: '',
    optional: false,
    price: 0,
    measurements: [],
    approximate: true,
    ...initialValues,
  };
};
