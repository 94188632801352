import { gql, TypedDocumentNode } from '@apollo/client';
import {
  SIVDiscountDetail,
  SIVDiscountDetailVariables,
} from './__generated__/SIVDiscountDetail.query';
import SIVDiscountSIVFragment from '../../../graphql/SIVDiscountSIV.fragment';

const SIVDiscountDetailQuery: TypedDocumentNode<SIVDiscountDetail, SIVDiscountDetailVariables> =
  gql`
    query SIVDiscountDetail($projectId: ID!) {
      project(id: $projectId) {
        id

        siv {
          ...SIVDiscountSIV
        }
      }
    }

    ${SIVDiscountSIVFragment}
  `;

export default SIVDiscountDetailQuery;
