import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { qsstRoutes } from '../qsstRoutes';
import { QsstLocationState } from '../Qsst.route.types';
import { navigateToLocation } from '../../routes.utils';
import RouteDialog from '../../../containers/RouteDialog';
import { Qsst } from '../graphql/__generated__/Qsst.fragment';
import { QsstScopes } from '../Qsst.types';
import { QsstProject } from '../graphql/__generated__/QsstProject.fragment';
import QsstEditBody from './components/QsstEditBody';

interface QsstEditProps {
  qsst: Qsst;
  scopes: QsstScopes;
  project: QsstProject;
}

export default function QsstEdit({ qsst, scopes, project }: QsstEditProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<QsstLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: qsstRoutes.detail.generatePath({ qsstId: qsst.id }),
    });
  }, [navigate, location.state, qsst.id]);

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  const permitted = scopes.updateQsstDocument.isPermitted;
  const missingScopeNames = scopes.updateQsstDocument.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
            {project.additionalName && ' | ' + project.additionalName}
          </Text>

          <Text variant="title-small" component="h2" className="u-margin-bottom-0">
            {t('editQsst.title', { documentName: qsst.name })}
          </Text>
        </>
      }
    >
      {permitted ? (
        <QsstEditBody
          qsstId={qsst.id}
          projectId={project.id}
          formDirty={formDirty}
          setFormDirty={setFormDirty}
          onRequestClose={() => setDialogOpen(false)}
        />
      ) : (
        <MissingScopeError missingScopes={missingScopeNames} />
      )}

      <AlertDialog
        destructive
        open={confirmationDialogOpen}
        title={t('editQsst.confirmationModal.title')}
        description={t('editQsst.confirmationModal.body')}
        confirmButtonText={t('editQsst.confirmationModal.confirmButton')}
        cancelButtonText={t('editQsst.confirmationModal.dismissButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={handleConfirmationDialogConfirm}
      />
    </RouteDialog>
  );
}
