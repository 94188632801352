import { ApolloError, useQuery } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import CustomFileDocumentDetailQuery from '../../graphql/CustomFileDocumentDetail.query';
import { CustomFileDocument } from '../../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../../graphql/__generated__/CustomFileDocumentProject.fragment';
import { CustomFileDocumentScopes } from '../../Document.types';

interface UseCustomFileDocumentDataResponse {
  document: CustomFileDocument;
  project: CustomFileDocumentProject;
  scopes: CustomFileDocumentScopes;
}

interface UseCustomFileDocumentResponse {
  data?: UseCustomFileDocumentDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useCustomFileDocument(documentId: string): UseCustomFileDocumentResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(CustomFileDocumentDetailQuery, {
    variables: {
      id: documentId,
    },
  });

  const document = data?.driveDocument;
  const project = data?.driveDocument?.project;
  const scopes = viewer?.scopes;

  return {
    data: document && project && scopes ? { document, project, scopes } : undefined,
    loading,
    error,
  };
}
