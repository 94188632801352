import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ConcernCreateDetail,
  ConcernCreateDetailVariables,
} from './__generated__/ConcernCreateDetail.query';

const ConcernCreateDetailQuery: TypedDocumentNode<
  ConcernCreateDetail,
  ConcernCreateDetailVariables
> = gql`
  query ConcernCreateDetail($projectId: ID!) {
    project(id: $projectId) {
      id

      hsProjectNumber
      additionalName
      description
      date
      draft

      client {
        name
      }
    }
  }
`;

export default ConcernCreateDetailQuery;
