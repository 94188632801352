import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ProjectFilterTargetedProjects,
  ProjectFilterTargetedProjectsVariables,
} from './__generated__/ProjectFilterTargetedProjects.query';

const ProjectFilterTargetedProjectsQuery: TypedDocumentNode<
  ProjectFilterTargetedProjects,
  ProjectFilterTargetedProjectsVariables
> = gql`
  query ProjectFilterTargetedProjects($includeStatus: [ProjectStatus!]!, $profitCenters: [ID!]!) {
    projects(includeStatus: $includeStatus, profitCenters: $profitCenters) {
      meta {
        totalCount

        selectedFilters {
          status {
            status
            count
          }
          profitCenter {
            count

            profitCenter {
              id
            }
          }
        }
      }
    }
  }
`;

export default ProjectFilterTargetedProjectsQuery;
