import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';

interface FormFeedbackInfoModalProps {
  visible?: boolean;
  onRequestClose?: () => void;
  message: string;
}

export default function FormFeedbackInfoModal({
  visible,
  message,
  onRequestClose,
}: FormFeedbackInfoModalProps): JSX.Element {
  return (
    <Dialog open={!!visible} onRequestClose={onRequestClose} size="xsmall" role="alertdialog">
      <DialogHeader
        rightSlot={<IconButton onClick={onRequestClose} icon={<Icon name="close" />} />}
      >
        <Text variant="title-small">{message}</Text>
      </DialogHeader>
      <DialogBody>
        <Text variant="body-medium">{message}</Text>
      </DialogBody>
      <DialogActions direction="column">
        <Button onClick={onRequestClose} type="button" data-testid="close-button">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
