import { TypedDocumentNode, gql } from '@apollo/client';
import { ObstructionLog } from './__generated__/ObstructionLog.fragment';

const ObstructionLogFragment: TypedDocumentNode<ObstructionLog> = gql`
  fragment ObstructionLog on ObstructionLog {
    ... on ObstructionArchivedLog {
      id
      obstructionId
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on ObstructionCreatedLog {
      id
      obstructionId
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on ObstructionDeletedLog {
      id
      obstructionId
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on ObstructionEmailSentLog {
      id
      obstructionId
      name
      date

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on ObstructionSignedLog {
      id
      obstructionId
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on ObstructionUpdatedLog {
      id
      obstructionId
      name
      date

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default ObstructionLogFragment;
