import { QsstLog } from '../../graphql/__generated__/QsstLog.fragment';
import { Log } from '../Log.types';

export const QSST_LOG_TYPES = [
  'QsstArchivedLog',
  'QsstAssignedToProjectLog',
  'QsstCreatedLog',
  'QsstDeletedLog',
  'QsstDownloadedLog',
  'QsstEmailSentLog',
  'QsstEmailSentWithAttachmentLog',
  'QsstExternalLinkExpiredLog',
  'QsstNotSignedLog',
  'QsstSignedLog',
  'QsstSignedOnSideLog',
  'QsstUpdatedLog',
  // Remove manual signature
  'QsstUploadedDeletedLog',
  // Upload manual signature
  'QsstUploadedLog',
];

export function isQsstLog(log: Log): log is QsstLog {
  return QSST_LOG_TYPES.includes(log.__typename);
}
