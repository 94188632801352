import yup, { InferObjectToYupShape } from '../../../../yup';
import obstructionFormFactValidation from './components/ObstructionFormFactsSection/components/ObstructionFormFact/ObstructionFormFact.validation';
import { imageUploadWithLabelValidationSchema } from '../../../../containers/forms/ImageUploadWithLabel';
import { ObstructionFormValues } from './ObstructionForm.form';

const obstructionFormValidation = yup.object().shape<InferObjectToYupShape<ObstructionFormValues>>({
  projectNumber: yup.string().required(),
  clientName: yup.string().required(),
  date: yup.date(),
  professions: yup.array().of(yup.string().required()).required().min(1),
  facts: yup.array().of(obstructionFormFactValidation.defined()).required().min(1),
  images: yup.array().of(imageUploadWithLabelValidationSchema.defined()).defined(),
});

export default obstructionFormValidation;
