import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  UploadManuallySignedAcceptanceReport,
  UploadManuallySignedAcceptanceReportVariables,
} from './__generated__/UploadManuallySignedAcceptanceReport.mutation';
import AcceptanceReportFragment from '../../graphql/AcceptanceReport.fragment';

const UploadManuallySignedAcceptanceReportMutation: TypedDocumentNode<
  UploadManuallySignedAcceptanceReport,
  UploadManuallySignedAcceptanceReportVariables
> = gql`
  mutation UploadManuallySignedAcceptanceReport(
    $file: Upload!
    $input: UploadManualPDFToAcceptanceReportInput!
  ) {
    uploadManualPDFToAcceptanceReport(file: $file, input: $input) {
      ...AcceptanceReport
    }
  }

  ${AcceptanceReportFragment}
`;

export default UploadManuallySignedAcceptanceReportMutation;
