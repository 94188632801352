import { ApolloError, useMutation } from '@apollo/client';

import { Folder } from '../../Detail/types';
import { REMOVE_FOLDER_MUTATION } from './graphql';
import { RemoveFolder, RemoveFolderVariables } from './__generated__/graphql';

interface UseRemoveFolderReturnProps {
  error?: ApolloError;
  removeFolder(folderId: string): Promise<boolean>;
  loading?: boolean;
}

export default function useRemoveFolder(): UseRemoveFolderReturnProps {
  const [removeFolderMutation, { error, loading }] = useMutation<
    RemoveFolder,
    RemoveFolderVariables
  >(REMOVE_FOLDER_MUTATION);

  async function removeFolder(id: Folder['id']) {
    const response = await removeFolderMutation({ variables: { input: { id } } });
    return response.data?.removeFolder.status || false;
  }

  return { removeFolder, error, loading };
}
