import { gql } from '@apollo/client';

export const GET_CATALOGUES = gql`
  query CreateNewOfferGetCatalogues($id: ID!) {
    project(id: $id) {
      id
      description
      draft
      additionalName
    }

    catalogues {
      id
      name
    }
  }
`;

export const OfferFrameworkSave = gql`
  mutation OfferFrameworkSave($input: OfferFrameworkSaveInput!) {
    offerFrameworkSave(input: $input) {
      id
      name
    }
  }
`;

export const OfferFreeformSave = gql`
  mutation OfferFreeformSave($input: OfferFreeFormSaveInput!) {
    offerFreeFormSave(input: $input) {
      id
      name
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const UPLOAD_CATALOGUE_MUTATION = gql`
  mutation UploadOfferCatalogue($file: Upload!) {
    uploadCatalogue(file: $file) {
      id
      name
    }
  }
`;
