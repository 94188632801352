import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiskAssessment } from '../../../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../../../graphql/__generated__/RiskAssessmentProject.fragment';
import { RiskAssessmentScopes } from '../../types';
import RemoveDocument from '../../../../Project/document/RemoveDocument';
import { DocumentType } from '../../../../../globalTypes';
import { riskAssessmentRoutes } from '../../../riskAssessmentRoutes';
import { createDocumentAssignPath } from '../../RiskAssessmentDetail.utils';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';
import { isRiskAssessmentSignable } from '../../../RiskAssessment.utils';

interface RiskAssessmentDetailSidebarBottomProps {
  riskAssessment: RiskAssessment;
  project: RiskAssessmentProject;
  scopes: RiskAssessmentScopes;
  onRemoveSuccess: () => void;
}

export default function RiskAssessmentDetailSidebarBottom({
  riskAssessment,
  project,
  scopes,
  onRemoveSuccess,
}: RiskAssessmentDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();
  const location = useLocation();

  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;
  const signable = isRiskAssessmentSignable({
    riskAssessment,
    signPermitted: scopes.signRiskAssessmentDocument.isPermitted,
  });

  return (
    <Stack gap="small-3x">
      {riskAssessment.viewerCanArchive && archiveEnabled && (
        <Button
          component={Link}
          color="primary"
          endIcon={<Icon name="archive" />}
          to={riskAssessmentRoutes.archive.generatePath({ riskAssessmentId: riskAssessment.id })}
          state={{ backgroundLocation: location }}
          fullWidth
        >
          {t('riskAssessmentDetail.archive.button')}
        </Button>
      )}

      {riskAssessment.viewerCanAssign && (
        <Button
          endIcon={<Icon name="folder" />}
          component={Link}
          to={createDocumentAssignPath(riskAssessment, project.id)}
          fullWidth
        >
          {t('riskAssessmentDetail.assignToProject')}
        </Button>
      )}

      {signable && (
        <Button
          component={Link}
          color="primary"
          endIcon={<Icon name="border-color" />}
          to={riskAssessmentRoutes.sign.generatePath({ riskAssessmentId: riskAssessment.id })}
          state={{ backgroundLocation: location }}
          fullWidth
        >
          {t('riskAssessmentDetail.sign.button')}
        </Button>
      )}

      {riskAssessment.viewerCanRemove && removePermitted && (
        <RemoveDocument
          document={{
            id: riskAssessment.id,
            type: DocumentType.RISK_ASSESSMENT,
            name: riskAssessment.name,
          }}
          renderTrigger={({ onClick, loading: removeLoading }) => (
            <Button
              color="error"
              variant="text"
              onClick={onClick}
              loading={removeLoading}
              fullWidth
            >
              {t('riskAssessmentDetail.removeDocument.button')}
            </Button>
          )}
          onRemoveSuccess={onRemoveSuccess}
        />
      )}
    </Stack>
  );
}
