import { ApolloError, useMutation } from '@apollo/client';

import { CLONE_GROUP_MUTATION } from './graphql';
import { BaseValues } from '../containers/FolderForm/values';
import { cast, clearEmptyTotal } from '../../measurement/MeasurementSection/values';
import { castNote } from '../containers/FolderForm/form';
import { GET_OFFER_DATA } from '../../Detail/graphql';
import { CloneFolder, CloneFolderVariables } from './__generated__/graphql';
import { OfferMode } from '../../../../globalTypes';

export interface FolderCloneProps {
  folderId: string;
  offerId: string;
  offerMode?: OfferMode;
  onSuccess: (id: string) => void;
}

interface UseFolderCloneParams {
  error?: ApolloError;
  cloneFolder: (values: BaseValues) => Promise<void>;
}

const useFolderClone = ({
  folderId,
  onSuccess,
  offerMode,
  offerId,
}: FolderCloneProps): UseFolderCloneParams => {
  const [mutation, { loading, error }] = useMutation<CloneFolder, CloneFolderVariables>(
    CLONE_GROUP_MUTATION,
  );

  const cloneFolder = async (values: BaseValues) => {
    if (loading) {
      return;
    }

    const { measurements, note } = values;
    const unit = measurements && measurements.length ? measurements[0].type : undefined;
    const castedMeasurements = clearEmptyTotal(cast(measurements));
    const castedNotes = castNote(note);

    const { data } = await mutation({
      variables: {
        input: {
          partNoAlias: offerMode === OfferMode.ALIAS_NUMBER ? values.alias : undefined,
          from: folderId,
          name: values.name,
          note: castedNotes,
          approximate: values.approximate,
          unit,
          measurements: castedMeasurements,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    if (!data) {
      return;
    }

    onSuccess(data.cloneFolder.id);
  };

  return {
    error,
    cloneFolder,
  };
};

export default useFolderClone;
