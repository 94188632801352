import { useMutation } from '@apollo/client';
import { AlertDialog, Button, Icon } from '@hs-baumappe/legacy-ui';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { qsstDeleteUploadedPdfMutation } from './graphql/qsstDeleteUploadedPdfMutation';
import {
  QsstDeleteUploadedPdf,
  QsstDeleteUploadedPdfVariables,
} from './graphql/__generated__/qsstDeleteUploadedPdfMutation';

interface DeleteUploadedQsstButtonProps {
  qsstName: string;
  qsstId: string;
}

export const DeleteUploadedQsstButton: FC<DeleteUploadedQsstButtonProps> = ({
  qsstId,
  qsstName,
}) => {
  const { t } = useTranslation();

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const [qsstDeleteUploadedPdf] = useMutation<
    QsstDeleteUploadedPdf,
    QsstDeleteUploadedPdfVariables
  >(qsstDeleteUploadedPdfMutation);

  return (
    <>
      <Button variant="text" color="error" onClick={() => setIsAlertDialogOpen(true)}>
        {t('qsst.deleteUploadedQsstButton')}
      </Button>

      <AlertDialog
        destructive
        open={isAlertDialogOpen}
        title={t('qsstDeleteUploadedModal.title', { documentName: qsstName })}
        description={t('qsstDeleteUploadedModal.description')}
        confirmButtonText={t('qsstDeleteUploadedModal.confirmButton')}
        cancelButtonText={t('qsstDeleteUploadedModal.cancelButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setIsAlertDialogOpen(false)}
        onCancel={() => setIsAlertDialogOpen(false)}
        onConfirm={async () => {
          await qsstDeleteUploadedPdf({
            variables: {
              id: qsstId,
            },
          });

          setIsAlertDialogOpen(false);
        }}
      />
    </>
  );
};
