import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CLONE_GROUP_MUTATION = gql`
  mutation CloneFolder($input: CloneFolderInput!) {
    cloneFolder(input: $input) {
      id
    }
  }
`;
