import { MutationTuple, useMutation } from '@apollo/client';
import UpdateConstructionNoteCanvasMutation from './graphql/UpdateConstructionNoteCanvas.mutation';
import {
  UpdateConstructionNoteCanvas,
  UpdateConstructionNoteCanvasVariables,
} from './graphql/__generated__/UpdateConstructionNoteCanvas.mutation';

export default function useUpdateConstructionNote(): MutationTuple<
  UpdateConstructionNoteCanvas,
  UpdateConstructionNoteCanvasVariables
> {
  return useMutation(UpdateConstructionNoteCanvasMutation);
}
