import { useEffect } from 'react';
import { Alert, Icon, useTimer } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useConcernDetailAlert from './hooks/useConcernDetailAlert';

export default function ConcernDetailAlert(): JSX.Element | null {
  const { t } = useTranslation();
  const { alert, clearAlert } = useConcernDetailAlert();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  useEffect(() => {
    if (!alert) {
      return;
    }

    setTimeoutTimer(clearAlert, 5000);

    return () => clearAllTimers();
  }, [alert, clearAlert, clearAllTimers, setTimeoutTimer]);

  if (!alert) {
    return null;
  }

  return (
    <Alert color="success" endSection={<Icon name="check" />}>
      {t(`concernDetailAlerts.${alert}`)}
    </Alert>
  );
}
