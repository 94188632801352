import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Icon, Search } from '@hs-baumappe/legacy-ui';
import { useApolloClient, useQuery } from '@apollo/client';

import { SidebarLayout } from '../../../../../components';

import {
  AssignableProjectList,
  AssignableProjectListVariables,
} from './graphql/__generated__/AssignableProjectList.query';
import { AssignableProjectListQuery } from './graphql/AssignableProjectList.query';
import { Project } from '../AssignableProjectTable/AssignableProjectTable.types';
import AssignableProjectTable from '../AssignableProjectTable';

interface HSProjectSearchProps {
  defaultSelectedProjectId?: string;
  onClickProjectAssign: (project?: Project) => void;
  onClickProjectImport: () => void;
}

export default function AssignableProjectSearch({
  defaultSelectedProjectId,
  onClickProjectImport,
  onClickProjectAssign,
}: HSProjectSearchProps): JSX.Element | null {
  const { t } = useTranslation();

  const client = useApolloClient();
  const { data, loading, refetch, variables } = useQuery(AssignableProjectListQuery, {
    variables: {
      excludeDraft: true,
    },
  });

  const hasNextPage = data?.projects.pagination.hasNextPage;
  const projects = data?.projects.projects || [];
  const defaultActiveProject =
    projects.find((project) => project.id === defaultSelectedProjectId) || undefined;

  const [selectedProject, setSelectedProject] = useState<Project | undefined>(defaultActiveProject);

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    refetch({ term: event.target.value });
  }

  async function handleClickLoadMoreButton() {
    if (!data) {
      return;
    }

    const nextPage = data.projects.pagination.current + 1;
    const { data: incomingData } = await refetch({
      page: nextPage,
    });

    const incomingProjects = incomingData.projects.projects;

    client.writeQuery<AssignableProjectList, AssignableProjectListVariables>({
      query: AssignableProjectListQuery,
      variables: {
        ...variables,
        page: nextPage,
      },
      data: {
        ...incomingData,
        projects: {
          ...incomingData.projects,
          projects: [...data.projects.projects, ...incomingProjects],
        },
      },
    });
  }

  return (
    <div className="row">
      <div className="col col--sm-8">
        <Search
          id="assign-offer-search"
          fieldProps={{
            label: t('assignDocumentToProject.searchInput.label'),
            placeholder: t('assignDocumentToProject.searchInput.placeholder'),
            onChange: handleSearchChange,
          }}
        />

        <Box stylex={{ marginTop: 'medium' }}>
          <AssignableProjectTable
            projects={projects}
            loading={loading}
            selectedProjectId={selectedProject?.id}
            onProjectClick={setSelectedProject}
          />
        </Box>
        {hasNextPage && (
          <div className="u-width-100% u-margin-top-small">
            <div className="row u-justify-content-center">
              <div className="col col--sm-4">
                <Button onClick={handleClickLoadMoreButton} fullWidth>
                  {t('assignProject.moreLoadButton')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col col--sm-4">
        <SidebarLayout>
          <Button
            disabled={!selectedProject && !defaultActiveProject}
            onClick={() => onClickProjectAssign(selectedProject || defaultActiveProject)}
            color="primary"
            fullWidth
            endIcon={<Icon name="folder" />}
          >
            {t('assignDocumentToProject.importProject.button')}
          </Button>
          <Button
            onClick={onClickProjectImport}
            endIcon={<Icon name="search" />}
            className="u-margin-top-small"
            fullWidth
          >
            {t('assignDocumentToProject.importHSProject.button')}
          </Button>
        </SidebarLayout>
      </div>
    </div>
  );
}
