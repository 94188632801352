import { ComponentProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Link, Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import LogEmails from '../LogEmails';
import { QsstLog as QsstLogType } from '../../graphql/__generated__/QsstLog.fragment';
import { assertUnknownLog, getActor } from '../Log.utils';
import { projectRoutes } from '../../../../routes/routes';

interface QsstLogProps extends ComponentProps<typeof Text> {
  log: QsstLogType;
}

export default function QsstLog({ log, ...props }: QsstLogProps): JSX.Element {
  switch (log.__typename) {
    case 'QsstArchivedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstArchived"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'QsstAssignedToProjectLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstAssignedToProject"
            values={{ qsstName: log.name, projectName: log.projectName }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              projectLink: (
                <Link
                  component={RouterLink}
                  variant="label-medium"
                  to={projectRoutes.detail.generatePath({ projectId: log.projectId })}
                />
              ),
            }}
          />
        </Text>
      );
    case 'QsstCreatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstCreated"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'QsstDeletedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstDeleted"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'QsstDownloadedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstDownloaded"
            values={{ qsstName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'QsstEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstEmailSent"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'QsstEmailSentWithAttachmentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstEmailSentWithAttachment"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'QsstExternalLinkExpiredLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstExternalLinkExpired"
            values={{ qsstName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'QsstNotSignedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstNotSigned"
            values={{
              qsstName: log.name,
              dayCount: log.dayCount || 7,
            }}
            components={{
              bold: <Text variant="label-medium" />,
              warning: <Text color="warning" variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'QsstSignedLog':
      // NOTE: Actor for qsst signed log does not exist, but translations were written as if there is an actor.
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstSigned"
            values={{ qsstName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'QsstSignedOnSideLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstSignedOnSide"
            values={{ qsstName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'QsstUpdatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstUpdated"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'QsstUploadedDeletedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstUploadedDeleted"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'QsstUploadedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.qsstUploaded"
            values={{ qsstName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
