import { ReactNode } from 'react';
import { Box, Button, FlexGrid, FlexGridColumn, Icon, Text } from '@hs-baumappe/legacy-ui';
import { FormikProvider } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';

import useProjectFilterForm from './hooks/useProjectFilterForm';
import ProjectFilterFormStatuses from './components/ProjectFilterFormStatuses';
import ProjectFilterFormProfitCenters from './components/ProjectFilterFormProfitCenters';
import { ProjectFilterFormPreset, ProjectFilterFormValues } from './ProjectFilterForm.form';
import { ProjectFilterViewerProfitCenters } from '../graphql/__generated__/ProjectFilterViewerProfitCenters.query';
import { ProjectFilterProfitCenter } from '../graphql/__generated__/ProjectFilterProfitCenter.fragment';

import './project-filter-form-actions.scss';

interface ProjectFilterFormProps {
  initialValues?: Partial<ProjectFilterFormValues>;
  onSubmit: (
    values: ProjectFilterFormValues,
    helpers: FormikHelpers<ProjectFilterFormValues>,
  ) => void;
  presets: ProjectFilterFormPreset[];
  profitCenters: ProjectFilterProfitCenter[];
  projectCountsByStatus: ProjectFilterViewerProfitCenters['viewer']['projectCountsByStatus'];
  initiallyCollapsedProfitCenterIds: string[];
  submitButtonHelperText?: ReactNode;
  onValuesChange?: (values: ProjectFilterFormValues) => void;
  onProfitCenterToggled: (profitCenterId: string, expanded: boolean) => void;
  submitButtonDisabled?: boolean;
  submitButtonLoading?: boolean;
}

export default function ProjectFilterForm({
  initialValues,
  onSubmit,
  presets,
  profitCenters,
  projectCountsByStatus,
  initiallyCollapsedProfitCenterIds,
  submitButtonHelperText,
  onValuesChange,
  onProfitCenterToggled,
  submitButtonDisabled,
  submitButtonLoading,
}: ProjectFilterFormProps): JSX.Element {
  const { t } = useTranslation();
  const { formik, resetForm, formCanReset } = useProjectFilterForm({
    initialValues,
    onSubmit,
    onValuesChange,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <FlexGrid>
          <FlexGridColumn md={4}>
            <ProjectFilterFormStatuses
              presets={presets}
              projectCountsByStatus={projectCountsByStatus}
            />
          </FlexGridColumn>

          <FlexGridColumn md={8}>
            <ProjectFilterFormProfitCenters
              profitCenters={profitCenters}
              initiallyCollapsedProfitCenterIds={initiallyCollapsedProfitCenterIds}
              onProfitCenterToggled={onProfitCenterToggled}
            />
          </FlexGridColumn>
        </FlexGrid>

        <Box className="project-filter-form-actions" stylex={{ marginTop: 'medium' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={resetForm}
            disabled={!formCanReset}
          >
            {t('projectFilterForm.resetButton')}
          </Button>

          <Button
            className="project-filter-form-actions__submit"
            type="submit"
            color="primary"
            endIcon={<Icon name="filter-list" />}
            fullWidth
            disabled={submitButtonDisabled}
            loading={submitButtonLoading}
          >
            {t('projectFilterForm.submitButton')}

            {submitButtonHelperText && (
              <Text variant="body-small" stylex={{ paddingLeft: 'small-x' }}>
                {submitButtonHelperText}
              </Text>
            )}
          </Button>
        </Box>
      </form>
    </FormikProvider>
  );
}
