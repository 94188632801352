import { useMemo } from 'react';
import { Box, Checkbox, Text, Tree, TreeItem } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import {
  profitCenterHierarchyBuilder,
  ProjectFilterProfitCenterWithChildren,
} from './ProjectFilterFormProfitCenters.utils';
import { ProjectFilterProfitCenter } from '../../../graphql/__generated__/ProjectFilterProfitCenter.fragment';
import useProjectFilterFormProfitCenters from './hooks/useProjectFilterFormProfitCenters';

import './o-profit-centers-list.scss';

interface ProjectFilterFormProfitCentersProps {
  profitCenters: ProjectFilterProfitCenter[];
  initiallyCollapsedProfitCenterIds: string[];
  onProfitCenterToggled: (profitCenterId: string, expanded: boolean) => void;
}

export default function ProjectFilterFormProfitCenters({
  profitCenters,
  initiallyCollapsedProfitCenterIds,
  onProfitCenterToggled,
}: ProjectFilterFormProfitCentersProps): JSX.Element {
  const { t } = useTranslation();
  const { isProfitCenterChecked, setCheckedProfitCenter, setUncheckedProfitCenter } =
    useProjectFilterFormProfitCenters();
  const hierarchicalProfitCenters = useMemo(
    () => profitCenterHierarchyBuilder(profitCenters),
    [profitCenters],
  );

  function handleProfitCenterCheckboxChange(
    profitCenter: ProjectFilterProfitCenterWithChildren,
    checked: boolean,
  ): void {
    if (checked) {
      setCheckedProfitCenter(profitCenter);
      return;
    }

    setUncheckedProfitCenter(profitCenter);
  }

  function renderProfitCenterCheckbox(profitCenter: ProjectFilterProfitCenterWithChildren) {
    const projectCount = profitCenter.projectCount < 100 ? profitCenter.projectCount : '+99';

    return (
      <Checkbox
        id={`profitCenter-${profitCenter.id}`}
        name="profitCenter"
        variant="filled"
        label={`${profitCenter.name} (${projectCount})`}
        value={profitCenter.id}
        checked={isProfitCenterChecked(profitCenter)}
        onChange={(event) => handleProfitCenterCheckboxChange(profitCenter, event.target.checked)}
        disabled={!profitCenter.projectCount}
        disableExtraPressableArea
      />
    );
  }

  function renderProfitCenters(profitCentersWithChildren: ProjectFilterProfitCenterWithChildren[]) {
    return profitCentersWithChildren.map((profitCenter) => {
      return (
        <TreeItem
          key={profitCenter.id}
          label={renderProfitCenterCheckbox(profitCenter)}
          defaultExpanded={!initiallyCollapsedProfitCenterIds.includes(profitCenter.id)}
          indentGuide
          onToggle={(expanded) => onProfitCenterToggled(profitCenter.id, expanded)}
        >
          {profitCenter.children.length > 0 && renderProfitCenters(profitCenter.children)}
        </TreeItem>
      );
    });
  }

  return (
    <Box className="o-profit-centers-list">
      <Text variant="label-large" color="gray-700">
        {t('projectFilterForm.profitCenters.title')}
      </Text>

      <Tree stylex={{ marginTop: 'small-3x' }}>
        {renderProfitCenters(hierarchicalProfitCenters)}
      </Tree>
    </Box>
  );
}
