import { ObstructionFormValues } from '../forms/ObstructionForm/ObstructionForm.form';
import { ObstructionEditDetail } from './graphql/__generated__/ObstructionEditDetail.query';
import { formatISOStringToDateString } from '../../../utils/date';
import castFactsToObstructionFormFactValues from '../utils/castFactsToObstructionFormFactValues';
import { ObstructionProject } from '../graphql/__generated__/ObstructionProject.fragment';

type ObstructionImages = NonNullable<ObstructionEditDetail['obstruction']>['images'];

export function castObstructionImagesToFormValues(
  images: ObstructionImages,
): ObstructionFormValues['images'] {
  return images.map(({ id, label, url, thumbnail }) => ({
    orderId: Math.random().toString(36).substr(2),
    imageId: id,
    imageUrl: url,
    imageThumbnailUrl: thumbnail,
    label: label || undefined,
  }));
}

export function mapObstructionToFormValues({
  obstruction,
  project,
}: {
  obstruction: NonNullable<ObstructionEditDetail['obstruction']>;
  project: ObstructionProject;
}): Partial<ObstructionFormValues> {
  return {
    clientName: obstruction.clientName || '',
    date: obstruction.date ? formatISOStringToDateString(obstruction.date) : undefined,
    facts: castFactsToObstructionFormFactValues(obstruction.facts),
    projectNumber: project.hsProjectNumber || '-',
    professions: obstruction.professions,
    images: castObstructionImagesToFormValues(obstruction.images),
  };
}
