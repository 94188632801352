import { useLocation, useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Alert, Box, Container, Stack, Text, useTimer } from '@hs-baumappe/legacy-ui';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRendererPage } from '@hs-baumappe/redkit';
import MoveTo from 'moveto';
import { ProjectDetailLocationState, ProjectDetailRouteParams } from './ProjectDetail.route.types';
import useResetLogUnreadCount from './useResetLogUnreadCount';
import ProjectDetailLoadingLayout from './components/ProjectDetailLoadingLayout';
import useProjectDetail from './useProjectDetail';
import DriveWebContainer from './DriveWebContainer';

import { GetPartProjectsInformationQuery } from './components/ProjectInformation/graphql/GetPartProjectsInformation.query';
import ProjectDetailNavigation from './components/ProjectDetailNavigation';
import { projectRoutes } from '../../routes';
import ProjectDetailInformation from './components/ProjectDetailInformation';
import SIVCockpitContainer from '../../../components/SIVCockpitContainer/SIVCockpitContainer';
import { useTranslation } from 'react-i18next';
import { ProjectDetailSearchParams, ProjectDetailTab } from './ProjectDetail.utils';
import { getEnumFromValue } from '../../../utils/enumUtils';

const ProjectDetailHeader = lazy(() => import('./components/ProjectDetailHeader'));
const ProjectDraftMessage = lazy(() => import('./components/ProjectDraftMessage'));

export default function ProjectDetail(): JSX.Element | null {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const location = useLocation<ProjectDetailLocationState>();
  const navigate = useNavigate();
  const matchWithDriveFolder = useMatch({
    path: projectRoutes.driveWithFolder.path,
    end: true,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const tabSearchParam = searchParams.get(ProjectDetailSearchParams.TAB);
  const removedDocumentName =
    searchParams.get(ProjectDetailSearchParams.REMOVED_DOCUMENT_NAME) ?? undefined;
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  const { data, loading, error } = useProjectDetail(projectId);
  const { data: partProjectsData } = useQuery(GetPartProjectsInformationQuery, {
    variables: { projectId },
    skip: !data?.project.showSubProjectHours,
  });
  const { resetLogUnreadCount } = useResetLogUnreadCount({ projectId });

  const [showSyncedAlert, setShowSyncedAlert] = useState<boolean>(false);
  const moveToInstance = useRef(new MoveTo());

  useEffect(() => {
    const mainContainer = document.getElementById('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (!removedDocumentName) {
      return;
    }

    setTimeoutTimer(
      () =>
        setSearchParams(
          (state) => {
            state.delete(ProjectDetailSearchParams.REMOVED_DOCUMENT_NAME);

            return state;
          },
          { replace: true },
        ),
      5000,
    );

    return () => clearAllTimers();
  }, [clearAllTimers, removedDocumentName, setSearchParams, setTimeoutTimer]);

  useEffect(() => {
    if (!location.state?.scrollToDrive || !matchWithDriveFolder) return;

    const mainContainer = document.getElementById('main');
    const driveContainerElement = document.getElementById('drive-container');

    if (mainContainer && driveContainerElement) {
      moveToInstance.current.move(driveContainerElement, { container: mainContainer });
    }

    navigate(matchWithDriveFolder.pathname, {
      state: { ...location.state, scrollToDrive: undefined },
      replace: true,
    });
  }, [location.state, matchWithDriveFolder, navigate]);

  if (!data && loading) return <ProjectDetailLoadingLayout />;
  if (error) return <ErrorRendererPage apolloError={error} />;
  if (!data) return null;

  const { project } = data;
  const { draft, id, syncable } = project;

  const activeFolderId = matchWithDriveFolder?.params.folderId;

  function handleProjectSync() {
    setShowSyncedAlert(true);

    setTimeoutTimer(() => setShowSyncedAlert(false), 5000);
  }

  return (
    <Container stylex={{ paddingEnds: 'large' }}>
      <Suspense fallback={<LayoutLoading />}>
        <ProjectDetailHeader activeFolderId={activeFolderId} draft={draft} />

        <Stack gap="medium" stylex={{ marginTop: 'large-x' }}>
          {showSyncedAlert && (
            <Alert color="success">
              <Text>{t('projectDetail.sync.alert')}</Text>
            </Alert>
          )}

          {removedDocumentName && (
            <Alert color="success">
              <Text>
                {t('removeDocument.documentSuccessfullyDeletedMessage', {
                  documentName: removedDocumentName,
                })}
              </Text>
            </Alert>
          )}

          {!syncable && !draft && (
            <Alert color="warning" className="qa-alert-message">
              {t('projectDetail.notSyncable.warning')}
            </Alert>
          )}

          {!draft ? (
            <ProjectDetailInformation
              project={project}
              partProjects={partProjectsData?.project.partProjects || []}
              partProjectSIV={partProjectsData?.project.siv || undefined}
              initiallyOpenTab={
                tabSearchParam ? getEnumFromValue(tabSearchParam, ProjectDetailTab) : undefined
              }
              onProjectSync={handleProjectSync}
              onRequestUnreadLogCount={resetLogUnreadCount}
            />
          ) : (
            <ProjectDraftMessage />
          )}

          {!project.draft && <SIVCockpitContainer projectId={projectId} />}

          <ProjectDetailNavigation projectId={id} />

          <Box id="drive-container">
            <DriveWebContainer />
          </Box>
        </Stack>
      </Suspense>
    </Container>
  );
}
