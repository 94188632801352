import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_CATEGORIES_FRAGMENT = gql`
  fragment AvaiableCategoriesFragment on Category {
    id
    calculatedPartNo
    name
  }
`;
