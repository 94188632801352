import { Button, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

interface ErrorStateProps {
  retry: () => Promise<void>;
  close: () => void;
}

function SAPTransmitErrorState({ retry, close }: ErrorStateProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="title-medium" color="white">
          {t('transmit.error.title')}
        </Text>
        <IconButton color="white" icon={<Icon name="close" />} onClick={close} />
      </Flex>

      <Text color="yellow-900">{t('transmit.error.info')}</Text>

      <Flex gap="medium" stylex={{ marginEnds: 'small' }}>
        <Button endIcon={<Icon name="close" />} onClick={close} fullWidth>
          {t('transmit.error.cancelButton')}
        </Button>
        <Button endIcon={<Icon name="cached" />} onClick={retry} fullWidth>
          {t('transmit.error.refreshButton')}
        </Button>
      </Flex>
    </>
  );
}

export default function SAPTransmitErrorStateWithTranslation(props: ErrorStateProps): JSX.Element {
  return (
    <I18nextProvider i18n={i18n}>
      <SAPTransmitErrorState {...props} />
    </I18nextProvider>
  );
}
