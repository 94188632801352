import { Viewer } from '@hs-baumappe/web-auth';
import { SchemaOf } from 'yup';
import yup, { InferObjectToYupShape } from '../../../../yup';
import {
  Acceptance,
  AcceptanceReportFormValues,
  AttendeeFormValues,
  Defect,
  DefectImageFormValues,
} from './AcceptanceReportFormValues';
import { AcceptanceReportAttendeeRole } from '../../../../globalTypes';
import { CreateAcceptanceReportProjectDetail } from '../../../Project/CreateAcceptanceReport/graphql/__generated__/CreateAcceptanceReportProjectDetailQuery';
import { formatDateToDateString } from '../../../../utils/date';
import {
  createEmptyImageWithLabel,
  imageUploadWithLabelValidationSchema,
} from '../../../../containers/forms/ImageUploadWithLabel';

const NEXT_DAY = 24 * 60 * 60 * 1000;

const attendeesValidationSchema: SchemaOf<AttendeeFormValues[]> = yup
  .array<AttendeeFormValues>()
  .defined()
  .default([])
  .min(0)
  .required()
  .of(
    yup
      .object({
        fullName: yup.string().when('role', (role: AcceptanceReportAttendeeRole, schema) => {
          if (role === AcceptanceReportAttendeeRole.HEINRICH_SCHMID) {
            return schema.required();
          }

          if (role === AcceptanceReportAttendeeRole.CLIENT) {
            return schema.required();
          }

          return schema;
        }),
        role: yup
          .mixed<AcceptanceReportAttendeeRole>()
          .oneOf(Object.values(AcceptanceReportAttendeeRole))
          .required(),
        position: yup.string().max(255),
      })
      .required(),
  );

const defectMetaValidationSchema: SchemaOf<DefectImageFormValues[]> = yup
  .array<DefectImageFormValues>()
  .defined()
  .default([])
  .min(0)
  .required()
  .of(
    yup
      .object()
      .shape({
        label: yup.string().when('imageId', {
          is: (imageId: string) => !!imageId,
          then: yup.string().required(),
        }),
        imageId: yup.string(),
        orderId: yup.string(),
      })
      .defined(),
  );

export const acceptanceReportFormValidationSchema = yup
  .object()
  .shape<InferObjectToYupShape<AcceptanceReportFormValues>>({
    name: yup.string().required(),
    description: yup.string(),
    projectNumber: yup.string().required(),
    date: yup.date().typeError('validation.date.typeError').required(),
    client: yup.string().max(255).required(),
    contractor: yup.string().max(255).required(),
    toProfessions: yup.array().defined().default([]).of(yup.string().required()),
    acceptance: yup.mixed().oneOf(Object.values(Acceptance)).required(),
    acceptanceDescriptionText: yup.string().when(['acceptance', 'acceptanceDescriptionReference'], {
      is: (acceptance: Acceptance, acceptanceDescriptionReference: string) =>
        acceptance === Acceptance.NOT_ACCEPT && !acceptanceDescriptionReference,
      then: yup.string().required(),
    }),
    acceptanceDescriptionReference: yup.string(),
    attendees: attendeesValidationSchema,
    defect: yup.mixed().oneOf(Object.values(Defect)).required(),
    defectReferenceFile: yup
      .string()
      .max(255)
      .when(['defect', 'defectMeta'], {
        is: (defect: Defect, defectMeta: DefectImageFormValues[]) => {
          const image = defectMeta.filter((f) => f.imageId);
          const label = defectMeta.filter((f) => f.label);
          if (defect === Defect.HAS_DEFECT && image.length === 0 && label.length === 0) {
            return true;
          }
          return false;
        },
        then: yup.string().required(),
      }),
    defectConfirmation: yup.boolean().defined(),
    defectDueDate: yup
      .date()
      .typeError('validation.date.typeError')
      .when('defect', {
        is: (defect: Defect) => defect === Defect.HAS_DEFECT,
        then: yup.date().required(),
      }),
    defectMeta: defectMetaValidationSchema,
    defectNote: yup.string(),
    attachImagesToPdf: yup.boolean(),
    images: yup.array().of(imageUploadWithLabelValidationSchema.defined()).defined(),
  });

export function createAcceptanceReportAttendeesInitialValues(
  viewer: Viewer,
  contactPerson?: CreateAcceptanceReportProjectDetail['project']['contactPeople'][number],
): AttendeeFormValues[] {
  return [
    {
      fullName: `${viewer.firstName} ${viewer.lastName}`,
      role: AcceptanceReportAttendeeRole.HEINRICH_SCHMID,
    },
    {
      fullName: contactPerson?.name || '',
      role: AcceptanceReportAttendeeRole.CLIENT,
    },
    {
      fullName: '',
      role: AcceptanceReportAttendeeRole.OTHER,
    },
  ];
}

export function getAcceptanceReportFormInitialValues(
  initialValues?: Partial<AcceptanceReportFormValues>,
): AcceptanceReportFormValues {
  const date = initialValues?.date ? new Date(initialValues.date) : undefined;
  const tomorrow = date ? new Date(date.getTime() + NEXT_DAY) : undefined;
  const defectMeta =
    !initialValues?.defectMeta || initialValues.defectMeta.length === 0
      ? [createEmptyImageWithLabel()]
      : initialValues.defectMeta;

  return {
    name: '',
    projectNumber: '',
    date: '',
    client: '',
    contractor: '',
    toProfessions: [],
    acceptance: Acceptance.ACCEPT,
    attendees: [],
    defect: Defect.NOT_DEFECTIVE,
    defectConfirmation: true,
    defectDueDate: tomorrow ? formatDateToDateString(tomorrow) : '',
    attachImagesToPdf: true,
    defectReferenceFile: '',
    acceptanceDescriptionText: '',
    acceptanceDescriptionReference: '',
    images: [],
    ...initialValues,
    defectMeta,
  };
}
