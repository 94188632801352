import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import useDriveDocumentTagDetails from '../../../../../hooks/useDriveDocumentTagDetails';
import useSendAcceptanceReportExternalLink from '../../useSendAcceptanceReportExternalLink';
import ErrorState from '../../../../../containers/ErrorState';
import EmailForm from '../../../../../containers/Email';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportProject } from '../../../graphql/__generated__/AcceptanceReportProject.fragment';
import { getEmailFormInitialValues } from '../../AcceptanceReportSendExternalLinkEmail.utils';
import { formatBytes } from '../../../../../utils/number';

interface AcceptanceReportSendExternalLinkEmailBodyProps {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  onFormDirty: () => void;
  onDocumentIdsValueChange: () => void;
}

export default function AcceptanceReportSendExternalLinkEmailBody({
  acceptanceReport,
  project,
  onFormDirty,
  onDocumentIdsValueChange,
}: AcceptanceReportSendExternalLinkEmailBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { driveDocument } = useDriveDocumentTagDetails(acceptanceReport.id);
  const { sendExternalLink, loading, error } = useSendAcceptanceReportExternalLink(
    acceptanceReport.id,
  );

  if (!viewer) {
    return null;
  }

  const documents = [
    {
      id: acceptanceReport.id,
      name: acceptanceReport.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <>
      {error && <ErrorState error={error} />}

      <EmailForm
        projectId={project.id}
        initialValues={getEmailFormInitialValues(acceptanceReport, project, viewer, t)}
        documents={documents}
        submitButtonText={t('acceptanceReport.submitButton')}
        loading={loading}
        onSubmit={sendExternalLink}
        onDirtyStateChange={onFormDirty}
        onDocumentIdsValueChange={onDocumentIdsValueChange}
      />
    </>
  );
}
