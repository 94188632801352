import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import { ApolloError, useQuery } from '@apollo/client';
import AppModuleProjectDetailQuery from './graphql/AppModuleProjectDetail.query';
import APP_MODULE_REGISTERS, { AppModule } from '../../app-modules';

interface UseAppModulesResponse {
  appModules: AppModule[];
  loading: boolean;
  error?: ApolloError;
}

export default function useAppModules(projectId: string): UseAppModulesResponse {
  const { viewer } = useViewer();
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(AppModuleProjectDetailQuery, {
    variables: {
      projectId,
    },
  });

  const project = data?.project;
  const scopes = viewer?.scopes;

  const appModules =
    project && scopes
      ? APP_MODULE_REGISTERS.filter((appModule) =>
          appModule.shouldShown(project, scopes),
        ).map<AppModule>((appModule) => ({
          id: appModule.id,
          title: t(appModule.titleTranslationKey),
          cornerBadgeText: appModule.cornerBadgeTranslationKey
            ? t(appModule.cornerBadgeTranslationKey)
            : undefined,
          cornerBadgeColor: appModule.cornerBadgeColor,
          imageSrc: appModule.imageSrc,
          className: `qa-${appModule.id}`,
          to: appModule.generatePath(project),
          disabled: appModule.shouldDisabled?.(project, scopes),
        }))
      : [];

  return {
    appModules,
    loading,
    error,
  };
}
