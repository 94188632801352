import { QueryResult, useQuery } from '@apollo/client';
import {
  CreateAcceptanceReportProjectDetail,
  CreateAcceptanceReportProjectDetailVariables,
} from './graphql/__generated__/CreateAcceptanceReportProjectDetailQuery';
import CreateAcceptanceReportProjectDetailQuery from './graphql/CreateAcceptanceReportProjectDetailQuery';

export default function useGetProjectDetail(
  id: string,
): QueryResult<CreateAcceptanceReportProjectDetail, CreateAcceptanceReportProjectDetailVariables> {
  return useQuery<
    CreateAcceptanceReportProjectDetail,
    CreateAcceptanceReportProjectDetailVariables
  >(CreateAcceptanceReportProjectDetailQuery, {
    variables: {
      id,
    },
  });
}
