import { ConcernFormValues } from '../../Concern/forms/ConcernForm/ConcernForm.types';
import { formatISOStringToDateString } from '../../../utils/date';
import { ConcernCreateDetail } from './graphql/__generated__/ConcernCreateDetail.query';

export function mapProjectToConcernFormValues(
  project: ConcernCreateDetail['project'],
): Partial<ConcernFormValues> {
  return {
    date: project.date ? formatISOStringToDateString(project.date) : '',
    projectNumber: project.hsProjectNumber || undefined,
    clientName: project.client?.name || '',
  };
}
