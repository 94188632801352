import { useField } from 'formik';
import { AutocompleteOption, Box } from '@hs-baumappe/legacy-ui';
import {
  FormikAutocompleteField,
  FormikFormField,
  NameAutocompleteField,
} from '@hs-baumappe/forms';
import { RiskAssessmentEmployees } from '../../graphql/__generated__/RiskAssessmentEmployees.query';
import { getEmployeePhoneNumbers } from './RiskAssessmentFormEmployee.utils';
import { InCharge } from '../../values';

interface RiskAssessmentFormEmployeeInputProps {
  label: string;
  placeholder: string;
}

interface RiskAssessmentFormEmployeeProps {
  name: string;
  employees?: RiskAssessmentEmployees['employeesForProject'];
  fullNameInputProps: RiskAssessmentFormEmployeeInputProps;
  phoneInputProps: RiskAssessmentFormEmployeeInputProps;
}

export default function RiskAssessmentFormEmployee({
  name,
  employees,
  fullNameInputProps,
  phoneInputProps,
}: RiskAssessmentFormEmployeeProps): JSX.Element {
  const fullNameFieldName = `${name}.fullName`;
  const phoneFieldName = `${name}.phone`;

  const [, fullNameFieldMeta] = useField<InCharge['fullName']>(fullNameFieldName);
  const [, , phoneFieldHelpers] = useField<InCharge['phone']>(phoneFieldName);
  const phoneNumbers = getEmployeePhoneNumbers(employees, fullNameFieldMeta.value);

  function handleNameAutocompleteChange(employeeName?: string | string[]) {
    if (!employeeName || !employees) {
      return;
    }

    const employee = employees.find((e) => e.name === employeeName);
    if (!employee) {
      return;
    }

    phoneFieldHelpers.setValue(employee.mobile || employee.phone || '', true);
  }

  return (
    <>
      <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
        {employees ? (
          <NameAutocompleteField
            name={fullNameFieldName}
            {...fullNameInputProps}
            names={employees.map((employee) => employee.name)}
            onChange={handleNameAutocompleteChange}
          />
        ) : (
          <FormikFormField name={fullNameFieldName} {...fullNameInputProps} autoComplete="off" />
        )}
      </Box>

      <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
        {phoneNumbers && phoneNumbers.length > 0 ? (
          <FormikAutocompleteField name={phoneFieldName} {...phoneInputProps} allowArbitraryValues>
            {phoneNumbers.map((phoneNumber) => (
              <AutocompleteOption key={phoneNumber} value={phoneNumber}>
                {phoneNumber}
              </AutocompleteOption>
            ))}
          </FormikAutocompleteField>
        ) : (
          <FormikFormField name={phoneFieldName} {...phoneInputProps} />
        )}
      </Box>
    </>
  );
}
