import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportProject } from '../graphql/__generated__/AcceptanceReportProject.fragment';
import config from '../../../config';
import { EmailFormValues } from '../../../containers/Email';

export function getEmailFormInitialValues(
  acceptanceReport: AcceptanceReport,
  project: AcceptanceReportProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  return {
    subject: t('acceptanceReportSendExternalLinkEmail.initialValues.subject', {
      projectName: project.name,
      additionalName: project.additionalName,
      documentName: acceptanceReport.name,
    }),
    content: viewer.signatureEmail
      ? t('acceptanceReportSendExternalLinkEmail.content', {
          acceptanceReportId: acceptanceReport.id,
          externalHost: config.HOST_URL,
          ...viewer,
          email: viewer.signatureEmail,
        })
      : t('acceptanceReportSendExternalLinkEmail.content_with_no_email', {
          acceptanceReportId: acceptanceReport.id,
          externalHost: config.HOST_URL,
          ...viewer,
        }),
    documentIds: [acceptanceReport.id],
  };
}
