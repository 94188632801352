import { gql, TypedDocumentNode } from '@apollo/client';
import {
  GetPartProjectsInformation,
  GetPartProjectsInformationVariables,
} from './__generated__/GetPartProjectsInformation.query';

export const GetPartProjectsInformationQuery: TypedDocumentNode<
  GetPartProjectsInformation,
  GetPartProjectsInformationVariables
> = gql`
  query GetPartProjectsInformation($projectId: ID!) {
    project(id: $projectId) {
      id
      name

      siv {
        id

        recording {
          totalHourOfEmployees
          totalHoursToBeDistributed
        }

        cockpit {
          totalCost {
            recorded
          }

          totalPerformanceCost {
            recorded
          }
        }
      }

      partProjects {
        id
        projectNumber

        siv {
          id

          recording {
            totalHourOfEmployees
            totalHoursToBeDistributed
          }

          cockpit {
            totalCost {
              recorded
            }

            totalPerformanceCost {
              recorded
            }
          }
        }
      }
    }
  }
`;
