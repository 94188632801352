import { useState } from 'react';
import { PDFViewer as BasePDFViewer } from '@hs-baumappe/pspdfkit';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Link, Text } from '@hs-baumappe/legacy-ui';
import config from '../../config';

interface PDFViewerProps {
  document: string;
  downloadPermitted: boolean;
}

export default function PDFViewer({ document, downloadPermitted }: PDFViewerProps): JSX.Element {
  const [error, setError] = useState<Error | undefined>(undefined);
  const { i18n } = useTranslation();

  if (error) {
    return (
      <Alert color="error">
        <Text>
          <Trans
            i18nKey="pdfViewer.errorMessage"
            components={[
              <Link
                component="button"
                color="inherit"
                underline="always"
                onClick={() => window.location.reload()}
                key="pdf-viewer-reload-button"
              />,
            ]}
          />
        </Text>
      </Alert>
    );
  }

  return (
    <BasePDFViewer
      document={document}
      baseUrl={config.PSPDFKIT_ASSET_URL}
      licenseKey={config.PSPDFKIT_LICENCE_KEY}
      locale={i18n.language.toLowerCase()}
      styleSheets={config.PSPDFKIT_STYLESHEETS}
      disableExport={!downloadPermitted}
      disablePrinting={!downloadPermitted}
      onError={setError}
      data-testid="pdf-viewer"
    />
  );
}
