import useSapTransmit from './useSapTransmit';
import SapTransmitLogin from './SAPTransmitLogin';
import { Dispatch, SetStateAction } from 'react';
import { Button, Flex, FlexGrid, FlexGridColumn, Tag, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import { SAPTransmissionProjects } from './graphql/__generated__/SAPTransmissionProjects.query';
import SAPTransmitModalLoading from './SAPTransmitModalLoading';
import { SivKeyDataSapTransmit } from './graphql/__generated__/SivKeyDataSapTransmit.mutation';
import { SAPTransmissionProject } from './graphql/__generated__/SAPTransmissionProject.fragment';
import { SAPTransmissionSelectedProfitCenter } from './graphql/__generated__/SAPTransmissionSelectedProfitCenter.fragment';
import { ProjectStatus } from '../../../globalTypes';
import SAPTransmitTable from './SAPTransmitTable';
import { SapTransmitLoginValues } from './SAPTransmitLogin/SapTransmitLogin';

interface SAPTransmitModalBodyProps {
  hasNextPage: boolean;
  fetching: boolean;
  selectedProjectIds: string[];
  setSelectedProjectIds: Dispatch<SetStateAction<string[]>>;
  sapTransmissionProjects: SAPTransmissionProject[];
  selectedProfitCenters: SAPTransmissionSelectedProfitCenter[];
  selectedProjectStatuses: ProjectStatus[];
  onLoadMore: () => Promise<void>;
}

export default function SAPTransmitModalBody({
  hasNextPage,
  fetching,
  sapTransmissionProjects,
  selectedProfitCenters,
  selectedProjectStatuses,
  selectedProjectIds,
  setSelectedProjectIds,
  onLoadMore,
}: SAPTransmitModalBodyProps): JSX.Element {
  const { t } = useTranslation();
  const { sapCredentials, setSapCredentials } = useSapCredentials();

  function handleSapTransmitSuccess(
    completedTransmits: SivKeyDataSapTransmit['sivKeyDataSapTransmit']['completedTransmissions'],
    formValues: SapTransmitLoginValues,
  ) {
    const projectIds = completedTransmits.map((transmit) => transmit.id);

    setSelectedProjectIds((prev) => prev.filter((id) => !projectIds.includes(id)));
    setSapCredentials({ username: formValues.name, password: formValues.password });
  }

  function handleSapTransmitError(
    failedTransmits: SivKeyDataSapTransmit['sivKeyDataSapTransmit']['failedTransmissions'],
  ) {
    setSelectedProjectIds(failedTransmits.map((transmit) => transmit.id));
  }

  const { sapTransmitKeyData, loading: mutationLoading } = useSapTransmit({
    onError: handleSapTransmitError,
    onSuccess: handleSapTransmitSuccess,
  });

  function handleCheckboxClick(
    project: SAPTransmissionProjects['sapTransmissionProjects']['projects'][number],
  ) {
    if (selectedProjectIds.includes(project.id)) {
      setSelectedProjectIds(selectedProjectIds.filter((f) => f !== project.id));
      return;
    }
    setSelectedProjectIds([...selectedProjectIds, project.id]);
  }

  const showFilters = selectedProjectStatuses.length > 0 || selectedProfitCenters.length > 0;

  return (
    <div className="row">
      <div className="col col--md-8">
        <SAPTransmitTable
          projects={sapTransmissionProjects}
          selectedProjectIds={selectedProjectIds}
          onProjectCheckboxChange={handleCheckboxClick}
        />

        <FlexGrid justifyContent="center" stylex={{ marginEnds: 'medium' }}>
          <FlexGridColumn xs md={6}>
            <Button fullWidth onClick={onLoadMore} loading={fetching} disabled={!hasNextPage}>
              {t('transmit.loadMoreButton')}
            </Button>
          </FlexGridColumn>
        </FlexGrid>
      </div>
      <div className="col col--md-4">
        <SapTransmitLogin
          initialValues={
            sapCredentials
              ? {
                  name: sapCredentials.username,
                  password: sapCredentials.password,
                }
              : undefined
          }
          onSubmit={async (values, formikHelpers) =>
            sapTransmitKeyData(values, selectedProjectIds, formikHelpers)
          }
          selectedProjectIds={selectedProjectIds}
        />

        {mutationLoading && <SAPTransmitModalLoading selectedProjectIds={selectedProjectIds} />}

        {showFilters && (
          <Flex wrap="wrap" gap="small-4x" stylex={{ marginTop: 'medium' }}>
            {selectedProjectStatuses.map((projectStatus) => (
              <Tag key={projectStatus}>
                <Text color="primary">{t(`projectStatus.${projectStatus}`)}</Text>
              </Tag>
            ))}

            {selectedProfitCenters.map((profitCenter) => (
              <Tag key={profitCenter.id}>
                <Text color="primary">{profitCenter.name}</Text>
              </Tag>
            ))}
          </Flex>
        )}
      </div>
    </div>
  );
}
