import { FC, ChangeEvent } from 'react';

import Input, { InputProps } from '../Input';

export interface Props extends InputProps {
  value: number | string;
}

const NumberInput: FC<Props> = ({ value, onChange, children, ...props }) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value.replace(/,/g, '.');

    onChange?.(e);
  };

  return (
    <Input
      value={value && String(value).replace(/\./g, ',')}
      onChange={onChange && onChangeHandler}
      {...props}
    >
      {children}
    </Input>
  );
};

export default NumberInput;
