import { gql } from '@apollo/client';

const ConstructionNoteUpdateMutation = gql`
  mutation ConstructionNoteUpdate($input: ConstructionNoteUpdateInput!) {
    constructionNoteUpdate(input: $input) {
      id
      name
      path
    }
  }
`;

export default ConstructionNoteUpdateMutation;
