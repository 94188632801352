import { FormikErrors, FormikHelpers } from 'formik';
import { gql, isApolloError, useMutation } from '@apollo/client';
import { SignQsst, SignQsstVariables } from './__generated__/useGetHandleSubmit';
import { useCallback } from 'react';
import b64toBlob from '../../../../../../utils/b64toBlob';
import { QsstSignInput } from '../../../../../../globalTypes';
import { getErrorsFromServerError } from '../../../../../../apollo/errors';
import { QsstSignFormValues } from '../qsstSignFormValues';
import QsstFragment from '../../../../graphql/Qsst.fragment';

const signQsstMutation = gql`
  mutation SignQsst($input: QsstSignInput!) {
    qsstSign(input: $input) {
      ...Qsst
    }
  }
  ${QsstFragment}
`;

interface UseGetHandleSubmitProps {
  documentId: string;
  externalAuthToken?: string;
  onSuccess?: () => void;
}

type HandleSubmitFn = (
  values: QsstSignFormValues,
  formikHelpers: FormikHelpers<QsstSignFormValues>,
) => void;

type UseGetHandleSubmitReturn = {
  handleSubmit: HandleSubmitFn;
  loading: boolean;
};

export const useGetHandleSubmit = ({
  documentId,
  externalAuthToken,
  onSuccess,
}: UseGetHandleSubmitProps): UseGetHandleSubmitReturn => {
  const [signQsst, { loading }] = useMutation<SignQsst, SignQsstVariables>(signQsstMutation);

  const handleSubmit = useCallback(
    async (values: QsstSignFormValues, formikHelpers: FormikHelpers<QsstSignFormValues>) => {
      const { specificScore, recommendationScore, clientComments, clientSignature } = values;

      if (
        recommendationScore === undefined ||
        !clientSignature?.name ||
        !clientSignature?.signature
      ) {
        throw new Error();
      }

      const clientSignatureUpload = b64toBlob(
        clientSignature.signature.replace(/^[^,]+,/, ''),
        'image/png',
      );

      try {
        await signQsst({
          variables: {
            input: {
              id: documentId,
              specificScore,
              recommendationScore,
              clientComments: clientComments || '',
              clientSignatureFullName: clientSignature.name,
              clientSignatureUpload,
              externalAuthToken,
            },
          },
        });

        onSuccess?.();
      } catch (e) {
        if (!(e instanceof Error) || !isApolloError(e)) {
          return;
        }

        const formErrors = getErrorsFromServerError<
          FormikErrors<QsstSignFormValues>,
          QsstSignInput
        >(e, (errors) => {
          return {
            ...errors,
            specificScore: errors.specificScore ? {} : undefined,
          };
        });

        if (!formErrors) {
          return;
        }

        formikHelpers.setErrors(formErrors);
      }
    },
    [documentId, externalAuthToken, signQsst, onSuccess],
  );

  return {
    handleSubmit,
    loading,
  };
};
