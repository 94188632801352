import { useMutation } from '@apollo/client';
import { EmailFormValues } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';
import { EXPORT_OFFER_GAEB_MUTATION } from './graphql';
import { ExportOffer, ExportOfferVariables } from './__generated__/graphql';
import { GET_OFFER_DATA } from '../../../Detail/graphql';

interface GaebProps {
  offerId: string;
  onSuccess: (m: string) => void;
}

interface UseGaebParams {
  exportOffer: (values: EmailFormValues) => Promise<void>;
  loading: boolean;
}

const useGaeb = ({ offerId, onSuccess }: GaebProps): UseGaebParams => {
  const { t } = useTranslation();
  const [mutation, { loading }] = useMutation<ExportOffer, ExportOfferVariables>(
    EXPORT_OFFER_GAEB_MUTATION,
  );

  const exportOffer = async (values: EmailFormValues) => {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        input: {
          id: offerId,
          recipients: values.toEmails,
          subject: t('exportOffer.tab.gaeb.form.subject', {
            offerId,
          }),
          content: values.content,
          cc: values.ccEmails,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    onSuccess(t('exportOffer.tab.gaeb.success'));
  };

  return {
    exportOffer,
    loading,
  };
};

export default useGaeb;
