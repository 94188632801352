import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import RouteDialog from '../../../containers/RouteDialog';
import ConcernEditBody from './components/ConcernEditBody';
import { concernRoutes } from '../concernRoutes';
import { ConcernLocationState } from '../Concern.route.types';
import { Concern } from '../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../graphql/__generated__/ConcernProject.fragment';
import { ConcernScopes } from '../Concern.types';
import { navigateToLocation } from '../../routes.utils';

interface ConcernEditProps {
  concern: Concern;
  project: ConcernProject;
  scopes: ConcernScopes;
}

export default function ConcernEdit({ concern, project, scopes }: ConcernEditProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<ConcernLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: concernRoutes.detail.generatePath({ concernId: concern.id }),
    });
  }, [concern.id, location.state, navigate]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setShowConfirmationDialog(true);
      return;
    }

    setDialogOpen(false);
  }

  const { isPermitted, missingScopeNames } = scopes.updateConcernDocument;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="h3" color="muted" stylex={{ marginEnds: '0' }}>
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('concern.edit.route.subtitle')}
          </Text>
        </>
      }
    >
      {!isPermitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <ConcernEditBody
          concernId={concern.id}
          project={project}
          formDirty={formDirty}
          onFormDirtyStateChange={setFormDirty}
          onDismiss={() => setDialogOpen(false)}
        />
      )}

      <AlertDialog
        open={showConfirmationDialog}
        title={t('concern.edit.form.cancelModal.title')}
        description={t('concern.edit.form.cancelModal.info')}
        confirmButtonEndIcon={<Icon name="delete" />}
        confirmButtonText={t('concern.edit.form.cancelModal.cancelButton')}
        cancelButtonText={t('concern.edit.form.cancelModal.dismissButton')}
        onConfirm={() => {
          setShowConfirmationDialog(false);
          setDialogOpen(false);
        }}
        onCancel={() => setShowConfirmationDialog(false)}
        onRequestClose={() => setShowConfirmationDialog(false)}
        destructive
      />
    </RouteDialog>
  );
}
