import { Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface ObstructionEditModalTitleProps {
  projectDescription: string;
}

export default function ObstructionEditModalTitle({
  projectDescription,
}: ObstructionEditModalTitleProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body-medium" component="div" color="muted">
        {projectDescription}
      </Text>
      <Text variant="title-small" component="h2" className="u-margin-bottom-0">
        {t('obstructionEdit.title')}
      </Text>
    </>
  );
}
