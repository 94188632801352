import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import useConcern from './hooks/useConcern';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';
import ConcernDetail from './ConcernDetail';
import ConcernArchive from './ConcernArchive';
import ConcernEdit from './ConcernEdit';
import ConcernSendEmail from './ConcernSendEmail';
import ConcernSign from './ConcernSign';
import { ConcernLocationState, ConcernRouteParams } from './Concern.route.types';

export default function Concern(): JSX.Element {
  const { concernId } = useParams<ConcernRouteParams>();
  const location = useLocation<ConcernLocationState>();
  const backgroundLocation = location.state?.backgroundLocation;

  const { data, loading, error } = useConcern(concernId);

  useUpdateDriveDocumentLastUsed({
    id: concernId,
    skip: !data?.concern,
  });

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<ConcernDetail {...data} />} />
      </Routes>

      <Routes>
        <Route path="archive" element={<ConcernArchive {...data} />} />
        <Route path="edit" element={<ConcernEdit {...data} />} />
        <Route path="send-email" element={<ConcernSendEmail {...data} />} />
        <Route path="sign" element={<ConcernSign {...data} />} />
      </Routes>
    </>
  );
}
