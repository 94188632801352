import { TypedDocumentNode, gql } from '@apollo/client';
import LogFragment from '../../../../containers/LogContainer/graphql/Log.fragment';
import { ObstructionLogs, ObstructionLogsVariables } from './__generated__/ObstructionLogsQuery';

const ObstructionLogsQuery: TypedDocumentNode<ObstructionLogs, ObstructionLogsVariables> = gql`
  query ObstructionLogs($obstructionId: ID!, $page: Int!, $limit: Int!) {
    obstruction(id: $obstructionId) {
      id

      logs(limit: $limit, page: $page) {
        ...LogFragment
      }
    }
  }

  ${LogFragment}
`;

export default ObstructionLogsQuery;
