import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PROJECT_STATUS = gql`
  mutation UpdateProjectStatus($input: UpdateProjectStatusInput!) {
    updateProjectStatus(input: $input) {
      id
      status
    }
  }
`;
