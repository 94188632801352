import { useHotkeys } from 'react-hotkeys-hook';
import useCanvasZoom from '../../hooks/useCanvasZoom';
import useCanvas from '../../hooks/useCanvas';
import useCanvasMover from '../../hooks/useCanvasMover';
import useCanvasWheelZoom from '../../hooks/useCanvasWheelZoom';
import ZoomInAction from './ZoomInAction';
import ZoomOutAction from './ZoomOutAction';
import NoteEditorZoomToolbar from '../../../../components/note-editor/NoteEditorZoomToolbar';

export default function ZoomActions(): JSX.Element {
  const { resetZoom } = useCanvasZoom();
  const { zoom } = useCanvas();
  const { canMove, endMoving, startMoving } = useCanvasMover();
  const zoomPercentage = (100 * zoom).toFixed(0);

  useCanvasWheelZoom();

  useHotkeys(
    'ctrl+0, command+0',
    () => {
      resetZoom();
    },
    [resetZoom],
  );

  useHotkeys(
    'space',
    () => {
      if (canMove) {
        endMoving();
      } else {
        startMoving();
      }
    },
    [canMove, startMoving, endMoving],
  );

  useHotkeys(
    'esc',
    () => {
      endMoving();
    },
    [endMoving],
  );

  return (
    <>
      <NoteEditorZoomToolbar
        zoomInAction={<ZoomInAction />}
        zoomOutAction={<ZoomOutAction />}
        zoomValue={`${zoomPercentage} %`}
      />
    </>
  );
}
