import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Textarea } from '@hs-baumappe/legacy-ui';

import styles from './comments.module.scss';

const maxTextlength = 500;

export interface CommentsFormValues {
  clientComments?: string;
}

const Comments: FC = () => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext<CommentsFormValues>();

  const [enteredTextLength, setEnteredTextLength] = useState(0);

  return (
    <div className="u-margin-top-small">
      <Text variant="title-medium">{t('qsstSign.form.clientComments.title')}</Text>
      <Textarea
        rows={7}
        placeholder={t('qsstSign.form.clientComments.placeholder')}
        maxLength={maxTextlength}
        onChange={(e) => {
          const text = e.target.value;

          setFieldValue('clientComments', text);
          setEnteredTextLength(text.length);
        }}
      />
      <div className={styles.remainingTextCount}>
        <Text variant="body-small">
          {t('qsstSign.form.clientComments.remainingChars', {
            count: maxTextlength - enteredTextLength,
          })}
        </Text>
      </div>
    </div>
  );
};

export { Comments };
