import { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import CreateNewOfferForm from './CreateNewOfferForm';
import { CreateNewOfferFormValues } from './CreateNewOfferForm/values';
import useGetCatalogues from './useGetCatalogues';
import useCreateNewOffer from './useCreateNewOffer';
import { OfferType } from '../../../globalTypes';
import { projectsRoutes } from '../../routes';
import ErrorState from '../../../containers/ErrorState';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import { UPLOAD_CATALOGUE_MUTATION } from './graphql';
import { UploadOfferCatalogue, UploadOfferCatalogueVariables } from './__generated__/graphql';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';

interface Props {
  documentId: string;
}

const CreateNewOffer: FC<Props> = ({ documentId }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const [routeModalOpen, setRouteModalOpen] = useState(true);
  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const { data: cataloguesData, loading, error } = useGetCatalogues(projectId);
  const { createOffer } = useCreateNewOffer(documentId);
  const [fileUpload, fileUploadMeta] = useMutation<
    UploadOfferCatalogue,
    UploadOfferCatalogueVariables
  >(UPLOAD_CATALOGUE_MUTATION);

  const viewerCanCreateOfferDocument = !!viewer?.scopes.createOfferDocument.isPermitted;
  const viewerCanAddOfferOperationFromTLK = !!viewer?.scopes.addOfferOperationFromTLK.isPermitted;

  const handleSubmit = async (values: CreateNewOfferFormValues) => {
    createOffer(projectId, values);
  };

  const handleDialogRequestClose = useCallback(() => {
    setRouteModalOpen(false);
  }, []);

  const handleDialogClose = useCallback(() => {
    if (!viewerCanCreateOfferDocument) {
      navigate(projectsRoutes.detail.generatePath({}));
      return;
    }

    navigate(-1);
  }, [navigate, viewerCanCreateOfferDocument]);

  async function handleFileUpload(file: File | null) {
    if (!file) {
      return;
    }

    await fileUpload({
      variables: {
        file,
      },
    });
  }

  return (
    <Dialog
      open={routeModalOpen}
      onClose={handleDialogClose}
      onRequestClose={handleDialogRequestClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && fileUploadMeta.error && <ErrorState error={error || fileUploadMeta.error} />}

      {!loading && (
        <div className="container u-width-100% u-padding-ends">
          <>
            <div className="u-display-flex u-align-items-center">
              <div className="u-flex-grow-1">
                {cataloguesData && (
                  <Text variant="body-medium" component="div" color="muted">
                    {cataloguesData.project.description}
                    {cataloguesData.project.additionalName &&
                      ' | ' + cataloguesData.project.additionalName}
                  </Text>
                )}
                <Text variant="title-small" component="h2" className="u-margin-bottom-0">
                  {t('createOfferModal.title')}
                </Text>
              </div>
              <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
            </div>
            {!viewerCanCreateOfferDocument && (
              <MissingScopeError
                missingScopes={viewer?.scopes.createOfferDocument.missingScopeNames ?? []}
              />
            )}
          </>

          {viewerCanCreateOfferDocument && (
            <div className="u-padding-top">
              {loading ? (
                <LayoutLoading />
              ) : (
                <CreateNewOfferForm
                  onSubmit={handleSubmit}
                  onFileUpload={handleFileUpload}
                  catalogueId={fileUploadMeta.data?.uploadCatalogue.id}
                  loading={fileUploadMeta.loading}
                  draft={cataloguesData ? cataloguesData.project.draft : false}
                  catalogues={cataloguesData ? cataloguesData.catalogues : undefined}
                  initialValues={{
                    type: OfferType.FREE_FORM,
                    catalogueId: fileUploadMeta.data?.uploadCatalogue.id,
                  }}
                  viewerCanAddOfferOperationFromTLK={viewerCanAddOfferOperationFromTLK}
                />
              )}
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default withGenerateDocumentId(CreateNewOffer, {
  mimeType: DriveSpecialDocumentMimeTypes.OFFER,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
