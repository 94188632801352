import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Flex, FlexGrid, FlexGridColumn, Icon, Search } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import AppliedFilters from './AppliedFilters';
import ProjectFilterModal from './ProjectFilterModal';
import { SearchedProject } from './__generated__/graphql';
import { ProjectStatus } from '../../../../globalTypes';
import ProjectFollowFilter from '../ProjectFollowFilter';
import { getFilterCountFromMeta, getFilterCountFromSelection } from './ProjectFilter.utils';
import { ProjectFilterSelection } from './ProjectFilter.types';

interface ProjectFilterProps {
  meta?: SearchedProject['projects']['meta'];
  projectStatuses: ProjectStatus[];
  profitCenters: string[];
  onlyFollowed: boolean;
  searchTerm?: string;
  onSearchTermChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFollowFilterChange: (followed: boolean) => void;
  onProjectStatusesChange: (projectStatuses: ProjectStatus[]) => void;
  onProfitCentersChange: (profitCenters: string[]) => void;
}

export default function ProjectFilter({
  meta,
  projectStatuses,
  profitCenters,
  onlyFollowed,
  searchTerm,
  onSearchTermChange,
  onFollowFilterChange,
  onProjectStatusesChange,
  onProfitCentersChange,
}: ProjectFilterProps): JSX.Element {
  const { t } = useTranslation();
  const [showProjectFilterModal, setShowProjectFilterModal] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);

  useEffect(() => {
    if (!meta) {
      return;
    }

    setFiltersCount(getFilterCountFromMeta(meta));
  }, [meta]);

  function handleProjectStatusRemove(projectStatus: ProjectStatus) {
    const nextStatuses = [...projectStatuses];

    nextStatuses.splice(nextStatuses.indexOf(projectStatus), 1);

    setFiltersCount((prev) => prev - 1);

    onProjectStatusesChange(nextStatuses);
  }

  function handleProfitCenterRemove(profitCenter: string) {
    const nextProfitCenterIds = [...profitCenters];

    nextProfitCenterIds.splice(nextProfitCenterIds.indexOf(profitCenter), 1);

    setFiltersCount((prev) => prev - 1);

    onProfitCentersChange(nextProfitCenterIds);
  }

  function handleAppliedFiltersChange(filterSelection: ProjectFilterSelection) {
    onProjectStatusesChange(filterSelection.statuses.map(({ status }) => status));
    onProfitCentersChange(filterSelection.profitCenters.map(({ profitCenter }) => profitCenter.id));

    setFiltersCount(getFilterCountFromSelection(filterSelection));
  }

  const filterButtonColor = filtersCount > 0 ? 'primary' : 'default';

  return (
    <>
      <Box stylex={{ marginTop: 'medium' }}>
        <Search
          id="projects-search"
          fieldProps={{
            value: searchTerm,
            onChange: onSearchTermChange,
            label: t('projectListPage.projectSearchInput.label'),
            placeholder: t('projectListPage.projectSearchInput.placeholder'),
          }}
        />
      </Box>

      <FlexGrid stylex={{ marginTop: 'small-3x' }}>
        <FlexGridColumn sm={6} lg={4}>
          <Button
            onClick={() => setShowProjectFilterModal(true)}
            endIcon={<Icon name="filter-list" />}
            color={filterButtonColor}
            fullWidth
          >
            {t('projectListPage.filterProjectsButton')}
            {filtersCount > 0 && ` (${filtersCount})`}
          </Button>
        </FlexGridColumn>
      </FlexGrid>

      {meta && (
        <Box stylex={{ marginTop: 'small-3x' }}>
          <AppliedFilters
            meta={meta}
            profitCenters={profitCenters}
            projectStatuses={projectStatuses}
            onProjectStatusRemove={handleProjectStatusRemove}
            onProfitCenterRemove={handleProfitCenterRemove}
          />
        </Box>
      )}

      <Flex stylex={{ marginBottom: 'small-x' }} alignItems="center" justifyContent="flex-end">
        <ProjectFollowFilter
          onlyFollowed={onlyFollowed}
          onFollowFilterChange={onFollowFilterChange}
        />
      </Flex>

      <ProjectFilterModal
        open={showProjectFilterModal}
        onRequestClose={() => setShowProjectFilterModal(false)}
        onDismissButtonClick={() => setShowProjectFilterModal(false)}
        onFilterSelectionChange={handleAppliedFiltersChange}
        filterSelection={{
          statuses: meta?.selectedFilters.status ?? [],
          profitCenters: meta?.selectedFilters.profitCenter ?? [],
        }}
      />
    </>
  );
}
