import { gql } from '@apollo/client';

const QsstFragment = gql`
  fragment Qsst on Qsst {
    id

    name
    status

    pdfInfo {
      versionedUrl
    }

    manuallySignedPdfInfo {
      versionedUrl
    }

    viewerCanUpdate
    viewerCanRemove
    viewerCanSign
    viewerCanUpload
    viewerCanRemoveUpload
    viewerCanArchive
    viewerCanAssign
  }
`;

export default QsstFragment;
