import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UpdateConstructionNoteCanvas,
  UpdateConstructionNoteCanvasVariables,
} from './__generated__/UpdateConstructionNoteCanvas.mutation';

const UpdateConstructionNoteCanvasMutation: TypedDocumentNode<
  UpdateConstructionNoteCanvas,
  UpdateConstructionNoteCanvasVariables
> = gql`
  mutation UpdateConstructionNoteCanvas(
    $file: Upload!
    $imagePresentation: Upload!
    $input: ConstructionNoteCanvasUpdateInput!
  ) {
    constructionNoteCanvasUpdate(
      file: $file
      imagePresentation: $imagePresentation
      input: $input
    ) {
      id
    }
  }
`;

export default UpdateConstructionNoteCanvasMutation;
