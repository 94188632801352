import routes from '../routes';

import { OfferRouteParams } from './Offer.route.types';
import { OfferFolderEditRouteParams } from './folder/OfferFolderEdit/route';
import { OfferFolderCloneRouteParams } from './folder/OfferFolderClone/route';
import { OfferFolderCreateRouteParams } from './folder/OfferFolderCreate/route';
import { OfferFolderOperationCreateRouteParams } from './operation/OfferFolderOperationCreate/route';
import { OfferFolderOperationCreateFromTlkRouteParams } from './operation/OfferFolderOperationCreateFromTlk/route';
import { OfferFolderOperationCloneRouteParams } from './operation/OfferFolderOperationClone/route';
import { OfferFolderOperationEditRouteParams } from './operation/OfferFolderOperationEdit/route';
import { OfferSummaryEditRouteParams } from './offer/OfferSummaryEdit/route';
import { ExportOfferRouteParams } from './offer/ExportOffer/route';
import { getDocumentRoutes, route } from '../routes.utils';

// eslint-disable-next-line import/prefer-default-export
export const offerRoutes = {
  ...getDocumentRoutes<OfferRouteParams>(routes.offer),
  folderCreate: route<OfferFolderCreateRouteParams>(`${routes.offer.path}/f/create`),
  folderEdit: route<OfferFolderEditRouteParams>(`${routes.offer.path}/f/:folderId/edit`),
  folderClone: route<OfferFolderCloneRouteParams>(`${routes.offer.path}/f/:folderId/clone`),
  operationCreate: route<OfferFolderOperationCreateRouteParams>(
    `${routes.offer.path}/f/:folderId/o/create`,
  ),
  operationCreateFromTlk: route<OfferFolderOperationCreateFromTlkRouteParams>(
    `${routes.offer.path}/f/:folderId/o/:operationId/create-tlk`,
  ),
  operationClone: route<OfferFolderOperationCloneRouteParams>(
    `${routes.offer.path}/f/:folderId/o/:operationId/clone`,
  ),
  operationEdit: route<OfferFolderOperationEditRouteParams>(
    `${routes.offer.path}/f/:folderId/o/:operationId/edit`,
  ),
  editOfferSummary: route<OfferSummaryEditRouteParams>(`${routes.offer.path}/s/edit`),
  exportOffer: route<ExportOfferRouteParams>(`${routes.offer.path}/export`),
  pdf: route<OfferRouteParams>(`${routes.offer.path}/pdf`),
};
