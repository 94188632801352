import { gql } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const REMOVE_FOLDER__OPERATION_MUTATION = gql`
  mutation RemoveFolderOperation($input: RemoveFolderOperationInput!) {
    removeFolderOperation(input: $input) {
      status
    }
  }
`;
