import { folderBuilder } from './folderBuilder';

import { OfferFolderFragment, OfferData } from './__generated__/graphql';
import { Folder } from './types';

export const findFolderById = (folders: Folder[], id: Folder['id']): Folder | undefined => {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < folders.length; index++) {
    if (folders[index].id === id) {
      return folders[index];
    }

    if (folders[index].subFolders.length) {
      const subfolder = findFolderById(folders[index].subFolders, id);

      if (subfolder) {
        return subfolder;
      }
    }
  }

  return undefined;
};

export const folderIdToFolder = (
  foldersData: OfferFolderFragment[],
  id: string,
): Folder | undefined => {
  const folders = folderBuilder(foldersData);

  return findFolderById(folders, id);
};

export const isShowSummaryTable = (summary: OfferData['offer']['summary']): boolean => {
  return !!summary.totalPrice || !!summary.totalOptionalPrice;
};
