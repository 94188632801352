import { gql } from '@apollo/client';

export default gql`
  mutation UploadObstructionImageAttachment($file: Upload!) {
    uploadImage(file: $file) {
      id
      url
    }
  }
`;
