import { useCallback, useEffect, useMemo, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  areStylesAppliedToCharacter,
  areStylesAppliedToSelection,
  hasSelection,
  updateSelectionStyles,
} from '../../utils';

export enum FontWeight {
  BOLD = 'bold',
  NORMAL = 'normal',
}

export interface UseTextBoxFontWeight {
  isBold: boolean;
  setFontWeight: (fontWeight: FontWeight) => void;
}

interface UseTextBoxFontWeightParameters {
  textBox: fabric.Textbox | undefined;
  onRequestRenderCanvas: () => void;
}

const BOLD_SHORTCUT_KEY = 'b';

function useTextBoxFontWeight({
  textBox,
  onRequestRenderCanvas,
}: UseTextBoxFontWeightParameters): UseTextBoxFontWeight {
  const [blockBold, setBlockBold] = useState(false);
  const [selectionBold, setSelectionBold] = useState(false);
  const [typingBold, setTypingBold] = useState(false);
  const isBold = useMemo(
    () => typingBold || selectionBold || blockBold,
    [typingBold, selectionBold, blockBold],
  );

  useEffect(() => {
    setBlockBold(textBox?.fontWeight === 'bold');
    setSelectionBold(false);
    setTypingBold(false);
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxSelectionChanged = () => {
      if (hasSelection(textBox) && areStylesAppliedToSelection(textBox, 'fontWeight', 'bold')) {
        setSelectionBold(true);
      } else if (areStylesAppliedToCharacter(textBox, 'fontWeight', 'bold')) {
        setSelectionBold(true);
      } else {
        setSelectionBold(false);
      }
    };

    textBox.on('selection:changed', handleTextBoxSelectionChanged);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('selection:changed', handleTextBoxSelectionChanged);
    };
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxEditingExited = () => {
      setTypingBold(false);
    };

    textBox.on('editing:exited', handleTextBoxEditingExited);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('editing:exited', handleTextBoxEditingExited);
    };
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxChanged = () => {
      const { selectionEnd } = textBox;

      if (selectionEnd === undefined) {
        return;
      }

      textBox.setSelectionStyles(
        {
          fontWeight: typingBold ? 'bold' : undefined,
        },
        selectionEnd - 1,
        selectionEnd,
      );
    };

    textBox.on('changed', handleTextBoxChanged);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('changed', handleTextBoxChanged);
    };
  }, [textBox, typingBold]);

  const setSelectionFontWeight = useCallback(
    (fontWeight: FontWeight) => {
      if (!textBox) {
        return;
      }

      textBox.setSelectionStyles({
        fontWeight: fontWeight === FontWeight.NORMAL ? undefined : 'bold',
      });

      setSelectionBold(fontWeight === FontWeight.BOLD);
    },
    [textBox],
  );

  const setBlockFontWeight = useCallback(
    (fontWeight: FontWeight) => {
      if (!textBox) {
        return;
      }

      /**
       * The fabric will render your block-level styles at first,
       * but if you remove the block-level styles,
       * you will see your selection styles are still defined.
       * In this line, we're going to remove selection styles
       * because they make unexpected behavior after the block-level styles removed.
       * We're setting fontWeight to undefined (on selection) (not NORMAL) to remove selection-level styles.

       * Follow these steps to produce the bug
       * - Remove the next line
       * - Create selection level styles.
       * - Create a block-level style.
       * - Undo block-level style.

       * Result: Your selection level style still observable
       * Expected Result: No styles should be applied.
       */
      updateSelectionStyles(textBox, 'fontWeight', undefined);

      textBox.set({
        fontWeight,
      });

      setBlockBold(fontWeight === FontWeight.BOLD);
    },
    [textBox],
  );

  const setTypingFontWeight = useCallback(
    (fontWeight: FontWeight) => {
      if (!textBox) {
        return;
      }

      if (fontWeight === FontWeight.NORMAL) {
        const { selectionEnd } = textBox;

        textBox.setSelectionStyles(
          {
            fontWeight: undefined,
          },
          selectionEnd,
        );
      }

      setTypingBold(fontWeight === FontWeight.BOLD);
    },
    [textBox],
  );

  const setFontWeight = useCallback(
    (fontWeight: FontWeight) => {
      if (!textBox) {
        return;
      }

      if (textBox.isEditing && hasSelection(textBox)) {
        setSelectionFontWeight(fontWeight);
      } else {
        setTypingFontWeight(fontWeight);
      }

      if (!textBox.isEditing) {
        setBlockFontWeight(fontWeight);
      }

      onRequestRenderCanvas();
    },
    [
      setBlockFontWeight,
      setSelectionFontWeight,
      setTypingFontWeight,
      textBox,
      onRequestRenderCanvas,
    ],
  );

  const handleFontWeightKeyboardShortcut = useCallback(() => {
    if (isBold) {
      setFontWeight(FontWeight.NORMAL);
    } else {
      setFontWeight(FontWeight.BOLD);
    }
  }, [isBold, setFontWeight]);

  useHotkeys(
    `ctrl+${BOLD_SHORTCUT_KEY}, command+${BOLD_SHORTCUT_KEY}`,
    (event) => {
      event.preventDefault();

      handleFontWeightKeyboardShortcut();
    },
    [handleFontWeightKeyboardShortcut],
  );

  useEffect(() => {
    if (!textBox) {
      return;
    }

    /*
     * Fabric is not publishing the event details on change events to the handlers.
     * Therefore we're adding to keydown listener to document
     * But we only run our logic when the target node is textbox's hidden textarea
     * I opened a discuss to improve the `changed` event on fabricjs's Github
     * https://github.com/fabricjs/fabric.js/discussions/6890
     */
    const handleDocumentKeyDownEvent = (event: KeyboardEvent) => {
      const { target, ctrlKey, metaKey, key } = event;

      if (
        !target ||
        !(target instanceof Node) ||
        !textBox.hiddenTextarea ||
        !textBox.hiddenTextarea.isSameNode(target)
      ) {
        return;
      }

      if ((ctrlKey || metaKey) && key === BOLD_SHORTCUT_KEY) {
        handleFontWeightKeyboardShortcut();
      }
    };

    document.addEventListener('keydown', handleDocumentKeyDownEvent);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDownEvent);
    };
  }, [textBox, handleFontWeightKeyboardShortcut]);

  return {
    isBold,
    setFontWeight,
  };
}

export default useTextBoxFontWeight;
