import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import useDriveDocumentTagDetails from '../../../../hooks/useDriveDocumentTagDetails';
import useConcernSendEmail from './hooks/useConcernSendEmail/useConcernSendEmail';
import ErrorState from '../../../../containers/ErrorState';
import EmailForm from '../../../../containers/Email';
import { Concern } from '../../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../../graphql/__generated__/ConcernProject.fragment';
import { getConcernEmailFormInitialValues } from './ConcernSendEmailBody.utils';
import { formatBytes } from '../../../../utils/number';

export interface ConcernSendEmailBodyProps {
  concern: Concern;
  project: ConcernProject;
  onRequestClose: () => void;
  onDirtyStateChange: (dirty: boolean) => void;
}

export default function ConcernSendEmailBody({
  concern,
  project,
  onRequestClose,
  onDirtyStateChange,
}: ConcernSendEmailBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { driveDocument } = useDriveDocumentTagDetails(concern.id);
  const { send, loading: sending, error: sendError } = useConcernSendEmail(concern.id);

  if (!viewer) {
    return null;
  }

  const documents = [
    {
      id: concern.id,
      name: concern.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <>
      {sendError && <ErrorState error={sendError} />}

      <EmailForm
        projectId={project.id}
        initialValues={getConcernEmailFormInitialValues(concern, project, viewer, t)}
        documents={documents}
        submitButtonText={t('concern.sendPdfEmail.confirmButton')}
        onSubmit={send}
        loading={sending}
        onDocumentIdsValueChange={onRequestClose}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
