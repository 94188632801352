import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import useAcceptanceReportSendEmail from '../../useAcceptanceReportSendEmail';
import useDriveDocumentTagDetails from '../../../../../hooks/useDriveDocumentTagDetails';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';
import ErrorState from '../../../../../containers/ErrorState';
import EmailForm from '../../../../../containers/Email';
import { AcceptanceReportProject } from '../../../graphql/__generated__/AcceptanceReportProject.fragment';
import { getEmailFormInitialValues } from '../../AcceptanceReportSendEmail.utils';
import { formatBytes } from '../../../../../utils/number';

interface AcceptanceReportSendEmailBodyProps {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  onDocumentIdsValueChange: () => void;
  onDirtyStateChange: () => void;
}

export default function AcceptanceReportSendEmailBody({
  acceptanceReport,
  project,
  onDocumentIdsValueChange,
  onDirtyStateChange,
}: AcceptanceReportSendEmailBodyProps): JSX.Element | null {
  const { viewer } = useViewer();
  const { t } = useTranslation();

  const { driveDocument } = useDriveDocumentTagDetails(acceptanceReport.id);
  const { sendEmail, loading, error } = useAcceptanceReportSendEmail(acceptanceReport.id);

  if (!viewer) {
    return null;
  }

  const documents = [
    {
      id: acceptanceReport.id,
      name: acceptanceReport.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument?.size) : undefined,
    },
  ];

  return (
    <>
      {error && <ErrorState error={error} />}

      <EmailForm
        projectId={project.id}
        initialValues={getEmailFormInitialValues(acceptanceReport, project, viewer, t)}
        documents={documents}
        submitButtonText={t('acceptanceReport.submitButton')}
        loading={loading}
        onSubmit={sendEmail}
        onDocumentIdsValueChange={onDocumentIdsValueChange}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
