import { FormikHelpers, FormikProps } from 'formik/dist/types';
import useForm from '../../../../../../../../hooks/useForm';
import {
  projectFilterFormInitialValues,
  ProjectFilterFormValues,
} from '../../ProjectFilterForm.form';
import { useEffect, useState } from 'react';

interface UseProjectFilterFormProps {
  initialValues?: Partial<ProjectFilterFormValues>;
  onSubmit: (
    values: ProjectFilterFormValues,
    formikHelpers: FormikHelpers<ProjectFilterFormValues>,
  ) => void;
  onValuesChange?: (values: ProjectFilterFormValues) => void;
}

interface UseProjectFilterFormResponse {
  formik: FormikProps<ProjectFilterFormValues>;
  resetForm: () => void;
  formCanReset: boolean;
}

export default function useProjectFilterForm({
  initialValues,
  onSubmit,
  onValuesChange,
}: UseProjectFilterFormProps): UseProjectFilterFormResponse {
  const [formCanReset, setFormCanReset] = useState(false);
  const { formik } = useForm<ProjectFilterFormValues>({
    initialValues: projectFilterFormInitialValues(initialValues),
    onSubmit,
  });
  const { values, setValues } = formik;

  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(values);
    }
  }, [values, onValuesChange]);

  useEffect(() => {
    const someStatusesChecked = values?.statuses
      ? values.statuses.some((status) => status.checked)
      : false;

    const someProfitCentersChecked = values?.profitCenters
      ? values.profitCenters.some((status) => status.checked)
      : false;

    setFormCanReset(someStatusesChecked || someProfitCentersChecked);
  }, [values]);

  function resetForm() {
    const nextValues: ProjectFilterFormValues = {
      statuses: values.statuses.map((status) => ({ ...status, checked: false })),
      profitCenters: values.profitCenters.map((profitCenter) => ({
        ...profitCenter,
        checked: false,
      })),
    };

    setValues(nextValues);
  }

  return {
    formik,
    resetForm,
    formCanReset,
  };
}
