import { useTranslation } from 'react-i18next';
import { Badge, Checkbox, Flex, TableCell, TableRow, Text } from '@hs-baumappe/legacy-ui';
import { numberFixer } from '../../../../../../utils/number';
import { SAPTransmissionProject } from '../../../graphql/__generated__/SAPTransmissionProject.fragment';

interface SAPTransmitTableRowProps {
  project: SAPTransmissionProject;
  selected: boolean;
  onCheckboxChange: () => void;
}

export default function SAPTransmitTableRow({
  project,
  selected,
  onCheckboxChange,
}: SAPTransmitTableRowProps): JSX.Element {
  const { t } = useTranslation();

  const { totalHourForecast, planningTotalHour, recordingTotalCostByMeasurement } =
    project.sivKeyData;

  return (
    <TableRow>
      <TableCell>{project.projectNumber}</TableCell>
      <TableCell>{project.name}</TableCell>
      <TableCell>
        <Flex justifyContent="space-between">
          <Text variant="label-medium" color="gray-500">
            {t('transmit.planningHour')}
          </Text>
          <Text variant="label-medium" color="gray-500">
            {t('transmit.hour', {
              hours: String(numberFixer(planningTotalHour, 2)).replace('.', ','),
            })}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Text variant="label-medium" color="gray-500">
            {t('transmit.recordingHour')}
          </Text>
          <Text variant="label-medium" color="gray-500">
            {t('transmit.hour', {
              hours: String(numberFixer(totalHourForecast, 2)).replace('.', ','),
            })}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Text variant="label-medium" color="gray-500">
            {t('transmit.recordingTotalMeasurement')}
          </Text>
          <Text variant="label-medium" color="gray-500">
            {t('transmit.price', {
              price: String(numberFixer(recordingTotalCostByMeasurement, 2)).replace('.', ','),
            })}
          </Text>
        </Flex>
      </TableCell>
      <TableCell stylex={{ textAlign: 'center' }}>
        {project.isSynced ? (
          <Badge color="primary">{t('transmit.synchron')}</Badge>
        ) : (
          <Checkbox color="primary" onChange={onCheckboxChange} checked={selected} />
        )}
      </TableCell>
    </TableRow>
  );
}
