import { ImageUploadWithLabelFormValues } from '../../../../containers/forms/ImageUploadWithLabel';

export enum Misgivings {
  EXECUTION = 'EXECUTION',
  QUALITY = 'QUALITY',
  CONTRACTORS = 'CONTRACTORS',
}

export interface Quality {
  id: string;
  name: string;
  description: string;
}

export interface ConcernFormValues {
  projectNumber: string;
  clientName: string;
  date?: string;
  professions: string[];
  misgivings: Misgivings[];
  execution?: string;
  qualities?: Quality[];
  contractorsName?: string;
  contractorsDescription?: string;
  endDate: string;
  images: ImageUploadWithLabelFormValues[];
}
