import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import useRiskAssessment from './hooks/useRiskAssessment';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';
import DocumentDetailLoadingLayout from '../components/DocumentDetailLoadingLayout';
import RiskAssessmentDetail from './RiskAssessmentDetail';
import RiskAssessmentArchive from './RiskAssessmentArchive';
import RiskAssessmentEdit from './RiskAssessmentEdit';
import RiskAssessmentSendEmail from './RiskAssessmentSendEmail';
import RiskAssessmentSign from './RiskAssessmentSign';
import {
  RiskAssessmentLocationState,
  RiskAssessmentRouteParams,
} from './RiskAssessment.route.type';

export default function RiskAssessment(): JSX.Element {
  const { riskAssessmentId } = useParams<RiskAssessmentRouteParams>();
  const location = useLocation<RiskAssessmentLocationState>();
  const backgroundLocation = location.state?.backgroundLocation;

  const { data, loading, error } = useRiskAssessment(riskAssessmentId);

  useUpdateDriveDocumentLastUsed({
    id: riskAssessmentId,
    skip: !data?.riskAssessment,
  });

  if (loading) {
    return <DocumentDetailLoadingLayout />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<RiskAssessmentDetail {...data} />} />
      </Routes>

      <Routes>
        <Route path="archive" element={<RiskAssessmentArchive {...data} />} />
        <Route path="edit" element={<RiskAssessmentEdit {...data} />} />
        <Route path="send-email" element={<RiskAssessmentSendEmail {...data} />} />
        <Route path="sign" element={<RiskAssessmentSign {...data} />} />
      </Routes>
    </>
  );
}
