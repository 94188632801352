import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Icon } from '@hs-baumappe/legacy-ui';
import cx from 'classnames';

import styles from './PDFManipulatorLayout.module.scss';

interface PDFManipulatorLayoutProps {
  containerRef: RefObject<HTMLDivElement>;
  documentId: string;
  readOnly: boolean;
  fullScreen: boolean;
  hasUnsavedChanges: boolean;
  saving: boolean;
  onSaveButtonClick: () => void;
}

export default function PDFManipulatorLayout({
  containerRef,
  readOnly,
  fullScreen,
  hasUnsavedChanges,
  saving,
  onSaveButtonClick,
}: PDFManipulatorLayoutProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box className={cx(styles['pdf-manipulator'], { 'is-fullscreen': fullScreen })}>
      <Box className={styles['pdf-manipulator__pdf-viewer']} elementRef={containerRef} />

      <Box className={styles['pdf-manipulator__floating-actions']}>
        {fullScreen && !readOnly && (
          <Button
            color="primary"
            endIcon={<Icon name="save" />}
            onClick={onSaveButtonClick}
            loading={saving}
            disabled={!hasUnsavedChanges}
          >
            {t('documentManipulate.pdf.saveButtonText')}
          </Button>
        )}
      </Box>
    </Box>
  );
}
