import { PropsWithChildren, useRef } from 'react';

import NoteEditorLayout from '../../../components/note-editor/NoteEditorLayout';
import Canvas from '../Canvas';
import NoteEditorToolbar from '../../../components/note-editor/NoteEditorToolbar';
import UndoAction from '../actions/UndoAction';
import RedoAction from '../actions/RedoAction';
import RotateAction from '../actions/RotateAction';
import BackwardAction from '../actions/BackwardAction';
import ForwardAction from '../actions/ForwardAction';
import DeleteAction from '../actions/DeleteAction';
import ZoomActions from '../actions/ZoomActions';
import SaveTool from '../tools/SaveTool';
import TextTool from '../tools/TextTool';
import PenTool from '../tools/PenTool';
import EraseTool from '../tools/EraseTool';
import ImageTool from '../tools/ImageTool';

interface EditLayoutProps {
  data: string;
  projectId: string;
}

export default function EditLayout({
  data,
  projectId,
}: PropsWithChildren<EditLayoutProps>): JSX.Element {
  const canvasAreaRef = useRef<HTMLDivElement | null>(null);

  return (
    <NoteEditorLayout
      className="u-height-100%"
      canvasAreaRef={canvasAreaRef}
      canvasArea={<Canvas initialState={data} canvasAreaRef={canvasAreaRef} />}
      firstToolbarArea={
        <NoteEditorToolbar directionModifier="column@md-down">
          <UndoAction />
          <RedoAction />
          <RotateAction />
          <BackwardAction />
          <ForwardAction />
          <DeleteAction />
        </NoteEditorToolbar>
      }
      secondToolbarArea={<ZoomActions />}
      thirdToolbarArea={
        <NoteEditorToolbar type="tool" directionModifier="column">
          <SaveTool />
          <TextTool />
          <PenTool />
          <EraseTool />
          <ImageTool projectId={projectId} />
        </NoteEditorToolbar>
      }
    />
  );
}
