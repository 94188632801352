import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import CreateConstructionNoteMutation from './graphql/CreateConstructionNote.mutation';
import {
  CreateConstructionNote,
  CreateConstructionNoteVariables,
} from './graphql/__generated__/CreateConstructionNote.mutation';

interface UseCreateConstructionNoteResponse {
  createConstructionNote: (
    variables: CreateConstructionNoteVariables,
  ) => Promise<FetchResult<CreateConstructionNote> | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
}

export default function useCreateConstructionNote(): UseCreateConstructionNoteResponse {
  const [mutate, { loading, error }] = useMutation(CreateConstructionNoteMutation);

  const createConstructionNote = useCallback(
    async (variables: CreateConstructionNoteVariables) => {
      const response = await mutate({ variables });

      if (!response.data) {
        return;
      }

      return response;
    },
    [mutate],
  );

  return { createConstructionNote, loading, error };
}
