import { useCallback, useEffect, useMemo, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  areStylesAppliedToCharacter,
  areStylesAppliedToSelection,
  hasSelection,
  updateSelectionStyles,
} from '../../utils';

export enum FontStyle {
  ITALIC = 'italic',
  NORMAL = 'normal',
}

export interface UseTextBoxFontStyle {
  isItalic: boolean;
  setFontStyle: (fontWeight: FontStyle) => void;
}

interface UseTextBoxFontStyleParameters {
  textBox: fabric.Textbox | undefined;
  onRequestRenderCanvas: () => void;
}

const ITALIC_SHORTCUT_KEY = 'i';

function useTextBoxFontStyle({
  textBox,
  onRequestRenderCanvas,
}: UseTextBoxFontStyleParameters): UseTextBoxFontStyle {
  const [blockItalic, setBlockItalic] = useState(false);
  const [selectionItalic, setSelectionItalic] = useState(false);
  const [typingItalic, setTypingItalic] = useState(false);
  const isItalic = useMemo(
    () => typingItalic || selectionItalic || blockItalic,
    [typingItalic, selectionItalic, blockItalic],
  );

  useEffect(() => {
    setBlockItalic(textBox?.fontStyle === 'italic');
    setSelectionItalic(false);
    setTypingItalic(false);
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxSelectionChanged = () => {
      if (hasSelection(textBox) && areStylesAppliedToSelection(textBox, 'fontStyle', 'italic')) {
        setSelectionItalic(true);
      } else if (areStylesAppliedToCharacter(textBox, 'fontStyle', 'italic')) {
        setSelectionItalic(true);
      } else {
        setSelectionItalic(false);
      }
    };

    textBox.on('selection:changed', handleTextBoxSelectionChanged);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('selection:changed', handleTextBoxSelectionChanged);
    };
  }, [textBox, typingItalic]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxEditingExited = () => {
      setTypingItalic(false);
    };

    textBox.on('editing:exited', handleTextBoxEditingExited);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('editing:exited', handleTextBoxEditingExited);
    };
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxChanged = () => {
      const { selectionEnd } = textBox;

      if (selectionEnd === undefined) {
        return;
      }

      textBox.setSelectionStyles(
        {
          fontStyle: typingItalic ? 'italic' : undefined,
        },
        selectionEnd - 1,
        selectionEnd,
      );
    };

    textBox.on('changed', handleTextBoxChanged);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('changed', handleTextBoxChanged);
    };
  }, [textBox, typingItalic]);

  const setSelectionFontStyle = useCallback(
    (fontStyle: FontStyle) => {
      if (!textBox) {
        return;
      }

      textBox.setSelectionStyles({
        fontStyle: fontStyle === FontStyle.NORMAL ? undefined : 'italic',
      });

      setSelectionItalic(fontStyle === FontStyle.ITALIC);
    },
    [textBox],
  );

  const setBlockFontStyle = useCallback(
    (fontStyle: FontStyle) => {
      if (!textBox) {
        return;
      }

      // Please follow up useTextBoxFontWeight:125
      updateSelectionStyles(textBox, 'fontStyle', undefined);

      textBox.set({
        fontStyle,
      });

      setBlockItalic(fontStyle === FontStyle.ITALIC);
    },
    [textBox],
  );

  const setTypingFontStyle = useCallback(
    (fontStyle: FontStyle) => {
      if (!textBox) {
        return;
      }

      setTypingItalic(fontStyle === FontStyle.ITALIC);
    },
    [textBox],
  );

  const setFontStyle = useCallback(
    (fontStyle: FontStyle) => {
      if (!textBox) {
        return;
      }

      if (textBox.isEditing && hasSelection(textBox)) {
        setSelectionFontStyle(fontStyle);
      } else {
        setTypingFontStyle(fontStyle);
      }

      if (!textBox.isEditing) {
        setBlockFontStyle(fontStyle);
      }

      onRequestRenderCanvas();
    },
    [onRequestRenderCanvas, setBlockFontStyle, setSelectionFontStyle, setTypingFontStyle, textBox],
  );

  const handleFontStyleKeyboardShortcuts = useCallback(() => {
    if (isItalic) {
      setFontStyle(FontStyle.NORMAL);
    } else {
      setFontStyle(FontStyle.ITALIC);
    }
  }, [isItalic, setFontStyle]);

  useHotkeys(
    `ctrl+${ITALIC_SHORTCUT_KEY}, command+${ITALIC_SHORTCUT_KEY}`,
    (event) => {
      event.preventDefault();

      handleFontStyleKeyboardShortcuts();
    },
    [handleFontStyleKeyboardShortcuts],
  );

  useEffect(() => {
    if (!textBox) {
      return;
    }

    /*
     * See useTextBoxFontWeight:238
     */
    const handleDocumentKeyDownEvent = (event: KeyboardEvent) => {
      const { target, ctrlKey, metaKey, key } = event;

      if (
        !target ||
        !(target instanceof Node) ||
        !textBox.hiddenTextarea ||
        !textBox.hiddenTextarea.isSameNode(target)
      ) {
        return;
      }

      if ((ctrlKey || metaKey) && key === ITALIC_SHORTCUT_KEY) {
        handleFontStyleKeyboardShortcuts();
      }
    };

    document.addEventListener('keydown', handleDocumentKeyDownEvent);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDownEvent);
    };
  }, [textBox, handleFontStyleKeyboardShortcuts]);

  return {
    isItalic,
    setFontStyle,
  };
}

export default useTextBoxFontStyle;
