import { gql, TypedDocumentNode } from '@apollo/client';
import {
  SendConstructionNoteEmail,
  SendConstructionNoteEmailVariables,
} from './__generated__/SendConstructionNoteEmail.mutation';

const SendConstructionNoteEmailMutation: TypedDocumentNode<
  SendConstructionNoteEmail,
  SendConstructionNoteEmailVariables
> = gql`
  mutation SendConstructionNoteEmail($file: Upload!, $input: SendConstructionNoteEmailInput!) {
    sendConstructionNoteEmail(file: $file, input: $input) {
      status
    }
  }
`;

export default SendConstructionNoteEmailMutation;
