import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import LogEmails from '../LogEmails';
import { AcceptanceReportLog as AcceptanceReportLogType } from '../../graphql/__generated__/AcceptanceReportLog.fragment';
import { assertUnknownLog, getActor } from '../Log.utils';

interface AcceptanceReportLogProps extends ComponentProps<typeof Text> {
  log: AcceptanceReportLogType;
}

export default function AcceptanceReportLog({
  log,
  ...props
}: AcceptanceReportLogProps): JSX.Element {
  switch (log.__typename) {
    case 'AcceptanceReportArchivedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportArchived"
            values={{ acceptanceReportName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportCreatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportCreated"
            values={{ acceptanceReportName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportDeletedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportDeleted"
            values={{ acceptanceReportName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportEmailSent"
            values={{ acceptanceReportName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportExternalEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportExternalEmailSent"
            values={{ acceptanceReportName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportExternalLinkExpiredLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportExternalLinkExpired"
            values={{ acceptanceReportName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'AcceptanceReportManuallySignedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportManuallySigned"
            values={{ acceptanceReportName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportNotSignedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportNotSigned"
            values={{
              acceptanceReportName: log.name,
              dayCount: log.dayCount || 7,
            }}
            components={{
              bold: <Text variant="label-medium" />,
              warning: <Text color="warning" variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'AcceptanceReportSignedByCustomerLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.acceptanceReportSignedByCustomer"
            values={{ acceptanceReportName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
