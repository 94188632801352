import { Route, Routes, useLocation } from 'react-router-dom';
import { NotFoundErrorPage } from '@hs-baumappe/redkit';
import CreateConcern from './CreateConcern';
import CreateNewOffer from './CreateNewOffer';
import CreateNewOvertime from './CreateNewOvertime';
import CreateRiskAssessment from '../RiskAssessment/CreateRiskAssessment';
import CreateAcceptanceReport from './CreateAcceptanceReport';
import ConstructionNoteCreate from '../ConstructionNote/ConstructionNoteCreate';
import CreateObstruction from './CreateObstruction';
import CreateQsst from './CreateQsst';
import CreateSIVBillingAttachment from './CreateSIVBillingAttachment';
import AssignDocumentToProject from './assign/AssignDocumentToProject';
import AssignDocumentToProjectImport from './assign/AssignDocumentToProjectImport';
import CreateSiv from './CreateSiv';
import ProjectDetail from './ProjectDetail';
import AddContactPeople from './contactPeople/AddContactPeople';
import UpdateContactPeople from './contactPeople/UpdateContactPeople';
import DriveRouteModals from './DriveRouteModals';
import { ProjectLocationState } from './Project.route.types';

const Project = (): JSX.Element | null => {
  const location = useLocation<ProjectLocationState>();
  const backgroundLocation = location.state && location.state.backgroundLocation;

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="f/:folderId">
          <Route path="create-concern" element={<CreateConcern />} />
          <Route path="create-offer" element={<CreateNewOffer />} />
          <Route path="create-overtime" element={<CreateNewOvertime />} />
          <Route path="create-risk-assessment" element={<CreateRiskAssessment />} />
          <Route path="create-acceptance-report" element={<CreateAcceptanceReport />} />
          <Route path="create-construction-note" element={<ConstructionNoteCreate />} />
          <Route path="create-obstruction" element={<CreateObstruction />} />
          <Route path="create-qsst" element={<CreateQsst />} />
          <Route path="create-siv-billing-attachment" element={<CreateSIVBillingAttachment />} />
          <Route path="*" element={<NotFoundErrorPage />} />
        </Route>

        <Route path="d/:documentId">
          <Route path="assign-document-to-project" element={<AssignDocumentToProject />} />
          <Route
            path="assign-document-to-project-import"
            element={<AssignDocumentToProjectImport />}
          />
          <Route path="*" element={<NotFoundErrorPage />} />
        </Route>

        <Route path="create-siv" element={<CreateSiv />} />

        <Route path="*" element={<ProjectDetail />} />
      </Routes>

      <Routes>
        <Route path="cp">
          <Route path="add" element={<AddContactPeople />} />
          <Route path=":contactPersonId/edit" element={<UpdateContactPeople />} />
        </Route>

        <Route path="*" element={<DriveRouteModals />} />
      </Routes>
    </>
  );
};

export default Project;
