import { gql } from '@apollo/client';

export const GET_MEASUREMENT_FRAGMENT = gql`
  fragment OfferMeasurementFragment on Measurement {
    id
    name
    meta
    multiplier
    total
    subtraction
    totalMeta
  }
`;

export const GET_FOLDER_OPERATION_PAYMENT_FRAGMENT = gql`
  fragment OfferFolderOperationPaymentFragment on OperationPayment {
    totalPrice
    basePrice
    flat
    discountOrSurchargeValue
    discountOrSurchargeValueType
  }
`;

export const GET_FOLDER_OPERATION = gql`
  fragment OfferFolderOperationFragment on FolderOperation {
    id
    name
    description
    partNo
    partNoAlias
    partNoComputed
    price
    optional
    approximate
    note
    unit
    totalMeasurement
    completed
    viewerCanUpdate
    viewerCanClone
    viewerCanRemove

    measurements {
      id
      name
      meta
      multiplier
      total
      subtraction
      totalMeta
    }

    payment {
      ...OfferFolderOperationPaymentFragment
    }
  }

  ${GET_FOLDER_OPERATION_PAYMENT_FRAGMENT}
`;

export const GET_FOLDER_FRAGMENT = gql`
  fragment OfferFolderFragment on Folder {
    id
    name
    partNo
    partNoAlias
    partNoComputed
    sort
    totalPrice
    unit
    approximate
    note
    viewerCanAddSubfolder
    viewerCanAddOperation
    viewerCanGivePartNoAlias
    viewerCanUpdate
    viewerCanClone
    viewerCanRemove
    totalMeasurement

    measurements {
      ...OfferMeasurementFragment
    }

    parent {
      id
    }

    category {
      id
    }

    operations {
      ...OfferFolderOperationFragment
    }
  }

  ${GET_MEASUREMENT_FRAGMENT}
  ${GET_FOLDER_OPERATION}
`;

export const GET_OFFER_DATA = gql`
  query OfferData($id: ID!) {
    offer(id: $id) {
      id
      offerId
      pdfPath
      name
      updatedAt
      type
      status
      mode
      viewerCanUpdate
      viewerCanExport
      viewerCanUpdateTaxRate
      viewerCanRemove
      viewerCanAssign

      project {
        id
        name
        draft
        city
        street
        postCode
        addressLine
        projectNumber
        hsProjectNumber
      }

      summary {
        taxRate
        taxPrice
        grossPrice
        totalPrice
        totalOptionalPrice
      }

      meta {
        draftClient {
          name
          address
          city
          zip
        }
        draftConstructionSite {
          name
          address
          city
          zip
        }
      }

      catalogue {
        id
        name
      }

      folders {
        ...OfferFolderFragment
      }
    }
  }

  ${GET_FOLDER_FRAGMENT}
`;
