import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ObstructionArchive,
  ObstructionArchiveVariables,
} from './__generated__/ObstructionArchive.mutation';

const ObstructionArchiveMutation: TypedDocumentNode<
  ObstructionArchive,
  ObstructionArchiveVariables
> = gql`
  mutation ObstructionArchive($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
    }
  }
`;

export default ObstructionArchiveMutation;
