import { useMutation } from '@apollo/client';
import ObstructionDuplicateMutation from './graphql/ObstructionDuplicate.mutation';
import {
  ObstructionDuplicate,
  ObstructionDuplicateVariables,
} from './graphql/__generated__/ObstructionDuplicate.mutation';

interface UseObstructionDuplicateResponse {
  duplicate: () => Promise<string | undefined>;
  loading: boolean;
}

export default function useObstructionDuplicate(
  obstructionId: string,
): UseObstructionDuplicateResponse {
  const [mutate, { loading }] = useMutation<ObstructionDuplicate, ObstructionDuplicateVariables>(
    ObstructionDuplicateMutation,
  );

  async function duplicate() {
    const { data } = await mutate({
      variables: {
        input: {
          documentId: obstructionId,
        },
      },
    });

    if (!data) {
      return;
    }

    return data.obstructionDuplicate.id;
  }

  return { duplicate, loading };
}
