import { OfferRouteParams } from './Offer/Offer.route.types';
import { OvertimeRouteParams } from './Overtime/Overtime.route.types';

import { AcceptanceReportRouteParams } from './AcceptanceReport/AcceptanceReport.route.types';
import { route } from './routes.utils';
import { ConstructionNoteRouteParams } from './ConstructionNote/ConstructionNote.route.types';
import { RiskAssessmentRouteParams } from './RiskAssessment/RiskAssessment.route.type';
import { AssignDocumentToProjectRouteParams } from './Project/assign/AssignDocumentToProject/AssignDocumentToProject.route.types';
import { AssignDocumentToProjectImportRouteParams } from './Project/assign/AssignDocumentToProjectImport/AssignDocumentToProjectImport.route.types';
import { CustomFileRouteParams } from './CustomFile/CustomFile.route.types';
import { ConcernRouteParams } from './Concern/Concern.route.types';
import ObstructionRouteParams from './Obstruction/obstruction.route.types';
import { DriveDocumentPreviewRouteParams } from './DriveDocumentPreview/driveDocumentPreview.route.types';
import { UpdateContactPeopleRouteParams } from './Project/contactPeople/UpdateContactPeople/UpdateContactPeople.route.types';
import {
  ProjectDetailRouteParams,
  ProjectDetailWithFolderRouteParams,
} from './Project/ProjectDetail/ProjectDetail.route.types';
import { SIVRouteParams } from './SIV/SIV.route.types';
import { DocumentRouteParams } from './Document/document.route.types';
import { QsstRouteParams } from './Qsst/Qsst.route.types';
import { SIVBillingAttachmentRouteParams } from './SIVBillingAttachment/SIVBillingAttachment.route.types';

const routes = {
  home: route('/'),
  projects: route('/projects'),
  project: route<ProjectDetailRouteParams>('/project/:projectId'),
  offer: route<OfferRouteParams>('/offer/:offerId'),
  siv: route<SIVRouteParams>('/siv/:sivId'),
  concern: route<ConcernRouteParams>('/concern/:concernId'),
  customFile: route<CustomFileRouteParams>('/custom-file/:documentId'),
  constructionNote: route<ConstructionNoteRouteParams>('/construction-note/:constructionNoteId'),
  overtime: route<OvertimeRouteParams>(`/overtime/:overtimeId`),
  riskAssessment: route<RiskAssessmentRouteParams>('/risk-assessment/:riskAssessmentId'),
  acceptanceReport: route<AcceptanceReportRouteParams>(`/acceptance-report/:acceptanceReportId`),
  obstruction: route<ObstructionRouteParams>(`/obstruction/:obstructionId`),
  sivBillingAttachment: route<SIVBillingAttachmentRouteParams>(
    `/siv-billing-attachment/:sivBillingAttachmentId`,
  ),
  publicOvertime: route(`/external/overtime/:overtimeId`),
  cataglogues: route('/catalogues'),
  logout: route('/logout'),
  authCallback: route('/auth/callback'),
  settings: route('/settings'),
  driveDocumentPreview: route<DriveDocumentPreviewRouteParams>('/document-preview/:documentId'),
  document: route<DocumentRouteParams>('/document/:documentId'),
  qsst: route<QsstRouteParams>(`/qsst/:qsstId`),
  timeTracking: route('/time-tracking'),
};

export const catalogueRoutes = {
  detail: routes.cataglogues,
};

export const projectsRoutes = {
  detail: routes.projects,
  importModal: route(`${routes.projects.path}/import`),
  sapTransmitModal: route(`${routes.projects.path}/sap-transmit`),
};

export const projectRoutes = {
  detail: routes.project,
  driveWithFolder: route<ProjectDetailWithFolderRouteParams>(`${routes.project.path}/:folderId`),
  addContactPeople: route<ProjectDetailRouteParams>(`${routes.project.path}/cp/add`),
  updateContactPeople: route<UpdateContactPeopleRouteParams>(
    `${routes.project.path}/cp/:contactPersonId/edit`,
  ),
  offer: route<OfferRouteParams>(`${routes.project.path}/offer/:offerId`),
  createOffer: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-offer`,
  ),
  createSiv: route<ProjectDetailRouteParams>(`${routes.project.path}/create-siv`),
  createConcern: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-concern`,
  ),
  createOvertime: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-overtime`,
  ),
  createRiskAssessment: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-risk-assessment`,
  ),
  createAcceptanceReport: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-acceptance-report`,
  ),
  createConstructionNote: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-construction-note`,
  ),
  createObstruction: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-obstruction`,
  ),
  createQsst: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-qsst`,
  ),
  createSIVBillingAttachment: route<ProjectDetailWithFolderRouteParams>(
    `${routes.project.path}/f/:folderId/create-siv-billing-attachment`,
  ),
  assignDocumentToProject: route<AssignDocumentToProjectRouteParams>(
    `${routes.project.path}/d/:documentId/assign-document-to-project`,
  ),
  assignDocumentToProjectImport: route<AssignDocumentToProjectImportRouteParams>(
    `${routes.project.path}/d/:documentId/assign-document-to-project-import`,
  ),
};

export const settingsRoutes = {
  root: routes.settings,
  notifications: route(`${routes.settings.path}/notifications`),
};

export default routes;
