import { ApolloError, useQuery } from '@apollo/client';
import AcceptanceReportEditDetailQuery from '../../graphql/AcceptanceReportEditDetailQuery';
import { AcceptanceReportEditDetail } from '../../graphql/__generated__/AcceptanceReportEditDetailQuery';

interface UseAcceptanceReportEditDetailResponse {
  acceptanceReport?: AcceptanceReportEditDetail['acceptanceReport'];
  contactPeople: string[];
  loading: boolean;
  error?: ApolloError;
}

const ACCEPTANCE_REPORT_THUMBNAIL_WIDTH = 58 * 2;
const ACCEPTANCE_REPORT_THUMBNAIL_HEIGHT = 48 * 2;

export default function useAcceptanceReportEditDetail(
  acceptanceReportId: string,
): UseAcceptanceReportEditDetailResponse {
  const { data, loading, error } = useQuery(AcceptanceReportEditDetailQuery, {
    variables: {
      id: acceptanceReportId,
      thumbnailWidth: ACCEPTANCE_REPORT_THUMBNAIL_WIDTH,
      thumbnailHeight: ACCEPTANCE_REPORT_THUMBNAIL_HEIGHT,
    },
    fetchPolicy: 'network-only',
  });

  const acceptanceReport = data?.acceptanceReport;
  const contactPeople = data
    ? (data.acceptanceReport.project.contactPeople
        .filter((contactPerson) => !!contactPerson.name)
        .map((contactPerson) => contactPerson.name) as string[])
    : [];

  return {
    acceptanceReport,
    contactPeople,
    loading,
    error,
  };
}
