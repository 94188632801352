import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useProfessions from '../../../hooks/useProfessions';
import useObstructionSave from '../../Obstruction/hooks/useObstructionSave';
import CreateObstructionProjectDetailQuery from './graphql/CreateObstructionProjectDetail.query';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState/ErrorState';
import ObstructionForm from '../../Obstruction/forms/ObstructionForm/ObstructionForm';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';
import { mapObstructionProjectToFormValues } from './CreateObstruction.utils';

interface CreateObstructionProps {
  documentId: string;
}

function CreateObstruction({ documentId }: CreateObstructionProps): JSX.Element | null {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const { data, loading, error } = useQuery(CreateObstructionProjectDetailQuery, {
    variables: {
      projectId,
    },
  });
  const { professions } = useProfessions();

  const { save, loading: saving, error: saveError } = useObstructionSave(documentId);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const project = data?.project;

  function renderContent() {
    if (loading) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!project) {
      return null;
    }

    return (
      <ObstructionForm
        initialValues={mapObstructionProjectToFormValues(project)}
        projectId={projectId}
        professions={professions}
        submitButtonText={t('obstructionForm.create.submitButton')}
        onSubmit={save}
        onDirtyStateChange={(dirty) => (dirty ? setFormDirty(true) : undefined)}
        loading={saving}
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          {project && (
            <Text variant="body-medium" component="div" color="muted">
              {project.description}
              {project.additionalName && ' | ' + project.additionalName}
            </Text>
          )}

          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('createObstruction.title')}
          </Text>
        </>
      }
    >
      {saveError && <ErrorState error={saveError} />}

      {renderContent()}

      <AlertDialog
        destructive
        open={confirmationDialogOpen}
        title={t('createObstruction.confirmationModal.title')}
        description={t('createObstruction.confirmationModal.body')}
        confirmButtonText={t('createObstruction.confirmationModal.confirmButton')}
        cancelButtonText={t('createObstruction.confirmationModal.dismissButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setDialogOpen(false);
        }}
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateObstruction, {
  mimeType: DriveSpecialDocumentMimeTypes.OBSTRUCTION,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
