import { SchemaOf } from 'yup';
import { OfferMode } from '../../../../../globalTypes';
import yup from '../../../../../yup';
import { ClientMetaData, EditNameFormValues } from './values';

export const clientMetaDataValidationSchema: SchemaOf<ClientMetaData> = yup.object({
  name: yup.string().max(250),
  address: yup.string().max(250),
  city: yup.string().max(250),
  zip: yup
    .string()
    .length(5, 'validation.postCode.invalid')
    .matches(/^[0-9]+$/, 'validation.postCode.invalid'),
});

export const validationSchema = yup.object({
  name: yup.string().max(255).required(),
  mode: yup.mixed().oneOf(Object.values(OfferMode)),
  client: clientMetaDataValidationSchema,
  constructionSite: clientMetaDataValidationSchema,
});

export function offerEditFormInitialValues(
  initialValues?: Partial<EditNameFormValues>,
): EditNameFormValues {
  return {
    name: '',
    mode: OfferMode.SYSTEM_NUMBER,
    ...initialValues,
  };
}
