import { Flex, Spinner, TableCell, TableRow } from '@hs-baumappe/legacy-ui';
import { useEffect, useRef } from 'react';

interface ProjectImportTablePageLoaderProps {
  loading: boolean;
  loadNextPage: () => void;
}

export default function ProjectImportTablePageLoader({
  loading,
  loadNextPage,
}: ProjectImportTablePageLoaderProps): JSX.Element | null {
  const elementRef = useRef<HTMLTableRowElement | null>(null);

  useEffect(() => {
    const observerSupported = !!window.IntersectionObserver;

    if (!observerSupported || !elementRef.current) return;

    const observer = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && !loading) {
        loadNextPage();
      }
    });

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [loading, loadNextPage]);

  return (
    <TableRow elementRef={elementRef}>
      <TableCell colSpan={3}>
        {loading && (
          <Flex alignItems="center" justifyContent="center">
            <Spinner color="primary" size={24} />
          </Flex>
        )}
      </TableCell>
    </TableRow>
  );
}
