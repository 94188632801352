import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { QsstStatus } from '../../../../../globalTypes';

interface QsstDetailTitleProps {
  name: string;
  status: QsstStatus;
}

export const QsstDetailTitle: FC<QsstDetailTitleProps> = ({ name, status }) => {
  const { t } = useTranslation();

  const statusColor = [
    QsstStatus.SENT,
    QsstStatus.SIGNED,
    QsstStatus.MANUALLY_SIGNED,
    QsstStatus.ARCHIVED,
  ].includes(status)
    ? 'success'
    : 'muted';

  return (
    <div>
      <Text component="h1" variant="body-large" color="muted" className="u-margin-bottom-0">
        {name}
      </Text>
      <Text color={statusColor}>{t('obstruction.status', { status: t(status) })}</Text>
    </div>
  );
};
