import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DataList from '../../../../../../components/layouts/DataList';
import Table, { Thead, Tr, Th, Tbody, TableEmptyState } from '../../../../../../components/Table';

import ProjectsTableRow from './ProjectsTableRow';
import { HsProject } from '../__generated__/graphql';
import { Paper, Text } from '@hs-baumappe/legacy-ui';

interface Props {
  projects: HsProject[];
  activeProject?: HsProject;
  activeProjectIcon?: HsProject;
  emptyText?: string;
  onClickProject: (value: HsProject) => void;
}

const ProjectsTable: FC<Props> = ({
  projects,
  activeProject,
  activeProjectIcon,
  emptyText,
  onClickProject,
}) => {
  const { t } = useTranslation();

  return (
    <Paper className="u-margin-top u-padding-xsmall">
      <DataList>
        <Table>
          <Thead>
            <Tr sticky>
              <Th>
                <Text variant="body-large">
                  {t('assignOfferToProjectImport.projectTable.projectId')}
                </Text>
              </Th>
              <Th>
                <Text variant="body-large">
                  {t('assignOfferToProjectImport.projectTable.projectName')}
                </Text>
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            <>
              {!projects.length && (
                <TableEmptyState id="project-import-empty-text" colSpan={4}>
                  <Text variant="body-large" color="gray-500">
                    {emptyText}
                  </Text>
                </TableEmptyState>
              )}
              {projects.map((project, index) => (
                <ProjectsTableRow
                  id={`project-import-list-${index}`}
                  key={project.id}
                  activeProject={activeProject}
                  activeProjectIcon={activeProjectIcon}
                  project={project}
                  onClickProject={onClickProject}
                />
              ))}
            </>
          </Tbody>
        </Table>
      </DataList>
    </Paper>
  );
};

export default ProjectsTable;
