class Point {
  x: number;

  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  distanceFrom(that: Point): number {
    const horizontalDistance = this.x - that.x;
    const verticalDistance = this.y - that.y;

    return Math.sqrt(horizontalDistance * horizontalDistance + verticalDistance * verticalDistance);
  }
}

export default Point;
