import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_FOLDER_OPERATION_MUTATION = gql`
  mutation UpdateFolderOperation($input: UpdateFolderOperationInput!) {
    updateFolderOperation(input: $input) {
      id
    }
  }
`;
