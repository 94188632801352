import { ReactNode } from 'react';
import cx from 'classnames';
import { FieldStatus } from '../fieldStatus';
import FormFeedback, { FormFeedbackTheme } from '../FormFeedback';
import FormLabel from '../FormLabel';

import './c-field-layout.scss';

export interface ChildrenProps {
  status: FieldStatus;
}

export interface Props {
  className?: string;
  children: (props: ChildrenProps) => ReactNode;
  status?: FieldStatus;
  feedback?: Partial<{ [key in FieldStatus]: string }>;
  label?: ReactNode;
  labelHtmlFor?: string;
}

function getFeedbackThemeByStatus(status: FieldStatus): FormFeedbackTheme | undefined {
  switch (status) {
    case 'invalid':
      return 'invalid';
    case 'valid':
      return 'valid';
    default:
      return undefined;
  }
}

const FieldLayout = ({
  className,
  children,
  status = 'idle',
  label,
  labelHtmlFor = '',
  feedback = {},
}: Props): JSX.Element => {
  const isDisplayTextIdle = status !== 'idle' && !feedback[status];
  const displayText = isDisplayTextIdle ? feedback.idle : feedback[status];

  return (
    <div className={cx('c-field-layout', className)}>
      {label && (
        <FormLabel className="c-field-layout__label" htmlFor={labelHtmlFor}>
          {label}
        </FormLabel>
      )}
      {children({ status })}
      {displayText && (
        <FormFeedback
          className="c-field-layout__feedback"
          theme={isDisplayTextIdle ? undefined : getFeedbackThemeByStatus(status)}
        >
          {displayText}
        </FormFeedback>
      )}
    </div>
  );
};

export default FieldLayout;
