import { ApolloError, useMutation } from '@apollo/client';

import ToggleShowSubProjectHours from './graphql/ToggleShowSubProjectHours.mutation';

interface UseToggleShowSubProjectHours {
  toggle: (id: string) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useToggleShowSubProjectHours(): UseToggleShowSubProjectHours {
  const [mutation, { loading, error }] = useMutation(ToggleShowSubProjectHours);

  async function toggle(id: string) {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        input: {
          id,
        },
      },
    });
  }

  return {
    toggle,
    loading,
    error,
  };
}
