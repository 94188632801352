import { gql, TypedDocumentNode } from '@apollo/client';
import HSProjectFragment from '../../../graphql/HSProject.fragment';
import {
  SearchHSProjects,
  SearchHSProjectsVariables,
} from './__generated__/SearchHSProjects.query';

const SearchHSProjectsQuery: TypedDocumentNode<SearchHSProjects, SearchHSProjectsVariables> = gql`
  query SearchHSProjects($term: String!, $limit: Int, $continuationToken: String) {
    searchHsProjects(term: $term, limit: $limit, continuationToken: $continuationToken) {
      projects {
        ...HSProject
      }

      continuationToken
    }
  }

  ${HSProjectFragment}
`;

export default SearchHSProjectsQuery;
