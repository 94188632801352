import { createContext } from 'react';

import { Folder, FolderOperation } from '../../Detail/types';

import { OfferData, OfferDataVariables } from '../../Detail/__generated__/graphql';
import { CatalogOperation } from '../../operation/OfferFolderOperationCreate/OperationSearch/types';

export interface OfferContextProps {
  selectedFolder?: Folder;
  alertMessage?: string;
  setAlertMessage: (message: string | undefined) => void;
  setSelectedFolder: (id: Folder['id'] | undefined) => void;
  getProject: () => OfferData['offer']['project'];
  getOffer: () => OfferData['offer'];
  getFolderById: (id: Folder['id']) => Folder | undefined;
  selectedCatalogue?: string;
  setSelectedCatalogueId: (id: string) => void;
  getOperationById: (
    folderId: Folder['id'],
    operationId: FolderOperation['id'],
  ) => FolderOperation | undefined;
  refetchOfferData: (variables?: Partial<OfferDataVariables>) => void;
  selectedCatalogueOperation?: CatalogOperation;
  setSelectedCatalogueOperation: (operation: CatalogOperation) => void;
}

const OfferContext = createContext<OfferContextProps>({} as OfferContextProps);

export default OfferContext;
