import { ConcernStatus } from '../../../../../globalTypes';

export function getStatusColor(status: ConcernStatus): 'muted' | 'success' {
  switch (status) {
    case ConcernStatus.OPEN:
      return 'muted';
    default:
      return 'success';
  }
}
