import { TypedDocumentNode, gql } from '@apollo/client';
import { ConcernLog } from './__generated__/ConcernLog.fragment';

const ConcernLogFragment: TypedDocumentNode<ConcernLog> = gql`
  fragment ConcernLog on ConcernLog {
    ... on ConcernArchivedLog {
      id
      name
      date

      concernId

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConcernCreatedLog {
      id
      name
      date

      concernId

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConcernDeletedLog {
      id
      name
      date

      concernId

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConcernEmailSentLog {
      id
      name
      date

      concernId

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on ConcernSignedLog {
      id
      name
      date

      concernId

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConcernUpdatedLog {
      id
      name
      date

      concernId

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default ConcernLogFragment;
