import { gql, TypedDocumentNode } from '@apollo/client';
import { SearchedProject, SearchedProjectVariables } from './__generated__/graphql';

export const SEARCHED_PROJECT_QUERY: TypedDocumentNode<SearchedProject, SearchedProjectVariables> =
  gql`
    query SearchedProject(
      $term: String
      $onlyFollowed: Boolean
      $includeStatus: [ProjectStatus!]
      $sorting: ProjectSorting
      $profitCenters: [ID!]
      $page: Int
      $limit: Int
      $viewSIVOverviewPermitted: Boolean!
      $viewSIVPricesPermitted: Boolean!
      $sivKeyFiguresInProjectList: Boolean!
    ) {
      projects(
        term: $term
        onlyFollowed: $onlyFollowed
        includeStatus: $includeStatus
        sorting: $sorting
        page: $page
        limit: $limit
        profitCenters: $profitCenters
      ) {
        projects {
          id

          name
          additionalName
          projectNumber
          draft
          status
          updatedAt
          sourceSystem

          viewerLogUnreadCount
          viewerFollowsProject

          profitCenter {
            id
            name
          }

          siv @include(if: $viewSIVOverviewPermitted) {
            id

            planning {
              totalMaterialCost
              totalCostByMeasurement
              totalHour
            }

            recording {
              totalMaterialCost
              totalCostByMeasurement
              totalHourOfEmployees
            }

            cockpit @include(if: $sivKeyFiguresInProjectList) {
              totalHourOfEmployees
              totalPlannedHours
              totalHoursToBeDistributed
              plusMinusHours

              # Fields that related the prices of SIV which should be shown if they have the view SIV prices scope. #
              ... on SIVCockpit @include(if: $viewSIVPricesPermitted) {
                totalPerformanceCost {
                  planned
                  recorded
                }

                totalCost {
                  recorded
                }

                recordedProfit {
                  monetary
                }
              }
            }
          }
        }
        pagination {
          current
          hasNextPage
        }
        meta {
          selectedFilters {
            profitCenter {
              count
              profitCenter {
                id

                name
              }
            }

            status {
              status
              count
            }
          }
        }
      }
    }
  `;
