import { gql } from '@apollo/client/core';
import { TypedDocumentNode } from '@apollo/client';
import AcceptanceReportFragment from '../../../../graphql/AcceptanceReport.fragment';
import {
  RemoveManuallyAcceptanceReport,
  RemoveManuallyAcceptanceReportVariables,
} from './__generated__/RemoveManuallyAcceptanceReport.mutation';

const RemoveManuallyAcceptanceReportMutation: TypedDocumentNode<
  RemoveManuallyAcceptanceReport,
  RemoveManuallyAcceptanceReportVariables
> = gql`
  mutation RemoveManuallyAcceptanceReport($input: RemoveManuallyUploadedAcceptanceReportInput!) {
    removeManuallyUploadedAcceptanceReport(input: $input) {
      ...AcceptanceReport
    }
  }

  ${AcceptanceReportFragment}
`;

export default RemoveManuallyAcceptanceReportMutation;
