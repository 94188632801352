import { ComponentProps, useEffect, useState } from 'react';
import { FlexGrid, FlexGridColumn, Stack, Text, useTimer } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';

const SHOW_WARNING_DELAY_IN_MILLIS = 3000;

export default function SAPTransmitLoadingLayout(
  props: ComponentProps<typeof LayoutLoading>,
): JSX.Element {
  const { t } = useTranslation();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setTimeoutTimer(() => setShowWarning(true), SHOW_WARNING_DELAY_IN_MILLIS);

    return () => clearAllTimers();
  }, [setTimeoutTimer, clearAllTimers]);

  return (
    <LayoutLoading {...props}>
      {showWarning && (
        <FlexGrid justifyContent="center" stylex={{ marginTop: 'large' }}>
          <FlexGridColumn md={6} component={Stack} gap="small-3x" stylex={{ textAlign: 'center' }}>
            <Text variant="title-small">{t('transmit.projectList.loadingWarning.title')}</Text>
            <Text color="gray-600">{t('transmit.projectList.loadingWarning.message')}</Text>
          </FlexGridColumn>
        </FlexGrid>
      )}
    </LayoutLoading>
  );
}
