import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  AddOperationToFolder,
  AddOperationToFolderVariables,
} from '../OfferFolderOperationCreate/CreateNewFolderOperation/__generated__/graphql';
import { ADD_OPERATION_TO_FOLDER } from '../OfferFolderOperationCreate/CreateNewFolderOperation/graphql';
import { OperationValues } from './CreateNewOperationFromTLKForm/values';
import {
  cast,
  clearEmptyTotal,
  createFlatRateMeasurmentValue,
} from '../../measurement/MeasurementSection/values';
import { OfferMode, OperationUnit } from '../../../../globalTypes';
import { castNote } from '../../folder/containers/FolderForm/form';
import { castPaymentToAddOperationToFolder } from '../PaymentForm/paymentMutationValidationSchema';
import { GetCatalogOperation } from './__generated__/graphql';
import { validationSchema } from './CreateNewOperationFromTLKForm/form';
import { GET_OFFER_DATA } from '../../Detail/graphql';
import { offerRoutes } from '../../offerRoutes';

type CatalogOperation = GetCatalogOperation['operation'];

interface FolderOperationCreateFromTlkProps {
  offerId: string;
  folderId: string;
  offerMode: OfferMode;
}

interface UseFolderOperationCreateFromTlkParams {
  error?: ApolloError;
  create: (v: OperationValues, o: CatalogOperation) => Promise<void>;
}

const useFolderOperationCreateFromTlk = ({
  offerId,
  offerMode,
  folderId,
}: FolderOperationCreateFromTlkProps): UseFolderOperationCreateFromTlkParams => {
  const navigate = useNavigate();
  const [mutation, { loading, error }] = useMutation<
    AddOperationToFolder,
    AddOperationToFolderVariables
  >(ADD_OPERATION_TO_FOLDER);

  const create = async (values: OperationValues, operation: CatalogOperation) => {
    if (loading) {
      return;
    }

    let castedValues = clearEmptyTotal(cast(values.measurements));

    const v = validationSchema().validateSync(values);
    if (!v) {
      return;
    }

    if (castedValues.length === 0 && operation.unit === OperationUnit.FLAT_RATE) {
      castedValues = [createFlatRateMeasurmentValue()];
    }

    await mutation({
      variables: {
        input: {
          partNoAlias: offerMode === OfferMode.ALIAS_NUMBER ? values.alias : undefined,
          folder: folderId,
          operation: operation.id,
          name: v.name,
          price: v.price,
          approximate: v.approximate,
          description: v.description,
          note: castNote(v.note),
          optional: v.optional || false,
          unit: (v.unit as OperationUnit) || operation.unit,
          measurements: castedValues || [],
          payment: castPaymentToAddOperationToFolder(v.payment),
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    navigate(offerRoutes.detail.generatePath({ offerId }));
  };

  return {
    error,
    create,
  };
};

export default useFolderOperationCreateFromTlk;
