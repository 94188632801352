import { fabric } from '@hs-baumappe/fabric';
import createTextBox from './createTextBox';

interface CreateFullBlockTextBoxParameters {
  canvasWidth: number;
}

/**
 * (0, 1), The width of Full Block Textbox by according to Canvas's width.
 */
const FULL_BLOCK_TEXTBOX_WIDTH_BY_CANVAS_WIDTH = 0.95;

export default function createFullBlockTextBox({
  canvasWidth,
}: CreateFullBlockTextBoxParameters): fabric.Textbox {
  return createTextBox({
    emptyLineCount: 3,
    textBoxOptions: {
      width: canvasWidth * FULL_BLOCK_TEXTBOX_WIDTH_BY_CANVAS_WIDTH,
      top: 55,
    },
  });
}
