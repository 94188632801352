import { ApolloError, useQuery } from '@apollo/client';

import RiskAssessmentCreateDetailQuery from '../../graphql/RiskAssessmentCreateDetail.query';
import RiskAssessmentEmployeesQuery from '../../../RiskAssessmentForm/Information/graphql/RiskAssessmentEmployees.query';
import { RiskAssessmentCreateDetail } from '../../graphql/__generated__/RiskAssessmentCreateDetail.query';
import { RegionalSecurityManager } from '../../../graphql/__generated__/RegionalSecurityManager.fragment';
import { RiskAssessmentEmployees } from '../../../RiskAssessmentForm/Information/graphql/__generated__/RiskAssessmentEmployees.query';

interface UseRiskAssessmentCreateDetailResponse {
  project?: RiskAssessmentCreateDetail['project'];
  employees: RiskAssessmentEmployees['employeesForProject'];
  regionalSecurityManagers: RegionalSecurityManager[];
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentCreateDetail(
  projectId: string,
): UseRiskAssessmentCreateDetailResponse {
  const { data, loading, error } = useQuery(RiskAssessmentCreateDetailQuery, {
    variables: {
      projectId,
    },
  });

  const project = data?.project;
  const regionalSecurityManagers = data?.regionalSecurityManagers ?? [];

  const { data: employeeData } = useQuery(RiskAssessmentEmployeesQuery, {
    variables: {
      projectId,
    },
  });

  const employees = employeeData?.employeesForProject ?? [];

  return {
    project,
    employees,
    regionalSecurityManagers,
    loading,
    error,
  };
}
