import { MeasurementValidationSchema } from '../MeasurementSection/validationSchema';
import yup, { InferObjectToYupShape } from '../../../../yup';
import { MeasurementValues } from '../MeasurementSection/values';

// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/explicit-module-boundary-types,
export const MeasurementsSectionValidationSchema = (required: boolean) =>
  yup
    .array<InferObjectToYupShape<Array<MeasurementValues>>>()
    .of(MeasurementValidationSchema(required))
    .default([])
    .defined();
