import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Box, FlexGrid, FlexGridColumn, Stack, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading, PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';
import { MissingScopeErrorPage } from '@hs-baumappe/redkit';

import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import usePDFManipulatorToken from '../DocumentManipulate/PDFManipulator/hooks/usePDFManipulatorToken';
import usePSPDFKitManipulator from '../DocumentManipulate/PDFManipulator/hooks/usePSPDFKitManipulator';
import DocumentDetailAlerts from './components/DocumentDetailAlerts/DocumentDetailAlerts';
import DocumentDetailHeader from './components/DocumentDetailHeader';
import PDFManipulatorLayout from '../DocumentManipulate/PDFManipulator/components/PDFManipulatorLayout';
import DocumentDetailSidebarTop from './components/DocumentDetailSidebarTop';
import DocumentDetailSidebarBottom from './components/DocumentDetailSidebarBottom';
import DocumentLogsList from './components/DocumentLogsList';
import { projectRoutes } from '../../routes';
import documentRoutes from '../documentRoutes';
import { DocumentLocationState } from '../document.route.types';
import { createDocumentLocation } from '../Document.utils';
import { CustomFileDocument } from '../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../graphql/__generated__/CustomFileDocumentProject.fragment';
import { CustomFileDocumentScopes } from '../Document.types';
import { DocumentManipulator } from '../../../globalTypes';

interface DocumentDetailProps {
  document: CustomFileDocument;
  project: CustomFileDocumentProject;
  scopes: CustomFileDocumentScopes;
}

export default function DocumentDetail({
  document,
  project,
  scopes,
}: DocumentDetailProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation<DocumentLocationState>();

  const { parentFolders } = useDriveDocumentParentFolders(document.id);
  const viewPermitted = scopes.viewCustomFileDocument.isPermitted;
  const viewMissingScopeNames = scopes.viewCustomFileDocument.missingScopeNames;
  const updatePermitted = scopes.updateCustomFileDocument.isPermitted;
  const downloadPermitted = scopes.downloadCustomFileDocument.isPermitted;

  const documentId = document.id;
  const readOnly = !document.viewerCanManipulate || !updatePermitted;

  const fullScreen = searchParams.has('fullScreen');
  const handleFullScreenClick = useCallback(() => {
    setSearchParams((params) => {
      if (params.has('fullScreen')) {
        params.delete('fullScreen');
      } else {
        params.set('fullScreen', 'true');
      }

      return params;
    });
  }, [setSearchParams]);

  const {
    token,
    loading,
    error,
    refresh: refreshToken,
  } = usePDFManipulatorToken({ documentId, readOnly });
  const {
    containerRef,
    save: saveAnnotations,
    saving,
    error: manipulatorError,
    hasUnsavedChanges,
  } = usePSPDFKitManipulator({
    documentId,
    token,
    viewerCanDownload: downloadPermitted,
    readOnly,
    onFullScreenToolClick: handleFullScreenClick,
  });

  if (loading) {
    return <LayoutLoading />;
  }

  if (error || !token) {
    return <Alert color="error">{t('documentManipulate.pdf.tokenError')}</Alert>;
  }

  if (manipulatorError) {
    return <Alert color="error">{t('documentManipulate.pdf.error')}</Alert>;
  }

  async function handleSaveButtonClick() {
    const savedDocumentId = await saveAnnotations();
    const documentDuplicated = !!savedDocumentId && savedDocumentId !== documentId;

    searchParams.delete('fullScreen');

    if (documentDuplicated) {
      navigate(
        createPath({
          pathname: documentRoutes.manipulate.generatePath({ documentId: savedDocumentId }),
          search: searchParams.toString(),
        }),
      );
      return;
    }

    if (fullScreen) {
      navigate(
        createPath({
          pathname: documentRoutes.manipulate.generatePath({ documentId }),
          search: searchParams.toString(),
        }),
      );
    }

    refreshToken();
  }

  if (!viewPermitted) {
    return <MissingScopeErrorPage missingScopes={viewMissingScopeNames} />;
  }

  function handleSendButtonClick() {
    navigate(documentRoutes.sendEmail.generatePath({ documentId: document.id }), {
      state: createDocumentLocation(location),
    });
  }

  function handleDownloadButtonClick() {
    if (!document.download?.url) {
      return;
    }

    window.open(document.download.url, '_blank');
  }

  function handleRemoveSuccess() {
    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({ projectId: project.id }),
        search: new URLSearchParams({ removedDocumentName: document.name }).toString(),
      }),
    );
  }

  function decideManipulator() {
    switch (document.manipulator) {
      case DocumentManipulator.PSPDFKIT:
        return (
          <PDFManipulatorLayout
            containerRef={containerRef}
            documentId={documentId}
            readOnly={readOnly}
            fullScreen={fullScreen}
            saving={saving}
            hasUnsavedChanges={hasUnsavedChanges}
            onSaveButtonClick={handleSaveButtonClick}
          />
        );
      default:
        return (
          <Alert color="error">
            <Text color="inherit">{t('documentManipulate.notAvailableToManipulation')}</Text>
          </Alert>
        );
    }
  }

  return (
    <PageLayoutWithStickySidebar
      header={
        <DocumentDetailHeader document={document} project={project} parentFolders={parentFolders} />
      }
      sidebarTop={
        !fullScreen && (
          <DocumentDetailSidebarTop
            document={document}
            scopes={scopes}
            saving={saving}
            hasUnsavedChanges={hasUnsavedChanges}
            onSaveButtonClick={handleSaveButtonClick}
            onSendButtonClick={handleSendButtonClick}
            onDownloadButtonClick={handleDownloadButtonClick}
            readOnly={readOnly}
          />
        )
      }
      sidebarBottom={
        !fullScreen && (
          <DocumentDetailSidebarBottom
            document={document}
            scopes={scopes}
            onRemoveSuccess={handleRemoveSuccess}
          />
        )
      }
      footer={
        <FlexGrid>
          <FlexGridColumn sm={8}>
            <DocumentLogsList documentId={document.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <DocumentDetailAlerts name={document.name} />

        <Box stylex={{ marginTop: 'large-x' }}>{decideManipulator()}</Box>
      </Stack>
    </PageLayoutWithStickySidebar>
  );
}
