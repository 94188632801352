import { fabric } from '@hs-baumappe/fabric';
import { useCallback } from 'react';

type UseCanvasCalculateIdealZoomValue = {
  calculateIdealZoomValueByIteration: (
    objects: fabric.Object[],
    zoomStartingValue: number,
    onSetZoom: (nextZoom: number) => void,
  ) => void;
};

/*
 * To increase that value reduces calculation error
 * but may occur performance problems
 * while finding the best ideal zoom value
 */
const ZOOM_STEP_DECREMENT = 0.005;

function useCanvasCalculateIdealZoomValue(): UseCanvasCalculateIdealZoomValue {
  const findOverflowedObjects = useCallback((objects: fabric.Object[]) => {
    return objects.filter((object: fabric.Object) => {
      const onScreen = object.isOnScreen(true);
      // Count the object as overflowed even some part of the object is in the viewport.
      const onScreenPartially = object.isPartiallyOnScreen(true);

      return onScreenPartially || !onScreen;
    });
  }, []);

  const calculateIdealZoomValueByIteration = useCallback(
    (
      objects: fabric.Object[],
      zoomStartingValue: number,
      onSetZoom: (zoomValue: number) => void,
    ) => {
      let currentZoomValue = zoomStartingValue;
      let overflowedObjects = findOverflowedObjects(objects);

      // Decrease the zoom value until all objects visible clearly.
      while (overflowedObjects.length > 0 && currentZoomValue > 0) {
        overflowedObjects = findOverflowedObjects(overflowedObjects);
        currentZoomValue -= ZOOM_STEP_DECREMENT;
        onSetZoom(currentZoomValue);
      }
    },
    [findOverflowedObjects],
  );

  return { calculateIdealZoomValueByIteration };
}

export default useCanvasCalculateIdealZoomValue;
