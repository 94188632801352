import { gql, TypedDocumentNode } from '@apollo/client';
import SIVDiscountSIVFragment from '../../../graphql/SIVDiscountSIV.fragment';
import {
  SIVDiscountSetDiscountAmount,
  SIVDiscountSetDiscountAmountVariables,
} from './__generated__/SIVDiscountSetDiscountAmountMutation';

const SIVDiscountToggleDiscountActiveMutation: TypedDocumentNode<
  SIVDiscountSetDiscountAmount,
  SIVDiscountSetDiscountAmountVariables
> = gql`
  mutation SIVDiscountToggleDiscountActive($input: SIVDiscountToggleInput!) {
    sivDiscountToggle(input: $input) {
      ...SIVDiscountSIV
    }
  }

  ${SIVDiscountSIVFragment}
`;

export default SIVDiscountToggleDiscountActiveMutation;
