import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useConcernEditDetail from '../../hooks/useConcernEditDetail';
import useProfessions from '../../../../../hooks/useProfessions';
import useConcernSave from '../../../hooks/useConcernSave';
import ErrorState from '../../../../../containers/ErrorState';
import ConcernForm from '../../../forms/ConcernForm';
import { ConcernFormValues } from '../../../forms/ConcernForm/ConcernForm.types';
import { ConcernProject } from '../../../graphql/__generated__/ConcernProject.fragment';
import { mapConcernToFormValues } from '../../ConcernEdit.utils';

interface ConcernEditBodyProps {
  concernId: string;
  project: ConcernProject;
  formDirty: boolean;
  onFormDirtyStateChange: (dirty: boolean) => void;
  onDismiss: () => void;
}

export default function ConcernEditBody({
  concernId,
  project,
  formDirty,
  onFormDirtyStateChange,
  onDismiss,
}: ConcernEditBodyProps): JSX.Element | null {
  const { t } = useTranslation();

  const { concern, loading, error } = useConcernEditDetail(concernId);
  const { professions } = useProfessions();
  const { save, loading: saving, error: saveError } = useConcernSave(concernId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!concern) {
    return null;
  }

  function handleSubmit(
    values: ConcernFormValues,
    formikHelpers: FormikHelpers<ConcernFormValues>,
  ) {
    if (!formDirty) {
      onDismiss();
      return;
    }

    save(values, formikHelpers);
  }

  return (
    <>
      {saveError && <ErrorState error={saveError} />}

      <ConcernForm
        initialValues={mapConcernToFormValues(concern)}
        projectId={project.id}
        draft={project.draft}
        professions={professions}
        submitButtonText={t('concern.edit.form.submitButton')}
        onSubmit={handleSubmit}
        loading={saving}
        onDirtyStateChange={onFormDirtyStateChange}
      />
    </>
  );
}
