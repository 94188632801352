import { ApolloError, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import config from '../../../../config';
import { qsstGenerateExternalAuthToken } from './graphql/QsstGenerateExternalAuthTokenQuery';
import {
  QsstGenerateExternalAuthToken,
  QsstGenerateExternalAuthTokenVariables,
} from './graphql/__generated__/QsstGenerateExternalAuthTokenQuery';

interface UseGenerateExternalQsstLinksProps {
  qsstIds: string[];
}

type LinkDic = Record<string, string>;

interface UseGenerateExternalQsstLinksReturn {
  externalQsstLinks: LinkDic | undefined;
  loading: boolean;
  error?: ApolloError;
}

export const useGenerateExternalQsstLinks = (
  props: UseGenerateExternalQsstLinksProps,
): UseGenerateExternalQsstLinksReturn => {
  const qsstIdsStr = JSON.stringify(props.qsstIds);

  const qsstIds = useMemo(() => {
    return JSON.parse(qsstIdsStr) as (typeof props)['qsstIds'];
  }, [qsstIdsStr]);

  const { data, loading, error } = useQuery<
    QsstGenerateExternalAuthToken,
    QsstGenerateExternalAuthTokenVariables
  >(qsstGenerateExternalAuthToken, {
    variables: {
      qsstIds: props.qsstIds,
    },
    skip: !qsstIds.length,
    fetchPolicy: 'cache-first',
  });

  const externalAuthToken = data?.qsstGenerateExternalAuthToken.token;

  const externalQsstLinks = useMemo(() => {
    if (!externalAuthToken) return;

    const linkDic: LinkDic = Object.fromEntries(
      qsstIds.map((qsstId) => {
        return [
          qsstId,
          `${config.HOST_URL}/qsst/customer-detail/${qsstId}?externalAuthToken=${externalAuthToken}`,
        ];
      }),
    );

    return linkDic;
  }, [qsstIds, externalAuthToken]);

  return {
    externalQsstLinks,
    loading,
    error,
  };
};
