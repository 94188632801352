import { useCallback, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import debounce from 'lodash.debounce';
import { SearchedProject } from './__generated__/graphql';
import { ProjectSortingField, ProjectStatus, SortingDirection } from '../../../../globalTypes';
import useProjects from './useProjects';
import {
  CachedProjectFilters,
  getProjectFilterCache,
  setProjectFilterCache,
} from './projectFilterCache';

interface UseProjectFilter {
  data: SearchedProject | undefined;
  projectStatuses: ProjectStatus[];
  setProjectStatuses: (projectStatuses: ProjectStatus[]) => void;
  searchProjects: (term?: string) => void;
  profitCenters: string[];
  setProfitCenters: (profitCenterIds: string[]) => void;
  projects: SearchedProject['projects']['projects'];
  loading: boolean;
  nextPageLoading: boolean;
  hasNextPage: boolean;
  error?: ApolloError;
  searchQuery?: string;
  onlyFollowed: boolean;
  setOnlyFollowed: (onlyFollowed: boolean) => void;
  fetchNextPage: () => Promise<void>;
  filterOutProject: (projectId: string) => void;
  meta?: SearchedProject['projects']['meta'];
  sortingField: ProjectSortingField;
  setSortingField: (sortingField: ProjectSortingField) => void;
  sortingDirection: SortingDirection;
  setSortingDirection: (sortingDirection: SortingDirection) => void;
}

interface UseProjectFilterOptions {
  viewSIVOverviewPermitted: boolean;
  viewSIVPricesPermitted: boolean;
  sivKeyFiguresInProjectList: boolean;
}

function useProjectFilter({
  viewSIVOverviewPermitted,
  viewSIVPricesPermitted,
  sivKeyFiguresInProjectList,
}: UseProjectFilterOptions): UseProjectFilter {
  const cachedFilters = getProjectFilterCache();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(cachedFilters?.searchQuery);
  const [profitCenters, updateProfitCenters] = useState<string[]>(
    cachedFilters?.profitCenters ?? [],
  );
  const [onlyFollowed, updateOnlyFollowed] = useState(cachedFilters?.onlyFollowed ?? false);
  const [projectStatuses, updateProjectStatuses] = useState<ProjectStatus[]>(
    cachedFilters?.projectStatuses ?? [],
  );
  const [sortingField, updateSortingField] = useState<ProjectSortingField>(
    cachedFilters?.sortingField ?? ProjectSortingField.HS_PROJECT_NUMBER,
  );
  const [sortingDirection, updateSortingDirection] = useState<SortingDirection>(
    cachedFilters?.sortingDirection ?? SortingDirection.DESC,
  );
  const { data, previousData, loading, nextPageLoading, error, fetchNextPage, filterOutProject } =
    useProjects({
      term: searchQuery,
      includeStatus: projectStatuses,
      page: 1,
      profitCenters: profitCenters.length > 0 ? profitCenters : undefined,
      onlyFollowed,
      viewSIVOverviewPermitted,
      viewSIVPricesPermitted,
      sorting: sortingField &&
        sortingDirection && {
          field: sortingField,
          direction: sortingDirection,
        },
      sivKeyFiguresInProjectList,
    });

  const projects = useMemo(() => (data ? data.projects.projects : []), [data]);
  const meta = useMemo(
    () => data?.projects.meta ?? previousData?.projects.meta,
    [data?.projects.meta, previousData?.projects.meta],
  );
  const hasNextPage = !!data?.projects.pagination.hasNextPage;

  const updateFiltersCache = useCallback(
    (value: Partial<CachedProjectFilters>): void => {
      setProjectFilterCache({
        searchQuery,
        projectStatuses,
        profitCenters,
        onlyFollowed,
        sortingField,
        sortingDirection,
        ...getProjectFilterCache(),
        ...value,
      });
    },
    [onlyFollowed, profitCenters, projectStatuses, searchQuery, sortingDirection, sortingField],
  );

  function setProfitCenters(value: string[]): void {
    updateProfitCenters(value);

    updateFiltersCache({
      profitCenters: value,
    });
  }

  function setOnlyFollowed(value: boolean): void {
    updateOnlyFollowed(value);

    updateFiltersCache({
      onlyFollowed: value,
    });
  }

  function setProjectStatuses(value: ProjectStatus[]): void {
    updateProjectStatuses(value);

    updateFiltersCache({
      projectStatuses: value,
    });
  }

  function setSortingField(value: ProjectSortingField): void {
    updateSortingField(value);

    updateFiltersCache({
      sortingField: value,
    });
  }

  function setSortingDirection(value: SortingDirection): void {
    updateSortingDirection(value);

    updateFiltersCache({
      sortingDirection: value,
    });
  }

  const searchProjects = useMemo(
    () =>
      debounce((term?: string) => {
        setSearchQuery(term);
        updateFiltersCache({ searchQuery: term });
      }, 500),
    [updateFiltersCache],
  );

  return {
    projectStatuses,
    setProjectStatuses,
    searchProjects,
    loading: loading && !data,
    nextPageLoading,
    hasNextPage,
    data,
    error,
    projects,
    searchQuery,
    profitCenters,
    setProfitCenters,
    onlyFollowed,
    setOnlyFollowed,
    fetchNextPage,
    filterOutProject,
    meta,
    sortingField,
    setSortingField,
    sortingDirection,
    setSortingDirection,
  };
}

export default useProjectFilter;
