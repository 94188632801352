import { concat } from './array';

export const MIME_TYPES = {
  PNG: ['image/png'],
  SVG: ['image/svg+xml'],
  JPG: ['image/jpeg'],
  GIF: ['image/gif'],
  PDF: ['application/pdf'],
};

export const IMAGE_MIME_TYPES = concat([
  MIME_TYPES.PNG,
  MIME_TYPES.JPG,
  MIME_TYPES.GIF,
  MIME_TYPES.SVG,
]);

export function areMimeTypesMatching(types: string[], mimeType: string): boolean {
  return types.includes(mimeType);
}

export function isDocumentPDF(mimeType: string): boolean {
  return areMimeTypesMatching(MIME_TYPES.PDF, mimeType);
}

export function isDocumentImage(mimeType: string): boolean {
  return areMimeTypesMatching(IMAGE_MIME_TYPES, mimeType);
}
