import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADD_BULK_OPERATION_TO_FOLDER = gql`
  mutation AddBulkOperationToFolder($input: AddBulkOperationToFolderInput!) {
    addBulkOperationToFolder(input: $input) {
      id
    }
  }
`;
