import { lazy, Suspense } from 'react';
import { Alert, Flex, Spinner, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../ErrorBoundary';

// eslint-disable-next-line import/no-unresolved
const SIVCockpit = lazy(() => import('sivWeb/SIVCockpit'));

interface SIVCockpitContainerProps {
  projectId: string;
}

export default function SIVCockpitContainer({
  projectId,
}: SIVCockpitContainerProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <ErrorBoundary
      fallback={
        <Alert color="error">
          <Text>{t('sivMFLoadError')}</Text>
        </Alert>
      }
    >
      <Suspense
        fallback={
          <Flex justifyContent="center">
            <Spinner color="primary" size={32} />
          </Flex>
        }
      >
        <SIVCockpit projectId={projectId} />
      </Suspense>
    </ErrorBoundary>
  );
}
