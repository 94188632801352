import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  AcceptanceReportUploadSignature,
  AcceptanceReportUploadSignatureVariables,
} from './__generated__/AcceptanceReportUploadSignature.mutation';
import AcceptanceReportFragment from '../../graphql/AcceptanceReport.fragment';

const AcceptanceReportUploadSignatureMutation: TypedDocumentNode<
  AcceptanceReportUploadSignature,
  AcceptanceReportUploadSignatureVariables
> = gql`
  mutation AcceptanceReportUploadSignature(
    $input: UploadAcceptanceReportSignatureInput!
    $file: Upload!
  ) {
    uploadAcceptanceReportSignature(input: $input, file: $file) {
      id

      acceptanceReport {
        ...AcceptanceReport
      }
    }
  }

  ${AcceptanceReportFragment}
`;

export default AcceptanceReportUploadSignatureMutation;
