import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import CustomFileLogsQuery from './graphql/CustomFileLogs.query';
import LogContainer, { Log } from '../../../../../containers/LogContainer';
import logsDateMapper from '../../../../../containers/LogContainer/LogContainer.utils';

interface DocumentLogListDetailProps {
  documentId: string;
}

export default function DocumentLogsListDetail({
  documentId,
}: DocumentLogListDetailProps): JSX.Element {
  const [logs, setLogs] = useState<Log[]>([]);
  const { data, loading, refetch } = useQuery(CustomFileLogsQuery, {
    variables: {
      id: documentId,
      page: 1,
      limit: 10,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data || !data.customFile?.logs) {
      return;
    }

    setLogs([...logs, ...data.customFile.logs.logs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMoreDataClick = async () => {
    if (!data || !data.customFile?.logs || !refetch) {
      return;
    }

    const currentPage = data.customFile.logs.pagination.current;

    await refetch({
      page: currentPage + 1,
    });
  };

  const logsDateData = logsDateMapper(logs);
  const hasNextPage =
    !loading && !!data && !!data.customFile?.logs && data.customFile.logs.pagination.hasNextPage;

  return (
    <LogContainer
      logs={logsDateData}
      hasNextPage={hasNextPage}
      onMoreDataClick={handleMoreDataClick}
      loading={loading}
    />
  );
}
