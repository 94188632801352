import { ApolloError, useMutation } from '@apollo/client';

import { CREATE_FOLDER_FRAMEWORK } from './graphql';
import {
  CreateFolderForFramework,
  CreateFolderForFrameworkVariables,
} from './__generated__/graphql';

import { BaseValues } from '../../containers/FolderForm/values';
import { cast, clearEmptyTotal } from '../../../measurement/MeasurementSection/values';
import { castNote } from '../../containers/FolderForm/form';
import { GET_OFFER_DATA } from '../../../Detail/graphql';

export interface OfferFolderCreateForFrameworkProps {
  onSuccess: (id: string) => void;
  offerId: string;
  parentId?: string;
}

interface UseOfferFolderCreateForFrameworkParams {
  error?: ApolloError;
  createFolder: (values: BaseValues) => Promise<void>;
}

const useOfferFolderCreateForFramework = ({
  onSuccess,
  offerId,
  parentId,
}: OfferFolderCreateForFrameworkProps): UseOfferFolderCreateForFrameworkParams => {
  const [mutation, { loading, error }] = useMutation<
    CreateFolderForFramework,
    CreateFolderForFrameworkVariables
  >(CREATE_FOLDER_FRAMEWORK);

  const createFolder = async (values: BaseValues) => {
    if (loading) {
      return;
    }

    const { measurements, note } = values;
    const unit = measurements && measurements.length ? measurements[0].type : undefined;

    const castedMeasurements = clearEmptyTotal(cast(measurements));
    const castedNotes = castNote(note);

    const { data } = await mutation({
      variables: {
        input: {
          offer: offerId,
          parent: parentId,
          category: values.category || '',
          approximate: values.approximate,
          name: values.name,
          note: castedNotes,
          unit,
          measurements: castedMeasurements,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    if (!data) {
      return;
    }

    const { createFolderForFramework } = data;

    onSuccess(createFolderForFramework.id);
  };

  return {
    error,
    createFolder,
  };
};

export default useOfferFolderCreateForFramework;
