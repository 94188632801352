import { useEffect } from 'react';
import { useViewer } from '@hs-baumappe/web-auth';
import { I18nextProvider } from 'react-i18next';
import DriveProviders from './DriveProviders';
import i18n from './i18n';
import Routes from './routes';
import { setYupLocale } from './yup';

function App(): JSX.Element {
  const { viewer } = useViewer();

  useEffect(() => {
    if (!viewer) return;

    i18n.changeLanguage(viewer.preferredLanguageCode.toLowerCase());
    setYupLocale();
  }, [viewer]);

  return (
    <DriveProviders>
      <I18nextProvider i18n={i18n}>
        <Routes />
      </I18nextProvider>
    </DriveProviders>
  );
}

export default App;
