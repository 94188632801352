import Cache, { CachedData } from '../Cache';

export default class LocalStorageCache<T> extends Cache<T> {
  getter = (key: string): unknown | undefined => {
    const data = localStorage.getItem(key);

    if (!data) {
      return undefined;
    }

    try {
      return JSON.parse(data);
    } catch (e) {
      return undefined;
    }
  };

  setter = (key: string, value: CachedData<T>): void =>
    localStorage.setItem(key, JSON.stringify(value));

  remover = (key: string): void => localStorage.removeItem(key);
}
