import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Button, Icon } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';
import { constructionNoteEditTitleInitialValues, validationSchema } from './form';
import { ConstructionNoteEditTitleFormValues } from './values';

interface ConstructionNoteEditTitleFormProps {
  initialValues?: Partial<ConstructionNoteEditTitleFormValues>;
  onSubmit: (noteName: ConstructionNoteEditTitleFormValues) => void;
}

export default function ConstructionNoteEditTitleForm({
  initialValues,
  onSubmit,
}: ConstructionNoteEditTitleFormProps): JSX.Element {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: constructionNoteEditTitleInitialValues(initialValues),
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box className="row">
          <Box className="col col--sm-8">
            <Box className="row">
              <Box className="col col--sm-6">
                <FormikFormField
                  name="name"
                  label={t('constructionNote.noteTitleModal.input.title')}
                />
              </Box>
            </Box>
          </Box>

          <Box className="col col--sm-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button type="submit" color="primary" fullWidth endIcon={<Icon name="save" />}>
                {t('constructionNote.noteTitleModal.saveButton')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
