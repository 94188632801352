import { getDocumentRoutes, route } from '../routes.utils';
import routes from '../routes';
import { ConstructionNoteRouteParams } from './ConstructionNote.route.types';

// eslint-disable-next-line import/prefer-default-export
export const constructionNoteRoutes = {
  ...getDocumentRoutes<ConstructionNoteRouteParams>(routes.constructionNote),
  detailFreeDrawing: route<ConstructionNoteRouteParams>(
    `${routes.constructionNote.path}/free-drawing`,
  ),
  detailImage: route<ConstructionNoteRouteParams>(`${routes.constructionNote.path}/image`),
  detailPDF: route<ConstructionNoteRouteParams>(`${routes.constructionNote.path}/pdf`),
  editTitle: route<ConstructionNoteRouteParams>(`${routes.constructionNote.path}/edit-title`),
};
