import { gql } from '@apollo/client';
import QsstFragment from '../../../../../graphql/Qsst.fragment';

export const qsstDeleteUploadedPdfMutation = gql`
  mutation QsstDeleteUploadedPdf($id: ID!) {
    qsstDeleteUploadedPdf(id: $id) {
      ...Qsst
    }
  }
  ${QsstFragment}
`;
