import { fabric } from '@hs-baumappe/fabric';

export const selectAllPaths =
  (canvas: fabric.Canvas) =>
  (event: fabric.IEvent): void => {
    const { target } = event;

    if (!target) {
      return;
    }

    if (target.get('type') === 'path') {
      canvas.discardActiveObject();
      const selection = new fabric.ActiveSelection(canvas.getObjects('path'), {
        canvas,
      });
      canvas.setActiveObject(selection);
      canvas.requestRenderAll();
    }
  };

export const selectAllObjects = (canvas: fabric.Canvas): void => {
  canvas.discardActiveObject();

  const objects = canvas.getObjects();

  if (objects.length === 0) {
    return;
  }

  const selection = new fabric.ActiveSelection(objects, {
    canvas,
  });
  canvas.setActiveObject(selection);
  canvas.requestRenderAll();
};

export const deselectAll = (canvas: fabric.Canvas): void => {
  canvas.discardActiveObject();
  canvas.requestRenderAll();
};

export function getObjectPosition(object: fabric.Object, objects: fabric.Object[]): number {
  return objects.indexOf(object);
}
