import { FC } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconButton, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';

const RedoAction: FC = () => {
  const { redo, canRedo } = useCanvas();

  useHotkeys(
    'ctrl+shift+z, command+shift+z',
    () => {
      redo();
    },
    [redo],
  );

  return (
    <IconButton
      data-testid="redo-action-button"
      icon={<Icon name="redo" />}
      onClick={redo}
      disabled={!canRedo}
    />
  );
};

export default RedoAction;
