import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProjectLocationState } from '../../Project.route.types';
import { projectRoutes } from '../../../routes';
import AddContactPersonForm from './AddContactPersonForm';
import useAddContactPerson from './useAddContactPerson';
import ContactPersonConfirmationModal from '../components/ContactPersonConfirmationModal';
import { ProjectDetailRouteParams } from '../../ProjectDetail/ProjectDetail.route.types';

export default function AddContactPeople(): JSX.Element {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const location = useLocation<ProjectLocationState>();
  const navigate = useNavigate();

  const { addContactPerson } = useAddContactPerson(projectId);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setConfirmationModalVisible(true);
      return;
    }

    setConfirmationModalVisible(false);
    setOpen(false);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(projectRoutes.detail.generatePath({ projectId }));
  }, [navigate, location.state, projectId]);

  return (
    <Dialog
      animationType="slide-up"
      onClose={handleDialogClose}
      onRequestClose={handleDialogRequestClose}
      open={open}
      size="fullscreen"
    >
      <div className="container u-width-100% u-padding-ends-small">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {t('addContactPeople.title')}
          </Text>
          <IconButton
            icon={<Icon name="close" />}
            onClick={handleDialogRequestClose}
            type="button"
          />
        </div>

        <AddContactPersonForm
          onSubmit={addContactPerson}
          submitButtonText={t('addContactPeople.submitButton')}
          onFormDirty={() => {
            setFormDirty(true);
          }}
        />

        <ContactPersonConfirmationModal
          title={t('addContactPeople.confirmationModal.title')}
          body={t('addContactPeople.confirmationModal.body')}
          dismissButtonText={t('addContactPeople.confirmationModal.dismissButton')}
          confirmButtonText={t('addContactPeople.confirmationModal.confirmButton')}
          open={confirmationModalVisible}
          onRequestClose={() => setOpen(false)}
          onDismissButtonClick={() => setConfirmationModalVisible(false)}
        />
      </div>
    </Dialog>
  );
}
