import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CATALOG_OPERATION = gql`
  query GetCatalogOperation($operationId: ID!) {
    operation(id: $operationId) {
      id
      name
      price
      description
      unit
    }
  }
`;
