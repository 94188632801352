import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useObstructionEditDetail from '../../hooks/useObstructionEditDetail';
import useProfessions from '../../../../../hooks/useProfessions';
import ErrorState from '../../../../../containers/ErrorState';
import ObstructionForm from '../../../forms/ObstructionForm';
import { ObstructionProject } from '../../../graphql/__generated__/ObstructionProject.fragment';
import { ObstructionFormValues } from '../../../forms/ObstructionForm/ObstructionForm.form';
import useObstructionSave from '../../../hooks/useObstructionSave';
import { mapObstructionToFormValues } from '../../ObstructionEdit.utils';

interface ObstructionEditBodyProps {
  obstructionId: string;
  project: ObstructionProject;
  formDirty: boolean;
  onFormDirtyStateChange: (dirty: boolean) => void;
  onRequestClose: () => void;
}

export default function ObstructionEditBody({
  obstructionId,
  project,
  formDirty,
  onFormDirtyStateChange,
  onRequestClose,
}: ObstructionEditBodyProps): JSX.Element | null {
  const { t } = useTranslation();

  const { obstruction, loading, error } = useObstructionEditDetail(obstructionId);
  const { professions } = useProfessions();
  const { save, loading: saving, error: saveError } = useObstructionSave(obstructionId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!obstruction) {
    return null;
  }

  function handleFormSubmit(
    values: ObstructionFormValues,
    formikHelpers: FormikHelpers<ObstructionFormValues>,
  ) {
    if (!formDirty) {
      onRequestClose();

      return;
    }

    save(values, formikHelpers);
  }

  return (
    <>
      {saveError && <ErrorState error={saveError} />}

      <ObstructionForm
        initialValues={mapObstructionToFormValues({ obstruction, project })}
        projectId={project.id}
        professions={professions}
        submitButtonText={t('obstructionForm.edit.submitButton')}
        onSubmit={handleFormSubmit}
        onDirtyStateChange={onFormDirtyStateChange}
        loading={saving}
      />
    </>
  );
}
