import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FlexGrid, FlexGridColumn, Icon, Text } from '@hs-baumappe/legacy-ui';
import stylex from '@hs-baumappe/legacy-stylex';
import cx from 'classnames';
import { FormikFormField } from '@hs-baumappe/forms';
import { Form, FormikProvider, useFormik } from 'formik';
import UploadInputGroup from '../../../../components/UploadInputGroup';

export interface QsstUploadManuallySignedDocumentFormValues {
  file?: File;
}

interface QsstUploadManuallySignedDocumentFormProps {
  onSubmit: (values: QsstUploadManuallySignedDocumentFormValues) => void;
  onDirty?: () => void;
  loading?: boolean;
}

export default function QsstUploadManuallySignedDocumentForm({
  onDirty,
  loading,
  onSubmit,
}: QsstUploadManuallySignedDocumentFormProps): JSX.Element {
  const { t } = useTranslation();

  const formik = useFormik<QsstUploadManuallySignedDocumentFormValues>({
    onSubmit,
    initialValues: { file: undefined },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const { values, dirty } = formik;

  useEffect(() => {
    if (dirty && onDirty) {
      onDirty();
    }
  }, [dirty, onDirty]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <FlexGrid justifyContent="space-between">
          <FlexGridColumn sm={6} stylex={{ paddingTop: 'medium' }}>
            <Text stylex={{ marginTop: 'small-x' }}>{t('uploadManuallySignedQsstForm.info')}</Text>

            <FormikFormField
              control={UploadInputGroup}
              name="file"
              className={cx(stylex.create({ marginTop: 'large-x' }))}
              label={t('uploadManuallySignedQsstForm.fileInput.label')}
              placeholder={t('uploadManuallySignedQsstForm.fileInput.placeholder')}
              type="file"
              accept="application/pdf"
            />
          </FlexGridColumn>
          <FlexGridColumn sm={6} md={4}>
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                fullWidth
                endIcon={<Icon name="save" />}
                disabled={!values.file}
                loading={loading}
              >
                {t('uploadManuallySignedQsstForm.submitButtonText')}
              </Button>
            </Box>
          </FlexGridColumn>
        </FlexGrid>
      </Form>
    </FormikProvider>
  );
}
