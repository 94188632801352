import { gql, TypedDocumentNode } from '@apollo/client';
import {
  GenerateDocumentId,
  GenerateDocumentIdVariables,
} from './__generated__/GenerateDocumentId.mutation';

const GenerateDocumentIdMutation: TypedDocumentNode<
  GenerateDocumentId,
  GenerateDocumentIdVariables
> = gql`
  mutation GenerateDocumentId($input: DriveDocumentGenerateIdInput!) {
    driveDocumentGenerateId(input: $input)
  }
`;

export default GenerateDocumentIdMutation;
