import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { Button, Icon, IconButton } from '@hs-baumappe/legacy-ui';
import { NameAutocompleteField, FormikFormField } from '@hs-baumappe/forms';
import { AcceptanceReportFormValues, AttendeeFormValues } from '../AcceptanceReportFormValues';
import AcceptanceReportFormSection from '../components/AcceptanceReportFormSection';
import getAttendeeFullNameLabel, { getAttendeeFullNamePlaceholder } from './AttendeeSection.utils';
import { AcceptanceReportAttendeeRole } from '../../../../../globalTypes';

const initialAttendee: AttendeeFormValues = {
  fullName: '',
  role: AcceptanceReportAttendeeRole.OTHER,
  position: undefined,
};

interface AttendeeSectionProps {
  contactPeople: string[];
  employeeNames: string[];
}

export default function AttendeeSection({
  contactPeople,
  employeeNames,
}: AttendeeSectionProps): JSX.Element {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AcceptanceReportFormValues>();

  function handleAddNewAttendeeClick() {
    setFieldValue('attendees', [...values.attendees, initialAttendee]);
  }

  function handleRemoveAttendeeClick(index: number) {
    const nextAttendees = [...values.attendees];

    nextAttendees.splice(index, 1);

    setFieldValue('attendees', nextAttendees);
  }

  return (
    <AcceptanceReportFormSection title={t('acceptanceReport.attendee.title')}>
      {values.attendees.map((attendee, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="row u-margin-top-small" key={index}>
          <div className="col col--sm-6">
            {index === 0 && (
              <NameAutocompleteField
                name="attendees[0].fullName"
                label={t(getAttendeeFullNameLabel(attendee.role) as ParseKeys<'translation'>)}
                placeholder={t(
                  getAttendeeFullNamePlaceholder(attendee.role) as ParseKeys<'translation'>,
                )}
                names={employeeNames}
              />
            )}
            {index !== 0 && (
              <NameAutocompleteField
                name={`attendees[${index}].fullName`}
                label={t(getAttendeeFullNameLabel(attendee.role) as ParseKeys<'translation'>)}
                placeholder={
                  t(
                    getAttendeeFullNamePlaceholder(attendee.role) as ParseKeys<'translation'>,
                  ) as string
                }
                names={contactPeople}
              />
            )}
          </div>
          <div className="col col--sm-6">
            <div className="u-display-flex u-align-items-center">
              <FormikFormField
                name={`attendees[${index}].position`}
                label={t('acceptanceReport.attendee.position.label')}
                placeholder={t('acceptanceReport.attendee.position.placeholder')}
                className="u-width-100%"
              />

              {values.attendees.length > 3 &&
                attendee.role === AcceptanceReportAttendeeRole.OTHER && (
                  <IconButton
                    icon={<Icon name="close" />}
                    className="u-margin-top u-margin-left-small"
                    size="tiny"
                    onClick={() => handleRemoveAttendeeClick(index)}
                    type="button"
                  />
                )}
            </div>
          </div>
        </div>
      ))}

      <div className="row u-margin-top-small">
        <div className="col col--sm-6">
          <Button
            type="button"
            endIcon={<Icon name="add" />}
            fullWidth
            onClick={handleAddNewAttendeeClick}
          >
            {t('acceptanceReport.attendee.addNewAttendee')}
          </Button>
        </div>
      </div>
    </AcceptanceReportFormSection>
  );
}
