import { gql, TypedDocumentNode } from '@apollo/client';
import { Concern } from './__generated__/Concern.fragment';

const ConcernFragment: TypedDocumentNode<Concern> = gql`
  fragment Concern on Concern {
    id

    name
    status
    pdfPath

    signatures {
      fullName
      imagePath
    }

    viewerCanAssign
    viewerCanUpdate
    viewerCanSign
    viewerCanArchive
    viewerCanRemove
  }
`;
export default ConcernFragment;
