import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { QsstStatus } from '../../../../globalTypes';

interface QsstSendMailModalTitleProps {
  qsstName: string;
  qsstStatus: QsstStatus;
  projectDescription: string;
}

export const QsstSendMailModalTitle: FC<QsstSendMailModalTitleProps> = ({
  qsstName,
  qsstStatus,
  projectDescription,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body-medium" component="div" color="muted">
        {projectDescription}
      </Text>
      <Text variant="title-small" component="h2" className="u-margin-bottom-0">
        {t('qsstSendEmail.title', {
          name: qsstName,
        })}
      </Text>
      <Text
        variant="title-small"
        component="div"
        color={qsstStatus === QsstStatus.SIGNED ? 'success' : 'muted'}
      >
        {t('qsstSendEmail.status', { status: t(qsstStatus) })}
      </Text>
    </>
  );
};
