import { useEffect, useRef } from 'react';

interface UseProjectsInfiniteLoaderOptions {
  loading: boolean;
  nextPageLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
}

const FETCH_NEXT_PAGE_AFTER_PERCENTAGE = 50;

export default function useProjectsInfiniteLoader({
  loading,
  nextPageLoading,
  hasNextPage,
  fetchNextPage,
}: UseProjectsInfiniteLoaderOptions): void {
  const prevScrolledPercentage = useRef(0);
  const containerRef = useRef(document.getElementById('main'));
  const nextPageCanBeLoaded = hasNextPage && !loading && !nextPageLoading;

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !nextPageCanBeLoaded) {
      return;
    }

    const scrollHeight = container.scrollHeight;
    const scrollTop = container.clientHeight + container.scrollTop;

    if (scrollTop === scrollHeight) {
      fetchNextPage();
    }
  }, [fetchNextPage, nextPageCanBeLoaded]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    function handleMainScroll() {
      if (!container || !nextPageCanBeLoaded) {
        return;
      }

      const scrollHeight = container.scrollHeight;
      const scrollTop = container.clientHeight + container.scrollTop;
      const scrolledPercentage = (scrollTop / scrollHeight) * 100;
      const scrollsDown = scrolledPercentage >= prevScrolledPercentage.current;

      if (scrollsDown && scrolledPercentage > FETCH_NEXT_PAGE_AFTER_PERCENTAGE) {
        fetchNextPage();
      }

      prevScrolledPercentage.current = scrolledPercentage;
    }

    container.addEventListener('scroll', handleMainScroll);

    return () => {
      container.removeEventListener('scroll', handleMainScroll);
    };
  }, [fetchNextPage, nextPageCanBeLoaded]);
}
