import { gql, TypedDocumentNode } from '@apollo/client';
import ObstructionFragment from '../../graphql/Obstruction.fragment';
import {
  ObstructionSendEmail,
  ObstructionSendEmailVariables,
} from './__generated__/ObstructionSendEmail.mutation';

const ObstructionSendEmailMutation: TypedDocumentNode<
  ObstructionSendEmail,
  ObstructionSendEmailVariables
> = gql`
  mutation ObstructionSendEmail($input: ObstructionEmailSendInput!) {
    obstructionEmailSend(input: $input) {
      ...Obstruction
    }
  }

  ${ObstructionFragment}
`;

export default ObstructionSendEmailMutation;
