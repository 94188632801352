import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Icon,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '@hs-baumappe/legacy-ui';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';
import { HSProject } from '../../graphql/__generated__/HSProject.fragment';
import { ProjectImportSourceSystem } from '../../../../../globalTypes';
import { DatahubSearchProjectError } from '../../../../../apollo/errors';

import styles from './ProjectImportTable.module.scss';

interface ProjectImportTableProps {
  searchTerm: string;
  projects: HSProject[];
  selectedProjectId?: string;
  loading?: boolean;
  searchError?: DatahubSearchProjectError;
  onProjectClick: (id: string) => void;
  pageLoader?: ReactNode;
}

export default function ProjectImportTable({
  searchTerm,
  projects,
  selectedProjectId,
  onProjectClick,
  loading,
  searchError,
  pageLoader,
}: ProjectImportTableProps): JSX.Element {
  const { t } = useTranslation();
  const { highlightVendocProjects } = useFlags<LaunchDarklyFlagSet>();

  return (
    <Paper stylex={{ overflow: 'hidden' }}>
      <Box className={cx(styles['project-import-table-container'])}>
        <Table>
          <colgroup>
            <col width="1%" />
          </colgroup>
          <TableHead stylex={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
            <TableRow>
              <TableCell component="th" stylex={{ paddingLeft: 'small' }}>
                <Text variant="body-large">{t('hsProjectSearch.projectsTable.projectNumber')}</Text>
              </TableCell>
              <TableCell component="th">
                <Text variant="body-large">{t('hsProjectSearch.projectsTable.name')}</Text>
              </TableCell>
              <TableCell component="th" />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              !searchError &&
              Array.from({ length: 10 }, (_, index) => (
                <TableRow key={index}>
                  <TableCell stylex={{ paddingLeft: 'small' }}>
                    <Skeleton width={130} height={16} radius={5} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={300} height={16} radius={5} />
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}

            {searchTerm.length > 0 && !loading && !searchError && projects.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center" stylex={{ padding: 'large' }}>
                  <Text color="muted" variant="body-medium">
                    {t('hsProjectSearch.projectsTable.noDataMessage')}
                  </Text>
                </TableCell>
              </TableRow>
            )}

            {searchError && projects.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center" stylex={{ padding: 'large' }}>
                  <Stack
                    gap="small-x"
                    alignItems="center"
                    stylex={{ paddingEnds: 'large', paddingSides: 'large-2x' }}
                  >
                    <Icon name="cancel" size={24} color="error" />
                    <Text variant="title-medium" color="error">
                      {t('importHsProjectModal.searchError.title')}
                    </Text>
                    <Text variant="body-medium" color="error">
                      {t('importHsProjectModal.searchError.description')}
                    </Text>
                    <Text variant="body-medium" color="error">
                      {`[${searchError.datahubStatusCode}]`}
                    </Text>
                  </Stack>
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              projects.map((project, index) => {
                const selected = selectedProjectId === project.id;
                const highlighted =
                  project.sourceSystem === ProjectImportSourceSystem.VENDOC &&
                  highlightVendocProjects;

                const textColor = highlighted
                  ? 'red-500'
                  : project.isImported
                    ? 'muted'
                    : undefined;

                return (
                  <TableRow
                    selected={selected}
                    key={`${project.id}-${index}`}
                    onClick={() => onProjectClick(project.id)}
                  >
                    <TableCell stylex={{ paddingLeft: 'small' }}>
                      <Text color={textColor}>{project.projectNumber}</Text>
                    </TableCell>
                    <TableCell>
                      <Text color={textColor}>{project.name}</Text>
                    </TableCell>
                    <TableCell
                      stylex={{
                        paddingSides: 'small',
                        paddingEnds: 'small-4x',
                        textAlign: 'right',
                      }}
                      disablePadding
                    >
                      {selected && (
                        <Icon
                          name="check"
                          color="primary"
                          size={24}
                          stylex={{ verticalAlign: 'middle' }}
                        />
                      )}
                      {!selected && project.isImported && (
                        <Text color={textColor}>
                          {t('hsProjectSearch.projectsTable.alreadyImported')}
                        </Text>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

            {pageLoader}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
