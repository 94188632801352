import { ObstructionEditDetail } from '../ObstructionEdit/graphql/__generated__/ObstructionEditDetail.query';
import { ObstructionFormValues } from '../forms/ObstructionForm/ObstructionForm.form';

type ObstructionFacts = NonNullable<ObstructionEditDetail['obstruction']>['facts'];

export default function castFactsToObstructionFormFactValues(
  facts: ObstructionFacts,
): ObstructionFormValues['facts'] {
  return facts.map((fact) => ({
    title: fact.title,
    description: fact.description,
  }));
}
