import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormField } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import useUpdateProjectDate from './useUpdateProjectDate';
import { ProjectDetailRouteParams } from '../../../ProjectDetail.route.types';

interface ProjectDateProps {
  projectDate?: string;
  disabled?: boolean;
}

export default function ProjectDate({ projectDate, disabled }: ProjectDateProps): JSX.Element {
  const { t } = useTranslation();
  const [date, setDate] = useState<string | undefined>(projectDate);
  const { projectId } = useParams<ProjectDetailRouteParams>();

  const { updateProjectDate } = useUpdateProjectDate({ projectId, date });

  return (
    <FormField<'input'>
      disabled={disabled}
      type="date"
      label={t('projectDetail.projectInformation.contractDate.label')}
      value={date}
      onBlur={() => updateProjectDate()}
      onChange={(event) => setDate(event.currentTarget.value)}
    />
  );
}
