import { TypedDocumentNode, gql } from '@apollo/client';
import { QsstLog } from './__generated__/QsstLog.fragment';

const QsstLogFragment: TypedDocumentNode<QsstLog> = gql`
  fragment QsstLog on QsstLog {
    ... on QsstArchivedLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
    }

    ... on QsstAssignedToProjectLog {
      id
      name
      date

      qsstId

      projectId
      projectName

      actorId
      actorFirstName
      actorLastName
    }

    ... on QsstCreatedLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
    }

    ... on QsstDeletedLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
    }

    ... on QsstDownloadedLog {
      id
      name
      date

      qsstId
    }

    ... on QsstEmailSentLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on QsstEmailSentWithAttachmentLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on QsstExternalLinkExpiredLog {
      id
      name
      date
    }

    ... on QsstNotSignedLog {
      id
      name
      date
      dayCount

      qsstId
    }

    ... on QsstSignedLog {
      id
      name
      date

      qsstId
    }

    ... on QsstSignedOnSideLog {
      id
      name
      date

      qsstId
    }

    ... on QsstUpdatedLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
    }

    ... on QsstUploadedDeletedLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
    }

    ... on QsstUploadedLog {
      id
      name
      date

      qsstId

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default QsstLogFragment;
