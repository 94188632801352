import { gql, TypedDocumentNode } from '@apollo/client';
import { ContactPerson } from './__generated__/ContactPerson.fragment';

const ContactPersonFragment: TypedDocumentNode<ContactPerson> = gql`
  fragment ContactPerson on ContactPerson {
    id
    email
    phone
    name
    note
  }
`;

export default ContactPersonFragment;
