import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { EmailFormValues } from '../../../../../containers/Email';
import createContentHTML from '../../createContentHTML';
import { RiskAssessment } from '../../../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../../../graphql/__generated__/RiskAssessmentProject.fragment';

export function getRiskAssessmentEmailFormInitialValues(
  riskAssessment: RiskAssessment,
  project: RiskAssessmentProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  return {
    subject: t('riskAssessmentSendEmail.form.subject', {
      projectName: project.name,
      riskAssessmentNo: riskAssessment.no,
    }),
    content: createContentHTML(viewer),
    documentIds: [riskAssessment.id],
  };
}
