import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import { QsstDetail } from './QsstDetail/QsstDetail';
import { QsstLocationState, QsstRouteParams } from './Qsst.route.types';
import { QsstSendMail } from './QsstSendMail/QsstSendMail';
import QsstSign from './QsstSign/QsstSign';
import QsstEdit from './QsstEdit/QsstEdit';
import { QsstUploadManuallySignedDocument } from './QsstUploadManuallySignedDocument/QsstUploadManuallySignedDocument';
import { QsstArchive } from './QsstArchive/QsstArchive';
import DocumentDetailLoadingLayout from '../components/DocumentDetailLoadingLayout';
import useQsst from './hooks/useQsst/useQsst';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';

export default function Qsst(): JSX.Element {
  const { qsstId } = useParams<QsstRouteParams>();
  const location = useLocation<QsstLocationState>();
  const backgroundLocation = location.state && location.state.backgroundLocation;
  const { data, loading, error } = useQsst(qsstId);

  useUpdateDriveDocumentLastUsed({
    id: qsstId,
    skip: !data?.qsst,
  });

  if (loading && !data) {
    return <DocumentDetailLoadingLayout />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data?.qsst) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<QsstDetail {...data} />} />
      </Routes>

      <Routes>
        <Route path="edit" element={<QsstEdit {...data} />} />
        <Route path="archive" element={<QsstArchive {...data} />} />
        <Route path="sign" element={<QsstSign />} />
        <Route path="manual-sign" element={<QsstUploadManuallySignedDocument {...data} />} />
        <Route path="send-email" element={<QsstSendMail {...data} />} />
      </Routes>
    </>
  );
}
