import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import useCanvasCalculateIdealZoomValue from '../hooks/canvasCalculateZoomValue';
import CanvasHistory, { HistoryStatus } from '../utils/history';
import stringToBlobGenerator from '../../../utils/stringToBlobGenerator';
import base64ToBlobGenerator from '../utils/base64ToBlobGenerator';

fabric.Object.prototype.set({
  borderColor: '#0089BF',
  cornerColor: '#0089BF',
});

export interface Canvas {
  canvas: fabric.Canvas | undefined;
  initCanvas: (element: HTMLCanvasElement | null, options: fabric.ICanvasOptions) => void;
  loadData: (data: string) => void;
  setReadOnly: (status: boolean) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  zoom: number;
  setZoom: (nextZoom: number) => void;
  objects: fabric.Object[];
  activeObjects: fabric.Object[];
  moveModeEnabled: boolean;
  setMoveModeEnabled: (status: boolean) => void;
  history: CanvasHistory | undefined;
  exportAsJSON: () => Blob | undefined;
  exportAsImage: () => Blob | undefined;
}

interface CanvasProviderProps {
  children: ReactNode;
}

export const CanvasContext = createContext<Canvas>({
  canvas: undefined,
  initCanvas: () => {},
  loadData: () => {},
  setReadOnly: () => {},
  undo: () => {},
  redo: () => {},
  canUndo: true,
  canRedo: true,
  objects: [],
  activeObjects: [],
  zoom: 1,
  setZoom: () => {},
  moveModeEnabled: false,
  setMoveModeEnabled: () => {},
  history: undefined,
  exportAsJSON: () => undefined,
  exportAsImage: () => undefined,
});

export function CanvasProvider({ children }: CanvasProviderProps): JSX.Element {
  const [canvas, setCanvas] = useState<fabric.Canvas | undefined>(undefined);
  const [history, setHistory] = useState<CanvasHistory | undefined>(undefined);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [objects, setObjects] = useState<fabric.Object[]>([]);
  const [activeObjects, setActiveObjects] = useState<fabric.Object[]>([]);
  const [zoom, updateZoom] = useState(1);
  const [moveModeEnabled, setMoveModeEnabled] = useState(false);
  const { calculateIdealZoomValueByIteration } = useCanvasCalculateIdealZoomValue();

  const updateActions = (status: HistoryStatus) => {
    setCanUndo(status.canUndo);
    setCanRedo(status.canRedo);
  };

  const setZoom = (nextZoom: number) => {
    if (!canvas) {
      return;
    }

    const { left, top } = canvas.getCenter();

    canvas.zoomToPoint(new fabric.Point(left, top), nextZoom);
    updateZoom(nextZoom);
  };

  const initCanvas = useCallback(
    (element: HTMLCanvasElement | null, options: fabric.ICanvasOptions) => {
      if (!element) {
        return;
      }

      const instance = new fabric.Canvas(element, options);

      instance.setBackgroundColor('rgb(255, 255, 255)', undefined, { erasable: false });

      setCanvas(instance);
      const historyInstance = new CanvasHistory(instance, 10, updateActions);
      setHistory(historyInstance);
      updateZoom(instance.getZoom());
      setMoveModeEnabled(false);
    },
    [],
  );

  const loadData = useCallback(
    (data: string) => {
      if (!canvas || !history) {
        return;
      }

      canvas.loadFromJSON(data, () => {
        const canvasRelativeCenter = canvas.getCenter();
        const canvasRelativeCenterPoint = new fabric.Point(
          canvasRelativeCenter.left,
          canvasRelativeCenter.top,
        );

        /*
         * TODO: While refactoring Free Drawing Construction note add unmount canvas logics
         *  Navigating between pages without destruct the canvas makes the canvas outdated.
         *  Carries unwanted logic, settings, etc.
         *  This line is temporary. We'll handle that error later.
         *  See: HS-1495 video.
         *  */
        canvas.zoomToPoint(canvasRelativeCenterPoint, 1);

        calculateIdealZoomValueByIteration(canvas.getObjects(), 1, (nextZoom) => {
          canvas.zoomToPoint(canvasRelativeCenterPoint, nextZoom);
        });

        const canvasObject = canvas.getObjects();

        /*
         * Hide the mb and mt controls of Textbox
         * */
        canvasObject
          .filter((object) => object instanceof fabric.Textbox)
          .forEach((textBox) =>
            textBox.setControlsVisibility({
              mb: false,
              mt: false,
            }),
          );

        canvasObject
          .filter((object) => object instanceof fabric.Textbox || object instanceof fabric.Image)
          .forEach((object) => object.set({ erasable: false }));

        setObjects(canvasObject);
        setActiveObjects([]);
        canvas.renderAll();
        history.clear(updateActions);
        updateZoom(canvas.getZoom());
      });
    },
    [canvas, updateZoom, history, setObjects, setActiveObjects, calculateIdealZoomValueByIteration],
  );

  const setReadOnly = useCallback(
    (status: boolean) => {
      if (!canvas) {
        return;
      }

      canvas.selection = !status;
      canvas.hoverCursor = status ? 'default' : 'move';

      objects.forEach((object) => {
        object.set('selectable', !status);
      });
    },
    [canvas, objects],
  );

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const removeEmptyTextBox = () => {
      canvas.getObjects().forEach((object) => {
        if (object instanceof fabric.Textbox) {
          const textEmpty = !object.text || !object.text.trim();

          if (textEmpty) {
            canvas.remove(object);
          }
        }
      });
    };

    canvas.on('after:render', () => {
      setObjects(canvas.getObjects());
      setActiveObjects(canvas.getActiveObjects());
    });

    canvas.on('selection:updated', () => {
      removeEmptyTextBox();
    });

    canvas.on('selection:cleared', () => {
      removeEmptyTextBox();
    });
  }, [canvas]);

  const undo = () => {
    if (!history || !canvas) {
      return;
    }

    if (canvas.getActiveObjects().some((o) => o instanceof fabric.Textbox && o.isEditing)) {
      canvas.discardActiveObject();
    }

    setTimeout(() => {
      history.undo(updateActions);
    });
  };

  const redo = () => {
    if (!history || !canvas) {
      return;
    }

    if (canvas.getActiveObjects().some((o) => o instanceof fabric.Textbox && o.isEditing)) {
      canvas.discardActiveObject();
    }

    setTimeout(() => {
      history.redo(updateActions);
    });
  };

  function exportAsJSON(): Blob | undefined {
    if (!canvas) {
      return undefined;
    }

    const canvasData = JSON.stringify(canvas.toDatalessJSON());
    const file = stringToBlobGenerator(canvasData, 'application/json');

    return file;
  }

  function exportAsImage(): Blob | undefined {
    if (!canvas) {
      return undefined;
    }

    const canvasData = canvas.toDataURL({
      format: 'jpeg',
      quality: 1,
      enableRetinaScaling: true,
      multiplier: 2,
    });

    const file = base64ToBlobGenerator(canvasData, 'image/jpeg');

    return file;
  }

  return (
    <CanvasContext.Provider
      value={{
        canvas,
        initCanvas,
        loadData,
        setReadOnly,
        undo,
        redo,
        canUndo,
        canRedo,
        objects,
        activeObjects,
        zoom,
        setZoom,
        moveModeEnabled,
        setMoveModeEnabled,
        history,
        exportAsJSON,
        exportAsImage,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
}
