import {
  createPath,
  generatePath as generatePathBase,
  Location,
  NavigateFunction,
  To,
} from 'react-router-dom';
import { DocumentRoutesProps, Route } from './routes.types';

export const route = <Params extends { [K in keyof Params]: string } = Record<string, never>>(
  path: string,
): Route<Params> => {
  return {
    path,
    generatePath: (params: Params) => generatePathBase(path, params),
  };
};

export function getDocumentRoutes<
  Params extends { [K in keyof Params]: string } = Record<string, never>,
>(documentBaseRoute: Route<Params>): DocumentRoutesProps<Params> {
  return {
    detail: documentBaseRoute,
    edit: route<Params>(`${documentBaseRoute.path}/edit`),
    sendEmail: route<Params>(`${documentBaseRoute.path}/send-email`),
    sendExternalEmail: route<Params>(`${documentBaseRoute.path}/send-external-email`),
    archive: route<Params>(`${documentBaseRoute.path}/archive`),
    sign: route<Params>(`${documentBaseRoute.path}/sign`),
  };
}

export interface RouteModalLocationState {
  backgroundLocation?: Location;
  returnLocation?: Location;
}

interface NavigateToLocationParams {
  navigate: NavigateFunction;
  state: RouteModalLocationState;
  fallbackPath: To;
}

export function navigateToLocation({
  navigate,
  state,
  fallbackPath,
}: NavigateToLocationParams): void {
  if (state?.returnLocation) {
    return navigate(createPath(state.returnLocation));
  }

  if (state?.backgroundLocation) {
    return navigate(-1);
  }

  navigate(fallbackPath);
}
