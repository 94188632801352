import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';

import { OfferLocationState } from '../../Offer.route.types';
import { OfferFolderOperationCloneRouteParams } from './route';
import useOffer from '../../hooks/offer';
import { castGqlForMeasurement } from '../../folder/containers/FolderForm/form';
import { MeasurementsValues } from '../../measurement/MeasurementsSection/values';
import { OfferMode, OfferType } from '../../../../globalTypes';
import useOfferFolderOperationClone from './useOfferFolderOperationClone';
import ErrorState from '../../../../containers/ErrorState';
import CloneOperationForm from './CloneOperationForm';
import InfoModal from '../../components/InfoModal';
import { offerRoutes } from '../../offerRoutes';

const OfferFolderOperationClone = (): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();

  const { getOperationById, getOffer } = useOffer();

  const offer = getOffer();
  const { offerId, folderId, operationId } = useParams<OfferFolderOperationCloneRouteParams>();

  const { clone, error } = useOfferFolderOperationClone({ offerId, offerMode: offer.mode });

  const [aliasInfoModal, setAliasInfoModal] = useState(false);
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location.state?.backgroundLocation, navigate, offerId]);

  const operation = getOperationById(folderId, operationId);
  if (!operation) {
    return null;
  }

  const { payment, viewerCanClone } = operation;

  if (!viewerCanClone) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
    return null;
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}

      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {t('offer.cloneFolderOperationForm.text', {
              operation: operation.partNoTree.join('.'),
            })}{' '}
            . {operation.name}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          <CloneOperationForm
            offerModeSystemNumber={offer.mode === OfferMode.SYSTEM_NUMBER}
            onClickAliasRightIcon={() => setAliasInfoModal(true)}
            onSubmit={(v) => clone(v, operation)}
            framework={offer.type === OfferType.FRAMEWORK}
            operationUnit={operation.unit}
            initialValues={{
              alias: operation.partNoComputed,
              name: t('offer.cloneFolderOperationForm.text', { operation: operation.name }),
              price: operation.price,
              description: operation.description,
              optional: operation.optional,
              unit: operation.unit,
              note: operation.note || '',
              approximate: operation.approximate,
              payment: payment
                ? {
                    flat: payment.flat,
                    discountOrSurchargeValueType: payment.discountOrSurchargeValueType,
                    discountOrSurchargeValue: payment.discountOrSurchargeValue
                      ? `${payment.discountOrSurchargeValue}`
                      : undefined,
                    totalPrice: payment.totalPrice ? `${payment.totalPrice}` : undefined,
                  }
                : undefined,
              measurements:
                operation.measurements && operation.unit
                  ? (castGqlForMeasurement(
                      operation.measurements,
                      operation.unit,
                    ) as MeasurementsValues)
                  : undefined,
            }}
          />
        </div>
      </div>

      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Dialog>
  );
};

export default OfferFolderOperationClone;
