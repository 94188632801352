import { ObstructionLog } from '../../graphql/__generated__/ObstructionLog.fragment';
import { Log } from '../Log.types';

const OBSTRUCTION_LOG_TYPES = [
  'ObstructionArchivedLog',
  'ObstructionCreatedLog',
  'ObstructionDeletedLog',
  'ObstructionEmailSentLog',
  'ObstructionSignedLog',
  'ObstructionUpdatedLog',
];

export function isObstructionLog(log: Log): log is ObstructionLog {
  return OBSTRUCTION_LOG_TYPES.includes(log.__typename);
}
