import { ParseKeys } from 'i18next';
import { Flex, Icon, IconButton, Tag, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { SearchedProject } from '../__generated__/graphql';
import { ProjectStatus } from '../../../../../globalTypes';

type ProjectSearchMeta = SearchedProject['projects']['meta'];

interface AppliedFiltersProps {
  meta: ProjectSearchMeta;
  profitCenters: string[];
  projectStatuses: ProjectStatus[];
  onProjectStatusRemove: (projectStatus: ProjectStatus) => void;
  onProfitCenterRemove: (id: string) => void;
}

function AppliedFilters({
  meta,
  profitCenters,
  projectStatuses,
  onProjectStatusRemove,
  onProfitCenterRemove,
}: AppliedFiltersProps): JSX.Element {
  const { t } = useTranslation();

  const projectStatusesWithProject = projectStatuses.filter((status) => {
    const projectStatusMeta = meta.selectedFilters.status.find(
      (selectedFilter) => selectedFilter.status === status,
    );

    return projectStatusMeta && projectStatusMeta.count > 0;
  });
  const profitCentersWithProject = meta.selectedFilters.profitCenter.filter(
    (selectedProfitCenter) =>
      selectedProfitCenter.count > 0 &&
      profitCenters.includes(selectedProfitCenter.profitCenter.id),
  );

  return (
    <Flex wrap="wrap" gap="small-3x">
      {projectStatusesWithProject.map((tag) => (
        <Tag
          key={tag}
          color="white"
          rightSection={
            <IconButton
              type="button"
              size="tiny"
              color="inherit"
              aria-label="remove"
              onClick={() => onProjectStatusRemove(tag)}
              icon={<Icon name="close" />}
            />
          }
        >
          <Text color="primary">{t(`projectStatus.${tag}` as ParseKeys<'translation'>)}</Text>
        </Tag>
      ))}

      {profitCentersWithProject.map((tag) => (
        <Tag
          key={tag.profitCenter.id}
          color="white"
          rightSection={
            <IconButton
              type="button"
              size="tiny"
              color="inherit"
              aria-label="remove"
              onClick={() => onProfitCenterRemove(tag.profitCenter.id)}
              icon={<Icon name="close" />}
            />
          }
        >
          <Text color="primary">{tag.profitCenter.name}</Text>
        </Tag>
      ))}
    </Flex>
  );
}

export default AppliedFilters;
