import { useCallback, useEffect, useMemo, useRef } from 'react';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import useCanvasZoom from './useCanvasZoom';
import futureScalingNormalization from '../utils/futureScalingNormalization';
import useCanvas from './useCanvas';
import useNavigator from '../../../hooks/useNavigator/useNavigator';

export enum GestureDirection {
  UP,
  DOWN,
}

const EXPECTED_MIN_SCROLL_DELTA_Y = 0;
const EXPECTED_MAX_SCROLL_DELTA_Y = 250;
const MAX_ZOOM_ON_SCROLL = 2;
const MIN_ZOOM_ON_SCROLL = 0.01;
const ZOOM_ON_SCROLL_THROTTLE = 800;
const EXPECTED_GESTURE_MIN_SCALE = 0.5;
const EXPECTED_GESTURE_MAX_SCALE = 7;
const MIN_ZOOM_ON_SCALE_GESTURE = 0.015;
const MAX_ZOOM_ON_SCALE_GESTURE = 0.5;
const SCALE_GESTURE_DEBOUNCE = 8;

function useCanvasWheelZoom(): void {
  const { canvas, setReadOnly } = useCanvas();
  const { touchScreen } = useNavigator();
  const { zoomOut, zoomIn } = useCanvasZoom();
  const previousScale = useRef(0);

  const handleWindowWheel = useMemo(
    () =>
      throttle((event: WheelEvent) => {
        const scrollDirection = event.deltaY < 0 ? GestureDirection.UP : GestureDirection.DOWN;
        const controlKeyPressing = event.metaKey || event.ctrlKey;

        if (!controlKeyPressing) {
          return;
        }

        event.preventDefault();

        const zoom = futureScalingNormalization(
          Math.abs(event.deltaY),
          EXPECTED_MIN_SCROLL_DELTA_Y,
          EXPECTED_MAX_SCROLL_DELTA_Y,
          MIN_ZOOM_ON_SCROLL,
          MAX_ZOOM_ON_SCROLL,
        );

        if (scrollDirection === GestureDirection.UP) {
          zoomIn(zoom);
        }

        if (scrollDirection === GestureDirection.DOWN) {
          zoomOut(zoom);
        }
      }, ZOOM_ON_SCROLL_THROTTLE),
    [zoomIn, zoomOut],
  );

  /*
   * Gesture Events is not standart events, they work only in Safari's touch pad.
   * To see Zoom (Pinch) gestures for touch screens, see useCanvasGestures
   * */

  const handleGestureChangeEvent = useMemo(
    () =>
      debounce((event: Event) => {
        const { scale } = event as Event & { scale: number };
        let gestureDirection: GestureDirection;

        if (previousScale.current === 0) {
          gestureDirection = scale > 1 ? GestureDirection.UP : GestureDirection.DOWN;
        } else {
          gestureDirection =
            previousScale.current > scale ? GestureDirection.DOWN : GestureDirection.UP;
        }

        event.preventDefault();

        const zoom = futureScalingNormalization(
          Math.abs(scale),
          EXPECTED_GESTURE_MIN_SCALE,
          EXPECTED_GESTURE_MAX_SCALE,
          MIN_ZOOM_ON_SCALE_GESTURE,
          MAX_ZOOM_ON_SCALE_GESTURE,
        );

        if (gestureDirection === GestureDirection.UP) {
          zoomIn(zoom);
        }

        if (gestureDirection === GestureDirection.DOWN) {
          zoomOut(zoom);
        }

        previousScale.current = scale;
      }, SCALE_GESTURE_DEBOUNCE),
    [zoomIn, zoomOut, previousScale],
  );

  const handleGestureStart = useCallback(
    (event: Event) => {
      if (!canvas) {
        return;
      }

      event.preventDefault();

      setReadOnly(true);
    },
    [canvas, setReadOnly],
  );

  const handleGestureEnd = useCallback(
    (event: Event) => {
      if (!canvas) {
        return;
      }

      event.preventDefault();
    },
    [canvas],
  );

  const handleOtherGestureEvents = useCallback((event: Event) => {
    event.preventDefault();
  }, []);

  const handleOtherWheelEvents = useCallback((event: WheelEvent) => {
    const controlKeyPressing = event.ctrlKey || event.metaKey;

    if (controlKeyPressing) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('wheel', handleWindowWheel, { passive: false });
    window.addEventListener('wheel', handleOtherWheelEvents, { passive: false });

    if (!touchScreen) {
      window.addEventListener('gesturestart', handleGestureStart);
      window.addEventListener('gestureend', handleGestureEnd);
      window.addEventListener('gesturechange', handleGestureChangeEvent);
      window.addEventListener('gesturechange', handleOtherGestureEvents);
    }

    return () => {
      window.removeEventListener('wheel', handleWindowWheel);
      window.removeEventListener('wheel', handleOtherWheelEvents);

      if (!touchScreen) {
        window.removeEventListener('gesturestart', handleGestureStart);
        window.removeEventListener('gestureend', handleGestureEnd);
        window.removeEventListener('gesturechange', handleGestureChangeEvent);
        window.removeEventListener('gesturechange', handleOtherGestureEvents);
      }
    };
  }, [
    handleWindowWheel,
    handleOtherWheelEvents,
    handleOtherGestureEvents,
    handleGestureChangeEvent,
    handleGestureStart,
    handleGestureEnd,
    touchScreen,
  ]);
}

export default useCanvasWheelZoom;
