import { createPath } from 'react-router-dom';
import { DocumentType, RiskAssessmentStatus } from '../../../globalTypes';
import { projectRoutes } from '../../routes';
import { RiskAssessment } from '../graphql/__generated__/RiskAssessment.fragment';
import { riskAssessmentRoutes } from '../riskAssessmentRoutes';

export enum RiskAssessmentDetailSearchParams {
  ALERT = 'alert',
}

export enum RiskAssessmentDetailAlert {
  ARCHIVED = 'archived',
  SEND = 'send',
}

// eslint-disable-next-line import/prefer-default-export
export function getRiskAssessmentStatus(status: RiskAssessmentStatus): 'muted' | 'success' {
  switch (status) {
    case RiskAssessmentStatus.SIGNED:
    case RiskAssessmentStatus.ARCHIVED:
      return 'success';
    default:
      return 'muted';
  }
}

export function createDocumentAssignPath(
  riskAssessment: RiskAssessment,
  projectId: string,
): string {
  const searchParams = new URLSearchParams({
    document: DocumentType.RISK_ASSESSMENT,
    returnPath: riskAssessmentRoutes.detail.generatePath({
      riskAssessmentId: riskAssessment.id,
    }),
  });

  return createPath({
    pathname: projectRoutes.assignDocumentToProject.generatePath({
      projectId,
      documentId: riskAssessment.id,
    }),
    search: searchParams.toString(),
  });
}
