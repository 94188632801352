import { route } from '../routes.utils';
import {
  TimeTrackingWithTimeRouteParams,
  TimeTrackingWithProjectRouteParams,
  TimeTrackingProjectOrientedRouteParams,
} from './timeTracking.route.types';
import routes from '../routes';

export const digitalSalaryRoutes = {
  detail: route<TimeTrackingWithTimeRouteParams>(
    `${routes.timeTracking.path}/digital-salary/:year/:week`,
  ),
  detailWithoutTime: route(`${routes.timeTracking.path}/digital-salary`),
};

export const projectOrientedRoutes = {
  detail: route<TimeTrackingProjectOrientedRouteParams>(
    `${routes.timeTracking.path}/:projectId/:year/:week`,
  ),
  detailWithoutTime: route<TimeTrackingWithProjectRouteParams>(
    `${routes.timeTracking.path}/:projectId`,
  ),
};

export default {
  digitalSalary: digitalSalaryRoutes,
  projectOriented: projectOrientedRoutes,
};
