import { useTranslation } from 'react-i18next';
import { Details, Divider, Summary, Text } from '@hs-baumappe/legacy-ui';

import DocumentLogsListDetail from './DocumentLogsListDetail';

interface DocumentLogListProps {
  documentId: string;
}

export default function DocumentLogList({ documentId }: DocumentLogListProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Details
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="label-large">{t('documentLogsDetail.title')}</Text>
        </Summary>
      )}
    >
      <Divider role="none" />
      <DocumentLogsListDetail documentId={documentId} />
    </Details>
  );
}
