import { useEffect, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import useCanvas from '../../../hooks/useCanvas';
import useTextBoxFontWeight, { UseTextBoxFontWeight } from './useTextBoxFontWeight';
import useTextBoxFontStyle, { UseTextBoxFontStyle } from './useTextBoxFontStyle';
import useTextBoxDecoration, { UseTextBoxDecoration } from './useTextBoxFontDecoration';

interface UseTextBoxFormatters
  extends UseTextBoxFontWeight,
    UseTextBoxFontStyle,
    UseTextBoxDecoration {}

export default function useTextBoxFormatters(): UseTextBoxFormatters {
  const { canvas } = useCanvas();
  const [textBox, setTextBox] = useState<fabric.Textbox | undefined>(undefined);
  const { isBold, setFontWeight } = useTextBoxFontWeight({
    textBox,
    onRequestRenderCanvas: () => canvas && canvas.requestRenderAll(),
  });
  const { isItalic, setFontStyle } = useTextBoxFontStyle({
    textBox,
    onRequestRenderCanvas: () => canvas && canvas.requestRenderAll(),
  });
  const { isUnderline, setFontDecoration } = useTextBoxDecoration({
    textBox,
    onRequestRenderCanvas: () => canvas && canvas.requestRenderAll(),
  });

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleCanvasSelectionEvents = () => {
      const activeObject = canvas.getActiveObject();

      if (activeObject instanceof fabric.Textbox) {
        setTextBox(activeObject);
      } else {
        setTextBox(undefined);
      }
    };

    canvas.on('selection:created', handleCanvasSelectionEvents);
    canvas.on('selection:cleared', handleCanvasSelectionEvents);
    canvas.on('selection:updated', handleCanvasSelectionEvents);

    // eslint-disable-next-line consistent-return
    return () => {
      if (!canvas) {
        return;
      }

      canvas.off('selection:created', handleCanvasSelectionEvents);
      canvas.off('selection:cleared', handleCanvasSelectionEvents);
      canvas.off('selection:updated', handleCanvasSelectionEvents);
    };
  }, [canvas]);

  return {
    isBold,
    setFontWeight,
    isItalic,
    setFontStyle,
    isUnderline,
    setFontDecoration,
  };
}
