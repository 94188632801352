import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import ErrorState from '../../../../../containers/ErrorState';
import LogContainer from '../../../../../containers/LogContainer';
import logsDateMapper from '../../../../../containers/LogContainer/LogContainer.utils';
import ConcernLogsQuery from '../../graphql/ConcernLogs.query';
import { ConcernLogs } from '../../graphql/__generated__/ConcernLogs.query';

type ConcernLog = NonNullable<NonNullable<ConcernLogs['concern']>['logs']>['logs'];

interface OvertimeLogsProps {
  concernId: string;
}

export default function ConcernLogsDetail({ concernId }: OvertimeLogsProps): JSX.Element {
  const [logs, setLogs] = useState<ConcernLog>([]);
  const { loading, error, data, refetch } = useQuery(ConcernLogsQuery, {
    variables: {
      concernId,
      page: 1,
      limit: 10,
    },
  });

  useEffect(() => {
    if (!data || !data.concern?.logs) {
      return;
    }

    const logsData = data.concern.logs.logs;

    if (!logsData.length) {
      setLogs([]);
      return;
    }

    if (!logs.length) {
      setLogs([...data.concern.logs.logs]);
      return;
    }

    const currentPage = data.concern.logs.pagination.current;
    const result = currentPage > 1 ? logs : [];

    logsData.forEach((log) => {
      if (!result.find((f) => f.id === log.id)) {
        result.push(log);
      }
    });

    setLogs(Array.from(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function handleLoadMoreClick() {
    if (!data?.concern?.logs?.logs) {
      return;
    }
    await refetch({
      page: data.concern.logs.pagination.current + 1,
      limit: 10,
    });
  }

  const logsDateData = logsDateMapper(logs);
  const hasNextPage = !loading && !!logs && data?.concern?.logs?.pagination.hasNextPage;

  if (error) {
    return <ErrorState error={error} />;
  }

  return (
    <LogContainer
      loading={loading}
      logs={logsDateData}
      hasNextPage={hasNextPage}
      onMoreDataClick={handleLoadMoreClick}
    />
  );
}
