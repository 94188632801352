import { useEffect, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import { Icon, IconButton } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';

export default function RotateAction(): JSX.Element {
  const { canvas, activeObjects } = useCanvas();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (activeObjects.length === 0) {
      setDisabled(true);
      return;
    }

    const textboxAsActiveObject = activeObjects.find(
      (object) => object instanceof fabric.Textbox,
    ) as fabric.Textbox | undefined;

    if (textboxAsActiveObject && textboxAsActiveObject.isEditing) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [activeObjects]);

  const handleRotate = () => {
    if (!canvas) {
      return;
    }

    canvas.getActiveObjects().forEach((object) => {
      const currentAngle = object.angle as number;
      const nextAngle = (currentAngle + 90) % 360;

      object.rotate(nextAngle);
      canvas.renderAll();
    });

    canvas.fire('object:rotated');
  };

  return (
    <IconButton
      icon={<Icon name="rotate-90-degrees-ccw" />}
      onClick={handleRotate}
      disabled={disabled}
    />
  );
}
