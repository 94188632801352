import { useCallback, useEffect, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import useCanvas from './useCanvas';

export default function useUserCanSave(): boolean {
  const [userCanSave, setUserCanSave] = useState(false);
  const { canvas } = useCanvas();

  const controlUserCanSaveState = useCallback(() => {
    if (!canvas) {
      return;
    }

    const activeObject = canvas.getActiveObject();
    const objectCount = canvas.getObjects().length;

    if (activeObject instanceof fabric.Textbox) {
      if (activeObject.isEditing) {
        setUserCanSave(false);
        return;
      }

      const textBoxValue = activeObject.text || '';
      const textBoxIsEmpty = textBoxValue.trim() === '';

      if (objectCount === 1 && textBoxIsEmpty) {
        setUserCanSave(false);
        return;
      }
    }

    setUserCanSave(canvas.getObjects().length > 0);
  }, [canvas, setUserCanSave]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    // Initial check
    controlUserCanSaveState();

    canvas.on('object:added', controlUserCanSaveState);
    canvas.on('object:removed', controlUserCanSaveState);
    canvas.on('text:editing:entered', controlUserCanSaveState);
    canvas.on('text:editing:exited', controlUserCanSaveState);
    canvas.on('selection:updated', controlUserCanSaveState);
    canvas.on('selection:created', controlUserCanSaveState);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.off('object:added', controlUserCanSaveState);
      canvas.off('object:removed', controlUserCanSaveState);
      canvas.off('text:editing:entered', controlUserCanSaveState);
      canvas.off('text:editing:exited', controlUserCanSaveState);
      canvas.off('selection:updated', controlUserCanSaveState);
      canvas.off('selection:created', controlUserCanSaveState);
    };
  }, [canvas, controlUserCanSaveState]);

  return userCanSave;
}
