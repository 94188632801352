import { FC, ReactNode } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';
import { Smiley0 } from './smileys/Smiley0';
import { Smiley1 } from './smileys/Smiley1';
import { Smiley2 } from './smileys/Smiley2';
import { Smiley3 } from './smileys/Smiley3';
import { Smiley4 } from './smileys/Smiley4';
import styles from './smileyBox.module.scss';

const smileyDic = {
  0: <Smiley0 />,
  1: <Smiley1 />,
  2: <Smiley2 />,
  3: <Smiley3 />,
  4: <Smiley4 />,
};

interface CompProps {
  score: 0 | 1 | 2 | 3 | 4;
  label: ReactNode;
}

const SmileyBox: FC<CompProps> = ({ score, label }) => {
  return (
    <div className={styles.container} style={{ gridRow: 1, gridColumn: score + 2 }}>
      {smileyDic[score]}

      <Text variant="body-small" color="gray-500" className={styles.caption}>
        {label}
      </Text>
    </div>
  );
};

export { SmileyBox };
