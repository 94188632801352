import { ComponentProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Link, Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import { assertUnknownLog, getActor } from '../Log.utils';
import { DriveFolderLog as DriveFolderLogType } from '../../graphql/__generated__/DriveFolderLog.fragment';
import { projectRoutes } from '../../../../routes/routes';
import { ProjectDetailLocationState } from '../../../../routes/Project/ProjectDetail/ProjectDetail.route.types';

interface DriveFolderLogProps extends ComponentProps<typeof Text> {
  log: DriveFolderLogType;
}

export default function DriveFolderLog({ log, ...props }: DriveFolderLogProps): JSX.Element {
  switch (log.__typename) {
    case 'DriveFolderCreatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.driveFolderCreated"
            values={{ folderName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              folderLink: (
                <Link
                  component={RouterLink}
                  variant="label-medium"
                  to={projectRoutes.driveWithFolder.generatePath({
                    projectId: log.projectId,
                    folderId: log.driveFolderId,
                  })}
                  state={{ scrollToDrive: true } as ProjectDetailLocationState}
                />
              ),
            }}
          />
        </Text>
      );

    case 'DriveFolderDeletedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.driveFolderDeleted"
            values={{ folderName: log.name }}
            components={{ actor: <LogActor actor={getActor(log)} /> }}
          />
        </Text>
      );
    case 'DriveFolderUpdatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.driveFolderUpdated"
            values={{ folderOldName: log.oldName, folderName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              folderLink: (
                <Link
                  component={RouterLink}
                  variant="label-medium"
                  to={projectRoutes.driveWithFolder.generatePath({
                    projectId: log.projectId,
                    folderId: log.driveFolderId,
                  })}
                  state={{ scrollToDrive: true } as ProjectDetailLocationState}
                />
              ),
            }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
