import { createPath, useNavigate } from 'react-router-dom';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import RemoveDocument from '../../../Project/document/RemoveDocument';
import { projectRoutes } from '../../../routes';
import { GetConstructionNoteDetail } from '../../graphql/__generated__/GetConstructionNoteDetail.query';
import { DocumentType } from '../../../../globalTypes';

type ConstructionNote = GetConstructionNoteDetail['constructionNote'];

interface RemoveFreeDrawingConstructionNoteActionProps {
  constructionNote: ConstructionNote;
  projectId: string;
}

export default function RemoveFreeDrawingConstructionNoteAction({
  constructionNote,
  projectId,
}: RemoveFreeDrawingConstructionNoteActionProps): JSX.Element {
  const navigate = useNavigate();

  function handleConstructionNoteRemove() {
    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId,
        }),
        search: `?removedDocumentName=${constructionNote.name}`,
      }),
    );
  }

  return (
    <RemoveDocument
      document={{
        name: constructionNote.name,
        type: DocumentType.CONSTRUCTION_NOTE,
        id: constructionNote.id,
      }}
      onRemoveSuccess={handleConstructionNoteRemove}
      renderTrigger={({ onClick, loading }) => (
        <Button color="error" contentClassName="u-display-flex" onClick={onClick} loading={loading}>
          <Icon name="delete" size={24} />
        </Button>
      )}
    />
  );
}
