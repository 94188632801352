import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import DriveDocumentNoteContainer from '../../../../../components/DriveDocumentNoteContainer';
import { AcceptanceReportScopes } from '../../../AcceptanceReport.types';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';

interface AcceptanceReportDetailSidebarTopProps {
  acceptanceReport: AcceptanceReport;
  scopes: AcceptanceReportScopes;
  onEditButtonClick: () => void;
  onSendButtonClick: () => void;
  onDownloadButtonClick: () => void;
}

export default function AcceptanceReportDetailSidebarTop({
  acceptanceReport,
  scopes,
  onEditButtonClick,
  onSendButtonClick,
  onDownloadButtonClick,
}: AcceptanceReportDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateAcceptanceReportDocument.isPermitted;
  const sendPermitted = scopes.sendAcceptanceReportDocumentAttachment.isPermitted;
  const downloadPermitted = scopes.downloadAcceptanceReportDocument.isPermitted;

  return (
    <Stack gap="small-3x">
      <DriveDocumentNoteContainer id={acceptanceReport.id} name={acceptanceReport.name}>
        {updatePermitted && (
          <Button
            className="qa-acceptance-report-edit"
            endIcon={<Icon name="create" />}
            onClick={onEditButtonClick}
          >
            {t('acceptanceReport.editButton')}
          </Button>
        )}

        {sendPermitted && (
          <Button
            className="qa-acceptance-report-send-email"
            endIcon={<Icon name="email" />}
            onClick={onSendButtonClick}
          >
            {t('acceptanceReport.sendPDFButton')}
          </Button>
        )}

        {downloadPermitted && (
          <Button
            className="qa-acceptance-report-download"
            endIcon={<Icon name="cloud-download" />}
            onClick={onDownloadButtonClick}
          >
            {t('acceptanceReport.downloadPDFButton')}
          </Button>
        )}
      </DriveDocumentNoteContainer>
    </Stack>
  );
}
