import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UpdateDriveDocumentLastUsed,
  UpdateDriveDocumentLastUsedVariables,
} from './__generated__/UpdateDriveDocumentLastUsedMutation';

const UpdateDriveDocumentLastUsedMutation: TypedDocumentNode<
  UpdateDriveDocumentLastUsed,
  UpdateDriveDocumentLastUsedVariables
> = gql`
  mutation UpdateDriveDocumentLastUsed($input: [DriveDocumentLastUsedUpdateInput!]!) {
    driveDocumentsLastUsedUpdate(input: $input) {
      id
    }
  }
`;

export default UpdateDriveDocumentLastUsedMutation;
