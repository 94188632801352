import { ComponentProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Link, Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import LogEmails from '../LogEmails';
import { assertUnknownLog, getActor } from '../Log.utils';
import { CustomFileLog as CustomFileLogType } from '../../graphql/__generated__/CustomFileLog.fragment';
import { projectRoutes } from '../../../../routes/routes';
import { ProjectDetailLocationState } from '../../../../routes/Project/ProjectDetail/ProjectDetail.route.types';

interface CustomFileLogProps extends ComponentProps<typeof Text> {
  log: CustomFileLogType;
}

export default function CustomFileLog({ log, ...props }: CustomFileLogProps): JSX.Element {
  switch (log.__typename) {
    case 'CustomFileArchivedLog': {
      if (log.folderId) {
        return (
          <Text component="div" variant="body-small" {...props}>
            <Trans
              i18nKey="logs.customFileArchivedLog"
              values={{ customFileName: log.name, folderName: log.folderName }}
              components={{
                actor: <LogActor actor={getActor(log)} />,
                bold: <Text variant="label-medium" />,
                folderLink: (
                  <Link
                    component={RouterLink}
                    variant="label-medium"
                    to={projectRoutes.driveWithFolder.generatePath({
                      projectId: log.projectId,
                      folderId: log.folderId,
                    })}
                    state={{ scrollToDrive: true } as ProjectDetailLocationState}
                  />
                ),
              }}
            />
          </Text>
        );
      }

      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileArchivedLogNotFolder"
            values={{ customFileName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />{' '}
        </Text>
      );
    }
    case 'CustomFileCreatedLog': {
      if (log.folderId) {
        return (
          <Text component="div" variant="body-small" {...props}>
            <Trans
              i18nKey="logs.customFileCreatedLog"
              values={{ customFileName: log.name, folderName: log.folderName }}
              context={log.source}
              components={{
                actor: <LogActor actor={getActor(log)} />,
                bold: <Text variant="label-medium" />,
                folderLink: (
                  <Link
                    component={RouterLink}
                    variant="label-medium"
                    to={projectRoutes.driveWithFolder.generatePath({
                      projectId: log.projectId,
                      folderId: log.folderId,
                    })}
                    state={{ scrollToDrive: true } as ProjectDetailLocationState}
                  />
                ),
              }}
            />{' '}
          </Text>
        );
      }

      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileCreatedLogNoFolder"
            values={{ customFileName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    }
    case 'CustomFileDeletedLog': {
      if (log.folderId) {
        return (
          <Text component="div" variant="body-small" {...props}>
            <Trans
              i18nKey="logs.customFileDeletedLog"
              values={{ customFileName: log.name, folderName: log.folderName }}
              components={{
                actor: <LogActor actor={getActor(log)} />,
                folderLink: (
                  <Link
                    component={RouterLink}
                    variant="label-medium"
                    to={projectRoutes.driveWithFolder.generatePath({
                      projectId: log.projectId,
                      folderId: log.folderId,
                    })}
                    state={{ scrollToDrive: true } as ProjectDetailLocationState}
                  />
                ),
              }}
            />
          </Text>
        );
      }

      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileDeletedLogNotFolder"
            values={{ customFileName: log.name }}
            components={{ actor: <LogActor actor={getActor(log)} /> }}
          />
        </Text>
      );
    }
    case 'CustomFileDuplicatedForManipulationLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileDuplicatedForManipulationLog"
            values={{ customFileName: log.name, folderName: log.definedFolderName }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              folderLink: (
                <Link
                  component={RouterLink}
                  variant="label-medium"
                  to={projectRoutes.driveWithFolder.generatePath({
                    projectId: log.projectId,
                    folderId: log.definedFolderId,
                  })}
                  state={{ scrollToDrive: true } as ProjectDetailLocationState}
                />
              ),
            }}
          />
        </Text>
      );
    case 'CustomFileEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileEmailSentLog"
            values={{ customFileName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'CustomFileManipulatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileManipulatedLog"
            values={{ customFileName: log.name, folderName: log.definedFolderName }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              folderLink: (
                <Link
                  component={RouterLink}
                  variant="label-medium"
                  to={projectRoutes.driveWithFolder.generatePath({
                    projectId: log.projectId,
                    folderId: log.definedFolderId,
                  })}
                  state={{ scrollToDrive: true } as ProjectDetailLocationState}
                />
              ),
            }}
          />
        </Text>
      );
    case 'CustomFileRenamedLog': {
      if (log.folderId) {
        return (
          <Text component="div" variant="body-small" {...props}>
            <Trans
              i18nKey="logs.customFileRenameLog"
              values={{
                customFileOldName: log.oldName,
                customFile: log.name,
                folderName: log.folderName,
              }}
              components={{
                actor: <LogActor actor={getActor(log)} />,
                bold: <Text variant="label-medium" />,
                folderLink: (
                  <Link
                    component={RouterLink}
                    variant="label-medium"
                    to={projectRoutes.driveWithFolder.generatePath({
                      projectId: log.projectId,
                      folderId: log.folderId,
                    })}
                    state={{ scrollToDrive: true } as ProjectDetailLocationState}
                  />
                ),
              }}
            />
          </Text>
        );
      }
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.customFileRenameLogNotFolder"
            values={{
              customFileOldName: log.oldName,
              customFile: log.name,
            }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    }
    default:
      return assertUnknownLog(log);
  }
}
