import { fabric } from '@hs-baumappe/fabric';
import createTextBox from './createTextBox';

export interface CreateStandardTextBoxParameters {
  top?: number;
  left?: number;
}

function createStandardTextBox({ top, left }: CreateStandardTextBoxParameters): fabric.Textbox {
  return createTextBox({
    emptyLineCount: 0,
    textBoxOptions: {
      width: 200,
      top,
      left,
    },
  });
}

export default createStandardTextBox;
