import { ComponentProps, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Icon, IconButton, IconProps, Popover } from '@hs-baumappe/legacy-ui';

import './c-note-editor-text-format-picker.scss';

interface NoteEditorTextFormatPickerAction {
  iconName: IconProps['name'];
  pressed?: boolean;
  onClick?: ComponentProps<'button'>['onClick'];
  label: string;
  'data-testid'?: string;
}

export interface NoteEditorTextFormatPickerProps {
  className?: string;
  actions: Array<NoteEditorTextFormatPickerAction>;
  open?: boolean;
  renderTrigger: ComponentProps<typeof Popover>['renderTrigger'];
  actionExtractor?: (action: NoteEditorTextFormatPickerAction, index: number) => string;
}

export default function NoteEditorTextFormatPicker({
  className,
  renderTrigger,
  open,
  actions,
  actionExtractor = (action) => `action-${action.label}`,
}: PropsWithChildren<NoteEditorTextFormatPickerProps>): JSX.Element {
  return (
    <Popover
      open={open}
      className={cx('c-note-editor-text-format-picker', className)}
      contentClassName="c-note-editor-text-format-picker__content"
      placement="left-start"
      renderTrigger={renderTrigger}
      content={
        <div className="c-note-editor-text-format-picker__actions">
          {actions.map((action, index) => (
            <IconButton
              shape="rounded"
              icon={<Icon name={action.iconName} />}
              pressed={action.pressed}
              onClick={action.onClick}
              data-testid={action['data-testid']}
              aria-label={action.label}
              key={actionExtractor(action, index)}
            />
          ))}
        </div>
      }
    />
  );
}
