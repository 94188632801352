import { ProjectStatus } from '../../../../../../globalTypes';

export interface ProjectFilterFormPreset {
  label: string;
  statuses: ProjectStatus[];
}

export interface ProjectStatusFilter {
  value: ProjectStatus;
  checked: boolean;
}

export interface ProjectProfitCenterFilter {
  label: string;
  value: string;
  checked: boolean;
}

export interface ProjectFilterFormValues {
  statuses: ProjectStatusFilter[];
  profitCenters: ProjectProfitCenterFilter[];
}

export function projectFilterFormInitialValues(
  initialValues?: Partial<ProjectFilterFormValues>,
): ProjectFilterFormValues {
  return {
    statuses: [],
    profitCenters: [],
    ...initialValues,
  };
}
