import { Flex } from '@hs-baumappe/legacy-ui';
import NoPreviewAvailable from '../NoPreviewAvailable';
import PDFViewer from '../../../../../components/PDFViewer';
import { isDocumentImage, isDocumentPDF } from '../../../../../utils/mimeTypes';

interface DriveDocumentPreviewProps {
  mimeType?: string;
  url?: string;
  downloadUrl?: string;
  downloadName?: string;
  viewerCanDownload?: boolean;
}

export default function DriveDocumentPreviewer({
  mimeType,
  url,
  downloadUrl,
  downloadName,
  viewerCanDownload,
}: DriveDocumentPreviewProps): JSX.Element {
  if (!url || !mimeType) {
    return <NoPreviewAvailable downloadUrl={downloadUrl} downloadName={downloadName} />;
  }

  if (isDocumentPDF(mimeType)) {
    return <PDFViewer document={url} downloadPermitted={!!viewerCanDownload} key={url} />;
  }

  if (isDocumentImage(mimeType)) {
    return (
      <Flex justifyContent="center">
        <img
          src={url}
          alt=""
          className="u-img-fluid u-pointer-events-none"
          data-testid="image-viewer"
        />
      </Flex>
    );
  }

  return <NoPreviewAvailable downloadUrl={downloadUrl} downloadName={downloadName} />;
}
