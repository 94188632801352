import { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';
import { Box, Checkbox, FormHelperText, Stack, Text, Textarea } from '@hs-baumappe/legacy-ui';
import { ConcernFormValues, Misgivings } from '../../ConcernForm.types';

export default function ContractorsFormSection(): JSX.Element {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext<ConcernFormValues>();

  const hasContractors = values.misgivings.includes(Misgivings.CONTRACTORS);

  function handleContractorToggle(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.currentTarget;

    const misgivings = new Set(values.misgivings);
    if (checked) {
      misgivings.add(Misgivings.CONTRACTORS);
    } else {
      misgivings.delete(Misgivings.CONTRACTORS);
    }

    setFieldValue('misgivings', Array.from(misgivings));
    setFieldValue('contractorsName', '');
    setFieldValue('contractorsDescription', '');
  }

  return (
    <Box stylex={{ marginTop: 'medium' }}>
      <Stack gap="small-3x">
        <div>
          <Checkbox
            id="concern-contractors"
            label={
              <Trans
                i18nKey="concern.create.form.contractors.label"
                components={[<strong key="concern-create-form-contractors-label-strong" />]}
              />
            }
            variant="filled"
            value={Misgivings.CONTRACTORS}
            checked={hasContractors}
            onChange={handleContractorToggle}
            disableExtraPressableArea
          />
        </div>

        {errors.misgivings && <FormHelperText error>{errors.misgivings}</FormHelperText>}
      </Stack>

      {hasContractors && (
        <Stack gap="small-x" stylex={{ marginLeft: 'large', marginTop: 'small-x' }}>
          <FormikFormField
            name="contractorsName"
            className="u-flex-basis-100%"
            label={t('concern.create.form.contractors.name.label')}
            placeholder={t('concern.create.form.contractors.name.placeholder')}
          />

          <Text component="div" color="gray-700">
            {t('concern.create.form.contractors.info')}
          </Text>

          <FormikFormField<typeof Textarea>
            control={Textarea}
            name="contractorsDescription"
            label={t('concern.create.form.contractors.description.label')}
            placeholder={t('concern.create.form.contractors.description.placeholder')}
            minRows={4}
            maxRows={12}
          />
        </Stack>
      )}
    </Box>
  );
}
