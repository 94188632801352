import { ApolloError, useQuery } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import ConcernDetailQuery from './graphql/ConcernDetail.query';
import { Concern } from '../../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../../graphql/__generated__/ConcernProject.fragment';
import { ConcernScopes } from '../../Concern.types';

interface UseConcernDataResponse {
  concern: Concern;
  project: ConcernProject;
  scopes: ConcernScopes;
}

interface UseConcernResponse {
  data?: UseConcernDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useConcern(concernId: string): UseConcernResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(ConcernDetailQuery, {
    variables: {
      concernId,
    },
  });

  const concern = data?.concern;
  const project = data?.concern?.project;
  const scopes = viewer?.scopes;

  return {
    data: concern && project && scopes ? { concern, project, scopes } : undefined,
    loading,
    error,
  };
}
