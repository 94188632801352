import { ChangeEvent, FC, useEffect } from 'react';
import { Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, Icon, NativeSelect, Text } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import useForm from '../../../../hooks/useForm';
import { OfferType } from '../../../../globalTypes';
import UploadInputGroup from '../../../../components/UploadInputGroup';
import { CreateNewOfferGetCatalogues } from '../__generated__/graphql';
import { CreateNewOfferFormValues } from './values';
import { defaultValues, validationSchema } from './form';

type Catalogues = CreateNewOfferGetCatalogues['catalogues'];

export interface CreateNewOfferFormProps {
  onSubmit: (values: CreateNewOfferFormValues) => void;
  initialValues?: Partial<CreateNewOfferFormValues>;
  catalogues?: Catalogues;
  catalogueId?: string;
  loading: boolean;
  onFileUpload: (file: File | null) => void;
  draft: boolean;
  viewerCanAddOfferOperationFromTLK: boolean;
}

const CreateNewOfferForm: FC<CreateNewOfferFormProps> = ({
  onSubmit,
  catalogues = [],
  initialValues,
  catalogueId,
  loading,
  onFileUpload,
  draft,
  viewerCanAddOfferOperationFromTLK,
}) => {
  const { t } = useTranslation();
  const { formik } = useForm({
    initialValues: defaultValues(initialValues),
    validationSchema: validationSchema(catalogues),
    onSubmit,
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue('catalogueId', catalogueId);
  }, [catalogueId, setFieldValue]);

  const showCatalogueSelect = values.type === OfferType.FRAMEWORK;

  const catalogueOptions: Array<{ label: string; value: string }> = catalogues.map((catalogue) => ({
    label: catalogue.name,
    value: catalogue.id,
  }));

  function handleCatalogueFileChange({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) {
    const file = files && files[0];

    if (!file) {
      return;
    }

    onFileUpload(file);
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="row">
          <div className="col col--sm-8">
            <div className="row">
              <div className="col col--sm-6">
                <FormikFormField<'input'>
                  name="name"
                  label={t('createOfferModal.name.label')}
                  className="u-margin-bottom-xsmall"
                  placeholder={t('createOfferModal.name.placeholder')}
                />
              </div>
            </div>
            {draft && (
              <>
                <hr />
                <Text variant="label-medium" color="muted" className="u-margin-top-large">
                  {t('createOfferModal.client.title')}
                </Text>
                <div className="row u-margin-top">
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="client.name"
                      label={t('createOfferModal.client.name.label')}
                      placeholder={t('createOfferModal.client.name.placeholder')}
                    />
                  </div>
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="client.address"
                      label={t('createOfferModal.client.adress.label')}
                      placeholder={t('createOfferModal.client.adress.placeholder')}
                    />
                  </div>
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="client.zip"
                      label={t('createOfferModal.client.zip.label')}
                      placeholder={t('createOfferModal.client.zip.placeholder')}
                    />
                  </div>
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="client.city"
                      label={t('createOfferModal.client.city.label')}
                      placeholder={t('createOfferModal.client.city.placeholder')}
                    />
                  </div>
                </div>

                <Text variant="label-medium" color="muted" className="u-margin-top-large">
                  {t('createOfferModal.constructionSite.title')}
                </Text>

                <div className="row u-margin-top">
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="constructionSite.name"
                      label={t('createOfferModal.constructionSite.name.label')}
                      placeholder={t('createOfferModal.constructionSite.name.placeholder')}
                    />
                  </div>
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="constructionSite.address"
                      label={t('createOfferModal.constructionSite.adress.label')}
                      placeholder={t('createOfferModal.constructionSite.adress.placeholder')}
                    />
                  </div>
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="constructionSite.zip"
                      label={t('createOfferModal.constructionSite.zip.label')}
                      placeholder={t('createOfferModal.constructionSite.zip.placeholder')}
                    />
                  </div>
                  <div className="col col--sm-6 u-margin-bottom-xsmall">
                    <FormikFormField
                      name="constructionSite.city"
                      label={t('createOfferModal.constructionSite.city.label')}
                      placeholder={t('createOfferModal.constructionSite.city.placeholder')}
                    />
                  </div>
                </div>

                <hr />
              </>
            )}

            <Text variant="label-medium" color="muted" className="u-margin-top-large">
              {t('createOfferModal.offer.title')}
            </Text>

            <div className="row">
              <div className="col col--sm-6">
                <Text component="p">{t('createOfferModal.offer.description')}</Text>
                <Text component="p">{t('createOfferModal.offer.subdescription')}</Text>
              </div>
            </div>

            <div className="row">
              <div className="col col--sm-6 u-margin-bottom-xsmall u-margin-top">
                <FormikFormField
                  control={NativeSelect}
                  name="type"
                  label={t('createOfferModal.type.label')}
                  onChange={() => {
                    setFieldValue('catalogueFile', undefined);
                  }}
                >
                  <option value={OfferType.FREE_FORM}>
                    {t('createOfferModal.select.freeform')}
                  </option>
                  {viewerCanAddOfferOperationFromTLK && (
                    <option value={OfferType.FRAMEWORK}>
                      {t('createOfferModal.select.framework')}
                    </option>
                  )}
                </FormikFormField>
              </div>
              {showCatalogueSelect && (
                <div className="col col--sm-6 u-margin-bottom-xsmall u-margin-top">
                  {catalogues && catalogues.length > 0 ? (
                    <FormikFormField
                      control={NativeSelect}
                      name="catalogueId"
                      label={t('createOfferModal.catalogueUpload.label')}
                    >
                      <option value="">{t('createOfferModal.catalogue.placeholder')}</option>
                      {catalogueOptions.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikFormField>
                  ) : (
                    <FormikFormField
                      type="file"
                      control={UploadInputGroup}
                      name="catalogueFile"
                      label={t('createOfferModal.catalogueUpload.label')}
                      id="upload-catalog"
                      placeholder={t('createOfferModal.catalogueUpload.placeholder')}
                      onChange={handleCatalogueFileChange}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col col--sm-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                color="primary"
                type="submit"
                disabled={loading}
                endIcon={<Icon name="add" />}
                fullWidth
              >
                {t('createOfferModal.submitButton')}
              </Button>
            </Box>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default CreateNewOfferForm;
