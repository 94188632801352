import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_FOLDER_FREEFORM = gql`
  mutation CreateFolderForFreeform($input: CreateFolderForFreeFormInput!) {
    createFolderForFreeForm(input: $input) {
      id
    }
  }
`;
