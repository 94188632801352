import { QueryResult, useQuery } from '@apollo/client';

import {
  CreateNewOfferGetCatalogues,
  CreateNewOfferGetCataloguesVariables,
} from './__generated__/graphql';
import { GET_CATALOGUES } from './graphql';

const useGetCataglogues = (
  id: string,
): QueryResult<CreateNewOfferGetCatalogues, CreateNewOfferGetCataloguesVariables> => {
  return useQuery<CreateNewOfferGetCatalogues, CreateNewOfferGetCataloguesVariables>(
    GET_CATALOGUES,
    {
      variables: {
        id,
      },
    },
  );
};

export default useGetCataglogues;
