import { lazy, Suspense, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorBoundary from '../../../ErrorBoundary';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';

interface CreateSIVBillingAttachmentProps {
  documentId: string;
}

const SIVBillingAttachmentCreate = lazy(
  // eslint-disable-next-line import/no-unresolved
  () => import('sivBillingAttachmentWeb/SIVBillingAttachmentCreate'),
);

function CreateSIVBillingAttachment({ documentId }: CreateSIVBillingAttachmentProps): JSX.Element {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const handleDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('createSivBillingAttachment.title')}
        </Text>
      }
    >
      <ErrorBoundary fallback={<Alert color="error">{t('sivBillingAttachmentMFLoadError')}</Alert>}>
        <Suspense fallback={<LayoutLoading />}>
          <SIVBillingAttachmentCreate
            projectId={projectId}
            documentId={documentId}
            onDirtyStateChange={setFormDirty}
          />
        </Suspense>
      </ErrorBoundary>

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('createSivBillingAttachment.closeDialog.title')}
        description={<Text>{t('createSivBillingAttachment.closeDialog.content')}</Text>}
        confirmButtonText={t('createSivBillingAttachment.closeDialog.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('createSivBillingAttachment.closeDialog.cancelButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateSIVBillingAttachment, {
  mimeType: DriveSpecialDocumentMimeTypes.SIV_BILLING_ATTACHMENT,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
