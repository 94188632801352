import { RefObject, useCallback, useMemo, useRef } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import debounce from 'lodash.debounce';
import useGestures from './useGestures/useGestures';
import useCanvas from './useCanvas';
import useCanvasZoom from './useCanvasZoom';
import futureScalingNormalization from '../utils/futureScalingNormalization';
import { GestureDirection } from './useCanvasWheelZoom';
import Point from './useGestures/Point/Point';
import useSelectedTool from './useSelectedTool';

const SCALE_GESTURE_DEBOUNCE = 8;
const EXPECTED_PINCH_MIN_SCALE = 0.1;
const EXPECTED_PINCH_MAX_SCALE = 8;
const MIN_ZOOM_ON_SCALE_PINCH = 0.015;
const MAX_ZOOM_ON_SCALE_PINCH = 1;

function useCanvasGestures(element: RefObject<HTMLDivElement>): void {
  const { canvas, setMoveModeEnabled } = useCanvas();
  const { setSelectedTool } = useSelectedTool();
  const { zoomIn, zoomOut } = useCanvasZoom();
  const clientLastTouchPoint = useRef(new Point(0, 0));
  const previousScale = useRef(0);

  const handleOnPinch = useMemo(
    () =>
      debounce((event: TouchEvent, scale: number) => {
        let gestureDirection: GestureDirection;

        if (previousScale.current === 0) {
          gestureDirection = scale > 1 ? GestureDirection.UP : GestureDirection.DOWN;
        } else {
          gestureDirection =
            previousScale.current > scale ? GestureDirection.DOWN : GestureDirection.UP;
        }

        const zoom = futureScalingNormalization(
          Math.abs(scale),
          EXPECTED_PINCH_MIN_SCALE,
          EXPECTED_PINCH_MAX_SCALE,
          MIN_ZOOM_ON_SCALE_PINCH,
          MAX_ZOOM_ON_SCALE_PINCH,
        );

        if (gestureDirection === GestureDirection.UP) {
          zoomIn(zoom);
        }

        if (gestureDirection === GestureDirection.DOWN) {
          zoomOut(zoom);
        }

        previousScale.current = scale;
        clientLastTouchPoint.current = new Point(
          event.touches[0].clientX,
          event.touches[0].clientY,
        );
      }, SCALE_GESTURE_DEBOUNCE),
    [previousScale, zoomIn, zoomOut],
  );

  const handleOnPan = useCallback(
    (event: TouchEvent) => {
      if (!canvas) {
        return;
      }

      const { touches } = event;
      const touch = touches[0];
      const { clientX, clientY } = touch;
      const delta = new Point(0, 0);
      const { x, y } = clientLastTouchPoint.current;

      delta.x = clientX - x;
      delta.y = clientY - y;

      requestAnimationFrame(() => {
        canvas.relativePan(new fabric.Point(delta.x, delta.y));
        clientLastTouchPoint.current = new Point(clientX, clientY);
      });
    },
    [canvas, clientLastTouchPoint],
  );

  const handleOnTouchStart = useCallback(
    (event: TouchEvent) => {
      if (!canvas) {
        return;
      }

      const { touches } = event;
      const touch = touches[0];

      clientLastTouchPoint.current = new Point(touch.clientX, touch.clientY);

      canvas.selection = false;
      canvas.discardActiveObject();

      setSelectedTool(undefined);
      setMoveModeEnabled(true);
    },
    [canvas, clientLastTouchPoint, setSelectedTool, setMoveModeEnabled],
  );

  const handleOnTouchEnd = useCallback(() => {
    if (!canvas) {
      return;
    }

    canvas.selection = true;

    setMoveModeEnabled(false);
  }, [canvas, setMoveModeEnabled]);

  useGestures({
    element,
    onPinch: handleOnPinch,
    onPan: handleOnPan,
    onTouchStart: handleOnTouchStart,
    onTouchEnd: handleOnTouchEnd,
  });
}

export default useCanvasGestures;
