import { gql } from '@apollo/client';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import { FormikProvider } from 'formik';
import { FC } from 'react';
import useForm from '../../../../../hooks/useForm';
import { Comments } from './Comments/Comments';
import { Details, detailsFragments } from './Details/Details';
import { Intro } from './Intro/Intro';
import {
  initialQsstSignFormValues,
  QsstSignFormValues,
  qsstSignFormValuesValidationSchema,
} from './qsstSignFormValues';
import { RecommendationScore } from './RecommendationScore/RecommendationScore';
import { SpecificScore } from './SpecificScore/SpecificScore';
import { useGetHandleSubmit } from './useGetHandleSubmit/useGetHandleSubmit';
import { QsstSignFormQsstForExternalFragment } from './__generated__/QsstSignForm';
import { useTranslation } from 'react-i18next';
import Signature from './Signature';

export const qsstSignFormFragments = {
  qsstForExternal: gql`
    fragment QsstSignFormQsstForExternalFragment on QsstForExternal {
      id

      ...QsstDetailsQsstForExternalFragment
    }
    ${detailsFragments.qsstForExternal}
  `,
};

interface QsstSignFormProps {
  qsstId: string;
  externalAuthToken?: string;
  qsstForExternal: QsstSignFormQsstForExternalFragment;
  onSuccess?: () => void;
}

const QsstSignForm: FC<QsstSignFormProps> = ({
  qsstId,
  externalAuthToken,
  qsstForExternal,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const { handleSubmit, loading } = useGetHandleSubmit({
    documentId: qsstId,
    externalAuthToken,
    onSuccess,
  });

  const { formik } = useForm<QsstSignFormValues>({
    onSubmit: handleSubmit,
    initialValues: initialQsstSignFormValues,
    validationSchema: qsstSignFormValuesValidationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          {/* project details */}
          <Details qsstForExternal={qsstForExternal} />

          {/* intro */}
          <Intro />

          {/* specific score */}
          <div className="u-margin-top-large">
            <SpecificScore />
          </div>

          <hr />

          {/* recommendation score */}
          <RecommendationScore />

          <hr />

          {/* comments */}
          <Comments />

          <hr />

          {/* signature */}
          <Signature />

          {/* submit button */}
          <div className="row u-margin-top-small">
            <div className="col col--sm-6 col--offset-sm-6">
              <Button
                color="primary"
                type="submit"
                loading={loading}
                endIcon={<Icon name="check" />}
                fullWidth
              >
                {t('qsstSign.form.submitButton')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export { QsstSignForm };
