import { gql, TypedDocumentNode } from '@apollo/client';
import SIVDiscountSIVFragment from '../../../graphql/SIVDiscountSIV.fragment';
import {
  SIVDiscountSetDiscountAmount,
  SIVDiscountSetDiscountAmountVariables,
} from './__generated__/SIVDiscountSetDiscountAmountMutation';

const SIVDiscountSetDiscountAmountMutation: TypedDocumentNode<
  SIVDiscountSetDiscountAmount,
  SIVDiscountSetDiscountAmountVariables
> = gql`
  mutation SIVDiscountSetDiscountAmount($input: SIVDiscountUpdateInput!) {
    sivDiscountUpdate(input: $input) {
      ...SIVDiscountSIV
    }
  }

  ${SIVDiscountSIVFragment}
`;

export default SIVDiscountSetDiscountAmountMutation;
