import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { AlertDialog, Box, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useCreateRiskAssessmentDetail from './hooks/useRiskAssessmentCreateDetail';
import useRiskAssessmentCreate from './hooks/useRiskAssessmentCreate';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import RiskAssessmentForm from '../RiskAssessmentForm';
import { ProjectDetailRouteParams } from '../../Project/ProjectDetail/ProjectDetail.route.types';
import { RiskAssessmentTab } from '../RiskAssessmentForm/useRiskAssessmentForm';
import { DriveSpecialDocumentMimeTypes } from '../../Project/drive/drive.types';
import { formatDateToDateString } from '../../../utils/date';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../../Project/document/CreateDocumentErrorContainer';

interface CreateRiskAssessmentProps {
  documentId: string;
}

function CreateRiskAssessment({ documentId }: CreateRiskAssessmentProps): JSX.Element {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<RiskAssessmentTab>(RiskAssessmentTab.Information);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { project, employees, regionalSecurityManagers, loading, error } =
    useCreateRiskAssessmentDetail(projectId);
  const { create, error: saveError } = useRiskAssessmentCreate(documentId);

  const handleDialogRequestClose = useCallback(() => {
    if (activeStep !== RiskAssessmentTab.Information || formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }, [activeStep, formDirty]);

  const handleDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  function renderContent() {
    if (loading) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!project) {
      return null;
    }

    return (
      <RiskAssessmentForm
        employees={employees}
        regionalSecurityManagers={regionalSecurityManagers}
        informationInitialValues={{
          date: formatDateToDateString(new Date()),
          projectNr: project.hsProjectNumber || '-',
          customerName: project.client?.name || '-',
        }}
        onStepChange={setActiveStep}
        onSubmit={(values) => create(values, project.draft)}
        onDirtyStateChange={setFormDirty}
        draft={project.draft}
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onClose={handleDialogClose}
      onRequestClose={handleDialogRequestClose}
      header={
        <>
          {project && (
            <Text variant="body-medium" component="div" color="muted">
              {project.description}
              {project.additionalName && ' | ' + project.additionalName}
            </Text>
          )}
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('createAndUpdateRiskAssessmentModal.createTitle')}
          </Text>
        </>
      }
    >
      {saveError && <ErrorState error={saveError} />}

      <Box>{renderContent()}</Box>

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('createAndUpdateRiskAssessmentModal.closeModal.title')}
        description={t('createAndUpdateRiskAssessmentModal.closeModal.description')}
        confirmButtonText={t('createAndUpdateRiskAssessmentModal.closeModal.confirmButton')}
        cancelButtonText={t('createAndUpdateRiskAssessmentModal.closeModal.cancelButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={handleConfirmationDialogConfirm}
        destructive
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateRiskAssessment, {
  mimeType: DriveSpecialDocumentMimeTypes.RISK_ASSESSMENT,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
