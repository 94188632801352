import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  AlertDialog,
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Icon,
  Stack,
  Text,
} from '@hs-baumappe/legacy-ui';
import QualityCard from './QualityCard';
import { ConcernFormValues, Misgivings, Quality } from '../../ConcernForm.types';
import { uuidv4 } from './QualityFormSection.utils';

export default function QualityFormSection(): JSX.Element {
  const { t } = useTranslation();

  const { values, errors, setFieldValue } = useFormikContext<ConcernFormValues>();

  const [removeQuality, setRemoveQuality] = useState<string | undefined>(undefined);

  const hasQuality = values.misgivings.includes(Misgivings.QUALITY);
  const { qualities } = values;

  function handleQualityToggle(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.currentTarget;

    const misgivings = new Set(values.misgivings);
    if (checked) {
      misgivings.add(Misgivings.QUALITY);
      setFieldValue('qualities', [{ id: uuidv4(), name: '', description: '' }]);
    } else {
      misgivings.delete(Misgivings.QUALITY);
      setFieldValue('qualities', []);
    }

    setFieldValue('misgivings', Array.from(misgivings));
  }

  function handleQualityAddClick() {
    if (!values.qualities?.length) {
      return;
    }

    setFieldValue('qualities', [...values.qualities, { id: uuidv4(), name: '', description: '' }]);
  }

  function handleQualityRemoveClick(quality: Quality) {
    if (!qualities || qualities.length <= 1) {
      return;
    }

    setRemoveQuality(quality.id);
  }

  function handleConfirmRemoveQuality() {
    if (!qualities?.length || !removeQuality) {
      return;
    }

    const remainingQualities = qualities.filter((quality) => quality.id !== removeQuality);
    setFieldValue('qualities', remainingQualities);
    setRemoveQuality(undefined);
  }

  return (
    <Box stylex={{ marginTop: 'medium' }}>
      <Stack gap="small-3x">
        <div>
          <Checkbox
            id="concern-quality"
            label={
              <Trans
                i18nKey="concern.create.form.quality.label"
                components={[<strong key="concern-create-form-quality-label" />]}
              />
            }
            variant="filled"
            value={Misgivings.QUALITY}
            checked={hasQuality}
            onChange={handleQualityToggle}
            disableExtraPressableArea
          />
        </div>

        {errors.misgivings && <FormHelperText error>{errors.misgivings}</FormHelperText>}
      </Stack>

      {hasQuality && (
        <Box stylex={{ marginLeft: 'large', marginTop: 'small-x' }}>
          <Text component="div" color="gray-700">
            {t('concern.create.form.quality.info')}
          </Text>

          {qualities?.length &&
            qualities.map((quality, index) => {
              return (
                <QualityCard
                  key={quality.id}
                  name={`qualities[${index}]`}
                  onRemoveClick={() => handleQualityRemoveClick(quality)}
                />
              );
            })}

          <Box className="row" stylex={{ marginTop: 'small' }}>
            <Box className="col col--sm-6">
              <Button
                type="button"
                endIcon={<Icon name="add" />}
                onClick={handleQualityAddClick}
                fullWidth
              >
                {t('concern.create.form.quality.addNewQuality')}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <AlertDialog
        open={!!removeQuality}
        title={t('concern.create.form.cancelModal.quality.title')}
        description={t('concern.create.form.cancelModal.quality.info')}
        cancelButtonText={t('concern.create.form.cancelModal.quality.dismissButton')}
        confirmButtonText={t('concern.create.form.cancelModal.quality.cancelButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setRemoveQuality(undefined)}
        onCancel={() => setRemoveQuality(undefined)}
        onConfirm={handleConfirmRemoveQuality}
        destructive
      />
    </Box>
  );
}
