import { Location } from 'react-router-dom';
import { AcceptanceReportLocationState } from './AcceptanceReport.route.types';
import { AcceptanceReport } from './graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportSignatureType, AcceptanceReportStatus } from '../../globalTypes';

interface GetacceptanceReportMetaResponse {
  signedManually: boolean;
  signedByEmployee: boolean;
  signedByCustomer: boolean;
  signable: boolean;
}

const SIGNABLE_STATUSES = [AcceptanceReportStatus.OPEN, AcceptanceReportStatus.SENT];

function hasSignatureInType(
  signatures: AcceptanceReport['signatures'],
  type: AcceptanceReportSignatureType,
) {
  return signatures.some((signature) => signature.type === type);
}

export function getAcceptanceReportMeta(
  acceptanceReport: AcceptanceReport,
): GetacceptanceReportMetaResponse {
  const signedManually = !!acceptanceReport.manualPdfPath;
  const signedByEmployee = hasSignatureInType(
    acceptanceReport.signatures,
    AcceptanceReportSignatureType.HEINRICH_SCHMID,
  );
  const signedByCustomer = hasSignatureInType(
    acceptanceReport.signatures,
    AcceptanceReportSignatureType.CUSTOMER,
  );
  const signable = !signedManually && SIGNABLE_STATUSES.includes(acceptanceReport.status);

  return {
    signedManually,
    signedByEmployee,
    signedByCustomer,
    signable,
  };
}

export function createAcceptanceReportLocation(location: Location): AcceptanceReportLocationState {
  return { backgroundLocation: { ...location, search: '' } };
}
