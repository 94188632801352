import { Route, Routes, useLocation } from 'react-router-dom';
import Detail from './Detail';
import { ProjectsRouteLocationState } from './Projects.routes';
import ProjectImportModal from './ProjectImportModal';
import SAPTransmitModal from './SAPTransmitModal';

const Projects = (): JSX.Element => {
  const location = useLocation<ProjectsRouteLocationState>();
  const backgroundLocation = location.state?.backgroundLocation;

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<Detail />} />
      </Routes>
      <Routes>
        <Route path="import" element={<ProjectImportModal />} />
        <Route path="sap-transmit" element={<SAPTransmitModal />} />
      </Routes>
    </>
  );
};

export default Projects;
