import {
  Box,
  Container,
  Flex,
  FlexGrid,
  FlexGridColumn,
  Skeleton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import cx from 'classnames';
import stylex from '@hs-baumappe/legacy-stylex';
import { useTranslation } from 'react-i18next';
import { ProjectSortingField, SortingDirection } from '../../../../globalTypes';

import routes from '../../../routes';
import ProjectsTableDecider from '../ProjectsTableDecider';

interface ProjectsLoadingLayoutProps {
  sortingField: ProjectSortingField;
  sortingDirection: SortingDirection;
  onlyFollowed: boolean;
  viewSIVOverviewPermitted: boolean;
  viewSIVPricesPermitted: boolean;
  showSIVTransmitButton: boolean;
  accessSIVPerformanceRecordingPermitted: boolean;
}

export default function ProjectsLoadingLayout({
  sortingField,
  sortingDirection,
  onlyFollowed,
  viewSIVOverviewPermitted,
  viewSIVPricesPermitted,
  accessSIVPerformanceRecordingPermitted,
  showSIVTransmitButton,
}: ProjectsLoadingLayoutProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <Container stylex={{ paddingEnds: 'large' }}>
      <AppHeader
        title={
          <Text component="h1" variant="body-large" color="muted" stylex={{ marginBottom: '0' }}>
            {t('projectListPage.pageTitle')}
          </Text>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />

      <FlexGrid stylex={{ marginTop: 'large-2x', rowGap: 'small-3x' }}>
        <FlexGridColumn sm={6} lg={4}>
          <Skeleton width="100%" height={40} radius={8} />
        </FlexGridColumn>

        {showSIVTransmitButton && (
          <FlexGridColumn sm={6} lg={{ size: 4, offset: 'auto' }}>
            <Skeleton width="100%" height={40} radius={8} />
          </FlexGridColumn>
        )}
      </FlexGrid>

      <Box stylex={{ marginTop: 'medium' }}>
        <Skeleton width={200} height={18} radius={6} />
        <Skeleton
          width="100%"
          height={48}
          radius={8}
          className={cx(stylex.create({ marginTop: 'small-3x' }))}
        />
      </Box>

      <FlexGrid>
        <FlexGridColumn sm={4} stylex={{ marginTop: 'small-3x' }}>
          <Skeleton width="100%" height={40} radius={8} />
        </FlexGridColumn>
      </FlexGrid>

      <Flex justifyContent="flex-end" stylex={{ marginTop: 'small-3x', marginBottom: 'small-x' }}>
        <Skeleton width={260} height={24} radius={8} />
      </Flex>

      <Box stylex={{ marginTop: 'small-3x' }}>
        <ProjectsTableDecider
          projects={[]}
          sorting={{ field: sortingField, direction: sortingDirection }}
          loading={true}
          nextPageLoading={false}
          highlightVendocProjects={false}
          showsFollowedProjects={onlyFollowed}
          viewSIVOverviewPermitted={viewSIVOverviewPermitted}
          viewSIVPricesPermitted={viewSIVPricesPermitted}
          accessSIVPerformanceRecordingPermitted={accessSIVPerformanceRecordingPermitted}
          onSortingChange={() => undefined}
          onProjectFollowButtonClick={() => undefined}
        />
      </Box>
    </Container>
  );
}
