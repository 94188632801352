import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { RiskAssessmentStatus } from '../../../../../globalTypes';
import routes from '../../../../routes';
import { RiskAssessmentProject } from '../../../graphql/__generated__/RiskAssessmentProject.fragment';
import { getRiskAssessmentStatus } from '../../RiskAssessmentDetail.utils';
import { DriveDocumentParentFolders } from '../../../../../hooks/useDriveDocumentParentFolders';
import DocumentDetailBreadcrumbs from '../../../../../components/DocumentDetailBreadcrumbs';

interface RiskAssessmentDetailHeaderProps {
  name: string;
  status: RiskAssessmentStatus;
  project: RiskAssessmentProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function RiskAssessmentDetailHeader({
  name,
  status,
  project,
  parentFolders,
}: RiskAssessmentDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={
          <Stack>
            <Text component="h1" color="muted" variant="body-large" stylex={{ marginBottom: '0' }}>
              {name}
            </Text>
            <Text color={getRiskAssessmentStatus(status)}>
              {t('riskAssessmentDetail.status', { status: t(status) })}
            </Text>
          </Stack>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
