import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import QsstForm from '../../../../../components/QsstSave/QsstForm/QsstForm';
import { QsstFormValues } from '../../../../../components/QsstSave/QsstForm/QsstForm.form';
import useQsstSave from '../../../hooks/useSaveQsst';
import useQsstEditDetail from '../../hooks/useQsstEditDetail';
import ErrorState from '../../../../../containers/ErrorState';
import { mapQsstToQsstFormValues } from './QsstEditBody.utils';

interface QsstEditBodyProps {
  qsstId: string;
  projectId: string;
  formDirty: boolean;
  setFormDirty: (dirty: boolean) => void;
  onRequestClose: () => void;
}

export default function QsstEditBody({
  qsstId,
  projectId,
  formDirty,
  setFormDirty,
  onRequestClose,
}: QsstEditBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { qsst, project, loading, error } = useQsstEditDetail(qsstId);
  const { save, loading: saving, error: saveError } = useQsstSave(qsstId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!qsst || !project || !viewer) {
    return null;
  }

  function handleFormSubmit(values: QsstFormValues, formikHelpers: FormikHelpers<QsstFormValues>) {
    if (!formDirty) {
      onRequestClose();
      return;
    }

    save(values, formikHelpers);
  }

  return (
    <>
      {saveError && <ErrorState error={saveError} />}

      <QsstForm
        projectId={projectId}
        onSubmit={handleFormSubmit}
        onDirtyStateChange={setFormDirty}
        submitButtonText={t('qsstForm.edit.submitButton')}
        loading={saving}
        initialValues={mapQsstToQsstFormValues({ qsst, project, viewer, t })}
        submitButtonIconName="save"
      />
    </>
  );
}
