import { QsstSaveInput } from '../../../../globalTypes';
import { QsstFormValues } from '../../../../components/QsstSave/QsstForm/QsstForm.form';

export function createQsstSaveInput({
  formValues,
  qsstId,
}: {
  formValues: QsstFormValues;
  qsstId: string;
}): QsstSaveInput {
  return {
    id: qsstId,
    name: formValues.name,
    clientName: formValues.clientName,
    contractDate: formValues.contractDate || null,
    onsideEmployee: formValues.onsideEmployee,
  };
}
