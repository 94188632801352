import { FC, useCallback } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { Button } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import RemoveDocument from '../../../../../Project/document/RemoveDocument';
import { DocumentType } from '../../../../../../globalTypes';
import { projectRoutes } from '../../../../../routes';

interface DeleteQsstButtonProps {
  qsstId: string;
  qsstName: string;
  projectId: string;
}

export const DeleteQsstButton: FC<DeleteQsstButtonProps> = ({ qsstId, qsstName, projectId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRemoveSuccess = useCallback(() => {
    const searchParams = new URLSearchParams({ removedDocumentName: qsstName });

    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId: projectId,
        }),
        search: searchParams.toString(),
      }),
    );
  }, [navigate, projectId, qsstName]);

  return (
    <RemoveDocument
      document={{
        name: qsstName,
        type: DocumentType.QSST,
        id: qsstId,
      }}
      onRemoveSuccess={handleRemoveSuccess}
      renderTrigger={(triggerProps) => (
        <Button variant="text" color="error" {...triggerProps}>
          {t('qsst.removeButton')}
        </Button>
      )}
    />
  );
};
