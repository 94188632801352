import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useAcceptanceReportSignDetail from '../../hooks/useAcceptanceReportSignDetail';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';
import useAcceptanceReportSign from '../../hooks/useAcceptanceReportSign';
import ErrorState from '../../../../../containers/ErrorState';
import SignatureForm from '../../../../../components/SignatureForm';
import { AcceptanceReportSignatureType } from '../../../../../globalTypes';

interface AcceptanceReportSignBodyProps {
  acceptanceReport: AcceptanceReport;
  onFormDirty: () => void;
}

export default function AcceptanceReportSignBody({
  acceptanceReport,
  onFormDirty,
}: AcceptanceReportSignBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const {
    employeeNames,
    clientAttendee,
    employeeSignature,
    customerSignature,
    viewerSignatureBase64,
    loading,
    error,
  } = useAcceptanceReportSignDetail(acceptanceReport.id);
  const { sign, loading: signing, error: signError } = useAcceptanceReportSign(acceptanceReport.id);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!viewer) {
    return null;
  }

  return (
    <>
      {signError && <ErrorState error={signError} />}

      <Tabs
        defaultSelectedTabId={
          !!employeeSignature
            ? AcceptanceReportSignatureType.CUSTOMER
            : AcceptanceReportSignatureType.HEINRICH_SCHMID
        }
      >
        <Tab
          id={AcceptanceReportSignatureType.HEINRICH_SCHMID}
          label={t('acceptanceReportSignForm.tabs.hsEmployeeTabTitle')}
          panel={
            <Box stylex={{ marginTop: 'small-x' }}>
              <SignatureForm
                initialValues={{
                  name:
                    employeeSignature?.fullName || `${viewer.firstName} ${viewer.lastName}` || '',
                  signature: employeeSignature?.imagePath ?? '',
                }}
                signatureInputLabel={t('signature.title')}
                submitButtonText={t('acceptanceReportSignForm.submitButtonText')}
                lastSignature={viewerSignatureBase64}
                readOnly={!!employeeSignature}
                resignable={!!employeeSignature && !customerSignature}
                loading={signing}
                onSubmit={(values) =>
                  sign({
                    type: AcceptanceReportSignatureType.HEINRICH_SCHMID,
                    values,
                  })
                }
                onDirtyStateChange={onFormDirty}
              />
            </Box>
          }
        />

        <Tab
          id={AcceptanceReportSignatureType.CUSTOMER}
          label={t('client.title')}
          disabled={!employeeSignature}
          panel={
            <Box stylex={{ marginTop: 'small-x' }}>
              <SignatureForm
                initialValues={{
                  name: clientAttendee?.fullName,
                }}
                signatureInputLabel={t('signature.title')}
                submitButtonText={t('acceptanceReportSignForm.submitButtonText')}
                names={employeeNames}
                loading={signing}
                onSubmit={(values) =>
                  sign({
                    type: AcceptanceReportSignatureType.CUSTOMER,
                    values,
                  })
                }
                onDirtyStateChange={onFormDirty}
              />
            </Box>
          }
        />
      </Tabs>
    </>
  );
}
