import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import useRiskAssessmentSendEmail from '../../hooks/useRiskAssessmentSendEmail';
import useDriveDocumentTagDetails from '../../../../../hooks/useDriveDocumentTagDetails';
import ErrorState from '../../../../../containers/ErrorState';
import EmailForm from '../../../../../containers/Email/EmailForm';
import { RiskAssessment } from '../../../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../../../graphql/__generated__/RiskAssessmentProject.fragment';
import { getRiskAssessmentEmailFormInitialValues } from './RiskAssessmentSendEmailBody.utils';
import { formatBytes } from '../../../../../utils/number';

interface RiskAssessmentSendEmailBodyProps {
  riskAssessment: RiskAssessment;
  project: RiskAssessmentProject;
  onDocumentIdsValueChange: () => void;
  onDirtyStateChange: () => void;
}

export default function RiskAssessmentSendEmailBody({
  riskAssessment,
  project,
  onDocumentIdsValueChange,
  onDirtyStateChange,
}: RiskAssessmentSendEmailBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { driveDocument } = useDriveDocumentTagDetails(riskAssessment.id);
  const {
    sendEmail,
    loading: sending,
    error: emailSendError,
  } = useRiskAssessmentSendEmail(riskAssessment.id);

  if (!viewer) {
    return null;
  }

  const documents = [
    {
      id: riskAssessment.id,
      name: riskAssessment.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <>
      {emailSendError && <ErrorState error={emailSendError} />}

      <EmailForm
        projectId={project.id}
        initialValues={getRiskAssessmentEmailFormInitialValues(riskAssessment, project, viewer, t)}
        documents={documents}
        submitButtonText={t('riskAssessmentSendEmail.form.submitButton')}
        loading={sending}
        onSubmit={sendEmail}
        onDocumentIdsValueChange={onDocumentIdsValueChange}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
