import { gql } from '@apollo/client';

export default gql`
  fragment ProjectFilterProfitCenter on ProfitCenter {
    id
    name
    projectCount

    parent {
      id
    }
  }
`;
