import { ApolloError, NetworkStatus, useQuery } from '@apollo/client';
import { useState } from 'react';

import { ProjectStatus } from '../../../globalTypes';
import { SAPTransmissionProjectsQuery } from './graphql/SAPTransmissionProjects.query';
import { getProjectFilterCache } from '../Detail/ProjectFilter/projectFilterCache';
import { SAPTransmissionProject } from './graphql/__generated__/SAPTransmissionProject.fragment';
import { SAPTransmissionSelectedProfitCenter } from './graphql/__generated__/SAPTransmissionSelectedProfitCenter.fragment';
import { SAPTransmissionProjectsVariables } from './graphql/__generated__/SAPTransmissionProjects.query';

interface UseSAPTransmitDetailOptions {
  limit?: number;
  skip?: boolean;
  onProjectLoad: (projects: SAPTransmissionProject[]) => void;
}

interface UseSAPTransmitDetailResponse {
  projects: SAPTransmissionProject[];
  selectedProfitCenters: SAPTransmissionSelectedProfitCenter[];
  selectedProjectStatuses: ProjectStatus[];
  fetchNextPage: () => Promise<void>;
  hasNextPage: boolean;
  loading: boolean;
  fetching: boolean;
  error?: ApolloError;
}

export default function useSAPTransmitDetail({
  limit,
  skip,
  onProjectLoad,
}: UseSAPTransmitDetailOptions): UseSAPTransmitDetailResponse {
  const cachedProjectsFilters = getProjectFilterCache();
  const filteredProfitCenters = cachedProjectsFilters?.profitCenters || [];
  const filteredProjectStatuses = cachedProjectsFilters?.projectStatuses || [];

  const [loadedProjectIds, setLoadedProjectIds] = useState<string[]>([]);

  const { data, loading, fetchMore, error, networkStatus } = useQuery(
    SAPTransmissionProjectsQuery,
    {
      variables: {
        profitCenters: filteredProfitCenters,
        projectStatuses: filteredProjectStatuses,
        limit,
      },
      skip,
      notifyOnNetworkStatusChange: true,
      onCompleted: (completedData) => {
        const loadedProjects = completedData.sapTransmissionProjects.projects.filter(
          (project) => !loadedProjectIds.includes(project.id),
        );

        onProjectLoad(loadedProjects);

        setLoadedProjectIds((prev) => [...prev, ...loadedProjects.map((project) => project.id)]);
      },
    },
  );

  const sapTransmission = data?.sapTransmissionProjects;
  const projects = sapTransmission?.projects ?? [];
  const selectedProfitCenters = sapTransmission?.selectedProfitCenters
    ? sapTransmission?.selectedProfitCenters.filter(
        (profitCenter) =>
          profitCenter.projectCount > 0 && filteredProfitCenters.includes(profitCenter.id),
      )
    : [];
  const selectedProjectStatuses = sapTransmission?.selectedStatuses
    ? sapTransmission?.selectedStatuses
        .filter(
          (selectedStatus) =>
            selectedStatus.count > 0 && filteredProjectStatuses.includes(selectedStatus.status),
        )
        .map((selectedStatus) => selectedStatus.status)
    : [];

  async function fetchNextPage() {
    if (!data) {
      return;
    }

    const nextPage = data.sapTransmissionProjects.pagination.current + 1;
    const nextVariables: SAPTransmissionProjectsVariables = {
      profitCenters: filteredProfitCenters,
      projectStatuses: filteredProjectStatuses,
      page: nextPage,
    };

    await fetchMore({
      variables: nextVariables,
      updateQuery: (previousResults, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          sapTransmissionProjects: {
            ...fetchMoreResult.sapTransmissionProjects,
            projects: [
              ...previousResults.sapTransmissionProjects.projects,
              ...fetchMoreResult.sapTransmissionProjects.projects,
            ],
          },
        };
      },
    });
  }

  const fetchingMoreOrRefetching =
    networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  return {
    projects,
    selectedProfitCenters,
    selectedProjectStatuses,
    fetchNextPage,
    hasNextPage: !!data?.sapTransmissionProjects.pagination.hasNextPage,
    fetching: networkStatus === NetworkStatus.fetchMore,
    loading: !fetchingMoreOrRefetching && loading,
    error,
  };
}
