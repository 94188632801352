import { useState } from 'react';

export enum RiskAssessmentTab {
  Information = 'Information',
  Precautions = 'Precautions',
  Equipments = 'Equipments',
}

interface UseRiskAssessmentFormParams {
  setActiveTab: (t: RiskAssessmentTab) => void;
  isActiveTab: (t: RiskAssessmentTab) => boolean;
  isValidTab: (candidate: string) => candidate is RiskAssessmentTab;
  tab: RiskAssessmentTab;
}

const useRiskAssessmentForm = (
  initialTab: RiskAssessmentTab,
  onUpdateStep: (step: RiskAssessmentTab) => void,
): UseRiskAssessmentFormParams => {
  const [tab, setTab] = useState<RiskAssessmentTab>(initialTab);

  function setActiveTab(t: RiskAssessmentTab) {
    onUpdateStep(t);
    setTab(t);
  }

  function isActiveTab(t: RiskAssessmentTab) {
    return t === tab;
  }

  function isValidTab(candidate: string): candidate is RiskAssessmentTab {
    return Object.keys(RiskAssessmentTab).includes(candidate);
  }

  return {
    tab,
    setActiveTab,
    isActiveTab,
    isValidTab,
  };
};

export default useRiskAssessmentForm;
