import { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useNavigator from '../../../../../../hooks/useNavigator';

interface DefectSectionAttachmentMenuProps {
  loading: boolean;
  onUploadImageButtonClick: () => void;
  onSelectImageButtonClick: () => void;
}

export default function DefectSectionAttachmentMenu({
  loading,
  onUploadImageButtonClick,
  onSelectImageButtonClick,
}: DefectSectionAttachmentMenuProps): JSX.Element {
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const { tabletDevice, mobileDevice } = useNavigator();

  function handleMenuItemClick(handler: () => void) {
    setMenuOpen(false);

    handler();
  }

  return (
    <Menu
      open={menuOpen}
      onRequestClose={() => setMenuOpen(false)}
      placement="bottom-fit"
      renderTrigger={(triggerProps) => (
        <Button
          {...triggerProps}
          onClick={() => setMenuOpen(!menuOpen)}
          color={menuOpen ? 'primary' : 'default'}
          contentClassName="u-display-flex u-align-items-center"
          loading={loading}
          type="button"
        >
          <Icon name="add-a-photo" aria-hidden size={24} />
        </Button>
      )}
    >
      <MenuItem
        leftSection={<Icon name="smartphone" className="u-color-primary" size={24} />}
        onClick={() => handleMenuItemClick(onUploadImageButtonClick)}
        disabled={!(tabletDevice || mobileDevice)}
      >
        <Text>{t('acceptanceReport.defect.attachmentMenu.uploadImageButton')}</Text>
      </MenuItem>

      <MenuItem
        leftSection={<Icon name="photo" className="u-color-primary" size={24} />}
        onClick={() => handleMenuItemClick(onSelectImageButtonClick)}
      >
        <Text>{t('acceptanceReport.defect.attachmentMenu.selectImageButton')}</Text>
      </MenuItem>
    </Menu>
  );
}
