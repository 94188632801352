import { format } from 'date-fns';
import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { QsstFormValues } from '../../../components/QsstSave/QsstForm/QsstForm.form';
import { QsstSaveProjectDetail } from './graphql/__generated__/QsstSaveProjectDetail.query';
import { DuplicatingQsstDetail } from './graphql/__generated__/DuplicatingQsstDetail.query';
import { formatISOStringToDateString } from '../../../utils/date';

interface GetQsstFormInitialValuesOptions {
  project: QsstSaveProjectDetail['project'];
  viewer: Viewer;
  t: TFunction;
  duplicatingQsst?: NonNullable<DuplicatingQsstDetail['qsst']>;
}

export function getQsstFormInitialValues({
  project,
  viewer,
  t,
  duplicatingQsst,
}: GetQsstFormInitialValuesOptions): QsstFormValues {
  const viewerFullName = `${viewer.firstName} ${viewer.lastName}`;
  const defaultName = t('qsstForm.default.documentName', {
    date: format(new Date(), 'dd.MM.yyyy'),
  });

  const contractDate = duplicatingQsst?.contractDate ?? project.date;
  const clientName = duplicatingQsst?.clientName ?? project.client?.name;
  const name = duplicatingQsst?.name || defaultName;
  const onSideEmployeeFullName = duplicatingQsst?.onsideEmployee.fullName ?? viewerFullName;

  return {
    contractDate: contractDate ? formatISOStringToDateString(contractDate) : '',
    clientName: clientName || '-',
    projectNumber: project.draft ? project.description : project.hsProjectNumber || '-',
    name,
    onsideEmployee: {
      fullName: onSideEmployeeFullName,
    },
  };
}
