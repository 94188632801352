import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileViewer, FileViewerToolbar, Icon, IconButton } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { useViewer } from '@hs-baumappe/web-auth';
import useDriveDocumentPreview from './hooks/useDriveDocumentPreview';
import { useUpdateDriveDocumentLastUsed } from '../../../hooks/useUpdateDriveDocumentLastUsed';
import DriveDocumentPreviewer from './components/DriveDocumentPreviewer';
import { projectRoutes, projectsRoutes } from '../../routes';
import { DriveDocumentPreviewRouteParams } from '../driveDocumentPreview.route.types';
import {
  getDocumentDownloadAccess,
  getDocumentViewAccess,
} from './DriveDocumentPreviewDetail.utils';

export default function DriveDocumentPreviewDetail(): JSX.Element {
  const { documentId } = useParams<DriveDocumentPreviewRouteParams>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const { viewer } = useViewer();
  const { currentDocument, loading } = useDriveDocumentPreview(documentId);

  useUpdateDriveDocumentLastUsed({
    id: documentId,
    skip: !currentDocument,
  });

  function handleFileViewerClose() {
    const path = currentDocument
      ? projectRoutes.detail.generatePath({ projectId: currentDocument.project.id })
      : projectsRoutes.detail.generatePath({});

    navigate(path);
  }

  function renderContent() {
    if (loading) {
      return <LayoutLoading />;
    }

    if (!currentDocument || !viewer?.scopes) {
      return null;
    }

    const documentViewAccess = getDocumentViewAccess(currentDocument, viewer.scopes);
    const documentDownloadAccess = getDocumentDownloadAccess(currentDocument, viewer.scopes);

    const viewPermitted = !!documentViewAccess?.isPermitted;
    const viewMissingScopeNames = documentViewAccess?.missingScopeNames || [];

    const downloadPermitted = !!documentDownloadAccess?.isPermitted;

    if (!viewPermitted) {
      return <MissingScopeError missingScopes={viewMissingScopeNames} />;
    }

    return (
      <DriveDocumentPreviewer
        viewerCanDownload={downloadPermitted}
        url={currentDocument.preview?.url}
        mimeType={currentDocument.preview?.mimeType}
        downloadUrl={currentDocument.download?.url}
        downloadName={currentDocument.download?.name}
      />
    );
  }

  return (
    <FileViewer
      open={open}
      onRequestClose={() => setOpen(false)}
      onClose={handleFileViewerClose}
      toolbar={
        <FileViewerToolbar
          title={currentDocument?.name}
          leftSection={
            <IconButton
              icon={<Icon name="keyboard-arrow-left" />}
              color="white"
              onClick={() => setOpen(false)}
            />
          }
        />
      }
    >
      {renderContent()}
    </FileViewer>
  );
}
