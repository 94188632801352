import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { Viewer } from '@hs-baumappe/web-auth';
import { QsstFormValues } from '../../../../../components/QsstSave/QsstForm/QsstForm.form';
import { formatISOStringToDateString } from '../../../../../utils/date';
import { QsstEditDetail } from '../../graphql/__generated__/QsstEditDetail.query';

export function mapQsstToQsstFormValues({
  qsst,
  project,
  viewer,
  t,
}: {
  qsst: NonNullable<QsstEditDetail['qsst']>;
  project: NonNullable<QsstEditDetail['qsst']>['project'];
  viewer: Viewer;
  t: TFunction;
}): QsstFormValues {
  return {
    contractDate: qsst.contractDate ? formatISOStringToDateString(qsst.contractDate) : '',
    clientName: qsst.clientName || '-',
    projectNumber: project.draft ? project.description : project.hsProjectNumber || '-',
    name:
      qsst.name ??
      t('qsstForm.default.documentName', {
        date: format(new Date(), 'dd.MM.yyyy'),
      }),
    onsideEmployee: {
      fullName: qsst.onsideEmployee.fullName ?? `${viewer.firstName} ${viewer.lastName}`,
    },
  };
}
