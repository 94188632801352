import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../../../../globalTypes';
import RemoveDocument from '../../../../Project/document/RemoveDocument';
import { ConcernScopes } from '../../../Concern.types';
import { createConcernLocation } from '../../../Concern.utils';
import { concernRoutes } from '../../../concernRoutes';
import { Concern } from '../../../graphql/__generated__/Concern.fragment';
import { createDocumentAssignPath } from '../../ConcernDetail.utils';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';

interface ConcernDetailSidebarBottomProps {
  concern: Concern;
  scopes: ConcernScopes;
  projectId: string;
  onRemoveSuccess: () => void;
}

export default function ConcernDetailSidebarBottom({
  concern,
  scopes,
  projectId,
  onRemoveSuccess,
}: ConcernDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();
  const location = useLocation();

  const signPermitted = scopes.signConcernDocument.isPermitted;
  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;

  return (
    <Stack gap="small-3x" stylex={{ marginTop: 'large-3x' }}>
      {concern.viewerCanAssign && (
        <Button
          endIcon={<Icon name="folder" />}
          component={Link}
          to={createDocumentAssignPath(concern, projectId)}
        >
          {t('concern.assignButton')}
        </Button>
      )}

      {concern.viewerCanSign && signPermitted && (
        <Button
          color="primary"
          endIcon={<Icon name="border-color" />}
          component={Link}
          to={concernRoutes.sign.generatePath({ concernId: concern.id })}
          state={createConcernLocation(location)}
        >
          {t('concern.signButtonText')}
        </Button>
      )}

      {concern.viewerCanArchive && archiveEnabled && (
        <Button
          color="primary"
          component={Link}
          to={concernRoutes.archive.generatePath({ concernId: concern.id })}
          endIcon={<Icon name="archive" />}
        >
          {t('concern.archiveButton')}
        </Button>
      )}

      {concern.viewerCanRemove && removePermitted && (
        <RemoveDocument
          document={{
            name: concern.name,
            type: DocumentType.CONCERN,
            id: concern.id,
          }}
          onRemoveSuccess={onRemoveSuccess}
          renderTrigger={({ onClick, loading }) => (
            <Button variant="text" color="error" onClick={onClick} loading={loading}>
              {t('concern.removeButton')}
            </Button>
          )}
        />
      )}
    </Stack>
  );
}
