import { gql } from '@apollo/client';

const RiskAssessmentProjectFragment = gql`
  fragment RiskAssessmentProject on Project {
    id

    name
    description
    hsProjectNumber
    projectNumber
    draft
  }
`;

export default RiskAssessmentProjectFragment;
