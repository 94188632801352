import { ApolloError, useMutation } from '@apollo/client';

import { AddOperationToFolder, AddOperationToFolderVariables } from './__generated__/graphql';
import { ADD_OPERATION_TO_FOLDER } from './graphql';
import { OperationValues } from './CreateNewOperationForm/values';
import {
  cast,
  clearEmptyTotal,
  createFlatRateMeasurmentValue,
} from '../../../measurement/MeasurementSection/values';
import { castNote } from '../../../folder/containers/FolderForm/form';
import { OfferMode, OperationUnit } from '../../../../../globalTypes';
import { castPaymentToAddOperationToFolder } from '../../PaymentForm/paymentMutationValidationSchema';
import { validationSchema } from './CreateNewOperationForm/form';
import { GET_OFFER_DATA } from '../../../Detail/graphql';

interface OfferFolderOperationCreateProps {
  onSuccess: () => void;
  offerId: string;
  folderId: string;
  folderUnit?: OperationUnit;
  offerMode: OfferMode;
}

interface UseOfferFolderOperationCreateParams {
  error?: ApolloError;
  createOperation: (values: OperationValues) => Promise<void>;
}

const useOfferFolderOperationCreate = ({
  offerId,
  folderId,
  folderUnit,
  offerMode,
  onSuccess,
}: OfferFolderOperationCreateProps): UseOfferFolderOperationCreateParams => {
  const [mutation, { loading, error }] = useMutation<
    AddOperationToFolder,
    AddOperationToFolderVariables
  >(ADD_OPERATION_TO_FOLDER);
  const createOperation = async (values: OperationValues) => {
    if (loading) {
      return;
    }
    const v = validationSchema().validateSync(values);
    if (!v) {
      return;
    }

    const { measurements, note } = values;
    const castedNotes = castNote(note);
    let castedMeasurements = clearEmptyTotal(cast(measurements));

    if (castedMeasurements.length === 0 && v.unit === OperationUnit.FLAT_RATE) {
      castedMeasurements = [createFlatRateMeasurmentValue()];
    }

    await mutation({
      variables: {
        input: {
          partNoAlias: offerMode === OfferMode.ALIAS_NUMBER ? values.alias : undefined,
          folder: folderId,
          name: v.name,
          description: v.description,
          price: v.price,
          note: castedNotes,
          approximate: v.approximate,
          measurements: castedMeasurements || [],
          unit: (v.unit as OperationUnit) || folderUnit,
          optional: v.optional,
          category: 'category' in v ? v.category : undefined,
          payment: castPaymentToAddOperationToFolder(v.payment),
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    onSuccess();
  };

  return {
    error,
    createOperation,
  };
};

export default useOfferFolderOperationCreate;
