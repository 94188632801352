import { lazy } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import { OfferLocationState, OfferRouteParams } from './Offer.route.types';

import OfferFolderEdit from './folder/OfferFolderEdit';
import OfferFolderCreate from './folder/OfferFolderCreate';
import OfferFolderClone from './folder/OfferFolderClone';
import OfferProvider from './hooks/offer/OfferProvider';
import OfferFolderOperationCreate from './operation/OfferFolderOperationCreate';
import { OfferData, OfferDataVariables } from './Detail/__generated__/graphql';
import { GET_OFFER_DATA } from './Detail/graphql';
import DocumentDetailLoadingLayout from '../components/DocumentDetailLoadingLayout';
import OfferFolderOperationCreateFromTlk from './operation/OfferFolderOperationCreateFromTlk';
import OfferFolderOperationClone from './operation/OfferFolderOperationClone';
import OfferFolderOperationEdit from './operation/OfferFolderOperationEdit';
import OfferEdit from './offer/OfferEdit';
import OfferSummaryEdit from './offer/OfferSummaryEdit';
import ExportOffer from './offer/ExportOffer';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';

const Detail = lazy(() => import('./Detail'));
const OfferPdf = lazy(() => import('./OfferPdf'));

const Offer = (): JSX.Element | null => {
  const { offerId } = useParams<OfferRouteParams>();
  const location = useLocation<OfferLocationState>();
  const backgroundLocation = location.state?.backgroundLocation;

  const { data, loading, refetch, error } = useQuery<OfferData, OfferDataVariables>(
    GET_OFFER_DATA,
    {
      variables: {
        id: offerId,
      },
      fetchPolicy: 'network-only',
    },
  );

  useUpdateDriveDocumentLastUsed({
    id: offerId,
    skip: !data?.offer,
  });

  const handleRefetchOfferData = (variables?: Partial<OfferDataVariables>) => refetch(variables);

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (loading && !data) {
    return <DocumentDetailLoadingLayout />;
  }

  if (!data?.offer) {
    return <NotFoundErrorPage />;
  }

  return (
    <OfferProvider offer={data.offer} refetchOfferData={handleRefetchOfferData}>
      <Routes location={backgroundLocation || location}>
        <Route path="pdf" element={<OfferPdf />} />
        <Route path="*" element={<Detail />} />
      </Routes>

      <Routes>
        <Route path="edit" element={<OfferEdit />} />
        <Route path="s/edit" element={<OfferSummaryEdit />} />
        {data.offer.viewerCanExport && <Route path="export" element={<ExportOffer />} />}

        <Route path="f">
          <Route path="create" element={<OfferFolderCreate />} />

          <Route path=":folderId">
            <Route path="edit" element={<OfferFolderEdit />} />
            <Route path="clone" element={<OfferFolderClone />} />

            <Route path="o">
              <Route path="create" element={<OfferFolderOperationCreate />} />

              <Route path=":operationId">
                <Route path="create-tlk" element={<OfferFolderOperationCreateFromTlk />} />
                <Route path="edit" element={<OfferFolderOperationEdit />} />
                <Route path="clone" element={<OfferFolderOperationClone />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </OfferProvider>
  );
};

export default Offer;
