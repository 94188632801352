import {
  Box,
  Icon,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { Project } from './AssignableProjectTable.types';

export interface AssignableProjectTableProps {
  projects: Project[];
  loading: boolean;
  selectedProjectId?: string;
  onProjectClick: (project: Project) => void;
}

export default function AssignableProjectTable({
  projects,
  loading,
  selectedProjectId,
  onProjectClick,
}: AssignableProjectTableProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Paper stylex={{ padding: 'small-x' }}>
      <Box stylex={{ overflow: 'auto' }}>
        <Table>
          <colgroup>
            <Box component="col" stylex={{ width: '1%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell component="th">
                {t('assignDocumentToProject.projectTable.projectId')}
              </TableCell>
              <TableCell component="th">
                {t('assignDocumentToProject.projectTable.projectName')}
              </TableCell>
              <TableCell component="th" />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              Array.from({ length: 2 }, (_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton width={165} height={20} radius={8} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={225} height={20} radius={8} />
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}

            {!loading && projects.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={3}
                  disablePadding
                  stylex={{ textAlign: 'center', paddingEnds: 'large', paddingSides: 'small-2x' }}
                >
                  <Text variant="body-large" color="gray-500">
                    {t('dataTable.emptyText')}
                  </Text>
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              projects.length > 0 &&
              projects.map((project) => {
                const selected = selectedProjectId === project.id;

                return (
                  <TableRow
                    key={project.id}
                    selected={selected}
                    onClick={() => onProjectClick(project)}
                  >
                    <TableCell>{project.projectNumber}</TableCell>
                    <TableCell>{project.name}</TableCell>
                    <TableCell
                      disablePadding
                      stylex={{
                        paddingRight: 'small',
                        paddingEnds: 'small-4x',
                        textAlign: 'right',
                      }}
                    >
                      <Icon
                        name="check"
                        color="primary"
                        size={24}
                        stylex={{
                          verticalAlign: 'middle',
                          visibility: !selected ? 'hidden' : undefined,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
