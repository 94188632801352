import { Category } from './types';
import { AvaiableCategoriesFragment } from './__generated__/graphql';

const categoryMapper = (categories: AvaiableCategoriesFragment[]): Category[] => {
  return categories.map((category) => {
    return {
      id: category.id,
      partNo: category.calculatedPartNo,
      name: category.name || undefined,
    };
  });
};

export default categoryMapper;
