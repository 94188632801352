import { createPath } from 'react-router-dom';
import { Obstruction } from '../graphql/__generated__/Obstruction.fragment';
import { DocumentType } from '../../../globalTypes';
import { projectRoutes } from '../../routes';
import obstructionRoutes from '../obstructionRoutes';

export enum ObstructionDetailSearchParams {
  ALERT = 'alert',
}

export enum ObstructionDetailAlert {
  SEND = 'send',
  ARCHIVED = 'archived',
}

export function createDocumentAssignPath(obstruction: Obstruction, projectId: string): string {
  const searchParams = new URLSearchParams({
    document: DocumentType.OBSTRUCTION,
    returnPath: obstructionRoutes.detail.generatePath({
      obstructionId: obstruction.id,
    }),
  });

  return createPath({
    pathname: projectRoutes.assignDocumentToProject.generatePath({
      projectId,
      documentId: obstruction.id,
    }),
    search: searchParams.toString(),
  });
}
