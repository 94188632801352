import { gql, TypedDocumentNode } from '@apollo/client';
import { QsstEditDetail, QsstEditDetailVariables } from './__generated__/QsstEditDetail.query';

const QsstEditDetailQuery: TypedDocumentNode<QsstEditDetail, QsstEditDetailVariables> = gql`
  query QsstEditDetail($qsstId: ID!) {
    qsst(id: $qsstId) {
      id
      name
      clientName
      contractDate

      project {
        id

        description
        hsProjectNumber
        draft
      }

      onsideEmployee {
        fullName
      }
    }
  }
`;

export default QsstEditDetailQuery;
