import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { MissingScopeError } from '@hs-baumappe/redkit';

import RouteDialog from '../../../containers/RouteDialog';
import DocumentSendEmailBody from './components/DocumentSendEmailBody';
import documentRoutes from '../documentRoutes';
import { DocumentLocationState } from '../document.route.types';
import { CustomFileDocument } from '../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../graphql/__generated__/CustomFileDocumentProject.fragment';
import { CustomFileDocumentScopes } from '../Document.types';

interface DocumentSendEmailProps {
  document: CustomFileDocument;
  project: CustomFileDocumentProject;
  scopes: CustomFileDocumentScopes;
}

export default function DocumentSendEmail({
  document,
  scopes,
  project,
}: DocumentSendEmailProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<DocumentLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(documentRoutes.manipulate.generatePath({ documentId: document.id }));
  }, [document.id, location.state, navigate]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  const permitted = scopes.sendCustomFileDocumentAttachment.isPermitted;
  const missingScopeNames = scopes.sendCustomFileDocumentAttachment.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {document.name}
        </Text>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <DocumentSendEmailBody
          document={document}
          project={project}
          onDocumentIdsValueChange={() => setDialogOpen(false)}
          onDirtyStateChange={() => setFormDirty(true)}
        />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={document.name}
        description={t('customFile.sendEmail.confirmationDialog.description')}
        confirmButtonText={t('customFile.sendEmail.confirmationDialog.confirmButtonText')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('customFile.sendEmail.confirmationDialog.cancelButtonText')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
