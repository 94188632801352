import { FormikHelpers } from 'formik';
import { SapTransmitLoginValues } from './SAPTransmitLogin/SapTransmitLogin';
import { Icon, IconButton, SnackbarDuration, useSnackbar } from '@hs-baumappe/legacy-ui';
import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SivKeyDataSapTransmitMutation } from './graphql/SivKeyDataSapTransmit.mutation';
import { SAPTransmissionProjectsQuery } from './graphql/SAPTransmissionProjects.query';
import SAPTransmitErrorState from './SAPTransmitErrorState';
import SAPTransmitWarningState from './SAPTransmitWarningState';
import { SivKeyDataSapTransmit } from './graphql/__generated__/SivKeyDataSapTransmit.mutation';
import {
  SapTransmitFailedError,
  SapTransmitInvalidCredentialsError,
  SapTransmitPartiallyCompletedError,
  throwErrorIfSapTransmitNotFulfilled,
} from './useSapTransmit.utils';

interface SapTransmitResponse {
  sapTransmitKeyData: (
    values: SapTransmitLoginValues,
    projectIds: string[],
    formikHelpers: FormikHelpers<SapTransmitLoginValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

type SivKeyDataSapTransmitResult = SivKeyDataSapTransmit['sivKeyDataSapTransmit'];

interface SapTransmitProps {
  onError: (values: SivKeyDataSapTransmitResult['failedTransmissions']) => void;
  onSuccess: (
    values: SivKeyDataSapTransmitResult['completedTransmissions'],
    formValues: SapTransmitLoginValues,
  ) => void;
}

export default function useSapTransmit({
  onError,
  onSuccess,
}: SapTransmitProps): SapTransmitResponse {
  const { t } = useTranslation();
  const { open, close } = useSnackbar();
  const [mutation, { loading, error }] = useMutation(SivKeyDataSapTransmitMutation, {
    refetchQueries: [SAPTransmissionProjectsQuery],
    awaitRefetchQueries: true,
  });

  async function sapTransmitKeyData(
    values: SapTransmitLoginValues,
    projectIds: string[],
    formikHelpers: FormikHelpers<SapTransmitLoginValues>,
  ) {
    try {
      const result = await mutation({
        variables: {
          input: {
            sapUsername: values.name,
            sapPassword: values.password,
            projectIds,
          },
        },
      });

      if (!result?.data) return;

      throwErrorIfSapTransmitNotFulfilled(result.data);

      const completedTransmissions = result.data.sivKeyDataSapTransmit.completedTransmissions;

      onSuccess(completedTransmissions, values);
      open(`${completedTransmissions.length} Leistungsstände erfolgreich übermittelt.`, {
        type: 'success',
        autoHideDuration: SnackbarDuration.LONG,
        action: <IconButton color="white" icon={<Icon name="close" />} onClick={() => close()} />,
      });
    } catch (e) {
      if (e instanceof SapTransmitInvalidCredentialsError) {
        formikHelpers.setErrors({
          name: t('transmit.form.errorName'),
          password: t('transmit.form.errorPassword'),
        });

        return;
      }

      if (e instanceof SapTransmitPartiallyCompletedError) {
        const failedTransmissions = e.failedTransmissions;

        onError(e.failedTransmissions);
        open(
          <SAPTransmitWarningState
            close={() => close()}
            retry={async () => {
              close();

              await sapTransmitKeyData(
                values,
                failedTransmissions.map(({ id }) => id),
                formikHelpers,
              );
            }}
            failedTransmissions={failedTransmissions}
          />,
          {
            type: 'warning',
            autoHideDuration: null,
          },
        );

        return;
      }

      if (e instanceof SapTransmitFailedError) {
        const failedTransmissions = e.failedTransmissions;

        onError(failedTransmissions);
        open(
          <SAPTransmitErrorState
            close={() => close()}
            retry={async () => {
              close();
              onError(failedTransmissions);

              await sapTransmitKeyData(
                values,
                failedTransmissions.map(({ id }) => id),
                formikHelpers,
              );
            }}
          />,
          {
            type: 'error',
            autoHideDuration: null,
          },
        );
      }
    }
  }

  return {
    loading,
    error,
    sapTransmitKeyData,
  };
}
