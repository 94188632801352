import { gql, TypedDocumentNode } from '@apollo/client';
import AcceptanceReportFragment from '../../../graphql/AcceptanceReport.fragment';
import {
  AcceptanceReportSave,
  AcceptanceReportSaveVariables,
} from './__generated__/AcceptanceReportSave.mutation';

const AcceptanceReportSaveMutation: TypedDocumentNode<
  AcceptanceReportSave,
  AcceptanceReportSaveVariables
> = gql`
  mutation AcceptanceReportSave($input: AcceptanceReportSaveInput!) {
    acceptanceReportSave(input: $input) {
      ...AcceptanceReport
    }
  }

  ${AcceptanceReportFragment}
`;

export default AcceptanceReportSaveMutation;
