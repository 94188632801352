import { LocalStorageCache } from '@hs-baumappe/frontend-kit';
import yup from '../../../../../yup';

interface ProfitCenterCollapseCache {
  profitCenters: string[];
}

const projectFilterCacheTTL = 24 * 30 * 60 * 60 * 1000; // 30 day
const projectFilterCacheKey = 'profitCenterCollapse';
const profitCenterCollapseCache = new LocalStorageCache<ProfitCenterCollapseCache>({
  validationSchema: yup.object({
    profitCenters: yup.array().of(yup.string().required()),
  }),
});

export function getProfitCenterCollapseCache(): ProfitCenterCollapseCache {
  return profitCenterCollapseCache.get(projectFilterCacheKey) ?? { profitCenters: [] };
}

export function setProfitCenterCollapsedInCache(profitCenterId: string): void {
  const cache = getProfitCenterCollapseCache();

  profitCenterCollapseCache.set(
    projectFilterCacheKey,
    { profitCenters: Array.from(new Set([...cache.profitCenters, profitCenterId])) },
    projectFilterCacheTTL,
  );
}

export function removeProfitCenterCollapsedInCache(profitCenterId: string): void {
  const cache = getProfitCenterCollapseCache();

  profitCenterCollapseCache.set(
    projectFilterCacheKey,
    { profitCenters: cache.profitCenters.filter((id) => id !== profitCenterId) },
    projectFilterCacheTTL,
  );
}
