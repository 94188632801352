import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { CreateAcceptanceReportProjectDetail } from './graphql/__generated__/CreateAcceptanceReportProjectDetailQuery';
import { AcceptanceReportFormValues } from '../../AcceptanceReport/forms/AcceptanceReportForm/AcceptanceReportFormValues';
import { formatDateToDateString } from '../../../utils/date';
import { createAcceptanceReportAttendeesInitialValues } from '../../AcceptanceReport/forms/AcceptanceReportForm/AcceptanceReportForm.form';

export function mapAcceptanceReportProjectToFormValues({
  project,
  viewer,
  t,
}: {
  project: CreateAcceptanceReportProjectDetail['project'];
  viewer: Viewer;
  t: TFunction;
}): Partial<AcceptanceReportFormValues> {
  return {
    name: project.name,
    projectNumber: project.draft
      ? t('acceptanceReport.projectNumber.initialValues')
      : project.hsProjectNumber || '-',
    date: formatDateToDateString(new Date()),
    client: project.client ? project.client.name : undefined,
    contractor:
      project.profitCenter?.address?.name || t('acceptanceReport.create.contractorInitialValue'),
    attendees: createAcceptanceReportAttendeesInitialValues(
      viewer,
      project.contactPeople.length === 1 ? project.contactPeople[0] : undefined,
    ),
    description: project.additionalName || undefined,
    toProfessions: [],
  };
}

export function getProjectContactPeopleNames(
  project: CreateAcceptanceReportProjectDetail['project'],
): string[] {
  return project.contactPeople.reduce<string[]>(
    (result, person) => (person.name ? [...result, person.name] : []),
    [],
  );
}
