import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import useSearchOperationsQuery from './useSearchOperationQuery';
import { Catalog } from './types';

const MIN_SEARCH_TERM_LENGTH = 2;

interface Options {
  catalogID?: string;
  categoryID?: string;
}

interface UseOperationSearchParams {
  search: (term: string, catalogID?: string | undefined) => void;
  catalogs: Catalog[];
  noResult: boolean;
  catalogList: Catalog[];
  searchAndSet: (
    term?: string | undefined,
    catalogID?: string | undefined,
    select?: boolean | undefined,
  ) => Promise<void>;
  loading: boolean;
}

const useOperationSearch = (opt?: Options): UseOperationSearchParams => {
  const options = opt || {};
  const [catalogList, setCatalogList] = useState<Catalog[]>([]);
  const [catalogs, setCatalogs] = useState<Catalog[]>([]);
  const [noResult, setNoResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchCatalogs = useSearchOperationsQuery();

  const resetCatalog = () => {
    setCatalogs([]);
    setNoResult(false);
  };

  const searchAndSetCatalogList = async () => {
    const searchedCatalogs = await searchCatalogs({ categoryID: options.categoryID || '' });

    setCatalogList(searchedCatalogs);
  };

  const searchAndSet = async (term?: string, catalogID?: string, select?: boolean) => {
    if (select) {
      resetCatalog();
      return;
    }

    setLoading(true);

    const searchedCatalogs = await searchCatalogs({
      term,
      categoryID: options.categoryID,
      catalogID: catalogID || options.catalogID,
    });

    setCatalogs(searchedCatalogs);
    setNoResult(!searchedCatalogs.length);
    setLoading(false);
  };

  const search = (term: string, catalogID?: string) => {
    if (!term.length) {
      searchAndSet(term, catalogID);
    }
    if (term.length < MIN_SEARCH_TERM_LENGTH) return;

    searchAndSet(term, catalogID);
  };

  useEffect(() => {
    searchAndSetCatalogList();
    searchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = useRef(debounce(search, 250));

  return {
    search: debouncedSearch.current,
    catalogs,
    noResult,
    catalogList,
    searchAndSet,
    loading,
  };
};

export default useOperationSearch;
