import { SchemaOf } from 'yup';
import yup, { InferObjectToYupShape } from '../../../yup';
import { QsstFormValues } from './QsstForm.form';

const onsideEmployeeValidation: SchemaOf<QsstFormValues['onsideEmployee']> = yup.object({
  fullName: yup.string().required(),
  phoneNo: yup.string(),
  email: yup.string(),
});

export const qsstFormValidationSchema = yup.object().shape<InferObjectToYupShape<QsstFormValues>>({
  name: yup.string().required(),
  clientName: yup.string().required(),
  contractDate: yup.date(),
  onsideEmployee: onsideEmployeeValidation.defined(),
  projectNumber: yup.string().required(),
});
