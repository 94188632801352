import { gql, TypedDocumentNode } from '@apollo/client';
import {
  AcceptanceReportConvertImageAttachments,
  AcceptanceReportConvertImageAttachmentsVariables,
} from './__generated__/AcceptanceReportConvertImageAttachments.mutation';

const AcceptanceReportConvertImageAttachmentsMutation: TypedDocumentNode<
  AcceptanceReportConvertImageAttachments,
  AcceptanceReportConvertImageAttachmentsVariables
> = gql`
  mutation AcceptanceReportConvertImageAttachments($input: [ID!]!) {
    convertCustomFilesToImages(input: $input) {
      id

      url
    }
  }
`;

export default AcceptanceReportConvertImageAttachmentsMutation;
