import { gql } from '@apollo/client';

const AcceptanceReportProjectFragment = gql`
  fragment AcceptanceReportProject on Project {
    id

    name
    draft
    projectNumber
    hsProjectNumber
    additionalName
    description
  }
`;

export default AcceptanceReportProjectFragment;
