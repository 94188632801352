import { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { Details, Summary, Text } from '@hs-baumappe/legacy-ui';

import FieldLayout from '../../../../components/formik/FieldLayout';
import Input from '../../../../components/form/Control/Input';
import Choice from '../../../../components/form/Choice';
import Select from '../../../../components/form/Control/Select';
import { FieldStatus } from '../../../../components/form/fieldStatus';

import DiscountOrSurchargeValueTypeOptions from './DiscountOrSurchargeValueTypeOptions';
import { evalValue } from '../../measurement/MeasurementSection/values';
import { DiscountOrSurchargeValueType } from '../../../../globalTypes';
import { currencyFormat } from '../../../../utils/locale';

const PaymentForm = (): JSX.Element => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, setFieldValue } = useFormikContext<any>();

  const errorFeedback = (name: string) => {
    const err = getIn(errors, name);

    return err && t(err);
  };

  const priceMutationStatusCheck = (status: FieldStatus) => {
    return getIn(values, 'payment.flat') ? 'disabled' : status;
  };

  const calculatedPriceStatusCheck = (status: FieldStatus) => {
    return !getIn(values, 'payment.flat') ? 'disabled' : status;
  };

  const calculateTotalMeasurement = () => {
    let totalMeasurement = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values.measurements.forEach((m: any) => {
      const totalValue = Math.abs(evalValue(m.values.total)) || 0;
      const sum = m.values.subtraction ? totalValue * -1 : totalValue;
      totalMeasurement += evalValue(`${sum * m.values.multiplier}`);
    });

    return totalMeasurement;
  };

  const isInitialOpenCollapse = () => {
    const payment = getIn(values, 'payment');

    if (!payment) {
      return true;
    }

    if (payment.totalPrice) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const price = getIn(values, 'price');
    const totalMeasurement = calculateTotalMeasurement() || 0;

    const calculatedValue = price * totalMeasurement;
    let total = calculatedValue;

    const discountOrSurchargeValueType = getIn(values, 'payment.discountOrSurchargeValueType');
    const flat = getIn(values, 'payment.flat');
    const discountOrSurchargeValue =
      evalValue(getIn(values, 'payment.discountOrSurchargeValue')) || 0;

    if (flat) {
      return;
    }

    if (discountOrSurchargeValueType === DiscountOrSurchargeValueType.PERCENTAGE) {
      total = calculatedValue + calculatedValue * (discountOrSurchargeValue / 100);
    } else if (discountOrSurchargeValueType === DiscountOrSurchargeValueType.AMOUNT) {
      total = calculatedValue + discountOrSurchargeValue;
    } else {
      total = calculatedValue + calculatedValue * (discountOrSurchargeValue / 100);
    }

    setFieldValue('payment.totalPrice', `${currencyFormat(total)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.price, values.measurements, values.payment]);

  const discountOrSurchargeValueTypeSelection = DiscountOrSurchargeValueTypeOptions.map(
    ({ label, value }) => ({
      label: t(label as ParseKeys<'translation'>) as string,
      value,
    }),
  );

  return (
    <Details
      defaultOpen={!isInitialOpenCollapse()}
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="title-small" color="muted" component="h3" className="u-margin-bottom-0">
            {t('paymentForm.title')}
          </Text>
        </Summary>
      )}
    >
      <div className="row u-margin-top">
        <div className="col col--sm-6">
          <FieldLayout
            name="payment.totalPrice"
            label={t('paymentForm.totalPrice.label')}
            labelHtmlFor="totalPrice-input"
            feedback={{ invalid: errorFeedback(`payment.totalPrice`) }}
          >
            {(inputProps) => (
              <Input
                {...inputProps}
                status={calculatedPriceStatusCheck(inputProps.status)}
                id="totalPrice-input"
                dataTestId="payment-form-total-price-input"
                placeholder={t('paymentForm.totalPrice.placeholder')}
              />
            )}
          </FieldLayout>
        </div>
        <div className="col col--sm-6 u-display-flex u-margin-top u-align-items-center">
          <FieldLayout
            name="payment.flat"
            labelHtmlFor="flat-input"
            feedback={{ invalid: errorFeedback(`payment.flat`) }}
          >
            {(inputProps) => (
              <Choice
                {...inputProps}
                type="checkbox"
                id="flat-input"
                label={t('paymentForm.flatPrice.label')}
                checked={getIn(values, 'payment.flat')}
                dataTestId="payment-form-flat-price-choice"
                onChange={({ currentTarget }) => {
                  setFieldValue('payment.priceMutation', '');
                  if (currentTarget.checked) {
                    setFieldValue('payment.flat', true);
                    return;
                  }

                  setFieldValue('payment.flat', false);
                }}
              />
            )}
          </FieldLayout>
        </div>
        <div className="col col--sm-6">
          <FieldLayout
            name="payment.discountOrSurchargeValue"
            label={t('paymentForm.discountOrSurchargeAmount.label')}
            labelHtmlFor="discountOrSurchargeValue-input"
            feedback={{ invalid: errorFeedback(`payment.discountOrSurchargeValue`) }}
          >
            {(inputProps) => (
              <Input
                {...inputProps}
                status={priceMutationStatusCheck(inputProps.status)}
                id="discountOrSurchargeValue-input"
                dataTestId="payment-form-discount-or-surcharge-input"
                placeholder={t('paymentForm.discountOrSurchargeAmount.placeholder')}
              />
            )}
          </FieldLayout>
        </div>
        <div className="col col--sm-6">
          <FieldLayout
            name="payment.discountOrSurchargeValueType"
            label={t('paymentForm.discountOrSurchargeType.label')}
            labelHtmlFor="discountOrSurchargeValueType-input"
            feedback={{ invalid: errorFeedback(`payment.discountOrSurchargeValueType`) }}
          >
            {(inputProps) => {
              return (
                <Select
                  {...inputProps}
                  id="discountOrSurchargeValueType-input"
                  options={discountOrSurchargeValueTypeSelection}
                  status={priceMutationStatusCheck(inputProps.status)}
                />
              );
            }}
          </FieldLayout>
        </div>
      </div>
    </Details>
  );
};

export default PaymentForm;
