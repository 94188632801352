import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import FolderForm from '../../containers/FolderForm';
import useOfferFolderEditForFreeform, {
  FolderEditForFreeformProps,
} from './useOfferFolderEditForFreeform';
import { BaseValues } from '../../containers/FolderForm/values';
import ErrorState from '../../../../../containers/ErrorState';
import useOffer from '../../../hooks/offer';
import { OfferMode } from '../../../../../globalTypes';

interface Props {
  onRemoveFolderButtonClick: () => void;
  folderRemoving?: boolean;
  onSuccess: FolderEditForFreeformProps['onSuccess'];
  folderId: FolderEditForFreeformProps['folderId'];
  offerId: FolderEditForFreeformProps['offerId'];
  parentId?: string;
  offerMode: OfferMode;
  initialValues?: Partial<BaseValues>;
  onClickAliasRightIcon: () => void;
}

const OfferFolderEditForFreeform: FC<Props> = ({
  onSuccess,
  onRemoveFolderButtonClick,
  folderRemoving,
  folderId,
  offerId,
  parentId,
  offerMode,
  initialValues,
  onClickAliasRightIcon,
}) => {
  const { t } = useTranslation();
  const { editFolder, error } = useOfferFolderEditForFreeform({
    onSuccess,
    offerId,
    folderId,
    offerMode,
  });
  const { getFolderById } = useOffer();
  const folder = getFolderById(folderId);

  if (!folder) {
    return null;
  }

  const { viewerCanUpdateFolder, viewerCanRemoveFolder } = folder;

  return (
    <Fragment>
      {error && <ErrorState error={error} />}
      <FolderForm
        categories={[]}
        parentId={parentId}
        offerModeSystemNumber={offerMode === OfferMode.SYSTEM_NUMBER}
        onClickAliasRightIcon={onClickAliasRightIcon}
        onSubmit={editFolder}
        buttonText={t('editOfferFolderForFreeform.submitButton')}
        buttonIcon="save"
        initialValues={initialValues}
        onRemoveFolderButtonClick={viewerCanRemoveFolder ? onRemoveFolderButtonClick : undefined}
        folderRemoving={folderRemoving}
        renderSubmitButton={viewerCanUpdateFolder}
      />
    </Fragment>
  );
};

export default OfferFolderEditForFreeform;
