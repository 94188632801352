import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Icon, Paper, Text } from '@hs-baumappe/legacy-ui';
import { downloadDriveDocument } from './NoPreviewAvailable.utils';

interface NoPreviewAvailableProps {
  downloadUrl?: string;
  downloadName?: string;
}

export default function NoPreviewAvailable({
  downloadUrl,
  downloadName,
}: NoPreviewAvailableProps): JSX.Element {
  const { t } = useTranslation();
  const [documentDownloading, setDocumentDownloading] = useState(false);

  async function handleDownloadButtonClick() {
    if (!downloadUrl) {
      return;
    }

    setDocumentDownloading(true);

    await downloadDriveDocument(downloadUrl, downloadName);
    setDocumentDownloading(false);
  }

  return (
    <div className="row u-justify-content-center">
      <div className="col col--sm-8">
        <Paper className="u-padding-xsmall u-align-items-center u-display-flex u-flex-direction-column">
          <Icon name="file-copy" size={32} className="u-color-primary u-margin-top-xsmall" />
          <Text
            component="h3"
            color="muted"
            variant="headline-medium"
            className="u-margin-ends-small u-text-align-center"
          >
            {t('driveDocumentPreviewer.documentPreview.noPreviewAvailable')}
          </Text>

          {downloadUrl && (
            <div className="row u-justify-content-center u-width-100%">
              <div className="col col--sm-7">
                <Button
                  color="primary"
                  endIcon={<Icon name="cloud-download" />}
                  loading={documentDownloading}
                  onClick={handleDownloadButtonClick}
                  fullWidth
                >
                  {t('driveDocumentPreviewer.documentPreview.downloadButton')}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}
