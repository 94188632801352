import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { useNavigate } from 'react-router-dom';
import { SignatureFormValues } from '@hs-baumappe/forms';
import { getErrorsFromServerError } from '../../../../../apollo/errors';

import ConcernSignMutation from '../../graphql/ConcernSign.mutation';

import { ConcernSignInput } from '../../../../../globalTypes';
import base64toBlob from '../../../../../utils/b64toBlob';
import { concernRoutes } from '../../../concernRoutes';

// data:[<mediatype>][;base64],<data>
//                            ^- Until here
const DATA_URI_HEADER_PATTERN = /^[^,]+,/;

interface UseConcernSignResponse {
  sign: (values: SignatureFormValues, formikHelpers: FormikHelpers<SignatureFormValues>) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useConcernSign(concernId: string): UseConcernSignResponse {
  const navigate = useNavigate();
  const [mutate, { loading, error }] = useMutation(ConcernSignMutation);

  async function sign(
    { name, signature }: SignatureFormValues,
    formikHelpers: FormikHelpers<SignatureFormValues>,
  ) {
    if (loading) {
      return;
    }

    try {
      await mutate({
        variables: {
          input: {
            documentId: concernId,
            fullName: name,
            image: base64toBlob(signature.replace(DATA_URI_HEADER_PATTERN, ''), 'image/png'),
          },
        },
      });

      navigate(concernRoutes.detail.generatePath({ concernId }));
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<SignatureFormValues>,
        ConcernSignInput
      >(e, (errors) => {
        return {
          ...errors,
          name: errors.fullName,
          signature: errors.image,
        };
      });
      if (!formErrors) {
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sign,
    loading,
    error,
  };
}
