import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import useAcceptanceReportArchive from './hooks/useAcceptanceReportArchive';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { AcceptanceReportLocationState } from '../AcceptanceReport.route.types';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';

interface AcceptanceReportArchiveProps {
  acceptanceReport: AcceptanceReport;
}

export default function AcceptanceReportArchive({
  acceptanceReport,
}: AcceptanceReportArchiveProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const navigate = useNavigate();
  const location = useLocation<AcceptanceReportLocationState>();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(acceptanceReport.id);
  const { archive, loading: archiving, error } = useAcceptanceReportArchive(acceptanceReport.id);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(
      acceptanceReportRoutes.detail.generatePath({ acceptanceReportId: acceptanceReport.id }),
    );
  }, [location.state?.backgroundLocation, navigate, acceptanceReport.id]);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  if (!acceptanceReport.viewerCanArchive || !archiveEnabled) {
    return (
      <Navigate
        to={acceptanceReportRoutes.detail.generatePath({ acceptanceReportId: acceptanceReport.id })}
        replace
      />
    );
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = [
    {
      id: acceptanceReport.id,
      name: acceptanceReport.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('acceptanceReport.archive.title')}
        </Text>
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('archiveModals.informationText')}</Text>}
        rightSection={<Text variant="body-small">{t('archiveModals.warningText')}</Text>}
        initialValues={{
          username: sapCredentials?.username,
          password: sapCredentials?.password,
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
