import { useEffect } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';
import useSelectedTool from '../../hooks/useSelectedTool';

import eraserCursor from './eraser-cursor.svg';

export default function EraseTool(): JSX.Element {
  const { canvas } = useCanvas();
  const { selectedTool, setSelectedTool } = useSelectedTool();

  function handleClickEraserTool() {
    if (!canvas) {
      return;
    }

    if (selectedTool === 'eraser') {
      setSelectedTool(undefined);
      canvas.isDrawingMode = false;
      return;
    }

    setSelectedTool('eraser');
  }

  useEffect(() => {
    if (!canvas || selectedTool !== 'eraser') {
      return;
    }

    canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
    canvas.freeDrawingBrush.width = 30;
    canvas.freeDrawingCursor = `url("${eraserCursor}") 15 15, auto`;
    canvas.isDrawingMode = true;
  }, [canvas, selectedTool]);

  return (
    <Button
      color={selectedTool === 'eraser' ? 'warning' : undefined}
      contentClassName="u-display-flex u-align-items-center"
      onClick={handleClickEraserTool}
    >
      <Icon name="eraser" size={24} />
    </Button>
  );
}
