import { EmailSuggestion } from '@hs-baumappe/forms';
import useProjectEmailSuggestions from '../../../../hooks/useProjectEmailSuggestions';

export default function useEmailSuggestions(projectId: string): EmailSuggestion[][] {
  const { emailSuggestions } = useProjectEmailSuggestions(projectId);

  return [
    emailSuggestions.contactPeople.map((contactPerson) => ({
      ...contactPerson,
      name: contactPerson.name ?? undefined,
    })),
    emailSuggestions.recipients.map((recipient) => ({
      ...recipient,
      name: recipient.name ?? undefined,
    })),
  ].filter((suggestionSegment) => suggestionSegment.length > 0);
}
