import { TypedDocumentNode, gql } from '@apollo/client/core';
import {
  CustomFileDocumentSendEmail,
  CustomFileDocumentSendEmailVariables,
} from './__generated__/CustomFileDocumentEmailSend.mutation';

const CustomFileDocumentEmailSendMutation: TypedDocumentNode<
  CustomFileDocumentSendEmail,
  CustomFileDocumentSendEmailVariables
> = gql`
  mutation CustomFileDocumentSendEmail($input: DriveDocumentEmailSendInput!) {
    driveDocumentEmailSend(input: $input) {
      id
      status
    }
  }
`;

export default CustomFileDocumentEmailSendMutation;
