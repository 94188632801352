import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { SignatureFormValues } from '@hs-baumappe/forms';
import { AcceptanceReportSignatureType } from '../../../../../globalTypes';
import b64toBlob from '../../../../../utils/b64toBlob';
import { acceptanceReportRoutes } from '../../../acceptanceReportRoutes';
import AcceptanceReportUploadSignatureMutation from '../../graphql/AcceptanceReportUploadSignature.mutation';

interface AcceptanceReportSignDto {
  type: AcceptanceReportSignatureType;
  values: SignatureFormValues;
}

interface UseAcceptanceReportSignResponse {
  sign: (data: AcceptanceReportSignDto) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

// data:[<mediatype>][;base64],<data>
//                            ^- Until here
const DATA_URI_HEADER_PATTERN = /^[^,]+,/;

export default function useAcceptanceReportSign(
  acceptanceReportId: string,
): UseAcceptanceReportSignResponse {
  const navigate = useNavigate();
  const [mutate, { loading, error }] = useMutation(AcceptanceReportUploadSignatureMutation);

  async function sign({ type, values }: AcceptanceReportSignDto) {
    try {
      await mutate({
        variables: {
          input: {
            acceptanceReport: acceptanceReportId,
            fullName: values.name,
            type,
          },
          file: b64toBlob(values.signature.replace(DATA_URI_HEADER_PATTERN, ''), 'image/png'),
        },
      });

      navigate(acceptanceReportRoutes.detail.generatePath({ acceptanceReportId }));
    } catch (e) {}
  }

  return {
    sign,
    loading,
    error,
  };
}
