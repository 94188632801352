import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import AcceptanceReportLogQuery from './graphql/AcceptanceReportLog.query';
import LogContainer, { Log } from '../../../../../containers/LogContainer';
import logsDateMapper from '../../../../../containers/LogContainer/LogContainer.utils';

interface AcceptanceReportLogListDetailProps {
  acceptanceReportId: string;
}

export default function AcceptanceReportLogsListDetail({
  acceptanceReportId,
}: AcceptanceReportLogListDetailProps): JSX.Element {
  const [logs, setLogs] = useState<Log[]>([]);
  const { data, loading, refetch } = useQuery(AcceptanceReportLogQuery, {
    variables: {
      id: acceptanceReportId,
      page: 1,
      limit: 10,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data || !data.acceptanceReport.logs) {
      return;
    }

    setLogs([...logs, ...data.acceptanceReport.logs.logs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMoreDataClick = async () => {
    if (!data || !data.acceptanceReport.logs || !refetch) {
      return;
    }

    const currentPage = data.acceptanceReport.logs.pagination.current;

    await refetch({
      page: currentPage + 1,
    });
  };

  const logsDateData = logsDateMapper(logs);
  const hasNextPage =
    !loading &&
    !!data &&
    !!data.acceptanceReport.logs &&
    data.acceptanceReport.logs.pagination.hasNextPage;

  return (
    <LogContainer
      logs={logsDateData}
      hasNextPage={hasNextPage}
      onMoreDataClick={handleMoreDataClick}
      loading={loading}
    />
  );
}
