/*
 * Fits the x number which x ∈ [xMin, xMax] into
 * a number which y ∈ [a, b]
 * See https://en.wikipedia.org/wiki/Feature_scaling
 */
function futureScalingNormalization(
  x: number,
  xMin: number,
  xMax: number,
  a: number,
  b: number,
): number {
  return a + ((x - xMin) * (b - a)) / (xMax - xMin);
}

export default futureScalingNormalization;
