import { Viewer } from '@hs-baumappe/web-auth';
import createViewerSignature from '../../../containers/forms/createViewerSignature';

const createContentHTML = (viewer: Viewer): string => {
  return `
  <div>
    Sehr geehrte Damen und Herren,<br /><br />
  </div>
  <div>
    anbei übersenden wir Ihnen die Gefährdungsbeurteilung zu oben genanntem Bauvorhaben.<br /><br />
  </div>
  <div>
    Sollten Sie Fragen zu dieser Gefährdungsbeurteilung haben, antworten Sie bitte nicht auf diese Email.
  </div>
  ${viewer.signatureEmail && createViewerSignature(viewer)}
  `;
};

export default createContentHTML;
