import { Viewer } from '@hs-baumappe/web-auth';

import { DriveSpecialDocumentMimeTypes } from '../../Project/drive/drive.types';
import { DriveDocumentPreview } from './graphql/__generated__/DriveDocumentPreviewQuery';

type DriveDocument = NonNullable<DriveDocumentPreview['driveDocument']>;

export interface DriveScope {
  isPermitted: boolean;
}

export interface DriveScopeWithMissingScopes extends DriveScope {
  missingScopeNames: string[];
}

export function getDocumentViewAccess(
  document: DriveDocument,
  scopes: Viewer['scopes'],
): DriveScopeWithMissingScopes {
  switch (document.mimeType) {
    case DriveSpecialDocumentMimeTypes.ACCEPTANCE_REPORT:
      return scopes.viewAcceptanceReportDocument;
    case DriveSpecialDocumentMimeTypes.CONCERN:
      return scopes?.viewConcernDocument;
    case DriveSpecialDocumentMimeTypes.CONSTRUCTION_NOTE:
      return scopes?.viewConstructionNoteDocument;
    case DriveSpecialDocumentMimeTypes.OBSTRUCTION:
      return scopes?.viewObstructionDocument;
    case DriveSpecialDocumentMimeTypes.OFFER:
      return scopes?.viewOfferDocument;
    case DriveSpecialDocumentMimeTypes.OVERTIME:
      return scopes?.viewOvertimeDocument;
    case DriveSpecialDocumentMimeTypes.QSST:
      return scopes?.viewQsstDocument;
    case DriveSpecialDocumentMimeTypes.RISK_ASSESSMENT:
      return scopes?.viewRiskAssessmentDocument;
    default:
      return scopes?.viewCustomFileDocument;
  }
}

export function getDocumentDownloadAccess(
  document: DriveDocument,
  scopes: Viewer['scopes'],
): DriveScope {
  switch (document.mimeType) {
    case DriveSpecialDocumentMimeTypes.ACCEPTANCE_REPORT:
      return scopes?.downloadAcceptanceReportDocument;
    case DriveSpecialDocumentMimeTypes.CONCERN:
      return scopes?.downloadConcernDocument;
    case DriveSpecialDocumentMimeTypes.CONSTRUCTION_NOTE:
      return scopes?.downloadConstructionNoteDocument;
    case DriveSpecialDocumentMimeTypes.OBSTRUCTION:
      return scopes?.downloadObstructionDocument;
    case DriveSpecialDocumentMimeTypes.OFFER:
      return scopes?.downloadOfferDocument;
    case DriveSpecialDocumentMimeTypes.OVERTIME:
      return scopes?.downloadOvertimeDocument;
    case DriveSpecialDocumentMimeTypes.QSST:
      return scopes?.downloadQsstDocument;
    case DriveSpecialDocumentMimeTypes.RISK_ASSESSMENT:
      return scopes?.downloadRiskAssessmentDocument;
    default:
      return scopes?.downloadCustomFileDocument;
  }
}
