import { SchemaOf } from 'yup';
import { BaseValues } from './values';
import { MeasurementsSectionValidationSchema } from '../../../measurement/MeasurementsSection/validationSchema';
import { Measurement } from '../../../Detail/types';
import { OfferMode, OperationUnit } from '../../../../../globalTypes';
import { initialMeasurementValues } from '../../../measurement/MeasurementSection/values';
import yup from '../../../../../yup';

export const folderFormValidationSchema = (
  required: boolean,
  framework?: boolean,
  offerMode?: OfferMode,
): SchemaOf<BaseValues> => {
  const category = yup.string();
  const alias = yup
    .string()
    .max(2)
    .min(2)
    .test('is-int', 'validation.required', (v) => {
      if (!v) {
        return true;
      }

      return Number(v) % 1 === 0;
    });
  const categoryValidation = framework ? category.required() : category;

  const aliasValidation = offerMode === OfferMode.SYSTEM_NUMBER ? alias : alias.required();

  return yup.object({
    alias: aliasValidation,
    name: yup.string().required(),
    category: categoryValidation,
    note: yup.string(),
    measurements: MeasurementsSectionValidationSchema(required).default([]),
    approximate: yup.boolean().required(),
  });
};

export const defaultValues = (initialValues?: Partial<BaseValues>): BaseValues => {
  return {
    name: '',
    category: '',
    note: '',
    measurements: [],
    approximate: true,
    ...initialValues,
  };
};

export const castNote = (note?: string): string | undefined => {
  if (note === '<div><br></div>') {
    return undefined;
  }

  return note;
};

interface CastedMeasurementProps {
  type: OperationUnit;
  values: {
    multiplier?: number;
    total: string;
    subtraction: boolean;
    name: string;
    value: string[];
  };
}

export const castGqlForMeasurement = (
  measurements: Measurement[],
  type: OperationUnit,
  opType?: OperationUnit,
): CastedMeasurementProps[] => {
  if (opType) {
    if (opType === type)
      return measurements.map((m) => {
        return {
          type,
          values: {
            multiplier: m.multiplier,
            total: m.totalMeta,
            subtraction: m.subtraction,
            name: m.name,
            value: m.meta,
          },
        };
      });

    return [
      {
        type: opType,
        values: {
          ...initialMeasurementValues(),
          name: 'Menge Nr. 1',
        },
      },
    ];
  }

  return measurements.map((m) => {
    return {
      type,
      values: {
        multiplier: m.multiplier,
        total: m.totalMeta,
        subtraction: m.subtraction,
        name: m.name,
        value: m.meta,
      },
    };
  });
};

export type ModalType = 'CREATE_FOLDER' | 'CLONE_GROUP' | 'UPDATE_GROUP';
