import { fabric } from '@hs-baumappe/fabric';

export interface HistoryStatus {
  canUndo: boolean;
  canRedo: boolean;
}

type HistoryCallback = (status: HistoryStatus) => void;

class CanvasHistory {
  private canvas: fabric.Canvas;

  private historyUndo: string[];

  private historyRedo: string[];

  private historyNextState: string;

  private processing: boolean;

  private limit: number;

  public constructor(canvas: fabric.Canvas, limit: number, onHistoryChange: HistoryCallback) {
    this.canvas = canvas;

    this.historyRedo = [];
    this.historyUndo = [];
    this.processing = false;
    this.historyNextState = this.next();
    this.limit = limit || 100;

    canvas.on('object:added', (e: fabric.IEvent) => {
      if (!e.target) {
        return;
      }

      if (e.target.get('type') === 'textbox') {
        return;
      }

      this.save(onHistoryChange);
    });

    canvas.on('object:removed', () => {
      this.save(onHistoryChange);
    });

    canvas.on('object:moved', () => {
      this.save(onHistoryChange);
    });

    canvas.on('object:scaled', () => {
      this.save(onHistoryChange);
    });

    canvas.on('object:rotated', () => {
      this.save(onHistoryChange);
    });

    canvas.on('index:changed', () => {
      this.save(onHistoryChange);
    });

    canvas.on('selection:cleared', () => {
      this.save(onHistoryChange);
    });

    canvas.on('selection:updated', () => {
      this.save(onHistoryChange);
    });

    canvas.on('erasing:end', () => {
      this.save(onHistoryChange);
    });
  }

  public clear(callback: HistoryCallback): void {
    this.historyUndo = [];
    this.historyRedo = [];
    this.historyNextState = this.next();

    callback(this.canTakeAction());
  }

  public undo(callback: HistoryCallback): void {
    this.processing = true;
    const history = this.historyUndo.pop();

    if (!history) {
      this.processing = false;
      return;
    }

    this.historyRedo.push(this.next());
    this.historyNextState = history;

    callback(this.canTakeAction());

    this.load(history);
  }

  public redo(callback: HistoryCallback): void {
    this.processing = true;
    const history = this.historyRedo.pop();

    if (!history) {
      this.processing = false;
      return;
    }

    this.historyUndo.push(this.next());
    this.historyNextState = history;

    callback(this.canTakeAction());

    this.load(history);
  }

  public canTakeAction(): HistoryStatus {
    return {
      canRedo: this.historyRedo.length > 0,
      canUndo: this.historyUndo.length > 0,
    };
  }

  private save(onHistoryChange: HistoryCallback) {
    if (this.processing) {
      return;
    }

    this.historyRedo = [];

    setTimeout(() => {
      const next = this.next();
      if (this.historyNextState === next) {
        return;
      }

      if (this.historyUndo.length === this.limit) {
        this.historyUndo.shift();
      }

      this.historyUndo.push(this.historyNextState);
      this.historyNextState = next;

      onHistoryChange(this.canTakeAction());
    });
  }

  private load(history: string | undefined) {
    this.canvas.loadFromJSON(history, () => {
      this.canvas.renderAll();
      this.processing = false;
    });
  }

  private next() {
    return JSON.stringify(this.canvas.toDatalessJSON());
  }
}

export default CanvasHistory;
