import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikErrors, FormikHelpers } from 'formik';
import QsstSendMailBodyMutation from '../../QsstSendMailBody/graphql/QsstSendMailBody.mutation';
import { QsstDetailAlertType } from '../../../QsstDetail/QsstDetail.utils';
import { qsstRoutes } from '../../../qsstRoutes';
import { EmailFormValues } from '../../../../../containers/Email';
import { QsstSendMailInput } from '../../../../../globalTypes';
import { getErrorsFromServerError } from '../../../../../apollo/errors';

interface UseQsstSendEmailResponse {
  sendEmail: (
    formValues: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface UseQsstSendEmailOptions {
  qsstId: string;
  attachDocument: boolean;
}

export default function useQsstSendEmail({
  qsstId,
  attachDocument,
}: UseQsstSendEmailOptions): UseQsstSendEmailResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError>();
  const [mutate, { loading }] = useMutation(QsstSendMailBodyMutation);

  async function sendEmail(
    formValues: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: {
            recipients: formValues.toEmails,
            ccs: formValues.ccEmails,
            subject: formValues.subject,
            body: formValues.content,
            id: qsstId,
            attachDocument,
          },
        },
      });

      const searchParams = new URLSearchParams({ alert: QsstDetailAlertType.SEND });

      navigate(
        createPath({
          pathname: qsstRoutes.detail.generatePath({ qsstId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<FormikErrors<EmailFormValues>, QsstSendMailInput>(
        e,
        (errors) => {
          return {
            ...errors,
            toEmails: errors.recipients,
            ccEmails: errors.ccs,
          };
        },
      );

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendEmail,
    loading,
    error,
  };
}
