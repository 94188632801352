import { SchemaOf } from 'yup';
import yup from '../../yup';

export interface CachedData<T> {
  value: T;
  expiresAt?: number;
}

export interface CacheOptions<T> {
  validationSchema: SchemaOf<T>;
}

type CacheWithExpireTime<T> = T & {
  expiresAt?: Date;
};

export default abstract class Cache<T> {
  abstract getter: (key: string) => unknown;

  abstract setter: (key: string, value: CachedData<T>) => void;

  abstract remover: (key: string) => void;

  options: CacheOptions<T>;

  constructor(options: CacheOptions<T>) {
    this.options = options;
  }

  public get(key: string): CacheWithExpireTime<T> | undefined {
    const cache = this.getter(key);

    if (!cache) {
      return undefined;
    }

    if (!this.validateCache(cache)) {
      this.remover(key);

      return undefined;
    }

    return { ...cache.value, expiresAt: cache.expiresAt ? new Date(cache.expiresAt) : undefined };
  }

  public set(key: string, value: T, ttl?: number): void {
    const now = new Date().getTime();
    const expiresAt = ttl ? now + ttl : undefined;

    this.setter(key, { value, expiresAt });
  }

  public remove(key: string): void {
    this.remover(key);
  }

  private validateCache(value: unknown): value is CachedData<T> {
    const now = new Date().getTime();
    const schema: SchemaOf<CachedData<T>> = yup.object({
      value: this.options.validationSchema.defined(),
      expiresAt: yup.number().moreThan(now).optional(),
    });

    try {
      schema.validateSync(value);

      return true;
    } catch (e) {
      return false;
    }
  }
}
