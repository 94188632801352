import { ComponentProps, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import RouteDialog from '../../../../containers/RouteDialog';
import { projectsRoutes } from '../../../routes';

interface CreateDocumentErrorContainerProps extends ComponentProps<typeof ErrorRenderer> {}

export default function CreateDocumentErrorContainer(
  props: CreateDocumentErrorContainerProps,
): JSX.Element {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <RouteDialog
      open={open}
      onRequestClose={() => setOpen(false)}
      onClose={() => navigate(projectsRoutes.detail.generatePath({}))}
    >
      <ErrorRenderer {...props} />
    </RouteDialog>
  );
}
