import { Box, Checkbox, FlexGrid, FlexGridColumn, Stack, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import PartProjectHoursTable from './components/PartProjectHoursTable';
import useToggleShowSubProjectHours from './hooks/useToggleShowSubProjectHours';
import { GetProjectInformation } from '../graphql/__generated__/GetProjectInformation.query';
import { GetPartProjectsInformation } from '../graphql/__generated__/GetPartProjectsInformation.query';
import { sortPartProjectsByProjectNumber } from './PartProjects.utils';

interface PartProjectsProps {
  project: GetProjectInformation['project'];
  partProjects: GetPartProjectsInformation['project']['partProjects'];
  siv?: NonNullable<GetPartProjectsInformation['project']['siv']>;
  updateSIVPartProjectsPermitted: boolean;
  viewSIVPricesPermitted: boolean;
}

export default function PartProjects({
  project,
  partProjects,
  siv,
  updateSIVPartProjectsPermitted,
  viewSIVPricesPermitted,
}: PartProjectsProps): JSX.Element {
  const { t } = useTranslation();
  const { toggle } = useToggleShowSubProjectHours();

  function renderContent() {
    if (project.showSubProjectHours) {
      return (
        <Box stylex={{ overflowX: 'auto' }}>
          <PartProjectHoursTable
            project={project}
            partProjects={sortPartProjectsByProjectNumber(partProjects)}
            projectSIV={siv}
            showCostColumns={viewSIVPricesPermitted}
          />
        </Box>
      );
    }

    return (
      <FlexGrid>
        <FlexGridColumn xs={4}>
          <Text variant="body-small" color="muted">
            {t('projectDetail.projectInformation.partProjects.information')}
          </Text>
        </FlexGridColumn>
      </FlexGrid>
    );
  }

  return (
    <Stack gap="small-x">
      <div>
        <Checkbox
          color="primary"
          variant="filled"
          label={t('projectDetail.projectInformation.partProjects.checkbox')}
          checked={project.showSubProjectHours}
          onChange={() => toggle(project.id)}
          disabled={!updateSIVPartProjectsPermitted}
          disableExtraPressableArea
        />
      </div>

      {renderContent()}
    </Stack>
  );
}
