import { useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';
import { Box, FlexGrid, FlexGridColumn } from '@hs-baumappe/legacy-ui';
import AcceptanceReportFormSection from '../components/AcceptanceReportFormSection';
import FormikProfessionsField from '../../../../../components/FormikProfessionsField';

interface InformationSectionProps {
  professions: string[];
  draft: boolean;
}

export default function InformationSection({
  professions,
  draft,
}: InformationSectionProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <AcceptanceReportFormSection title={t('acceptanceReport.information.title')}>
      <FormikFormField
        name="name"
        label={t('acceptanceReport.information.name.label')}
        placeholder={t('acceptanceReport.information.name.placeholder')}
      />

      <FormikFormField
        name="description"
        label={t('acceptanceReport.information.description.label')}
        placeholder={t('acceptanceReport.information.description.placeholder')}
        className="u-margin-top-small"
      />
      <FlexGrid>
        <FlexGridColumn sm={6}>
          <FormikFormField
            name="projectNumber"
            label={t('acceptanceReport.information.projectNumber.label')}
            placeholder={t('acceptanceReport.information.projectNumber.placeholder')}
            contentEditable={draft}
            disabled={!draft}
            className="u-margin-top-small"
          />
        </FlexGridColumn>
        <FlexGridColumn sm={6}>
          <FormikFormField
            type="date"
            name="date"
            label={t('acceptanceReport.information.date.label')}
            placeholder={t('acceptanceReport.information.date.placeholder')}
            className="u-margin-top-small"
          />
        </FlexGridColumn>
        <FlexGridColumn sm={6}>
          <FormikFormField
            name="client"
            label={t('client.title')}
            placeholder={t('client.title')}
            className="u-margin-top-small"
          />
        </FlexGridColumn>
        <FlexGridColumn sm={6}>
          <FormikFormField
            name="contractor"
            label={t('acceptanceReport.information.contractor.label')}
            placeholder={t('acceptanceReport.information.contractor.placeholder')}
            className="u-margin-top-small"
          />
        </FlexGridColumn>
        <FlexGridColumn sm={6}>
          <Box stylex={{ marginTop: 'small-x' }}>
            <FormikProfessionsField name="toProfessions" professions={professions} />
          </Box>
        </FlexGridColumn>
      </FlexGrid>
    </AcceptanceReportFormSection>
  );
}
