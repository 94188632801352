import { gql, TypedDocumentNode } from '@apollo/client';
import { ConcernProject } from './__generated__/ConcernProject.fragment';

const ConcernProjectFragment: TypedDocumentNode<ConcernProject> = gql`
  fragment ConcernProject on Project {
    id

    draft
    name
    description
    projectNumber
    hsProjectNumber
  }
`;

export default ConcernProjectFragment;
