import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROJECT_IMPORT = gql`
  mutation ImportProject($input: ImportProjectInput!) {
    importProject(input: $input) {
      id
    }
  }
`;
