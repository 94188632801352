import { OfferLog } from '../../graphql/__generated__/OfferLog.fragment';
import { Log } from '../Log.types';

const OFFER_LOG_TYPES = [
  'OfferCreatedLog',
  'OfferDeletedLog',
  'OfferEmailSentLog',
  'OfferExportedToBotLog',
];

export function isOfferLog(log: Log): log is OfferLog {
  return OFFER_LOG_TYPES.includes(log.__typename);
}
