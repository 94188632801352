import { Box, Checkbox, FlexGrid, FlexGridColumn, Stack, Text } from '@hs-baumappe/legacy-ui';
import { Trans, useTranslation } from 'react-i18next';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import useSIVDiscountDetail from './hooks/useSIVDiscountDetail';
import useSIVDiscount from './hooks/useSIVDiscount';
import ErrorState from '../../../../../../containers/ErrorState';
import SIVDiscountBody from './components/SIVDiscountBody';
import { formatPriceEur } from '../../../../../../utils/number';

interface SIVDiscountProps {
  projectId: string;
  sivId?: string;
  updateSIVDiscountPermitted: boolean;
}

export default function SIVDiscount({
  projectId,
  sivId,
  updateSIVDiscountPermitted,
}: SIVDiscountProps): JSX.Element {
  const { t } = useTranslation();
  const { setDiscountAmount, toggleDiscountActive, error: saveError } = useSIVDiscount(sivId);
  const {
    discount,
    totalPerformanceCostPlannedRaw,
    totalPerformanceCostPlanned,
    totalPerformanceCostRecorded,
    loading,
    error,
  } = useSIVDiscountDetail({ projectId, skip: !sivId });

  if (loading) {
    return <LayoutLoading />;
  }

  async function handleSetDiscountAmount(nextAmount?: number) {
    if (discount.amount === nextAmount) {
      return;
    }

    await setDiscountAmount(nextAmount);
  }

  function renderContent() {
    if (!discount.active) {
      return (
        <Text color="muted">{t('projectDetail.projectInformation.sivDiscount.information2')}</Text>
      );
    }

    return (
      <SIVDiscountBody
        discountAmount={discount.amount}
        totalPerformanceCostPlannedRaw={totalPerformanceCostPlannedRaw}
        totalPerformanceCostPlanned={totalPerformanceCostPlanned}
        totalPerformanceCostRecorded={totalPerformanceCostRecorded}
        updateSIVDiscountPermitted={updateSIVDiscountPermitted}
        onSetDiscountAmount={handleSetDiscountAmount}
      />
    );
  }

  return (
    <>
      {saveError && <ErrorState error={saveError} />}

      <FlexGrid>
        <FlexGridColumn xs={4}>
          <Stack gap="small-2x" className="qa-project-siv-discount">
            {!error && sivId && (
              <Text variant="label-small" color="muted">
                <Trans
                  i18nKey="projectDetail.projectInformation.sivDiscount.information"
                  values={{
                    price: formatPriceEur(totalPerformanceCostPlannedRaw).replace('€', ',-€'),
                  }}
                >
                  {[<Box key="price" component="span" stylex={{ whiteSpace: 'nowrap' }} />]}
                </Trans>
              </Text>
            )}

            <div>
              <Checkbox
                label={t('projectDetail.projectInformation.sivDiscount.checkbox')}
                checked={!error && sivId ? discount.active : false}
                variant="filled"
                disableExtraPressableArea
                disabled={!updateSIVDiscountPermitted || !!error}
                onChange={toggleDiscountActive}
              />
            </div>

            {renderContent()}
          </Stack>
        </FlexGridColumn>
      </FlexGrid>
    </>
  );
}
