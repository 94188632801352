import { gql, TypedDocumentNode } from '@apollo/client';
import { QsstSave, QsstSaveVariables } from './__generated__/QsstSave.mutation';
import QsstFragment from '../../../graphql/Qsst.fragment';

const QsstSaveMutation: TypedDocumentNode<QsstSave, QsstSaveVariables> = gql`
  mutation QsstSave($input: QsstSaveInput!) {
    qsstSave(input: $input) {
      ...Qsst
    }
  }

  ${QsstFragment}
`;

export default QsstSaveMutation;
