import { Alert, Icon, Stack, useTimer } from '@hs-baumappe/legacy-ui';
import { useEffect } from 'react';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';
import useQsstDetailAlert from './hooks/useQsstDetailAlert';

interface QsstDetailAlertsProps {
  isManuallySigned: boolean;
}

export function QsstDetailAlerts({ isManuallySigned }: QsstDetailAlertsProps): JSX.Element | null {
  const { t } = useTranslation();
  const { alert, clearAlert } = useQsstDetailAlert();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  useEffect(() => {
    if (!alert) {
      return;
    }

    setTimeoutTimer(clearAlert, 5000);

    return () => clearAllTimers();
  }, [alert, clearAlert, clearAllTimers, setTimeoutTimer]);

  if (!alert && !isManuallySigned) {
    return null;
  }

  return (
    <Stack gap="medium">
      {alert && (
        <Alert color="success" endSection={<Icon name="check" />}>
          {t(`qsstDetailAlerts.${alert}` as ParseKeys<'translation'>)}
        </Alert>
      )}

      {isManuallySigned && <Alert color="warning">{t('qsstDetailAlerts.manuallySigned')}</Alert>}
    </Stack>
  );
}
