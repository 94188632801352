import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialog, Icon, IconButton, Paper, Text, Textarea } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';

interface ObstructionFormFactProps {
  name: string;
  onRequestRemove?: () => void;
}

export default function ObstructionFormFact({
  name,
  onRequestRemove,
}: ObstructionFormFactProps): JSX.Element {
  const { t } = useTranslation();
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  function handleClickRemoveButton() {
    setAlertDialogOpen(true);
  }

  function handleAlertDialogCancel() {
    setAlertDialogOpen(false);
  }

  function handleAlertDialogConfirm() {
    setAlertDialogOpen(false);

    if (onRequestRemove) {
      onRequestRemove();
    }
  }

  return (
    <>
      <Paper className="u-margin-bottom u-padding-xsmall">
        <div className="u-display-flex u-align-items-center u-margin-bottom">
          <Text variant="title-small">{t('obstructionForm.fact.cardTitle')}</Text>

          {onRequestRemove && (
            <IconButton
              icon={<Icon name="close" />}
              onClick={handleClickRemoveButton}
              data-testid="remove-fact-button"
              type="button"
              className="u-margin-left-auto"
              size="xsmall"
            />
          )}
        </div>

        <div className="row">
          <div className="col col--sm-6">
            <FormikFormField
              name={`${name}.title`}
              label={t('obstructionForm.fact.title.label')}
              placeholder={t('obstructionForm.fact.title.placeholder')}
            />
          </div>
        </div>

        <div className="u-margin-top-small">
          <FormikFormField
            control={Textarea}
            name={`${name}.description`}
            label={t('obstructionForm.fact.description.label')}
            placeholder={t('obstructionForm.fact.description.placeholder')}
            minRows={4}
            maxRows={12}
          />
        </div>
      </Paper>

      <AlertDialog
        destructive
        open={alertDialogOpen}
        title={t('obstructionForm.fact.confirmationModal.title')}
        description={t('obstructionForm.fact.confirmationModal.body')}
        confirmButtonText={t('obstructionForm.fact.confirmationModal.confirmButton')}
        cancelButtonText={t('obstructionForm.fact.confirmationModal.dismissButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={handleAlertDialogCancel}
        onCancel={handleAlertDialogCancel}
        onConfirm={handleAlertDialogConfirm}
      />
    </>
  );
}
