import { ChangeEvent, useRef, useState } from 'react';
import { Details, FlexGrid, FlexGridColumn, Stack, Summary, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useMutation } from '@apollo/client';
import { AcceptanceReportFormValues } from '../AcceptanceReportFormValues';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../../../components/DriveImageGalleryContainer';
import { createEmptyImageWithLabel } from '../../../../../containers/forms/ImageUploadWithLabel';
import ErrorState from '../../../../../containers/ErrorState';
import AcceptanceReportConvertImageAttachmentsMutation from './graphql/AcceptanceReportConvertImageAttachments.mutation';
import AcceptanceReportFormImageAttachmentMenu from './components/AcceptanceReportFormImageAttachmentMenu';
import AcceptanceReportFormImageAttachment from './components/AcceptanceReportFormImageAttachment';

interface ImageAttachmentsSectionProps {
  projectId: string;
}

export default function ImageAttachmentsSection({
  projectId,
}: ImageAttachmentsSectionProps): JSX.Element {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState(false);
  const [, imagesFieldMeta, imagesFieldHelpers] =
    useField<AcceptanceReportFormValues['images']>('images');
  const [convertDriveImagesMutation, { loading, error }] = useMutation(
    AcceptanceReportConvertImageAttachmentsMutation,
  );

  async function handleAddImagesFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const files = Array.from(event.target.files);
    const newImages = files.map((file) => createEmptyImageWithLabel({ file }));

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    await imagesFieldHelpers.setValue([...imagesFieldMeta.value, ...newImages]);
  }

  async function convertImages(selectedDocuments: string[]) {
    const { data } = await convertDriveImagesMutation({
      variables: {
        input: selectedDocuments,
      },
    });

    if (!data) {
      return;
    }

    return data.convertCustomFilesToImages;
  }

  async function handleDocumentsConverted(selectedDocuments: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    const documentIds = selectedDocuments.map(({ id }) => id);
    const convertedImages = await convertImages(documentIds);

    if (!convertedImages) {
      return;
    }

    const imageAttachments: AcceptanceReportFormValues['images'] = convertedImages.map(
      (convertedImage, index) => {
        const selectedDocument = selectedDocuments[index];

        return createEmptyImageWithLabel({
          imageId: convertedImage.id,
          label: selectedDocument?.name || '',
          imageUrl: convertedImage.url,
          imageThumbnailUrl: convertedImage.url,
        });
      },
    );

    await imagesFieldHelpers.setValue([...imagesFieldMeta.value, ...imageAttachments]);
  }

  async function removeImageAttachmentByIndex(index: number) {
    const imageAttachments = [...imagesFieldMeta.value];

    imageAttachments.splice(index, 1);

    await imagesFieldHelpers.setValue(imageAttachments);
  }

  function handleUploadImageButtonClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const imagesAdded = imagesFieldMeta.value.length > 0;
  const showRemoveButton = imagesFieldMeta.value.length > 1;
  const imagesAddedOnInitial = (imagesFieldMeta.initialValue || []).some(
    ({ imageId }) => !!imageId,
  );

  return (
    <Details
      defaultOpen={imagesAddedOnInitial}
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="title-small" color="muted" component="h3" stylex={{ marginBottom: '0' }}>
            {t('acceptanceReport.imageAttachments.title')}
          </Text>
        </Summary>
      )}
    >
      {error && <ErrorState error={error} />}

      <FlexGrid>
        <FlexGridColumn sm={5}>
          <Text component="p">{t('acceptanceReport.imageAttachments.helperText')}</Text>
        </FlexGridColumn>
      </FlexGrid>

      <Stack stylex={{ marginTop: 'medium' }} gap="small-3x">
        {imagesFieldMeta.value.map((imageAttachment, index) => (
          <AcceptanceReportFormImageAttachment
            key={imageAttachment.orderId}
            name={`images.${index}`}
            onRequestRemove={async () => removeImageAttachmentByIndex(index)}
            showRemoveButton={showRemoveButton}
          />
        ))}
      </Stack>

      <FlexGrid stylex={{ marginTop: 'small-3x' }}>
        <FlexGridColumn sm={5}>
          <AcceptanceReportFormImageAttachmentMenu
            imagesAdded={imagesAdded}
            onUploadImageButtonClick={handleUploadImageButtonClick}
            onSelectImageButtonClick={() => setDriveImageGalleryOpen(true)}
            loading={loading}
          />
        </FlexGridColumn>
      </FlexGrid>

      <input
        ref={fileInputRef}
        type="file"
        className="u-visually-hidden"
        accept="image/jpeg, image/png, image/jpg"
        multiple
        onChange={handleAddImagesFileInputChange}
      />

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsConverted}
      />
    </Details>
  );
}
