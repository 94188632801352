import { useCallback, useMemo, useState } from 'react';
import { ApolloError, NetworkStatus, isApolloError, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { HSProject } from '../../graphql/__generated__/HSProject.fragment';
import SearchHSProjectsQuery from './graphql/SearchHSProjects.query';
import { SearchHSProjectsVariables } from './graphql/__generated__/SearchHSProjects.query';

interface UseProjectImportSearchProjectsOptions {
  onError: (error: ApolloError) => void;
}

interface UseProjectImportSearchProjectsResponse {
  projects: HSProject[];
  searchProjects: (searchTerm: string) => void;
  fetchNextPage: (variables: SearchHSProjectsVariables) => Promise<void>;
  loading: boolean;
  loadingNextPage: boolean;
  hasNextPage: boolean;
  error?: ApolloError;
}

export const MIN_TERM_LENGTH = 2;

export default function useProjectImportSearchProjects({
  onError,
}: UseProjectImportSearchProjectsOptions): UseProjectImportSearchProjectsResponse {
  const [serverError, setServerError] = useState<ApolloError>();
  const [query, { data, loading, networkStatus, fetchMore }] = useLazyQuery(SearchHSProjectsQuery, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setServerError(error);
      onError(error);
    },
  });

  const initialLoading = loading && networkStatus !== NetworkStatus.fetchMore;
  const loadingNextPage = loading && networkStatus === NetworkStatus.fetchMore;

  const searchProjects = useCallback(
    async (searchTerm: string) => {
      setServerError(undefined);

      if (searchTerm.length > MIN_TERM_LENGTH) {
        await query({ variables: { term: searchTerm } });
      }
    },
    [query],
  );

  const debouncedProjectSearch = useMemo(() => debounce(searchProjects, 500), [searchProjects]);

  async function fetchNextPage(variables: SearchHSProjectsVariables) {
    if (!data || loadingNextPage || serverError) {
      return;
    }

    setServerError(undefined);

    const nextContinuationToken = data.searchHsProjects.continuationToken;
    const nextVariables: SearchHSProjectsVariables = {
      ...variables,
      continuationToken: nextContinuationToken,
    };

    try {
      await fetchMore({
        variables: nextVariables,
        updateQuery: (previous, { fetchMoreResult: incoming }) => {
          const previousProjects = previous.searchHsProjects.projects ?? [];
          const incomingProjects = incoming.searchHsProjects.projects ?? [];
          return {
            searchHsProjects: {
              ...incoming.searchHsProjects,
              projects: [...previousProjects, ...incomingProjects],
            },
          };
        },
      });
    } catch (error) {
      if (error instanceof Error && isApolloError(error)) {
        setServerError(error);
        onError(error);
      }
    }
  }

  return {
    searchProjects: debouncedProjectSearch,
    projects: data?.searchHsProjects.projects ?? [],
    loading: initialLoading,
    fetchNextPage,
    hasNextPage: !!data?.searchHsProjects.continuationToken,
    loadingNextPage,
    error: serverError,
  };
}
