import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useConcernArchive from './hooks/useConcernArchive';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm/ArchiveForm';
import { concernRoutes } from '../concernRoutes';
import { ConcernLocationState } from '../Concern.route.types';
import { Concern } from '../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../graphql/__generated__/ConcernProject.fragment';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';

interface ConcernArchiveProps {
  concern: Concern;
  project: ConcernProject;
}

export default function ConcernArchive({ concern, project }: ConcernArchiveProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const location = useLocation<ConcernLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(concern.id);
  const { archive, loading: archiving, error } = useConcernArchive(concern.id);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(concernRoutes.detail.generatePath({ concernId: concern.id }));
  }, [concern.id, location.state?.backgroundLocation, navigate]);

  if (!concern.viewerCanArchive || !archiveEnabled) {
    return <Navigate to={concernRoutes.detail.generatePath({ concernId: concern.id })} replace />;
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = [
    {
      id: concern.id,
      name: concern.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('concern.archive.route.subtitle', { name: project.name })}
          </Text>
        </>
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('concern.archive.route.description')}</Text>}
        rightSection={<Text variant="body-small">{t('concern.archive.route.info')}</Text>}
        initialValues={{
          username: sapCredentials?.username,
          password: sapCredentials?.password,
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
