import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADD_OPERATION_TO_FOLDER = gql`
  mutation AddOperationToFolder($input: AddOperationToFolderInput!) {
    addOperationToFolder(input: $input) {
      id
    }
  }
`;
