import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import ConcernSaveMutation from './graphql/ConcernSaveMutation';
import { concernRoutes } from '../../concernRoutes';
import { ConcernSaveInput } from '../../../../globalTypes';
import { ConcernFormValues } from '../../forms/ConcernForm/ConcernForm.types';
import { getErrorsFromServerError } from '../../../../apollo/errors';
import { createConcernSaveInput } from '../../forms/ConcernForm/ConcernForm.utils';

interface UseConcernSaveResponse {
  save: (formValues: ConcernFormValues, formikHelpers: FormikHelpers<ConcernFormValues>) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useConcernSave(concernId: string): UseConcernSaveResponse {
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(ConcernSaveMutation);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function save(
    formValues: ConcernFormValues,
    formikHelpers: FormikHelpers<ConcernFormValues>,
  ) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: createConcernSaveInput({ formValues, concernId }),
        },
      });

      navigate(concernRoutes.detail.generatePath({ concernId }));
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ConcernFormValues>,
        ConcernSaveInput
      >(e, (errors) => {
        return {
          ...errors,
          toProfessions: errors.professions,
          qualities: errors.quality,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    save,
    loading,
    error,
  };
}
