export const CONSTRUCTION_NOTE_SUPPORTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/svg+xml',
  'image/gif',
];

export const CONSTRUCTION_NOTE_UNSUPPORTED_IMAGE_TYPES = [
  'image/heic',
  'image/heif',
  'image/tiff',
  'image/tif',
];
