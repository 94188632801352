import { ApolloError, isApolloError, useMutation } from '@apollo/client';

import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { createPath, useNavigate } from 'react-router-dom';
import AddContactPersonMutation from './graphql/AddContactPerson.mutation';
import { getErrorsFromServerError } from '../../../../apollo/errors';
import { ContactPersonAddInput } from '../../../../globalTypes';
import { AddContactPersonFormValues } from './AddContactPersonForm/AddContactForm.types';
import {
  ContactPersonAdd,
  ContactPersonAddVariables,
} from './graphql/__generated__/AddContactPerson.mutation';
import { projectRoutes } from '../../../routes';

interface UseAddContactPersonResponse {
  addContactPerson: (
    values: AddContactPersonFormValues,
    formikHelpers: FormikHelpers<AddContactPersonFormValues>,
  ) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useAddContactPerson(projectId: string): UseAddContactPersonResponse {
  const navigate = useNavigate();
  const [mutation, { loading, error }] = useMutation<ContactPersonAdd, ContactPersonAddVariables>(
    AddContactPersonMutation,
  );

  async function addContactPerson(
    values: AddContactPersonFormValues,
    formikHelpers: FormikHelpers<AddContactPersonFormValues>,
  ) {
    if (loading) {
      return;
    }

    try {
      await mutation({
        variables: {
          input: {
            projectId,
            ...values,
          },
        },
      });
      const searchParams = new URLSearchParams({ tab: 'information' });

      navigate(
        createPath({
          pathname: projectRoutes.detail.generatePath({ projectId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<AddContactPersonFormValues>,
        ContactPersonAddInput
      >(e, (errors) => errors);
      if (!formErrors) {
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    addContactPerson,
    loading,
    error,
  };
}
