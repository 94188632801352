import { ProjectFilterFormValues } from './ProjectFilterForm/ProjectFilterForm.form';
import { ProjectStatus } from '../../../../../globalTypes';
import { ProjectFilterProfitCenter } from './graphql/__generated__/ProjectFilterProfitCenter.fragment';
import { ProjectFilterSelection } from '../ProjectFilter.types';

export const PROJECT_STATUSES = [
  ProjectStatus.REGISTERED,
  ProjectStatus.ORDERED,
  ProjectStatus.OFFERED,
  ProjectStatus.ACTIVE_CONSTRUCTION,
  ProjectStatus.CLOSED,
  ProjectStatus.CANCELED,
];

export const PROJECT_FILTER_FORM_PRESETS = [
  {
    label: 'projectFilterForm.presets.onlyActiveProjects',
    statuses: [
      ProjectStatus.REGISTERED,
      ProjectStatus.ORDERED,
      ProjectStatus.OFFERED,
      ProjectStatus.ACTIVE_CONSTRUCTION,
    ],
  },
];

export function getFilterFormInitialValues(
  filterSelection: ProjectFilterSelection,
  profitCenters: ProjectFilterProfitCenter[],
): Partial<ProjectFilterFormValues> {
  const selectedStatuses = filterSelection.statuses.map(({ status }) => status);
  const selectedProfitCenters = filterSelection.profitCenters.map(
    ({ profitCenter }) => profitCenter.id,
  );

  return {
    statuses: PROJECT_STATUSES.map((projectStatus) => ({
      value: projectStatus,
      checked: selectedStatuses.includes(projectStatus),
    })),
    profitCenters: profitCenters.map((profitCenter) => ({
      value: profitCenter.id,
      label: profitCenter.name,
      checked: selectedProfitCenters.includes(profitCenter.id),
    })),
  };
}
