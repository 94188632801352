import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ProjectDriveMimeTypeFolders,
  ProjectDriveMimeTypeFoldersVariables,
} from './__generated__/ProjectDriveMimeTypeFolders.query';

const ProjectDriveMimeTypeFoldersQuery: TypedDocumentNode<
  ProjectDriveMimeTypeFolders,
  ProjectDriveMimeTypeFoldersVariables
> = gql`
  query ProjectDriveMimeTypeFolders($projectId: ID!) {
    project(id: $projectId) {
      id

      drive {
        id

        mimeTypeFolderMap {
          folder {
            id
            name
          }
          mimeType
        }
      }
    }
  }
`;

export default ProjectDriveMimeTypeFoldersQuery;
