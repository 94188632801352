import { forwardRef } from 'react';
import cx from 'classnames';

export type TrProps = Omit<JSX.IntrinsicElements['tr'], 'ref'> & {
  selected?: boolean;
  sticky?: boolean;
};

export default forwardRef<HTMLTableRowElement, TrProps>(function Tr(
  { className, children, selected, sticky, onClick, onKeyPress, ...otherProps },
  ref,
) {
  const handleKeyPress: JSX.IntrinsicElements['tr']['onKeyPress'] = (event) => {
    // trigger click event when the enter or space key pressed
    if (event.key === 'Enter' || event.key === ' ') {
      event.currentTarget.click();

      if (event.key === ' ') {
        event.preventDefault();
      }
    }

    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  return (
    <tr
      ref={ref}
      className={cx('c-table__tr', { 'is-selected': selected, 'is-sticky': sticky }, className)}
      {...(onClick
        ? {
            onClick,
            role: 'button',
            tabIndex: 0,
            onKeyPress: handleKeyPress,
          }
        : null)}
      {...otherProps}
    >
      {children}
    </tr>
  );
});
