import { ApolloError, useMutation } from '@apollo/client';
import RemoveManuallyAcceptanceReportMutation from '../graphql/RemoveManuallyAcceptanceReport.mutation';

interface RemoveAcceptanceReportManuallyParams {
  remove: () => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useRemoveAcceptanceReportManually(
  acceptanceReportId: string,
  onSuccess: () => void,
): RemoveAcceptanceReportManuallyParams {
  const [removeMutation, { loading, error }] = useMutation(RemoveManuallyAcceptanceReportMutation);

  async function remove() {
    if (loading) {
      return;
    }

    await removeMutation({
      variables: {
        input: {
          acceptanceReportId,
        },
      },
    });

    onSuccess();
  }

  return { remove, loading, error };
}
