import { PropsWithChildren, ReactNode } from 'react';
import { FlexGrid, FlexGridColumn } from '@hs-baumappe/legacy-ui';

interface AcceptanceReportFormLayoutProps {
  information: ReactNode;
  attendee: ReactNode;
  defect: ReactNode;
  accept: ReactNode;
  imageAttachments: ReactNode;
}

export default function AcceptanceReportFormLayout({
  information,
  attendee,
  defect,
  accept,
  imageAttachments,
  children,
}: PropsWithChildren<AcceptanceReportFormLayoutProps>): JSX.Element {
  return (
    <FlexGrid>
      <FlexGridColumn md={8}>
        <div>{information}</div>
        <div>{attendee}</div>
        <div>{accept}</div>
        <div>{defect}</div>
        <div>{imageAttachments}</div>
      </FlexGridColumn>
      <FlexGridColumn md={4}>{children}</FlexGridColumn>
    </FlexGrid>
  );
}
