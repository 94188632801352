import { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes as BaseRoutes, useLocation } from 'react-router-dom';
import { RequireAuth } from '@hs-baumappe/web-auth';
import { NotFoundErrorPage } from '@hs-baumappe/redkit';

import ScrollToTop from './ScrollToTop';

import Project from './Project';
import Offer from './Offer';
import AcceptanceReport from './AcceptanceReport';
import RiskAssessment from './RiskAssessment';

import routes from './routes';
import ConstructionNote from './ConstructionNote';

import CustomFile from './CustomFile';
import Obstruction from './Obstruction';
import Concern from './Concern';
import DriveDocumentPreview from './DriveDocumentPreview';
import { RouteLocationParams } from './routes.types';
import Document from './Document/Document';
import Qsst from './Qsst/Qsst';
import QsstSign from './Qsst/QsstSign';
import Projects from './Projects';
import OvertimeCustomerRedirect from './Overtime/OvertimeCustomer/OvertimeCustomerRedirect';

const Catalogues = lazy(() => import('./Catalogues'));
const Settings = lazy(() => import('./Settings'));
const AcceptanceReportCustomer = lazy(() => import('./AcceptanceReport/AcceptanceReportCustomer'));

const Routes = (): JSX.Element => {
  const location = useLocation<RouteLocationParams>();
  const { state } = location;

  return (
    <Fragment>
      {state && !state.disableScrollToTop && <ScrollToTop />}

      <Suspense fallback={null}>
        <BaseRoutes>
          <Route index element={<Navigate to={routes.projects.path} />} />
          <Route path="project-overview" element={<Navigate to={routes.projects.path} />} />

          <Route
            path="acceptance-report/customer-detail/:acceptanceReportId/*"
            element={<AcceptanceReportCustomer />}
          />
          <Route path="qsst/customer-detail/:qsstId" element={<QsstSign />} />

          <Route
            path="projects/*"
            element={
              <RequireAuth>
                <Projects />
              </RequireAuth>
            }
          />
          <Route
            path="project/:projectId/*"
            element={
              <RequireAuth>
                <Project />
              </RequireAuth>
            }
          />

          <Route
            path="custom-file/:documentId"
            element={
              <RequireAuth>
                <CustomFile />
              </RequireAuth>
            }
          />

          <Route
            path="concern/:concernId/*"
            element={
              <RequireAuth>
                <Concern />
              </RequireAuth>
            }
          />
          <Route
            path="offer/:offerId/*"
            element={
              <RequireAuth>
                <Offer />
              </RequireAuth>
            }
          />

          <Route
            path="obstruction/:obstructionId/*"
            element={
              <RequireAuth>
                <Obstruction />
              </RequireAuth>
            }
          />

          <Route
            path="qsst/:qsstId/*"
            element={
              <RequireAuth>
                <Qsst />
              </RequireAuth>
            }
          />

          <Route
            path="acceptance-report/:acceptanceReportId/*"
            element={
              <RequireAuth>
                <AcceptanceReport />
              </RequireAuth>
            }
          />

          <Route
            path="risk-assessment/:riskAssessmentId/*"
            element={
              <RequireAuth>
                <RiskAssessment />
              </RequireAuth>
            }
          />

          <Route
            path="construction-note/:constructionNoteId/*"
            element={
              <RequireAuth>
                <ConstructionNote />
              </RequireAuth>
            }
          />

          <Route
            path="catalogues/*"
            element={
              <RequireAuth>
                <Catalogues />
              </RequireAuth>
            }
          />
          <Route
            path="settings/*"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="document-preview/:documentId/*"
            element={
              <RequireAuth>
                <DriveDocumentPreview />
              </RequireAuth>
            }
          />
          <Route
            path="document/:documentId/*"
            element={
              <RequireAuth>
                <Document />
              </RequireAuth>
            }
          />

          {/*
           * The routes that start with /external (ex. overtime) are moved to nearby their codebase.
           * We changed their route path corresponding with the new architecture.
           * To supply consistency on the route system, we're redirecting the old route paths into the new ones.
           * We added as string term on line 43 purposely, if the location match with the routes.publicOvertime path
           * then there is an overtimeId as defined.
           */}
          <Route path="external/overtime/:overtimeId" element={<OvertimeCustomerRedirect />} />

          <Route path="*" element={<NotFoundErrorPage />} />
        </BaseRoutes>
      </Suspense>
    </Fragment>
  );
};

export default Routes;
