import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertDialog, Box, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { RiskAssessmentTab } from '../RiskAssessmentForm/useRiskAssessmentForm';
import RouteDialog from '../../../containers/RouteDialog';
import RiskAssessmentEditBody from './components/RiskAssessmentEditBody';
import { riskAssessmentRoutes } from '../riskAssessmentRoutes';
import {
  RiskAssessmentLocationState,
  RiskAssessmentRouteParams,
} from '../RiskAssessment.route.type';
import { RiskAssessment } from '../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../graphql/__generated__/RiskAssessmentProject.fragment';
import { RiskAssessmentScopes } from '../RiskAssessmentDetail/types';
import { navigateToLocation } from '../../routes.utils';

interface RiskAssessmentEditProps {
  riskAssessment: RiskAssessment;
  project: RiskAssessmentProject;
  scopes: RiskAssessmentScopes;
}

export default function RiskAssessmentEdit({
  project,
  scopes,
}: RiskAssessmentEditProps): JSX.Element {
  const { t } = useTranslation();

  const { riskAssessmentId } = useParams<RiskAssessmentRouteParams>();
  const location = useLocation<RiskAssessmentLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [activeStep, setActiveStep] = useState<RiskAssessmentTab>(RiskAssessmentTab.Information);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  function handleDialogRequestClose() {
    if (activeStep !== RiskAssessmentTab.Information || formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: riskAssessmentRoutes.detail.generatePath({ riskAssessmentId }),
    });
  }, [navigate, location.state, riskAssessmentId]);

  const permitted = scopes.updateRiskAssessmentDocument.isPermitted;
  const missingScopeNames = scopes.updateRiskAssessmentDocument.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Box>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('createAndUpdateRiskAssessmentModal.updateTitle')}
          </Text>
        </Box>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <RiskAssessmentEditBody
          riskAssessmentId={riskAssessmentId}
          project={project}
          onFormStepChange={setActiveStep}
          onFormDirtyStateChange={setFormDirty}
        />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('createAndUpdateRiskAssessmentModal.closeModal.title')}
        description={t('createAndUpdateRiskAssessmentModal.closeModal.description')}
        confirmButtonText={t('createAndUpdateRiskAssessmentModal.closeModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('createAndUpdateRiskAssessmentModal.closeModal.cancelButton')}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setDialogOpen(false);
        }}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
