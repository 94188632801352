import { FC } from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';

import FieldLayout from '../../../../../components/formik/FieldLayout';
import Input from '../../../../../components/form/Control/Input';
import SidebarLayout from '../../../../../components/layouts/SidebarLayout';
import useForm from '../../../../../hooks/useForm';
import { EditNameFormValues } from './values';
import { offerEditFormInitialValues, validationSchema } from './form';
import { OfferMode } from '../../../../../globalTypes';
import Select, { SelectOption } from '../../../../../components/form/Control/Select';

interface Props {
  onSubmit: (v: EditNameFormValues) => void;
  draft: boolean;
  initialValues?: Partial<EditNameFormValues>;
}

const OfferEditForm: FC<Props> = ({ onSubmit, initialValues, draft }) => {
  const { t } = useTranslation();
  const { formik, errorFeedback } = useForm<EditNameFormValues>({
    initialValues: offerEditFormInitialValues(initialValues),
    onSubmit,
    validationSchema,
  });

  const { handleSubmit } = formik;

  const selectOptions: SelectOption[] = [
    {
      label: t('offerEdit.modal.mode.system.label'),
      value: OfferMode.SYSTEM_NUMBER,
    },
    {
      label: t('offerEdit.modal.mode.alias.label'),
      value: OfferMode.ALIAS_NUMBER,
    },
  ];

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col col--sm-8">
            <div className="row">
              <div className="col col--sm-6">
                <FieldLayout
                  name="name"
                  label={t('offerEditForm.name.label')}
                  labelHtmlFor="input-name"
                  feedback={{ invalid: errorFeedback('name') }}
                >
                  {(inputProps) => <Input id="input-name" {...inputProps} />}
                </FieldLayout>
              </div>
            </div>
            {draft && (
              <>
                <hr />
                <Text
                  component="div"
                  variant="title-small"
                  color="muted"
                  className="u-margin-top-large"
                >
                  {t('updateOfferModal.client.title')}
                </Text>

                <div className="row u-margin-top">
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="client.name"
                      label={t('updateOfferModal.client.name.label')}
                      labelHtmlFor="input-client-of-the-update-offer"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('client.name') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-client-of-the-update-offer"
                          placeholder={t('updateOfferModal.client.name.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="client.address"
                      label={t('updateOfferModal.client.adress.label')}
                      labelHtmlFor="input-address-of-the-update-offer-client"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('client.address') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-address-of-the-update-offer-client"
                          placeholder={t('updateOfferModal.client.adress.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="client.zip"
                      label={t('updateOfferModal.client.zip.label')}
                      labelHtmlFor="input-zip-of-the-update-offer-client"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('client.zip') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-zip-of-the-update-offer-client"
                          placeholder={t('updateOfferModal.client.zip.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="client.city"
                      label={t('updateOfferModal.client.city.label')}
                      labelHtmlFor="input-city-of-the-update-offer-client"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('client.city') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-city-of-the-update-offer-client"
                          placeholder={t('updateOfferModal.client.city.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                </div>

                <Text
                  component="div"
                  color="muted"
                  variant="title-small"
                  className="u-margin-top-large"
                >
                  {t('updateOfferModal.constructionSite.title')}
                </Text>

                <div className="row u-margin-top">
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="constructionSite.name"
                      label={t('updateOfferModal.constructionSite.name.label')}
                      labelHtmlFor="input-name-of-the-update-offer-constructionSite"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('constructionSite.name') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-name-of-the-update-offer-constructionSite"
                          placeholder={t('updateOfferModal.constructionSite.name.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="constructionSite.address"
                      label={t('updateOfferModal.constructionSite.adress.label')}
                      labelHtmlFor="input-address-of-the-update-offer-constructionSite"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('constructionSite.address') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-address-of-the-update-offer-constructionSite"
                          placeholder={t('updateOfferModal.constructionSite.adress.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="constructionSite.zip"
                      label={t('updateOfferModal.constructionSite.zip.label')}
                      labelHtmlFor="input-zip-of-the-update-offer-constructionSite"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('constructionSite.zip') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-zip-of-the-update-offer-constructionSite"
                          placeholder={t('updateOfferModal.constructionSite.zip.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="constructionSite.city"
                      label={t('updateOfferModal.constructionSite.city.label')}
                      labelHtmlFor="input-city-of-the-update-offer-constructionSite"
                      className="u-margin-bottom-xsmall u-width-100%"
                      feedback={{ invalid: errorFeedback('constructionSite.city') }}
                    >
                      {(props) => (
                        <Input
                          rightIconName={undefined}
                          id="input-city-of-the-update-offer-constructionSite"
                          placeholder={t('updateOfferModal.constructionSite.city.placeholder')}
                          {...props}
                        />
                      )}
                    </FieldLayout>
                  </div>
                </div>
              </>
            )}
            <hr />
            <div className="row">
              <div className="col col--sm-6">
                <Text variant="title-small">{t('offerEdit.modal.alias.title')}</Text>
                <Text component="p" variant="body-small" className="u-margin-top">
                  {t('offerEdit.modal.alias.subtitle')}
                </Text>
              </div>
            </div>
            <div className="row u-margin-top-small">
              <div className="col col--sm-6">
                <FieldLayout
                  name="mode"
                  label={t('offerEdit.modal.mode.label')}
                  labelHtmlFor="input-type-mode"
                  feedback={{ invalid: errorFeedback(`mode`) }}
                >
                  {(props) => <Select {...props} options={selectOptions} id="input-type-mode" />}
                </FieldLayout>
              </div>
            </div>
          </div>
          <div className="col col--sm-4">
            <SidebarLayout>
              <Button color="primary" fullWidth endIcon={<Icon name="save" />}>
                {t('offerEditForm.name.submitButton')}
              </Button>
            </SidebarLayout>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default OfferEditForm;
