import { TypedDocumentNode, gql } from '@apollo/client';
import LogFragment from '../../../../../../../containers/LogContainer/graphql/Log.fragment';
import { QsstLogs, QsstLogsVariables } from './__generated__/QsstLogsDetailQuery';

const QsstLogsQuery: TypedDocumentNode<QsstLogs, QsstLogsVariables> = gql`
  query QsstLogs($qsstId: ID!, $page: Int!, $limit: Int!) {
    qsst(id: $qsstId) {
      id

      logs(limit: $limit, page: $page) {
        ...LogFragment
      }
    }
  }

  ${LogFragment}
`;

export default QsstLogsQuery;
