import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UploadConcernImage,
  UploadConcernImageVariables,
} from './__generated__/UploadConcernImage.mutation';

const UploadConcernImageMutation: TypedDocumentNode<
  UploadConcernImage,
  UploadConcernImageVariables
> = gql`
  mutation UploadConcernImage($file: Upload!) {
    uploadImage(file: $file) {
      id
      url
    }
  }
`;

export default UploadConcernImageMutation;
