import { ObstructionFormValues } from '../../forms/ObstructionForm/ObstructionForm.form';
import {
  ObstructionFactSaveInput,
  ObstructionImageInput,
  ObstructionSaveInput,
} from '../../../../globalTypes';

export function createObstructionSaveInput({
  formValues,
  obstructionId,
}: {
  formValues: ObstructionFormValues;
  obstructionId: string;
}): ObstructionSaveInput {
  const facts = formValues.facts.map<ObstructionFactSaveInput>(({ title, description }) => ({
    title,
    description,
  }));

  const images = formValues.images.reduce<ObstructionImageInput[]>((result, { imageId, label }) => {
    if (imageId) {
      const input: ObstructionImageInput = {
        imageId,
        label,
      };

      return [...result, input];
    }

    return result;
  }, []);

  return {
    documentId: obstructionId,
    date: formValues.date,
    clientName: formValues.clientName,
    professions: formValues.professions,
    facts,
    images,
  };
}
