import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import useDriveDocumentTagDetails from '../../../../../hooks/useDriveDocumentTagDetails';
import useObstructionSendEmail from '../../hooks/useObstructionSendEmail';
import ErrorState from '../../../../../containers/ErrorState';
import EmailForm from '../../../../../containers/Email/EmailForm';
import { Obstruction } from '../../../graphql/__generated__/Obstruction.fragment';
import { ObstructionProject } from '../../../graphql/__generated__/ObstructionProject.fragment';
import { obstructionSendEmailFormInitialValues } from './ObstructionSendEmailBody.utils';
import { formatBytes } from '../../../../../utils/number';

interface ObstructionSendEmailBodyProps {
  obstruction: Obstruction;
  project: ObstructionProject;
  onDirtyStateChange: (dirty: boolean) => void;
  onRequestClose: () => void;
}

export default function ObstructionSendEmailBody({
  obstruction,
  project,
  onRequestClose,
  onDirtyStateChange,
}: ObstructionSendEmailBodyProps): JSX.Element | null {
  const { viewer } = useViewer();
  const { t } = useTranslation();

  const { driveDocument } = useDriveDocumentTagDetails(obstruction.id);
  const { send, loading, error } = useObstructionSendEmail(obstruction.id);

  const documents = [
    {
      id: obstruction.id,
      name: obstruction.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  if (!viewer) {
    return null;
  }

  return (
    <>
      {error && <ErrorState error={error} />}

      <EmailForm
        projectId={project.id}
        initialValues={obstructionSendEmailFormInitialValues(t, {
          obstruction,
          project,
          viewer,
        })}
        documents={documents}
        loading={loading}
        onSubmit={send}
        onDocumentIdsValueChange={onRequestClose}
        onDirtyStateChange={onDirtyStateChange}
        submitButtonText={t('obstructionSendEmailForm.confirmButtonText')}
      />
    </>
  );
}
