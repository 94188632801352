import { RefObject, useCallback, useEffect, useRef } from 'react';
import useNavigator from '../../../../hooks/useNavigator/useNavigator';
import TouchPair from './TouchPair';
import { calculateScaleFactor } from './utils';

type UseGestures = {
  element: RefObject<HTMLDivElement>;
  onPinch?: (event: TouchEvent, scale: number) => void;
  onPan?: (event: TouchEvent) => void;
  onTouchStart?: (event: TouchEvent) => void;
  onTouchEnd?: (event: TouchEvent) => void;
};

const GESTURE_TRANSITION_THRESHOLD = 5;
const DISTANCE_THRESHOLD = 1.6;

function useGestures(settings: UseGestures): void {
  const { touchScreen } = useNavigator();
  const firstTouchPair = useRef<TouchPair | undefined>(undefined);
  const gestureTransitionCounter = useRef(0);
  const lastDistanceBetweenTouches = useRef(0);

  const handleTouchStart = useCallback(
    (event: TouchEvent) => {
      const { touches } = event;

      if (touches.length !== 2) {
        return;
      }

      const [firstTouch, secondTouch] = Array.from(touches);
      const touchPair = new TouchPair(firstTouch, secondTouch);

      lastDistanceBetweenTouches.current = touchPair.distance;
      firstTouchPair.current = touchPair;

      if (settings.onTouchStart) {
        settings.onTouchStart(event);
      }
    },
    [lastDistanceBetweenTouches, settings],
  );

  const handleTouchEnd = useCallback(
    (event: TouchEvent) => {
      if (settings.onTouchEnd) {
        settings.onTouchEnd(event);
      }
    },
    [settings],
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      const { touches } = event;

      if (touches.length !== 2 || !firstTouchPair.current) {
        return;
      }

      /*
       * Increase that value until ensure to find a gesture
       *  */
      gestureTransitionCounter.current += 1;

      const [firstTouch, secondTouch] = Array.from(touches);
      const touchPair = new TouchPair(firstTouch, secondTouch);
      const distanceBetweenTouches = touchPair.distance;
      const distanceCurrentThreshold = Math.abs(
        lastDistanceBetweenTouches.current - distanceBetweenTouches,
      );

      if (
        distanceCurrentThreshold < DISTANCE_THRESHOLD &&
        gestureTransitionCounter.current > GESTURE_TRANSITION_THRESHOLD
      ) {
        if (settings.onPan) {
          settings.onPan(event);
        }

        gestureTransitionCounter.current = 0;

        return;
      }

      if (gestureTransitionCounter.current > GESTURE_TRANSITION_THRESHOLD) {
        if (settings.onPinch) {
          /*
           * This value is not calculating as default by browsers except Safari.
           * In the future, the browsers will support this calculation natively,
           * we'll renounce from this implementation.
           * See: https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent#properties
           * */
          const scale = calculateScaleFactor(touchPair, firstTouchPair.current);

          settings.onPinch(event, scale);
        }

        gestureTransitionCounter.current = 0;
      }

      lastDistanceBetweenTouches.current = distanceBetweenTouches;
    },
    [lastDistanceBetweenTouches, settings, gestureTransitionCounter],
  );

  useEffect(() => {
    const { element } = settings;

    if (!element.current || !touchScreen) {
      return;
    }

    element.current.addEventListener('touchstart', handleTouchStart, { passive: false });
    element.current.addEventListener('touchend', handleTouchEnd, { passive: false });
    element.current.addEventListener('touchmove', handleTouchMove, { passive: false });

    // eslint-disable-next-line consistent-return
    return () => {
      if (!element.current) {
        return;
      }

      element.current.removeEventListener('touchstart', handleTouchStart);
      element.current.removeEventListener('touchend', handleTouchEnd);
      element.current.removeEventListener('touchmove', handleTouchMove);
    };
  }, [settings, handleTouchStart, handleTouchEnd, handleTouchMove, touchScreen]);
}

export default useGestures;
