import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useField } from 'formik';
import { useMutation } from '@apollo/client';
import { Box } from '@hs-baumappe/legacy-ui';
import ImageUploadWithLabel, {
  ImageUploadWithLabelFormValues,
} from '../../../../../../../../containers/forms/ImageUploadWithLabel';
import UploadConcernImageMutation from './graphql/UploadConcernImage.mutation';

import ClickableImageThumbnail from '../../../../../../../../components/ClickableImageThumbnail';

function createFormFieldKey(name: string, field: string) {
  return `${name}.${field}`;
}

interface ConcernFormImageAttachmentProps {
  name: string;
  onRequestRemove: () => void;
  showRemoveButton?: boolean;
}

export default function ConcernFormImageAttachment({
  name,
  onRequestRemove,
  showRemoveButton,
}: ConcernFormImageAttachmentProps): JSX.Element {
  const { t } = useTranslation();

  const imageUrlKey = createFormFieldKey(name, 'imageUrl');
  const imageIdKey = createFormFieldKey(name, 'imageId');
  const fileKey = createFormFieldKey(name, 'file');

  const [imageUrlField, , imageUrlFieldHelpers] =
    useField<ImageUploadWithLabelFormValues['imageUrl']>(imageUrlKey);
  const [imageThumbnailUrlField, , imageThumbnailUrlFieldHelpers] =
    useField<ImageUploadWithLabelFormValues['imageThumbnailUrl']>(imageUrlKey);
  const [imageIdField, , imageIdFieldHelpers] =
    useField<ImageUploadWithLabelFormValues['imageId']>(imageIdKey);
  const [fileField] = useField<ImageUploadWithLabelFormValues['file']>(fileKey);

  const [uploadImage, { loading, data }] = useMutation(UploadConcernImageMutation);

  useEffect(() => {
    const file = fileField.value;

    if (!file || loading || imageIdField.value) {
      return;
    }

    uploadImage({
      variables: {
        file,
      },
    });
  }, [fileField.value, uploadImage, loading, imageIdField]);

  useEffect(() => {
    if (data && !imageIdField.value) {
      imageIdFieldHelpers.setValue(data.uploadImage.id);
      imageUrlFieldHelpers.setValue(data.uploadImage.url);
      imageThumbnailUrlFieldHelpers.setValue(data.uploadImage.url);
    }
  }, [
    imageIdFieldHelpers,
    imageIdField.value,
    data,
    imageUrlFieldHelpers,
    imageThumbnailUrlFieldHelpers,
  ]);

  return (
    <Box stylex={{ marginBottom: 'small-x' }}>
      <ImageUploadWithLabel
        name={name}
        labelInputPlaceholder={t('concern.create.form.imageAttachment.label.placeholder')}
        originalImageUrl={imageUrlField.value}
        previewSection={(handleShowOriginalImageOpen) => (
          <ClickableImageThumbnail
            src={imageThumbnailUrlField.value}
            width={56}
            height={40}
            onClick={handleShowOriginalImageOpen}
          />
        )}
        showRemoveButton={!!imageIdField.value || showRemoveButton}
        onRequestRemove={onRequestRemove}
        loading={loading}
      />
    </Box>
  );
}
