import { gql, TypedDocumentNode } from '@apollo/client';
import ConcernFragment from '../../../graphql/Concern.fragment';
import {
  ConcernSendEmail,
  ConcernSendEmailVariables,
} from './__generated__/ConcernSendEmail.mutation';

const ConcernSendEmailMutation: TypedDocumentNode<ConcernSendEmail, ConcernSendEmailVariables> =
  gql`
    mutation ConcernSendEmail($input: ConcernPdfEmailSendInput!) {
      concernPdfEmailSend(input: $input) {
        ...Concern
      }
    }

    ${ConcernFragment}
  `;

export default ConcernSendEmailMutation;
