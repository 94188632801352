import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { ObstructionLocationState } from '../obstruction.route.types';
import obstructionRoutes from '../obstructionRoutes';
import ObstructionEditModalTitle from './components/ObstructionEditModalTitle';
import { navigateToLocation } from '../../routes.utils';
import { Obstruction } from '../graphql/__generated__/Obstruction.fragment';
import { ObstructionProject } from '../graphql/__generated__/ObstructionProject.fragment';
import { ObstructionScopes } from '../Obstruction.types';
import RouteDialog from '../../../containers/RouteDialog';
import ObstructionEditBody from './components/ObstructionEditBody';

interface ObstructionEditProps {
  obstruction: Obstruction;
  project: ObstructionProject;
  scopes: ObstructionScopes;
}

export default function ObstructionEdit({
  obstruction,
  project,
  scopes,
}: ObstructionEditProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<ObstructionLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: obstructionRoutes.detail.generatePath({ obstructionId: obstruction.id }),
    });
  }, [navigate, location.state, obstruction.id]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  const permitted = scopes.updateObstructionDocument.isPermitted;
  const missingScopeNames = scopes.updateObstructionDocument.missingScopeNames;

  if (!obstruction.viewerCanUpdate) {
    return (
      <Navigate
        to={obstructionRoutes.detail.generatePath({ obstructionId: obstruction.id })}
        replace
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={<ObstructionEditModalTitle projectDescription={project.description} />}
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <ObstructionEditBody
          obstructionId={obstruction.id}
          project={project}
          formDirty={formDirty}
          onFormDirtyStateChange={setFormDirty}
          onRequestClose={() => setDialogOpen(false)}
        />
      )}

      <AlertDialog
        destructive
        open={confirmationDialogOpen}
        title={t('obstructionEdit.confirmationModal.title')}
        description={t('obstructionEdit.confirmationModal.body')}
        confirmButtonText={t('obstructionEdit.confirmationModal.confirmButton')}
        cancelButtonText={t('obstructionEdit.confirmationModal.dismissButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={handleConfirmationDialogConfirm}
      />
    </RouteDialog>
  );
}
