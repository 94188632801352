import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ImportProjectMutation,
  ImportProjectMutationVariables,
} from './__generated__/ProjectImport.mutation';

const ProjectImportMutation: TypedDocumentNode<
  ImportProjectMutation,
  ImportProjectMutationVariables
> = gql`
  mutation ImportProjectMutation($input: ImportProjectInput!) {
    importProject(input: $input) {
      id
    }
  }
`;

export default ProjectImportMutation;
