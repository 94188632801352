import { ProjectStatus } from '../../../../../../../../../../globalTypes';
import { useFormikContext } from 'formik';
import { ProjectFilterFormValues } from '../../../../ProjectFilterForm.form';

interface UseProjectFilterFormStatusesResponse {
  statuses: ProjectFilterFormValues['statuses'];
  areStatusesChecked: (statuses: ProjectStatus[]) => boolean;
  setCheckedProjectStatuses: (nextStatues: ProjectStatus[]) => void;
  setCheckedProjectStatus: (status: ProjectStatus, checked: boolean) => void;
}

export default function useProjectFilterFormStatuses(): UseProjectFilterFormStatusesResponse {
  const { values, setFieldValue } = useFormikContext<ProjectFilterFormValues>();

  function setCheckedProjectStatuses(nextStatuses: ProjectStatus[]) {
    const statuses = [...values.statuses].map((projectStatusFilter) => ({
      ...projectStatusFilter,
      checked: nextStatuses.includes(projectStatusFilter.value),
    }));

    setFieldValue('statuses', statuses);
  }

  function areStatusesChecked(statuses: ProjectStatus[]) {
    const checkedStatuses = values.statuses
      .filter((statusFilter) => statusFilter.checked)
      .map((statusFilter) => statusFilter.value);

    return (
      checkedStatuses.length === statuses.length &&
      statuses.every((status) => checkedStatuses.includes(status))
    );
  }

  function setCheckedProjectStatus(status: ProjectStatus, checked: boolean) {
    const statuses = [...values.statuses].map((projectStatusFilter) => ({
      ...projectStatusFilter,
      checked: projectStatusFilter.value === status ? checked : projectStatusFilter.checked,
    }));

    setFieldValue('statuses', statuses);
  }

  return {
    statuses: values.statuses,
    areStatusesChecked,
    setCheckedProjectStatuses,
    setCheckedProjectStatus,
  };
}
