import { useCallback, useState } from 'react';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useQsstManualSign from './useQsstManualSign';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import QsstUploadManuallySignedDocumentForm from './QsstUploadManuallySignedDocumentForm';
import { qsstRoutes } from '../qsstRoutes';
import { QsstLocationState } from '../Qsst.route.types';
import { Qsst } from '../graphql/__generated__/Qsst.fragment';
import { QsstProject } from '../graphql/__generated__/QsstProject.fragment';

interface QsstUploadManuallySignedDocumentProps {
  qsst: Qsst;
  project: QsstProject;
}

export function QsstUploadManuallySignedDocument({
  qsst,
  project,
}: QsstUploadManuallySignedDocumentProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<QsstLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { sign, loading, error } = useQsstManualSign(qsst.id);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(qsstRoutes.detail.generatePath({ qsstId: qsst.id }));
  }, [qsst.id, location.state, navigate]);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }, [formDirty]);

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  if (!qsst.viewerCanUpload) {
    return <Navigate to={qsstRoutes.detail.generatePath({ qsstId: qsst.id })} replace />;
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('uploadManuallySignedQsstModal.title')}
          </Text>
        </>
      }
    >
      {error && <ErrorState error={error} />}

      <QsstUploadManuallySignedDocumentForm
        loading={loading}
        onSubmit={sign}
        onDirty={() => setFormDirty(true)}
      />

      <AlertDialog
        destructive
        open={confirmationDialogOpen}
        title={t('uploadManuallySignedQsstModal.confirmationDialog.title')}
        description={t('uploadManuallySignedQsstModal.confirmationDialog.description')}
        confirmButtonText={t('uploadManuallySignedQsstModal.confirmationDialog.confirmButtonText')}
        cancelButtonText={t('uploadManuallySignedQsstModal.confirmationDialog.cancelButtonText')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
      />
    </RouteDialog>
  );
}
