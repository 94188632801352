import Compressor from 'compressorjs';

const MEGABYTES = 2 ** 20;
const COMPRESSOR_CONVERT_SIZE = 1.8 * MEGABYTES;
const COMPRESSOR_QUALITY = 0.3;

interface Dimensions {
  width: number;
  height: number;
}

export async function compressImage(image: File): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      convertSize: COMPRESSOR_CONVERT_SIZE,
      quality: COMPRESSOR_QUALITY,
      success(compressedFile: File) {
        resolve(compressedFile);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
}

export async function calculateDimensions(file: File): Promise<Dimensions> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const imageSrc = URL.createObjectURL(file);

    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });

      URL.revokeObjectURL(imageSrc);
    };

    image.onerror = () => {
      reject(new Error('Could not load image'));
      URL.revokeObjectURL(imageSrc);
    };

    image.src = imageSrc;
  });
}

export function scaleDimensions(source: Dimensions, scaleFor: Dimensions): Dimensions {
  const { width, height } = source;
  const { width: scaleWidth, height: scaleHeight } = scaleFor;

  const scale = Math.min(scaleWidth / width, scaleHeight / height);

  return {
    width: width * scale,
    height: height * scale,
  };
}

export function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error('Could not read file'));

    reader.readAsDataURL(file);
  });
}

export async function fetchFile(url: string): Promise<File> {
  const blob = await fetch(url).then((response) => response.blob());

  return new File([blob], `file.${blob.type}`, {
    type: blob.type,
  });
}
