import { PropsWithChildren } from 'react';
import cx from 'classnames';

import './c-note-editor-toolbar.scss';

interface NoteEditorToolbarProps {
  className?: string;
  type?: 'default' | 'tool';
  directionModifier?: 'column' | 'column@md-down' | 'column-reverse@md-down';
}

export default function NoteEditorToolbar({
  className,
  type = 'default',
  directionModifier,
  children,
}: PropsWithChildren<NoteEditorToolbarProps>): JSX.Element {
  return (
    <div
      className={cx(
        'c-note-editor-toolbar',
        { [`c-note-editor-toolbar--type-${type}`]: type !== 'default' },
        { [`c-note-editor-toolbar--direction-${directionModifier}`]: !!directionModifier },
        className,
      )}
    >
      {children}
    </div>
  );
}
