import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import DriveDocumentNoteContainer from '../../../../../components/DriveDocumentNoteContainer';
import { RiskAssessment } from '../../../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentScopes } from '../../types';
import { useTranslation } from 'react-i18next';

interface RiskAssessmentDetailSidebarTopProps {
  riskAssessment: RiskAssessment;
  scopes: RiskAssessmentScopes;
  onEditButtonClick: () => void;
  onSendButtonClick: () => void;
  onDownloadButtonClick: () => void;
}

export default function RiskAssessmentDetailSidebarTop({
  riskAssessment,
  scopes,
  onEditButtonClick,
  onSendButtonClick,
  onDownloadButtonClick,
}: RiskAssessmentDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateRiskAssessmentDocument.isPermitted;
  const downloadPermitted = scopes.downloadRiskAssessmentDocument.isPermitted;
  const sendPermitted = scopes.sendRiskAssessmentDocumentAttachment.isPermitted;

  return (
    <>
      <Stack gap="small-3x" stylex={{ marginTop: 'small-x' }}>
        <DriveDocumentNoteContainer id={riskAssessment.id} name={riskAssessment.name}>
          {updatePermitted && (
            <Button endIcon={<Icon name="create" />} onClick={onEditButtonClick} fullWidth>
              {t('riskAssessmentDetail.edit.button')}
            </Button>
          )}

          {riskAssessment.viewerCanSendRiskAssessmentPDFEmail && sendPermitted && (
            <Button endIcon={<Icon name="email" />} onClick={onSendButtonClick} fullWidth>
              {t('riskAssessmentPDF.pdfSend.button')}
            </Button>
          )}

          {downloadPermitted && (
            <Button
              endIcon={<Icon name="cloud-download" />}
              onClick={onDownloadButtonClick}
              fullWidth
            >
              {t('riskAssessmentPDF.pdfDownload.button')}
            </Button>
          )}
        </DriveDocumentNoteContainer>
      </Stack>
    </>
  );
}
