import { gql, TypedDocumentNode } from '@apollo/client';
import AcceptanceReportFragment from '../../../graphql/AcceptanceReport.fragment';
import AcceptanceReportProjectFragment from '../../../graphql/AcceptanceReportProject.fragment';
import {
  AcceptanceReportDetail,
  AcceptanceReportDetailVariables,
} from './__generated__/AcceptanceReportDetail.query';

const AcceptanceReportDetailQuery: TypedDocumentNode<
  AcceptanceReportDetail,
  AcceptanceReportDetailVariables
> = gql`
  query AcceptanceReportDetail($acceptanceReportId: ID!) {
    acceptanceReport(id: $acceptanceReportId) {
      ...AcceptanceReport

      project {
        ...AcceptanceReportProject
      }
    }
  }

  ${AcceptanceReportFragment}
  ${AcceptanceReportProjectFragment}
`;

export default AcceptanceReportDetailQuery;
