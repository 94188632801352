import { createPath, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import SendConstructionNoteEmailMutation from './graphql/SendConstructionNoteEmail.mutation';
import useCanvas from '../hooks/useCanvas';
import base64ToBlobGenerator from '../utils/base64ToBlobGenerator';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import { getErrorsFromServerError } from '../../../apollo/errors';
import { SendConstructionNoteEmailInput } from '../../../globalTypes';
import { EmailFormValues } from '../../../containers/Email';
import GetConstructionNoteDetailQuery from '../graphql/GetConstructionNoteDetail.query';

interface UseSendConstructionNoteEmailResponse {
  sendEmail: (variables: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useSendConstructionNoteEmail(
  constructionNoteId: string,
): UseSendConstructionNoteEmailResponse {
  const { canvas } = useCanvas();
  const navigate = useNavigate();

  const [mutate, { loading }] = useMutation(SendConstructionNoteEmailMutation, {
    refetchQueries: [GetConstructionNoteDetailQuery],
  });
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function sendEmail(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    if (!canvas || loading) {
      return;
    }

    const canvasData = canvas.toDataURL({
      format: 'jpeg',
      quality: 1,
      enableRetinaScaling: true,
      multiplier: 2,
    });

    const file = base64ToBlobGenerator(canvasData, 'image/jpeg');

    try {
      await mutate({
        variables: {
          file,
          input: {
            id: constructionNoteId,
            recipients: values.toEmails,
            cc: values.ccEmails,
            subject: values.subject,
            content: values.content,
          },
        },
      });

      const searchParams = new URLSearchParams({
        'send-email': 'true',
      });

      navigate(
        createPath({
          pathname: constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        SendConstructionNoteEmailInput
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.recipients,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendEmail,
    loading,
    error,
  };
}
