import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { EmailFormValues } from '../../../../containers/Email';
import { Concern } from '../../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../../graphql/__generated__/ConcernProject.fragment';

export function getConcernEmailFormInitialValues(
  concern: Concern,
  project: ConcernProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  const { firstName, lastName, phone, faxNumber, mobile, email, signatureEmail } = viewer;
  const emailContentTranslationKey = signatureEmail
    ? 'concern.sendPdfEmail.route.content'
    : 'concern.sendPdfEmail.route.content_with_no_email';

  return {
    subject: t('concern.sendPdfEmail.route.subject', {
      projectName: project.name,
      concernName: concern.name,
    }),
    content: t(emailContentTranslationKey, {
      projectNumber: project.projectNumber,
      concernName: concern.name,
      firstName,
      lastName,
      phone,
      faxNumber,
      mobile,
      email: signatureEmail ?? email,
    }),
    documentIds: [concern.id],
  };
}
