import { useTranslation } from 'react-i18next';
import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useMutation } from '@apollo/client';
import DuplicateRiskAssessmentMutation from './graphql/DuplicateRiskAssessment.mutation';

interface DuplicateRiskAssessmentDialogProps {
  open: boolean;
  riskAssessmentId: string;
  riskAssessmentNo: number;
  onRequestClose: () => void;
  onSubmitSuccess: (id: string) => void;
}

export default function DuplicateRiskAssessmentDialog({
  open,
  riskAssessmentNo,
  riskAssessmentId,
  onRequestClose,
  onSubmitSuccess,
}: DuplicateRiskAssessmentDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [duplicate, { loading }] = useMutation(DuplicateRiskAssessmentMutation);

  async function handleRiskAssessmentDuplication() {
    if (loading) return;

    try {
      const { data } = await duplicate({
        variables: {
          input: {
            riskAssessment: riskAssessmentId,
          },
        },
      });

      if (!data) return;

      onSubmitSuccess(data.duplicateRiskAssessment.id);
    } catch (e) {
      alert(t('common.error'));
    }
  }

  return (
    <AlertDialog
      open={open}
      title={t('riskAssessmentDetail.duplication.title', { riskAssessmentNo })}
      description={t('riskAssessmentDetail.duplication.text')}
      confirmButtonText={t('riskAssessmentDetail.duplication.confirmButton')}
      confirmButtonEndIcon={<Icon name="file-copy" />}
      cancelButtonText={t('riskAssessmentDetail.duplication.cancelButton')}
      onRequestClose={onRequestClose}
      onCancel={onRequestClose}
      onConfirm={handleRiskAssessmentDuplication}
    />
  );
}
