import { format } from 'date-fns';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import i18n from '../i18n';

export function setYupLocale(): void {
  yup.setLocale({
    mixed: {
      required: i18n.t('validation.required'),
      oneOf: i18n.t('validation.required'),
    },
    string: {
      min: i18n.t('validation.required'),
      max: ({ max }) => i18n.t('validation.string.max', { max }),
      email: i18n.t('validation.email.invalid'),
    },
    date: {
      max: ({ max }) => {
        return i18n.t('validation.date.max', {
          min: typeof max === 'string' ? max : format(max, 'dd.MM.yyyy'),
        });
      },
      min: ({ min }) => {
        return i18n.t('validation.date.min', {
          min: typeof min === 'string' ? min : format(min, 'dd.MM.yyyy'),
        });
      },
    },
    number: {
      min: i18n.t('validation.required'),
      max: i18n.t('validation.required'),
    },
    array: {
      min: i18n.t('validation.required'),
      max: i18n.t('validation.required'),
    },
  });
}

setYupLocale();

type ObjectShapeValues = ObjectShape extends Record<string, infer V> ? V : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InferObjectToYupShape<T extends Record<any, any>> = Record<keyof T, ObjectShapeValues>;

export default yup;
