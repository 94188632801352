import { gql } from '@apollo/client';

export default gql`
  mutation ProjectDateUpdate($input: ProjectDateUpdateInput!) {
    projectDateUpdate(input: $input) {
      id
      date
    }
  }
`;
