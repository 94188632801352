import { useViewer } from '@hs-baumappe/web-auth';
import { ApolloError, useQuery } from '@apollo/client';
import ObstructionDetailQuery from '../../graphql/ObstructionDetail.query';
import { ObstructionScopes } from '../../Obstruction.types';
import { Obstruction } from '../../graphql/__generated__/Obstruction.fragment';
import { ObstructionProject } from '../../graphql/__generated__/ObstructionProject.fragment';

interface UseObstructionDataResponse {
  obstruction: Obstruction;
  project: ObstructionProject;
  scopes: ObstructionScopes;
}

interface UseObstructionResponse {
  data?: UseObstructionDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useObstruction(obstructionId: string): UseObstructionResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(ObstructionDetailQuery, {
    variables: {
      obstructionId,
    },
  });

  const obstruction = data?.obstruction;
  const project = data?.obstruction?.project;
  const scopes = viewer?.scopes;

  return {
    data: obstruction && project && scopes ? { obstruction, project, scopes } : undefined,
    loading,
    error,
  };
}
