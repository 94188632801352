import { TFunction } from 'i18next';
import { Viewer } from '@hs-baumappe/web-auth';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportProject } from '../graphql/__generated__/AcceptanceReportProject.fragment';
import { EmailFormValues } from '../../../containers/Email';

export function getEmailFormInitialValues(
  acceptanceReport: AcceptanceReport,
  project: AcceptanceReportProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  return {
    subject: t('acceptanceReportSendEmail.initialValues.subject', {
      projectName: project.name,
      additionalName: project.additionalName,
      acceptanceReportNo: acceptanceReport.no,
    }),
    content: viewer.signatureEmail
      ? t('acceptanceReportSendEmail.initialValues.content', {
          firstName: viewer.firstName,
          lastName: viewer.lastName,
          phone: viewer.phone,
          faxNumber: viewer.faxNumber,
          mobile: viewer.mobile,
          email: viewer.signatureEmail,
        })
      : t('acceptanceReportSendEmail.initialValues.content_with_no_email', {
          firstName: viewer.firstName,
          lastName: viewer.lastName,
          phone: viewer.phone,
          faxNumber: viewer.faxNumber,
          mobile: viewer.mobile,
          email: viewer.email,
        }),
    documentIds: [acceptanceReport.id],
  };
}
