import { gql } from '@apollo/client/core';

export default gql`
  query CreateAcceptanceReportProjectDetail($id: ID!) {
    project(id: $id) {
      id
      name
      description
      hsProjectNumber
      draft
      additionalName

      client {
        id
        name
      }

      profitCenter {
        address {
          name
        }
      }

      contactPeople {
        id
        name
      }
    }
  }
`;
