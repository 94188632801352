import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import useConcernSign from '../hooks/useConcernSign/useConcernSign';
import ErrorState from '../../../../containers/ErrorState';

import useConcernSignDetail from '../hooks/useConcernSignDetail';
import { Concern } from '../../graphql/__generated__/Concern.fragment';
import SignatureForm from '../../../../components/SignatureForm';

interface ConcernSignBodyProps {
  concern: Concern;
  onFormDirtyStatusChange: (dirty: boolean) => void;
  viewerSignatureUrl?: string;
}

export default function ConcernSignBody({
  concern,
  onFormDirtyStatusChange,
}: ConcernSignBodyProps): JSX.Element | null {
  const { t } = useTranslation();

  const { viewer } = useViewer();

  const { viewerSignatureBase64 } = useConcernSignDetail();
  const { sign, loading: signing, error: signError } = useConcernSign(concern.id);

  if (!viewer) {
    return null;
  }

  return (
    <>
      {signError && <ErrorState error={signError} />}

      <SignatureForm
        initialValues={{
          name: `${viewer.firstName} ${viewer.lastName}`,
        }}
        signatureInputLabel={t('concern.sign.form.signature.label')}
        lastSignature={viewerSignatureBase64}
        loading={signing}
        onSubmit={sign}
        onDirtyStateChange={onFormDirtyStatusChange}
      />
    </>
  );
}
