import { FC } from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, Details, Icon, Summary, Text, Textarea } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';

import FieldLayout from '../../../../../../components/formik/FieldLayout';
import Input from '../../../../../../components/form/Control/Input';
import { Catalog, CatalogCategory } from '../../OperationSearch/types';
import Select, { SelectOption } from '../../../../../../components/form/Control/Select';

import { defaultValues, validationSchema } from './form';
import NumberInput from '../../../../../../components/form/Control/NumberInput';
import Choice from '../../../../../../components/form/Choice';
import MeasurementsSections from '../../../../measurement/MeasurementsSection/MeasurementsSections';
import { OperationValues } from './values';
import { OfferMode, OperationUnit } from '../../../../../../globalTypes';
import SidebarLayout from '../../../../../../components/layouts/SidebarLayout';
import RichTextEditor from '../../../../../../components/form/Control/RichTextEditor';
import { Measurement } from '../../../../Detail/types';
import useForm from '../../../../../../hooks/useForm';
import PaymentForm from '../../../PaymentForm';

const categoryOptionMapper = (category: CatalogCategory): SelectOption => {
  return {
    label: `${category.partNoTree.join('.')} ${category.name}`,
    value: category.subCategories.length
      ? category.subCategories.map(categoryOptionMapper)
      : category.id,
  };
};

interface CreateNewOperationFormProps {
  catalogs: Catalog[];
  initialValues?: Partial<OperationValues>;
  catalogSelectable: boolean;
  unit?: OperationUnit;
  onClickAliasRightIcon: () => void;
  freeform: boolean;
  offerModeSystemNumber: boolean;
  parentMeasurement?: Measurement;
  onSubmit: (values: OperationValues) => void;
}

const CreateNewOperationForm: FC<CreateNewOperationFormProps> = ({
  catalogSelectable,
  catalogs,
  unit,
  offerModeSystemNumber,
  onClickAliasRightIcon,
  freeform,
  parentMeasurement,
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { formik, errorFeedback } = useForm<OperationValues>({
    validationSchema: validationSchema(
      offerModeSystemNumber ? OfferMode.SYSTEM_NUMBER : OfferMode.ALIAS_NUMBER,
    ),
    initialValues: defaultValues(initialValues),
    onSubmit,
  });
  const { values, setFieldValue, handleSubmit, handleChange } = formik;

  const selectedCatalog = catalogs.find((catalog) => catalog.id === values.catalog);
  const categories = selectedCatalog ? selectedCatalog.categories : [];

  const handleMeasurementTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!parentMeasurement) {
      return;
    }

    if (unit !== e.currentTarget.value) {
      return;
    }

    setFieldValue('measurements', parentMeasurement);
  };

  const handleChangeProxy = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setFieldValue('unit', e.currentTarget.value);
    handleMeasurementTypeChange(e);
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className="row u-margin-top">
          <div className="col col--sm-8">
            <div className="row">
              {freeform && (
                <div className="col col--sm-6">
                  <FieldLayout
                    name="alias"
                    label={t('offer.alias.label')}
                    labelHtmlFor="input-name-of-the-create-alias"
                    className="u-margin-bottom-xsmall u-width-100%"
                    feedback={{ invalid: errorFeedback('alias') }}
                  >
                    {(props) => (
                      <Input
                        {...props}
                        id="input-name-of-the-alias"
                        disabled={offerModeSystemNumber}
                        contentEditable={offerModeSystemNumber}
                        rightIconName={offerModeSystemNumber ? 'info' : undefined}
                        onRightIconClick={offerModeSystemNumber ? onClickAliasRightIcon : undefined}
                      />
                    )}
                  </FieldLayout>
                </div>
              )}
              <div className="col col--sm-6">
                <FieldLayout
                  name="name"
                  label={t('offer.createNewOperationForm.name.label')}
                  labelHtmlFor="input-name-of-the-add-operation"
                  className="u-margin-bottom-xsmall u-width-100%"
                  feedback={{ invalid: errorFeedback('name') }}
                >
                  {(props) => (
                    <Input
                      id="input-name-of-the-add-operation"
                      placeholder={t('offer.createNewOperationForm.name.placeholder')}
                      dataTestId="create-new-operation-form-name-input"
                      {...props}
                    />
                  )}
                </FieldLayout>
              </div>
            </div>
            <div className="row">
              <div className="col col--sm-6">
                <FieldLayout
                  className="u-margin-bottom-xsmall"
                  name="price"
                  label={t('offer.createNewOperationForm.price.label')}
                  labelHtmlFor="input-price"
                  feedback={{ invalid: errorFeedback('price') }}
                >
                  {(inputProps) => (
                    <NumberInput
                      id="input-price"
                      placeholder={t('createNewOperationForm.price.placeholder')}
                      dataTestId="create-new-operation-form-price-input"
                      {...inputProps}
                    />
                  )}
                </FieldLayout>
              </div>
            </div>

            <Box stylex={{ marginBottom: 'small-3x' }}>
              <FormikFormField
                control={Textarea}
                name="description"
                label={t('offer.createNewOperationForm.description.label')}
                id="input-description"
                placeholder={t('offer.createNewOperationForm.description.placeholder')}
                minRows={4}
                maxRows={12}
              />
            </Box>

            <hr />
            <div className="u-margin-top">
              <Details
                renderSummary={({ onClick }) => (
                  <Summary onClick={onClick}>
                    <Text
                      variant="title-small"
                      color="muted"
                      component="h3"
                      className="u-margin-bottom-0"
                    >
                      {t('offer.createNewOperationForm.collapse.title')}
                    </Text>
                  </Summary>
                )}
              >
                <FieldLayout
                  name="note"
                  label={t('offer.createNewOperationForm.note.title')}
                  className="u-margin-top-xsmall"
                  labelHtmlFor="input-kommentar"
                  feedback={{ invalid: errorFeedback('note') }}
                >
                  {(props) => <RichTextEditor id="input-kommentar" enableResizeImage {...props} />}
                </FieldLayout>
              </Details>
            </div>
            <hr />
            <div className="u-margin-top">
              <PaymentForm />
            </div>
            <hr />
            <Text variant="title-small" color="muted">
              {t('offer.createNewOperationFolder.measurement.title')}
            </Text>
            <div className="u-margin-top">
              <MeasurementsSections
                operation
                operationUnit={values.unit}
                required={!unit}
                handleChangeProxy={handleChangeProxy}
              />
            </div>
            {catalogSelectable && (
              <>
                <hr />
                <div className="row">
                  <div className="col col--sm-6">
                    <Text variant="title-small" color="muted">
                      {t('offer.createNewOperationFolder.catalog.title')}
                    </Text>
                    <Text component="p">{t('offer.createNewOperationFolder.catalog.text')}</Text>
                  </div>
                </div>
                <div className="row u-margin-top">
                  <div className="col col--sm-6">
                    <FieldLayout
                      name="catalog"
                      className="u-margin-bottom-xsmall"
                      label={t('offer.createNewOperationFolder.catalog.form.label')}
                      labelHtmlFor="select-catalog"
                      feedback={{ invalid: errorFeedback('catalog') }}
                    >
                      {(inputProps) => (
                        <Select
                          id="select-catalog"
                          options={catalogs.map((catalog) => ({
                            label: catalog.name,
                            value: catalog.id,
                          }))}
                          placeholder={t('offer.createNewOperationFolder.catalog.form.placeholder')}
                          {...inputProps}
                          onChange={(e) => {
                            handleChange(e);
                            const catalogId = e.currentTarget.value;
                            if (catalogId === values.catalog) return;

                            setFieldValue('category', undefined);
                          }}
                        />
                      )}
                    </FieldLayout>
                  </div>
                  <div className="col col--sm-6">
                    {selectedCatalog && (
                      <FieldLayout
                        className="u-margin-bottom-xsmall"
                        name="category"
                        label={t('offer.createNewOperationFolder.catalog.form.tlk.label')}
                        labelHtmlFor="select-category"
                        feedback={{ invalid: errorFeedback('category') }}
                      >
                        {(inputProps) => (
                          <Select
                            id="select-category"
                            options={categories.map(categoryOptionMapper)}
                            placeholder={t(
                              'offer.createNewOperationFolder.catalog.form.tlk.placeholder',
                            )}
                            {...inputProps}
                          />
                        )}
                      </FieldLayout>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col col--sm-4">
            <SidebarLayout>
              <Button
                color="primary"
                fullWidth
                endIcon={<Icon name="save" />}
                data-testid="create-new-operation-form-submit-button"
              >
                {t('offer.createNewOperationFolder.submitButton')}
              </Button>
              <FieldLayout
                className="u-margin-top"
                name="optional"
                labelHtmlFor="input-optional"
                feedback={{ invalid: errorFeedback('optional') }}
              >
                {(inputProps) => (
                  <Choice
                    id="input-optional"
                    type="checkbox"
                    checked={formik.values.optional}
                    label={t('offer.createNewOperationFolder.optional.label')}
                    {...inputProps}
                  />
                )}
              </FieldLayout>
              <FieldLayout
                className="u-margin-top"
                name="approximate"
                labelHtmlFor="input-ca"
                feedback={{ invalid: errorFeedback('approximate') }}
              >
                {(inputProps) => (
                  <Choice
                    id="input-ca"
                    type="checkbox"
                    checked={formik.values.approximate}
                    label={t('offer.createNewOperationFolder.ca.label')}
                    {...inputProps}
                  />
                )}
              </FieldLayout>
            </SidebarLayout>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default CreateNewOperationForm;
