import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { OfferLocationState } from '../../Offer.route.types';
import { OfferFolderCreateRouteParams } from './route';
import { OfferType } from '../../../../globalTypes';
import OfferFolderCreateForFramework from './OfferFolderCreateForFramework';
import OfferFolderCreateForFreeform from './OfferFolderCreateForFreeform';
import { MeasurementsValues } from '../../measurement/MeasurementsSection/values';
import { BaseValues } from '../containers/FolderForm/values';
import { castGqlForMeasurement } from '../containers/FolderForm/form';
import useOffer from '../../hooks/offer';
import { pad } from './offerFolderCreate.utils';
import InfoModal from '../../components/InfoModal';
import { offerRoutes } from '../../offerRoutes';

const OfferFolderCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const { setSelectedFolder, getFolderById, getOffer } = useOffer();

  const [aliasInfoModal, setAliasInfoModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);

  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId } = useParams<OfferFolderCreateRouteParams>();

  const urlParams = new URLSearchParams(location.search);

  const folderId = urlParams.get('folderId') || undefined;
  const f = folderId ? getFolderById(folderId) : undefined;
  const parentId = f ? f.id : undefined;

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [navigate, location.state?.backgroundLocation, offerId]);

  const handleSuccess = (newFolderId: string) => {
    setSelectedFolder(newFolderId);
    navigate(offerRoutes.detail.generatePath({ offerId }));
  };

  const initialValues = (): Partial<BaseValues> | undefined => {
    const { folders } = getOffer();

    if (!folderId) {
      const alias = folders.filter((fol) => !fol.parent).length;

      return {
        alias: pad(alias + 1, 2),
      };
    }

    const folder = getFolderById(folderId);
    if (!folder) {
      return undefined;
    }

    const note = folder.note || '';
    const measurements =
      folder.measurements && folder.measurements.length > 0 && folder.unit
        ? (castGqlForMeasurement(folder.measurements, folder.unit) as MeasurementsValues)
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any);

    const subFolders = folders.filter((fol) => fol.parent?.id === folder.id);

    return {
      alias: pad(subFolders.length + 1, 2),
      note,
      measurements,
    };
  };

  const renderFolderCreateFormByType = () => {
    const { type, mode } = getOffer();

    if (type === OfferType.FRAMEWORK) {
      return (
        <OfferFolderCreateForFramework
          parentId={parentId}
          offerId={offerId}
          onSuccess={handleSuccess}
          initialValues={initialValues()}
        />
      );
    }

    return (
      <OfferFolderCreateForFreeform
        offerMode={mode}
        parentId={parentId}
        offerId={offerId}
        onClickAliasRightIcon={() => setAliasInfoModal(true)}
        onSuccess={handleSuccess}
        initialValues={initialValues()}
      />
    );
  };

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  return (
    <Dialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {t(
              !parentId
                ? 'offer.createFolder.forGroup.title'
                : 'offer.createFolder.forSubGroup.title',
            )}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div>{renderFolderCreateFormByType()}</div>
      </div>

      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Dialog>
  );
};

export default OfferFolderCreate;
