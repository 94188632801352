import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { OfferFolderOperationCloneRouteParams } from '../../operation/OfferFolderOperationClone/route';
import Select, { SelectOption } from '../../../../components/form/Control/Select';
import FieldLayout from '../../../../components/form/FieldLayout';
import SidebarLayout from '../../../../components/layouts/SidebarLayout';
import useOfferSummaryEdit from './useOfferSummaryEdit';
import ErrorState from '../../../../containers/ErrorState';
import useOffer from '../../hooks/offer';
import { offerRoutes } from '../../offerRoutes';
import { OfferLocationState } from '../../Offer.route.types';

const OfferSummaryEdit = (): JSX.Element | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { getOffer } = useOffer();
  const { offerId } = useParams<OfferFolderOperationCloneRouteParams>();
  const { error, edit } = useOfferSummaryEdit(offerId);
  const [routeModalOpen, setRouteModalOpen] = useState(true);
  const { summary, viewerCanUpdateTaxRate } = getOffer();
  const [value, setValue] = useState<string>(`${summary.taxRate * 100}`);

  const handleDialogClose = useCallback(() => {
    if (location?.state.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location?.state.backgroundLocation, navigate, offerId]);

  if (!viewerCanUpdateTaxRate) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
    return null;
  }

  const taxAmountOptions: SelectOption[] = [
    {
      value: '0',
      label: '0 %',
    },
    {
      value: '16',
      label: '16 %',
    },
    {
      value: '19',
      label: '19 %',
    },
  ];

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}

      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {t('offerSummaryEditModal.title')}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          <div className="row">
            <div className="col col--sm-8">
              <Text component="span" variant="title-small" color="gray-700">
                {t('offerSummaryEditModal.subtitle')}
              </Text>
              <div className="u-width-50% u-margin-top">
                <FieldLayout
                  label={t('offerSummaryEditModal.taxAmount.label')}
                  labelHtmlFor="input-type-of-the-offers"
                  className="u-margin-bottom-xsmall u-margin-top u-width-100%"
                >
                  {(props) => (
                    <Select
                      {...props}
                      options={taxAmountOptions}
                      id="input-type-of-the-offers"
                      value={value}
                      defaultValue={summary.taxRate * 100}
                      onChange={({ currentTarget }) => {
                        setValue(currentTarget.value);
                      }}
                    />
                  )}
                </FieldLayout>
              </div>
            </div>
            <div className="col col--sm-4">
              <SidebarLayout>
                <Button
                  color="primary"
                  fullWidth
                  endIcon={<Icon name="save" />}
                  onClick={() => edit(value)}
                >
                  {t('offerSummaryEditModal.submitButton')}
                </Button>
              </SidebarLayout>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OfferSummaryEdit;
