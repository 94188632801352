import { ConcernLog } from '../../graphql/__generated__/ConcernLog.fragment';
import { Log } from '../Log.types';

export const CONCERN_LOG_TYPES = [
  'ConcernArchivedLog',
  'ConcernCreatedLog',
  'ConcernDeletedLog',
  'ConcernEmailSentLog',
  'ConcernSignedLog',
  'ConcernUpdatedLog',
];

export function isConcernLog(log: Log): log is ConcernLog {
  return CONCERN_LOG_TYPES.includes(log.__typename);
}
