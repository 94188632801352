import { fabric } from '@hs-baumappe/fabric';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button, Icon } from '@hs-baumappe/legacy-ui';

import useSelectedTool from '../../hooks/useSelectedTool';
import useCanvas from '../../hooks/useCanvas';
import useAddTextBox from './useAddTextBox';
import useCanvasMover from '../../hooks/useCanvasMover';
import { FontWeight } from './useTextBoxFormatters/useTextBoxFontWeight';
import { FontStyle } from './useTextBoxFormatters/useTextBoxFontStyle';
import { FontDecoration } from './useTextBoxFormatters/useTextBoxFontDecoration';
import useTextBoxFormatters from './useTextBoxFormatters';
import NoteEditorTextFormatPicker from '../../../../components/note-editor/NoteEditorTextFormatPicker';

interface TextToolProps {
  createMode?: boolean;
}

function TextTool({ createMode }: TextToolProps): JSX.Element {
  const { canvas } = useCanvas();
  const { selectedTool, setSelectedTool } = useSelectedTool();
  const { endMoving } = useCanvasMover();
  const { isBold, isItalic, isUnderline, setFontWeight, setFontDecoration, setFontStyle } =
    useTextBoxFormatters();
  const { addTextBox } = useAddTextBox({ createMode: createMode || false });

  function handleTextToolButtonClick() {
    if (!canvas) {
      return;
    }

    endMoving();

    const activeObject = canvas.getActiveObject();

    if (activeObject instanceof fabric.Textbox) {
      // We have to exit then enter to editing to inform the changes to fabric.
      activeObject.exitEditing();
      activeObject.enterEditing();
    } else {
      addTextBox();
    }

    setSelectedTool('text');
  }

  function handleChangeFontWeightButtonClick() {
    if (!canvas) {
      return;
    }

    const activeObject = canvas.getActiveObject();

    if (!(activeObject instanceof fabric.Textbox)) {
      return;
    }

    if (activeObject.isEditing && activeObject.hiddenTextarea) {
      activeObject.hiddenTextarea.focus();
    }

    if (!isBold) {
      setFontWeight(FontWeight.BOLD);
    } else {
      setFontWeight(FontWeight.NORMAL);
    }
  }

  function handleChangeFontStyleButtonClick() {
    if (!canvas) {
      return;
    }

    const activeObject = canvas.getActiveObject();

    if (!(activeObject instanceof fabric.Textbox)) {
      return;
    }

    if (activeObject.isEditing && activeObject.hiddenTextarea) {
      activeObject.hiddenTextarea.focus();
    }

    if (!isItalic) {
      setFontStyle(FontStyle.ITALIC);
    } else {
      setFontStyle(FontStyle.NORMAL);
    }
  }

  function handleChangeFontDecorationButtonClick() {
    if (!canvas) {
      return;
    }

    const activeObject = canvas.getActiveObject();

    if (!(activeObject instanceof fabric.Textbox)) {
      return;
    }

    if (activeObject.isEditing && activeObject.hiddenTextarea) {
      activeObject.hiddenTextarea.focus();
    }

    if (!isUnderline) {
      setFontDecoration(FontDecoration.UNDERLINE);
    } else {
      setFontDecoration(FontDecoration.NONE);
    }
  }

  useHotkeys(
    't',
    () => {
      setTimeout(() => {
        handleTextToolButtonClick();
      });
    },
    [canvas],
  );

  const active = selectedTool === 'text';

  return (
    <NoteEditorTextFormatPicker
      open={active}
      renderTrigger={(triggerProps) => (
        <Button
          color={active ? 'warning' : undefined}
          onClick={handleTextToolButtonClick}
          contentClassName="u-display-flex u-align-items-center"
          {...triggerProps}
        >
          <Icon name="text-fields" size={24} />
        </Button>
      )}
      actions={[
        {
          label: 'Bold',
          iconName: 'format-bold',
          pressed: isBold,
          onClick: handleChangeFontWeightButtonClick,
        },
        {
          label: 'Italic',
          iconName: 'format-italic',
          pressed: isItalic,
          onClick: handleChangeFontStyleButtonClick,
        },
        {
          label: 'Underlined',
          iconName: 'format-underlined',
          pressed: isUnderline,
          onClick: handleChangeFontDecorationButtonClick,
        },
      ]}
    />
  );
}

export default TextTool;
