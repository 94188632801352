import { ApolloError, useQuery } from '@apollo/client';
import SendConstructionNoteEmailDetailQuery from './graphql/SendConstructionNoteEmailDetail.query';
import { SendConstructionNoteEmailDetail } from './graphql/__generated__/SendConstructionNoteEmailDetail.query';

export type ConstructionNoteWithDriveDocument =
  SendConstructionNoteEmailDetail['constructionNote'] & {
    driveDocument?: SendConstructionNoteEmailDetail['driveDocument'];
  };

interface UseSendConstructionNoteEmailDetailResponse {
  constructionNote?: ConstructionNoteWithDriveDocument;
  loading: boolean;
  error?: ApolloError;
}

export default function useSendConstructionNoteEmailDetail(
  constructionNoteId: string,
): UseSendConstructionNoteEmailDetailResponse {
  const { data, loading, error } = useQuery(SendConstructionNoteEmailDetailQuery, {
    variables: { constructionNoteId },
  });

  const driveDocument = data?.driveDocument;
  const constructionNote = data?.constructionNote
    ? { ...data.constructionNote, driveDocument }
    : undefined;

  return {
    constructionNote,
    loading,
    error,
  };
}
