import { FC } from 'react';
import cx from 'classnames';

import './o-data-list.scss';

export type DataListMaxHeight = 'normal' | 'small' | 'infinite';

type Props = JSX.IntrinsicElements['div'] & {
  maxHeightSize?: DataListMaxHeight;
};

const DataList: FC<Props> = ({
  className,
  children,
  maxHeightSize = 'infinite',
  ...otherProps
}) => (
  <div
    className={cx('o-data-list', `o-data-list--${maxHeightSize}`, className)}
    {...otherProps}
    aria-label="Data List"
  >
    {children}
  </div>
);

export default DataList;
