import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Dialog, Flex, Icon, IconButton } from '@hs-baumappe/legacy-ui';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer, NotFoundError } from '@hs-baumappe/redkit';
import { differenceInCalendarDays } from 'date-fns';
import QsstSignDetailQuery from './graphql/QsstSignDetail.query';
import { Logo } from './components/Logo/Logo';
import { QsstSignForm } from './components/QsstSignForm/QsstSignForm';
import { SignedOnSide } from './components/SignedOnSide/SignedOnSide';
import { DocumentDownload } from './components/DocumentDownload/DocumentDownload';
import { LinkExpired } from './components/LinkExpired/LinkExpired';
import { projectsRoutes } from '../../routes';
import { qsstRoutes } from '../qsstRoutes';
import { QsstLocationState, QsstRouteParams } from '../Qsst.route.types';
import { getUrlQueryParams } from '../../../utils/urlQueryParams/getUrlQueryParams';
import { QsstSignUrlQueryParams } from './qsstSignUrlQueryParams';

export default function QsstSign(): JSX.Element {
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const { qsstId } = useParams<QsstRouteParams>();
  const location = useLocation<QsstLocationState>();
  const navigate = useNavigate();

  const { externalAuthToken } = getUrlQueryParams<QsstSignUrlQueryParams>() || {};

  const { data, loading, error, refetch } = useQuery(QsstSignDetailQuery, {
    variables: {
      input: {
        id: qsstId,
        externalAuthToken,
      },
    },
  });

  const handleDialogRequestClose = useCallback(() => {
    setRouteModalOpen(false);
  }, []);

  const handleDialogClose = useCallback(() => {
    if (error) {
      navigate(projectsRoutes.detail.generatePath({}));
      return;
    }

    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(qsstRoutes.detail.generatePath({ qsstId }));
  }, [error, location.state, navigate, qsstId]);

  function renderContent() {
    if (loading && !data) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!data?.qsstForExternal) {
      return <NotFoundError />;
    }

    const { qsstForExternal } = data;
    const { id, signedAt, accessDueDate, hsContactInfo } = qsstForExternal;

    const remainingDays = signedAt && differenceInCalendarDays(new Date(accessDueDate), new Date());
    const isLinkExpired = remainingDays <= 0;

    if (!signedAt) {
      return (
        <QsstSignForm
          qsstId={qsstId}
          externalAuthToken={externalAuthToken}
          qsstForExternal={qsstForExternal}
          onSuccess={() => refetch()}
        />
      );
    }

    if (!externalAuthToken) {
      return <SignedOnSide qsstId={id} />;
    }

    if (!isLinkExpired) {
      return (
        <DocumentDownload qsstForExternal={qsstForExternal} externalAuthToken={externalAuthToken} />
      );
    }

    return <LinkExpired hsContactEmail={hsContactInfo.email ?? ''} />;
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      <Flex className="row" justifyContent="center">
        <Box className="col col--md-10 col--lg-8 col--xl-7">
          <Box className="container" stylex={{ width: '100%', paddingEnds: 'large' }}>
            <Flex alignItems="center">
              <Box stylex={{ flexGrow: '1' }}>
                <Logo />
              </Box>

              <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
            </Flex>

            <Box>{renderContent()}</Box>
          </Box>
        </Box>
      </Flex>
    </Dialog>
  );
}
