import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import ErrorState from '../../../../../containers/ErrorState';
import useRemoveAcceptanceReportManually from './hooks/useRemoveAcceptanceReportManually';

interface AcceptanceReportDetailRemoveManualPdfDialogProps {
  open: boolean;
  name: string;
  id: string;
  onRequestClose: () => void;
  onRemoveSuccess: () => void;
}

export default function AcceptanceReportDetailRemoveManualPdfDialog({
  open,
  name,
  id,
  onRemoveSuccess,
  onRequestClose,
}: AcceptanceReportDetailRemoveManualPdfDialogProps): JSX.Element {
  const { t } = useTranslation();

  const { remove, error } = useRemoveAcceptanceReportManually(id, onRemoveSuccess);

  return (
    <>
      {error && <ErrorState error={error} />}

      <AlertDialog
        destructive
        open={open}
        title={name}
        description={t('acceptanceReport.removeManuallyUploadedReportModal.bodyText')}
        cancelButtonText={t('acceptanceReport.removeManuallyUploadedReportModal.dismissButtonText')}
        confirmButtonText={t('acceptanceReport.removeManuallyUploadedReportModal.submitButtonText')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onConfirm={remove}
        onRequestClose={onRequestClose}
        onCancel={onRequestClose}
      />
    </>
  );
}
