import { Viewer } from '@hs-baumappe/web-auth';

const createViewerSignature = (viewer: Viewer): string => {
  const { email, firstName, lastName, mobile, phone, faxNumber } = viewer;

  return `
    <div>
      Mit freundlichen Grüßen<br /><br />
    </div>
    <div><strong style="color: #0089bf;">${firstName} ${lastName}</strong><br /><br /></div>
    <div>
      <img width="250" src="https://hsprod.blob.core.windows.net/assets/logo.png" alt="Heinrich Schmid" /><br /><br />
    </div>
    <div>
      <span style="color: #0089bf;">Tel: </span>${
        phone ? `<a style="color: #0089bf;" href="tel:${phone}">${phone}</a>` : '-'
      }<br />
      ${faxNumber ? `<span style="color: #0089bf;">Fax: ${faxNumber}</span><br />` : ''}
      <span style="color: #0089bf;">Mobil: </span>${
        mobile ? `<a style="color: #0089bf;" href="tel:${mobile}">${mobile}</a>` : '-'
      }<br />
      <span style="color: #0089bf;">E-Mail: </span>${
        email ? `<a style="color: #0089bf;" href="mailto:${email}">${email}</a>` : '-'
      }<br />
      <a style="color: #0089bf;" href="//www.heinrich-schmid.de/">www.heinrich-schmid.de/</a>
    </div>
  `;
};

export default createViewerSignature;
