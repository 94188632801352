import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  AcceptanceReportSignDetail,
  AcceptanceReportSignDetailVariables,
} from './__generated__/AcceptanceReportSignDetail.query';

const AcceptanceReportSignDetailQuery: TypedDocumentNode<
  AcceptanceReportSignDetail,
  AcceptanceReportSignDetailVariables
> = gql`
  query AcceptanceReportSignDetail($acceptanceReportId: ID!) {
    acceptanceReport(id: $acceptanceReportId) {
      id

      project {
        contactPeople {
          id
          name
        }
      }

      signatures {
        id
        fullName
        type
        imagePath
        createdAt
      }

      attendees {
        id

        fullName
        role
      }
    }

    viewer {
      id

      signature
    }
  }
`;

export default AcceptanceReportSignDetailQuery;
