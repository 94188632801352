import acceptanceReportDe from './locales/de/acceptanceReport.json';
import appModulesDe from './locales/de/appModules.json';
import breadcrumbDe from './locales/de/breadcrumb.json';
import catalogueDe from './locales/de/catalogue.json';
import commonDe from './locales/de/common.json';
import concernDe from './locales/de/concern.json';
import constructionNoteDe from './locales/de/constructionNote.json';
import contactPeopleDe from './locales/de/contactPeople.json';
import customFileDe from './locales/de/customFile.json';
import documentManipulateDe from './locales/de/documentManipulate.json';
import driveImageGalleryDe from './locales/de/driveImageGallery.json';
import formsDe from './locales/de/forms.json';
import logDe from './locales/de/log.json';
import obstructionDe from './locales/de/obstruction.json';
import offerDe from './locales/de/offer.json';
import overtimeDe from './locales/de/overtime.json';
import projectDetailDe from './locales/de/projectDetail.json';
import projectListDe from './locales/de/projectList.json';
import qsstDe from './locales/de/qsst.json';
import riskAssessmentDe from './locales/de/riskAssessment.json';
import settingsDe from './locales/de/settings.json';
import sivBillingAttachmentDe from './locales/de/sivBillingAttachment.json';
import validationDe from './locales/de/validation.json';

import appModulesFr from './locales/fr/appModules.json';
import breadcrumbFr from './locales/fr/breadcrumb.json';
import commonFr from './locales/fr/common.json';
import contactPeopleFr from './locales/fr/contactPeople.json';
import customFileFr from './locales/fr/customFile.json';
import documentManipulateFr from './locales/fr/documentManipulate.json';
import formsFr from './locales/fr/forms.json';
import logFr from './locales/fr/log.json';
import projectDetailFr from './locales/fr/projectDetail.json';
import projectListFr from './locales/fr/projectList.json';
import settingsFr from './locales/fr/settings.json';
import validationFr from './locales/fr/validation.json';

import appModulesEn from './locales/en/appModules.json';
import breadcrumbEn from './locales/en/breadcrumb.json';
import commonEn from './locales/en/common.json';
import contactPeopleEn from './locales/en/contactPeople.json';
import customFileEn from './locales/en/customFile.json';
import documentManipulateEn from './locales/en/documentManipulate.json';
import formsEn from './locales/en/forms.json';
import logEn from './locales/en/log.json';
import projectDetailEn from './locales/en/projectDetail.json';
import projectListEn from './locales/en/projectList.json';
import settingsEn from './locales/en/settings.json';
import validationEn from './locales/en/validation.json';

export const resources = {
  de: {
    translation: {
      ...acceptanceReportDe,
      ...breadcrumbDe,
      ...catalogueDe,
      ...commonDe,
      ...concernDe,
      ...constructionNoteDe,
      ...contactPeopleDe,
      ...customFileDe,
      ...documentManipulateDe,
      ...driveImageGalleryDe,
      ...formsDe,
      ...appModulesDe,
      ...logDe,
      ...obstructionDe,
      ...offerDe,
      ...overtimeDe,
      ...projectDetailDe,
      ...projectListDe,
      ...qsstDe,
      ...riskAssessmentDe,
      ...settingsDe,
      ...sivBillingAttachmentDe,
      ...validationDe,
    },
  },
  fr: {
    translation: {
      ...appModulesFr,
      ...breadcrumbFr,
      ...commonFr,
      ...contactPeopleFr,
      ...customFileFr,
      ...documentManipulateFr,
      ...formsFr,
      ...logFr,
      ...projectDetailFr,
      ...projectListFr,
      ...settingsFr,
      ...validationFr,
    },
  },
  en: {
    translation: {
      ...appModulesEn,
      ...breadcrumbEn,
      ...commonEn,
      ...contactPeopleEn,
      ...customFileEn,
      ...documentManipulateEn,
      ...formsEn,
      ...logEn,
      ...projectDetailEn,
      ...projectListEn,
      ...settingsEn,
      ...validationEn,
    },
  },
};
