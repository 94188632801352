import { useEffect } from 'react';
import { Alert, Icon, Stack, useTimer } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useRiskAssessmentDetailAlert from './hooks/useRiskAssessmentDetailAlert';
import { RiskAssessmentDetailAlert } from '../../RiskAssessmentDetail.utils';

interface RiskAssessmentDetailAlertsProps {
  name: string;
}

export default function RiskAssessmentDetailAlerts({
  name,
}: RiskAssessmentDetailAlertsProps): JSX.Element | null {
  const { t } = useTranslation();

  const { alert, clearAlert } = useRiskAssessmentDetailAlert();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  useEffect(() => {
    if (!alert) {
      return;
    }

    setTimeoutTimer(clearAlert, 5000);

    return () => clearAllTimers();
  }, [alert, clearAlert, clearAllTimers, setTimeoutTimer]);

  function getAlertContent() {
    switch (alert) {
      case RiskAssessmentDetailAlert.SEND:
        return t('riskAssessmentDetail.sendEmail.alert');
      case RiskAssessmentDetailAlert.ARCHIVED:
        return t('riskAssessmentDetail.archive.alert', { riskAssessmentName: name });
      default:
        return '';
    }
  }

  if (!alert) {
    return null;
  }

  return (
    <Stack gap="medium">
      {alert && (
        <Alert color="success" className="qa-alert-message" endSection={<Icon name="check" />}>
          {getAlertContent()}
        </Alert>
      )}
    </Stack>
  );
}
