import { Button, Flex, Spinner, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import { LogsWithDate } from './LogsWithDate';
import Log from './Log';

import './o-document-log.scss';

interface DocumentLogProps {
  hasNextPage?: boolean;
  onMoreDataClick: () => void;
  logs: LogsWithDate[];
  loading?: boolean;
}

export default function LogContainer({
  hasNextPage,
  onMoreDataClick,
  logs,
  loading,
}: DocumentLogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="o-document-log">
      {loading && (
        <Flex justifyContent="center" stylex={{ height: '100%' }}>
          <Spinner size={72} color="primary" />
        </Flex>
      )}

      {logs.length === 0 && !loading && (
        <Flex justifyContent="center" alignItems="center" stylex={{ height: '100%' }}>
          <Text variant="body-small">{t('logContainer.noLogFound')}</Text>
        </Flex>
      )}

      {!loading &&
        logs.map((logsDate, j) => (
          <div key={logsDate.date}>
            <div className="o-document-log__log">
              {logsDate.logs.map((log, i) => (
                <Log key={log.id} showDate={i === 0} log={log} />
              ))}
            </div>
            {logs.length > 0 && j !== logs.length - 1 && <hr className="u-margin-ends-xsmall" />}
          </div>
        ))}
      {hasNextPage && (
        <div className="container">
          <div className="row u-margin-top u-justify-content-center">
            <div className="col col--sm-5 col--xs-12">
              <div className="u-padding-xsmall">
                <Button onClick={onMoreDataClick} fullWidth>
                  {t('logContainer.moreLoadButton')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
