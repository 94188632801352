import { FC } from 'react';
import { Radio, Text } from '@hs-baumappe/legacy-ui';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './RecommendationScore.module.scss';

export interface RecommendationScoreFormValues {
  recommendationScore?: number;
}

const RecommendationScore: FC = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<RecommendationScoreFormValues>();

  const [, meta, helpers] = useField({ name: 'recommendationScore' });

  const isError = meta.error && formik.values.recommendationScore === undefined;

  const rowClassName = cx(['u-margin-top-small', styles.row]);

  const radioClassName = cx({
    [styles.radioError]: isError,
  });

  return (
    <>
      <Text variant="title-medium">{t('qsstSign.form.recommendationScore.title')}</Text>

      <div className={rowClassName}>
        {[...Array(10)].map((_, idx) => {
          const isFirstItem = idx === 0;
          const isLastItem = idx === 9;

          return (
            <div key={idx} className={styles.box}>
              <Text className={styles.label}>{idx + 1}</Text>

              <Text variant="body-small" className={styles.caption}>
                {isFirstItem ? (
                  t('qsstSign.form.recommendationScore.lowScoreLabel')
                ) : isLastItem ? (
                  t('qsstSign.form.recommendationScore.highScoreLabel')
                ) : (
                  <>&nbsp;</>
                )}
              </Text>

              <Radio
                checked={formik.values.recommendationScore === idx}
                onClick={() => {
                  helpers.setValue(idx);
                }}
                className={radioClassName}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export { RecommendationScore };
