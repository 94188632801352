import { gql, TypedDocumentNode } from '@apollo/client';
import { SAPTransmissionSelectedProfitCenter } from './__generated__/SAPTransmissionSelectedProfitCenter.fragment';

const SAPTransmissionSelectedProfitCenterFragment: TypedDocumentNode<SAPTransmissionSelectedProfitCenter> = gql`
  fragment SAPTransmissionSelectedProfitCenter on ProfitCenter {
    id

    name
    projectCount
  }
`;

export default SAPTransmissionSelectedProfitCenterFragment;
