import { ComponentProps, ComponentPropsWithoutRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ControlGroup, FormField, Icon, Input } from '@hs-baumappe/legacy-ui';

interface AddElementInputProps extends ComponentProps<typeof FormField> {
  className?: string;
  onChange?: (value: string) => void;
  onAdd: (value: string) => void;
  id: string;
}

interface AddElementInputGroupProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'color'> {
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
}

function AddElementInputGroup({
  buttonDisabled,
  onButtonClick,
  ...inputProps
}: AddElementInputGroupProps) {
  return (
    <ControlGroup>
      <Input {...inputProps} />
      <Button
        type="button"
        color="primary"
        onClick={onButtonClick}
        disabled={buttonDisabled}
        contentClassName="u-display-flex"
      >
        <Icon name="add" size={24} />
      </Button>
    </ControlGroup>
  );
}

export default function AddElementInput({
  id,
  className,
  onChange,
  onAdd,
}: AddElementInputProps): JSX.Element {
  const { t } = useTranslation();
  const [term, setTerm] = useState('');

  return (
    <FormField<typeof AddElementInputGroup>
      control={AddElementInputGroup}
      id={id}
      className={className}
      label={t('addAdditionalMeasure.additionalMeasure.label')}
      placeholder={t('addAdditionalMeasure.additionalMeasure.placeholder')}
      value={term}
      onChange={({ currentTarget }) => {
        setTerm(currentTarget.value);

        if (onChange) onChange(currentTarget.value);
      }}
      onButtonClick={() => {
        onAdd(term);
        setTerm('');
      }}
      buttonDisabled={!term}
    />
  );
}
