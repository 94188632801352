import { ApolloError } from '@apollo/client';

export enum ErrorCode {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INSUFFICIENT_PROFIT_CENTER_ACCESS_ERROR = 'INSUFFICIENT_PROFIT_CENTER_ACCESS_ERROR',
  ACCESS_EXPIRED_ERROR = 'ACCESS_EXPIRED_ERROR',
  MISSING_SCOPE_ERROR = 'MISSING_SCOPE_ERROR',
  DATAHUB_SEARCH_PROJECT_ERROR = 'DATAHUB_SEARCH_PROJECT_ERROR',
}

export interface ServerError {
  code: string;
  message: string;
  errors: { [key: string]: unknown };
  traceId?: string;
}

export interface BadUserInputError<T> extends ServerError {
  code: ErrorCode.BAD_USER_INPUT;
  errors: { [key in keyof T]: string };
}

export interface DatahubSearchProjectError {
  code: ErrorCode.DATAHUB_SEARCH_PROJECT_ERROR;
  datahubStatusCode: number;
}

export const isBadUserInput = <Errors>(error: ServerError): error is BadUserInputError<Errors> =>
  error.code === ErrorCode.BAD_USER_INPUT;

export const getServerError = (error: ApolloError): ServerError | undefined => {
  const graphqlError = error.graphQLErrors[0];

  if (!graphqlError) return undefined;
  if (!graphqlError.extensions) return undefined;

  const { message } = graphqlError;
  const { code, errors, traceId } = graphqlError.extensions;

  return {
    message,
    code: code as ServerError['code'],
    errors: errors as ServerError['errors'],
    traceId: traceId as ServerError['traceId'],
  };
};

export function getDatahubSearchProjectError(
  error: ApolloError,
): DatahubSearchProjectError | undefined {
  const [graphqlError] = error.graphQLErrors;

  if (!graphqlError) return;
  if (!graphqlError.extensions) return;

  const { code, datahubStatusCode } = graphqlError.extensions;
  if (code !== ErrorCode.DATAHUB_SEARCH_PROJECT_ERROR || typeof datahubStatusCode !== 'number') {
    return;
  }

  return {
    code,
    datahubStatusCode,
  };
}

export function getErrorsFromServerError<TFromValues, R>(
  error: ApolloError,
  mapper: (errors: BadUserInputError<R>['errors']) => Partial<TFromValues>,
): Partial<TFromValues> | undefined {
  const serverError = getServerError(error);
  if (serverError && isBadUserInput<R>(serverError)) {
    return mapper(serverError.errors);
  }

  return undefined;
}

export function getServerErrorFromApolloError(error: ApolloError): string | undefined {
  const serverError = getServerError(error);

  if (!serverError) {
    return undefined;
  }

  if (isBadUserInput(serverError)) {
    return undefined;
  }

  return serverError.message;
}
