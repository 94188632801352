import { useState, useEffect } from 'react';

interface WindowSize {
  innerWidth?: number;
  innerHeight?: number;
}

// This hook is created to avoid 100vh bug on mobile devicess
const useWindowSize = (): WindowSize => {
  const [size, setSize] = useState<WindowSize>({ innerHeight: undefined, innerWidth: undefined });

  useEffect(() => {
    function listener() {
      const { innerWidth, innerHeight } = window;

      setSize({ innerWidth, innerHeight });
    }

    setSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return size;
};

export default useWindowSize;
