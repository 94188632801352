import { memo } from 'react';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import GetProjectClientQuery from './graphql/GetProjectClient.query';
import {
  GetProjectClient,
  GetProjectClientVariables,
} from './graphql/__generated__/GetProjectClient.query';

import ContactPeopleTable from './ContactPeopleTable';

import { ProjectDetailLocationState } from '../../ProjectDetail.route.types';
import { projectRoutes } from '../../../../routes';

const MISSING_INFORMATION_CALLBACK = '-';

interface ProjectClientInformationProps {
  projectId: string;
  viewerCanChangeProjectData: boolean;
}

function ProjectClientInformation({
  projectId,
  viewerCanChangeProjectData,
}: ProjectClientInformationProps): JSX.Element | null {
  const { t } = useTranslation();
  const location = useLocation<ProjectDetailLocationState>();
  const navigate = useNavigate();

  const { data, error } = useQuery<GetProjectClient, GetProjectClientVariables>(
    GetProjectClientQuery,
    {
      variables: {
        projectId,
      },
    },
  );

  if (error) return <ErrorRenderer apolloError={error} />;
  if (!data) return null;

  const { client, contactPeople } = data.project;

  function clientAddressFormatter(address: string) {
    const [zipAndCity, streetAndHouseNumber] = address.split(',');

    if (!streetAndHouseNumber) {
      return zipAndCity;
    }

    return `${streetAndHouseNumber}, ${zipAndCity}`;
  }

  function handleAddPersonButtonClick() {
    navigate(projectRoutes.addContactPeople.generatePath({ projectId }), {
      state: {
        backgroundLocation: location,
      },
    });
  }

  return (
    <>
      <div className="row u-margin-top-xsmall">
        <div className="col col--sm-6 u-margin-top-xsmall">
          <Text variant="label-small" color="muted">
            {t('client.title')}
          </Text>
          <Text
            component="p"
            variant="body-small"
            className="u-margin-top-0"
            data-testid="customer-information-client-name"
          >
            {client?.name || MISSING_INFORMATION_CALLBACK}
          </Text>
        </div>
        <div className="col col--sm-6 u-margin-top-xsmall">
          <Text variant="label-small" color="muted">
            {t('projectDetail.customerInformation.address')}
          </Text>
          <Text
            component="p"
            variant="body-small"
            className="u-margin-top-0"
            data-testid="customer-information-client-address"
          >
            {client && client.address
              ? clientAddressFormatter(client.address)
              : MISSING_INFORMATION_CALLBACK}
          </Text>
        </div>
      </div>
      <div className="u-margin-top">
        <ContactPeopleTable
          projectId={projectId}
          contactPeople={contactPeople}
          viewerCanChangeProjectData={viewerCanChangeProjectData}
        />
      </div>
      <div className="row u-margin-top u-align-items-center">
        <div className="col col--md-5 col--lg-6">
          <Text>{t('contactPeopleForm.addAnotherPersonHelperText')}</Text>
        </div>

        <div className="col col--md-5 col--lg-5">
          <Button
            type="button"
            color="primary"
            endIcon={<Icon name="add" />}
            onClick={handleAddPersonButtonClick}
            data-testid="add-a-person-button"
            disabled={!viewerCanChangeProjectData}
            fullWidth
          >
            {t('contactPeopleForm.addAnotherPersonButton')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default memo(ProjectClientInformation);
