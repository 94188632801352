import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getErrorsFromServerError } from '../../../../apollo/errors';
import { QsstSaveInput } from '../../../../globalTypes';
import QsstSaveMutation from './graphql/QsstSave.mutation';
import { QsstFormValues } from '../../../../components/QsstSave/QsstForm/QsstForm.form';
import { qsstRoutes } from '../../qsstRoutes';
import { createQsstSaveInput } from './useQsstSave.utils';

interface UseQsstSaveResponse {
  save: (formValues: QsstFormValues, formikHelpers: FormikHelpers<QsstFormValues>) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useQsstSave(qsstId: string): UseQsstSaveResponse {
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(QsstSaveMutation);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function save(formValues: QsstFormValues, formikHelpers: FormikHelpers<QsstFormValues>) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: createQsstSaveInput({ formValues, qsstId }),
        },
      });

      navigate(qsstRoutes.detail.generatePath({ qsstId }));
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<FormikErrors<QsstFormValues>, QsstSaveInput>(
        e,
        (errors) => {
          return {
            ...errors,
            onsideEmployee: {
              fullName: errors.onsideEmployee,
            },
          };
        },
      );

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return { save, loading, error };
}
