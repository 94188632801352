import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Viewer } from '@hs-baumappe/web-auth';
import { Box, Text } from '@hs-baumappe/legacy-ui';
import { OfferData } from '../../../Detail/__generated__/graphql';
import EmailForm from '../../../../../containers/Email';
import useGaeb from './useGaeb';
import { createExportFormHTMLContentGaeb } from './createExportFormHTMLContent';

interface Props {
  offerId: string;
  project: OfferData['offer']['project'];
  viewer: Viewer;
  onSuccess: (m: string) => void;
}

const Gaeb: FC<Props> = ({ offerId, project, viewer, onSuccess }) => {
  const { t } = useTranslation();
  const { exportOffer, loading } = useGaeb({ offerId, onSuccess });

  return (
    <Box>
      <Text>{t('exportOffer.tab.gaeb.description')}</Text>

      <Box stylex={{ marginTop: 'medium' }}>
        <EmailForm
          initialValues={{
            subject: t('exportOffer.tab.gaeb.form.subject', {
              offerId,
            }),
            content: createExportFormHTMLContentGaeb(project, viewer),
          }}
          projectId={project.id}
          submitButtonText={t('exportOfferForm.gaeb.submitButton')}
          loading={loading}
          hideSubjectInput
          onSubmit={exportOffer}
        />
      </Box>
    </Box>
  );
};

export default Gaeb;
