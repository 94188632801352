import { gql } from '@apollo/client';

const AppModuleNavigationProjectFragment = gql`
  fragment AppModuleNavigationProject on Project {
    id
    draft

    siv {
      id

      hasBillableOperations
    }

    drive {
      id

      mimeTypeFolderMap {
        folder {
          id
          name
        }
        mimeType
      }
    }
  }
`;

export default AppModuleNavigationProjectFragment;
