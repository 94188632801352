import { useState } from 'react';
import { createPath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { AssignDocumentToProjectRouteParams } from './AssignDocumentToProject.route.types';
import AssignableProjectSearch from './AssignableProjectSearch';
import useAssignDocumentToProject from './useAssignDocumentToProject';
import { DocumentType } from '../../../../globalTypes';
import { projectRoutes } from '../../../routes';
import ErrorState from '../../../../containers/ErrorState';

const AssignDocumentToProject = (): JSX.Element => {
  const { t } = useTranslation();
  const { projectId, documentId } = useParams<AssignDocumentToProjectRouteParams>();
  const navigate = useNavigate();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const [searchParams] = useSearchParams();
  const selectedProjectId = searchParams.get('projectId') || undefined;
  const documentType = (searchParams.get('document') as DocumentType) || undefined;
  const returnPath = searchParams.get('returnPath') || undefined;

  const { assignDocument, error } = useAssignDocumentToProject(
    documentId,
    documentType,
    returnPath,
  );

  function handleDialogClose() {
    navigate(-1);
  }

  function handleProjectImportClick() {
    const assignDocumentSearchParams = new URLSearchParams();

    if (returnPath) {
      assignDocumentSearchParams.set('returnPath', returnPath);
    }

    if (documentType) {
      assignDocumentSearchParams.set('document', documentType);
    }

    navigate(
      createPath({
        pathname: projectRoutes.assignDocumentToProjectImport.generatePath({
          projectId,
          documentId,
        }),
        search: assignDocumentSearchParams.toString(),
      }),
    );
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {t('assignDocumentToProject.title')}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          <AssignableProjectSearch
            defaultSelectedProjectId={selectedProjectId}
            onClickProjectImport={handleProjectImportClick}
            onClickProjectAssign={(project) => project && assignDocument(project.id)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AssignDocumentToProject;
