import { createPath, useNavigate, useParams } from 'react-router-dom';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';
import useUserCanSave from '../../hooks/useUserCanSave';
import useUpdateConstructionNote from './useUpdateConstructionNote';
import ErrorState from '../../../../containers/ErrorState';
import { constructionNoteRoutes } from '../../constructionNoteRoutes';
import { ConstructionNoteRouteParams } from '../../ConstructionNote.route.types';

export default function SaveTool(): JSX.Element {
  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const navigate = useNavigate();

  const { canvas, exportAsJSON, exportAsImage } = useCanvas();
  const userCanSave = useUserCanSave();
  const [mutate, { loading, error }] = useUpdateConstructionNote();

  const save = async () => {
    if (!canvas) {
      return;
    }

    const file = exportAsJSON();
    const imagePresentation = exportAsImage();

    if (!file || !imagePresentation) {
      return;
    }

    await mutate({
      variables: {
        file,
        imagePresentation,
        input: {
          documentId: constructionNoteId,
        },
      },
    });

    const searchParams = new URLSearchParams({
      noteEdited: 'true',
    });

    navigate(
      createPath({
        pathname: constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }),
        search: searchParams.toString(),
      }),
    );
  };

  return (
    <>
      <Button
        contentClassName="u-display-flex u-align-items-center"
        onClick={save}
        disabled={!userCanSave || loading}
      >
        <Icon name="save" size={24} />
      </Button>

      {error && <ErrorState error={error} />}
    </>
  );
}
