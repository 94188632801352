import { createPath, Link } from 'react-router-dom';
import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import DriveDocumentNoteContainer from '../../../../../components/DriveDocumentNoteContainer';
import { EditQsstButton } from './EditQsstButton/EditQsstButton';
import { qsstRoutes } from '../../../qsstRoutes';
import { Qsst } from '../../../graphql/__generated__/Qsst.fragment';
import { QsstScopes } from '../../../Qsst.types';
import { QsstSendMailMethod } from '../../../QsstSendMail/QsstSendMail';

interface QsstDetailSidebarTopProps {
  qsst: Qsst;
  scopes: QsstScopes;
  projectId: string;
  versionedUrl: string;
}

function QsstDetailSidebarTop({
  qsst,
  scopes,
  projectId,
  versionedUrl,
}: QsstDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateQsstDocument.isPermitted;
  const sendPermitted = scopes.sendQsstDocumentAttachment.isPermitted;
  const downloadPermitted = scopes.downloadQsstDocument.isPermitted;

  return (
    <Stack gap="small-3x">
      <DriveDocumentNoteContainer id={qsst.id} name={qsst.name}>
        {updatePermitted && (
          <EditQsstButton
            qsstId={qsst.id}
            qsstName={qsst.name}
            projectId={projectId}
            viewerCanUpdate={qsst.viewerCanUpdate}
          />
        )}

        {sendPermitted && (
          <Button
            component={Link}
            endIcon={<Icon name="email" />}
            to={createPath({
              pathname: qsstRoutes.sendEmail.generatePath({ qsstId: qsst.id }),
              search: new URLSearchParams({ method: QsstSendMailMethod.ATTACHMENT }).toString(),
            })}
          >
            {t('qsst.sendMailButton')}
          </Button>
        )}

        {downloadPermitted && (
          <Button
            component="a"
            endIcon={<Icon name="cloud-download" />}
            target="_blank"
            href={versionedUrl || ''}
          >
            {t('qsst.downloadButton')}
          </Button>
        )}
      </DriveDocumentNoteContainer>
    </Stack>
  );
}

export { QsstDetailSidebarTop };
