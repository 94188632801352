import { RiskAssessmentEmployees } from '../../graphql/__generated__/RiskAssessmentEmployees.query';
import { InCharge } from '../../values';

export function getEmployeePhoneNumbers(
  employees?: RiskAssessmentEmployees['employeesForProject'],
  employeeFullName?: InCharge['fullName'],
): undefined | string[] {
  if (!employees || !employeeFullName) {
    return;
  }

  const selectedEmployee =
    employeeFullName && employees
      ? employees.find((employee) => employee.name === employeeFullName)
      : undefined;

  return selectedEmployee
    ? ([selectedEmployee.mobile, selectedEmployee.phone].filter(Boolean) as string[])
    : undefined;
}
