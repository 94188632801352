import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ASSIGN_DOCUMENT_TO_PROJECT } from './graphql';

import { AssignDocumentToProject, AssignDocumentToProjectVariables } from './__generated__/graphql';
import { DocumentType } from '../../../../globalTypes';

interface UseAssignDocumentToProjectResponse {
  error?: ApolloError;
  assignDocument: (projectId: string) => Promise<void>;
}

function useAssignDocumentToProject(
  documentId: string,
  documentType: DocumentType,
  returnPath?: string,
): UseAssignDocumentToProjectResponse {
  const navigate = useNavigate();
  const [mutation, { loading, error }] = useMutation<
    AssignDocumentToProject,
    AssignDocumentToProjectVariables
  >(ASSIGN_DOCUMENT_TO_PROJECT);

  async function assignDocument(projectId: string) {
    if (loading) {
      return;
    }

    const { data } = await mutation({
      variables: {
        input: {
          id: documentId,
          project: projectId,
          type: documentType,
        },
      },
    });

    if (!data || !returnPath) {
      return;
    }

    navigate(returnPath);
  }

  return {
    error,
    assignDocument,
  };
}

export default useAssignDocumentToProject;
