import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentEmployees,
  RiskAssessmentEmployeesVariables,
} from './__generated__/RiskAssessmentEmployees.query';

const RiskAssessmentEmployeesQuery: TypedDocumentNode<
  RiskAssessmentEmployees,
  RiskAssessmentEmployeesVariables
> = gql`
  query RiskAssessmentEmployees($projectId: ID!) {
    employeesForProject(id: $projectId) {
      name
      mobile
      phone
    }
  }
`;

export default RiskAssessmentEmployeesQuery;
