import { FC } from 'react';
import { SmileyBox } from './SmileyBox/SmileyBox';
import { useTranslation } from 'react-i18next';

const SmileyRow: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <SmileyBox score={0} label={t('qsstSign.form.specificScore.smiley.score0label')} />
      <SmileyBox score={1} label={t('qsstSign.form.specificScore.smiley.score1label')} />
      <SmileyBox score={2} label={t('qsstSign.form.specificScore.smiley.score2label')} />
      <SmileyBox score={3} label={t('qsstSign.form.specificScore.smiley.score3label')} />
      <SmileyBox score={4} label={t('qsstSign.form.specificScore.smiley.score4label')} />
    </>
  );
};

export { SmileyRow };
