import { FC, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import FolderForm from '../../containers/FolderForm';
import { BaseValues } from '../../containers/FolderForm/values';
import { FOLDER_CLONE_DATA } from './graphql';
import { FolderCloneData, FolderCloneDataVariables } from './__generated__/graphql';
import categoryMapper from '../../containers/categoryMapper';
import useFolderClone, { FolderCloneProps } from '../useFolderClone';
import ErrorState from '../../../../../containers/ErrorState';

interface Props {
  parentId?: string;
  folderId: FolderCloneProps['folderId'];
  offerId: FolderCloneProps['offerId'];
  onSuccess: FolderCloneProps['onSuccess'];
  initialValues?: Partial<BaseValues>;
}

const OfferFolderCloneForFramework: FC<Props> = ({
  parentId,
  offerId,
  folderId,
  initialValues,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { cloneFolder, error } = useFolderClone({ onSuccess, folderId, offerId });
  const { data, loading } = useQuery<FolderCloneData, FolderCloneDataVariables>(FOLDER_CLONE_DATA, {
    variables: {
      parentId,
      offerId,
    },
  });

  if (!data || loading) {
    return <LayoutLoading />;
  }

  const { listAvailableCategoriesForFolderCreation } = data;

  const categories = categoryMapper(listAvailableCategoriesForFolderCreation);

  return (
    <Fragment>
      {error && <ErrorState error={error} />}

      <FolderForm
        framework
        categories={categories}
        parentId={parentId}
        onSubmit={cloneFolder}
        buttonText={t('cloneOfferFolderForFramework.saveButton')}
        buttonIcon="save"
        initialValues={initialValues}
      />
    </Fragment>
  );
};

export default OfferFolderCloneForFramework;
