import { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';

interface AddContactPersonConfirmationModalProps {
  open: boolean;
  onRequestClose: () => void;
  onDismissButtonClick: () => void;
  title: ReactNode;
  body: ReactNode;
  dismissButtonText: ReactNode;
  confirmButtonText: ReactNode;
}

export default function ContactPersonConfirmationModal({
  open,
  onRequestClose,
  onDismissButtonClick,
  title,
  body,
  dismissButtonText,
  confirmButtonText,
}: AddContactPersonConfirmationModalProps): JSX.Element {
  return (
    <Dialog onRequestClose={onRequestClose} open={open} role="alertdialog" size="xsmall">
      <DialogHeader
        rightSlot={
          <IconButton icon={<Icon name="close" />} onClick={onDismissButtonClick} type="button" />
        }
      >
        <Text component="h2" variant="title-medium" className="u-margin-ends-0">
          {title}
        </Text>
      </DialogHeader>
      <DialogBody>
        <Text>{body}</Text>
      </DialogBody>
      <DialogActions direction="column">
        <Button type="button" onClick={onDismissButtonClick}>
          {dismissButtonText}
        </Button>
        <Button
          type="button"
          color="error"
          onClick={onRequestClose}
          endIcon={<Icon name="delete" />}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
