import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { createPath, useNavigate } from 'react-router-dom';
import SendPdfEmailConcernMutation from '../../graphql/ConcernSendEmail.mutation';

import { getErrorsFromServerError } from '../../../../../../apollo/errors';
import { ConcernPdfEmailSendInput } from '../../../../../../globalTypes';
import { concernRoutes } from '../../../../concernRoutes';
import {
  ConcernDetailAlert,
  ConcernDetailSearchParams,
} from '../../../../ConcernDetail/ConcernDetail.utils';
import { EmailFormValues } from '../../../../../../containers/Email';

interface UseConcernSendEmailResponse {
  send: (values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useConcernSendEmail(concernId: string): UseConcernSendEmailResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError>();
  const [mutate, { loading }] = useMutation(SendPdfEmailConcernMutation);

  async function send(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    if (loading) {
      return;
    }

    setError(undefined);

    try {
      await mutate({
        variables: {
          input: {
            documentId: concernId,
            cc: values.ccEmails,
            recipients: values.toEmails,
            subject: values.subject,
            content: values.content,
          },
        },
      });

      const searchParams = new URLSearchParams({
        [ConcernDetailSearchParams.ALERT]: ConcernDetailAlert.SEND,
      });

      navigate(
        createPath({
          pathname: concernRoutes.detail.generatePath({ concernId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        ConcernPdfEmailSendInput
      >(e, (errors) => {
        return {
          ...errors,
          toEmails: errors.recipients,
          ccEmails: errors.cc,
        };
      });

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return { send, loading, error };
}
