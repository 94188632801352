import { gql } from '@apollo/client';

const ObstructionProjectFragment = gql`
  fragment ObstructionProject on Project {
    id

    name
    description
    projectNumber
    hsProjectNumber
    draft
  }
`;

export default ObstructionProjectFragment;
