import { TypedDocumentNode, gql } from '@apollo/client';
import { OvertimeLog } from './__generated__/OvertimeLog.fragment';

const OvertimeLogFragment: TypedDocumentNode<OvertimeLog> = gql`
  fragment OvertimeLog on OvertimeLog {
    ... on OvertimeArchivedLog {
      id
      name
      date
      overtimeId

      actorId
      actorFirstName
      actorLastName
    }

    ... on OvertimeCreatedLog {
      id
      name
      date
      overtimeId

      actorId
      actorFirstName
      actorLastName
    }

    ... on OvertimeDeletedLog {
      id
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on OvertimeEmailSentLog {
      id
      name
      date
      overtimeId

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on OvertimeExternalEmailSentLog {
      id
      name
      date

      overtimeId

      actorId
      actorFirstName
      actorLastName

      emails
    }

    ... on OvertimeExternalLinkExpiredLog {
      id
      name
      date
    }

    ... on OvertimeManuallySignedLog {
      id
      name
      date

      overtimeId

      actorId
      actorFirstName
      actorLastName
    }

    ... on OvertimeNotSignedLog {
      id
      overtimeId
      name
      date
      dayCount
    }

    ... on OvertimeRenamedLog {
      id
      name
      oldName
      date
      overtimeId

      actorId
      actorFirstName
      actorLastName
    }

    ... on OvertimeSignedByCustomerLog {
      id
      name
      date
      overtimeId
    }

    ... on OvertimeUpdatedLog {
      id
      name
      date
      overtimeId

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default OvertimeLogFragment;
