import { gql } from '@apollo/client';
import { CATALOGUE_FRAGMENT } from '../containers/catalogueMapper/graphql';

// eslint-disable-next-line import/prefer-default-export
export const GET_OPERATION_CREATE_DATA = gql`
  query GetOperationCreateData {
    catalogues {
      ...CatalogueFragment
    }
  }

  ${CATALOGUE_FRAGMENT}
`;
