import { AlertDialog, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useConstructionNoteDuplication from './useConstructionNoteDuplication';
import ErrorState from '../../../../containers/ErrorState';

interface ConstructionNoteDuplicationModalProps {
  open: boolean;
  constructionNoteId: string;
  constructionNoteName: string;

  onRequestClose?(): void;
}

export default function ConstructionNoteDuplicationModal({
  open,
  constructionNoteId,
  constructionNoteName,
  onRequestClose,
}: ConstructionNoteDuplicationModalProps): JSX.Element {
  const { t } = useTranslation();
  const { duplicate, error } = useConstructionNoteDuplication({
    constructionNoteId,
  });

  return (
    <>
      {error && <ErrorState error={error} />}

      <AlertDialog
        title={constructionNoteName}
        description={t('constructionNote.duplicate.bodyText')}
        confirmButtonText={t('constructionNote.duplicate.confirmButton')}
        cancelButtonText={t('constructionNote.duplicate.cancelButton')}
        confirmButtonEndIcon={<Icon name="file-copy" />}
        onConfirm={() => {
          duplicate();
          onRequestClose?.();
        }}
        onRequestClose={onRequestClose}
        onCancel={onRequestClose}
        open={open}
      />
    </>
  );
}
