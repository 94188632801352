import { FC, useMemo } from 'react';
import { createPath, Link } from 'react-router-dom';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../../../../../globalTypes';
import { projectRoutes } from '../../../../../routes';
import { qsstRoutes } from '../../../../qsstRoutes';

interface AssignQsstButtonProps {
  qsstId: string;
  projectId: string;
}

export const AssignQsstButton: FC<AssignQsstButtonProps> = ({ qsstId, projectId }) => {
  const { t } = useTranslation();

  const path = useMemo(() => {
    const searchParams = new URLSearchParams({
      document: DocumentType.QSST,
      returnPath: qsstRoutes.detail.generatePath({
        qsstId,
      }),
    });

    const pathname = projectRoutes.assignDocumentToProject.generatePath({
      projectId,
      documentId: qsstId,
    });

    return createPath({
      pathname,
      search: searchParams.toString(),
    });
  }, [projectId, qsstId]);

  return (
    <Button
      component={Link}
      className="u-margin-top-xsmall"
      to={path}
      fullWidth
      endIcon={<Icon name="folder" />}
    >
      {t('qsst.assignButton')}
    </Button>
  );
};
