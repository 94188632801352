import CardCheckbox, { CardCheckboxProps } from '../../../../../../components/form/CardCheckbox';
import { EquipmentIcons, EquipmentIconName } from '../../equipmentIcons';

type ProtectiveEquipmentCheckboxProps = Omit<CardCheckboxProps, 'imageSrc'> & {
  iconName: EquipmentIconName;
};

export default function ProtectiveEquipmentCheckbox({
  iconName,
  ...otherProps
}: ProtectiveEquipmentCheckboxProps): JSX.Element {
  return <CardCheckbox imageSrc={EquipmentIcons[iconName]} {...otherProps} />;
}
