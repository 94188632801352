import { fabric } from '@hs-baumappe/fabric';

export function countTextBoxObjects(objects: fabric.Object[]): number {
  const textBoxObjects = objects.filter((object) => object.type === 'textbox');

  return textBoxObjects.length;
}

export function repeatEmptyLines(count: number): string {
  return '\n'.repeat(count);
}
