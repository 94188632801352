import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  DuplicateAcceptanceReport,
  DuplicateAcceptanceReportVariables,
} from './__generated__/DuplicateAcceptanceReport.mutation';

const DuplicateAcceptanceReportMutation: TypedDocumentNode<
  DuplicateAcceptanceReport,
  DuplicateAcceptanceReportVariables
> = gql`
  mutation DuplicateAcceptanceReport($input: DuplicateAcceptanceReportInput!) {
    duplicateAcceptanceReport(input: $input) {
      id
    }
  }
`;

export default DuplicateAcceptanceReportMutation;
