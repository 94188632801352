import { ProjectFilterFormValues } from './ProjectFilterForm.form';
import { ProjectStatus } from '../../../../../../globalTypes';

interface GetAppliedFiltersResponse {
  statuses: ProjectStatus[];
  profitCenters: string[];
}

// eslint-disable-next-line import/prefer-default-export
export function getAppliedFilters(values: ProjectFilterFormValues): GetAppliedFiltersResponse {
  const statuses = values.statuses.filter((status) => status.checked).map((status) => status.value);
  const profitCenters = values.profitCenters
    .filter((profitCenter) => profitCenter.checked)
    .map((profitCenter) => profitCenter.value);

  return {
    statuses,
    profitCenters,
  };
}
