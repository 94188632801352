import { gql, TypedDocumentNode } from '@apollo/client';
import {
  SivKeyDataSapTransmit,
  SivKeyDataSapTransmitVariables,
} from './__generated__/SivKeyDataSapTransmit.mutation';

export const SivKeyDataSapTransmitMutation: TypedDocumentNode<
  SivKeyDataSapTransmit,
  SivKeyDataSapTransmitVariables
> = gql`
  mutation SivKeyDataSapTransmit($input: SIVKeyDataSapTransmitInput!) {
    sivKeyDataSapTransmit(input: $input) {
      status
      completedTransmissions {
        id
        projectNumber
        name
      }
      failedTransmissions {
        id
        projectNumber
        name
      }
    }
  }
`;
