import { gql, TypedDocumentNode } from '@apollo/client';
import {
  GetDocumentPSPDFKitToken,
  GetDocumentPSPDFKitTokenVariables,
} from './__generated__/GetDocumentPSPDFKitToken.query';

const GetDocumentPSPDFKitTokenQuery: TypedDocumentNode<
  GetDocumentPSPDFKitToken,
  GetDocumentPSPDFKitTokenVariables
> = gql`
  query GetDocumentPSPDFKitToken($input: GetPspdfkitAuthTokenInput!) {
    getPspdfkitAuthToken(input: $input) {
      pspdfkitAuthToken
    }
  }
`;
export default GetDocumentPSPDFKitTokenQuery;
