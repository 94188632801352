import { gql } from '@apollo/client';

export default gql`
  query QsstFormDetailSectionDetails($projectId: ID!) {
    employeesForProject(id: $projectId) {
      name
    }

    project(id: $projectId) {
      id
      contactPeople {
        id
        fullName: name
      }
      draft
    }
  }
`;
