import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_DOCUMENT_MUTATION = gql`
  mutation DriveDocumentRemove($input: DriveDocumentRemoveInput!) {
    driveDocumentRemove(input: $input) {
      status
    }
  }
`;
