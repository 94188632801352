import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentArchive,
  RiskAssessmentArchiveVariables,
} from './__generated__/RiskAssessmentArchive.mutation';

const RiskAssessmentArchiveMutation: TypedDocumentNode<
  RiskAssessmentArchive,
  RiskAssessmentArchiveVariables
> = gql`
  mutation RiskAssessmentArchive($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
    }
  }
`;

export default RiskAssessmentArchiveMutation;
