import { gql } from '@apollo/client';

export default gql`
  fragment GetProjectDetailFragment on Project {
    id

    name
    additionalName
    projectNumber
    status
    date

    draft
    syncable
    ordered

    addressLine
    postCode
    city
    street

    client {
      name
    }

    showSubProjectHours

    viewerCanUpdateStatus
    viewerLogUnreadCount
    viewerFollowsProject
  }
`;
