import { useHotkeys } from 'react-hotkeys-hook';
import { IconButton, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';

export default function UndoAction(): JSX.Element {
  const { undo, canUndo } = useCanvas();

  useHotkeys(
    'ctrl+z, command+z',
    () => {
      undo();
    },
    [undo],
  );

  return (
    <IconButton
      data-testid="undo-action-button"
      icon={<Icon name="undo" />}
      onClick={undo}
      disabled={!canUndo}
    />
  );
}
