import { gql, TypedDocumentNode } from '@apollo/client';
import LogFragment from '../../../../containers/LogContainer/graphql/Log.fragment';
import { ConcernLogs, ConcernLogsVariables } from './__generated__/ConcernLogs.query';

const ConcernLogsQuery: TypedDocumentNode<ConcernLogs, ConcernLogsVariables> = gql`
  query ConcernLogs($concernId: ID!, $page: Int!, $limit: Int!) {
    concern(id: $concernId) {
      id

      logs(page: $page, limit: $limit) {
        ...LogFragment

        pagination {
          current
          hasNextPage
        }
      }
    }
  }
  ${LogFragment}
`;

export default ConcernLogsQuery;
