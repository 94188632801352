import { gql, TypedDocumentNode } from '@apollo/client';

import {
  ToggleShowSubProjectHours,
  ToggleShowSubProjectHoursVariables,
} from './__generated__/ToggleShowSubProjectHours.mutation';

const ToggleShowSubProjectHoursMutation: TypedDocumentNode<
  ToggleShowSubProjectHours,
  ToggleShowSubProjectHoursVariables
> = gql`
  mutation ToggleShowSubProjectHours($input: ToggleShowSubProjectHoursInput!) {
    toggleShowSubProjectHours(input: $input) {
      id

      showSubProjectHours

      partProjects {
        id
        siv {
          recording {
            totalHourOfEmployees
            totalHoursToBeDistributed
          }
        }
      }
    }
  }
`;

export default ToggleShowSubProjectHoursMutation;
