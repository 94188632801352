import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { Box, Tab, Tabs, Text } from '@hs-baumappe/legacy-ui';
import { OfferLocationState } from '../../Offer.route.types';
import { ExportOfferRouteParams } from './route';

import useOffer from '../../hooks/offer';
import Gaeb from './Gaeb';
import { offerRoutes } from '../../offerRoutes';
import RouteDialog from '../../../../containers/RouteDialog';

export enum ExportForms {
  ITWO = 'ITWO',
  GAEB = 'GAEB',
}

const ExportOffer = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId } = useParams<ExportOfferRouteParams>();
  const { getProject, setAlertMessage } = useOffer();
  const [dialogOpen, setDialogOpen] = useState(true);

  const project = getProject();

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location.state?.backgroundLocation, navigate, offerId]);

  if (!viewer) {
    return null;
  }

  const handleSuccess = (m: string) => {
    setAlertMessage(m);
    navigate(offerRoutes.detail.generatePath({ offerId }));
  };

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  return (
    <RouteDialog
      open={dialogOpen}
      header={
        <Text variant="title-small" component="h2">
          {t('exportOffer.title')}
        </Text>
      }
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
    >
      <Tabs defaultSelectedTabId={ExportForms.GAEB}>
        <Tab
          id={ExportForms.GAEB}
          label={t('exportOffer.tab.gaeb.title')}
          panel={
            <Box stylex={{ marginTop: 'medium' }}>
              <Gaeb offerId={offerId} project={project} viewer={viewer} onSuccess={handleSuccess} />
            </Box>
          }
        />
      </Tabs>
    </RouteDialog>
  );
};

export default ExportOffer;
