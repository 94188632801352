import { SchemaOf } from 'yup';
import { OperationValues } from './values';
import { paymentMutationValidationSchema } from '../../../PaymentForm/paymentMutationValidationSchema';
import { MeasurementsSectionValidationSchema } from '../../../../measurement/MeasurementsSection/validationSchema';
import { OperationUnit, OfferMode } from '../../../../../../globalTypes';
import yup from '../../../../../../yup';

export const validationSchema = (offerMode?: OfferMode): SchemaOf<OperationValues> => {
  const alias = yup
    .string()
    .max(4)
    .min(4)
    .test('is-int', 'validation.required', (v) => {
      if (!v) {
        return true;
      }

      return Number(v) % 1 === 0;
    });

  const aliasValidation = offerMode === OfferMode.SYSTEM_NUMBER ? alias : alias.required();

  return yup.object({
    alias: aliasValidation,
    name: yup.string().max(255).required(),
    description: yup.string(),
    price: yup.number().required().typeError('validation.required'),
    catalog: yup.string(),
    note: yup.string(),
    category: yup
      .string()
      .when('catalog', { is: (catalog: string) => !!catalog, then: yup.string().required() }),
    optional: yup.boolean(),
    payment: paymentMutationValidationSchema().optional(),
    measurements: MeasurementsSectionValidationSchema(false).defined().default([]),
    unit: yup.mixed<OperationUnit>().oneOf(Object.values(OperationUnit)).required(),
    approximate: yup.boolean().required(),
  });
};

export const defaultValues = (initialValues?: Partial<OperationValues>): OperationValues => {
  return {
    catalog: '',
    category: '',
    description: '',
    name: '',
    note: '',
    optional: false,
    price: '' as never,
    measurements: [],
    approximate: true,
    ...initialValues,
  };
};
