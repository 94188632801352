import { ApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadManuallySignedQsstMutation from './graphql/UploadManuallySignedQsst.mutation';
import { qsstRoutes } from '../qsstRoutes';
import { QsstUploadManuallySignedDocumentFormValues } from './QsstUploadManuallySignedDocumentForm';

interface UseQsstManualSignResponse {
  sign: (values: QsstUploadManuallySignedDocumentFormValues) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useQsstManualSign(qsstId: string): UseQsstManualSignResponse {
  const navigate = useNavigate();
  const [uploadQsst, { loading, error }] = useMutation(UploadManuallySignedQsstMutation);

  const sign = useCallback(
    async ({ file }: QsstUploadManuallySignedDocumentFormValues) => {
      if (!file) return;

      await uploadQsst({
        variables: {
          input: {
            id: qsstId,
            pdf: file,
          },
        },
      });

      navigate(qsstRoutes.detail.generatePath({ qsstId }));
    },
    [qsstId, uploadQsst, navigate],
  );

  return { sign, loading, error };
}
