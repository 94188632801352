import { formatISOStringToDateString } from '../../../utils/date';
import { ObstructionFormValues } from '../../Obstruction/forms/ObstructionForm/ObstructionForm.form';
import { CreateObstructionProjectDetail } from './graphql/__generated__/CreateObstructionProjectDetail.query';

export function mapObstructionProjectToFormValues(
  project: CreateObstructionProjectDetail['project'],
): Partial<ObstructionFormValues> {
  return {
    date: project.date ? formatISOStringToDateString(project.date) : '',
    clientName: project.client?.name || '',
    projectNumber: project.hsProjectNumber || '-',
  };
}
