import cx from 'classnames';

import Control, { SharedProps as ControlProps } from '../Control';

export type InputProps = Omit<JSX.IntrinsicElements['input'], 'type'> &
  ControlProps & {
    type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
    innerRef?: InputProps['ref'];
  };

function Input({
  status,
  rightIconName,
  onRightIconClick,
  label,
  type = 'text',
  onFocus,
  onBlur,
  className,
  innerClassName,
  innerRef,
  dataTestId,
  ...otherProps
}: InputProps): JSX.Element {
  return (
    <Control
      status={status}
      rightIconName={rightIconName}
      onRightIconClick={onRightIconClick}
      label={label}
      isFilled={!!otherProps.placeholder || !!otherProps.value || !!otherProps.defaultValue}
      className={className}
    >
      {({ onElementFocus, onElementBlur, isElementDisabled, elementClassName }) => (
        <input
          ref={innerRef}
          className={cx(elementClassName, innerClassName)}
          disabled={isElementDisabled}
          type={type}
          data-testid={dataTestId}
          onFocus={(e) => {
            onElementFocus(e);
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={(e) => {
            onElementBlur(e);
            if (onBlur) {
              onBlur(e);
            }
          }}
          autoComplete="off"
          {...otherProps}
        />
      )}
    </Control>
  );
}

export default Input;
