export interface ConnectorConnection {
  id: string;
  parentID?: string;
}

export type Connector<T> = (data: T) => ConnectorConnection;

export interface TreeStorageOptions<T> {
  data: T[];
  connector: Connector<T>;
}

interface GraphStorage<T> {
  getNode(id: string): T;
  getRoots(): T[];
  getConnections(id: string): T[];
}

class TreeStorage<T> implements GraphStorage<T> {
  private nodes = new Map<string, T>();

  private connections = new Map<string, string[]>();

  private roots = new Set<string>();

  private connector: Connector<T>;

  public constructor({ connector, data }: TreeStorageOptions<T>) {
    this.connector = connector;

    this.load(data);
  }

  private load(datas: T[]) {
    datas.forEach((data) => {
      const { id, parentID } = this.connector(data);

      this.nodes.set(id, data);
      if (parentID) {
        this.addToNodeMap(id, parentID);
      } else {
        this.roots.add(id);
      }
    });
  }

  public getNode = (id: string): T => {
    const node = this.nodes.get(id);

    if (!node) {
      throw new Error('Node does not exists');
    }

    return node;
  };

  public getRoots = (): T[] => {
    return Array.from(this.roots).map(this.getNode);
  };

  public getConnections = (id: string): T[] => {
    return this.getChildIds(id).map(this.getNode);
  };

  private getChildIds = (id: string) => this.connections.get(id) || [];

  private addToNodeMap = (id: string, parentID: string) => {
    const childrens = this.connections.get(parentID) || [];
    this.connections.set(parentID, [...childrens, id]);
  };
}

export default TreeStorage;
