import { gql } from '@apollo/client';

import { AVAILABLE_CATEGORIES_FRAGMENT } from '../../containers/categoryMapper/graphql';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_FOLDER_FRAMEWORK = gql`
  mutation CreateFolderForFramework($input: CreateFolderForFrameworkInput!) {
    createFolderForFramework(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const FOLDER_CREATE_DATA = gql`
  query FolderCreateData($offerId: ID!, $parentId: ID) {
    listAvailableCategoriesForFolderCreation(offer: $offerId, parent: $parentId) {
      ...AvaiableCategoriesFragment
    }
  }

  ${AVAILABLE_CATEGORIES_FRAGMENT}
`;
