import { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import AcceptanceReportSignDetailQuery from '../../graphql/AcceptanceReportSignDetail.query';
import { AcceptanceReportSignDetail } from '../../graphql/__generated__/AcceptanceReportSignDetail.query';
import {
  AcceptanceReportAttendeeRole,
  AcceptanceReportSignatureType,
} from '../../../../../globalTypes';
import urlToBase64 from '../../../../../utils/urlToBase64';

interface UseAcceptanceReportSignDetailResponse {
  employeeNames: string[];
  clientAttendee?: AcceptanceReportSignDetail['acceptanceReport']['attendees'][number];
  employeeSignature?: AcceptanceReportSignDetail['acceptanceReport']['signatures'][number];
  customerSignature?: AcceptanceReportSignDetail['acceptanceReport']['signatures'][number];
  viewerSignatureBase64?: string;
  loading: boolean;
  error?: ApolloError;
}

export default function useAcceptanceReportSignDetail(
  acceptanceReportId: string,
): UseAcceptanceReportSignDetailResponse {
  const { data, loading, error } = useQuery(AcceptanceReportSignDetailQuery, {
    variables: {
      acceptanceReportId,
    },
  });
  const employeeNames = data
    ? (data.acceptanceReport.project.contactPeople
        .map((contactPerson) => contactPerson.name)
        .filter((name) => !!name) as string[])
    : [];

  const clientAttendee = data
    ? data.acceptanceReport.attendees.find(
        (attendee) => attendee.role === AcceptanceReportAttendeeRole.CLIENT,
      )
    : undefined;

  const employeeSignature = data
    ? data.acceptanceReport.signatures.find(
        (signature) => signature.type === AcceptanceReportSignatureType.HEINRICH_SCHMID,
      )
    : undefined;

  const customerSignature = data
    ? data.acceptanceReport.signatures.find(
        (signature) => signature.type === AcceptanceReportSignatureType.CUSTOMER,
      )
    : undefined;

  const viewerSignatureUrl = data?.viewer.signature ?? undefined;
  const [viewerSignatureBase64, setViewerSignatureBase64] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (viewerSignatureUrl) {
      urlToBase64(viewerSignatureUrl).then((result) => setViewerSignatureBase64(result));
    }
  }, [viewerSignatureUrl]);

  return {
    employeeNames,
    clientAttendee,
    employeeSignature,
    customerSignature,
    viewerSignatureBase64,
    loading,
    error,
  };
}
