import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { SAPTransmissionProject } from '../graphql/__generated__/SAPTransmissionProject.fragment';
import SAPTransmitTableRow from './components/SAPTransmitTableRow';

export interface SAPTransmitTableProps {
  projects: SAPTransmissionProject[];
  selectedProjectIds: string[];
  onProjectCheckboxChange: (project: SAPTransmissionProject) => void;
}

export default function SAPTransmitTable({
  projects,
  selectedProjectIds,
  onProjectCheckboxChange,
}: SAPTransmitTableProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Paper stylex={{ padding: 'small-x' }}>
      <Box stylex={{ overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('transmit.table.projectNumber')}
              </TableCell>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('transmit.table.name')}
              </TableCell>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('transmit.table.siv')}
              </TableCell>
              <TableCell component="th" />
            </TableRow>
          </TableHead>

          <TableBody>
            {projects.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  disablePadding
                  stylex={{ textAlign: 'center', paddingEnds: 'large', paddingSides: 'small-2x' }}
                >
                  <Text variant="body-large" color="gray-500">
                    {t('dataTable.emptyText')}
                  </Text>
                </TableCell>
              </TableRow>
            )}

            {projects.length !== 0 &&
              projects.map((project) => (
                <SAPTransmitTableRow
                  key={project.id}
                  project={project}
                  selected={selectedProjectIds.includes(project.id)}
                  onCheckboxChange={() => onProjectCheckboxChange(project)}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
