import { TypedDocumentNode, gql } from '@apollo/client';
import { OfferLog } from './__generated__/OfferLog.fragment';

const OfferLogFragment: TypedDocumentNode<OfferLog> = gql`
  fragment OfferLog on OfferLog {
    ... on OfferCreatedLog {
      id
      name
      date
      offerId

      actorId
      actorFirstName
      actorLastName
    }

    ... on OfferDeletedLog {
      id
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on OfferEmailSentLog {
      id
      name
      date
      emails
      actorId
      actorFirstName
      actorLastName
      offerId
    }

    ... on OfferExportedToBotLog {
      id
      name
      date
      offerId

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default OfferLogFragment;
