import { ApolloError, useMutation } from '@apollo/client';
import ProjectSyncMutation from './graphql/ProjectSync.mutation';

interface SyncProps {
  projectId: string;
  projectOrdered: boolean;
  onProjectSync: () => void;
}

interface ProjectSyncResponse {
  error?: ApolloError;
  loading: boolean;
  projectSync: () => Promise<void>;
}

function useSync({
  projectId,
  projectOrdered: projectOrderedBefore,
  onProjectSync,
}: SyncProps): ProjectSyncResponse {
  const [syncProject, { loading, error }] = useMutation(ProjectSyncMutation, {
    refetchQueries: ({ data }) => {
      const becomeOrdered = !projectOrderedBefore && !!data?.syncProject.ordered;
      if (becomeOrdered) {
        return ['DriveFolderEntries'];
      }

      return [];
    },
    awaitRefetchQueries: true,
  });

  async function projectSync() {
    if (loading) {
      return;
    }

    await syncProject({
      variables: {
        input: {
          id: projectId,
        },
      },
    });

    onProjectSync();
  }

  return {
    error,
    projectSync,
    loading,
  };
}

export default useSync;
