import { gql, TypedDocumentNode } from '@apollo/client';
import { SAPTransmissionProject } from './__generated__/SAPTransmissionProject.fragment';

const SAPTransmissionProjectFragment: TypedDocumentNode<SAPTransmissionProject> = gql`
  fragment SAPTransmissionProject on SapTransmissionProject {
    id

    projectNumber
    name
    isSynced

    sivKeyData {
      planningTotalHour
      totalHourForecast
      recordingTotalCostByMeasurement
    }
  }
`;

export default SAPTransmissionProjectFragment;
