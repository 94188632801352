import { ApolloError } from '@apollo/client';

import { useNavigate } from 'react-router-dom';
import { EditNameFormValues } from './OfferEditForm/values';
import { GET_OFFER_DATA } from '../../Detail/graphql';
import { offerRoutes } from '../../offerRoutes';
import useOfferFreeformSave from '../../../Project/CreateNewOffer/useOfferFreeformSave';
import useOfferFrameworkSave from '../../../Project/CreateNewOffer/useOfferFrameworkSave';
import { OfferType } from '../../../../globalTypes';

interface OfferEditProps {
  offerId: string;
  offerType: OfferType;
  catalogueId?: string;
}

interface UseOfferEditParams {
  error?: ApolloError;
  edit: (values: EditNameFormValues) => Promise<void>;
}

const useOfferEdit = ({ offerId, offerType, catalogueId }: OfferEditProps): UseOfferEditParams => {
  const navigate = useNavigate();
  const [offerFreeform, { loading: freeformLoading, error: freeformError }] =
    useOfferFreeformSave();
  const [offerFramework, { loading: frameworkLoading, error: frameworkError }] =
    useOfferFrameworkSave();

  const loading = freeformLoading || frameworkLoading;
  const error = freeformError || frameworkError;

  const edit = async (values: EditNameFormValues) => {
    if (loading) {
      return;
    }

    if (offerType === OfferType.FRAMEWORK && catalogueId) {
      await offerFramework({
        variables: {
          input: {
            meta: {
              draftClient: values.client,
              draftConstructionSite: values.constructionSite,
            },
            name: values.name,
            documentId: offerId,
            catalogueId,
            mode: values.mode,
          },
        },
        refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
      });
    } else {
      await offerFreeform({
        variables: {
          input: {
            meta: {
              draftClient: values.client,
              draftConstructionSite: values.constructionSite,
            },
            name: values.name,
            documentId: offerId,
            mode: values.mode,
          },
        },
        refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
      });
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  };

  return {
    error,
    edit,
  };
};

export default useOfferEdit;
