import { Breadcrumb, BreadcrumbItem, Skeleton } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SidebarLayout from '../../../components/layouts/SidebarLayout';
import routes from '../../routes';

export default function DocumentDetailLoadingLayout(): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <div className="container u-padding-ends">
      <Breadcrumb className="qa-overtime-detail-breadcrumb">
        <BreadcrumbItem component={NavLink} to={routes.projects.generatePath({})}>
          {t('breadcrumb.project')}
        </BreadcrumbItem>
      </Breadcrumb>
      <AppHeader
        title={
          <div className="qa-overtime-detail-title u-width-50%">
            <Skeleton width="20%" height={20} radius={8} />
            <Skeleton width="15%" height={20} radius={8} className="u-margin-top-xsmall" />
          </div>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
      <div className="row u-margin-top-large">
        <div className="col col--md-8">
          <Skeleton width="70%" height={20} radius={8} />
          <Skeleton width="80%" height={20} radius={8} className="u-margin-top-xsmall" />
          <Skeleton width="60%" height={20} radius={8} className="u-margin-top-xsmall" />
          <Skeleton width="90%" height={20} radius={8} className="u-margin-top-xsmall" />
        </div>
        <div className="col col--md-4">
          <SidebarLayout>
            <Skeleton width="100%" height={21} radius={8} />
            <Skeleton width="100%" height={40} radius={8} className="u-margin-top-xsmall" />
            <Skeleton width="100%" height={40} radius={8} className="u-margin-top-xsmall" />
            <Skeleton width="100%" height={40} radius={8} className="u-margin-top-xsmall" />
            <div className="u-margin-top-auto">
              <Skeleton width="100%" height={40} radius={8} />
              <Skeleton width="100%" height={40} radius={8} className="u-margin-top-xsmall" />
              <Skeleton width="100%" height={40} radius={8} className="u-margin-top-xsmall" />
            </div>
          </SidebarLayout>
        </div>
      </div>
    </div>
  );
}
