import { Breadcrumb, BreadcrumbItem, Text } from '@hs-baumappe/legacy-ui';
import { NavLink, useParams } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import routes, { projectRoutes } from '../../../../routes';

import { ProjectDetailRouteParams } from '../../ProjectDetail.route.types';

interface ProjectDetailHeaderProps {
  draft?: boolean;
  activeFolderId?: string;
}

export default function ProjectDetailHeader({
  draft,
  activeFolderId,
}: PropsWithChildren<ProjectDetailHeaderProps>): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { projectId } = useParams<ProjectDetailRouteParams>();

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem component={NavLink} to={routes.projects.generatePath({})}>
          {t('breadcrumb.project')}
        </BreadcrumbItem>
        {draft && activeFolderId && (
          <BreadcrumbItem
            component={NavLink}
            to={projectRoutes.driveWithFolder.generatePath({
              projectId,
              folderId: activeFolderId,
            })}
          >
            {t('projectDetail.draft.title')}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <AppHeader
        title={
          <Text component="h1" variant="body-large" color="muted" className="u-margin-bottom-0">
            {t(draft ? 'projectDetail.draft.title' : 'projectDetail.title')}
          </Text>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
