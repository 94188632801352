import { SchemaOf } from 'yup';
import yup, { InferObjectToYupShape } from '../../../../yup';
import { ConcernFormValues, Misgivings, Quality } from './ConcernForm.types';
import { imageUploadWithLabelValidationSchema } from '../../../../containers/forms/ImageUploadWithLabel';
import i18n from '../../../../i18n';

const qualityValidationSchema: SchemaOf<Quality[]> = yup
  .array<Quality>()
  .default([])
  .of(
    yup
      .object({
        id: yup.string().required(),
        name: yup.string().required(),
        description: yup.string().required(),
      })
      .required(),
  );

export const concernFormValidationSchema = yup
  .object()
  .shape<InferObjectToYupShape<ConcernFormValues>>({
    projectNumber: yup.string(),
    clientName: yup.string().required(),
    date: yup.date(),
    professions: yup.array().defined().min(1).default([]).of(yup.string().required()),
    misgivings: yup.array().required().default([]).min(1),
    endDate: yup.date().typeError(i18n.t('validation.date.typeError')).required(),
    images: yup.array().of(imageUploadWithLabelValidationSchema.defined()).defined(),
    execution: yup.string().when(['misgivings'], {
      is: (misgivings: Misgivings[]) => misgivings.includes(Misgivings.EXECUTION),
      then: yup.string().required(),
    }),
    qualities: qualityValidationSchema.when(['misgivings'], {
      is: (misgivings: Misgivings[]) => misgivings.includes(Misgivings.QUALITY),
      then: qualityValidationSchema.min(1),
    }),
    contractorsName: yup.string().when(['misgivings'], {
      is: (misgivings: Misgivings[]) => misgivings.includes(Misgivings.CONTRACTORS),
      then: yup.string().required(),
    }),
    contractorsDescription: yup.string().when(['misgivings'], {
      is: (misgivings: Misgivings[]) => misgivings.includes(Misgivings.CONTRACTORS),
      then: yup.string().required(),
    }),
  });
