import { gql, TypedDocumentNode } from '@apollo/client';
import LogFragment from '../../../containers/LogContainer/graphql/Log.fragment';
import {
  RiskAssessmentLogs,
  RiskAssessmentLogsVariables,
} from './__generated__/RiskAssessmentLogs.query';

const RiskAssessmentLogsQuery: TypedDocumentNode<RiskAssessmentLogs, RiskAssessmentLogsVariables> =
  gql`
    query RiskAssessmentLogs($riskAssessmentId: ID!, $page: Int, $limit: Int) {
      riskAssessment(id: $riskAssessmentId) {
        id

        logs(page: $page, limit: $limit) {
          ...LogFragment
        }
      }
    }

    ${LogFragment}
  `;

export default RiskAssessmentLogsQuery;
