import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import LogActor from '../LogActor';
import { assertUnknownLog, getActor } from '../Log.utils';
import { ObstructionLog as ObstructionLogType } from '../../graphql/__generated__/ObstructionLog.fragment';
import LogEmails from '../LogEmails';

interface ObstructionLogProps extends ComponentProps<typeof Text> {
  log: ObstructionLogType;
}

export default function ObstructionLog({ log, ...props }: ObstructionLogProps): JSX.Element {
  switch (log.__typename) {
    case 'ObstructionArchivedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.obstructionArchived"
            values={{ obstructionName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ObstructionCreatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.obstructionCreated"
            values={{ obstructionName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ObstructionDeletedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.obstructionDeleted"
            values={{ obstructionName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ObstructionEmailSentLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.obstructionEmailSent"
            values={{ obstructionName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'ObstructionSignedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.obstructionSigned"
            values={{ obstructionName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'ObstructionUpdatedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.obstructionUpdated"
            values={{ obstructionName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
