import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { Link, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { ObstructionScopes } from '../../../Obstruction.types';
import { Obstruction } from '../../../graphql/__generated__/Obstruction.fragment';
import { createDocumentAssignPath } from '../../ObstructionDetail.utils';
import obstructionRoutes from '../../../obstructionRoutes';
import { createObstructionLocation } from '../../../Obstruction.utils';
import { DocumentType } from '../../../../../globalTypes';
import RemoveDocument from '../../../../Project/document/RemoveDocument';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';

interface ObstructionDetailSidebarBottomProps {
  obstruction: Obstruction;
  scopes: ObstructionScopes;
  projectId: string;
  onRemoveSuccess: () => void;
}

export default function ObstructionDetailSidebarBottom({
  obstruction,
  scopes,
  projectId,
  onRemoveSuccess,
}: ObstructionDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();
  const location = useLocation();

  const signPermitted = scopes.signObstructionDocument.isPermitted;
  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;

  return (
    <Stack gap="small-3x">
      {obstruction.viewerCanAssign && (
        <Button
          component={Link}
          to={createDocumentAssignPath(obstruction, projectId)}
          endIcon={<Icon name="folder" />}
        >
          {t('obstruction.assignButton')}
        </Button>
      )}

      {obstruction.viewerCanSign && signPermitted && (
        <Button
          component={Link}
          to={obstructionRoutes.sign.generatePath({ obstructionId: obstruction.id })}
          state={createObstructionLocation(location)}
          color="primary"
          endIcon={<Icon name="border-color" />}
        >
          {t('obstruction.signButton')}
        </Button>
      )}

      {obstruction.viewerCanArchive && archiveEnabled && (
        <Button
          component={Link}
          to={obstructionRoutes.archive.generatePath({ obstructionId: obstruction.id })}
          state={createObstructionLocation(location)}
          color="primary"
          endIcon={<Icon name="archive" />}
        >
          {t('obstruction.archiveButton')}
        </Button>
      )}

      {obstruction.viewerCanRemove && removePermitted && (
        <RemoveDocument
          document={{
            name: obstruction.name,
            id: obstruction.id,
            type: DocumentType.OBSTRUCTION,
          }}
          onRemoveSuccess={onRemoveSuccess}
          renderTrigger={({ onClick, loading }) => (
            <Button
              variant="text"
              color="error"
              className="u-margin-top-xsmall"
              onClick={onClick}
              loading={loading}
            >
              {t('obstruction.removeButton')}
            </Button>
          )}
        />
      )}
    </Stack>
  );
}
