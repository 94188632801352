import { gql, TypedDocumentNode } from '@apollo/client';
import { documentDownloadFragments } from '../components/DocumentDownload/DocumentDownload';
import { qsstSignFormFragments } from '../components/QsstSignForm/QsstSignForm';
import { QsstSignDetail, QsstSignDetailVariables } from './__generated__/QsstSignDetail.query';

const QsstSignDetailQuery: TypedDocumentNode<QsstSignDetail, QsstSignDetailVariables> = gql`
  query QsstSignDetail($input: QsstForExternalInput!) {
    qsstForExternal(input: $input) {
      id

      projectLogo

      signedAt

      ...QsstSignFormQsstForExternalFragment
      ...DocumentDownloadQsstForExternalFragment
    }
  }

  ${qsstSignFormFragments.qsstForExternal}
  ${documentDownloadFragments.qsstForExternal}
`;

export default QsstSignDetailQuery;
