import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import RiskAssessmentSendEmailMutation from '../../graphql/RiskAssessmentSendEmail.mutation';
import RiskAssessmentDetailQuery from '../../../graphql/RiskAssessmentDetail.query';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import { SendRiskAssessmentEmailInput } from '../../../../../globalTypes';
import { riskAssessmentRoutes } from '../../../riskAssessmentRoutes';
import {
  RiskAssessmentDetailAlert,
  RiskAssessmentDetailSearchParams,
} from '../../../RiskAssessmentDetail/RiskAssessmentDetail.utils';
import { EmailFormValues } from '../../../../../containers/Email';

interface UseRiskAssessmentSendEmailResponse {
  sendEmail: (values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentSendEmail(
  riskAssessmentId: string,
): UseRiskAssessmentSendEmailResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutate, { loading }] = useMutation(RiskAssessmentSendEmailMutation, {
    refetchQueries: [RiskAssessmentDetailQuery],
  });

  async function sendEmail(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    if (loading) {
      return;
    }

    try {
      await mutate({
        variables: {
          input: {
            id: riskAssessmentId,
            recipients: values.toEmails,
            subject: values.subject,
            content: values.content,
            cc: values.ccEmails,
          },
        },
      });

      const searchParams = new URLSearchParams({
        [RiskAssessmentDetailSearchParams.ALERT]: RiskAssessmentDetailAlert.SEND,
      });

      navigate(
        createPath({
          pathname: riskAssessmentRoutes.detail.generatePath({ riskAssessmentId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        SendRiskAssessmentEmailInput
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.recipients,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendEmail,
    loading,
    error,
  };
}
