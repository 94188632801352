import { gql, TypedDocumentNode } from '@apollo/client';
import { Equipment } from './__generated__/Equipment.fragment';

const EquipmentFragment: TypedDocumentNode<Equipment> = gql`
  fragment Equipment on Equipment {
    id

    title
    icon
    sort
    required
    preselected
  }
`;

export default EquipmentFragment;
