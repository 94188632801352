import { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import ObstructionSignDetailQuery from '../../graphql/ObstructionSignDetail.query';
import urlToBase64 from '../../../../../utils/urlToBase64';

interface UseObstructionSignDetailResponse {
  viewerSignatureBase64?: string;
  loading: boolean;
  error?: ApolloError;
}

export default function useObstructionSignDetail(): UseObstructionSignDetailResponse {
  const { data, loading, error } = useQuery(ObstructionSignDetailQuery);

  const viewerSignatureUrl = data?.viewer.signature ?? undefined;
  const [viewerSignatureBase64, setViewerSignatureBase64] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (viewerSignatureUrl) {
      urlToBase64(viewerSignatureUrl).then((result) => setViewerSignatureBase64(result));
    }
  }, [viewerSignatureUrl]);

  return {
    viewerSignatureBase64,
    loading,
    error,
  };
}
