import {
  OfferFolderFragment,
  OfferMeasurementFragment,
  OfferFolderOperationPaymentFragment,
  OfferFolderOperationFragment,
} from './__generated__/graphql';

import { Folder, FolderOperation, FolderOperationPayment, Measurement } from './types';

import TreeStorage from '../../../utils/TreeStorage';

const measurementMapper = (m?: OfferMeasurementFragment[]): Measurement[] | undefined => {
  if (!m) {
    return undefined;
  }

  return m.map((fm) => {
    return {
      id: fm.id,
      multiplier: fm.multiplier,
      name: fm.name,
      subtraction: fm.subtraction,
      total: fm.total,
      totalMeta: fm.totalMeta,
      meta: fm.meta,
    };
  });
};

const folderSorter = (a: OfferFolderFragment, b: OfferFolderFragment) => {
  return a.sort - b.sort;
};

const operationPaymentMapper = (
  payment?: OfferFolderOperationPaymentFragment | null,
): FolderOperationPayment | undefined => {
  if (!payment) {
    return undefined;
  }

  return {
    flat: payment.flat,
    discountOrSurchargeValue: payment.discountOrSurchargeValue || undefined,
    discountOrSurchargeValueType: payment.discountOrSurchargeValueType || undefined,
    totalPrice: payment.totalPrice || undefined,
  };
};

const operationMapper =
  (partNoTree: string[]) =>
  (op: OfferFolderOperationFragment): FolderOperation => {
    const measurements = measurementMapper(op.measurements);
    const payment = operationPaymentMapper(op.payment);

    return {
      id: op.id,
      name: op.name,
      totalMeasurement: op.totalMeasurement,
      unit: op.unit || undefined,
      description: op.description || undefined,
      partNo: op.partNo,
      partNoAlias: op.partNoAlias || undefined,
      partNoComputed: op.partNoComputed,
      partNoTree: [...partNoTree, op.partNoComputed],
      price: op.price,
      completed: op.completed,
      approximate: op.approximate,
      note: op.note || undefined,
      optional: op.optional,
      measurements,
      payment,
      viewerCanClone: op.viewerCanClone,
      viewerCanUpdate: op.viewerCanUpdate,
      viewerCanRemove: op.viewerCanRemove,
    };
  };

const folderMapper =
  (childrenGetter: (id: string) => OfferFolderFragment[], partNoTuple: string[] = []) =>
  (fields: OfferFolderFragment): Folder => {
    const partNoTree = [...partNoTuple, fields.partNoComputed];
    return {
      viewerCanCloneFolder: fields.viewerCanClone,
      viewerCanRemoveFolder: fields.viewerCanRemove,
      viewerCanUpdateFolder: fields.viewerCanUpdate,
      id: fields.id,
      name: fields.name,
      approximate: fields.approximate,
      viewerCanAddSubfolder: fields.viewerCanAddSubfolder,
      viewerCanAddOperation: fields.viewerCanAddOperation,
      viewerCanGivePartNoAlias: fields.viewerCanGivePartNoAlias,
      partNo: fields.partNo,
      partNoAlias: fields.partNoAlias || undefined,
      partNoComputed: fields.partNoComputed,
      partNoTree,
      totalPrice: fields.totalPrice,
      note: fields.note || undefined,
      totalMeasurement: fields.totalMeasurement,
      unit: fields.unit || undefined,
      measurements: measurementMapper(fields.measurements),
      category: fields.category || undefined,
      subFolders: childrenGetter(fields.id)
        .sort(folderSorter)
        .map(folderMapper(childrenGetter, partNoTree)),
      operations: fields.operations.map(operationMapper(partNoTree)),
      parentID: fields.parent ? fields.parent.id : undefined,
    };
  };

// eslint-disable-next-line import/prefer-default-export
export const folderBuilder = (fields: OfferFolderFragment[]): Folder[] => {
  const FolderTree = new TreeStorage({
    data: fields,
    connector: (f) => ({
      id: f.id,
      parentID: f.parent ? f.parent.id : undefined,
    }),
  });

  return FolderTree.getRoots().sort(folderSorter).map(folderMapper(FolderTree.getConnections));
};
