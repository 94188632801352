import { FocusEvent, KeyboardEvent, MouseEvent, ReactNode, useState } from 'react';
import cx from 'classnames';
import { Icon, IconProps } from '@hs-baumappe/legacy-ui';

import { FieldStatus } from '../fieldStatus';

import './c-form-control.scss';

type ControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export interface ControlElementProps {
  elementClassName: string;
  onElementFocus: (e: FocusEvent<ControlElement>) => void;
  onElementBlur: (e: FocusEvent<ControlElement>) => void;
  isElementDisabled: boolean;
}

export interface SharedProps {
  status?: FieldStatus;
  rightIconName?: IconProps['name'];
  onRightIconClick?: (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void;
  label?: string;
  isFilled?: boolean;
  className?: string;
  innerClassName?: string;
  dataTestId?: string;
}

type Props = SharedProps & {
  children: (props: ControlElementProps) => ReactNode;
};

function getRightIconNameByStatus(status: FieldStatus): IconProps['name'] | undefined {
  switch (status) {
    case 'invalid':
      return 'warning';
    default:
      return undefined;
  }
}

const KEY_CODES = {
  ENTER: 13,
  SPACE: 32,
};

const Control = ({
  status = 'idle',
  rightIconName,
  onRightIconClick,
  children,
  isFilled,
  className,
}: Props): JSX.Element => {
  const [isFocused, setFocusActivity] = useState(false);

  const rightIconNameByStatus = getRightIconNameByStatus(status);

  const rightIconToDisplay = rightIconName || rightIconNameByStatus;

  const isRightIconClickable = rightIconName && onRightIconClick;

  const handleOnFocus = () => {
    setFocusActivity(true);
  };

  const handleOnBlur = () => {
    setFocusActivity(false);
  };

  const handleRightIconKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if ((e.which === KEY_CODES.ENTER || e.which === KEY_CODES.SPACE) && onRightIconClick) {
      e.preventDefault();
      onRightIconClick(e);
    }
  };

  return (
    <div
      className={cx(
        'c-form-control',
        { [`is-${status}`]: status !== 'idle' },
        { 'is-focused': isFocused },
        { 'is-filled': isFilled },
        { 'is-disabled': status === 'disabled' },
        { 'has-right-icon': typeof rightIconToDisplay === 'string' },
        className,
      )}
    >
      {children({
        elementClassName: 'c-form-control__control',
        onElementFocus: handleOnFocus,
        onElementBlur: handleOnBlur,
        isElementDisabled: status === 'disabled',
      })}
      {rightIconToDisplay && (
        <div
          data-testid="right-icon"
          className={cx('c-form-control__right-icon', {
            'u-pointer-events-none': !isRightIconClickable,
          })}
          {...(isRightIconClickable
            ? {
                onClick: onRightIconClick,
                role: 'button',
                tabIndex: 0,
                onKeyPress: handleRightIconKeyPress,
              }
            : null)}
        >
          <Icon className="u-display-block" name={rightIconToDisplay} />
        </div>
      )}
    </div>
  );
};

export default Control;
