import { FC } from 'react';
import { createPath, Link, useNavigate } from 'react-router-dom';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { qsstRoutes } from '../../../qsstRoutes';
import { QsstSendMailMethod } from '../../../QsstSendMail/QsstSendMail';

interface SignedOnSideProps {
  qsstId: string;
}

const SignedOnSide: FC<SignedOnSideProps> = ({ qsstId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleSendEmailButtonClick() {
    const searchParams = new URLSearchParams({
      method: QsstSendMailMethod.ATTACHMENT,
    });

    navigate(
      createPath({
        pathname: qsstRoutes.sendEmail.generatePath({ qsstId }),
        search: searchParams.toString(),
      }),
    );
  }

  return (
    <div className="u-margin-top-xlarge">
      <div className="u-text-align-center">
        <Text className="u-margin-bottom" component="h2" variant="title-large" color="black">
          {t('qsstSign.signedOnSide.title')}
        </Text>
        <Text component="p" variant="body-medium" color="black">
          {t('qsstSign.signedOnSide.content1')}
        </Text>
      </div>
      <div className="row u-justify-content-center u-margin-top-large">
        <div className="col col--sm-8 col--lg-7">
          <Button
            color="primary"
            endIcon={<Icon name="email" />}
            fullWidth
            onClick={handleSendEmailButtonClick}
          >
            {t('qsstSign.signedOnSide.shipPdfButton')}
          </Button>

          {/* document details button */}
          <Button
            component={Link}
            endIcon={<Icon name="forward" />}
            fullWidth
            to={qsstRoutes.detail.generatePath({ qsstId })}
            className="u-margin-top-small"
          >
            {t('qsstSign.signedOnSide.documentDetailLink')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SignedOnSide };
