import { gql, TypedDocumentNode } from '@apollo/client';
import QsstFragment from '../../../graphql/Qsst.fragment';
import QsstProjectFragment from '../../../graphql/QsstProject.fragment';
import { QsstDetail, QsstDetailVariables } from './__generated__/QsstDetail.query';

const QsstDetailQuery: TypedDocumentNode<QsstDetail, QsstDetailVariables> = gql`
  query QsstDetail($qsstId: ID!) {
    qsst(id: $qsstId) {
      ...Qsst

      project {
        ...QsstProject
      }
    }
  }

  ${QsstFragment}
  ${QsstProjectFragment}
`;

export default QsstDetailQuery;
