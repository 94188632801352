import { FC } from 'react';
import cx from 'classnames';

import './c-table.scss';

type Props = JSX.IntrinsicElements['table'] & {
  stripe?: boolean;
};

const Table: FC<Props> = ({ className, stripe, children, ...otherProps }) => (
  <table className={cx('c-table', { 'c-table--stripe': stripe }, className)} {...otherProps}>
    {children}
  </table>
);

export default Table;
