import { FC } from 'react';
import { RadioRow } from './RadioRow/RadioRow';
import { SmileyRow } from './SmileyRow/SmileyRow';
import styles from './specificScore.module.scss';

const SpecificScore: FC = () => {
  return (
    <div className={styles.grid}>
      <SmileyRow />

      <RadioRow gridRow={2} scoreKey={'quality'} />
      <RadioRow gridRow={3} scoreKey={'cleanliness'} />
      <RadioRow gridRow={4} scoreKey={'service'} />
      <RadioRow gridRow={5} scoreKey={'timeliness'} />
    </div>
  );
};

export { SpecificScore };
