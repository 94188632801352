import { gql } from '@apollo/client';
import GetProjectDetailFragment from './GetProjectDetailFragment';

export default gql`
  query GetProjectDetail($projectId: ID!) {
    project(id: $projectId) {
      ...GetProjectDetailFragment

      siv {
        id

        isCollapsed
      }
    }
  }

  ${GetProjectDetailFragment}
`;
