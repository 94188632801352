import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EDIT_TAX_AMOUNT = gql`
  mutation UpdateTaxRate($input: UpdateTaxRateInput!) {
    updateTaxRate(input: $input) {
      id
    }
  }
`;
