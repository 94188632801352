import { useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import useCanvas from './useCanvas';
import {
  calculateDimensions,
  compressImage,
  convertFileToBase64,
  fetchFile,
  scaleDimensions,
} from './useCanvasAddImage.utils';

type UseCanvasAddImageError = 'FileSizeError' | 'Error';

interface UseCanvasAddImage {
  addFromFile: (file: File) => Promise<void>;
  addFromURL: (url: string) => Promise<void>;
  loading: boolean;
  error?: UseCanvasAddImageError;
}

const MEGABYTES = 2 ** 20;
const MAX_FILE_SIZE = 10 * MEGABYTES;

export default function useCanvasAddImage(): UseCanvasAddImage {
  const { canvas } = useCanvas();
  const [currentOperationsCount, setCurrentOperationsCount] = useState(0);
  const [error, setError] = useState<UseCanvasAddImageError | undefined>(undefined);

  async function addImage(file: File) {
    if (!canvas) {
      return;
    }

    const dimensions = await calculateDimensions(file);
    const compressedImage = await compressImage(file);
    const base64Data = await convertFileToBase64(compressedImage);
    const scaledDimensions = scaleDimensions(dimensions, {
      width: canvas.width || 0,
      height: canvas.height || 0,
    });

    fabric.Image.fromURL(base64Data, (image) => {
      image.scaleToWidth(scaledDimensions.width, true);
      image.scaleToHeight(scaledDimensions.height, true);

      canvas.add(image);
      canvas.centerObject(image);
      canvas.setActiveObject(image);
      canvas.renderAll();
    });
  }

  async function addFromFile(file: File) {
    if (file.size > MAX_FILE_SIZE) {
      setError('FileSizeError');
      return;
    }

    setCurrentOperationsCount((prev) => prev + 1);
    setError(undefined);

    try {
      await addImage(file);
    } catch (e) {
      setError('Error');
    } finally {
      setCurrentOperationsCount((prev) => prev - 1);
    }
  }

  async function addFromURL(url: string) {
    setCurrentOperationsCount((prev) => prev + 1);
    setError(undefined);

    try {
      const file = await fetchFile(url);

      if (file.size > MAX_FILE_SIZE) {
        setError('FileSizeError');
        return;
      }

      await addImage(file);
    } catch (e) {
      setError('Error');
    } finally {
      setCurrentOperationsCount((prev) => prev - 1);
    }
  }

  return {
    addFromFile,
    addFromURL,
    loading: currentOperationsCount > 0,
    error,
  };
}
