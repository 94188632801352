import { format } from 'date-fns';
import { LogsWithDate } from './LogsWithDate';
import { Log } from './Log/Log.types';

export default function logsDateMapper(logs: Log[]): LogsWithDate[] {
  const result: LogsWithDate[] = [];
  logs.forEach((log) => {
    const date = format(new Date(log.date), 'dd.MM.yyyy');
    const dateItem = result.find((r) => r.date === date);
    if (dateItem) {
      dateItem.logs.push(log);
    } else {
      result.push({ date, logs: [log] });
    }
  });
  return result;
}
