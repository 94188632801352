import { useCallback } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { SEARCHED_PROJECT_QUERY } from './graphql';
import { SearchedProject, SearchedProjectVariables } from './__generated__/graphql';

interface UseProjectsResponse {
  data: SearchedProject | undefined;
  previousData: SearchedProject | undefined;
  loading: boolean;
  nextPageLoading: boolean;
  error?: ApolloError;
  refetch: (variables?: SearchedProjectVariables) => Promise<ApolloQueryResult<SearchedProject>>;
  fetchNextPage: () => Promise<void>;
  filterOutProject: (projectId: string) => void;
}

export default function useProjects(variables: SearchedProjectVariables): UseProjectsResponse {
  const { data, loading, error, previousData, refetch, fetchMore, updateQuery } = useQuery(
    SEARCHED_PROJECT_QUERY,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const nextPage = data ? data?.projects.pagination.current + 1 : undefined;
  const projects = data?.projects.projects ?? [];

  const fetchNextPage = useCallback(async () => {
    if (typeof nextPage === 'undefined') {
      return;
    }

    await fetchMore({
      variables: { page: nextPage },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          projects: {
            ...fetchMoreResult.projects,
            projects: [
              ...previousQueryResult.projects.projects,
              ...fetchMoreResult.projects.projects,
            ],
          },
        };
      },
    });
  }, [fetchMore, nextPage]);

  function filterOutProject(projectId: string) {
    if (!data) {
      return;
    }

    updateQuery((previousQueryResult) => ({
      ...previousQueryResult,
      projects: {
        ...previousQueryResult.projects,
        projects: previousQueryResult.projects.projects.filter(
          (project) => project.id !== projectId,
        ),
      },
    }));
  }

  return {
    data,
    previousData,
    loading,
    nextPageLoading: loading && projects.length > 0,
    refetch,
    error,
    fetchNextPage,
    filterOutProject,
  };
}
