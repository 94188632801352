import { getIn, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikConfig, FormikProps, FormikValues } from 'formik/dist/types';

export const defaultFormikOptions = <Values extends FormikValues = FormikValues>(
  options: FormikConfig<Values>,
): FormikConfig<Values> => {
  return {
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    ...options,
  };
};

export interface UseFormParams<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<T>;
  errorFeedback: (n: string) => string;
  touchedFeedback: (n: string) => string;
}

const useForm = <Values extends FormikValues = FormikValues>(
  options: FormikConfig<Values>,
): UseFormParams<Values> => {
  const { t } = useTranslation();

  const formik = useFormik<Values>({
    ...defaultFormikOptions(options),
  });

  const errorFeedback = (name: string) => {
    const error = getIn(formik.errors, name);

    return error && t(error);
  };

  const touchedFeedback = (name: string) => {
    return getIn(formik.touched, name);
  };

  return { formik, errorFeedback, touchedFeedback };
};

export default useForm;
