import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertDialog, Button, Icon } from '@hs-baumappe/legacy-ui';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { DriveSpecialDocumentMimeTypes } from '../../../../../Project/drive/drive.types';
import { projectRoutes } from '../../../../../routes';
import { QsstLocationState } from '../../../../Qsst.route.types';
import { qsstRoutes } from '../../../../qsstRoutes';
import ProjectDriveMimeTypeFoldersQuery from './graphql/ProjectDriveMimeTypeFolders.query';

interface EditQsstButtonProps {
  qsstId: string;
  qsstName: string;
  projectId: string;
  viewerCanUpdate: boolean;
}

export const EditQsstButton: FC<EditQsstButtonProps> = ({
  qsstId,
  qsstName,
  projectId,
  viewerCanUpdate,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isEditSignedDialogOpen, setIsEditSignedDialogOpen] = useState(false);

  const { data } = useQuery(ProjectDriveMimeTypeFoldersQuery, {
    variables: {
      projectId,
    },
    skip: viewerCanUpdate,
  });

  const handleEditButtonClick = useCallback(() => {
    if (!viewerCanUpdate) {
      setIsEditSignedDialogOpen(true);
      return;
    }

    navigate(qsstRoutes.edit.generatePath({ qsstId }));
  }, [navigate, qsstId, viewerCanUpdate]);

  const handleConfirm = useCallback(() => {
    const mimeTypeFolder = data?.project.drive.mimeTypeFolderMap.find(
      (f) => f.mimeType === DriveSpecialDocumentMimeTypes.QSST,
    );

    if (!mimeTypeFolder) return;

    navigate(
      projectRoutes.createQsst.generatePath({
        projectId,
        folderId: mimeTypeFolder.folder.id,
      }),
      {
        state: {
          qsstId,
        } as QsstLocationState,
      },
    );
  }, [data, navigate, projectId, qsstId]);

  return (
    <>
      <Button
        fullWidth
        className="u-margin-top-xsmall"
        endIcon={<Icon name="create" />}
        onClick={handleEditButtonClick}
      >
        {t('qsst.editButton')}
      </Button>

      {/* edit uneditable dialog */}
      <AlertDialog
        open={isEditSignedDialogOpen}
        title={t('qsst.editSignedQsstDialog.title', { documentName: qsstName })}
        description={t('qsst.editSignedQsstDialog.body')}
        confirmButtonText={t('qsst.editSignedQsstDialog.confirmButton')}
        cancelButtonText={t('qsst.editSignedQsstDialog.cancelButton')}
        confirmButtonEndIcon={<Icon name="file-copy" />}
        onRequestClose={() => setIsEditSignedDialogOpen(false)}
        onCancel={() => setIsEditSignedDialogOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};
