import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { LogDateFragment } from './__generated__/LogDateFragment';

export function getStringToLogDate(date: string): string {
  const d = new Date(date);
  return format(d, 'dd.MM.yyyy');
}

export function getHourToLogDate(date: string): string {
  const d = new Date(date);
  return format(d, 'HH:mm');
}

interface LogDateProps {
  showDate?: boolean;
  date: LogDateFragment['date'];
}

export default function LogDate({ showDate, date }: LogDateProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      {showDate && t('logTable.logDate', { date: getStringToLogDate(date) })}
      {t('logTable.logTime', { hour: getHourToLogDate(date) })}
    </>
  );
}
