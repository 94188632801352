import { TypedDocumentNode, gql } from '@apollo/client/core';
import {
  SendAcceptanceReportEmail,
  SendAcceptanceReportEmailVariables,
} from './__generated__/AcceptanceReportSendEmailMutation';

const SendAcceptanceReportEmailMutation: TypedDocumentNode<
  SendAcceptanceReportEmail,
  SendAcceptanceReportEmailVariables
> = gql`
  mutation SendAcceptanceReportEmail($input: SendAcceptanceReportEmailInput!) {
    sendAcceptanceReportEmail(input: $input) {
      status
    }
  }
`;

export default SendAcceptanceReportEmailMutation;
