import { gql } from '@apollo/client';

const QsstProjectFragment = gql`
  fragment QsstProject on Project {
    id

    name
    draft
    description
    projectNumber
    hsProjectNumber
    additionalName
  }
`;

export default QsstProjectFragment;
