import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ObstructionConvertImageAttachments,
  ObstructionConvertImageAttachmentsVariables,
} from './__generated__/ObstructionConvertImageAttachments.mutation';

const ObstructionConvertImageAttachmentsMutation: TypedDocumentNode<
  ObstructionConvertImageAttachments,
  ObstructionConvertImageAttachmentsVariables
> = gql`
  mutation ObstructionConvertImageAttachments($input: [ID!]!) {
    convertCustomFilesToImages(input: $input) {
      id

      url
    }
  }
`;

export default ObstructionConvertImageAttachmentsMutation;
