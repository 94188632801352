import { format } from 'date-fns';

export const currentDate = (): string => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
};

export function formatISOStringToDateString(date: string): string {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function formatDateToDateString(date: Date): string {
  return formatISOStringToDateString(date.toISOString());
}
