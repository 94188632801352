import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { FormikFormField, NameAutocompleteField } from '@hs-baumappe/forms';
import QsstFormDetailSectionQuery from './graphql/QsstFormDetailSection.query';
import {
  QsstFormDetailSectionDetails,
  QsstFormDetailSectionDetailsVariables,
} from './graphql/__generated__/QsstFormDetailSection.query';

interface QsstFormDetailSectionProps {
  projectId: string;
}

export const QsstFormDetailSection: FC<QsstFormDetailSectionProps> = ({ projectId }) => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery<
    QsstFormDetailSectionDetails,
    QsstFormDetailSectionDetailsVariables
  >(QsstFormDetailSectionQuery, {
    variables: {
      projectId,
    },
  });

  if (error) return null;

  if (!data && loading) {
    return <LayoutLoading />;
  }

  const employeeNames = data?.employeesForProject
    ? data?.employeesForProject.map((employee) => employee.name)
    : [];

  return (
    <>
      {/* projectNumber and clientName */}
      <div className="row">
        <div className="col col--sm-6">
          <FormikFormField
            name="projectNumber"
            label={t('qsstForm.projectNumber.label')}
            disabled={!data?.project.draft}
          />
        </div>

        <div className="col col--sm-6">
          <FormikFormField
            name="clientName"
            label={t('qsstForm.clientName.label')}
            placeholder={t('qsstForm.clientName.placeholder')}
            disabled={!data?.project.draft}
          />
        </div>
      </div>

      {/* documentName and contractDate */}
      <div className="row u-margin-top-small">
        <div className="col col--sm-6">
          <FormikFormField name="name" label={t('qsstForm.name.label')} />
        </div>

        <div className="col col--sm-6">
          <FormikFormField<'input'>
            type="date"
            name="contractDate"
            label={t('qsstForm.contractDate.label')}
            placeholder={t('obstructionForm.date.placeholder')}
          />
        </div>
      </div>

      {/* onsideEmployee and clientContact */}
      <div className="row u-margin-top-small">
        <div className="col col--sm-6">
          <NameAutocompleteField
            name="onsideEmployee.fullName"
            label={t('qsstForm.onsideEmployee.label')}
            placeholder={t('qsstForm.onsideEmployee.placeholder')}
            names={employeeNames}
          />
        </div>
      </div>
    </>
  );
};
