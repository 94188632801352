import { ApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { UPDATE_PROJECT_STATUS } from './graphql';
import { UpdateProjectStatus, UpdateProjectStatusVariables } from './__generated__/graphql';
import { ProjectStatus } from '../../../../../globalTypes';

type UseUpdateProjectStatus = {
  updateProjectStatus: (status: ProjectStatus) => void;
  error: ApolloError | undefined;
  loading: boolean;
};

function useUpdateProjectStatus(projectId: string): UseUpdateProjectStatus {
  const [updateProjectStatusMutation, { error, loading }] = useMutation<
    UpdateProjectStatus,
    UpdateProjectStatusVariables
  >(UPDATE_PROJECT_STATUS);

  const updateProjectStatus = useCallback(
    async (status: ProjectStatus) => {
      await updateProjectStatusMutation({
        variables: {
          input: {
            project: projectId,
            status,
          },
        },
      });
    },
    [projectId, updateProjectStatusMutation],
  );

  return {
    updateProjectStatus,
    error,
    loading,
  };
}

export default useUpdateProjectStatus;
