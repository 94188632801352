import { gql, TypedDocumentNode } from '@apollo/client';
import {
  CreateObstructionProjectDetail,
  CreateObstructionProjectDetailVariables,
} from './__generated__/CreateObstructionProjectDetail.query';

const CreateObstructionProjectDetailQuery: TypedDocumentNode<
  CreateObstructionProjectDetail,
  CreateObstructionProjectDetailVariables
> = gql`
  query CreateObstructionProjectDetail($projectId: ID!) {
    project(id: $projectId) {
      id

      date
      description
      hsProjectNumber
      additionalName

      client {
        id

        name
      }
    }
  }
`;
export default CreateObstructionProjectDetailQuery;
