import { gql, TypedDocumentNode } from '@apollo/client';
import { PrecautionCategories } from './__generated__/PrecautionCategories.query';
import PrecautionFragment from './Precaution.fragment';

const PrecautionCategoriesQuery: TypedDocumentNode<PrecautionCategories> = gql`
  query PrecautionCategories {
    precautionCategories {
      id

      title
      sort
      icon

      precautions {
        ...Precaution
      }
    }
  }

  ${PrecautionFragment}
`;

export default PrecautionCategoriesQuery;
