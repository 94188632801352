import { TypedDocumentNode, gql } from '@apollo/client';
import { RiskAssessmentLog } from './__generated__/RiskAssessmentLog.fragment';

const RiskAssessmentLogFragment: TypedDocumentNode<RiskAssessmentLog> = gql`
  fragment RiskAssessmentLog on RiskAssessmentLog {
    ... on RiskAssessmentArchivedLog {
      id
      name
      date
      riskAssessmentId

      actorId
      actorFirstName
      actorLastName
    }

    ... on RiskAssessmentCreatedLog {
      id
      name
      date
      riskAssessmentId

      actorId
      actorFirstName
      actorLastName
    }

    ... on RiskAssessmentDeletedLog {
      id
      name
      date

      actorId
      actorFirstName
      actorLastName
    }

    ... on RiskAssessmentEmailSentLog {
      id
      name
      date
      riskAssessmentId
      emails

      actorId
      actorFirstName
      actorLastName
    }

    ... on RiskAssessmentSignedByCreatorLog {
      id
      name
      date
      riskAssessmentId

      actorId
      actorFirstName
      actorLastName
    }

    ... on RiskAssessmentSignedByEmployeeLog {
      id
      name
      date
      riskAssessmentId

      employeeName
    }
  }
`;

export default RiskAssessmentLogFragment;
