import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  FlexGrid,
  FlexGridColumn,
  Icon,
  IconButton,
  Search,
  Stack,
  Text,
  useSnackbar,
} from '@hs-baumappe/legacy-ui';

import useProjectImport from '../../hooks/useProjectImport';
import useProjectImportSearchProjects, {
  MIN_TERM_LENGTH,
} from '../../hooks/useProjectImportSearchProjects';
import ErrorState from '../../../../../containers/ErrorState';
import ProjectImportTable from '../ProjectImportTable';
import ProjectImportTablePageLoader from '../ProjectImportTable/ProjectImportTablePageLoader';
import { getDatahubSearchProjectError } from '../../../../../apollo/errors';

export default function ProjectImportModalBody(): JSX.Element {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();

  const { open: openSnackbar, close: closeSnackbar } = useSnackbar();
  const { projectImport, loading: importing } = useProjectImport();
  const { projects, searchProjects, hasNextPage, fetchNextPage, loading, loadingNextPage, error } =
    useProjectImportSearchProjects({
      onError: (searchError) => {
        const datahubSearchError = getDatahubSearchProjectError(searchError);
        if (!datahubSearchError) return;

        openSnackbar(
          <Flex gap="small-3x" stylex={{ paddingEnds: 'small-2x' }}>
            <Icon name="cancel" size={20} />
            <Stack gap="small-3x">
              <Text variant="title-small">{t('importHsProjectModal.searchError.title')}</Text>
              <Text variant="body-medium">{t('importHsProjectModal.searchError.description')}</Text>
              <Box component="ul" stylex={{ marginBottom: '0' }}>
                <li>
                  <Text variant="body-small">{`[${datahubSearchError.datahubStatusCode}]`}</Text>
                </li>
              </Box>
            </Stack>
          </Flex>,
          {
            type: 'error',
            action: (snackbarId) => (
              <IconButton
                icon={<Icon name="close" />}
                color="inherit"
                onClick={() => closeSnackbar(snackbarId)}
              />
            ),
            autoHideDuration: undefined,
          },
        );
      },
    });

  useEffect(() => {
    if (!loading) {
      setSearching(false);
    }
  }, [loading]);

  function handleSearch(value: string) {
    const formattedValue = value.trimStart();

    setSearching(true);
    setSearchValue(formattedValue);
    setSelectedProjectId(undefined);
    searchProjects(formattedValue);
  }

  function handleProjectClick(id: string) {
    if (id === selectedProjectId) {
      setSelectedProjectId(undefined);
      return;
    }

    setSelectedProjectId(id);
  }

  const showPageLoader = hasNextPage && !error && !loading && !searching;
  const searchError = error ? getDatahubSearchProjectError(error) : undefined;

  return (
    <>
      {error && !searchError && <ErrorState error={error} />}

      <FlexGrid>
        <FlexGridColumn sm={8}>
          <FlexGrid>
            <FlexGridColumn sm={6}>
              <Text>{t('importHsProjectModal.description')}</Text>
            </FlexGridColumn>
          </FlexGrid>

          <Stack stylex={{ marginTop: 'medium' }} gap="medium">
            <Search
              id="hs-project-search"
              fieldProps={{
                label: t('hsProjectSearch.projectSearchInput.label'),
                placeholder: t('hsProjectSearch.projectSearchInput.placeholder'),
                value: searchValue,
                onChange: (event) => handleSearch(event.currentTarget.value),
              }}
              onSubmit={() => handleSearch(searchValue)}
            />

            {searchValue.length > MIN_TERM_LENGTH && (
              <ProjectImportTable
                searchTerm={searchValue}
                loading={searching || loading}
                searchError={searchError}
                projects={projects}
                selectedProjectId={selectedProjectId}
                onProjectClick={handleProjectClick}
                pageLoader={
                  showPageLoader && (
                    <ProjectImportTablePageLoader
                      loading={loadingNextPage}
                      loadNextPage={() => fetchNextPage({ term: searchValue })}
                    />
                  )
                }
              />
            )}
          </Stack>
        </FlexGridColumn>

        <FlexGridColumn sm={4}>
          <Box stylex={{ position: 'sticky', top: '0', paddingTop: 'medium' }}>
            <Button
              color="primary"
              className="qa-project-import-submit-button"
              disabled={!selectedProjectId}
              loading={importing}
              onClick={() => selectedProjectId && projectImport(selectedProjectId)}
              endIcon={<Icon name="save" />}
              fullWidth
            >
              {t('importHsProjectModal.submitButton')}
            </Button>
          </Box>
        </FlexGridColumn>
      </FlexGrid>
    </>
  );
}
