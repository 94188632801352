import { FC } from 'react';
import { ParseKeys } from 'i18next';
import { Radio, Text } from '@hs-baumappe/legacy-ui';
import styles from './radioRow.module.scss';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { SpecificScoreFormValues } from '../utils';

interface RadioRowProps {
  gridRow: number;
  scoreKey: keyof SpecificScoreFormValues['specificScore'];
}

const RadioRow: FC<RadioRowProps> = ({ gridRow, scoreKey }) => {
  const { t } = useTranslation();
  const formik = useFormikContext<SpecificScoreFormValues>();

  const [, meta, helpers] = useField({ name: `specificScore.${scoreKey}` });

  const isError = meta.error && meta.value === undefined;

  const radioClassName = cx({
    [styles.radioError]: isError,
  });

  return (
    <>
      <Text
        color="primary"
        variant="body-medium"
        className={styles.label}
        style={{
          gridRow: `${gridRow}`,
          gridColumn: '1',
          justifySelf: 'start',
        }}
      >
        {t(`qsstSign.form.specificScore.radio.${scoreKey}` as ParseKeys<'translation'>)}
      </Text>

      {[...Array(5)].map((_, idx) => (
        <div
          key={idx}
          style={{
            gridRow: `${gridRow}`,
            gridColumn: `${idx + 2}`,
          }}
        >
          <Radio
            checked={formik.values.specificScore[scoreKey] === idx}
            onClick={() => {
              helpers.setValue(idx);
            }}
            className={radioClassName}
          />
        </div>
      ))}
    </>
  );
};

export { RadioRow };
