import { ApolloError, useMutation } from '@apollo/client';

import { UPDATE_FREEFORM_FOLDER_MUTATION } from './graphql';
import { UpdateFreeformFolder, UpdateFreeformFolderVariables } from './__generated__/graphql';
import { GET_OFFER_DATA } from '../../../Detail/graphql';
import { BaseValues } from '../../containers/FolderForm/values';
import { cast, clearEmptyTotal } from '../../../measurement/MeasurementSection/values';
import { castNote } from '../../containers/FolderForm/form';
import { OfferMode } from '../../../../../globalTypes';

export interface FolderEditForFreeformProps {
  onSuccess: () => void;
  offerId: string;
  folderId: string;
  offerMode: OfferMode;
}

interface UseOfferFolderEditForFreeformParams {
  error?: ApolloError;
  editFolder: (values: BaseValues) => Promise<void>;
}

const useOfferFolderEditForFreeform = ({
  onSuccess,
  offerId,
  folderId,
  offerMode,
}: FolderEditForFreeformProps): UseOfferFolderEditForFreeformParams => {
  const [mutation, { loading, error }] = useMutation<
    UpdateFreeformFolder,
    UpdateFreeformFolderVariables
  >(UPDATE_FREEFORM_FOLDER_MUTATION);

  const editFolder = async (values: BaseValues) => {
    if (loading) {
      return;
    }

    const { measurements, note } = values;
    const unit = measurements?.length ? measurements[0].type : undefined;

    const castedMeasurements = clearEmptyTotal(cast(measurements));
    const castedNotes = castNote(note);

    await mutation({
      variables: {
        input: {
          partNoAlias: offerMode === OfferMode.ALIAS_NUMBER ? values.alias : undefined,
          folder: folderId,
          name: values.name,
          approximate: values.approximate,
          note: castedNotes,
          unit,
          measurements: castedMeasurements,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    onSuccess();
  };

  return {
    error,
    editFolder,
  };
};

export default useOfferFolderEditForFreeform;
