import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentSignGetEmployeeNames,
  RiskAssessmentSignGetEmployeeNamesVariables,
} from './__generated__/RiskAssessmentSignGetEmployeeNames.query';

const RiskAssessmentSignGetEmployeeNamesQuery: TypedDocumentNode<
  RiskAssessmentSignGetEmployeeNames,
  RiskAssessmentSignGetEmployeeNamesVariables
> = gql`
  query RiskAssessmentSignGetEmployeeNames($projectId: ID!) {
    employeesForProject(id: $projectId) {
      name
    }
  }
`;

export default RiskAssessmentSignGetEmployeeNamesQuery;
