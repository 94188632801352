import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTimer } from '@hs-baumappe/legacy-ui';

type CanvasAlertProviderProps = {
  children: ReactNode;
};

type AlertColor = 'error' | 'warning' | 'info';

export type CanvasAlertContextProps = {
  setAlert: (message: string, color?: AlertColor) => void;
  alertMessage: string | null;
  alertColor: AlertColor;
};

export const CanvasAlertContext = createContext<CanvasAlertContextProps>({
  alertMessage: null,
  alertColor: 'info',
  setAlert: () => '',
});

export function CanvasAlertProvider({ children }: CanvasAlertProviderProps): JSX.Element {
  const { setTimeoutTimer, clearAllTimers } = useTimer();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertColor, setAlertColor] = useState<AlertColor>('info');

  const setAlert = useCallback(
    (message: string | null, color?: AlertColor) => {
      setAlertMessage(message);
      setAlertColor(color || 'info');
    },
    [setAlertMessage, setAlertColor],
  );

  useEffect(() => {
    if (alertMessage) {
      setTimeoutTimer(() => {
        setAlertMessage(null);
        setAlertColor('info');
      }, 5000);
    }

    return () => {
      clearAllTimers();
    };
  }, [alertMessage, clearAllTimers, setTimeoutTimer]);

  return (
    <CanvasAlertContext.Provider value={{ alertMessage, setAlert, alertColor }}>
      {children}
    </CanvasAlertContext.Provider>
  );
}
