import { useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';
import { Box, Flex, Icon, IconButton, Paper, Text, Textarea } from '@hs-baumappe/legacy-ui';

interface QualityCardProps {
  name: string;
  onRemoveClick?: () => void;
}

export default function QualityCard({ name, onRemoveClick }: QualityCardProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Paper stylex={{ marginTop: 'medium', padding: 'small-x' }}>
      <Flex>
        <Text variant="title-medium">{t('concern.create.form.quality.card.title')}</Text>

        {onRemoveClick && (
          <IconButton
            icon={<Icon name="close" />}
            stylex={{ marginLeft: 'auto' }}
            onClick={onRemoveClick}
          />
        )}
      </Flex>

      <Box className="row">
        <Box className="col col--sm-6">
          <FormikFormField
            name={`${name}.name`}
            className="u-margin-top-small u-flex-basis-100%"
            label={t('concern.create.form.quality.name.label')}
            placeholder={t('concern.create.form.quality.name.placeholder')}
          />
        </Box>
      </Box>

      <FormikFormField<typeof Textarea>
        control={Textarea}
        name={`${name}.description`}
        className="u-margin-top-small"
        label={t('concern.create.form.quality.description.label')}
        placeholder={t('concern.create.form.quality.description.placeholder')}
        minRows={4}
        maxRows={12}
      />
    </Paper>
  );
}
