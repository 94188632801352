import { SivKeyDataSapTransmit } from './graphql/__generated__/SivKeyDataSapTransmit.mutation';
import { SIVKeyDataSapTransmitStatus } from '../../../globalTypes';

export class SapTransmitInvalidCredentialsError extends Error {}

export class SapTransmitPartiallyCompletedError extends Error {
  constructor(
    public failedTransmissions: SivKeyDataSapTransmit['sivKeyDataSapTransmit']['failedTransmissions'],
  ) {
    super();
  }
}

export class SapTransmitFailedError extends Error {
  constructor(
    public failedTransmissions: SivKeyDataSapTransmit['sivKeyDataSapTransmit']['failedTransmissions'],
  ) {
    super();
  }
}

export function throwErrorIfSapTransmitNotFulfilled(data: SivKeyDataSapTransmit): void {
  if (data.sivKeyDataSapTransmit.status === SIVKeyDataSapTransmitStatus.INVALID_CREDENTIALS) {
    throw new SapTransmitInvalidCredentialsError();
  }

  const completedTransmissions = data.sivKeyDataSapTransmit.completedTransmissions;
  const failedTransmissions = data.sivKeyDataSapTransmit.failedTransmissions;
  const partiallyCompleted = completedTransmissions.length > 0 && failedTransmissions.length > 0;

  if (partiallyCompleted) {
    throw new SapTransmitPartiallyCompletedError(failedTransmissions);
  }

  const failed = completedTransmissions.length === 0 && failedTransmissions.length > 0;

  if (failed) {
    throw new SapTransmitFailedError(failedTransmissions);
  }
}
