import { gql, TypedDocumentNode } from '@apollo/client';
import {
  GetConstructionNoteDetail,
  GetConstructionNoteDetailVariables,
} from './__generated__/GetConstructionNoteDetail.query';

const GetConstructionNoteDetailQuery: TypedDocumentNode<
  GetConstructionNoteDetail,
  GetConstructionNoteDetailVariables
> = gql`
  query GetConstructionNoteDetail($id: ID!) {
    constructionNote(id: $id) {
      id
      name
      path
      status
      viewerCanRename
      viewerCanArchive
      viewerCanRemove
      viewerCanAssign

      project {
        id
        projectNumber
        hsProjectNumber
        name
        draft
      }
    }
  }
`;

export default GetConstructionNoteDetailQuery;
