import { Location } from 'react-router-dom';
import { parseFromTimeZone } from 'date-fns-timezone';
import { RiskAssessmentFormValues } from './RiskAssessmentForm/RiskAssessmentForm';
import { InformationValues } from './RiskAssessmentForm/Information/values';
import { RiskAssessment } from './graphql/__generated__/RiskAssessment.fragment';
import {
  RiskAssessmentInChargeSaveInput,
  RiskAssessmentSaveInput,
  RiskAssessmentSignatureType,
} from '../../globalTypes';
import { RiskAssessmentLocationState } from './RiskAssessment.route.type';

function createRiskAssessmentInChargeFromInformation({
  firstAider,
  sideManager,
  securityManager,
  operationManager,
  regionalSecurityManagers,
  additionalRegionalSecurityManagers,
}: InformationValues): RiskAssessmentInChargeSaveInput[] {
  const inCharge = [firstAider, sideManager, securityManager].map((person) => ({
    fullName: person.fullName,
    role: person.role,
    phone: person.phone || undefined,
  }));

  if (operationManager?.fullName) {
    inCharge.push({
      fullName: operationManager.fullName,
      role: operationManager.role,
      phone: operationManager.phone || undefined,
    });
  }

  const additionalSecurityManagers = additionalRegionalSecurityManagers
    .filter((manager) => manager.fullName)
    .map((manager) => ({
      ...manager,
      phone: manager.phone || undefined,
    }));

  return [...inCharge, ...regionalSecurityManagers, ...additionalSecurityManagers];
}

interface CreateRiskAssessmentSaveInputParams {
  documentId: string;
  draft: boolean;
  values: RiskAssessmentFormValues;
}

export function createRiskAssessmentSaveInput({
  documentId,
  draft,
  values,
}: CreateRiskAssessmentSaveInputParams): RiskAssessmentSaveInput {
  const {
    information,
    equipments: { equipments },
    precautions: { precautions, specialPrecautions },
  } = values;

  const date = parseFromTimeZone(information.date, { timeZone: 'Europe/Berlin' });

  return {
    documentId,
    description: information.description,
    date,
    clientName: draft ? information.customerName : undefined,
    draftProjectNumber: draft ? information.projectNr : undefined,
    inCharges: createRiskAssessmentInChargeFromInformation(information),
    selectedPrecautions: precautions || [],
    equipmentIds: equipments
      .filter((equipment) => equipment.value)
      .map((equipment) => equipment.label),
    specialPrecautions:
      specialPrecautions.map((precaution) => ({
        title: precaution.title,
        precautionCategoryId: precaution.precautionCategory,
      })) || [],
  };
}

export function hasSignatureInType(
  signatures: RiskAssessment['signatures'],
  type: RiskAssessmentSignatureType,
): boolean {
  return signatures.some((signature) => signature.type === type);
}

export function createRiskAssessmentLocation(location: Location): RiskAssessmentLocationState {
  return {
    backgroundLocation: { ...location, search: '' },
  };
}

export function getParentDialog(element: HTMLElement | null): HTMLElement | null {
  if (element === null || !element.parentElement) {
    return null;
  }

  if (element.parentElement.getAttribute('role') === 'dialog') {
    return element.parentElement;
  }

  return getParentDialog(element.parentElement);
}

type IsRiskAssessmentSignableParams = {
  riskAssessment: RiskAssessment;
  signPermitted: boolean;
};

export function isRiskAssessmentSignable({
  riskAssessment,
  signPermitted,
}: IsRiskAssessmentSignableParams): boolean {
  const signedByManager = hasSignatureInType(
    riskAssessment.signatures,
    RiskAssessmentSignatureType.MANAGER,
  );

  return riskAssessment.viewerCanSign && (signedByManager || signPermitted);
}
