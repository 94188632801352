import { Button, Icon } from '@hs-baumappe/legacy-ui';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';
import cx from 'classnames';
import stylex from '@hs-baumappe/legacy-stylex';
import yup from '../../../../yup';

export interface SapTransmitLoginValues {
  name: string;
  password: string;
}

const sapTransmitLoginValidationSchema: yup.SchemaOf<SapTransmitLoginValues> = yup.object({
  name: yup.string().required(),
  password: yup.string().required(),
});

interface SapTransmitLoginProps {
  selectedProjectIds: string[];
  onSubmit: (
    values: SapTransmitLoginValues,
    helpers: FormikHelpers<SapTransmitLoginValues>,
  ) => void;
  initialValues?: Partial<SapTransmitLoginValues>;
}

export default function SapTransmitLogin({
  selectedProjectIds,
  initialValues,
  onSubmit,
}: SapTransmitLoginProps): JSX.Element {
  const { t } = useTranslation();
  const formik = useFormik<SapTransmitLoginValues>({
    validationSchema: sapTransmitLoginValidationSchema,
    onSubmit,
    initialValues: {
      name: '',
      password: '',
      ...initialValues,
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Button
          color="primary"
          type="submit"
          fullWidth
          stylex={{ marginBottom: 'medium' }}
          disabled={!selectedProjectIds.length}
          endIcon={<Icon name="forward" />}
        >
          {selectedProjectIds.length} {t('transmit.submitButton')}
        </Button>
        <FormikFormField
          name="name"
          className={cx(stylex.create({ marginBottom: 'small-x' }))}
          placeholder={t('transmit.name.placeholder')}
          label={t('transmit.name.label')}
          type="text"
        />

        <FormikFormField
          name="password"
          label={t('transmit.password.label')}
          placeholder={t('transmit.password.placeholder')}
          type="password"
        />
      </Form>
    </FormikProvider>
  );
}
