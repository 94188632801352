import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ProjectEmailSuggestions,
  ProjectEmailSuggestionsVariables,
} from './__generated__/ProjectEmailSuggestions.query';

const ProjectEmailSuggestionsQuery: TypedDocumentNode<
  ProjectEmailSuggestions,
  ProjectEmailSuggestionsVariables
> = gql`
  query ProjectEmailSuggestions($projectId: ID!) {
    emailSuggestions(project: $projectId) {
      recipients {
        name
        email
      }

      contactPeople {
        name
        email
      }
    }
  }
`;

export default ProjectEmailSuggestionsQuery;
