import { ReactNode } from 'react';

import useWindowSize from '../../../hooks/useWindowHeight';

import './o-sidebar-layout.scss';

const HEADER_HEIGHT = 192;

interface SidebarLayoutProps {
  children: ReactNode;
}

export default function SidebarLayout({ children }: SidebarLayoutProps): JSX.Element {
  const { innerHeight, innerWidth } = useWindowSize();

  return (
    <div
      className="o-sidebar-layout"
      style={
        innerWidth && innerHeight && innerWidth >= 768
          ? { height: `${innerHeight - HEADER_HEIGHT}px` }
          : undefined
      }
    >
      {children}
    </div>
  );
}
