import ClickableDivElement from '../ClickableDivElement';
import ImageThumbnail, { ImageThumbnailProps } from '../ImageThumbnail';
import { ClickableDivElementProps } from '../ClickableDivElement/ClickableDivElement';

export type ClickableImageThumbnailProps = ImageThumbnailProps & {
  onClick: ClickableDivElementProps['onClick'];
};

export default function ClickableImageThumbnail({
  onClick,
  ...otherProps
}: ClickableImageThumbnailProps): JSX.Element {
  return (
    <ClickableDivElement onClick={onClick}>
      <ImageThumbnail {...otherProps} />
    </ClickableDivElement>
  );
}
