function stringToBlobGenerator(source: string, fileMimeType: string): Blob {
  const textEncoder = new TextEncoder();
  const encodedString = textEncoder.encode(source);

  return new Blob([encodedString], {
    type: fileMimeType,
  });
}

export default stringToBlobGenerator;
