import { SchemaOf } from 'yup';
import yup from '../../../../yup';
import { ConstructionNoteEditTitleFormValues } from './values';

// eslint-disable-next-line import/prefer-default-export
export const validationSchema: SchemaOf<ConstructionNoteEditTitleFormValues> = yup.object({
  name: yup.string().max(255).required(),
});

export const constructionNoteEditTitleInitialValues = (
  initialValues?: Partial<ConstructionNoteEditTitleFormValues>,
): ConstructionNoteEditTitleFormValues => {
  return {
    name: '',
    ...initialValues,
  };
};
