import { useViewer, ViewerDriveDocumentScopes } from '@hs-baumappe/web-auth';
import { useQuery } from '@apollo/client';
import { DriveDocumentPreview } from '../../graphql/__generated__/DriveDocumentPreviewQuery';
import DriveDocumentPreviewQuery from '../../graphql/DriveDocumentPreviewQuery';

interface UseDriveDocumentPreview {
  currentDocument?: NonNullable<DriveDocumentPreview['driveDocument']>;
  loading: boolean;
  scopes?: ViewerDriveDocumentScopes;
}

export default function useDriveDocumentPreview(documentId: string): UseDriveDocumentPreview {
  const { viewer } = useViewer();
  const { data, loading } = useQuery(DriveDocumentPreviewQuery, {
    variables: {
      documentId,
    },
  });

  const currentDocument = data?.driveDocument ? data.driveDocument : undefined;

  return {
    loading,
    currentDocument,
    scopes: viewer?.scopes,
  };
}
