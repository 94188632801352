import { forwardRef, Ref } from 'react';
import cx from 'classnames';

import './c-note-editor-canvas.scss';

type NoteEditorCanvasProps = JSX.IntrinsicElements['canvas'];

export default forwardRef(function NoteEditorCanvas(
  { className, ...otherProps }: NoteEditorCanvasProps,
  ref: Ref<HTMLCanvasElement>,
) {
  return <canvas className={cx('c-note-editor-canvas', className)} ref={ref} {...otherProps} />;
});
