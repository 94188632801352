import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { EmailFormValues } from '../../../containers/Email';
import { ConstructionNoteWithDriveDocument } from './useSendConstructionNoteEmailDetail';

export function getConstructionNoteEmailFormInitialValues(
  constructionNote: ConstructionNoteWithDriveDocument,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  return {
    content: viewer.signatureEmail
      ? t('sendConstructionNoteEmailForm.content.initial', {
          ...viewer,
          email: viewer.signatureEmail,
        })
      : t('sendConstructionNoteEmailForm.content.initial_with_no_email', {
          ...viewer,
        }),
    subject: t('sendConstructionNoteEmailForm.subject.initial', {
      projectName: constructionNote.project.name,
      constructionNoteName: constructionNote.name,
    }),
    documentIds: [constructionNote.id],
  };
}
