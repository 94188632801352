import { gql } from '@apollo/client';

export default gql`
  query GetContactPerson($id: ID!) {
    contactPerson(id: $id) {
      id
      name
      email
      phone
      note
    }
  }
`;
