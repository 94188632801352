import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import Tr from '../Tr';
import Td from '../Td';

import './c-table-empty-state.scss';

interface Props {
  className?: string;
  colSpan: number;
  id?: string;
}

const TableEmptyState: FC<PropsWithChildren<Props>> = ({ className, children, colSpan, id }) => (
  <Tr className={cx('c-table-empty-state', className)}>
    <Td id={id} className="c-table-empty-state__content" colSpan={colSpan}>
      {children}
    </Td>
  </Tr>
);

export default TableEmptyState;
