import { IconButton, Icon } from '@hs-baumappe/legacy-ui';
import useCanvasZoom from '../../../hooks/useCanvasZoom';

const ZOOM_OUT_DECREMENT = 0.25;

export default function ZoomOutAction(): JSX.Element {
  const { zoomOut, canZoomOut } = useCanvasZoom();

  function handleZoomOutActionClick() {
    zoomOut(ZOOM_OUT_DECREMENT);
  }

  return (
    <IconButton
      data-testid="zoom-out-action-button"
      icon={<Icon name="zoom-out" />}
      onClick={handleZoomOutActionClick}
      disabled={!canZoomOut}
    />
  );
}
