import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import ObstructionDetail from './ObstructionDetail';
import ObstructionRouteParams, { ObstructionLocationState } from './obstruction.route.types';
import ObstructionEdit from './ObstructionEdit';
import ObstructionSign from './ObstructionSign';
import ObstructionArchive from './ObstructionArchive';
import ObstructionSendEmail from './ObstructionSendEmail';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';
import useObstruction from './hooks/useObstruction';
import DocumentDetailLoadingLayout from '../components/DocumentDetailLoadingLayout';

export default function Obstruction(): JSX.Element {
  const { obstructionId } = useParams<ObstructionRouteParams>();
  const location = useLocation<ObstructionLocationState>();
  const backgroundLocation = location.state && location.state.backgroundLocation;
  const { data, loading, error } = useObstruction(obstructionId);

  useUpdateDriveDocumentLastUsed({
    id: obstructionId,
    skip: !data?.obstruction,
  });

  if (loading && !data) {
    return <DocumentDetailLoadingLayout />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<ObstructionDetail {...data} />} />
      </Routes>

      <Routes>
        <Route path="edit" element={<ObstructionEdit {...data} />} />
        <Route path="send-email" element={<ObstructionSendEmail {...data} />} />
        <Route path="archive" element={<ObstructionArchive {...data} />} />
        <Route path="sign" element={<ObstructionSign {...data} />} />
      </Routes>
    </>
  );
}
