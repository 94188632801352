import { ApolloError, useMutation } from '@apollo/client';
import GenerateDocumentIdMutation from './graphql/GenerateDocumentId.mutation';
import { FetchResult } from '@apollo/client/link/core';
import { GenerateDocumentId } from './graphql/__generated__/GenerateDocumentId.mutation';

interface UseGenerateDocumentIdResponse {
  generate: () => Promise<FetchResult<GenerateDocumentId>>;
  documentId?: string;
  loading: boolean;
  error?: ApolloError;
}

export default function useGenerateDocumentId(
  folderId: string,
  mimeType: string,
): UseGenerateDocumentIdResponse {
  const [generate, { data, loading, error }] = useMutation(GenerateDocumentIdMutation, {
    variables: {
      input: {
        id: folderId,
        mimeType,
      },
    },
  });

  return {
    generate,
    documentId: data?.driveDocumentGenerateId,
    loading,
    error,
  };
}
