import { gql } from '@apollo/client';

const HSProjectFragment = gql`
  fragment HSProject on HsProject {
    id

    projectNumber
    name
    isImported
    sourceSystem
  }
`;

export default HSProjectFragment;
