import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@hs-baumappe/legacy-ui';
import { useViewer } from '@hs-baumappe/web-auth';
import { MissingScopeError } from '@hs-baumappe/redkit';
import { ProjectsRouteLocationState } from '../Projects.routes';
import { projectsRoutes } from '../../routes';
import RouteDialog from '../../../containers/RouteDialog';
import ProjectImportModalBody from './components/ProjectImportModalBody';

export default function ProjectImportModal(): JSX.Element {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(true);
  const location = useLocation<ProjectsRouteLocationState>();
  const navigate = useNavigate();
  const { viewer } = useViewer();

  const handleAfterClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(projectsRoutes.detail.generatePath({}));
  }, [navigate, location.state]);

  const importProjectPermitted = !!viewer?.scopes.importProject.isPermitted;
  const missingScopes = viewer?.scopes.importProject.missingScopeNames ?? [];

  return (
    <RouteDialog
      open={dialogOpen}
      onClose={handleAfterClose}
      onRequestClose={() => setDialogOpen(false)}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('importHsProjectModal.title')}
        </Text>
      }
    >
      {importProjectPermitted ? (
        <ProjectImportModalBody />
      ) : (
        <MissingScopeError missingScopes={missingScopes} />
      )}
    </RouteDialog>
  );
}
