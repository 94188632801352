import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTimer } from '@hs-baumappe/legacy-ui';
import OfferContext from './OfferContext';

import { Folder, FolderOperation } from '../../Detail/types';
import { findFolderById } from '../../Detail/Detail.utils';
import { OfferData, OfferDataVariables } from '../../Detail/__generated__/graphql';
import { folderBuilder } from '../../Detail/folderBuilder';
import { CatalogOperation } from '../../operation/OfferFolderOperationCreate/OperationSearch/types';
import { OfferStatus } from '../../../../globalTypes';

interface OfferProviderProps {
  offer: OfferData['offer'];
  refetchOfferData: (variables?: Partial<OfferDataVariables>) => void;
}

const OfferProvider: FC<PropsWithChildren<OfferProviderProps>> = ({
  offer,
  refetchOfferData,
  children,
}) => {
  const { setTimeoutTimer, clearAllTimers } = useTimer();
  const [offerData, setOfferData] = useState<OfferProviderProps['offer']>(offer);
  const [selectedFolderId, setSelectedFolderId] = useState<Folder['id']>();
  const [selectedCatalogue, setSelectedCatalogueId] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const [selectedCatalogueOperation, setSelectedCatalogueOperation] = useState<CatalogOperation>();

  const folders = folderBuilder(offerData.folders);

  useEffect(() => {
    setOfferData(offer);
    setAlertMessage(undefined);
  }, [offer]);

  const dismissibleAlert = !!alertMessage && offer.status !== OfferStatus.ARCHIVED;

  useEffect(() => {
    if (dismissibleAlert) {
      setTimeoutTimer(() => setAlertMessage(undefined), 5000);
    }

    return () => {
      clearAllTimers();
    };
  }, [clearAllTimers, dismissibleAlert, setTimeoutTimer]);

  const getProject = () => {
    return offerData.project;
  };

  const getOffer = () => {
    return offerData;
  };

  const getFolderById = (id: Folder['id']) => {
    return findFolderById(folders, id);
  };

  const getOperationById = (folderId: Folder['id'], operationId: FolderOperation['id']) => {
    const folder = getFolderById(folderId);
    if (!folder) {
      return undefined;
    }

    return folder.operations.find((f) => f.id === operationId);
  };

  return (
    <OfferContext.Provider
      value={{
        alertMessage,
        setAlertMessage,
        setSelectedFolder: setSelectedFolderId,
        selectedFolder: selectedFolderId ? findFolderById(folders, selectedFolderId) : undefined,
        getProject,
        getOffer,
        getFolderById,
        getOperationById,
        selectedCatalogue,
        setSelectedCatalogueId,
        refetchOfferData,
        selectedCatalogueOperation,
        setSelectedCatalogueOperation,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferProvider;
