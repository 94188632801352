import { TypedDocumentNode, gql } from '@apollo/client/core';
import {
  CustomFileDocumentArchive,
  CustomFileDocumentArchiveVariables,
} from './__generated__/CustomFileDocumentArchive.mutation';
import CustomFileDocumentFragment from '../../graphql/CustomFileDocument.fragment';

const CustomFileArchiveMutation: TypedDocumentNode<
  CustomFileDocumentArchive,
  CustomFileDocumentArchiveVariables
> = gql`
  mutation CustomFileDocumentArchive($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      ...CustomFileDocument
    }
  }

  ${CustomFileDocumentFragment}
`;

export default CustomFileArchiveMutation;
