import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';
import RouteDialog from '../../../containers/RouteDialog';
import RiskAssessmentSendEmailBody from './components/RiskAssessmentSendEmailBody';
import { riskAssessmentRoutes } from '../riskAssessmentRoutes';
import { RiskAssessmentLocationState } from '../RiskAssessment.route.type';
import { RiskAssessment } from '../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../graphql/__generated__/RiskAssessmentProject.fragment';
import { RiskAssessmentScopes } from '../RiskAssessmentDetail/types';

interface RiskAssessmentSendEmailProps {
  riskAssessment: RiskAssessment;
  project: RiskAssessmentProject;
  scopes: RiskAssessmentScopes;
}

export default function RiskAssessmentSendEmail({
  riskAssessment,
  project,
  scopes,
}: RiskAssessmentSendEmailProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<RiskAssessmentLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  function handleDialogRequestClose() {
    if (formDirty) {
      setShowConfirmationDialog(true);
      return;
    }

    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(riskAssessmentRoutes.detail.generatePath({ riskAssessmentId: riskAssessment.id }));
  }, [location.state, navigate, riskAssessment]);

  function handleConfirmationDialogConfirm() {
    setShowConfirmationDialog(false);
    setDialogOpen(false);
  }

  const permitted = scopes.sendRiskAssessmentDocumentAttachment.isPermitted;
  const missingScopes = scopes.sendRiskAssessmentDocumentAttachment.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {riskAssessment.name}
        </Text>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopes} />
      ) : (
        <RiskAssessmentSendEmailBody
          riskAssessment={riskAssessment}
          project={project}
          onDocumentIdsValueChange={() => setDialogOpen(false)}
          onDirtyStateChange={() => setFormDirty(true)}
        />
      )}

      <AlertDialog
        open={showConfirmationDialog}
        title={riskAssessment?.name}
        description={t('riskAssessmentSendEmail.confirmationModal.body')}
        confirmButtonText={t('riskAssessmentSendEmail.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('riskAssessmentSendEmail.confirmationModal.cancelButton')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setShowConfirmationDialog(false)}
        onRequestClose={() => setShowConfirmationDialog(false)}
        destructive
      />
    </RouteDialog>
  );
}
