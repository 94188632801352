import { lazy, Suspense } from 'react';
import ErrorBoundary from '../../ErrorBoundary';

// eslint-disable-next-line import/no-unresolved
const RouteModals = lazy(() => import('driveWeb/RouteModals'));

export default function DriveRouteModals(): JSX.Element {
  return (
    <ErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <RouteModals />
      </Suspense>
    </ErrorBoundary>
  );
}
