import { useQuery } from '@apollo/client';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import EquipmentsQuery from './graphql/Equipments.query';
import EquipmentsForm from './EquipmentsForm';
import { EquipmentSelection, EquipmentsFormValues } from './values';

interface EquipmentsProps {
  initialValues?: Partial<EquipmentsFormValues>;
  precautions: string[];
  edit?: boolean;
  onSubmit: (values: EquipmentsFormValues) => void;
  onDirtyStateChange: (dirty: boolean) => void;
}

export default function Equipments({
  initialValues,
  precautions,
  onSubmit,
  edit,
  onDirtyStateChange,
}: EquipmentsProps): JSX.Element | null {
  const { data, loading, error } = useQuery(EquipmentsQuery, {
    variables: {
      precautions,
    },
    fetchPolicy: 'network-only',
  });

  if (loading && !data) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!data) {
    return null;
  }

  const { equipments } = data;

  const equipmentSelections: EquipmentSelection[] = equipments.map((equipment) => {
    const initialEquipment = initialValues?.equipments?.find(({ label }) => label === equipment.id);

    return {
      label: equipment.id,
      required: equipment.required || false,
      value: initialEquipment ? initialEquipment.value : equipment.preselected || false,
    };
  });

  return (
    <EquipmentsForm
      initialValues={{ equipments: equipmentSelections }}
      equipments={equipments}
      onSubmit={onSubmit}
      onDirtyStateChange={onDirtyStateChange}
      edit={edit}
    />
  );
}
