import { gql, TypedDocumentNode } from '@apollo/client';
import {
  SendConstructionNoteEmailDetail,
  SendConstructionNoteEmailDetailVariables,
} from './__generated__/SendConstructionNoteEmailDetail.query';

const SendConstructionNoteEmailDetailQuery: TypedDocumentNode<
  SendConstructionNoteEmailDetail,
  SendConstructionNoteEmailDetailVariables
> = gql`
  query SendConstructionNoteEmailDetail($constructionNoteId: ID!) {
    constructionNote(id: $constructionNoteId) {
      id
      name

      project {
        id

        name
      }
    }

    driveDocument(id: $constructionNoteId) {
      id

      size
    }
  }
`;

export default SendConstructionNoteEmailDetailQuery;
