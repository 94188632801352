import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import ConstructionNoteDuplicationModal from '../../ConstructionNoteDetail/ConstructionNoteDuplicationModal';
import { constructionNoteRoutes } from '../../constructionNoteRoutes';
import { ConstructionNoteRouteParams } from '../../ConstructionNote.route.types';
import { ConstructionNoteStatus } from '../../../../globalTypes';

interface EditActionProps {
  status: ConstructionNoteStatus;
  name: string;
}

export default function EditAction({ status, name }: EditActionProps): JSX.Element {
  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const navigate = useNavigate();

  const [showDuplicateModal, updateShowDuplicateModal] = useState(false);

  function handleClickEdit() {
    if (status === ConstructionNoteStatus.ARCHIVED) {
      updateShowDuplicateModal(true);
      return;
    }

    navigate(constructionNoteRoutes.edit.generatePath({ constructionNoteId }));
  }

  return (
    <>
      <Button onClick={handleClickEdit} contentClassName="u-display-flex">
        <Icon name="create" size={24} />
      </Button>

      <ConstructionNoteDuplicationModal
        open={showDuplicateModal}
        constructionNoteId={constructionNoteId}
        constructionNoteName={name}
        onRequestClose={() => updateShowDuplicateModal(false)}
      />
    </>
  );
}
