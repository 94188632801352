import { gql } from '@apollo/client';
import ContactPersonFragment from '../../../ProjectDetail/components/ProjectClientInformation/graphql/ContactPerson.fragment';

export default gql`
  mutation ContactPeopleUpdate($input: ContactPersonUpdateInput!) {
    contactPersonUpdate(input: $input) {
      ...ContactPerson
    }
  }

  ${ContactPersonFragment}
`;
