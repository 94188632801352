import { Button, Flex, Icon, IconButton, List, ListItem, Text } from '@hs-baumappe/legacy-ui';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { SivKeyDataSapTransmit } from './graphql/__generated__/SivKeyDataSapTransmit.mutation';
import i18n from '../../../i18n';

interface WarningStateProps {
  retry: () => Promise<void>;
  close: () => void;
  failedTransmissions: SivKeyDataSapTransmit['sivKeyDataSapTransmit']['failedTransmissions'];
}

function SAPTransmitWarningState({
  failedTransmissions,
  close,
  retry,
}: WarningStateProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="title-medium" color="white">
          {t('transmit.warning.title')}
        </Text>
        <IconButton color="white" icon={<Icon name="close" />} onClick={close} />
      </Flex>

      <Text color="yellow-900">{t('transmit.warning.info')}</Text>

      <List>
        {failedTransmissions.map((transmit) => (
          <ListItem key={transmit.id}>{`${transmit.projectNumber} ${transmit.name}`}</ListItem>
        ))}
      </List>

      <Flex gap="medium" stylex={{ marginEnds: 'small' }}>
        <Button endIcon={<Icon name="close" />} onClick={close} fullWidth>
          {t('transmit.warning.cancelButton')}
        </Button>
        <Button endIcon={<Icon name="cached" />} onClick={retry} fullWidth>
          {t('transmit.warning.refreshButton')}
        </Button>
      </Flex>
    </>
  );
}

export default function SAPTransmitWarningStateWithTranslation(
  props: WarningStateProps,
): JSX.Element {
  return (
    <I18nextProvider i18n={i18n}>
      <SAPTransmitWarningState {...props} />
    </I18nextProvider>
  );
}
