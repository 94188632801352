import { AcceptanceReportLog } from '../../graphql/__generated__/AcceptanceReportLog.fragment';
import { Log } from '../Log.types';

const ACCEPTANCE_REPORT_LOG_TYPES = [
  'AcceptanceReportArchivedLog',
  'AcceptanceReportCreatedLog',
  'AcceptanceReportDeletedLog',
  'AcceptanceReportEmailSentLog',
  'AcceptanceReportExternalEmailSentLog',
  'AcceptanceReportExternalLinkExpiredLog',
  'AcceptanceReportManuallySignedLog',
  'AcceptanceReportNotSignedLog',
  'AcceptanceReportSignedByCustomerLog',
];

export function isAcceptanceReportLog(log: Log): log is AcceptanceReportLog {
  return ACCEPTANCE_REPORT_LOG_TYPES.includes(log.__typename);
}
