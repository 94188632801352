import { gql, TypedDocumentNode } from '@apollo/client';
import {
  RiskAssessmentCreateDetail,
  RiskAssessmentCreateDetailVariables,
} from './__generated__/RiskAssessmentCreateDetail.query';
import RegionalSecurityManagerFragment from '../../graphql/RegionalSecurityManager.fragment';

const RiskAssessmentCreateDetailQuery: TypedDocumentNode<
  RiskAssessmentCreateDetail,
  RiskAssessmentCreateDetailVariables
> = gql`
  query RiskAssessmentCreateDetail($projectId: ID!) {
    project(id: $projectId) {
      id

      description
      draft
      hsProjectNumber
      additionalName

      client {
        name
      }
    }

    regionalSecurityManagers {
      ...RegionalSecurityManager
    }
  }

  ${RegionalSecurityManagerFragment}
`;

export default RiskAssessmentCreateDetailQuery;
