import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useGetProjectDetail from './useGetProjectDetail';
import useProfessions from '../../../hooks/useProfessions';
import RouteDialog from '../../../containers/RouteDialog';
import ErrorState from '../../../containers/ErrorState';
import AcceptanceReportForm from '../../AcceptanceReport/forms/AcceptanceReportForm';
import { projectsRoutes } from '../../routes';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';
import useAcceptanceReportSave from '../../AcceptanceReport/hooks/useAcceptanceReportSave';
import {
  getProjectContactPeopleNames,
  mapAcceptanceReportProjectToFormValues,
} from './CreateAcceptanceReport.utils';

interface CreateAcceptanceReportProps {
  documentId: string;
}

function CreateAcceptanceReport({ documentId }: CreateAcceptanceReportProps): JSX.Element | null {
  const [dialogOpen, setDialogOpen] = useState(true);

  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const { data, loading, error } = useGetProjectDetail(projectId);
  const { save, loading: saving, error: saveError } = useAcceptanceReportSave(documentId);
  const { professions } = useProfessions();

  const [formDirty, setFormDirty] = useState(false);
  const [dirtyFormAlertDialogOpen, setDirtyFormAlertDialogOpen] = useState(false);

  const createAcceptanceReportScope = viewer?.scopes.createAcceptanceReportDocument;
  const viewerCanCreateAcceptanceReport = !!createAcceptanceReportScope?.isPermitted;

  const handleDialogClose = useCallback(() => {
    if (!viewerCanCreateAcceptanceReport) {
      navigate(projectsRoutes.detail.generatePath({}));
      return;
    }

    navigate(-1);
  }, [navigate, viewerCanCreateAcceptanceReport]);

  const project = data?.project;

  function handleDialogRequestClose() {
    if (formDirty) {
      setDirtyFormAlertDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleDirtyFormAlertDialogCancel() {
    setDirtyFormAlertDialogOpen(false);
  }

  function handleDirtyFormAlertDialogConfirm() {
    setDirtyFormAlertDialogOpen(false);
    setDialogOpen(false);
  }

  function renderContent() {
    if (loading) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!viewer || !project) {
      return null;
    }

    return (
      <AcceptanceReportForm
        initialValues={mapAcceptanceReportProjectToFormValues({ project, viewer, t })}
        projectId={project.id}
        draft={project.draft}
        contactPeople={getProjectContactPeopleNames(project)}
        professions={professions}
        loading={saving}
        submitButtonLabel={t('createAcceptanceReport.submitButton')}
        onSubmit={save}
        onDirtyStateChange={setFormDirty}
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          {project && (
            <Text variant="body-medium" component="div" color="muted">
              {project.description}
              {project.additionalName && ' | ' + project.additionalName}
            </Text>
          )}
          <Text variant="title-small" component="h2" className="u-margin-bottom-0">
            {t('createAcceptanceReport.title')}
          </Text>
        </>
      }
    >
      {saveError && <ErrorState error={saveError} />}

      {renderContent()}

      <AlertDialog
        open={dirtyFormAlertDialogOpen}
        title={t('createAcceptanceReport.confirmationModal.title')}
        description={t('createAcceptanceReport.confirmationModal.body')}
        confirmButtonText={t('createAcceptanceReport.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('createAcceptanceReport.confirmationModal.dismissButton')}
        onConfirm={handleDirtyFormAlertDialogConfirm}
        onCancel={handleDirtyFormAlertDialogCancel}
        onRequestClose={handleDirtyFormAlertDialogCancel}
        destructive
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateAcceptanceReport, {
  mimeType: DriveSpecialDocumentMimeTypes.ACCEPTANCE_REPORT,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
