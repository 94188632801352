import { fabric } from '@hs-baumappe/fabric';

export function hasSelection(object: fabric.Textbox): boolean {
  return object.selectionStart !== object.selectionEnd;
}

function haveSelectionStyles<T extends keyof fabric.TextOptions, K extends fabric.TextOptions[T]>(
  textBox: fabric.Textbox,
  property: T,
  value: K,
  start: number,
  end: number,
) {
  const selectionStyles = textBox.getSelectionStyles(start, end);

  /*
   * Array.every function returns true when the given array is empty.
   * */
  return (
    selectionStyles.length > 0 &&
    selectionStyles.every((selectionStyle) => selectionStyle[property] === value)
  );
}

// Controls to the given style - value pair defined on textbox's selection
export function areStylesAppliedToSelection<
  T extends keyof fabric.TextOptions,
  K extends fabric.TextOptions[T],
>(textBox: fabric.Textbox, property: T, value: K): boolean {
  const { selectionStart, selectionEnd } = textBox;

  if (selectionStart === undefined || selectionEnd === undefined) {
    return false;
  }

  return haveSelectionStyles(textBox, property, value, selectionStart, selectionEnd);
}

// Controls to the given style - value pair defined on the character that nearest to cursor's position
export function areStylesAppliedToCharacter<
  T extends keyof fabric.TextOptions,
  K extends fabric.TextOptions[T],
>(textBox: fabric.Textbox, property: T, value: K): boolean {
  const { selectionEnd, text } = textBox;
  const trimmedText = text ? text.trim() : '';
  const lastIndex = trimmedText.length > 1 ? trimmedText.length - 1 : 0;

  if (selectionEnd === undefined || lastIndex === selectionEnd - 1) {
    return false;
  }

  return haveSelectionStyles(textBox, property, value, selectionEnd - 1, selectionEnd - 1);
}

/*
 * Sets the given style property with the given value
 * for those selection styles that already have the given style property.
 * */
export function updateSelectionStyles<T extends keyof fabric.Textbox, K extends fabric.Textbox[T]>(
  textBox: fabric.Textbox,
  property: T,
  value: K,
): void {
  const lastIndex = textBox.text ? textBox.text.length - 1 : 0;
  /*
   * Due to Fabricjs's getSelectionStyles internal code
   * We have to increment by one the last index to get all selection styles.
   * */
  const selectionStyles = textBox.getSelectionStyles(0, lastIndex + 1);

  selectionStyles.forEach((selectionStyle, index) => {
    if (selectionStyle[property]) {
      textBox.setSelectionStyles(
        {
          ...selectionStyle,
          [property]: value,
        },
        index,
        index + 1,
      );
    }
  });
}
