import routes from '../routes';
import { route } from '../routes.utils';
import ObstructionRouteParams from './obstruction.route.types';

const obstructionRoutes = {
  detail: routes.obstruction,
  edit: route<ObstructionRouteParams>(`${routes.obstruction.path}/edit`),
  sign: route<ObstructionRouteParams>(`${routes.obstruction.path}/sign`),
  archive: route<ObstructionRouteParams>(`${routes.obstruction.path}/archive`),
  sendEmail: route<ObstructionRouteParams>(`${routes.obstruction.path}/send-email`),
};

export default obstructionRoutes;
