import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import LogResetUnreadCountMutation from './components/ProjectLog/LogResetUnreadCountMutation';
import {
  LogResetUnreadCount,
  LogResetUnreadCountVariables,
} from './components/ProjectLog/__generated__/LogResetUnreadCountMutation';
import GetProjectDetailQuery from './graphql/GetProjectDetail.query';

interface ResetLogUnreadCountResponse {
  resetLogUnreadCount: () => void;
}

interface ResetLogUnreadCountProps {
  projectId: string;
}

export default function useResetLogUnreadCount({
  projectId,
}: ResetLogUnreadCountProps): ResetLogUnreadCountResponse {
  const [mutation, { loading }] = useMutation<LogResetUnreadCount, LogResetUnreadCountVariables>(
    LogResetUnreadCountMutation,
  );

  const resetLogUnreadCount = useCallback(async () => {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        input: {
          projectId,
        },
      },
      refetchQueries: [{ query: GetProjectDetailQuery, variables: { projectId } }],
    });
  }, [loading, mutation, projectId]);

  return {
    resetLogUnreadCount,
  };
}
