import { TableRow } from '@hs-baumappe/legacy-ui';
import { ComponentProps, ReactNode } from 'react';
import PartProjectHoursTableCell from '../PartProjectHoursTableCell';

interface PartProjectHoursListRowProps extends Omit<ComponentProps<typeof TableRow>, 'children'> {
  codeColumn?: ReactNode;
  numberColumn?: ReactNode;
  totalHourOfEmployeesColumn: ReactNode;
  totalHoursToBeDistributedColumn: ReactNode;
  totalCostRecordedPrice: ReactNode;
  performanceCostRecordedPrice: ReactNode;
  showCostColumns: boolean;
}

export default function PartProjectHoursTableRow({
  codeColumn,
  numberColumn,
  totalHourOfEmployeesColumn,
  totalHoursToBeDistributedColumn,
  totalCostRecordedPrice,
  performanceCostRecordedPrice,
  showCostColumns,
  ...otherProps
}: PartProjectHoursListRowProps): JSX.Element {
  return (
    <TableRow {...otherProps}>
      <PartProjectHoursTableCell>{codeColumn}</PartProjectHoursTableCell>
      <PartProjectHoursTableCell stylex={{ paddingRight: 'large' }}>
        {numberColumn}
      </PartProjectHoursTableCell>
      <PartProjectHoursTableCell stylex={{ textAlign: 'right' }}>
        {totalHourOfEmployeesColumn}
      </PartProjectHoursTableCell>
      <PartProjectHoursTableCell
        stylex={{
          textAlign: 'right',
          paddingRight: showCostColumns ? 'large' : undefined,
        }}
      >
        {totalHoursToBeDistributedColumn}
      </PartProjectHoursTableCell>
      {showCostColumns && (
        <>
          <PartProjectHoursTableCell
            stylex={{
              borderLeft: '1px-solid-gray-300',
              paddingLeft: 'large',
              paddingRight: 'large',
              textAlign: 'right',
            }}
          >
            {totalCostRecordedPrice}
          </PartProjectHoursTableCell>

          <PartProjectHoursTableCell
            stylex={{ borderLeft: '1px-solid-gray-300', paddingLeft: 'large', textAlign: 'right' }}
          >
            {performanceCostRecordedPrice}
          </PartProjectHoursTableCell>
        </>
      )}
    </TableRow>
  );
}
