import { FC } from 'react';
import cx from 'classnames';

type Props = JSX.IntrinsicElements['td'] & {
  bordered?: boolean;
  dataTestId?: string;
};

const Td: FC<Props> = ({ className, bordered, dataTestId, children, ...otherProps }) => (
  <td
    className={cx('c-table__td', { 'is-bordered': bordered }, className)}
    data-testid={dataTestId}
    {...otherProps}
  >
    {children}
  </td>
);

export default Td;
