import { TypedDocumentNode, gql } from '@apollo/client';
import { ConstructionNoteLog } from './__generated__/ConstructionNoteLog.fragment';

const ConstructionNoteLogFragment: TypedDocumentNode<ConstructionNoteLog> = gql`
  fragment ConstructionNoteLog on ConstructionNoteLog {
    ... on ConstructionNoteArchivedLog {
      id
      name
      date
      constructionNoteId

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConstructionNoteCreatedLog {
      id
      name
      date
      constructionNoteId
      constructionNoteType

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConstructionNoteDeletedLog {
      id
      name
      date
      constructionNoteId

      actorId
      actorFirstName
      actorLastName
    }

    ... on ConstructionNoteEmailSentLog {
      id
      name
      date
      constructionNoteId

      actorId
      actorFirstName
      actorLastName
      emails
    }
  }
`;

export default ConstructionNoteLogFragment;
