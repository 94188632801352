import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import cx from 'classnames';

import Select, { SelectOption } from '../../../../../components/form/Control/Select';
import useUpdateProjectStatus from './useUpdateProjectStatus';
import ErrorState from '../../../../../containers/ErrorState';
import FieldLayout from '../../../../../components/form/FieldLayout';
import { ProjectStatus } from '../../../../../globalTypes';
import { GetProjectDetail } from '../../graphql/__generated__/GetProjectDetail.query';

import './c-project-status-select.scss';

type ProjectStatusSelectProps = {
  project: GetProjectDetail['project'];
  disabled?: boolean;
};

const projectStatuses = [
  ProjectStatus.REGISTERED,
  ProjectStatus.OFFERED,
  ProjectStatus.ORDERED,
  ProjectStatus.ACTIVE_CONSTRUCTION,
  ProjectStatus.CLOSED,
  ProjectStatus.CANCELED,
];

function projectStatusClassNameMapper(status: ProjectStatus): string {
  return status.toLocaleLowerCase().replace('_', '-');
}

function ProjectStatusSelect({ project, disabled }: ProjectStatusSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { id, viewerCanUpdateStatus, status } = project;
  const { updateProjectStatus, error, loading } = useUpdateProjectStatus(id);
  const projectStatusOptions: SelectOption[] = projectStatuses.map((pS) => ({
    label: t(`projectStatus.${pS}`),
    value: pS,
    className: 'u-color-black',
  }));
  const projectStatusSelectClassName = `is-${projectStatusClassNameMapper(status)}`;

  async function handleProjectStatusChange(event: ChangeEvent<HTMLSelectElement>) {
    await updateProjectStatus(event.target.value as ProjectStatus);
  }

  return (
    <>
      {error && <ErrorState error={error} />}
      <FieldLayout
        status={
          ((!viewerCanUpdateStatus || disabled) && 'disabled') ||
          (loading && 'loading') ||
          undefined
        }
      >
        {(props) => (
          <Select
            {...props}
            options={projectStatusOptions}
            className={cx(
              'c-project-status-select',
              'qa-project-status-select',
              projectStatusSelectClassName,
            )}
            value={project.status}
            onChange={handleProjectStatusChange}
            aria-label={t(`projectStatus.${project.status}`)}
          />
        )}
      </FieldLayout>
    </>
  );
}

export default ProjectStatusSelect;
