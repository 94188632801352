import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Icon, IconButton, Tab, Tabs, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { GET_OPERATION_CREATE_DATA } from './graphql';
import { GetOperationCreateData } from './__generated__/graphql';
import { OfferType } from '../../../../globalTypes';
import { OfferFolderOperationCreateRouteParams } from './route';
import OperationSearch from './OperationSearch';
import { OfferLocationState } from '../../Offer.route.types';
import { CatalogOperation } from './OperationSearch/types';
import CreateNewFolderOperation from './CreateNewFolderOperation';
import useOffer from '../../hooks/offer';
import { offerRoutes } from '../../offerRoutes';

enum CreateOperationTab {
  FROM_CATALOG = 'FROM_CATALOG',
  STANDARD = 'STANDARD',
}

const OfferFolderOperationCreate = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const { offerId, folderId } = useParams<OfferFolderOperationCreateRouteParams>();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const {
    getFolderById,
    getOffer,
    selectedCatalogue,
    setSelectedCatalogueId,
    setSelectedCatalogueOperation,
  } = useOffer();

  const { data, loading } = useQuery<GetOperationCreateData>(GET_OPERATION_CREATE_DATA, {
    variables: {
      folderId,
    },
  });

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location.state?.backgroundLocation, navigate, offerId]);

  const offer = getOffer();
  const folder = getFolderById(folderId);
  if (!folder) {
    return null;
  }

  const viewerCanAddOfferOperationFromTLK = !!viewer?.scopes.addOfferOperationFromTLK.isPermitted;

  const setSelectedCatalogue = (catId: string) => {
    setSelectedCatalogueId(catId);
  };

  const handleOperationSearchSelect = (operation: CatalogOperation) => {
    setSelectedCatalogueOperation(operation);
    navigate(
      offerRoutes.operationCreateFromTlk.generatePath({
        offerId,
        folderId,
        operationId: operation.id,
      }),
    );
  };

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {t('offer.createNewOperation.title')}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          {loading && <LayoutLoading />}
          {data && !loading && (
            <Tabs>
              {viewerCanAddOfferOperationFromTLK && (
                <Tab
                  id={CreateOperationTab.FROM_CATALOG}
                  label={t('offer.createNewOperation.tab.tlk.title')}
                  keepTabContentMounted
                  panel={
                    <OperationSearch
                      offerId={offerId}
                      folderId={folder.id}
                      freeform={offer.type === OfferType.FREE_FORM}
                      categoryID={folder.category?.id}
                      onSuccess={handleDialogClose}
                      initialCatalog={selectedCatalogue}
                      selectCatalog={setSelectedCatalogue}
                      onOperationSelect={handleOperationSearchSelect}
                    />
                  }
                />
              )}

              <Tab
                id={CreateOperationTab.STANDARD}
                label={t('offer.createNewOperation.tab.default.title')}
                panel={
                  <CreateNewFolderOperation
                    folder={folder}
                    catalogues={data.catalogues}
                    offerId={offer.id}
                    offerMode={offer.mode}
                    offerType={offer.type}
                    onSuccess={handleDialogClose}
                  />
                }
              />
            </Tabs>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default OfferFolderOperationCreate;
