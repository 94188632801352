import { Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import DocumentDetailBreadcrumbs from '../../../../../components/DocumentDetailBreadcrumbs';
import { CustomFileDocument } from '../../../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../../../graphql/__generated__/CustomFileDocumentProject.fragment';
import { DriveDocumentParentFolders } from '../../../../../hooks/useDriveDocumentParentFolders';
import routes from '../../../../routes';
import { getStatusColor } from './DocumentDetailHeader.utils';

interface DocumentDetailHeaderProps {
  document: CustomFileDocument;
  project: CustomFileDocumentProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function DocumentDetailHeader({
  document,
  project,
  parentFolders,
}: DocumentDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={
          <div className="qa-custom-file-document-title">
            <Text component="h1" color="muted" variant="body-large" stylex={{ marginBottom: '0' }}>
              {document.name}
            </Text>
            <Text color={getStatusColor(document.status)}>
              {t('customFile.documentDetail.header.status', { status: t(document.status) })}
            </Text>
          </div>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
