import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useRiskAssessmentForm, { RiskAssessmentTab } from './useRiskAssessmentForm';
import Information from './Information';
import Precautions from './Precautions';
import Equipments from './Equipments';
import { InformationValues } from './Information/values';
import { PrecautionValues } from './Precautions/values';
import { EquipmentsFormValues } from './Equipments/values';
import { RiskAssessmentEmployees } from './Information/graphql/__generated__/RiskAssessmentEmployees.query';
import { RegionalSecurityManager } from '../graphql/__generated__/RegionalSecurityManager.fragment';
import { getParentDialog } from '../RiskAssessment.utils';

export interface RiskAssessmentFormValues {
  information: InformationValues;
  precautions: PrecautionValues;
  equipments: EquipmentsFormValues;
}

interface RiskAssessmentFormProps {
  informationInitialValues?: Partial<InformationValues>;
  precautionsInitialValues?: Partial<PrecautionValues>;
  equipmentsInitialValues?: Partial<EquipmentsFormValues>;
  employees: RiskAssessmentEmployees['employeesForProject'];
  regionalSecurityManagers: RegionalSecurityManager[];
  onSubmit: (values: RiskAssessmentFormValues) => void;
  onStepChange: (step: RiskAssessmentTab) => void;
  onDirtyStateChange?: (dirty: boolean) => void;
  draft: boolean;
  edit?: boolean;
}

export default function RiskAssessmentForm({
  informationInitialValues,
  precautionsInitialValues,
  equipmentsInitialValues,
  employees,
  regionalSecurityManagers,
  onSubmit,
  onStepChange,
  onDirtyStateChange,
  draft,
  edit,
}: RiskAssessmentFormProps): JSX.Element {
  const { t } = useTranslation();

  const [informationValues, setInformationValues] = useState<InformationValues>();
  const [precautionValues, setPrecautionValues] = useState<PrecautionValues>();
  const [informationFormDirty, setInformationFormDirty] = useState(false);
  const [precautionFormDirty, setPrecautionFormDirty] = useState(false);
  const [equipmentsFormDirty, setEquipmentsFormDirty] = useState(false);

  useEffect(() => {
    const formDirty = informationFormDirty || precautionFormDirty || equipmentsFormDirty;
    onDirtyStateChange?.(formDirty);
  }, [informationFormDirty, precautionFormDirty, equipmentsFormDirty, onDirtyStateChange]);

  const { tab, isActiveTab, setActiveTab, isValidTab } = useRiskAssessmentForm(
    RiskAssessmentTab.Information,
    onStepChange,
  );

  function changeTab(nextTab: string) {
    if (!isValidTab(nextTab)) {
      return;
    }

    setActiveTab(nextTab);
    getParentDialog(document.getElementById(nextTab))?.scroll(0, 0);
  }

  function handleTabChange(nextTab: string) {
    changeTab(nextTab);
  }

  function handleInformationValuesSubmit(values: InformationValues) {
    setInformationValues(values);
    changeTab(RiskAssessmentTab.Precautions);
  }

  function handlePrecautionValuesSubmit(values: PrecautionValues) {
    setPrecautionValues(values);
    changeTab(RiskAssessmentTab.Equipments);
  }

  function handleEquipmentValuesSubmit(equipmentValues: EquipmentsFormValues) {
    if (!informationValues || !precautionValues || !equipmentValues) {
      return;
    }

    onSubmit({
      information: informationValues,
      precautions: precautionValues,
      equipments: equipmentValues,
    });
  }

  return (
    <Tabs selectedTabId={tab} onChange={handleTabChange}>
      <Tab
        id={RiskAssessmentTab.Information}
        label={t('createAndUpdateRiskAssessmentModal.tab.information.title')}
        panel={
          <Information
            initialValues={informationValues || informationInitialValues}
            employees={employees}
            regionalSecurityManagers={regionalSecurityManagers}
            onSubmit={handleInformationValuesSubmit}
            onDirtyStateChange={setInformationFormDirty}
            draft={draft}
          />
        }
      />

      <Tab
        id={RiskAssessmentTab.Precautions}
        label={t('createAndUpdateRiskAssessmentModal.tab.precautions.title')}
        panel={
          <Precautions
            initialValues={precautionValues || precautionsInitialValues}
            onSubmit={handlePrecautionValuesSubmit}
            onDirtyStateChange={setPrecautionFormDirty}
          />
        }
        disabled={
          !isActiveTab(RiskAssessmentTab.Precautions) && !isActiveTab(RiskAssessmentTab.Equipments)
        }
      />

      <Tab
        id={RiskAssessmentTab.Equipments}
        label={t('createAndUpdateRiskAssessmentModal.tab.equipment.title')}
        panel={
          <Equipments
            initialValues={equipmentsInitialValues}
            precautions={precautionValues?.precautions || []}
            onSubmit={handleEquipmentValuesSubmit}
            onDirtyStateChange={setEquipmentsFormDirty}
            edit={edit}
          />
        }
        disabled={!isActiveTab(RiskAssessmentTab.Equipments)}
      />
    </Tabs>
  );
}
