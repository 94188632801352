import { formatISOStringToDateString } from '../../../utils/date';
import { ConcernFormValues, Misgivings, Quality } from '../forms/ConcernForm/ConcernForm.types';
import { castConcernImageAttachmentsToFormValues } from '../forms/ConcernForm/ConcernForm.utils';
import { uuidv4 } from '../forms/ConcernForm/ConcernFormFields/QualityFormSection/QualityFormSection.utils';
import { ConcernEditDetail } from './graphql/__generated__/ConcernEditDetail.query';

type Concern = NonNullable<ConcernEditDetail['concern']>;

function getMisgivingsFromConcern({
  execution,
  contractor,
  quality,
}: Pick<Concern, 'execution' | 'contractor' | 'quality'>) {
  const hasExecution = execution ? Misgivings.EXECUTION : undefined;
  const hasContractor = contractor ? Misgivings.CONTRACTORS : undefined;
  const hasQuality = quality?.length ? Misgivings.QUALITY : undefined;

  const misgivings = [];

  if (hasExecution) {
    misgivings.push(Misgivings.EXECUTION);
  }

  if (hasContractor) {
    misgivings.push(Misgivings.CONTRACTORS);
  }

  if (hasQuality) {
    misgivings.push(Misgivings.QUALITY);
  }

  return misgivings;
}

function getQualitiesFromConcern({ quality }: Pick<Concern, 'quality'>): Quality[] {
  return quality?.length
    ? quality.map(({ name, description }) => ({
        id: uuidv4(),
        name,
        description,
      }))
    : [];
}

export function mapConcernToFormValues({
  project,
  execution,
  contractor,
  quality,
  clientName,
  professions,
  date,
  endDate,
  images,
}: Concern): ConcernFormValues {
  return {
    projectNumber: project.hsProjectNumber ?? '',
    clientName: clientName ?? '',
    date: date ? formatISOStringToDateString(date) : undefined,
    professions: professions ?? [],
    contractorsName: contractor?.name || '',
    contractorsDescription: contractor?.description || '',
    execution: execution || undefined,
    misgivings: getMisgivingsFromConcern({ execution, contractor, quality }),
    qualities: getQualitiesFromConcern({ quality }),
    endDate: formatISOStringToDateString(endDate),
    images: castConcernImageAttachmentsToFormValues(images),
  };
}
