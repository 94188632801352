import { gql, TypedDocumentNode } from '@apollo/client';
import ConcernFragment from '../../graphql/Concern.fragment';
import { ConcernSign, ConcernSignVariables } from './__generated__/ConcernSign.mutation';

const ConcernSignMutation: TypedDocumentNode<ConcernSign, ConcernSignVariables> = gql`
  mutation ConcernSign($input: ConcernSignInput!) {
    concernSign(input: $input) {
      ...Concern
    }
  }

  ${ConcernFragment}
`;

export default ConcernSignMutation;
