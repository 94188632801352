import { PropsWithChildren } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Button, Dialog, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';
import { CreateConstructionNoteValues } from './values';
import { validationSchema } from './form';

interface TitleModalProps {
  open: boolean;
  onRequestClose: () => void;
  initialTitle: string;
  loading: boolean;
  onSuccess: (values: CreateConstructionNoteValues) => void;
}

export default function TitleModal({
  initialTitle,
  loading,
  onSuccess,
  open,
  onRequestClose,
}: PropsWithChildren<TitleModalProps>): JSX.Element {
  const { t } = useTranslation();
  const formik = useFormik<CreateConstructionNoteValues>({
    initialValues: { title: initialTitle },
    onSubmit: onSuccess,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return (
    <Dialog open={open} onRequestClose={onRequestClose} size="fullscreen" animationType="slide-up">
      <Box className="container" stylex={{ width: '100%', paddingEnds: 'large' }}>
        <Flex alignItems="center">
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0', flexGrow: '1' }}>
            {t('constructionNote.create.modalTitle', { title: formik.values.title })}
          </Text>

          <IconButton icon={<Icon name="close" />} onClick={onRequestClose} />
        </Flex>

        <Box stylex={{ paddingTop: 'large' }}>
          <FormikProvider value={formik}>
            <Form>
              <Box className="row">
                <Box className="col col--sm-8">
                  <Box className="row">
                    <Box className="col col--sm-6">
                      <FormikFormField
                        name="title"
                        label={t('constructionNote.create.title.label')}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className="col col--sm-4">
                  <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
                    <Button
                      color="primary"
                      fullWidth
                      endIcon={<Icon name="save" />}
                      loading={loading}
                      type="submit"
                    >
                      {t('constructionNote.create.submitButton')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        </Box>
      </Box>
    </Dialog>
  );
}
