type UseNavigator = {
  chromiumBasedBrowser: boolean;
  touchScreen: boolean;
  iosDevice: boolean;
  tabletDevice: boolean;
  mobileDevice: boolean;
};

function useNavigator(): UseNavigator {
  const { userAgent, vendor, platform, maxTouchPoints } = navigator;
  const chromiumBasedBrowser = /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
  const touchScreen = !!maxTouchPoints && maxTouchPoints > 2;
  const iosDevice =
    /iPad|iPhone|iPod/.test(platform) || (maxTouchPoints > 2 && /MacIntel/.test(platform));
  const tabletDevice =
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent) ||
    (maxTouchPoints > 2 && /MacIntel/.test(platform));
  const mobileDevice =
    /Mobile|Android|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent,
    );

  return {
    chromiumBasedBrowser,
    touchScreen,
    iosDevice,
    tabletDevice,
    mobileDevice,
  };
}

export default useNavigator;
