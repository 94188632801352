import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useField } from 'formik';
import { useMutation } from '@apollo/client';
import ImageUploadWithLabel, {
  ImageUploadWithLabelFormValues,
} from '../../../../../../../../containers/forms/ImageUploadWithLabel';
import UploadObstructionImageAttachmentMutation from './graphql/UploadObstructionImageAttachment.mutation';
import {
  UploadObstructionImageAttachment,
  UploadObstructionImageAttachmentVariables,
} from './graphql/__generated__/UploadObstructionImageAttachment.mutation';
import ClickableImageThumbnail from '../../../../../../../../components/ClickableImageThumbnail';

interface ObstructionFormImageAttachmentProps {
  name: string;
  onRequestRemove: () => void;
  showRemoveButton?: boolean;
}

export default function ObstructionFormImageAttachment({
  name,
  onRequestRemove,
  showRemoveButton,
}: ObstructionFormImageAttachmentProps): JSX.Element {
  const { t } = useTranslation();
  const [uploadImage, { loading, data }] = useMutation<
    UploadObstructionImageAttachment,
    UploadObstructionImageAttachmentVariables
  >(UploadObstructionImageAttachmentMutation);
  const [imageUrlField, , imageUrlFieldHelpers] = useField<
    ImageUploadWithLabelFormValues['imageUrl']
  >(`${name}.imageUrl`);
  const [imageThumbnailUrlField, , imageThumbnailUrlFieldHelpers] = useField<
    ImageUploadWithLabelFormValues['imageThumbnailUrl']
  >(`${name}.imageThumbnailUrl`);
  const [imageIdField, , imageIdFieldHelpers] = useField<ImageUploadWithLabelFormValues['imageId']>(
    `${name}.imageId`,
  );
  const [fileField] = useField<ImageUploadWithLabelFormValues['file']>(`${name}.file`);

  useEffect(() => {
    const file = fileField.value;

    if (!file || loading || imageIdField.value) {
      return;
    }

    uploadImage({
      variables: {
        file,
      },
    });
  }, [fileField.value, uploadImage, imageIdField, loading]);

  useEffect(() => {
    if (data && !imageIdField.value) {
      imageIdFieldHelpers.setValue(data.uploadImage.id);
      imageUrlFieldHelpers.setValue(data.uploadImage.url);
      imageThumbnailUrlFieldHelpers.setValue(data.uploadImage.url);
    }
  }, [
    data,
    imageIdField.value,
    imageIdFieldHelpers,
    imageUrlFieldHelpers,
    imageThumbnailUrlFieldHelpers,
  ]);

  return (
    <div className="u-margin-bottom-xsmall">
      <ImageUploadWithLabel
        name={name}
        loading={loading}
        originalImageUrl={imageUrlField.value}
        onRequestRemove={onRequestRemove}
        showRemoveButton={!!imageIdField.value || showRemoveButton}
        labelInputPlaceholder={t('obstructionForm.imageAttachment.label.placeholder')}
        previewSection={(handleShowOriginalImageOpen) => (
          <ClickableImageThumbnail
            width={56}
            height={40}
            src={imageThumbnailUrlField.value}
            onClick={handleShowOriginalImageOpen}
          />
        )}
      />
    </div>
  );
}
