import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik';

import { AcceptanceReportDetailAlert } from '../AcceptanceReportDetail/AcceptanceReportDetail.utils';

import AcceptanceReportDetailQuery from '../hooks/useAcceptanceReport/graphql/AcceptanceReportDetail.query';
import AcceptanceReportSendExternalLinkEmailMutation from './graphql/AcceptanceReportSendExternalLinkEmail.mutation';
import { EmailFormValues } from '../../../containers/Email';
import { SendAcceptanceReportExternalLinkEmailInput } from '../../../globalTypes';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { getErrorsFromServerError } from '../../../apollo/errors';

interface SendAcceptanceReportExternalLinkParams {
  sendExternalLink: (
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useSendAcceptanceReportExternalLink(
  acceptanceReportId: string,
): SendAcceptanceReportExternalLinkParams {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError>();
  const [mutation, { loading }] = useMutation(AcceptanceReportSendExternalLinkEmailMutation, {
    refetchQueries: [{ query: AcceptanceReportDetailQuery, variables: { acceptanceReportId } }],
  });

  async function sendExternalLink(
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) {
    if (loading) {
      return;
    }

    try {
      await mutation({
        variables: {
          input: {
            id: acceptanceReportId,
            recipients: values.toEmails,
            subject: values.subject,
            content: values.content,
            cc: values.ccEmails,
          },
        },
      });

      const searchParams = new URLSearchParams({
        alert: AcceptanceReportDetailAlert.SEND_EMAIL,
      });

      navigate(
        createPath({
          pathname: acceptanceReportRoutes.detail.generatePath({ acceptanceReportId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        SendAcceptanceReportExternalLinkEmailInput
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.recipients,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendExternalLink,
    loading,
    error,
  };
}
