import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import DuplicateConstructionNoteMutation from './graphql/DuplicateConstructionNote.mutation';
import { constructionNoteRoutes } from '../../constructionNoteRoutes';

interface UseConstructionNoteDuplicationParams {
  duplicate: () => void;
  error?: ApolloError;
}

interface ConstructionNoteDuplicationProps {
  constructionNoteId: string;
}

export default function useConstructionNoteDuplication({
  constructionNoteId,
}: ConstructionNoteDuplicationProps): UseConstructionNoteDuplicationParams {
  const navigate = useNavigate();
  const [mutate, { loading, error }] = useMutation(DuplicateConstructionNoteMutation);

  async function duplicate() {
    if (loading) {
      return;
    }

    const { data } = await mutate({
      variables: {
        input: {
          constructionNote: constructionNoteId,
        },
      },
    });

    if (!data) {
      return;
    }

    navigate(
      constructionNoteRoutes.detailFreeDrawing.generatePath({
        constructionNoteId: data.duplicateConstructionNote.id,
      }),
    );
  }

  return {
    duplicate,
    error,
  };
}
