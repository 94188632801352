import { gql, TypedDocumentNode } from '@apollo/client';
import { Precaution } from './__generated__/Precaution.fragment';

const PrecautionFragment: TypedDocumentNode<Precaution> = gql`
  fragment Precaution on Precaution {
    id

    title
    sort
  }
`;

export default PrecautionFragment;
