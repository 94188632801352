import { fabric } from '@hs-baumappe/fabric';
import { repeatEmptyLines } from '../utils';

interface CreateTextBoxOptions {
  emptyLineCount: number;
  textBoxOptions: fabric.ITextboxOptions;
}

const DEFAULT_EMPTY_LINE_COUNT = 0;
const DEFAULT_TEXTBOX_OPTIONS = {
  fontSize: 18,
  fontFamily: 'Arial',
  textAlign: 'left',
  editingBorderColor: 'rgb(0, 137, 191)',
  borderColor: 'rgb(0, 137, 191)',
  padding: 10,
};

export default function createTextBox({
  emptyLineCount = DEFAULT_EMPTY_LINE_COUNT,
  textBoxOptions,
}: Partial<CreateTextBoxOptions>): fabric.Textbox {
  const textBox = new fabric.Textbox(repeatEmptyLines(emptyLineCount), {
    ...DEFAULT_TEXTBOX_OPTIONS,
    ...textBoxOptions,
  });

  textBox.setControlsVisibility({
    mb: false,
    mt: false,
  });

  return textBox;
}
