import { useField } from 'formik';
import { Box, Details, Summary, Text } from '@hs-baumappe/legacy-ui';
import { ChangeEvent, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ConcernFormValues } from '../../ConcernForm.types';
import { createEmptyImageWithLabel } from '../../../../../../containers/forms/ImageUploadWithLabel';
import ConcernFormImageAttachment from './components/ConcernFormImageAttachment';
import ConcernFormImageAttachmentMenu from './components/ConcernImageAttachmentMenu';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../../../../components/DriveImageGalleryContainer';
import ConcernConvertImageAttachmentsMutation from './graphql/ConcernConvertImageAttachmentsMutation';
import ErrorState from '../../../../../../containers/ErrorState';

interface ImageAttachmentFormSectionProps {
  projectId: string;
}

export default function ImageAttachmentFormSection({
  projectId,
}: ImageAttachmentFormSectionProps): JSX.Element {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState(false);
  const [imageAttachmentsField, imageAttachmentsFieldMeta, imageAttachmentsFieldHelpers] =
    useField<ConcernFormValues['images']>('images');
  const [convertDriveImages, { loading, error }] = useMutation(
    ConcernConvertImageAttachmentsMutation,
  );

  function removeImageAttachmentByIndex(index: number) {
    const imageAttachments = [...imageAttachmentsField.value];

    imageAttachments.splice(index, 1);

    imageAttachmentsFieldHelpers.setValue(imageAttachments);
  }

  function handleAddImagesFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const files = Array.from(event.target.files);
    const newImages = files.map((file) => createEmptyImageWithLabel({ file }));

    event.target.value = '';

    imageAttachmentsFieldHelpers.setValue([...imageAttachmentsField.value, ...newImages]);
  }

  async function handleDocumentsSelected(selectedDocuments: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    const { data } = await convertDriveImages({
      variables: {
        input: selectedDocuments.map(({ id }) => id),
      },
    });

    if (!data) {
      return;
    }

    const imageAttachments: ConcernFormValues['images'] = data.convertCustomFilesToImages.map(
      (convertedImage, index) => {
        const selectedDocument = selectedDocuments[index];

        return createEmptyImageWithLabel({
          imageId: convertedImage.id,
          label: selectedDocument?.name || '',
          imageUrl: convertedImage.url,
          imageThumbnailUrl: convertedImage.url,
        });
      },
    );

    imageAttachmentsFieldHelpers.setValue([...imageAttachmentsField.value, ...imageAttachments]);
    setDriveImageGalleryOpen(false);
  }

  function handleUploadImageButtonClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const hasImageAttachments = imageAttachmentsField.value.length > 0;
  const showRemoveButton = imageAttachmentsField.value.length > 1;
  const hasValidImageAttachmentOnInitial = imageAttachmentsFieldMeta.initialValue
    ? imageAttachmentsFieldMeta.initialValue.some((imageAttachment) => !!imageAttachment.imageId)
    : false;

  return (
    <Details
      defaultOpen={hasValidImageAttachmentOnInitial}
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="title-small" color="gray-700" component="h3" className="u-margin-bottom-0">
            {t('concern.create.form.imageAttachment.title')}
          </Text>
        </Summary>
      )}
    >
      {error && <ErrorState error={error} />}

      <Box className="row">
        <Box className="col col--sm-5">
          <Text component="p">{t('concern.create.form.imageAttachment.helperText')}</Text>
        </Box>
      </Box>

      <Box stylex={{ marginTop: 'medium' }}>
        {imageAttachmentsField.value.map((imageAttachment, index) => {
          return (
            <ConcernFormImageAttachment
              key={imageAttachment.orderId}
              name={`images.${index}`}
              onRequestRemove={() => removeImageAttachmentByIndex(index)}
              showRemoveButton={showRemoveButton}
            />
          );
        })}

        <Box className="row">
          <Box className="col col--sm-5">
            <ConcernFormImageAttachmentMenu
              hasImageAttachments={hasImageAttachments}
              onUploadImageButtonClick={handleUploadImageButtonClick}
              onSelectImageButtonClick={() => setDriveImageGalleryOpen(true)}
              loading={loading}
            />

            <input
              ref={fileInputRef}
              type="file"
              className="u-visually-hidden"
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleAddImagesFileInputChange}
              multiple
            />
          </Box>
        </Box>
      </Box>

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsSelected}
      />
    </Details>
  );
}
