import { ApolloError, useMutation } from '@apollo/client';

import UpdateProjectDateMutation from './graphql/UpdateProjectDate.mutation';
import {
  ProjectDateUpdate,
  ProjectDateUpdateVariables,
} from './graphql/__generated__/UpdateProjectDate.mutation';

interface UseUpdateProjectDateProps {
  projectId: string;
  date?: string;
}

interface UseUpdateProjectDateResponse {
  loading: boolean;
  error?: ApolloError;
  updateProjectDate: () => void;
}

export default function useUpdateProjectDate({
  projectId,
  date,
}: UseUpdateProjectDateProps): UseUpdateProjectDateResponse {
  const [mutation, { loading, error }] = useMutation<ProjectDateUpdate, ProjectDateUpdateVariables>(
    UpdateProjectDateMutation,
  );

  async function updateProjectDate() {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        input: {
          date: date || undefined,
          id: projectId,
        },
      },
    });
  }

  return {
    loading,
    error,
    updateProjectDate,
  };
}
