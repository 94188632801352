import { FC, KeyboardEvent, MouseEvent } from 'react';
import cx from 'classnames';

type Props = Omit<JSX.IntrinsicElements['th'], 'onClick'> & {
  bordered?: boolean;
  onClick?: (
    event: KeyboardEvent<HTMLTableHeaderCellElement> | MouseEvent<HTMLTableHeaderCellElement>,
  ) => void;
};

const KEY_CODES = {
  ENTER: 13,
  SPACE: 32,
};

const Th: FC<Props> = ({
  className,
  bordered,
  children,
  onClick,
  scope = 'col',
  ...otherProps
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLTableHeaderCellElement>) => {
    if ((event.which === KEY_CODES.ENTER || event.which === KEY_CODES.SPACE) && onClick) {
      event.preventDefault();

      onClick(event);
    }
  };
  return (
    <th
      className={cx('c-table__th', { 'is-bordered': bordered }, className)}
      scope={scope}
      {...(onClick
        ? {
            onClick,
            role: 'button',
            tabIndex: 0,
            onKeyPress: handleKeyPress,
          }
        : null)}
      {...otherProps}
    >
      {children}
    </th>
  );
};

export default Th;
