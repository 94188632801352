import { ElementType } from 'react';
import { BoxProps, TableCell } from '@hs-baumappe/legacy-ui';

type PartProjectHoursTableCellProps<T extends ElementType = 'td'> = BoxProps<T> & {
  disablePadding?: boolean;
};

export default function PartProjectHoursTableCell<T extends ElementType = 'td'>({
  stylex,
  component: componentProp,
  ...otherProps
}: PartProjectHoursTableCellProps<T>): JSX.Element {
  const component: ElementType = componentProp || 'td';

  return (
    <TableCell
      component={component}
      stylex={{
        paddingLeft: 'small-3x',
        paddingRight: 'small-3x',
        paddingEnds: 'small-4x',
        whiteSpace: 'nowrap',
        ...stylex,
      }}
      disablePadding
      {...otherProps}
    />
  );
}
