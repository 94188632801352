import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';

import useCustomFileDocumentSendEmail from '../../hooks/useCustomFileDocumentSendEmail';
import EmailForm from '../../../../../containers/Email';
import ErrorState from '../../../../../containers/ErrorState';
import { CustomFileDocument } from '../../../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../../../graphql/__generated__/CustomFileDocumentProject.fragment';
import { formatBytes } from '../../../../../utils/number';
import { getEmailFormInitialValues } from '../../DocumentSendEmail.utils';

interface DocumentSendEmailBodyProps {
  document: CustomFileDocument;
  project: CustomFileDocumentProject;
  onDocumentIdsValueChange: () => void;
  onDirtyStateChange: () => void;
}

export default function DocumentSendEmailBody({
  document,
  project,
  onDocumentIdsValueChange,
  onDirtyStateChange,
}: DocumentSendEmailBodyProps): JSX.Element | null {
  const { viewer } = useViewer();
  const { t } = useTranslation();

  const { sendEmail, loading, error } = useCustomFileDocumentSendEmail(document.id);

  if (!viewer) {
    return null;
  }

  const documents = [
    {
      id: document.id,
      name: document.name,
      size: typeof document.size === 'number' ? formatBytes(document.size) : undefined,
    },
  ];

  return (
    <>
      {error && <ErrorState error={error} />}

      <EmailForm
        projectId={project.id}
        initialValues={getEmailFormInitialValues(document, project, viewer, t)}
        documents={documents}
        submitButtonText={t('customFile.sendEmail.submitButtonText')}
        loading={loading}
        onSubmit={sendEmail}
        onDocumentIdsValueChange={onDocumentIdsValueChange}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
