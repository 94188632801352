import { gql } from '@apollo/client';
import ContactPersonFragment from './ContactPerson.fragment';

export default gql`
  query GetProjectClient($projectId: ID!) {
    project(id: $projectId) {
      id

      client {
        id
        name
        address
      }

      contactPeople {
        ...ContactPerson
      }
    }
  }

  ${ContactPersonFragment}
`;
