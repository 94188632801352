import { useEffect, useState } from 'react';
import { Divider, FlexGrid, FlexGridColumn, Stack, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import SIVDiscountInput from '../SIVDiscountInput';
import { validationSchema } from '../SIVDiscountInput/SIVDiscountInput';
import yup from '../../../../../../../../yup';
import { formatNumber, formatPriceEur } from '../../../../../../../../utils/number';

interface SIVDiscountBodyProps {
  discountAmount?: number;
  totalPerformanceCostPlannedRaw: number;
  totalPerformanceCostPlanned: number;
  totalPerformanceCostRecorded: number;
  updateSIVDiscountPermitted: boolean;
  onSetDiscountAmount: (discount?: number) => Promise<void>;
}

export default function SIVDiscountBody({
  discountAmount,
  updateSIVDiscountPermitted,
  totalPerformanceCostPlannedRaw,
  totalPerformanceCostPlanned,
  totalPerformanceCostRecorded,
  onSetDiscountAmount,
}: SIVDiscountBodyProps): JSX.Element {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [inputHelperText, setInputHelperText] = useState<string | undefined>(undefined);

  useEffect(
    () =>
      setInputValue(
        typeof discountAmount === 'number' ? formatNumber(discountAmount.toString()) : '',
      ),
    [discountAmount],
  );

  function handleInputBlur() {
    setInputHelperText(undefined);

    try {
      const result = validationSchema.validateSync(inputValue);
      onSetDiscountAmount(result ?? undefined);
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        setInputHelperText(e.message);
      }
    }
  }

  return (
    <>
      <SIVDiscountInput
        disabled={!updateSIVDiscountPermitted}
        value={inputValue}
        onChange={(event) => setInputValue(event.currentTarget.value)}
        onBlur={handleInputBlur}
        helperText={inputHelperText}
        discountPriceSection={
          <Stack gap="small-4x" justifyContent="center" stylex={{ height: '100%' }}>
            <Text color="muted" variant="label-small">
              {t('projectDetail.projectInformation.sivDiscount.discountPrice')}
            </Text>

            <Text
              variant="body-small"
              stylex={{ whiteSpace: 'nowrap' }}
              className="qa-discount-price"
            >
              {formatPriceEur(
                discountAmount ? (totalPerformanceCostPlannedRaw / 100) * discountAmount : 0,
              )}
            </Text>
          </Stack>
        }
      />

      <Divider stylex={{ marginEnds: '0' }} role="none" />

      <FlexGrid gutter="small-2x">
        <FlexGridColumn xs>
          <Stack gap="small-4x">
            <Text color="muted" variant="label-small">
              {t('projectDetail.projectInformation.sivDiscount.totalPerformanceCostPlannedPrice')}
            </Text>

            <Text
              variant="body-small"
              stylex={{ whiteSpace: 'nowrap' }}
              className="qa-discounted-performance-cost-planned"
            >
              {formatPriceEur(totalPerformanceCostPlanned)}
            </Text>
          </Stack>
        </FlexGridColumn>
        <FlexGridColumn xs>
          <Stack gap="small-4x">
            <Text color="muted" variant="label-small">
              {t('projectDetail.projectInformation.sivDiscount.totalPerformanceCostRecordedPrice')}
            </Text>

            <Text
              variant="body-small"
              stylex={{ whiteSpace: 'nowrap' }}
              className="qa-discounted-performance-cost-recorded"
            >
              {formatPriceEur(totalPerformanceCostRecorded)}
            </Text>
          </Stack>
        </FlexGridColumn>
      </FlexGrid>
    </>
  );
}
