import { useQuery } from '@apollo/client';
import { Divider, Stack, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import { formatISOStringToDateString } from '../../../../../utils/date';
import PartProjects from './PartProjects';
import ProjectDate from './ProjectDate';
import ProjectInformationLayout from './ProjectInformationLayout';
import SIVDiscount from './SIVDiscount';
import GetProjectInformationQuery from './graphql/GetProjectInformation.query';
import { GetPartProjectsInformation } from './graphql/__generated__/GetPartProjectsInformation.query';

interface ProjectInformationProps {
  projectId: string;
  sivId?: string;
  updateProjectPermitted: boolean;
  updateSIVPartProjectsPermitted: boolean;
  viewSIVPartProjectsPermitted: boolean;
  viewSIVPricesPermitted: boolean;
  viewSIVDiscountPermitted: boolean;
  updateSIVDiscountPermitted: boolean;
  partProjects: GetPartProjectsInformation['project']['partProjects'];
  siv?: NonNullable<GetPartProjectsInformation['project']['siv']>;
}

export default function ProjectInformation({
  projectId,
  partProjects,
  siv,
  sivId,
  updateProjectPermitted,
  updateSIVPartProjectsPermitted,
  viewSIVDiscountPermitted,
  updateSIVDiscountPermitted,
  viewSIVPartProjectsPermitted,
  viewSIVPricesPermitted,
}: ProjectInformationProps): JSX.Element | null {
  const { t } = useTranslation();
  const { data, error } = useQuery(GetProjectInformationQuery, {
    variables: {
      projectId,
    },
  });

  if (error) return <ErrorRenderer apolloError={error} />;
  if (!data) return null;

  const { project } = data;

  function showProjectInformation(value: string | null | undefined) {
    return value || '-';
  }

  return (
    <>
      <ProjectInformationLayout
        nameSection={
          <>
            <Text variant="label-small" color="muted">
              {t('projectDetail.projectInformation.name')}
            </Text>

            <Text component="p" variant="body-small" stylex={{ marginTop: '0' }}>
              {showProjectInformation(project.name)}
            </Text>
          </>
        }
        additionalNameSection={
          <>
            <Text variant="label-small" color="muted">
              {t('projectDetail.projectInformation.secondName')}
            </Text>

            <Text component="p" variant="body-small" stylex={{ marginTop: '0' }}>
              {showProjectInformation(project.additionalName)}
            </Text>
          </>
        }
        addressSection={
          <>
            <Text variant="label-small" color="muted">
              {t('projectDetail.projectInformation.address')}
            </Text>

            <Text component="p" variant="body-small" stylex={{ marginTop: '0' }}>
              {showProjectInformation(project.street || project.addressLine)}
            </Text>
          </>
        }
        citySection={
          <>
            <Text variant="label-small" color="muted">
              {t('projectDetail.projectInformation.city')}
            </Text>

            <Text component="p" variant="body-small" stylex={{ marginTop: '0' }} truncate>
              {showProjectInformation(project.city)}
            </Text>
          </>
        }
        postCodeSection={
          <>
            <Text variant="label-small" color="muted">
              {t('projectDetail.projectInformation.postCode')}
            </Text>

            <Text component="p" variant="body-small" stylex={{ marginTop: '0' }}>
              {showProjectInformation(project.postCode)}
            </Text>
          </>
        }
        dateSection={
          <ProjectDate
            projectDate={project.date ? formatISOStringToDateString(project.date) : ''}
            disabled={!updateProjectPermitted}
          />
        }
      />

      <Stack gap="small-x" stylex={{ marginTop: 'medium' }}>
        {viewSIVPartProjectsPermitted && project.isMainProject && (
          <>
            <PartProjects
              project={project}
              partProjects={partProjects}
              siv={siv}
              updateSIVPartProjectsPermitted={updateSIVPartProjectsPermitted}
              viewSIVPricesPermitted={viewSIVPricesPermitted}
            />

            <Divider stylex={{ marginEnds: '0' }} role="none" />
          </>
        )}

        {viewSIVDiscountPermitted && (
          <SIVDiscount
            projectId={projectId}
            sivId={sivId}
            updateSIVDiscountPermitted={updateSIVDiscountPermitted}
          />
        )}
      </Stack>
    </>
  );
}
