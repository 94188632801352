import { useField } from 'formik';
import { ProjectFilterFormValues } from '../../../../ProjectFilterForm.form';
import {
  getDeepestChildrenIdsOfProfitCenter,
  ProjectFilterProfitCenterWithChildren,
} from '../../ProjectFilterFormProfitCenters.utils';

interface UseProjectFilterFormProfitCentersResponse {
  isProfitCenterChecked: (profitCenter: ProjectFilterProfitCenterWithChildren) => boolean;
  setCheckedProfitCenter: (profitCenter: ProjectFilterProfitCenterWithChildren) => void;
  setUncheckedProfitCenter: (profitCenter: ProjectFilterProfitCenterWithChildren) => void;
}

export default function useProjectFilterFormProfitCenters(): UseProjectFilterFormProfitCentersResponse {
  const [profitCentersField, , profitCentersFieldHelpers] =
    useField<ProjectFilterFormValues['profitCenters']>('profitCenters');

  function isProfitCenterChecked(profitCenter: ProjectFilterProfitCenterWithChildren) {
    const checkedProfitCenters = profitCentersField.value
      .filter((profitCenterFilter) => profitCenterFilter.checked)
      .map(({ value }) => value);

    return checkedProfitCenters.includes(profitCenter.id);
  }

  function setCheckedProfitCenter(profitCenter: ProjectFilterProfitCenterWithChildren) {
    const affectedProfitCenterIds = [
      profitCenter.id,
      ...getDeepestChildrenIdsOfProfitCenter(profitCenter),
    ];

    const nextProfitCenters = [...profitCentersField.value].map((profitCenterFilter) => ({
      ...profitCenterFilter,
      checked: affectedProfitCenterIds.includes(profitCenterFilter.value)
        ? true
        : profitCenterFilter.checked,
    }));

    profitCentersFieldHelpers.setValue(nextProfitCenters);
  }

  function setUncheckedProfitCenter(profitCenter: ProjectFilterProfitCenterWithChildren) {
    const affectedProfitCenterIds = [
      profitCenter.id,
      ...getDeepestChildrenIdsOfProfitCenter(profitCenter),
    ];

    const nextProfitCenters = [...profitCentersField.value].map((profitCenterFilter) => ({
      ...profitCenterFilter,
      checked: affectedProfitCenterIds.includes(profitCenterFilter.value)
        ? false
        : profitCenterFilter.checked,
    }));

    profitCentersFieldHelpers.setValue(nextProfitCenters);
  }

  return {
    isProfitCenterChecked,
    setCheckedProfitCenter,
    setUncheckedProfitCenter,
  };
}
