export enum QsstDetailAlertType {
  SEND = 'send',
  ARCHIVED = 'archived',
}

export enum QsstDetailSearchParam {
  ALERT = 'alert',
}

export interface QsstDetailUrlQueryParams {
  alert: QsstDetailAlertType;
}
