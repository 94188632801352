import { gql, useApolloClient } from '@apollo/client';

import {
  GetSearchedOperations,
  GetSearchedOperationsVariables,
} from './__generated__/useSearchOperationQuery';
import { Catalog, CatalogCategory } from './types';
import TreeStorage from '../../../../../utils/TreeStorage';

type SearchedCategory = GetSearchedOperations['catalogues'][number]['allCategories'][number];
type SearchedCatalog = GetSearchedOperations['catalogues'][number];

const getSearchedOperations = gql`
  query GetSearchedOperations($term: String, $categoryID: String, $catalogID: String) {
    catalogues {
      id
      name
      allCategories {
        id
        name
        partNo
        parent {
          id
        }

        operations(term: $term, category: $categoryID, catalogue: $catalogID) {
          id
          name
          unit
          description
          viewerCanSelectAsMultiple
          price
          partNo
        }
      }
    }
  }
`;

const filterEmptyCategories =
  (storage: TreeStorage<SearchedCategory>) => (category: SearchedCategory) => {
    const subs = storage.getConnections(category.id);
    const filterer = filterEmptyCategories(storage);
    const filteredSubs: SearchedCategory[] = subs.filter(filterer);

    return filteredSubs.length + category.operations.length !== 0;
  };

const mapCategories =
  (storage: TreeStorage<SearchedCategory>, partNoTree: string[] = []) =>
  (category: SearchedCategory): CatalogCategory => {
    const subCategories = storage.getConnections(category.id);
    const newPartNoTree = [...partNoTree, category.partNo];
    const subCategoryMapper = mapCategories(storage, newPartNoTree);

    return {
      id: category.id,
      partNo: category.partNo,
      partNoTree: newPartNoTree,
      name: category.name || undefined,
      subCategories: subCategories
        .slice()
        .filter(filterEmptyCategories(storage))
        .map(subCategoryMapper),
      operations: category.operations.slice().map((op) => ({
        ...op,
        name: op.name || undefined,
        unit: op.unit || undefined,
        price: op.price || undefined,
        description: op.description || '',
        viewerCanSelectAsMultiple: op.viewerCanSelectAsMultiple,
        partNoTree: [...newPartNoTree, op.partNo],
      })),
    };
  };

export const buildCategories = (categories: SearchedCategory[]): CatalogCategory[] => {
  const categoryStorage = new TreeStorage({
    data: categories,
    connector: (category) => ({
      id: category.id,
      parentID: category.parent ? category.parent.id : undefined,
    }),
  });

  return categoryStorage
    .getRoots()
    .filter(filterEmptyCategories(categoryStorage))
    .map(mapCategories(categoryStorage));
};

export const buildCatalogs = (catalogs: SearchedCatalog[]): Catalog[] => {
  return catalogs
    .map<Catalog>((catalog) => ({
      ...catalog,
      categories: buildCategories(catalog.allCategories),
    }))
    .filter((c) => c.categories.length);
};

type UseSearchOperationsQueryParams = (v: GetSearchedOperationsVariables) => Promise<Catalog[]>;

const useSearchOperationsQuery = (): UseSearchOperationsQueryParams => {
  const client = useApolloClient();

  return async (variables: GetSearchedOperationsVariables) => {
    const response = await client.query<GetSearchedOperations, GetSearchedOperationsVariables>({
      query: getSearchedOperations,
      variables,
      fetchPolicy: 'no-cache',
    });

    return response.data ? buildCatalogs(response.data.catalogues || []) : [];
  };
};

export default useSearchOperationsQuery;
