import { OvertimeLog } from '../../graphql/__generated__/OvertimeLog.fragment';
import { Log } from '../Log.types';

const OVERTIME_LOG_TYPES = [
  'OvertimeArchivedLog',
  'OvertimeCreatedLog',
  'OvertimeDeletedLog',
  'OvertimeEmailSentLog',
  'OvertimeExternalEmailSentLog',
  'OvertimeExternalLinkExpiredLog',
  'OvertimeManuallySignedLog',
  'OvertimeNotSignedLog',
  'OvertimeRenamedLog',
  'OvertimeSignedByCustomerLog',
  'OvertimeUpdatedLog',
];

export function isOvertimeLog(log: Log): log is OvertimeLog {
  return OVERTIME_LOG_TYPES.includes(log.__typename);
}
