import { useNavigate } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import {
  cast,
  clearEmptyTotal,
  createFlatRateMeasurmentValue,
} from '../../measurement/MeasurementSection/values';
import { OfferMode, OperationUnit } from '../../../../globalTypes';
import { FolderOperation } from '../../Detail/types';
import { CloneOperation, CloneOperationVariables } from './__generated__/graphql';
import { CLONE_OPERATION_MUTATION } from './graphql';
import { castNote } from '../../folder/containers/FolderForm/form';
import { castPaymentToAddOperationToFolder } from '../PaymentForm/paymentMutationValidationSchema';

import { GET_OFFER_DATA } from '../../Detail/graphql';
import { BaseValues } from './CloneOperationForm/values';
import { validationSchema } from './CloneOperationForm/form';
import { offerRoutes } from '../../offerRoutes';

interface OfferFolderOperationCloneProps {
  offerId: string;
  offerMode: OfferMode;
}

interface UseOfferFolderOperationCloneParams {
  error?: ApolloError;
  clone: (v: BaseValues, o: FolderOperation) => Promise<void>;
}

const useOfferFolderOperationClone = ({
  offerId,
  offerMode,
}: OfferFolderOperationCloneProps): UseOfferFolderOperationCloneParams => {
  const navigate = useNavigate();
  const [mutation, { loading, error }] = useMutation<CloneOperation, CloneOperationVariables>(
    CLONE_OPERATION_MUTATION,
  );

  const clone = async (values: BaseValues, operation: FolderOperation) => {
    if (loading) {
      return;
    }

    const v = validationSchema().validateSync(values);
    if (!v) {
      return;
    }

    let castedValues = clearEmptyTotal(cast(values.measurements));

    if (castedValues.length === 0 && operation.unit === OperationUnit.FLAT_RATE) {
      castedValues = [createFlatRateMeasurmentValue()];
    }

    await mutation({
      variables: {
        input: {
          partNoAlias: offerMode === OfferMode.ALIAS_NUMBER ? values.alias : undefined,
          from: operation.id,
          approximate: v.approximate,
          name: v.name,
          description: v.description,
          price: v.price,
          optional: v.optional,
          note: castNote(values.note),
          unit: (v.unit as OperationUnit) || operation.unit,
          measurements: castedValues,
          payment: castPaymentToAddOperationToFolder(v.payment),
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    navigate(offerRoutes.detail.generatePath({ offerId }));
  };

  return {
    error,
    clone,
  };
};

export default useOfferFolderOperationClone;
