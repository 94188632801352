import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { Paper, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { Catalog } from './types';
import CatalogRows, { Props as RowProps } from './CatalogRows';
import DataList from '../../../../../components/layouts/DataList';
import Table, {
  RowHeading,
  TableEmptyState,
  Tbody,
  Th,
  Thead,
  Tr,
} from '../../../../../components/Table';

interface Props extends Pick<RowProps, 'onOperationClick' | 'onCategoryClick'> {
  catalogs: Catalog[];
  emptyText?: string;
  selectedItems?: string[];
  onChangeChoice?: (v: string) => void;
  loading?: boolean;
}

const DEFAULT_EMPTY_TEXT = 'offer.createNewOperation.search.catalogTable.emptyText';

const CatalogsTable: FC<Props> = ({
  catalogs,
  emptyText = DEFAULT_EMPTY_TEXT,
  onChangeChoice,
  loading,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <Paper className="u-padding-xsmall">
      <DataList>
        <Table>
          <Thead>
            <Tr sticky>
              <Th>
                <Text variant="body-large">
                  {t('offer.createNewOperationModal.tab.tlk.catalogTable.ordinalName')}
                </Text>
              </Th>
              <Th>
                <Text variant="body-large">
                  {t('offer.createNewOperationModal.tab.tlk.catalogTable.shortText')}
                </Text>
              </Th>
              <Th>
                <Text variant="body-large">
                  {t('offer.createNewOperationModal.tab.tlk.catalogTable.unit')}
                </Text>
              </Th>
              <Th>
                <Text variant="body-large">
                  {t('offer.createNewOperationModal.tab.tlk.catalogTable.ep')}
                </Text>
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {loading && (
              <TableEmptyState colSpan={4}>
                <LayoutLoading />
              </TableEmptyState>
            )}
            {!loading && !catalogs.length && (
              <TableEmptyState colSpan={4}>
                <Text variant="body-large" color="gray-500">
                  {t(emptyText as ParseKeys<'translation'>) as string}
                </Text>
              </TableEmptyState>
            )}
            {!loading &&
              catalogs.map((catalog) => {
                return (
                  <Fragment key={catalog.id}>
                    <RowHeading colSpan={4}>{catalog.name}</RowHeading>
                    <CatalogRows
                      onChangeChoice={onChangeChoice}
                      categories={catalog.categories}
                      {...otherProps}
                    />
                  </Fragment>
                );
              })}
          </Tbody>
        </Table>
      </DataList>
    </Paper>
  );
};

export default CatalogsTable;
