import { Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface ObstructionArchiveModalTitleProps {
  obstructionName: string;
  projectDescription: string;
}

export default function ObstructionArchiveModalTitle({
  projectDescription,
  obstructionName,
}: ObstructionArchiveModalTitleProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body-medium" component="div" color="muted">
        {projectDescription}
      </Text>
      <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
        {t('obstructionArchive.modalTitle', { obstructionName })}
      </Text>
    </>
  );
}
