import { ChangeEvent, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Flex,
  FormHelperText,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useMutation } from '@apollo/client';
import { FormikFormField } from '@hs-baumappe/forms';
import stylex from '@hs-baumappe/legacy-stylex';
import cx from 'classnames';
import { AcceptanceReportFormValues, Defect } from '../AcceptanceReportFormValues';
import DefectSectionUploadImageItem from './DefectSectionUploadImageItem';
import { formatDateToDateString } from '../../../../../utils/date';
import AcceptanceReportFormSection from '../components/AcceptanceReportFormSection';
import { createEmptyImageWithLabel } from '../../../../../containers/forms/ImageUploadWithLabel';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../../../components/DriveImageGalleryContainer';
import AcceptanceReportConvertImageAttachmentsMutation from './graphql/AcceptanceReportConvertImageAttachments.mutation';
import ErrorState from '../../../../../containers/ErrorState';

const NEXT_DAY = 24 * 60 * 60 * 1000;

interface DefectSectionProps {
  projectId: string;
}

export default function DefectSection({ projectId }: DefectSectionProps): JSX.Element {
  const { t } = useTranslation();
  const [startingIndex, setStartingIndex] = useState(0);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { values, errors, setFieldValue } = useFormikContext<AcceptanceReportFormValues>();
  const [convertDriveImagesMutation, { loading, error }] = useMutation(
    AcceptanceReportConvertImageAttachmentsMutation,
  );

  function handleAddAnotherImageButtonClick() {
    setFieldValue('defectMeta', [...values.defectMeta, createEmptyImageWithLabel()]);
  }

  function removeImageItemByIndex(index: number) {
    const defectMeta = [...values.defectMeta];

    defectMeta.splice(index, 1);

    if (defectMeta.length === 0) {
      defectMeta.push(createEmptyImageWithLabel());
    }

    setFieldValue('defectMeta', defectMeta);
  }

  function handleAddImagesFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (!files) {
      return;
    }

    const nextDefectMeta = [...values.defectMeta];
    const [firstFile, ...restFiles] = Array.from(files);

    if (nextDefectMeta[startingIndex]) {
      nextDefectMeta[startingIndex].file = firstFile;
    }

    const newDefects = restFiles.map((file) => createEmptyImageWithLabel({ file }));

    nextDefectMeta.splice(startingIndex + 1, 0, ...newDefects);

    setFieldValue('defectMeta', nextDefectMeta);

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }

  function handleUploadImageButtonClick(index: number) {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setStartingIndex(index);
    }
  }

  function handleSelectImageButtonClick(index: number) {
    setStartingIndex(index);
    setDriveImageGalleryOpen(true);
  }

  async function convertImages(selectedDocuments: string[]) {
    const { data } = await convertDriveImagesMutation({
      variables: {
        input: selectedDocuments,
      },
    });

    if (!data) {
      return;
    }

    return data.convertCustomFilesToImages;
  }

  async function handleDocumentsSelected(selectedDocuments: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    const documentIds = selectedDocuments.map(({ id }) => id);
    const convertedImages = await convertImages(documentIds);

    if (!convertedImages) {
      return;
    }

    const newDefects = convertedImages.map((converted, index) => {
      const selectedDocument = selectedDocuments[index];

      return createEmptyImageWithLabel({
        imageId: converted.id,
        label: selectedDocument?.name || '',
        imageUrl: converted.url,
        imageThumbnailUrl: converted.url,
      });
    });

    const nextDefectMeta = [...values.defectMeta];
    const [firstNewDefect, ...restDefects] = newDefects;

    if (nextDefectMeta[startingIndex]) {
      nextDefectMeta[startingIndex] = firstNewDefect;
    }

    nextDefectMeta.splice(startingIndex + 1, 0, ...restDefects);
    setFieldValue('defectMeta', nextDefectMeta);
  }

  const showRemoveButton = values.defectMeta.length > 1;

  const date = new Date(values.date);
  const tomorrow = new Date(date.getTime() + NEXT_DAY);

  return (
    <AcceptanceReportFormSection title={t('acceptanceReport.defect.title')}>
      {error && <ErrorState error={error} />}

      <RadioGroup
        value={values.defect}
        onChange={(value) => setFieldValue('defect', value)}
        className={cx(stylex.create({ marginTop: 'medium', gap: 'small-3x' }))}
      >
        <Flex>
          <Radio
            value={Defect.NOT_DEFECTIVE}
            label={t('acceptanceReport.defect.notDefect.label')}
            disableExtraPressableArea
          />
        </Flex>

        <Flex>
          <Radio
            value={Defect.HAS_DEFECT}
            label={t('acceptanceReport.defect.hasDefect.label')}
            disableExtraPressableArea
          />
        </Flex>
      </RadioGroup>

      {values.defect === Defect.HAS_DEFECT && (
        <>
          <div className="u-margin-top">
            {values.defectMeta.map((meta, index) => {
              return (
                <DefectSectionUploadImageItem
                  name={`defectMeta[${index}]`}
                  disabled={values.defect === Defect.NOT_DEFECTIVE}
                  key={meta.orderId}
                  showRemoveButton={showRemoveButton}
                  onRemoveButtonClick={() => removeImageItemByIndex(index)}
                  onUploadImageButtonClick={() => handleUploadImageButtonClick(index)}
                  onSelectImageButtonClick={() => handleSelectImageButtonClick(index)}
                />
              );
            })}
            <div className="row u-margin-top">
              <div className="col col--sm-6">
                <Button
                  onClick={handleAddAnotherImageButtonClick}
                  type="button"
                  endIcon={<Icon name="add" />}
                  fullWidth
                  loading={loading}
                >
                  {t('acceptanceReport.defect.addImageButton')}
                </Button>
              </div>
            </div>
          </div>

          <Text component="div" className="u-margin-top">
            {t('acceptanceReport.defect.referenceInfo')}
          </Text>
          <div className="row u-margin-top-small">
            <div className="col col--sm-6">
              <FormikFormField
                name="defectReferenceFile"
                placeholder={t('acceptanceReport.defect.reference.placeholder')}
              />
            </div>
          </div>

          <Stack gap="small-3x" stylex={{ marginTop: 'medium' }}>
            <Flex>
              <Checkbox
                name="defectConfirmation"
                label={t('acceptanceReport.defect.confirmation.label')}
                checked={values.defectConfirmation}
                onChange={(event) =>
                  setFieldValue('defectConfirmation', event.currentTarget.checked)
                }
                disableExtraPressableArea
              />
            </Flex>

            {errors.defectConfirmation && (
              <FormHelperText error>{errors.defectConfirmation}</FormHelperText>
            )}
          </Stack>

          {!values.defectConfirmation && (
            <div className="row u-margin-top-xsmall">
              <div className="col col--sm-6 u-color-warning">
                <div className="u-display-flex u-align-items-center">
                  <Icon name="warning" size={24} />
                  <Text variant="title-small" className="u-margin-left-xsmall">
                    {t('acceptanceReport.defect.notAgreedWarning.title')}
                  </Text>
                </div>

                <Text variant="body-small" className="u-margin-top-xsmall">
                  {t('acceptanceReport.defect.notAgreedWarning.description')}
                </Text>
              </div>
            </div>
          )}

          <Text component="div" color="primary" className="u-margin-top">
            {t('acceptanceReport.defect.dueDate.info')}
          </Text>

          <div className="row u-margin-top-small">
            <div className="col col--sm-6">
              <FormikFormField
                type="date"
                name="defectDueDate"
                label={t('acceptanceReport.defect.dueDate.label')}
                placeholder={t('acceptanceReport.defect.dueDate.placeholder')}
                min={formatDateToDateString(tomorrow)}
              />
            </div>
            <div className="col col--sm-6">
              <FormikFormField
                name="defectNote"
                label={t('acceptanceReport.defect.note.label')}
                placeholder={t('acceptanceReport.defect.note.placeholder')}
              />
            </div>
          </div>
        </>
      )}

      <input
        ref={fileInputRef}
        type="file"
        className="u-visually-hidden"
        accept="image/jpeg, image/png, image/jpg"
        multiple
        onChange={handleAddImagesFileInputChange}
      />

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsSelected}
      />
    </AcceptanceReportFormSection>
  );
}
