import { useViewer } from '@hs-baumappe/web-auth';
import { ApolloError, useQuery } from '@apollo/client';
import RiskAssessmentDetailQuery from '../../graphql/RiskAssessmentDetail.query';
import { RiskAssessment } from '../../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../../graphql/__generated__/RiskAssessmentProject.fragment';
import { RiskAssessmentScopes } from '../../RiskAssessmentDetail/types';

interface UseRiskAssessmentDataResponse {
  riskAssessment: RiskAssessment;
  project: RiskAssessmentProject;
  scopes: RiskAssessmentScopes;
}

interface UseRiskAssessmentResponse {
  data?: UseRiskAssessmentDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessment(riskAssessmentId: string): UseRiskAssessmentResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(RiskAssessmentDetailQuery, {
    variables: {
      id: riskAssessmentId,
    },
  });

  const riskAssessment = data?.riskAssessment;
  const project = data?.riskAssessment.project;
  const scopes = viewer?.scopes;

  return {
    data: riskAssessment && project && scopes ? { riskAssessment, project, scopes } : undefined,
    loading,
    error,
  };
}
