import yup, { InferObjectToYupShape } from '../../../../../yup';
import { CommentsFormValues } from './Comments/Comments';
import { RecommendationScoreFormValues } from './RecommendationScore/RecommendationScore';
import { SpecificScoreFormValues } from './SpecificScore/utils';
import { SignatureFormValues } from './Signature/Signature';

export type QsstSignFormValues = SpecificScoreFormValues &
  RecommendationScoreFormValues &
  CommentsFormValues & {
    clientSignature: SignatureFormValues;
  };

export const initialQsstSignFormValues: QsstSignFormValues = {
  specificScore: {},
  clientSignature: {
    name: '',
    date: new Date().toLocaleDateString(),
    signature: '',
  },
};

export const qsstSignFormValuesValidationSchema = yup
  .object()
  .shape<InferObjectToYupShape<QsstSignFormValues>>({
    specificScore: yup.object({
      quality: yup.number().required(),
      cleanliness: yup.number().required(),
      service: yup.number().required(),
      timeliness: yup.number().required(),
    }),
    recommendationScore: yup.number().required(),

    clientComments: yup.string(),
    clientSignature: yup.object({
      name: yup.string().required(),
      signature: yup.string().required(),
    }),
  });
