import { FormikErrors } from 'formik/dist/types';
import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { createPath, useNavigate } from 'react-router-dom';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import GetConstructionNoteDetailQuery from '../graphql/GetConstructionNoteDetail.query';
import ConstructionNoteArchiveMutation from './graphql/ConstructionNoteArchive.mutation';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import { getErrorsFromServerError } from '../../../apollo/errors';
import { DriveDocumentArchiveInput } from '../../../globalTypes';
import { ArchiveFormValues } from '../../../containers/ArchiveForm';

interface UseArchiveConstructionNoteParams {
  constructionNoteId: string;
}

interface UseArchiveConstructionNoteResponse {
  archive: (values: ArchiveFormValues, formik: FormikHelpers<ArchiveFormValues>) => void;
  error?: ApolloError;
  loading: boolean;
}

export default function useArchiveConstructionNote({
  constructionNoteId,
}: UseArchiveConstructionNoteParams): UseArchiveConstructionNoteResponse {
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(ConstructionNoteArchiveMutation);
  const { setSapCredentials } = useSapCredentials();

  const [error, setError] = useState<ApolloError | undefined>();

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    if (loading) {
      return;
    }

    try {
      const { data } = await mutate({
        variables: {
          input: {
            id: constructionNoteId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
        refetchQueries: [
          { query: GetConstructionNoteDetailQuery, variables: { id: constructionNoteId } },
        ],
      });

      if (!data) {
        return;
      }

      setSapCredentials({ username: values.username, password: values.password });

      const searchParams = new URLSearchParams({
        archive: 'true',
      });

      navigate(
        createPath({
          pathname: constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return { error, loading, archive };
}
