import { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';
import { Box, Checkbox, FormHelperText, Stack, Text, Textarea } from '@hs-baumappe/legacy-ui';
import { ConcernFormValues, Misgivings } from '../../ConcernForm.types';

export default function ExecutionFormSection(): JSX.Element {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext<ConcernFormValues>();

  const hasExecution = values.misgivings.includes(Misgivings.EXECUTION);

  function handleExecutionToggle(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.currentTarget;

    const misgivings = new Set(values.misgivings);
    if (checked) {
      misgivings.add(Misgivings.EXECUTION);
    } else {
      misgivings.delete(Misgivings.EXECUTION);
    }

    setFieldValue('misgivings', Array.from(misgivings));
    setFieldValue('execution', '');
  }

  return (
    <Box stylex={{ marginTop: 'medium' }}>
      <Stack gap="small-3x">
        <div>
          <Checkbox
            name="misgivings"
            label={
              <Trans
                i18nKey="concern.create.form.execution.label"
                components={[<strong key="concern-create-form-execution-label-strong" />]}
              />
            }
            variant="filled"
            value={Misgivings.EXECUTION}
            checked={hasExecution}
            onChange={handleExecutionToggle}
            disableExtraPressableArea
          />
        </div>

        {errors.misgivings && <FormHelperText color="error">{errors.misgivings}</FormHelperText>}
      </Stack>

      {hasExecution && (
        <Stack gap="small-x" stylex={{ marginLeft: 'large', marginTop: 'small-x' }}>
          <Text component="div" color="gray-700">
            {t('concern.create.form.execution.info')}
          </Text>

          <FormikFormField<typeof Textarea>
            control={Textarea}
            name="execution"
            label={t('concern.create.form.execution.description.label')}
            placeholder={t('concern.create.form.execution.description.placeholder')}
            minRows={4}
            maxRows={12}
          />
        </Stack>
      )}
    </Box>
  );
}
