import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { SignatureFormValues } from '@hs-baumappe/forms';
import ObstructionSignMutation from '../../graphql/ObstructionSign.mutation';
import b64toBlob from '../../../../../utils/b64toBlob';
import obstructionRoutes from '../../../obstructionRoutes';

interface UseObstructionSignResponse {
  sign: (values: SignatureFormValues) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useObstructionSign(obstructionId: string): UseObstructionSignResponse {
  const navigate = useNavigate();
  const [mutate, { loading, error }] = useMutation(ObstructionSignMutation);

  async function sign(values: SignatureFormValues) {
    try {
      await mutate({
        variables: {
          input: {
            fullName: values.name,
            image: b64toBlob(values.signature.replace(/^[^,]+,/, ''), 'image/png'),
            documentId: obstructionId,
          },
        },
      });

      navigate(obstructionRoutes.detail.generatePath({ obstructionId }));
    } catch (e) {}
  }

  return {
    sign,
    loading,
    error,
  };
}
