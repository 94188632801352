import { gql, TypedDocumentNode } from '@apollo/client';
import ConcernFragment from '../../../graphql/Concern.fragment';
import ConcernProjectFragment from '../../../graphql/ConcernProject.fragment';
import { ConcernDetail, ConcernDetailVariables } from './__generated__/ConcernDetail.query';

const ConcernDetailQuery: TypedDocumentNode<ConcernDetail, ConcernDetailVariables> = gql`
  query ConcernDetail($concernId: ID!) {
    concern(id: $concernId) {
      ...Concern

      project {
        ...ConcernProject
      }
    }
  }

  ${ConcernFragment}
  ${ConcernProjectFragment}
`;

export default ConcernDetailQuery;
