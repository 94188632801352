import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider } from 'formik';
import { Box, Button, Details, Icon, Summary, Text, Textarea } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';

import { defaultValues, validationSchema } from './form';
import Input from '../../../../../components/form/Control/Input';
import FieldLayout from '../../../../../components/formik/FieldLayout';
import NumberInput from '../../../../../components/form/Control/NumberInput';
import FormFeedback from '../../../../../components/form/FormFeedback';
import { BaseValues } from './values';
import SidebarLayout from '../../../../../components/layouts/SidebarLayout';
import RichTextEditor from '../../../../../components/form/Control/RichTextEditor';

import { OfferMode, OperationUnit } from '../../../../../globalTypes';
import useForm from '../../../../../hooks/useForm';
import PaymentForm from '../../PaymentForm';
import MeasurementsSections from '../../../measurement/MeasurementsSection/MeasurementsSections';
import Choice from '../../../../../components/form/Choice';

export interface CloneOperationFormProps {
  framework?: boolean;
  offerModeSystemNumber: boolean;
  initialValues?: Partial<BaseValues>;
  operationUnit?: OperationUnit;
  onClickAliasRightIcon: () => void;
  onSubmit: (values: BaseValues) => void;
}

const CloneOperationForm: FC<CloneOperationFormProps> = ({
  framework,
  onSubmit,
  onClickAliasRightIcon,
  offerModeSystemNumber,
  initialValues,
  operationUnit,
}) => {
  const { t } = useTranslation();
  const { formik, errorFeedback } = useForm<BaseValues>({
    onSubmit,
    validationSchema: validationSchema(
      offerModeSystemNumber ? OfferMode.SYSTEM_NUMBER : OfferMode.ALIAS_NUMBER,
    ),
    initialValues: defaultValues(initialValues),
  });

  const [priceActive, setPriceActive] = useState(false);
  const { values, handleSubmit, setFieldValue } = formik;

  const handleUnitTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) return;
    setFieldValue('unit', e.currentTarget.value);
  };

  const isInitialCollapsed = initialValues ? !initialValues.note : true;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col col--sm-8">
            <div className="row">
              {!framework && (
                <div className="col col--sm-6">
                  <FieldLayout
                    name="alias"
                    label={t('offer.alias.label')}
                    labelHtmlFor="input-name-of-the-create-alias"
                    className="u-margin-bottom-xsmall u-width-100%"
                    feedback={{ invalid: errorFeedback('alias') }}
                  >
                    {(props) => (
                      <Input
                        {...props}
                        id="input-name-of-the-alias"
                        disabled={offerModeSystemNumber}
                        contentEditable={offerModeSystemNumber}
                        rightIconName={offerModeSystemNumber ? 'info' : undefined}
                        onRightIconClick={offerModeSystemNumber ? onClickAliasRightIcon : undefined}
                      />
                    )}
                  </FieldLayout>
                </div>
              )}
              <div className="col col--sm-6">
                <FieldLayout
                  name="name"
                  label={t('offer.cloneFolderOperationForm.name.label')}
                  labelHtmlFor="input-name-of-the-create-operation"
                  className="u-margin-bottom-xsmall u-width-100%"
                  feedback={{ invalid: errorFeedback('name') }}
                >
                  {(props) => (
                    <Input
                      id="input-name-of-the-create-operation"
                      placeholder={t('offer.cloneFolderOperationForm.name.placeholder')}
                      {...props}
                    />
                  )}
                </FieldLayout>
              </div>
              <div className="col col--sm-6">
                <FieldLayout
                  className="u-margin-bottom-xsmall"
                  name="price"
                  label={t('offer.cloneFolderOperationForm.price.label')}
                  labelHtmlFor="input-price"
                  feedback={{
                    invalid: errorFeedback('price'),
                  }}
                >
                  {(inputProps) =>
                    framework ? (
                      <>
                        <NumberInput
                          {...inputProps}
                          id="input-price"
                          onFocus={() => {
                            setPriceActive(true);
                          }}
                          placeholder={t('offer.cloneFolderOperationForm.price.placeholder')}
                        />
                        {priceActive && !errorFeedback('price') && (
                          <FormFeedback className="u-margin-top-2xsmall" theme="invalid">
                            {t('offer.cloneFolderOperationForm.price.alert')}
                          </FormFeedback>
                        )}
                      </>
                    ) : (
                      <NumberInput
                        id="input-price"
                        placeholder={t('offer.cloneFolderOperationForm.price.placeholder')}
                        {...inputProps}
                      />
                    )
                  }
                </FieldLayout>
              </div>
            </div>

            <Box stylex={{ marginBottom: 'small-3x' }}>
              <FormikFormField
                control={Textarea}
                id="input-description"
                name="description"
                label={t('offer.cloneFolderOperationForm.description.label')}
                placeholder={t('offer.cloneFolderOperationForm.description.placeholder')}
                minRows={4}
                maxRows={12}
              />
            </Box>

            <hr />
            <div className="u-margin-top">
              <Details
                defaultOpen={!isInitialCollapsed}
                renderSummary={({ onClick }) => (
                  <Summary onClick={onClick}>
                    <Text
                      variant="title-small"
                      color="muted"
                      component="h3"
                      className="u-margin-bottom-0"
                    >
                      {t('offer.cloneFolderOperationForm.comment.title')}
                    </Text>
                  </Summary>
                )}
              >
                <FieldLayout
                  name="note"
                  label={t('offer.cloneFolderOperationForm.comment.label')}
                  className="u-margin-top-xsmall"
                  labelHtmlFor="input-kommentar"
                  feedback={{ invalid: errorFeedback('note') }}
                >
                  {(props) => <RichTextEditor id="input-kommentar" enableResizeImage {...props} />}
                </FieldLayout>
              </Details>
            </div>
            <hr />
            <div className="u-margin-top">
              <PaymentForm />
            </div>
            <hr />

            <Text variant="title-small" color="muted">
              {t('offer.cloneFolderOperationForm.measurement.title')}
            </Text>
            <div className="u-margin-top">
              <MeasurementsSections
                operation
                operationUnit={values.unit}
                required={!operationUnit}
                handleChangeProxy={handleUnitTypeChange}
              />
            </div>
          </div>
          <div className="col col--sm-4">
            <SidebarLayout>
              <Button color="primary" fullWidth endIcon={<Icon name="file-copy" />}>
                {t('offer.cloneFolderOperationForm.submitButton')}
              </Button>
              <FieldLayout
                className="u-margin-top"
                name="optional"
                labelHtmlFor="input-optional"
                feedback={{ invalid: errorFeedback('optional') }}
              >
                {(inputProps) => (
                  <Choice
                    id="input-optional"
                    type="checkbox"
                    checked={formik.values.optional}
                    label={t('offer.createNewOperationFolder.optional.label')}
                    {...inputProps}
                  />
                )}
              </FieldLayout>
              <FieldLayout
                className="u-margin-top"
                name="approximate"
                labelHtmlFor="input-ca"
                feedback={{ invalid: errorFeedback('approximate') }}
              >
                {(inputProps) => (
                  <Choice
                    id="input-ca"
                    type="checkbox"
                    checked={formik.values.approximate}
                    label={t('offer.createNewOperationFolder.ca.label')}
                    {...inputProps}
                  />
                )}
              </FieldLayout>
            </SidebarLayout>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default CloneOperationForm;
