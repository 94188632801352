import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import { CustomFileRouteParams } from './CustomFile.route.types';
import { useUpdateDriveDocumentLastUsed } from '../../hooks/useUpdateDriveDocumentLastUsed';
import {
  GetCustomFileDetail,
  GetCustomFileDetailVariables,
} from './graphql/__generated__/GetCustomFileDetail.query';
import GetCustomFileDetailQuery from './graphql/GetCustomFileDetail.query';

export default function CustomFile(): JSX.Element | null {
  const { t } = useTranslation();
  const { documentId } = useParams<CustomFileRouteParams>();
  const { data, loading, error } = useQuery<GetCustomFileDetail, GetCustomFileDetailVariables>(
    GetCustomFileDetailQuery,
    {
      variables: {
        documentId,
      },
    },
  );

  useUpdateDriveDocumentLastUsed({
    id: documentId,
    skip: !data?.customFile,
  });

  useEffect(() => {
    if (!data || loading || error) return;
    if (!data.customFile) return;

    window.location.href = data.customFile.url;
  }, [data, error, loading]);

  if (!data && loading)
    return (
      <LayoutLoading>
        <Text variant="body-large">{t('customFile.download.loading')}</Text>
      </LayoutLoading>
    );
  if (error) return <ErrorRendererPage apolloError={error} />;
  if (!data) return <NotFoundErrorPage />;

  return null;
}
