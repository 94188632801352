import { Dispatch, SetStateAction } from 'react';
import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { createPath, Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { qsstRoutes } from '../../../qsstRoutes';
import { QsstSendMailMethod } from '../../../QsstSendMail/QsstSendMail';
import { DeleteUploadedQsstButton } from './DeleteUploadedQsstButton/DeleteUploadedQsstButton';
import { DeleteQsstButton } from './DeleteQsstButton/DeleteQsstButton';
import { Qsst } from '../../../graphql/__generated__/Qsst.fragment';
import { QsstScopes } from '../../../Qsst.types';
import { AssignQsstButton } from './AssignQsstButton/AssignQsstButton';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';

interface QsstDetailSidebarBottomProps {
  qsst: Qsst;
  scopes: QsstScopes;
  projectId: string;
  showUploadedPdf: boolean;
  setShowUploadedPdf: Dispatch<SetStateAction<boolean>>;
}

export default function QsstDetailSidebarBottom({
  qsst,
  scopes,
  projectId,
  showUploadedPdf,
  setShowUploadedPdf,
}: QsstDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;
  const sendForSignaturePermitted = scopes.sendQsstDocumentSignatureMail.isPermitted;

  return (
    <Stack gap="small-3x">
      {qsst.viewerCanAssign && <AssignQsstButton qsstId={qsst.id} projectId={projectId} />}

      {qsst.viewerCanUpload && (
        <Button
          component={Link}
          endIcon={<Icon name="cloud-upload" />}
          to={qsstRoutes.manualSign.generatePath({ qsstId: qsst.id })}
        >
          {t('qsst.uploadButton')}
        </Button>
      )}

      {qsst.viewerCanSign && (
        <>
          {sendForSignaturePermitted && (
            <Button
              component={Link}
              color="primary"
              endIcon={<Icon name="border-color" />}
              to={createPath({
                pathname: qsstRoutes.sendEmail.generatePath({ qsstId: qsst.id }),
                search: new URLSearchParams({ method: QsstSendMailMethod.LINK }).toString(),
              })}
            >
              {t('qsst.sign')}
            </Button>
          )}

          <Button
            component={Link}
            color="primary"
            endIcon={<Icon name="place" />}
            to={qsstRoutes.sign.generatePath({ qsstId: qsst.id })}
          >
            {t('qsst.signOnSide')}
          </Button>
        </>
      )}

      {qsst.manuallySignedPdfInfo && (
        <>
          {showUploadedPdf && (
            <Button endIcon={<Icon name="undo" />} onClick={() => setShowUploadedPdf(false)}>
              {t('qsst.showGeneratedQsstButton')}
            </Button>
          )}

          {!showUploadedPdf && (
            <Button endIcon={<Icon name="undo" />} onClick={() => setShowUploadedPdf(true)}>
              {t('qsst.showUploadedQsstButton')}
            </Button>
          )}
        </>
      )}

      {qsst.viewerCanArchive && archiveEnabled && (
        <Button
          component={Link}
          color="primary"
          endIcon={<Icon name="archive" />}
          to={qsstRoutes.archive.generatePath({ qsstId: qsst.id })}
        >
          {t('obstruction.archiveButton')}
        </Button>
      )}

      {qsst.viewerCanRemove && removePermitted && (
        <DeleteQsstButton qsstId={qsst.id} qsstName={qsst.name} projectId={projectId} />
      )}

      {qsst.manuallySignedPdfInfo && qsst.viewerCanRemoveUpload && showUploadedPdf && (
        <DeleteUploadedQsstButton qsstId={qsst.id} qsstName={qsst.name} />
      )}
    </Stack>
  );
}
