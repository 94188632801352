import { TypedDocumentNode, gql } from '@apollo/client';
import { AcceptanceReportLog } from './__generated__/AcceptanceReportLog.fragment';

const AcceptanceReportLogFragment: TypedDocumentNode<AcceptanceReportLog> = gql`
  fragment AcceptanceReportLog on AcceptanceReportLog {
    ... on AcceptanceReportArchivedLog {
      id
      name
      date

      acceptanceReportId

      actorId
      actorFirstName
      actorLastName
    }

    ... on AcceptanceReportCreatedLog {
      id
      name
      date

      acceptanceReportId

      actorId
      actorFirstName
      actorLastName
    }

    ... on AcceptanceReportDeletedLog {
      id
      name
      date

      acceptanceReportId

      actorId
      actorFirstName
      actorLastName
    }

    ... on AcceptanceReportEmailSentLog {
      id
      name
      date

      acceptanceReportId

      actorId
      actorFirstName
      actorLastName
      emails
    }

    ... on AcceptanceReportExternalEmailSentLog {
      id
      name
      date

      acceptanceReportId

      actorId
      actorFirstName
      actorLastName

      emails
    }

    ... on AcceptanceReportExternalLinkExpiredLog {
      id
      name
      date
    }

    ... on AcceptanceReportManuallySignedLog {
      id
      name
      date

      acceptanceReportId

      actorId
      actorFirstName
      actorLastName
    }

    ... on AcceptanceReportNotSignedLog {
      id
      name
      date

      acceptanceReportId

      dayCount
    }

    ... on AcceptanceReportSignedByCustomerLog {
      id
      name
      date
      acceptanceReportId
    }
  }
`;

export default AcceptanceReportLogFragment;
