import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetProjectInformation,
  GetProjectInformationVariables,
} from './__generated__/GetProjectInformation.query';

const GetProjectInformationQuery: TypedDocumentNode<
  GetProjectInformation,
  GetProjectInformationVariables
> = gql`
  query GetProjectInformation($projectId: ID!) {
    project(id: $projectId) {
      id

      name
      projectNumber
      additionalName
      street
      city
      postCode
      addressLine
      date

      isMainProject
      showSubProjectHours
    }
  }
`;

export default GetProjectInformationQuery;
