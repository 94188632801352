import { Route, Routes } from 'react-router-dom';
import DriveDocumentPreviewDetail from './DriveDocumentPreviewDetail';

export default function DriveDocumentPreview(): JSX.Element {
  return (
    <Routes>
      <Route path="*" element={<DriveDocumentPreviewDetail />} />
    </Routes>
  );
}
