import { useCallback, useState } from 'react';
import { createPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer, MissingScopeError, NotFoundError } from '@hs-baumappe/redkit';
import { AlertDialog, Box, Dialog, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import useSendConstructionNoteEmailDetail from './useSendConstructionNoteEmailDetail';
import useSendConstructionNoteEmail from './useSendConstructionNoteEmail';
import ErrorState from '../../../containers/ErrorState';
import EmailForm from '../../../containers/Email';
import { projectsRoutes } from '../../routes';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import {
  ConstructionNoteLocationState,
  ConstructionNoteRouteParams,
} from '../ConstructionNote.route.types';
import { formatBytes } from '../../../utils/number';
import { getConstructionNoteEmailFormInitialValues } from './SendConstructionNoteEmail.utils';

export default function SendConstructionNoteEmail(): JSX.Element {
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const location = useLocation<ConstructionNoteLocationState>();
  const navigate = useNavigate();

  const { constructionNote, loading, error } =
    useSendConstructionNoteEmailDetail(constructionNoteId);
  const {
    sendEmail,
    loading: emailSending,
    error: emailSendError,
  } = useSendConstructionNoteEmail(constructionNoteId);

  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [dirtyFormAlertDialogOpen, setDirtyFormAlertDialogOpen] = useState(false);

  function handleDialogRequestClose() {
    if (formDirty) {
      setDirtyFormAlertDialogOpen(true);
      return;
    }

    setRouteModalOpen(false);
  }

  function handleDocumentIdsValueChange() {
    setRouteModalOpen(false);
  }

  const documents = constructionNote
    ? [
        {
          id: constructionNote.id,
          name: constructionNote.name,
          size:
            typeof constructionNote.driveDocument?.size === 'number'
              ? formatBytes(constructionNote.driveDocument.size)
              : undefined,
        },
      ]
    : undefined;

  const handleDialogClose = useCallback(() => {
    if (error) {
      navigate(projectsRoutes.detail.generatePath({}));
      return;
    }

    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    const isPermittedToSendDocumentAttachment =
      !!viewer?.scopes.sendConstructionNoteDocumentAttachment.isPermitted;

    navigate(
      createPath({
        pathname: constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }),
        search: isPermittedToSendDocumentAttachment ? '?send-mail=true' : undefined,
      }),
    );
  }, [error, location.state, navigate, constructionNoteId, viewer]);

  function renderContent() {
    if (loading && !constructionNote) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!constructionNote) {
      return <NotFoundError />;
    }

    const sendConstructionNoteDocumentAttachment =
      viewer?.scopes.sendConstructionNoteDocumentAttachment;

    if (!sendConstructionNoteDocumentAttachment?.isPermitted) {
      return (
        <MissingScopeError
          missingScopes={sendConstructionNoteDocumentAttachment?.missingScopeNames ?? []}
        />
      );
    }

    if (!viewer) {
      return null;
    }

    return (
      <Box stylex={{ paddingTop: 'large' }}>
        <EmailForm
          projectId={constructionNote.project.id}
          initialValues={getConstructionNoteEmailFormInitialValues(constructionNote, viewer, t)}
          documents={documents}
          submitButtonText={t('sendConstructionNoteEmailForm.submitButton')}
          loading={emailSending}
          onSubmit={sendEmail}
          onDocumentIdsValueChange={handleDocumentIdsValueChange}
          onDirtyStateChange={setFormDirty}
        />
      </Box>
    );
  }

  return (
    <Dialog
      open={routeModalOpen}
      onClose={handleDialogClose}
      onRequestClose={handleDialogRequestClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {emailSendError && <ErrorState error={emailSendError} />}

      <Box className="container" stylex={{ width: '100%', paddingEnds: 'large' }}>
        <Flex alignItems="center">
          {constructionNote && (
            <Text
              variant="title-small"
              component="h2"
              stylex={{ marginBottom: '0', flexGrow: '1' }}
            >
              {constructionNote.name}
            </Text>
          )}
          <IconButton
            stylex={{ marginLeft: 'auto' }}
            icon={<Icon name="close" />}
            onClick={handleDialogRequestClose}
          />
        </Flex>

        <Box>{renderContent()}</Box>
      </Box>

      <AlertDialog
        open={dirtyFormAlertDialogOpen}
        title={t('sendConstructionNoteEmailForm.cancelModal.title')}
        description={t('sendConstructionNoteEmailForm.cancelModal.message')}
        confirmButtonText={t('sendConstructionNoteEmailForm.cancelModal.submit')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('sendConstructionNoteEmailForm.cancelModal.dismiss')}
        onConfirm={() => {
          setDirtyFormAlertDialogOpen(false);
          setRouteModalOpen(false);
        }}
        onCancel={() => setDirtyFormAlertDialogOpen(false)}
        onRequestClose={() => setDirtyFormAlertDialogOpen(false)}
        destructive
      />
    </Dialog>
  );
}
