import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@hs-baumappe/legacy-ui';
import useCanvasAlert from '../../hooks/useCanvasAlert';
import useSelectedTool from '../../hooks/useSelectedTool';
import useCanvasAddImage from '../../hooks/useCanvasAddImage';
import ImageToolMenu from './components/ImageToolMenu';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../../components/DriveImageGalleryContainer';
import {
  CONSTRUCTION_NOTE_UNSUPPORTED_IMAGE_TYPES,
  CONSTRUCTION_NOTE_SUPPORTED_IMAGE_TYPES,
} from '../../constructionNoteMimeTypes';

interface ImageToolProps {
  projectId: string;
}

export default function ImageTool({ projectId }: ImageToolProps): JSX.Element {
  const { t } = useTranslation();
  const { setAlert } = useCanvasAlert();
  const { setSelectedTool } = useSelectedTool();
  const { loading, error, addFromFile, addFromURL } = useCanvasAddImage();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState(false);

  useEffect(() => {
    if (error) {
      if (error === 'FileSizeError') {
        setAlert(t('constructionNote.imageTool.sizeError'), 'warning');
        return;
      }

      setAlert(t('constructionNote.imageTool.error'), 'warning');
    }
  }, [error, setAlert, t]);

  function handleUploadImageButtonClick() {
    if (!fileInputRef.current) {
      return;
    }

    setSelectedTool('image');
    fileInputRef.current.click();
  }

  async function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (!files) {
      return;
    }

    const file = files[0];

    event.target.value = '';

    await addFromFile(file);
  }

  async function handleDocumentsSelected(documents: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    await Promise.all(documents.map((document) => addFromURL(document.download?.url || '')));
  }

  return (
    <>
      <ImageToolMenu
        onSelectImageButtonClick={() => setDriveImageGalleryOpen(true)}
        onUploadImageButtonClick={handleUploadImageButtonClick}
        onMenuButtonClick={() => setSelectedTool('image')}
        loading={loading}
      />

      <VisuallyHidden
        elementRef={fileInputRef}
        component="input"
        type="file"
        accept={CONSTRUCTION_NOTE_SUPPORTED_IMAGE_TYPES.join(', ')}
        onChange={handleFileChange}
      />

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        excludedMimeTypes={CONSTRUCTION_NOTE_UNSUPPORTED_IMAGE_TYPES}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsSelected}
      />
    </>
  );
}
