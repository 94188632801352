import { gql, TypedDocumentNode } from '@apollo/client';
import ObstructionFragment from '../../../graphql/Obstruction.fragment';
import { ObstructionSave, ObstructionSaveVariables } from './__generated__/ObstructionSaveMutation';

const ObstructionSaveMutation: TypedDocumentNode<ObstructionSave, ObstructionSaveVariables> = gql`
  mutation ObstructionSave($input: ObstructionSaveInput!) {
    obstructionSave(input: $input) {
      ...Obstruction
    }
  }

  ${ObstructionFragment}
`;

export default ObstructionSaveMutation;
