import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useDriveDocumentTagDetails from '../../../hooks/useDriveDocumentTagDetails';
import useObstructionArchive from './hooks/useObstructionArchive';
import RouteDialog from '../../../containers/RouteDialog';
import ObstructionArchiveModalTitle from './components/ObstructionArchiveModalTitle';
import ErrorState from '../../../containers/ErrorState';
import ArchiveForm from '../../../containers/ArchiveForm';
import { Obstruction } from '../graphql/__generated__/Obstruction.fragment';
import { ObstructionProject } from '../graphql/__generated__/ObstructionProject.fragment';
import obstructionRoutes from '../obstructionRoutes';
import { ObstructionLocationState } from '../obstruction.route.types';
import { formatBytes } from '../../../utils/number';
import { LaunchDarklyFlagSet } from '../../../launchdarkly/launchDarklyFlagSet';

interface ObstructionArchiveProps {
  obstruction: Obstruction;
  project: ObstructionProject;
}

export default function ObstructionArchive({
  obstruction,
  project,
}: ObstructionArchiveProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const location = useLocation<ObstructionLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(obstruction.id);
  const { archive, loading: archiving, error } = useObstructionArchive(obstruction.id);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(obstructionRoutes.detail.generatePath({ obstructionId: obstruction.id }));
  }, [navigate, obstruction.id, location.state]);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  if (!obstruction.viewerCanArchive || !archiveEnabled) {
    return (
      <Navigate
        to={obstructionRoutes.detail.generatePath({ obstructionId: obstruction.id })}
        replace
      />
    );
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = [
    {
      id: obstruction.id,
      name: obstruction.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <ObstructionArchiveModalTitle
          projectDescription={project.description}
          obstructionName={obstruction.name}
        />
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('obstructionArchiveForm.description')}</Text>}
        rightSection={<Text variant="body-small">{t('obstructionArchiveForm.infoText')}</Text>}
        initialValues={{
          username: sapCredentials?.username,
          password: sapCredentials?.password,
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
