import { Route, Routes, useLocation } from 'react-router-dom';
import ConstructionNoteEditTitle from './ConstructionNoteEditTitle';
import SendConstructionNoteEmail from './SendConstructionNoteEmail';
import { CanvasProvider } from './context/CanvasContext';
import { SelectedToolContextProvider } from './context/SelectedToolContext';
import ConstructionNoteArchive from './ConstructionNoteArchive';
import { CanvasAlertProvider } from './context/CanvasAlertContext';
import { ConstructionNoteLocationState } from './ConstructionNote.route.types';
import ConstructionNoteDetail from './ConstructionNoteDetail';
import ConstructionNoteEdit from './ConstructionNoteEdit';

export default function ConstructionNote(): JSX.Element {
  const location = useLocation<ConstructionNoteLocationState>();
  const backgroundLocation = location.state && location.state.backgroundLocation;

  return (
    <CanvasProvider>
      <SelectedToolContextProvider>
        <CanvasAlertProvider>
          <Routes location={backgroundLocation || location}>
            <Route path="edit" element={<ConstructionNoteEdit />} />
            <Route path="*" element={<ConstructionNoteDetail />} />
          </Routes>

          <Routes>
            <Route path="edit-title" element={<ConstructionNoteEditTitle />} />
            <Route path="send-email" element={<SendConstructionNoteEmail />} />
            <Route path="archive" element={<ConstructionNoteArchive />} />
          </Routes>
        </CanvasAlertProvider>
      </SelectedToolContextProvider>
    </CanvasProvider>
  );
}
