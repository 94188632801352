import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CLONE_OPERATION_MUTATION = gql`
  mutation CloneOperation($input: CloneOperationInput!) {
    cloneOperation(input: $input) {
      id
    }
  }
`;
