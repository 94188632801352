import { gql, TypedDocumentNode } from '@apollo/client';
import { ConcernSignDetail } from './__generated__/ConcernSignDetail.query';

const ConcernSignDetailQuery: TypedDocumentNode<ConcernSignDetail> = gql`
  query ConcernSignDetail {
    viewer {
      signature
    }
  }
`;

export default ConcernSignDetailQuery;
