import { ElementType, ComponentPropsWithoutRef, ReactNode } from 'react';
import cx from 'classnames';
import { Text } from '@hs-baumappe/legacy-ui';

import styles from './CardButton.module.scss';

export const DEFAULT_ELEMENT = 'button';

interface CardButtonOwnProps {
  title: ReactNode;
  imageSrc: JSX.IntrinsicElements['img']['src'];
  cornerBadgeText?: ReactNode;
  cornerBadgeColor?: 'default' | 'primary';
  disabled?: boolean;
}

interface WithComponent<T> extends CardButtonOwnProps {
  component?: T;
}

export type CardButtonProps<T extends ElementType = typeof DEFAULT_ELEMENT> = Omit<
  ComponentPropsWithoutRef<T>,
  'title'
> &
  WithComponent<T>;

export default function CardButton<T extends ElementType = typeof DEFAULT_ELEMENT>({
  className,
  title,
  imageSrc,
  cornerBadgeText,
  cornerBadgeColor = 'default',
  disabled,
  component,
  ...otherProps
}: CardButtonProps<T>): JSX.Element {
  const Component = component || DEFAULT_ELEMENT;

  const disabledProp =
    Component === 'button' ? { disabled: disabled } : { 'aria-disabled': disabled };

  return (
    <Component className={cx(styles['card-button'], className)} {...otherProps} {...disabledProp}>
      <img className={styles['card-button__image']} src={imageSrc} alt="" aria-hidden="true" />
      <Text className={styles['card-button__title']} variant="label-medium">
        {title}
      </Text>
      {cornerBadgeText && (
        <Text
          component="div"
          variant="label-medium"
          className={cx(
            styles['card-button__corner-badge'],
            styles[`card-button__corner-badge--color-${cornerBadgeColor}`],
          )}
        >
          {cornerBadgeText}
        </Text>
      )}
    </Component>
  );
}
