import { Fragment, FC } from 'react';
import { CatalogCategory, CatalogOperation } from './types';
import CatalogRow from './CatalogRow';

export interface Props {
  categories: CatalogCategory[];
  onOperationClick?: (operation: CatalogOperation) => void;
  onCategoryClick?: (category: CatalogCategory) => void;
  selectedItems?: string[];
  onChangeChoice?: (v: string) => void;
}

const CatalogRows: FC<Props> = ({ categories, selectedItems, onChangeChoice, ...otherProps }) => {
  const { onCategoryClick, onOperationClick } = otherProps;
  const categoryClickHandler = (category: CatalogCategory) => {
    if (!onCategoryClick) return undefined;

    return () => {
      onCategoryClick(category);
    };
  };

  const operationClickHandler = (operation: CatalogOperation) => {
    if (!onOperationClick) return undefined;

    return () => {
      onOperationClick(operation);
    };
  };

  return (
    <>
      {categories.map((category) => (
        <Fragment key={category.id}>
          <CatalogRow item={category} onClick={categoryClickHandler(category)} />
          <CatalogRows
            categories={category.subCategories}
            onChangeChoice={onChangeChoice}
            selectedItems={selectedItems}
            {...otherProps}
          />
          {category.operations.map((operation) => (
            <CatalogRow
              key={operation.id}
              item={operation}
              selectedItems={selectedItems}
              onChangeChoice={onChangeChoice}
              onClick={operationClickHandler(operation)}
            />
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default CatalogRows;
