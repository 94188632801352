import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { AppHeader, LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';
import useConstructionNoteDetail from '../hooks/useConstructionNoteDetail';
import { useUpdateDriveDocumentLastUsed } from '../../../hooks/useUpdateDriveDocumentLastUsed';
import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import { useConstructionNoteDetailAlerts } from './useConstructionNoteDetailAlerts';
import DocumentDetailBreadcrumbs from '../../../components/DocumentDetailBreadcrumbs';
import ConstructionNoteDetailFreeDrawingNoteEditor from './ConstructionNoteDetailFreeDrawingNoteEditor';
import routes from '../../routes';
import { constructionNoteRoutes } from '../constructionNoteRoutes';
import {
  ConstructionNoteLocationState,
  ConstructionNoteRouteParams,
} from '../ConstructionNote.route.types';
import { ConstructionNoteStatus } from '../../../globalTypes';

function ConstructionNoteDetail(): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { constructionNoteId } = useParams<ConstructionNoteRouteParams>();
  const location = useLocation<ConstructionNoteLocationState>();
  const navigate = useNavigate();

  const { loading, data, error } = useConstructionNoteDetail(constructionNoteId);
  const { alerts } = useConstructionNoteDetailAlerts(data?.constructionNote);
  const { parentFolders } = useDriveDocumentParentFolders(constructionNoteId);

  useUpdateDriveDocumentLastUsed({
    id: constructionNoteId,
    skip: !data?.constructionNote,
  });

  if (loading && !data) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data?.constructionNote) {
    return <NotFoundErrorPage />;
  }

  const { constructionNote } = data;
  const {
    project: { id: projectId },
  } = constructionNote;

  function handleEditTitleClick() {
    navigate(
      constructionNoteRoutes.editTitle.generatePath({
        constructionNoteId: constructionNote.id,
      }),
      {
        state: {
          backgroundLocation: location,
        } as ConstructionNoteLocationState,
      },
    );
  }

  function renderTitle(): ReactNode {
    return (
      <Box>
        <Flex alignItems="center" gap="small-4x">
          <Text component="h1" variant="body-large" color="muted" stylex={{ marginBottom: '0' }}>
            {constructionNote.name}
          </Text>

          {constructionNote.viewerCanRename && (
            <IconButton icon={<Icon name="create" />} onClick={handleEditTitleClick} />
          )}
        </Flex>

        <Text
          variant="label-large"
          color={constructionNote.status === ConstructionNoteStatus.ARCHIVED ? 'success' : 'muted'}
        >
          {t('constructionNote.status.title', { status: t(constructionNote.status) })}
        </Text>
      </Box>
    );
  }

  return (
    <Box className="container" stylex={{ paddingEnds: 'large' }}>
      <DocumentDetailBreadcrumbs
        projectId={constructionNote.project.id}
        projectNumber={constructionNote.project.projectNumber}
        parentFolders={parentFolders}
      />

      <AppHeader
        title={renderTitle()}
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />

      <Box className="o-construction-note-editor-container" stylex={{ marginTop: 'large-x' }}>
        {alerts}

        <ConstructionNoteDetailFreeDrawingNoteEditor
          constructionNote={constructionNote}
          projectId={projectId}
        />
      </Box>
    </Box>
  );
}

export default ConstructionNoteDetail;
