import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import RemoveDocument from '../../../../Project/document/RemoveDocument';
import documentRoutes from '../../../documentRoutes';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';
import { CustomFileDocument } from '../../../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentScopes } from '../../../Document.types';
import { DocumentType } from '../../../../../globalTypes';
import { createDocumentLocation } from '../../../Document.utils';

interface DocumentDetailSidebarBottomProps {
  document: CustomFileDocument;
  scopes: CustomFileDocumentScopes;
  onRemoveSuccess: () => void;
}

export default function DocumentDetailSidebarBottom({
  document,
  scopes,
  onRemoveSuccess,
}: DocumentDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;

  return (
    <Stack gap="small-3x">
      {document.viewerCanArchive && archiveEnabled && (
        <Button
          className="qa-custom-file-document-archive"
          component={Link}
          to={documentRoutes.archive.generatePath({ documentId: document.id })}
          state={createDocumentLocation(location)}
          color="primary"
          endIcon={<Icon name="archive" />}
        >
          {t('customFile.documentDetail.sidebar.actions.archiveButtonText')}
        </Button>
      )}

      {document.viewerCanRemove && removePermitted && (
        <RemoveDocument
          document={{
            name: document.name,
            type: DocumentType.CUSTOM_FILE,
            id: document.id,
          }}
          onRemoveSuccess={onRemoveSuccess}
          renderTrigger={(triggerProps) => (
            <Button
              color="error"
              variant="text"
              className="qa-custom-file-document-remove"
              {...triggerProps}
            >
              {t('customFile.documentDetail.sidebar.actions.removeButtonText')}
            </Button>
          )}
        />
      )}
    </Stack>
  );
}
