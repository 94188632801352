import { gql, TypedDocumentNode } from '@apollo/client';
import ObstructionFragment from '../../graphql/Obstruction.fragment';
import {
  ObstructionSign,
  ObstructionSignVariables,
} from './__generated__/ObstructionSign.mutation';

const ObstructionSignMutation: TypedDocumentNode<ObstructionSign, ObstructionSignVariables> = gql`
  mutation ObstructionSign($input: ObstructionSignInput!) {
    obstructionSign(input: $input) {
      ...Obstruction
    }
  }

  ${ObstructionFragment}
`;

export default ObstructionSignMutation;
