import cx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import stylex from '@hs-baumappe/legacy-stylex';
import { Alert, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import CardButton from '../../../../../components/CardButton';
import useAppModules from '../../../app-modules/hooks/useAppModules';

import './o-project-detail-navigation.scss';

interface ProjectDetailNavigationProps {
  className?: string;
  projectId: string;
}

export default function ProjectDetailNavigation({
  className,
  projectId,
}: ProjectDetailNavigationProps): JSX.Element {
  const { t } = useTranslation();
  const { appModules, loading, error } = useAppModules(projectId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return (
      <Alert color="error">
        <Text>{t('projectDetail.appModulesLoadError')}</Text>
      </Alert>
    );
  }

  return (
    <nav className={cx('o-project-detail-navigation', className)}>
      <ul className="o-project-detail-navigation__menu">
        {appModules.map((appModule) => {
          return (
            <li key={appModule.id}>
              {!appModule.disabled ? (
                <CardButton component={RouterLink} to={appModule.to || ''} {...appModule} />
              ) : (
                <CardButton
                  {...appModule}
                  disabled
                  className={cx(stylex.create({ width: '100%' }))}
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
