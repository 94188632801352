import { gql, TypedDocumentNode } from '@apollo/client';
import RiskAssessmentFragment from './RiskAssessment.fragment';
import RiskAssessmentProjectFragment from './RiskAssessmentProject.fragment';
import {
  RiskAssessmentDetail,
  RiskAssessmentDetailVariables,
} from './__generated__/RiskAssessmentDetail.query';

const RiskAssessmentDetailQuery: TypedDocumentNode<
  RiskAssessmentDetail,
  RiskAssessmentDetailVariables
> = gql`
  query RiskAssessmentDetail($id: ID!) {
    riskAssessment(id: $id) {
      ...RiskAssessment

      project {
        ...RiskAssessmentProject
      }
    }
  }

  ${RiskAssessmentFragment}
  ${RiskAssessmentProjectFragment}
`;

export default RiskAssessmentDetailQuery;
