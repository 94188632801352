import { ReactNode, useEffect } from 'react';
import { Box, Button, Icon, IconProps } from '@hs-baumappe/legacy-ui';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { QsstFormValues } from './QsstForm.form';
import { qsstFormValidationSchema } from './QsstForm.validation';
import { QsstFormDetailSection } from './QsstFormDetailSection/QsstFormDetailSection';

export interface QsstFormProps {
  projectId: string;
  submitButtonText: ReactNode;
  onSubmit: (values: QsstFormValues, formikHelpers: FormikHelpers<QsstFormValues>) => void;
  onDirtyStateChange?: (dirty: boolean) => void;
  initialValues: QsstFormValues;
  loading?: boolean;
  submitButtonIconName: IconProps['name'];
}

export default function QsstForm({
  projectId,
  submitButtonText,
  onSubmit,
  onDirtyStateChange,
  initialValues,
  loading,
  submitButtonIconName,
}: QsstFormProps): JSX.Element {
  const formik = useFormik({
    initialValues,
    validationSchema: qsstFormValidationSchema,
    onSubmit,
  });

  const { dirty } = formik;

  useEffect(() => {
    if (onDirtyStateChange) {
      onDirtyStateChange(dirty);
    }
  }, [dirty, onDirtyStateChange]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="row">
          <div className="col col--md-8">
            <QsstFormDetailSection projectId={projectId} />
          </div>

          <div className="col col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                endIcon={<Icon name={submitButtonIconName} />}
                fullWidth
                loading={loading}
              >
                {submitButtonText}
              </Button>
            </Box>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}
