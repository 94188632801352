import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';

import CustomFileDocumentArchiveMutation from '../../graphql/CustomFileDocumentArchive.mutation';
import { ArchiveFormValues } from '../../../../../containers/ArchiveForm';
import { DriveDocumentArchiveInput } from '../../../../../globalTypes';
import { DocumentDetailAlert } from '../../../DocumentDetail/DocumentDetail.utils';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import documentRoutes from '../../../documentRoutes';

interface UseCustomFileDocumentArchiveResponse {
  archive: (
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useCustomFileDocumentArchive(
  documentId: string,
): UseCustomFileDocumentArchiveResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutate, { loading }] = useMutation(CustomFileDocumentArchiveMutation);
  const { setSapCredentials } = useSapCredentials();

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    setError(undefined);

    try {
      const { data } = await mutate({
        variables: {
          input: {
            id: documentId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
      });

      if (data) {
        setSapCredentials({ username: values.username, password: values.password });
      }

      const searchParams = new URLSearchParams({
        alert: DocumentDetailAlert.ARCHIVE,
      });

      navigate(
        createPath({
          pathname: documentRoutes.manipulate.generatePath({ documentId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    archive,
    loading,
    error,
  };
}
