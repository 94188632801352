import { ApolloError, useMutation } from '@apollo/client';

import { CREATE_FOLDER_FREEFORM } from './graphql';
import { CreateFolderForFreeform, CreateFolderForFreeformVariables } from './__generated__/graphql';

import { cast, clearEmptyTotal } from '../../../measurement/MeasurementSection/values';
import { BaseValues } from '../../containers/FolderForm/values';
import { castNote } from '../../containers/FolderForm/form';
import { GET_OFFER_DATA } from '../../../Detail/graphql';
import { OfferMode } from '../../../../../globalTypes';

export interface OfferFolderCreateForFreeformProps {
  onSuccess: (id: string) => void;
  offerId: string;
  offerMode: OfferMode;
  parentId?: string;
}

interface UseOfferFolderCreateForFreeformParams {
  error?: ApolloError;

  createFolder: (values: BaseValues) => Promise<void>;
}

const useOfferFolderCreateForFreeform = ({
  offerId,
  onSuccess,
  parentId,
  offerMode,
}: OfferFolderCreateForFreeformProps): UseOfferFolderCreateForFreeformParams => {
  const [mutation, { loading, error }] = useMutation<
    CreateFolderForFreeform,
    CreateFolderForFreeformVariables
  >(CREATE_FOLDER_FREEFORM);

  const createFolder = async (values: BaseValues) => {
    if (loading) {
      return;
    }

    const { measurements, note } = values;
    const unit = measurements && measurements.length ? measurements[0].type : undefined;

    const castedMeasurements = clearEmptyTotal(cast(measurements));
    const castedNotes = castNote(note);

    const { data } = await mutation({
      variables: {
        input: {
          partNoAlias: offerMode !== OfferMode.SYSTEM_NUMBER ? values.alias : undefined,
          offer: offerId,
          parent: parentId,
          name: values.name,
          note: castedNotes,
          approximate: values.approximate,
          unit,
          measurements: castedMeasurements,
        },
      },
      refetchQueries: [{ query: GET_OFFER_DATA, variables: { id: offerId } }],
    });

    if (!data) {
      return;
    }

    const { createFolderForFreeForm } = data;

    onSuccess(createFolderForFreeForm.id);
  };

  return {
    error,
    createFolder,
  };
};

export default useOfferFolderCreateForFreeform;
