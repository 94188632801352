import { Viewer } from '@hs-baumappe/web-auth';
import stundenerfassungIcon from '../../../static/img/deepLinks/stundenerfassung.svg';
import acceptanceReportIcon from '../../../static/img/project-detail-navigation/acceptance-report.svg';
import concernIcon from '../../../static/img/project-detail-navigation/concern.svg';
import noteIcon from '../../../static/img/project-detail-navigation/note.svg';
import obstructionIcon from '../../../static/img/project-detail-navigation/obstruction.svg';
import offerIcon from '../../../static/img/project-detail-navigation/offer.svg';
import qsstIcon from '../../../static/img/project-detail-navigation/qsst.svg';
import overtimeIcon from '../../../static/img/project-detail-navigation/report.svg';
import riskAssessmentIcon from '../../../static/img/project-detail-navigation/risk-assessment.svg';
import sivBillingAttachmentIcon from '../../../static/img/project-detail-navigation/siv-billing-attachment.svg';
import timeTrackingRoutes from '../../TimeTracking/timeTrackingRoutes';
import { projectRoutes } from '../../routes';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import { AppModuleNavigationProject } from './graphql/__generated__/AppModuleNavigationProject.fragment';

type AppModuleScopes = Omit<Viewer['scopes'], '__typename'>;
type AppModuleDecider = (project: AppModuleNavigationProject, scope: AppModuleScopes) => boolean;
type AppModulePathGenerator = (project: AppModuleNavigationProject) => string | undefined;

interface AppModuleBase {
  id: string;
  title: string;
  imageSrc: string;
  cornerBadge?: string;
  cornerBadgeColor?: 'default' | 'primary';
}

export interface AppModule extends AppModuleBase {
  className?: string;
  to?: string;
  disabled?: boolean;
}

interface AppModuleRegister extends Omit<AppModuleBase, 'title' | 'cornerBadge'> {
  titleTranslationKey: string;
  cornerBadgeTranslationKey?: string;
  generatePath: AppModulePathGenerator;
  shouldShown: AppModuleDecider;
  shouldDisabled?: AppModuleDecider;
}

function findDriveFolderIdByMimeType(
  mimeType: string,
  folderMap: AppModuleNavigationProject['drive']['mimeTypeFolderMap'],
): string | undefined {
  return folderMap.find((folderMapItem) => folderMapItem.mimeType === mimeType)?.folder.id;
}

const constructionNoteModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.CONSTRUCTION_NOTE,
  titleTranslationKey: 'appModules.createConstructionNote',
  imageSrc: noteIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.CONSTRUCTION_NOTE,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createConstructionNote.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createConstructionNoteDocument.isPermitted,
};

const offerModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.OFFER,
  titleTranslationKey: 'appModules.createOffer',
  imageSrc: offerIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.OFFER,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createOffer.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createOfferDocument.isPermitted,
};

const riskAssessmentModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.RISK_ASSESSMENT,
  titleTranslationKey: 'appModules.createRiskAssessment',
  imageSrc: riskAssessmentIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.RISK_ASSESSMENT,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createRiskAssessment.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createRiskAssessmentDocument.isPermitted,
};

const overtimeModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.OVERTIME,
  titleTranslationKey: 'appModules.createOvertime',
  imageSrc: overtimeIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.OVERTIME,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createOvertime.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createOvertimeDocument.isPermitted,
};

const acceptanceReportModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.ACCEPTANCE_REPORT,
  titleTranslationKey: 'appModules.createAcceptanceReport',
  imageSrc: acceptanceReportIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.ACCEPTANCE_REPORT,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createAcceptanceReport.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createAcceptanceReportDocument.isPermitted,
};

const concernModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.CONCERN,
  titleTranslationKey: 'appModules.createConcern',
  imageSrc: concernIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.CONCERN,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createConcern.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createConcernDocument.isPermitted,
};

const obstructionModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.OBSTRUCTION,
  titleTranslationKey: 'appModules.createObstruction',
  imageSrc: obstructionIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.OBSTRUCTION,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createObstruction.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createObstructionDocument.isPermitted,
};

const qsstModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.QSST,
  titleTranslationKey: 'appModules.createQsst',
  imageSrc: qsstIcon,
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.QSST,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createQsst.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (_, scopes) => scopes.createQsstDocument.isPermitted,
};

const timeTrackingModule: AppModuleRegister = {
  id: 'TimeTracking',
  titleTranslationKey: 'appModules.timeTracking',
  imageSrc: stundenerfassungIcon,
  generatePath: (project) =>
    timeTrackingRoutes.projectOriented.detailWithoutTime.generatePath({
      projectId: project.id,
    }),
  shouldShown: (project, scopes) => !project.draft && scopes.viewTimeTrackingOverview.isPermitted,
};

const sivBillingAttachmentModule: AppModuleRegister = {
  id: DriveSpecialDocumentMimeTypes.SIV_BILLING_ATTACHMENT,
  titleTranslationKey: 'appModules.createSIVBillingAttachment',
  imageSrc: sivBillingAttachmentIcon,
  cornerBadgeTranslationKey: 'appModules.newCornerBadge',
  cornerBadgeColor: 'primary',
  generatePath: (project) => {
    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.SIV_BILLING_ATTACHMENT,
      project.drive.mimeTypeFolderMap,
    );

    return folderId
      ? projectRoutes.createSIVBillingAttachment.generatePath({ projectId: project.id, folderId })
      : undefined;
  },
  shouldShown: (project, scope) =>
    !project.draft && scope.createSIVBillingAttachmentDocument.isPermitted,
  shouldDisabled: (project) => !project.siv?.hasBillableOperations,
};

const APP_MODULE_REGISTERS = [
  constructionNoteModule,
  offerModule,
  riskAssessmentModule,
  overtimeModule,
  acceptanceReportModule,
  concernModule,
  obstructionModule,
  qsstModule,
  timeTrackingModule,
  sivBillingAttachmentModule,
];

export default APP_MODULE_REGISTERS;
