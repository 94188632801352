import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_DOCUMENT_TO_PROJECT = gql`
  mutation AssignDocumentToProject($input: AssignDocumentToProjectInput!) {
    assignDocumentToProject(input: $input) {
      id

      project {
        id
      }
    }
  }
`;
