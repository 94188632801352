function base64ToBlobGenerator(source: string, fileMimeType: string): Blob {
  const [, sourceString] = source.split(',');
  const byteRepresentation = atob(sourceString);
  const bytes = byteRepresentation.length;
  const arrayBuffer = new ArrayBuffer(bytes);
  const ia = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteRepresentation.length; i += 1) {
    ia[i] = byteRepresentation.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: fileMimeType });
}

export default base64ToBlobGenerator;
