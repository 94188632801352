import { DocumentDetailAlert } from '../../DocumentDetail.utils';
import i18n from '../../../../../i18n';

export function getAlertContent(alert: DocumentDetailAlert, name: string): string {
  switch (alert) {
    case DocumentDetailAlert.SEND_EMAIL:
      return i18n.t('customFile.documentDetail.alerts.emailSent');
    case DocumentDetailAlert.ARCHIVE:
      return i18n.t('customFile.documentDetail.alerts.archived', { name });
    default:
      return '';
  }
}
