import { ApolloError, useMutation } from '@apollo/client';
import { RemoveFolderOperation, RemoveFolderOperationVariables } from './__generated__/graphql';
import { REMOVE_FOLDER__OPERATION_MUTATION } from './graphql';

export default function useFolderOperationRemove(
  operationId: string,
  onSuccess: () => void,
): {
  error: ApolloError | undefined;
  removeFolderOperation: () => Promise<void>;
} {
  const [mutation, { loading, error }] = useMutation<
    RemoveFolderOperation,
    RemoveFolderOperationVariables
  >(REMOVE_FOLDER__OPERATION_MUTATION, {
    variables: { input: { id: operationId } },
  });

  async function removeFolderOperation() {
    if (loading) return;

    await mutation();
    onSuccess();
  }

  return {
    error,
    removeFolderOperation,
  };
}
