import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useCreateQsstDetail from './hooks/useCreateQsstDetail';
import useQsstSave from '../../Qsst/hooks/useSaveQsst/useQsstSave';
import RouteDialog from '../../../containers/RouteDialog';
import QsstForm from '../../../components/QsstSave/QsstForm/QsstForm';
import { QsstLocationState } from '../../Qsst/Qsst.route.types';
import { ProjectDetailRouteParams } from '../ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../drive/drive.types';
import { getQsstFormInitialValues } from './CreateQsst.utils';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';
import CreateDocumentErrorContainer from '../document/CreateDocumentErrorContainer';
import ErrorState from '../../../containers/ErrorState';

interface CreateQsstProps {
  documentId: string;
}

function CreateQsst({ documentId }: CreateQsstProps): JSX.Element | null {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const location = useLocation<QsstLocationState>();
  const navigate = useNavigate();

  const { viewer } = useViewer();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { project, duplicatingQsst, loading, error } = useCreateQsstDetail({
    projectId,
    duplicatingQsstId: location.state?.qsstId,
  });
  const { save, loading: saving, error: saveError } = useQsstSave(documentId);

  const handleDialogRequestClose = useCallback(() => {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }, [formDirty]);

  const handleDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  function renderContent() {
    if (loading) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!project || !viewer) {
      return null;
    }

    return (
      <QsstForm
        projectId={projectId}
        onSubmit={save}
        onDirtyStateChange={setFormDirty}
        submitButtonText={t('qsstForm.create.submitButton')}
        loading={saving}
        initialValues={getQsstFormInitialValues({
          project,
          viewer,
          t,
          duplicatingQsst,
        })}
        submitButtonIconName="add"
      />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          {project && (
            <Text variant="body-medium" component="div" color="muted">
              {project.description}
              {project.additionalName && ' | ' + project.additionalName}
            </Text>
          )}

          <Text variant="title-small" component="h2">
            {t('createQsst.title')}
          </Text>
        </>
      }
    >
      {saveError && <ErrorState error={saveError} />}

      {renderContent()}

      <AlertDialog
        destructive
        open={confirmationDialogOpen}
        title={t('createQsst.confirmationModal.title')}
        description={t('createQsst.confirmationModal.body')}
        confirmButtonText={t('createQsst.confirmationModal.confirmButton')}
        cancelButtonText={t('createQsst.confirmationModal.dismissButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={() => setDialogOpen(false)}
      />
    </RouteDialog>
  );
}

export default withGenerateDocumentId(CreateQsst, {
  mimeType: DriveSpecialDocumentMimeTypes.QSST,
  errorRenderer: (error) => <CreateDocumentErrorContainer apolloError={error} />,
});
