import { FC, Fragment, useState } from 'react';
import useOfferFolderOperationCreate from './useOfferFolderOperationCreate';
import CreateNewOperationForm from './CreateNewOperationForm';
import { OfferMode, OfferType } from '../../../../../globalTypes';
import { GetOperationCreateData } from '../__generated__/graphql';
import catalogueMapper from '../../containers/catalogueMapper';
import { castGqlForMeasurement } from '../../../folder/containers/FolderForm/form';
import { MeasurementsValues } from '../../../measurement/MeasurementsSection/values';
import { Folder } from '../../../Detail/types';
import ErrorState from '../../../../../containers/ErrorState';
import { pad } from '../../../folder/OfferFolderCreate/offerFolderCreate.utils';
import InfoModal from '../../../components/InfoModal';

interface Props {
  offerId: string;
  offerType: OfferType;
  folder: Folder;
  offerMode: OfferMode;
  catalogues: GetOperationCreateData['catalogues'];
  onSuccess: () => void;
}

const CreateNewFolderOperation: FC<Props> = ({
  offerId,
  offerType,
  folder,
  offerMode,
  catalogues,
  onSuccess,
}) => {
  const [aliasInfoModal, setAliasInfoModal] = useState(false);

  const { createOperation, error } = useOfferFolderOperationCreate({
    offerId,
    folderId: folder.id,
    folderUnit: folder.unit || undefined,
    onSuccess,
    offerMode,
  });

  const catalogs = catalogueMapper(catalogues);

  return (
    <Fragment>
      {error && <ErrorState error={error} />}
      <CreateNewOperationForm
        onClickAliasRightIcon={() => setAliasInfoModal(true)}
        catalogs={catalogs}
        offerModeSystemNumber={offerMode === OfferMode.SYSTEM_NUMBER}
        freeform={offerType === OfferType.FREE_FORM}
        catalogSelectable={offerType === OfferType.FREE_FORM}
        onSubmit={createOperation}
        parentMeasurement={
          folder.measurements && folder.unit
            ? (castGqlForMeasurement(folder.measurements, folder.unit) as MeasurementsValues)
            : // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ([] as any)
        }
        initialValues={{
          alias: pad(folder.operations.length + 1, 4),
          optional: false,
          category: folder.category?.id,
          unit: folder.unit,
          measurements:
            folder.measurements && folder.unit
              ? (castGqlForMeasurement(folder.measurements, folder.unit) as MeasurementsValues)
              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ([] as any),
        }}
      />
      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Fragment>
  );
};

export default CreateNewFolderOperation;
