import { Button, Icon } from '@hs-baumappe/legacy-ui';
import { createPath, useNavigate } from 'react-router-dom';
import { DocumentType } from '../../../../globalTypes';
import { projectRoutes } from '../../../routes';
import { constructionNoteRoutes } from '../../constructionNoteRoutes';

interface AssignActionProps {
  constructionNoteId: string;
  projectId: string;
}

export default function AssignAction({
  constructionNoteId,
  projectId,
}: AssignActionProps): JSX.Element {
  const navigate = useNavigate();

  function handleAssignButtonClick() {
    const assignRouteSearchParams = new URLSearchParams({
      document: DocumentType.CONSTRUCTION_NOTE,
      returnPath: constructionNoteRoutes.detailFreeDrawing.generatePath({ constructionNoteId }),
    });

    navigate(
      createPath({
        pathname: projectRoutes.assignDocumentToProject.generatePath({
          projectId,
          documentId: constructionNoteId,
        }),
        search: assignRouteSearchParams.toString(),
      }),
    );
  }

  return (
    <Button contentClassName="u-display-flex" onClick={handleAssignButtonClick}>
      <Icon name="folder" size={24} />
    </Button>
  );
}
