import { useCallback, useMemo } from 'react';
import { Flex, Spinner, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { useViewerProfitCenters } from '../useViewerProfitCenters';
import useProjectFilterTargeter from '../useProjectFilterTargeter';
import ProjectFilterForm from '../ProjectFilterForm/ProjectFilterForm';
import {
  getFilterFormInitialValues,
  PROJECT_FILTER_FORM_PRESETS,
} from '../ProjectFilterModal.utils';
import { ProjectFilterFormValues } from '../ProjectFilterForm/ProjectFilterForm.form';
import { getAppliedFilters } from '../ProjectFilterForm/ProjectFilterForm.utils';
import deadLink from '../../../../../../static/img/dead-link.svg';
import {
  getProfitCenterCollapseCache,
  removeProfitCenterCollapsedInCache,
  setProfitCenterCollapsedInCache,
} from '../profitCenterCollapseCache';
import { ProjectFilterSelection } from '../../ProjectFilter.types';

interface ProjectFilterModalBodyProps {
  filterSelection: ProjectFilterSelection;
  onFilterSelectionChange: (filterSelection: ProjectFilterSelection) => void;
}

export default function ProjectFilterModalBody({
  filterSelection,
  onFilterSelectionChange,
}: ProjectFilterModalBodyProps): JSX.Element {
  const { t } = useTranslation();
  const { profitCenters, projectCountsByStatus, loading, error } = useViewerProfitCenters();
  const {
    matchCount,
    statuses,
    profitCenters: targetedProfitCenters,
    loading: targeting,
    changeTargets,
  } = useProjectFilterTargeter();

  const profitCenterCollapseCache = useMemo(() => getProfitCenterCollapseCache(), []);
  const initiallyCollapsedProfitCenterIds = profitCenterCollapseCache.profitCenters;

  const handleProjectFilterFormChange = useCallback(
    (values: ProjectFilterFormValues) => {
      const nextAppliedFilters = getAppliedFilters(values);

      changeTargets(nextAppliedFilters.statuses, nextAppliedFilters.profitCenters);
    },
    [changeTargets],
  );

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Spinner size={48} />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex gap="small-2x" alignItems="center">
        <img src={deadLink} alt="error" width={64} height={64} />
        <Text variant="title-medium">{t('projectFilterModal.error')}</Text>
      </Flex>
    );
  }

  function handleProfitCenterToggled(profitCenterId: string, expanded: boolean) {
    if (!expanded) {
      setProfitCenterCollapsedInCache(profitCenterId);
      return;
    }

    const cache = getProfitCenterCollapseCache();
    const initiallyCollapsed = cache.profitCenters.includes(profitCenterId);
    if (initiallyCollapsed) {
      removeProfitCenterCollapsedInCache(profitCenterId);
    }
  }

  function handleProjectFilterFormSubmit() {
    onFilterSelectionChange({
      statuses,
      profitCenters: targetedProfitCenters,
    });
  }

  return (
    <ProjectFilterForm
      presets={PROJECT_FILTER_FORM_PRESETS}
      profitCenters={profitCenters}
      projectCountsByStatus={projectCountsByStatus}
      initialValues={getFilterFormInitialValues(filterSelection, profitCenters)}
      submitButtonDisabled={matchCount === 0}
      initiallyCollapsedProfitCenterIds={initiallyCollapsedProfitCenterIds}
      onProfitCenterToggled={handleProfitCenterToggled}
      submitButtonLoading={targeting}
      submitButtonHelperText={t('projectFilterModal.targetedProjectCount', {
        count: matchCount,
        context: matchCount,
      })}
      onValuesChange={handleProjectFilterFormChange}
      onSubmit={handleProjectFilterFormSubmit}
    />
  );
}
