import { ApolloError, useMutation } from '@apollo/client';

import { createPath, useNavigate } from 'react-router-dom';
import { HsProject } from './ProjectSearch/__generated__/graphql';
import { ImportProject, ImportProjectVariables } from './__generated__/graphql';
import { PROJECT_IMPORT } from './graphql';
import { DocumentType } from '../../../../globalTypes';
import { projectRoutes } from '../../../routes';

interface AssignOfferToProjectImportProps {
  returnPath?: string;
  documentType: DocumentType;
  documentId: string;
}

interface UseAssignOfferToProjectImportParams {
  error?: ApolloError;
  projectImport: (project?: HsProject) => Promise<void>;
}

const useAssignDocumentToProjectImport = ({
  returnPath,
  documentType,
  documentId,
}: AssignOfferToProjectImportProps): UseAssignOfferToProjectImportParams => {
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation<ImportProject, ImportProjectVariables>(
    PROJECT_IMPORT,
  );

  const projectImport = async (project?: HsProject) => {
    if (loading || !project) {
      return;
    }

    const { data } = await mutation({ variables: { input: { hsId: project.id } } });
    if (!data) {
      return;
    }

    const searchParams = new URLSearchParams({
      projectId: data.importProject.id,
      document: documentType,
    });

    if (returnPath) {
      searchParams.set('returnPath', returnPath);
    }

    navigate(
      createPath({
        pathname: projectRoutes.assignDocumentToProject.generatePath({
          projectId: data.importProject.id,
          documentId,
        }),
        search: searchParams.toString(),
      }),
    );
  };

  return {
    error,
    projectImport,
  };
};

export default useAssignDocumentToProjectImport;
