import { Navigate, useParams } from 'react-router-dom';
import { overtimeRoutes } from '../overtimeRoutes';
import { OvertimeRouteParams } from '../overtimeRoutes.types';

export default function OvertimeCustomerRedirect(): JSX.Element {
  const { overtimeId } = useParams<OvertimeRouteParams>();

  return (
    <Navigate
      to={overtimeRoutes.customer.generatePath({
        overtimeId,
      })}
    />
  );
}
