import { Dialog, DialogBody, DialogHeader, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import ProjectFilterModalBody from './ProjectFilterModalBody';
import { ProjectFilterSelection } from '../ProjectFilter.types';

interface ProjectFilterModalProps {
  open: boolean;
  onRequestClose: () => void;
  onDismissButtonClick: () => void;
  onFilterSelectionChange: (nextFilterSelection: ProjectFilterSelection) => void;
  filterSelection: ProjectFilterSelection;
}

export default function ProjectFilterModal({
  open,
  onRequestClose,
  onDismissButtonClick,
  onFilterSelectionChange,
  filterSelection,
}: ProjectFilterModalProps): JSX.Element {
  const { t } = useTranslation();

  function handleAppliedFiltersChange(nextAppliedFilters: ProjectFilterSelection) {
    onFilterSelectionChange(nextAppliedFilters);
    onRequestClose();
  }

  return (
    <Dialog open={open} onRequestClose={onRequestClose} size="large">
      <DialogHeader
        rightSlot={<IconButton icon={<Icon name="close" />} onClick={onDismissButtonClick} />}
        data-testid="project-filter-modal"
      >
        <Text variant="title-medium">{t('projectFilterModal.title')}</Text>
      </DialogHeader>
      <DialogBody>
        <ProjectFilterModalBody
          filterSelection={filterSelection}
          onFilterSelectionChange={handleAppliedFiltersChange}
        />
      </DialogBody>
    </Dialog>
  );
}
