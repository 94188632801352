import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useRiskAssessmentEditDetail from '../../hooks/useRiskAssessmentEditDetail';
import useRiskAssessmentEdit from '../../hooks/useRiskAssessmentEdit';
import ErrorState from '../../../../../containers/ErrorState';
import RiskAssessmentForm from '../../../RiskAssessmentForm';
import { RiskAssessmentProject } from '../../../graphql/__generated__/RiskAssessmentProject.fragment';
import { RiskAssessmentTab } from '../../../RiskAssessmentForm/useRiskAssessmentForm';
import {
  mapRiskAssessmentEquipmentInitialValues,
  mapRiskAssessmentInformationInitialValues,
  mapRiskAssessmentPrecautionInitialValues,
} from '../../RiskAssessmentEdit.utils';

interface RiskAssessmentEditBodyProps {
  riskAssessmentId: string;
  project: RiskAssessmentProject;
  onFormStepChange: (step: RiskAssessmentTab) => void;
  onFormDirtyStateChange: (dirty: boolean) => void;
}

export default function RiskAssessmentEditBody({
  riskAssessmentId,
  project,
  onFormStepChange,
  onFormDirtyStateChange,
}: RiskAssessmentEditBodyProps): JSX.Element | null {
  const { riskAssessment, employees, regionalSecurityManagers, loading, error } =
    useRiskAssessmentEditDetail({ riskAssessmentId, projectId: project.id });
  const { edit, error: editError } = useRiskAssessmentEdit(riskAssessmentId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!riskAssessment) {
    return null;
  }

  return (
    <>
      {editError && <ErrorState error={editError} />}

      <RiskAssessmentForm
        informationInitialValues={mapRiskAssessmentInformationInitialValues(
          riskAssessment,
          regionalSecurityManagers,
          project,
        )}
        precautionsInitialValues={mapRiskAssessmentPrecautionInitialValues(riskAssessment)}
        equipmentsInitialValues={mapRiskAssessmentEquipmentInitialValues(riskAssessment)}
        employees={employees}
        regionalSecurityManagers={regionalSecurityManagers}
        onSubmit={(values) => edit(values, project.draft)}
        onStepChange={onFormStepChange}
        onDirtyStateChange={onFormDirtyStateChange}
        draft={project.draft}
        edit
      />
    </>
  );
}
