import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Text } from '@hs-baumappe/legacy-ui';

import LogActor from '../LogActor';
import { projectRoutes } from '../../../../routes/routes';
import { ProjectDetailLocationState } from '../../../../routes/Project/ProjectDetail/ProjectDetail.route.types';
import { DocumentLog as DocumentLogType } from '../../graphql/__generated__/DocumentLog.fragment';
import { getActor } from '../Log.utils';

interface DocumentLogProps extends ComponentProps<typeof Text> {
  log: DocumentLogType;
}

export default function DocumentLog({ log, ...props }: DocumentLogProps): JSX.Element {
  switch (log.__typename) {
    case 'DocumentMovedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.documentMovedLog"
            values={{
              customFileName: log.name,
              customFileFolderNameOld: log.sourceFolder.name,
              customFileFolderNameNew: log.targetFolder.name,
            }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              targetFolderLink: (
                <Link
                  component={RouterLink}
                  variant="label-medium"
                  to={projectRoutes.driveWithFolder.generatePath({
                    projectId: log.projectId,
                    folderId: log.targetFolder.id,
                  })}
                  state={{ scrollToDrive: true } as ProjectDetailLocationState}
                />
              ),
            }}
          />
        </Text>
      );
    default:
      throw new Error(`Unknown document log: ${log}`);
  }
}
