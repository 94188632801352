import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getEnumFromValue } from '../../../../../../../utils/enumUtils';
import {
  ObstructionDetailAlert,
  ObstructionDetailSearchParams,
} from '../../../../ObstructionDetail.utils';

interface UseObstructionDetailAlertResponse {
  alert?: ObstructionDetailAlert;
  clearAlert: () => void;
}

export default function useObstructionDetailAlert(): UseObstructionDetailAlertResponse {
  const [searchParams, setSearchParams] = useSearchParams();

  const alertParam = searchParams.get(ObstructionDetailSearchParams.ALERT);
  const alert = alertParam ? getEnumFromValue(alertParam, ObstructionDetailAlert) : undefined;

  const clearAlert = useCallback(() => {
    setSearchParams(
      (prev) => {
        prev.delete(ObstructionDetailSearchParams.ALERT);
        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return {
    alert,
    clearAlert,
  };
}
