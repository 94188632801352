import { AcceptanceReportAttendeeRole } from '../../../../globalTypes';
import { ImageUploadWithLabelFormValues } from '../../../../containers/forms/ImageUploadWithLabel';

export enum Acceptance {
  ACCEPT = 'ACCEPT',
  NOT_ACCEPT = 'NOT_ACCEPT',
}

export enum Defect {
  HAS_DEFECT = 'HAS_DEFECT',
  NOT_DEFECTIVE = 'NOT_DEFECTIVE',
}

export interface AttendeeFormValues {
  fullName?: string;
  role: AcceptanceReportAttendeeRole;
  position?: string;
}

export type DefectImageFormValues = ImageUploadWithLabelFormValues;

export interface AcceptanceReportFormValues {
  name: string;
  description?: string;
  projectNumber: string;
  date: string;
  client: string;
  contractor: string;
  toProfessions: Array<string>;
  acceptance: Acceptance;
  acceptanceDescriptionText?: string;
  acceptanceDescriptionReference?: string;
  attendees: AttendeeFormValues[];
  defect: Defect;
  defectMeta: DefectImageFormValues[];
  defectReferenceFile?: string;
  defectConfirmation: boolean;
  defectDueDate: string;
  defectNote?: string;
  attachImagesToPdf: boolean;
  images: ImageUploadWithLabelFormValues[];
}
