import AcceptanceReportCustomerRouteParams from './AcceptanceReportCustomer/AcceptanceReportCustomer.route.types';
import { AcceptanceReportRouteParams } from './AcceptanceReport.route.types';
import routes from '../routes';

import { getDocumentRoutes, route } from '../routes.utils';

export const acceptanceReportRoutes = {
  ...getDocumentRoutes<AcceptanceReportRouteParams>(routes.acceptanceReport),
  uploadManuallySignedReport: route<AcceptanceReportRouteParams>(
    `${routes.acceptanceReport.path}/upload-manually-signed-report`,
  ),
  customer: route<AcceptanceReportCustomerRouteParams>(
    '/acceptance-report/customer-detail/:acceptanceReportId',
  ),
};

export const acceptanceReportCustomerRoutes = {
  detail: acceptanceReportRoutes.customer,
  sign: route<AcceptanceReportCustomerRouteParams>(`${acceptanceReportRoutes.customer.path}/sign`),
};
