import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UploadAcceptanceReportImageAttachment,
  UploadAcceptanceReportImageAttachmentVariables,
} from './__generated__/UploadAcceptanceReportImageAttachment.mutation';

const UploadAcceptanceReportImageAttachmentMutation: TypedDocumentNode<
  UploadAcceptanceReportImageAttachment,
  UploadAcceptanceReportImageAttachmentVariables
> = gql`
  mutation UploadAcceptanceReportImageAttachment($file: Upload!) {
    uploadImage(file: $file) {
      id
      url
    }
  }
`;

export default UploadAcceptanceReportImageAttachmentMutation;
