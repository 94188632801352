import { ApolloError, useQuery } from '@apollo/client';
import ObstructionEditDetailQuery from '../../graphql/ObstructionEditDetail.query';
import { ObstructionEditDetail } from '../../graphql/__generated__/ObstructionEditDetail.query';

interface UseObstructionEditDetailResponse {
  obstruction?: ObstructionEditDetail['obstruction'];
  loading: boolean;
  error?: ApolloError;
}

const OBSTRUCTION_IMAGE_THUMBNAIL_WIDTH = 58 * 2;
const OBSTRUCTION_IMAGE_THUMBNAIL_HEIGHT = 48 * 2;

export default function useObstructionEditDetail(
  obstructionId: string,
): UseObstructionEditDetailResponse {
  const { data, loading, error } = useQuery(ObstructionEditDetailQuery, {
    variables: {
      obstructionId,
      thumbnailWidth: OBSTRUCTION_IMAGE_THUMBNAIL_WIDTH,
      thumbnailHeight: OBSTRUCTION_IMAGE_THUMBNAIL_HEIGHT,
    },
    fetchPolicy: 'network-only',
  });

  const obstruction = data?.obstruction;

  return {
    obstruction,
    loading,
    error,
  };
}
