import { gql } from '@apollo/client';
import { AVAILABLE_CATEGORIES_FRAGMENT } from '../../containers/categoryMapper/graphql';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_FRAMEWORK_FOLDER_MUTATION = gql`
  mutation UpdateFrameworkFolder($input: UpdateFolderForFrameworkInput!) {
    updateFolderForFramework(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_FRAMEWORK_FOLDER_CATEGORIES = gql`
  query UpdateFrameworkFolderCategories($offerId: ID!, $parentId: ID) {
    listAvailableCategoriesForFolderCreation(offer: $offerId, parent: $parentId) {
      ...AvaiableCategoriesFragment
    }
  }

  ${AVAILABLE_CATEGORIES_FRAGMENT}
`;
