import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import ObstructionSendEmailMutation from '../../graphql/ObstructionSendEmail.mutation';
import {
  ObstructionDetailAlert,
  ObstructionDetailSearchParams,
} from '../../../ObstructionDetail/ObstructionDetail.utils';
import obstructionRoutes from '../../../obstructionRoutes';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import { ObstructionEmailSendInput } from '../../../../../globalTypes';
import { EmailFormValues } from '../../../../../containers/Email';

interface UseObstructionSendEmailResponse {
  send: (values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useObstructionSendEmail(
  obstructionId: string,
): UseObstructionSendEmailResponse {
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(ObstructionSendEmailMutation);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function send(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: {
            recipients: values.toEmails,
            cc: values.ccEmails,
            subject: values.subject,
            content: values.content,
            documentId: obstructionId,
          },
        },
      });

      const searchParams = new URLSearchParams({
        [ObstructionDetailSearchParams.ALERT]: ObstructionDetailAlert.SEND,
      });

      navigate(
        createPath({
          pathname: obstructionRoutes.detail.generatePath({ obstructionId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        ObstructionEmailSendInput
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.recipients,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    send,
    loading,
    error,
  };
}
