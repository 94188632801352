import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { ErrorRendererPage, MissingScopeError } from '@hs-baumappe/redkit';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import CreateConstructionNoteProjectDetailQuery from './graphql/CreateConstructionNoteProjectDetail.query';
import ConstructionNoteCreateLoadingLayout from './ConstructionNoteCreateLoadingLayout';
import { CanvasProvider } from '../context/CanvasContext';
import { SelectedToolContextProvider } from '../context/SelectedToolContext';
import { CanvasAlertProvider } from '../context/CanvasAlertContext';
import CreateLayout from '../layouts/CreateLayout';
import routes, { projectRoutes, projectsRoutes } from '../../routes';
import { ProjectDetailRouteParams } from '../../Project/ProjectDetail/ProjectDetail.route.types';
import { DriveSpecialDocumentMimeTypes } from '../../Project/drive/drive.types';
import withGenerateDocumentId from '../../../hocs/withGenerateDocumentId';

import '../o-construction-note-editor-container.scss';

interface ConstructionNoteCreateProps {
  documentId: string;
}

function ConstructionNoteCreate({ documentId }: ConstructionNoteCreateProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { projectId } = useParams<ProjectDetailRouteParams>();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(CreateConstructionNoteProjectDetailQuery, {
    variables: {
      id: projectId,
    },
  });

  if (loading) {
    return <ConstructionNoteCreateLoadingLayout />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return null;
  }

  const date = format(new Date(), 'dd.MM.yyyy');
  const initialTitle = t('constructionNote.create.initialTitle', { date });

  const createConstructionNoteScope = viewer?.scopes.createConstructionNoteDocument;
  const viewerCanCreateConstructionNote = !!createConstructionNoteScope?.isPermitted;

  return (
    <Box className="container" stylex={{ paddingEnds: 'large' }}>
      {!viewerCanCreateConstructionNote && (
        <>
          <Flex alignItems="center" justifyContent="flex-end">
            <IconButton
              icon={<Icon name="close" />}
              onClick={
                viewerCanCreateConstructionNote
                  ? () => navigate(projectRoutes.detail.generatePath({ projectId }))
                  : () => navigate(projectsRoutes.detail.generatePath({}))
              }
            />
          </Flex>

          <MissingScopeError missingScopes={createConstructionNoteScope?.missingScopeNames ?? []} />
        </>
      )}

      {viewerCanCreateConstructionNote && (
        <CanvasProvider>
          <SelectedToolContextProvider>
            <CanvasAlertProvider>
              <Breadcrumb>
                <BreadcrumbItem component={NavLink} to={routes.projects.generatePath({})}>
                  {t('breadcrumb.project')}
                </BreadcrumbItem>

                <BreadcrumbItem
                  component={NavLink}
                  to={projectRoutes.detail.generatePath({ projectId: data.project.id })}
                >
                  {data.project.projectNumber}
                </BreadcrumbItem>
              </Breadcrumb>
              <AppHeader
                title={
                  <Text
                    component="h1"
                    variant="body-large"
                    color="muted"
                    stylex={{ marginBottom: '0' }}
                  >
                    {t('constructionNote.create.initialTitle', { date })}
                  </Text>
                }
                userName={viewer?.email}
                logoutText={t('pageInnerLayout.logOut')}
                logoutURL={routes.logout.generatePath({})}
              />
              <Box
                className="o-construction-note-editor-container"
                stylex={{ marginTop: 'large-x' }}
              >
                <CreateLayout
                  initialTitle={initialTitle}
                  documentId={documentId}
                  projectId={projectId}
                />
              </Box>
            </CanvasAlertProvider>
          </SelectedToolContextProvider>
        </CanvasProvider>
      )}
    </Box>
  );
}

export default withGenerateDocumentId(ConstructionNoteCreate, {
  mimeType: DriveSpecialDocumentMimeTypes.CONSTRUCTION_NOTE,
  errorRenderer: (error) => <ErrorRendererPage apolloError={error} />,
});
