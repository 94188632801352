import { FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { FormikFormField, SignaturePadField } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';

export interface SignatureFormValues {
  name: string;
  date: string;
  signature: string;
}

export default function Signature(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack gap="small-x">
      <FlexGrid>
        <FlexGridColumn sm>
          <FormikFormField
            name="clientSignature.name"
            label={t('signatureForm.name.label')}
            placeholder={t('signatureForm.name.placeholder')}
          />
        </FlexGridColumn>
        <FlexGridColumn sm>
          <FormikFormField
            name="clientSignature.date"
            label={t('signatureForm.date.label')}
            placeholder={t('signatureForm.date.placeholder')}
            readOnly
          />
        </FlexGridColumn>
      </FlexGrid>

      <SignaturePadField
        name="clientSignature.signature"
        label={t('qsstSign.form.signatureLabel')}
      />
    </Stack>
  );
}
