import { createPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';
import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import AcceptanceReportLogsList from './components/AcceptanceReportLogsList';
import AcceptanceReportDetailHeader from './components/AcceptanceReportDetailHeader';
import PDFViewer from '../../../components/PDFViewer';
import AcceptanceReportDetailAlerts from './components/AcceptanceReportDetailAlerts/AcceptanceReportDetailAlerts';
import ConfirmAcceptanceReportDuplication from './components/ConfirmAcceptanceReportDuplication';
import AcceptanceReportDetailSidebarTop from './components/AcceptanceReportDetailSidebarTop';
import AcceptanceReportDetailSidebarBottom from './components/AcceptanceReportDetailSidebarBottom/AcceptanceReportDetailSidebarBottom';
import AcceptanceReportDetailRemoveManualPdfDialog from './components/AcceptanceReportDetailRemoveManualPdfDialog';
import AcceptanceReportHasNotSignedDialog from './components/AcceptanceReportHasNotSignedDialog';
import { AcceptanceReport } from '../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportProject } from '../graphql/__generated__/AcceptanceReportProject.fragment';
import { AcceptanceReportScopes } from '../AcceptanceReport.types';
import { acceptanceReportRoutes } from '../acceptanceReportRoutes';
import { projectRoutes } from '../../routes';
import { AcceptanceReportLocationState } from '../AcceptanceReport.route.types';
import { AcceptanceReportDetailSearchParams } from './AcceptanceReportDetail.utils';
import { createAcceptanceReportLocation, getAcceptanceReportMeta } from '../AcceptanceReport.utils';

interface AcceptanceReportDetailProps {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  scopes: AcceptanceReportScopes;
}

export default function AcceptanceReportDetail({
  acceptanceReport,
  project,
  scopes,
}: AcceptanceReportDetailProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation<AcceptanceReportLocationState>();
  const [searchParams, setSearchParams] = useSearchParams();
  const showOriginalPdf = searchParams.has(AcceptanceReportDetailSearchParams.SHOW_ORIGINAL_PDF);

  const [showDuplicationDialog, setShowDuplicationDialog] = useState(false);
  const [showHasNotSignedDialog, setShowHasNotSignedDialog] = useState<boolean>(false);
  const [showRemoveManualUploadedPdfDialog, setShowRemoveManualUploadedPdfDialog] = useState(false);

  const { parentFolders } = useDriveDocumentParentFolders(acceptanceReport.id);

  const { signedManually, signable, signedByEmployee } = getAcceptanceReportMeta(acceptanceReport);
  const pdfSource = signedManually && !showOriginalPdf ? 'signed-manually' : 'original';
  const pdfPath =
    pdfSource === 'original' ? acceptanceReport.pdfPath : acceptanceReport.manualPdfPath;
  const downloadPermitted = scopes.downloadAcceptanceReportDocument.isPermitted;

  function handleEditButtonClick() {
    if (!acceptanceReport.viewerCanUpdate) {
      setShowDuplicationDialog(true);
      return;
    }

    navigate(
      acceptanceReportRoutes.edit.generatePath({ acceptanceReportId: acceptanceReport.id }),
      {
        state: createAcceptanceReportLocation(location),
      },
    );
  }

  function handleSendButtonClick() {
    if (!signedByEmployee) {
      setShowHasNotSignedDialog(true);
      return;
    }

    navigate(
      acceptanceReportRoutes.sendEmail.generatePath({ acceptanceReportId: acceptanceReport.id }),
      { state: createAcceptanceReportLocation(location) },
    );
  }

  function handleDownloadButtonClick() {
    if (!pdfPath) {
      return;
    }

    window.open(pdfPath, '_blank');
  }

  function handleTogglePdfButtonClick() {
    setSearchParams((prev) => {
      if (pdfSource === 'original') {
        prev.delete(AcceptanceReportDetailSearchParams.SHOW_ORIGINAL_PDF);
      } else {
        prev.set(AcceptanceReportDetailSearchParams.SHOW_ORIGINAL_PDF, 'true');
      }

      return prev;
    });
  }

  function handleRemoveSuccess() {
    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId: project.id,
        }),
        search: `?removedDocumentName=${acceptanceReport.name}`,
      }),
    );
  }

  function handleSignNowButtonClick() {
    setShowHasNotSignedDialog(false);

    navigate(
      acceptanceReportRoutes.sign.generatePath({ acceptanceReportId: acceptanceReport.id }),
      {
        state: createAcceptanceReportLocation(location),
      },
    );
  }

  function handleSendWithoutSignatureButtonClick() {
    setShowHasNotSignedDialog(false);

    navigate(
      acceptanceReportRoutes.sendEmail.generatePath({ acceptanceReportId: acceptanceReport.id }),
      {
        state: createAcceptanceReportLocation(location),
      },
    );
  }

  function handleDuplicationSuccess(duplicatedAcceptanceReportId: string) {
    navigate(
      acceptanceReportRoutes.detail.generatePath({
        acceptanceReportId: duplicatedAcceptanceReportId,
      }),
    );
  }

  return (
    <PageLayoutWithStickySidebar
      header={
        <AcceptanceReportDetailHeader
          acceptanceReport={acceptanceReport}
          project={project}
          parentFolders={parentFolders}
        />
      }
      sidebarTop={
        <AcceptanceReportDetailSidebarTop
          acceptanceReport={acceptanceReport}
          scopes={scopes}
          onEditButtonClick={handleEditButtonClick}
          onSendButtonClick={handleSendButtonClick}
          onDownloadButtonClick={handleDownloadButtonClick}
        />
      }
      sidebarBottom={
        <AcceptanceReportDetailSidebarBottom
          acceptanceReport={acceptanceReport}
          scopes={scopes}
          projectId={project.id}
          signable={signable}
          signedManually={signedManually}
          pdfSource={pdfSource}
          onTogglePdfButtonClick={handleTogglePdfButtonClick}
          onRemoveManualPdfButtonClick={() => setShowRemoveManualUploadedPdfDialog(true)}
          onRemoveSuccess={handleRemoveSuccess}
        />
      }
      footer={
        <FlexGrid>
          <FlexGridColumn sm={8}>
            <AcceptanceReportLogsList acceptanceReportId={acceptanceReport.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <AcceptanceReportDetailAlerts
          name={acceptanceReport.name}
          signedManually={signedManually}
        />

        {pdfPath && (
          <PDFViewer document={pdfPath} downloadPermitted={downloadPermitted} key={pdfPath} />
        )}
      </Stack>

      <AcceptanceReportHasNotSignedDialog
        open={showHasNotSignedDialog}
        onRequestClose={() => setShowHasNotSignedDialog(false)}
        onSendWithoutSignatureButtonClick={handleSendWithoutSignatureButtonClick}
        onSignNowButtonClick={handleSignNowButtonClick}
      />

      <ConfirmAcceptanceReportDuplication
        open={showDuplicationDialog}
        title={acceptanceReport.name}
        acceptanceReportId={acceptanceReport.id}
        onRequestClose={() => setShowDuplicationDialog(false)}
        onDuplicateSuccess={handleDuplicationSuccess}
      />

      <AcceptanceReportDetailRemoveManualPdfDialog
        open={showRemoveManualUploadedPdfDialog}
        id={acceptanceReport.id}
        name={acceptanceReport.name}
        onRemoveSuccess={() => setShowRemoveManualUploadedPdfDialog(false)}
        onRequestClose={() => setShowRemoveManualUploadedPdfDialog(false)}
      />
    </PageLayoutWithStickySidebar>
  );
}
