import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIn, useFormikContext } from 'formik';
import { Icon, IconButton, Paper, Text } from '@hs-baumappe/legacy-ui';

import { OperationUnit } from '../../../../globalTypes';
import FieldLayout from '../../../../components/formik/FieldLayout';
import MeasurementInput from './MeasurementInput';
import { evalValue, hasTotal, hasValue, reduceValues } from './values';
import FormFeedback from '../../../../components/form/FormFeedback';
import { valueValidationSchemaFactory } from './validationSchema';
import Choice from '../../../../components/form/Choice';
import Input from '../../../../components/form/Control/Input';
import NumberInput from '../../../../components/form/Control/NumberInput';

export const dimensionByType = (type: OperationUnit): number => {
  switch (type) {
    case OperationUnit.CUBIC_METER:
      return 3;
    case OperationUnit.SQUARE_METER:
      return 2;
    case OperationUnit.DAY:
    case OperationUnit.HOUR:
    case OperationUnit.KILOGRAM:
    case OperationUnit.LITER:
    case OperationUnit.METER:
    case OperationUnit.FLAT_RATE:
    default:
      return 0;
  }
};

interface Props {
  type: OperationUnit;
  index: number;
  name: string;
  onClickRemoveMeasurement: (index: number) => void;
}

const MeasurementSection: FC<Props> = ({ type, name, index, onClickRemoveMeasurement }) => {
  const [subtractionDisable, setSubtractionDisable] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, touched, values, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation();

  const dimension = dimensionByType(type);
  const fields = [...Array(dimension)]
    .map((_, y) => String.fromCharCode(y + 65))
    .join('')
    .split('');

  const errorFeedback = (n: string) => {
    const err = getIn(errors, n);

    return err && t(err);
  };

  const hasGroupError = () => {
    const err = getIn(errors, `${name}.values.value`);
    return typeof err === 'string';
  };

  const valueError = (n: string) => {
    const originName = hasGroupError() ? `${name}.values.value` : n;

    return errorFeedback(originName);
  };

  const valueFieldStatus = (n: string) => {
    const value = getIn(values, n);
    const hasError = getIn(errors, n);
    const isTouched = getIn(touched, n);

    if (hasGroupError()) {
      if (value) return 'valid';

      return 'invalid';
    }

    const validStatus = hasError ? 'invalid' : 'valid';

    return isTouched ? validStatus : 'idle';
  };

  const showEvalValueResult = (n: string) => {
    const v = values.measurements[index].values;
    const value = getIn(values, n) || '1';
    let evalMeasurementValue = evalValue(value);

    if (
      evalMeasurementValue === parseFloat(value) ||
      Number.isNaN(evalMeasurementValue) ||
      evalMeasurementValue === Infinity
    ) {
      return;
    }

    if (v.subtraction) {
      evalMeasurementValue *= -1;
    }

    // eslint-disable-next-line consistent-return
    return t('measurementForm.fieldTotalText', { total: evalMeasurementValue });
  };

  useEffect(() => {
    const v = values.measurements[index].values;

    if (!type) return;

    if (!hasValue(v)) {
      return;
    }
    const d = dimensionByType(type);
    if (d === 0) return;
    const validator = valueValidationSchemaFactory(d, true);
    const metas = v.value;

    if (!validator) return;
    const isValid = validator.isValidSync(metas);

    if (!isValid) {
      return;
    }
    let total = reduceValues(metas);
    if (!total) {
      return;
    }

    if (hasTotal(v) && evalValue(v.total) === total) return;

    setSubtractionDisable(false);
    if (total < 0) {
      setSubtractionDisable(true);
      setFieldValue(`measurements[${index}].values.subtraction`, true);
    }

    if (v.subtraction) {
      total *= -1;
    }

    setFieldValue(`${name}.values.total`, `${Math.abs(total)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Paper className="u-margin-top u-padding-xsmall">
      <div className="row">
        <div className="col">
          <div className="u-display-flex u-justify-content-space-between">
            <Text variant="title-medium">{values.measurements[index].values.name}</Text>

            <IconButton
              type="button"
              icon={<Icon name="close" />}
              onClick={() => onClickRemoveMeasurement(index)}
            />
          </div>
        </div>
      </div>
      <div className="row u-margin-bottom-xsmall u-margin-top">
        <div className="col col--sm-6 u-margin-bottom-xsmall">
          <FieldLayout
            name={`${name}.values.name`}
            label={t('measurementForm.name.label')}
            labelHtmlFor="input-name-area"
            status={valueFieldStatus(`${name}.values.name`)}
            feedback={{ invalid: valueError(`${name}.values.name`) }}
          >
            {(inputProps) => (
              <Input
                id="input-name-area"
                placeholder={t('measurementForm.name.placeholder')}
                {...inputProps}
              />
            )}
          </FieldLayout>
        </div>
        <div className="col col--sm-6 u-display-flex u-margin-top u-align-items-center u-margin-bottom-xsmall">
          <FieldLayout
            name={`${name}.values.subtraction`}
            labelHtmlFor={`input-subtraction-area-${index}`}
            status={subtractionDisable ? 'disabled' : 'idle'}
          >
            {(inputProps) => (
              <Choice
                type="checkbox"
                id={`input-subtraction-area-${index}`}
                label={t('measurementForm.subtraction.label')}
                {...inputProps}
                checked={values.measurements[index].values.subtraction}
              />
            )}
          </FieldLayout>
        </div>
        {fields.map((f, i) => (
          <div
            className="col col--sm-6 u-margin-bottom-xsmall"
            key={`input-area-side-${f.toLowerCase()}`}
          >
            <FieldLayout
              name={`${name}.values.value[${i}]`}
              label={t('measurementForm.values.label', {
                value: f,
                type: t(`operationUnit.${type}.unit`),
              })}
              labelHtmlFor={`input-area-side-${f.toLowerCase()}`}
              status={valueFieldStatus(`${name}.values.value[${i}]`)}
              feedback={{
                invalid: valueError(`${name}.values.value[${i}]`),
              }}
            >
              {(inputProps) => (
                <Fragment>
                  <MeasurementInput
                    id={`input-area-side-${f.toLowerCase()}`}
                    placeholder={t('measurementForm.values.placeholder', { value: f })}
                    {...inputProps}
                  />
                  {showEvalValueResult(`${name}.values.value[${i}]`) && (
                    <FormFeedback className="c-field-layout__feedback" theme="valid">
                      {showEvalValueResult(`${name}.values.value[${i}]`) || ''}
                    </FormFeedback>
                  )}
                </Fragment>
              )}
            </FieldLayout>
          </div>
        ))}
        <Fragment>
          <div className="col col--sm-6 u-margin-bottom-xsmall">
            <FieldLayout
              name={`${name}.values.total`}
              label={t(type ? `operationUnit.${type}.total` : `operationUnit.NULL.label`)}
              labelHtmlFor="input-area-square-meters"
              feedback={{ invalid: errorFeedback(`${name}.values.total`) }}
            >
              {(inputProps) => (
                <>
                  <MeasurementInput
                    id="input-area-square-meters"
                    placeholder={
                      type
                        ? t(`operationUnit.${type}.placeholder`)
                        : t(`operationUnit.NULL.placeholder`)
                    }
                    {...inputProps}
                    value={values.measurements[index].values.total || ''}
                    dataTestId="measurement-input"
                    onChange={(e) => {
                      const currentValue = e.currentTarget.value;
                      const total = parseFloat(currentValue);

                      if (total < 0) {
                        setSubtractionDisable(true);
                        setFieldValue(`measurements[${index}].values.subtraction`, true);
                      } else {
                        setSubtractionDisable(false);
                      }

                      setFieldValue(`${name}.values.total`, `${total}`);
                      inputProps.onChange(e);
                    }}
                  />
                  {showEvalValueResult(`${name}.values.total`) && (
                    <FormFeedback className="c-field-layout__feedback" theme="valid">
                      {showEvalValueResult(`${name}.values.total`) || ''}
                    </FormFeedback>
                  )}
                </>
              )}
            </FieldLayout>
          </div>
          <div className="col col--sm-6 u-margin-bottom-xsmall">
            <FieldLayout
              name={`${name}.values.multiplier`}
              label={t(`operationUnit.${type}.multiplier`)}
              labelHtmlFor="input-multiply-area"
              status={valueFieldStatus(`${name}.values.multiplier`)}
              feedback={{ invalid: valueError(`${name}.values.multiplier`) }}
            >
              {(inputProps) => (
                <NumberInput
                  {...inputProps}
                  id="input-multiply-area"
                  placeholder={t(`operationUnit.${type}.multiplier`)}
                />
              )}
            </FieldLayout>
          </div>
        </Fragment>
      </div>
    </Paper>
  );
};

export default MeasurementSection;
