const PSPDFKIT_ASSET_URL = `${process.env.REACT_APP_PSPDFKIT_ASSET_URL}/`;
export default {
  PRODUCTION_MODE_ENABLED: process.env.NODE_ENV === 'production',
  HOST_URL: process.env.REACT_APP_HOST_URL || '',
  PUBLIC_URL: process.env.PUBLIC_URL || '',
  PSPDFKIT_SERVER_URL: `${process.env.REACT_APP_PSPDFKIT_SERVER_URL || ''}/`,
  PSPDFKIT_ASSET_URL,
  PSPDFKIT_STYLESHEETS: [`${PSPDFKIT_ASSET_URL}PSPDFKit-overrides.css`],
  PSPDFKIT_LICENCE_KEY: process.env.REACT_APP_PSPDFKIT_LICENCE_KEY || '',
};
