import { useEffect, useState } from 'react';
import { IconButton, Icon } from '@hs-baumappe/legacy-ui';
import useCanvas from '../../hooks/useCanvas';
import { getObjectPosition } from '../../canvasUtils';

export default function ForwardAction(): JSX.Element {
  const { canvas, activeObjects, objects } = useCanvas();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (activeObjects.length !== 1) {
      setDisabled(true);
      return;
    }

    const position = getObjectPosition(activeObjects[0], objects);
    const canMove = position < objects.length - 1;

    setDisabled(!canMove);
  }, [activeObjects, objects, setDisabled]);

  const handleForward = () => {
    if (!canvas) {
      return;
    }

    canvas.getActiveObjects().forEach((object) => {
      canvas.bringForward(object);
    });

    canvas.fire('index:changed');
  };

  return (
    <IconButton icon={<Icon name="flip-to-front" />} onClick={handleForward} disabled={disabled} />
  );
}
