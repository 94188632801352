import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { EmailFormValues } from '../../../containers/Email';

export interface GetEmailFormInitialValuesOptions {
  qsstId: string;
  projectName: string;
  qsstName: string;
  attachDocument: boolean;
  externalLink: string;
  viewer: Viewer;
}

export function getEmailFormInitialValues(
  {
    qsstId,
    projectName,
    qsstName,
    attachDocument,
    externalLink,
    viewer,
  }: GetEmailFormInitialValuesOptions,
  t: TFunction,
): Partial<EmailFormValues> {
  const authorDetails = {
    authorFirstName: viewer.firstName || '',
    authorLastName: viewer.lastName || '',
    authorPhone: viewer.phone || '',
    authorMobile: viewer.mobile || '',
    authorEmail: viewer.email || '',
  };

  const content = attachDocument
    ? t('qsstSendEmailForm.initialValues.contentAsAttachment', authorDetails)
    : t('qsstSendEmailForm.initialValues.contentAsLink', {
        ...authorDetails,
        qsstId,
        externalLink,
      });

  return {
    subject: t('qsstSendEmailForm.initialValues.subject', {
      projectName,
      qsstName,
    }),
    content,
    documentIds: [qsstId],
  };
}
