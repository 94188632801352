import { FC } from 'react';
import { Icon, Text } from '@hs-baumappe/legacy-ui';

import { Tr, Td } from '../../../../../../components/Table';
import { HsProject } from '../__generated__/graphql';

interface Props {
  id?: string;
  project: HsProject;
  activeProject?: HsProject;
  activeProjectIcon?: HsProject;
  onClickProject: (value: HsProject) => void;
}

const ProjectsTableRow: FC<Props> = ({
  id,
  project,
  activeProject,
  activeProjectIcon,
  onClickProject,
}) => {
  return (
    <Tr
      id={id}
      key={project.id}
      selected={activeProject && project.id === activeProject.id}
      onClick={() => onClickProject(project)}
    >
      <Td>
        <Text variant="body-large">{project.projectNumber}</Text>
      </Td>
      <Td>
        <Text variant="body-large">{project.name}</Text>
      </Td>
      <Td className="c-table__td-small">
        {activeProjectIcon === project && <Icon name="check" />}
      </Td>
    </Tr>
  );
};

export default ProjectsTableRow;
