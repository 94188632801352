import { ChangeEvent, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider } from 'formik';
import { Button, Details, Icon, IconProps, Summary, Text } from '@hs-baumappe/legacy-ui';

import SidebarLayout from '../../../../../components/layouts/SidebarLayout';

import Select from '../../../../../components/form/Control/Select';
import Input from '../../../../../components/form/Control/Input';
import RichTextEditor from '../../../../../components/form/Control/RichTextEditor';

import FieldLayout from '../../../../../components/formik/FieldLayout/FieldLayout';

import MeasurementsSections from '../../../measurement/MeasurementsSection/MeasurementsSections';

import useForm from '../../../../../hooks/useForm';

import { BaseValues } from './values';
import { defaultValues, folderFormValidationSchema } from './form';
import Choice from '../../../../../components/form/Choice';
import { OfferMode } from '../../../../../globalTypes';

interface Props {
  parentId?: string;
  readonly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categories: any[];
  framework?: boolean;
  buttonText: ReactNode;
  offerModeSystemNumber?: boolean;
  buttonIcon: IconProps['name'];
  initialValues?: Partial<BaseValues>;
  onSubmit: (values: BaseValues) => void;
  onRemoveFolderButtonClick?: () => void;
  renderSubmitButton?: boolean;
  folderRemoving?: boolean;
  onClickAliasRightIcon?: () => void;
}

const FolderForm: FC<Props> = ({
  parentId,
  readonly,
  framework,
  buttonText,
  offerModeSystemNumber,
  buttonIcon,
  categories,
  onSubmit,
  onRemoveFolderButtonClick,
  initialValues,
  renderSubmitButton = true,
  folderRemoving,
  onClickAliasRightIcon,
}) => {
  const { t } = useTranslation();

  const { formik, errorFeedback } = useForm<BaseValues>({
    validationSchema: folderFormValidationSchema(
      false,
      framework,
      offerModeSystemNumber ? OfferMode.SYSTEM_NUMBER : OfferMode.ALIAS_NUMBER,
    ),
    initialValues: defaultValues(initialValues),
    onSubmit,
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const categoryFinder = (id?: string) => categories.find((c?) => c.id === id);

  const selectedCategory = categoryFinder(values.category);

  const handleCategorySelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;

    if (value === values.category) return;
    const category = categoryFinder(value);

    if (!category) return;

    setFieldValue('name', category.name || '');
  };

  const categoryOptions = categories.map((c) => ({
    label: `${c.partNo}. ${c.name ? c.name : ''}`,
    value: c.id,
  }));

  const isInitialCollapsed = initialValues ? !initialValues.note : true;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className="row u-margin-top-large">
          <div className="col col--sm-8">
            <div className="row">
              {framework && (
                <div className="col col--sm-6">
                  <FieldLayout
                    name="category"
                    label={t('folderForm.category.label')}
                    labelHtmlFor="input-category"
                    feedback={{ invalid: errorFeedback('category') }}
                  >
                    {({ onChange, ...inputProps }) =>
                      readonly ? (
                        <>
                          <Text component="p" className="u-margin-top-0">
                            {selectedCategory &&
                              t('folderForm.selectedCategory', {
                                partNo: selectedCategory.partNo,
                                name: selectedCategory.name,
                              })}
                          </Text>
                          <Text component="p">
                            {t('folderForm.areaAlreadyAssignedToSubgroupError')}
                          </Text>
                        </>
                      ) : (
                        <Select
                          {...inputProps}
                          id="framework-tlk"
                          options={categoryOptions}
                          placeholder={t('folderForm.category.placeholder')}
                          onChange={(e) => {
                            onChange(e);
                            handleCategorySelect(e);
                          }}
                        />
                      )
                    }
                  </FieldLayout>
                </div>
              )}
              {!framework && (
                <div className="col col--sm-6">
                  <FieldLayout
                    name="alias"
                    label={t('offer.alias.label')}
                    labelHtmlFor="input-name-of-the-create-alias"
                    className="u-margin-bottom-xsmall u-width-100%"
                    feedback={{ invalid: errorFeedback('alias') }}
                  >
                    {(props) => (
                      <Input
                        {...props}
                        id="input-name-of-the-alias"
                        disabled={offerModeSystemNumber}
                        contentEditable={offerModeSystemNumber}
                        rightIconName={offerModeSystemNumber ? 'info' : undefined}
                        onRightIconClick={offerModeSystemNumber ? onClickAliasRightIcon : undefined}
                      />
                    )}
                  </FieldLayout>
                </div>
              )}
              <div className="col col--sm-6">
                <FieldLayout
                  name="name"
                  label={t('folderForm.name.label')}
                  labelHtmlFor="input-name-of-the-create-group"
                  className="u-margin-bottom-xsmall u-width-100%"
                  feedback={{ invalid: errorFeedback('name') }}
                >
                  {(props) => (
                    <Input
                      id="input-name-of-the-create-group"
                      placeholder={
                        parentId
                          ? t('folderForm.name.forSubgroup.placeholder')
                          : t('folderForm.name.forGroup.placeholder')
                      }
                      {...props}
                    />
                  )}
                </FieldLayout>
              </div>
            </div>
            <hr />
            <div className="u-margin-top">
              <Details
                defaultOpen={!isInitialCollapsed}
                renderSummary={({ onClick }) => (
                  <Summary onClick={onClick}>
                    <Text
                      variant="title-small"
                      color="muted"
                      component="h3"
                      className="u-margin-bottom-0"
                    >
                      {t('folderForm.commentAndAttachment.title')}
                    </Text>
                  </Summary>
                )}
              >
                <FieldLayout
                  name="note"
                  label={t('folderForm.comment.label')}
                  className="u-margin-top-xsmall"
                  labelHtmlFor="input-kommentar"
                  feedback={{ invalid: errorFeedback('note') }}
                >
                  {(props) => <RichTextEditor id="input-kommentar" enableResizeImage {...props} />}
                </FieldLayout>
              </Details>
            </div>
            <hr />
            <div className="row">
              <div className="col col--sm-6">
                <Text color="muted" variant="title-small">
                  {t('folderForm.measurement.title')}
                </Text>
                <Text component="p">
                  {t(
                    parentId
                      ? 'folderForm.measurement.forSubgroup.text'
                      : 'folderForm.measurement.forGroup.text',
                  )}
                </Text>
              </div>
            </div>
            <hr />
            <div className="u-margin-top">
              <MeasurementsSections parent={!!parentId} />
            </div>
          </div>
          <div className="col col--sm-4">
            <SidebarLayout>
              {renderSubmitButton && (
                <Button
                  id="create-new-folder"
                  type="submit"
                  color="primary"
                  fullWidth
                  endIcon={<Icon name={buttonIcon} />}
                >
                  {buttonText}
                </Button>
              )}

              <FieldLayout
                className="u-margin-top"
                name="approximate"
                labelHtmlFor="input-ca"
                feedback={{ invalid: errorFeedback('approximate') }}
              >
                {(inputProps) => (
                  <Choice
                    id="input-ca"
                    type="checkbox"
                    checked={formik.values.approximate}
                    label={t('folderForm.ca.label')}
                    {...inputProps}
                  />
                )}
              </FieldLayout>

              {onRemoveFolderButtonClick && (
                <Button
                  className="u-margin-top"
                  color="error"
                  variant="text"
                  type="button"
                  onClick={onRemoveFolderButtonClick}
                  loading={folderRemoving}
                >
                  {t(
                    parentId
                      ? 'folderForm.forSubgroup.deleteOperation'
                      : 'folderForm.forGroup.deleteOperation',
                  )}
                </Button>
              )}
            </SidebarLayout>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default FolderForm;
