import { TFunction } from 'i18next';
import { Viewer } from '@hs-baumappe/web-auth';
import { EmailFormValues } from '../../../containers/Email';
import { CustomFileDocument } from '../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../graphql/__generated__/CustomFileDocumentProject.fragment';

export function getEmailFormInitialValues(
  document: CustomFileDocument,
  project: CustomFileDocumentProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  const subjectTranslationKey = 'customFile.sendEmail.subject';
  const contentTranslationKey = viewer.signatureEmail
    ? 'customFile.sendEmail.content'
    : 'customFile.sendEmail.content_with_no_email';

  return {
    subject: t(subjectTranslationKey, {
      projectName: project.name,
      additionalName: project.additionalName,
      documentName: document.name,
    }),
    content: t(contentTranslationKey, {
      projectName: project.projectNumber,
      documentName: document.name,
      ...viewer,
      email: viewer.signatureEmail ?? viewer.email,
    }),

    documentIds: [document.id],
  };
}
