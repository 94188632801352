import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import AcceptanceReportArchiveMutation from '../../graphql/AcceptanceReportArchiveMutation';
import AcceptanceReportDetailQuery from '../../../hooks/useAcceptanceReport/graphql/AcceptanceReportDetail.query';
import { AcceptanceReportDetailAlert } from '../../../AcceptanceReportDetail/AcceptanceReportDetail.utils';
import { acceptanceReportRoutes } from '../../../acceptanceReportRoutes';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import { DriveDocumentArchiveInput } from '../../../../../globalTypes';
import { ArchiveFormValues } from '../../../../../containers/ArchiveForm';

interface UseAcceptanceReportArchiveResponse {
  archive: (
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useAcceptanceReportArchive(
  acceptanceReportId: string,
): UseAcceptanceReportArchiveResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutate, { loading }] = useMutation(AcceptanceReportArchiveMutation, {
    refetchQueries: [{ query: AcceptanceReportDetailQuery, variables: { acceptanceReportId } }],
  });
  const { setSapCredentials } = useSapCredentials();

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    setError(undefined);

    try {
      const { data } = await mutate({
        variables: {
          input: {
            id: acceptanceReportId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
      });

      if (data) {
        setSapCredentials({ username: values.username, password: values.password });
      }

      const searchParams = new URLSearchParams({
        alert: AcceptanceReportDetailAlert.ARCHIVE,
      });

      navigate(
        createPath({
          pathname: acceptanceReportRoutes.detail.generatePath({ acceptanceReportId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    archive,
    loading,
    error,
  };
}
