import { gql, TypedDocumentNode } from '@apollo/client';
import RiskAssessmentFragment from '../../graphql/RiskAssessment.fragment';
import {
  RiskAssessmentEdit,
  RiskAssessmentEditVariables,
} from './__generated__/RiskAssessmentEdit.mutation';

const RiskAssessmentEditMutation: TypedDocumentNode<
  RiskAssessmentEdit,
  RiskAssessmentEditVariables
> = gql`
  mutation RiskAssessmentEdit($input: RiskAssessmentSaveInput!) {
    riskAssessmentSave(input: $input) {
      ...RiskAssessment
    }
  }

  ${RiskAssessmentFragment}
`;

export default RiskAssessmentEditMutation;
