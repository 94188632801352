import { AcceptanceReportDetailAlert } from '../../AcceptanceReportDetail.utils';
import i18n from '../../../../../i18n';

export function getAlertContent(alert: AcceptanceReportDetailAlert, name: string): string {
  switch (alert) {
    case AcceptanceReportDetailAlert.SEND_EMAIL:
      return i18n.t('acceptanceReport.sendEmail.alert');
    case AcceptanceReportDetailAlert.ARCHIVE:
      return i18n.t('acceptanceReport.archive.alert', { name });
    case AcceptanceReportDetailAlert.SIGNED_MANUALLY:
      return i18n.t('acceptanceReport.manuallySignedPdf.success');
    default:
      return '';
  }
}
