import { useState, useRef } from 'react';
import debounce from 'lodash.debounce';

import useHSProjectSearchQuery from './useHSProjectSearchQuery';
import { HsProject } from './__generated__/graphql';

interface UseHsProjectSearchParams {
  projects?: HsProject[];
  searchProject: (value?: string | undefined) => Promise<void> | undefined;
}

const useHsProjectSearch = (): UseHsProjectSearchParams => {
  const [projects, setProjects] = useState<HsProject[]>();
  const projectSearch = useHSProjectSearchQuery();

  const searchProject = async (value?: string) => {
    if (!value || value.length < 2) {
      return;
    }

    const data = await projectSearch(value);
    setProjects(data.projects);
  };

  const debouncedSearch = useRef(debounce(searchProject, 2000));

  return { projects, searchProject: debouncedSearch.current };
};

export default useHsProjectSearch;
