import { gql, TypedDocumentNode } from '@apollo/client';
import ConcernFragment from '../../../graphql/Concern.fragment';
import { ConcernSave, ConcernSaveVariables } from './__generated__/ConcernSaveMutation';

const ConcernSaveMutation: TypedDocumentNode<ConcernSave, ConcernSaveVariables> = gql`
  mutation ConcernSave($input: ConcernSaveInput!) {
    concernSave(input: $input) {
      ...Concern
    }
  }

  ${ConcernFragment}
`;

export default ConcernSaveMutation;
