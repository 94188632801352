import { gql, TypedDocumentNode } from '@apollo/client';
import {
  CreateNewOvertimeGetProjectDetail,
  CreateNewOvertimeGetProjectDetailVariables,
  OvertimeSave,
  OvertimeSaveVariables,
} from './__generated__/graphql';

export const OvertimeSaveMutation: TypedDocumentNode<OvertimeSave, OvertimeSaveVariables> = gql`
  mutation OvertimeSave($input: OvertimeSaveInput!) {
    overtimeSave(input: $input) {
      id
      name
    }
  }
`;

export const CREATE_NEW_OVERTIME_GET_PROJECT_DETAIL: TypedDocumentNode<
  CreateNewOvertimeGetProjectDetail,
  CreateNewOvertimeGetProjectDetailVariables
> = gql`
  query CreateNewOvertimeGetProjectDetail($id: ID!) {
    project(id: $id) {
      id

      description
      additionalName
    }
  }
`;
