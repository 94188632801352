import { ArrayHelpers, FieldArray, useField } from 'formik';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { ObstructionFormValues } from '../../ObstructionForm.form';
import ObstructionFormFact from './components/ObstructionFormFact';
import ObstructionFormFactValue from './components/ObstructionFormFact/ObstructionFormFact.type';

export default function ObstructionFormFactsSection(): JSX.Element {
  const { t } = useTranslation();

  const [factsInputProps] = useField<ObstructionFormValues['facts']>('facts');
  const facts = factsInputProps.value;

  const showFactRemoveButtons = facts.length >= 2;

  return (
    <FieldArray name="facts">
      {(arrayHelpers: ArrayHelpers<ObstructionFormFactValue[]>) => (
        <>
          <Text component="div" color="muted" variant="title-small">
            {t('obstructionForm.facts.title')}
          </Text>

          <Text component="p" variant="body-small">
            {t('obstructionForm.facts.informationText')}
          </Text>
          <Text component="p" variant="body-small">
            {t('obstructionForm.facts.informationText2')}
          </Text>

          <div className="u-margin-top-small u-margin-bottom">
            {facts.map((fact, index) => (
              <ObstructionFormFact
                name={`facts[${index}]`}
                key={`facts[${index}]`}
                onRequestRemove={
                  showFactRemoveButtons ? () => arrayHelpers.remove(index) : undefined
                }
              />
            ))}
          </div>

          <div className="row">
            <div className="col col--sm-6">
              <Button
                fullWidth
                endIcon={<Icon name="add" />}
                onClick={() =>
                  arrayHelpers.push({
                    title: '',
                    description: '',
                  })
                }
                type="button"
              >
                {t('obstructionForm.facts.addAFactButton')}
              </Button>
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
}
