import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { MissingScopeError } from '@hs-baumappe/redkit';
import RouteDialog from '../../../containers/RouteDialog';
import ConcernSendEmailBody from './ConcernSendEmailBody';
import { concernRoutes } from '../concernRoutes';
import { ConcernLocationState } from '../Concern.route.types';
import { Concern } from '../graphql/__generated__/Concern.fragment';
import { ConcernProject } from '../graphql/__generated__/ConcernProject.fragment';
import { ConcernScopes } from '../Concern.types';

interface ConcernSendEmailProps {
  concern: Concern;
  project: ConcernProject;
  scopes: ConcernScopes;
}

export default function ConcernSendEmail({
  concern,
  project,
  scopes,
}: ConcernSendEmailProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<ConcernLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  function handleDialogRequestClose() {
    if (formDirty) {
      setShowConfirmationDialog(true);
      return;
    }

    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(concernRoutes.detail.generatePath({ concernId: concern.id }));
  }, [concern.id, location.state, navigate]);

  const permitted = scopes.sendConcernDocumentAttachment.isPermitted;
  const missingScopeNames = scopes.sendConcernDocumentAttachment.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('concern.sendPdfEmail.route.subtitle', { name: concern.name })}
          </Text>
          <Text variant="title-small" component="div" color="muted">
            {t('concernSendEmail.status', { status: t(concern.status) })}
          </Text>
        </>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <ConcernSendEmailBody
          concern={concern}
          project={project}
          onRequestClose={() => setDialogOpen(false)}
          onDirtyStateChange={setFormDirty}
        />
      )}

      <AlertDialog
        open={showConfirmationDialog}
        title={t('concernSendEmail.confirmationModal.title', { concernName: concern.name })}
        description={t('concernSendEmail.confirmationModal.body')}
        confirmButtonText={t('concernSendEmail.confirmationModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('concernSendEmail.confirmationModal.dismissButton')}
        onConfirm={() => {
          setShowConfirmationDialog(false);
          setDialogOpen(false);
        }}
        onCancel={() => setShowConfirmationDialog(false)}
        onRequestClose={() => setShowConfirmationDialog(false)}
        destructive
      />
    </RouteDialog>
  );
}
