import { TFunction } from 'i18next';
import { Viewer } from '@hs-baumappe/web-auth';
import { Obstruction } from '../../../graphql/__generated__/Obstruction.fragment';
import { ObstructionProject } from '../../../graphql/__generated__/ObstructionProject.fragment';
import { EmailFormValues } from '../../../../../containers/Email';

interface ObstructionSendEmailFormMetaData {
  obstruction: Obstruction;
  project: ObstructionProject;
  viewer: Viewer;
}

export function obstructionSendEmailFormInitialValues(
  t: TFunction,
  metadata: ObstructionSendEmailFormMetaData,
): Partial<EmailFormValues> {
  const initialValues: Partial<EmailFormValues> = {
    subject: t('obstructionSendEmailForm.initialValues.subject', {
      projectName: metadata.project.name,
      obstructionName: metadata.obstruction.name,
    }),
    content: t('obstructionSendEmailForm.initialValues.content', {
      projectDescription: metadata.project.description,
      authorFirstName: metadata.viewer.firstName,
      authorLastName: metadata.viewer.lastName,
      authorPhone: metadata.viewer.phone || '',
      authorMobile: metadata.viewer.mobile || '',
      authorEmail: metadata.viewer.email,
    }),
    documentIds: [metadata.obstruction.id],
  };

  return initialValues;
}
