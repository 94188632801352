import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import ProtectiveEquipmentCheckbox from './components/ProtectiveEquipmentCheckbox';
import { EquipmentsFormValues } from './values';
import { defaultValue, validationSchema } from './form';
import { Equipment } from './graphql/__generated__/Equipment.fragment';
import { getInvalidEquipmentsIds, getRequiredEquipments } from './EquipmentsForm.utils';
import { EquipmentIconName } from './equipmentIcons';

import './o-protective-equipment-checkboxes.scss';

interface EquipmentsFormProps {
  equipments: Equipment[];
  initialValues?: Partial<EquipmentsFormValues>;
  onSubmit: (values: EquipmentsFormValues) => void;
  onDirtyStateChange: (dirty: boolean) => void;
  edit?: boolean;
}

export default function EquipmentsForm({
  equipments,
  initialValues,
  onSubmit,
  onDirtyStateChange,
  edit,
}: EquipmentsFormProps): JSX.Element {
  const { t } = useTranslation();
  const [invalidEquipmentIds, setInvalidEquipmentIds] = useState<string[]>([]);
  const requiredEquipments = getRequiredEquipments(equipments);

  const formik = useFormik({
    validationSchema: validationSchema(requiredEquipments),
    initialValues: defaultValue(initialValues),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit,
  });

  useEffect(() => {
    onDirtyStateChange(formik.dirty);
  }, [formik.dirty, onDirtyStateChange]);

  const { values, errors, setFieldValue, handleSubmit: handleSubmitFormik } = formik;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    const invalidEquipments = getInvalidEquipmentsIds(equipments, values.equipments);
    setInvalidEquipmentIds(invalidEquipments);

    handleSubmitFormik(event);
  }

  function isInvalidEquipment(equipment: Equipment) {
    const equipmentSelection = values.equipments.find(({ label }) => label === equipment.id);
    const checked = !!equipmentSelection?.value;

    return !checked && invalidEquipmentIds.includes(equipment.id);
  }

  function renderWarningMessage() {
    if (!Array.isArray(errors.equipments)) {
      return (
        <Text component="div" stylex={{ marginTop: 'small' }} variant="body-small" color="warning">
          {errors.equipments}
        </Text>
      );
    }

    return errors.equipments.map((error, index) => (
      <Text
        key={`equipment-${index}`}
        component="div"
        stylex={{ marginTop: 'small' }}
        variant="body-small"
        color="warning"
      >
        {typeof error === 'string' ? error : error?.value}
      </Text>
    ));
  }

  function handleProtectiveEquipmentToggle(equipment: Equipment) {
    return function (event: ChangeEvent<HTMLInputElement>) {
      const { checked } = event.currentTarget;

      const newEquipments = values.equipments.map((equipmentSelection) => {
        if (equipmentSelection.label === equipment.id) {
          return {
            ...equipmentSelection,
            value: checked,
          };
        }

        return equipmentSelection;
      });

      setFieldValue('equipments', newEquipments);
    };
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box className="row" stylex={{ marginTop: 'small-x' }}>
          <Box className="col col--md-8">
            <Box className="o-protective-equipment-checkboxes">
              {equipments.map((equipment) => (
                <ProtectiveEquipmentCheckbox
                  key={`equipment-${equipment.id}`}
                  name="equipments"
                  iconName={equipment.icon as EquipmentIconName}
                  label={equipment.title}
                  checked={!!values.equipments.find(({ label }) => label === equipment.id)?.value}
                  invalid={isInvalidEquipment(equipment)}
                  onChange={handleProtectiveEquipmentToggle(equipment)}
                />
              ))}
            </Box>
          </Box>
          <Box className="col col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button color="primary" endIcon={<Icon name="add" />} type="submit" fullWidth>
                {t(
                  edit
                    ? 'createAndUpdateRiskAssessment.updateButton'
                    : 'createAndUpdateRiskAssessment.submitButton',
                )}
              </Button>

              {renderWarningMessage()}
            </Box>
          </Box>
        </Box>
      </form>
    </FormikProvider>
  );
}
