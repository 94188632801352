import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Dialog, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { OfferLocationState } from '../../Offer.route.types';
import { OfferFolderOperationEditRouteParams } from './route';
import EditOperationForm from './EditOperationForm';
import useOfferFolderOperationEdit from './useOfferFolderOperationEdit';
import { castGqlForMeasurement } from '../../folder/containers/FolderForm/form';
import { MeasurementsValues } from '../../measurement/MeasurementsSection/values';
import useOffer from '../../hooks/offer';
import ErrorState from '../../../../containers/ErrorState';
import FolderOperationRemoveModal from '../containers/FolderOperationRemoveModal';
import { OperationValues } from './EditOperationForm/values';
import { OfferMode } from '../../../../globalTypes';
import InfoModal from '../../components/InfoModal';
import { offerRoutes } from '../../offerRoutes';

const OfferFolderOperationEdit = (): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation<OfferLocationState>();
  const [routeModalOpen, setRouteModalOpen] = useState(true);
  const [showRemoveFolderOperationModal, setShowRemoveFolderOperationModal] = useState(false);
  const { offerId, folderId, operationId } = useParams<OfferFolderOperationEditRouteParams>();

  const { getOperationById, refetchOfferData, getOffer } = useOffer();

  const { mode } = getOffer();
  const { edit, error } = useOfferFolderOperationEdit({ offerId, offerMode: mode });

  const [aliasInfoModal, setAliasInfoModal] = useState(false);

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(offerRoutes.detail.generatePath({ offerId }));
  }, [location.state?.backgroundLocation, navigate, offerId]);

  const handleClickCloneOperation = () => {
    navigate(offerRoutes.operationClone.generatePath({ offerId, folderId, operationId }));
  };

  const handleClickDeleteOperation = () => {
    setShowRemoveFolderOperationModal(true);
  };

  const operation = getOperationById(folderId, operationId);
  if (!operation) {
    return null;
  }

  const { payment, viewerCanRemove, viewerCanUpdate, viewerCanClone } = operation;

  if (!viewerCanUpdate) {
    navigate(offerRoutes.detail.generatePath({ offerId }));
    return null;
  }

  function handleSubmit(v: OperationValues) {
    if (!operation) {
      return;
    }

    edit(v, operation);
    setRouteModalOpen(false);
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {error && <ErrorState error={error} />}

      <div className="container u-width-100% u-padding-ends">
        <div className="u-display-flex u-align-items-center">
          <Text variant="title-small" component="h2" className="u-margin-bottom-0 u-flex-grow-1">
            {`${operation.partNoTree.join('.')}. ${operation.name}`}
          </Text>
          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </div>

        <div className="u-padding-top">
          <EditOperationForm
            offerModeSystemNumber={mode === OfferMode.SYSTEM_NUMBER}
            onClickAliasRightIcon={() => setAliasInfoModal(true)}
            onSubmit={handleSubmit}
            onClickCloneOperation={handleClickCloneOperation}
            onClickDeleteOperation={handleClickDeleteOperation}
            renderCloneOperationButton={viewerCanClone}
            renderDeleteOperationButton={viewerCanRemove}
            renderSubmitButton={viewerCanUpdate}
            operationUnit={operation.unit}
            initialValues={{
              alias: operation.partNoComputed,
              name: operation.name,
              price: operation.price,
              description: operation.description,
              optional: operation.optional,
              unit: operation.unit,
              note: operation.note || '',
              approximate: operation.approximate,
              payment: payment
                ? {
                    flat: payment.flat,
                    discountOrSurchargeValueType: payment.discountOrSurchargeValueType,
                    discountOrSurchargeValue: payment.discountOrSurchargeValue
                      ? `${payment.discountOrSurchargeValue}`
                      : undefined,
                    totalPrice: payment.totalPrice ? `${payment.totalPrice}` : undefined,
                  }
                : undefined,
              measurements:
                operation.measurements && operation.unit
                  ? (castGqlForMeasurement(
                      operation.measurements,
                      operation.unit,
                    ) as MeasurementsValues)
                  : [],
            }}
          />
        </div>
      </div>

      {viewerCanRemove && showRemoveFolderOperationModal && (
        <FolderOperationRemoveModal
          open
          operation={operation}
          onRequestClose={() => setShowRemoveFolderOperationModal(false)}
          onSuccess={() => {
            refetchOfferData();
            navigate(offerRoutes.detail.generatePath({ offerId }));
          }}
        />
      )}
      {aliasInfoModal && (
        <InfoModal offerId={offerId} onRequestDismiss={() => setAliasInfoModal(false)} />
      )}
    </Dialog>
  );
};

export default OfferFolderOperationEdit;
