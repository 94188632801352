import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SelectOption } from '../../../../../components/form/Control/Select';
import FieldLayout from '../../../../../components/form/FieldLayout';

interface Props {
  defaultValue?: string;
  catalogs: SelectOption[];
  onChange: (value: string) => void;
}

const CatalogSelect: FC<Props> = ({ catalogs, onChange, defaultValue }) => {
  const { t } = useTranslation();
  const [catalog, setCatalog] = useState<string>(
    defaultValue || t('offer.createNewOperation.search.catalogSelect.searchAll').toString(),
  );

  // TODO: CHANGE HERE
  const UNSELECTED_CATALOGS: SelectOption = {
    label: t('offer.createNewOperation.search.catalogSelect.unselected.label'),
    value: t('offer.createNewOperation.search.catalogSelect.unselected.value').toString(),
  };

  catalogs.unshift(UNSELECTED_CATALOGS);

  return (
    <FieldLayout
      label={t('offer.createNewOperation.search.catalogSelect.title')}
      labelHtmlFor="input-catalog"
    >
      {(fieldProps) => (
        <Select
          value={catalog}
          options={catalogs}
          id="input-catalog"
          onChange={(e) => {
            setCatalog(e.currentTarget.value);
            onChange(e.currentTarget.value);
          }}
          {...fieldProps}
        />
      )}
    </FieldLayout>
  );
};

export default CatalogSelect;
