import useCanvas from './useCanvas';

type UseCanvasZoom = {
  zoomIn: (increment: number) => void;
  zoomOut: (decrement: number) => void;
  resetZoom: () => void;
  canZoomIn: boolean;
  canZoomOut: boolean;
};

const MAX_ZOOM_ON_CANVAS = 3;
const MIN_ZOOM_ON_CANVAS = 0.1;
const DEFAULT_ZOOM_ON_CANVAS = 1;

function useCanvasZoom(): UseCanvasZoom {
  const { canvas, setZoom, zoom } = useCanvas();
  const canZoomIn = zoom < MAX_ZOOM_ON_CANVAS;
  const canZoomOut = zoom > MIN_ZOOM_ON_CANVAS;

  function zoomIn(increment: number) {
    if (!canvas) {
      return;
    }

    const nextZoomValue = canvas.getZoom() + increment;

    setZoom(nextZoomValue > MAX_ZOOM_ON_CANVAS ? MAX_ZOOM_ON_CANVAS : nextZoomValue);
  }

  function zoomOut(decrement: number) {
    if (!canvas) {
      return;
    }

    const nextZoomValue = canvas.getZoom() - decrement;

    setZoom(nextZoomValue < MIN_ZOOM_ON_CANVAS ? MIN_ZOOM_ON_CANVAS : nextZoomValue);
  }

  function resetZoom() {
    setZoom(DEFAULT_ZOOM_ON_CANVAS);
  }

  return {
    zoomIn,
    zoomOut,
    resetZoom,
    canZoomIn,
    canZoomOut,
  };
}

export default useCanvasZoom;
