import { getDocumentRoutes, route } from '../routes.utils';
import routes from '../routes';

import { QsstRouteParams } from './Qsst.route.types';

export const qsstRoutes = {
  ...getDocumentRoutes<QsstRouteParams>(routes.qsst),
  customer: route<QsstRouteParams>('/qsst/customer-detail/:qsstId'),
  manualSign: route<QsstRouteParams>(`${routes.qsst.path}/manual-sign`),
};
