import { gql } from '@apollo/client/core';

export default gql`
  mutation DefectSectionUploadImage($file: Upload!) {
    uploadImage(file: $file) {
      id
      url
    }
  }
`;
