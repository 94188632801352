import { ApolloError, useQuery } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import QsstDetailQuery from './graphql/QsstDetail.query';
import { QsstDetail } from './graphql/__generated__/QsstDetail.query';
import { QsstProject } from '../../graphql/__generated__/QsstProject.fragment';
import { QsstScopes } from '../../Qsst.types';

interface UseQsstDataResponse {
  qsst: NonNullable<QsstDetail['qsst']>;
  scopes: QsstScopes;
  project: QsstProject;
}

interface UseQsstResponse {
  data?: UseQsstDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useQsst(qsstId: string): UseQsstResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(QsstDetailQuery, {
    variables: {
      qsstId,
    },
  });

  const qsst = data?.qsst;
  const scopes = viewer?.scopes;
  const project = qsst?.project;

  return {
    data: qsst && scopes && project ? { qsst, scopes, project } : undefined,
    loading,
    error,
  };
}
