import { gql, TypedDocumentNode } from '@apollo/client';
import {
  CreateConstructionNoteProjectDetail,
  CreateConstructionNoteProjectDetailVariables,
} from './__generated__/CreateConstructionNoteProjectDetail.query';

const CreateConstructionNoteProjectDetailQuery: TypedDocumentNode<
  CreateConstructionNoteProjectDetail,
  CreateConstructionNoteProjectDetailVariables
> = gql`
  query CreateConstructionNoteProjectDetail($id: ID!) {
    project(id: $id) {
      id
      projectNumber
      hsProjectNumber
      draft

      drive {
        id

        rootFolder {
          id

          entries {
            ... on DriveFolder {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default CreateConstructionNoteProjectDetailQuery;
