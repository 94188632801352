import { useApolloClient } from '@apollo/client';

import { SEARCH_HS_PROJECTS } from './graphql';
import { SearchHsProjects, SearchHsProjectsVariables } from './__generated__/graphql';

type UseHSProjectSearchQueryParams = (t: string) => Promise<SearchHsProjects['searchHsProjects']>;

const useHSProjectSearchQuery = (): UseHSProjectSearchQueryParams => {
  const client = useApolloClient();

  return async (term: string) => {
    const response = await client.query<SearchHsProjects, SearchHsProjectsVariables>({
      query: SEARCH_HS_PROJECTS,
      variables: { term },
    });

    return response.data.searchHsProjects;
  };
};

export default useHSProjectSearchQuery;
