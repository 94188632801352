import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface SendRiskAssessmentPdfDialogProps {
  open: boolean;
  onRequestClose: () => void;
  onSendWithoutSignatureClick: () => void;
  onSignNowClick: () => void;
}

export default function SendRiskAssessmentPdfDialog({
  open,
  onRequestClose,
  onSendWithoutSignatureClick,
  onSignNowClick,
}: SendRiskAssessmentPdfDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog open={open} size="xsmall" role="alertdialog" onRequestClose={onRequestClose}>
      <DialogHeader
        rightSlot={<IconButton icon={<Icon name="close" />} onClick={onRequestClose} />}
      >
        <Text variant="title-small">{t('riskAssessmentHasNotSignedYetDialog.title')}</Text>
      </DialogHeader>

      <DialogBody>
        <Text>{t('riskAssessmentHasNotSignedYetDialog.body')}</Text>
      </DialogBody>

      <DialogActions direction="column">
        <Button fullWidth onClick={onSendWithoutSignatureClick}>
          {t('riskAssessmentHasNotSignedYetDialog.sendPdfWithoutSignatureButtonText')}
        </Button>

        <Button
          color="primary"
          endIcon={<Icon name="border-color" />}
          onClick={onSignNowClick}
          fullWidth
        >
          {t('riskAssessmentHasNotSignedYetDialog.signNowButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
