import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, IconButton, SnackbarDuration, Text, useSnackbar } from '@hs-baumappe/legacy-ui';
import ProjectImportMutation from './graphql/ProjectImport.mutation';
import { projectRoutes } from '../../../../routes';

interface UseProjectImportResponse {
  projectImport: (projectId: string) => Promise<void>;
  loading?: boolean;
}

function useProjectImport(): UseProjectImportResponse {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open: openSnackbar, close: closeSnackbar } = useSnackbar();
  const [importProject, { loading }] = useMutation(ProjectImportMutation);

  async function projectImport(projectId: string) {
    if (loading) {
      return;
    }

    try {
      const { data } = await importProject({
        variables: {
          input: {
            hsId: projectId,
          },
        },
      });

      if (!data) {
        alert(t('common.error'));
        return;
      }

      navigate(projectRoutes.detail.generatePath({ projectId: data.importProject.id }));
      openSnackbar(
        <Text variant="label-large" color="inherit">
          {t('importHsProjectModal.importSuccessfulMessage')}
        </Text>,
        {
          type: 'success',
          autoHideDuration: SnackbarDuration.MEDIUM,
          action: () => <Icon name="check" color="inherit" size={24} />,
        },
      );
    } catch (e) {
      openSnackbar(
        <Text variant="label-large" color="inherit">
          {t('importHsProjectModal.importFailMessage')}
        </Text>,
        {
          type: 'error',
          action: (snackbarId) => (
            <>
              <IconButton
                icon={<Icon name="cached" />}
                color="inherit"
                onClick={() => {
                  closeSnackbar(snackbarId);
                  projectImport(projectId);
                }}
              />

              <IconButton
                icon={<Icon name="close" />}
                color="inherit"
                onClick={() => closeSnackbar(snackbarId)}
              />
            </>
          ),
        },
      );
    }
  }

  return {
    projectImport,
    loading,
  };
}

export default useProjectImport;
