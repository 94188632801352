import { InChargeRole } from '../../../globalTypes';
import { formatISOStringToDateString } from '../../../utils/date';
import { EquipmentsFormValues } from '../RiskAssessmentForm/Equipments/values';
import { InCharge, InformationValues } from '../RiskAssessmentForm/Information/values';
import { PrecautionValues } from '../RiskAssessmentForm/Precautions/values';
import { RegionalSecurityManager } from '../graphql/__generated__/RegionalSecurityManager.fragment';
import { RiskAssessmentProject } from '../graphql/__generated__/RiskAssessmentProject.fragment';
import { RiskAssessmentEditDetail } from './graphql/__generated__/RiskAssessmentEditDetail.query';

function getInChargeWithRole(inCharges: InCharge[], role: InChargeRole): InCharge {
  const personInCharge = inCharges.find((inCharge) => inCharge.role === role);

  return {
    fullName: personInCharge?.fullName || '',
    phone: personInCharge?.phone || '',
    role,
  };
}

function createDefaultSecurityManager(): InCharge {
  return {
    fullName: '',
    phone: '',
    role: InChargeRole.REGION_SECURITY_MANAGER,
  };
}

type GetSecurityManagersFromInChargeParams = {
  regionalSecurityManagers: RegionalSecurityManager[];
  inCharges: InCharge[];
};

type RegionalSecurityManagersValues = Pick<
  InformationValues,
  'regionalSecurityManagers' | 'additionalRegionalSecurityManagers'
>;

export function getInitialSecurityManagerValues({
  regionalSecurityManagers,
  inCharges,
}: GetSecurityManagersFromInChargeParams): RegionalSecurityManagersValues {
  const inChargeRegionalSecurityManagers = inCharges.filter(
    ({ role }) => role === InChargeRole.REGION_SECURITY_MANAGER,
  );

  const existingRegionalSecurityManagers = inChargeRegionalSecurityManagers.filter(
    (inChargeManager) => {
      return regionalSecurityManagers.some(
        (securityManager) => securityManager.fullName === inChargeManager.fullName,
      );
    },
  );

  const additionalSecurityManagers = inChargeRegionalSecurityManagers.filter((manager) => {
    return !existingRegionalSecurityManagers.some(({ fullName }) => fullName === manager.fullName);
  });

  if (additionalSecurityManagers.length === 0) {
    additionalSecurityManagers.push(createDefaultSecurityManager(), createDefaultSecurityManager());
  }

  if (additionalSecurityManagers.length === 1) {
    additionalSecurityManagers.push(createDefaultSecurityManager());
  }

  return {
    regionalSecurityManagers: existingRegionalSecurityManagers.map((manager) => ({
      fullName: manager.fullName,
      role: manager.role,
      phone: manager.phone || '',
    })),
    additionalRegionalSecurityManagers: additionalSecurityManagers.map((manager) => ({
      fullName: manager.fullName,
      role: manager.role,
      phone: manager.phone || '',
    })),
  };
}

export function mapRiskAssessmentInformationInitialValues(
  riskAssessment: RiskAssessmentEditDetail['riskAssessment'],
  regionalSecurityManagers: RegionalSecurityManager[],
  project: RiskAssessmentProject,
): InformationValues {
  const { inCharges, draftProjectNumber, description, clientNameComputed, date } = riskAssessment;

  const securityManagers = getInitialSecurityManagerValues({ regionalSecurityManagers, inCharges });

  return {
    date: formatISOStringToDateString(date),
    projectNr: project.draft ? draftProjectNumber || '-' : project.hsProjectNumber || '-',
    customerName: clientNameComputed || '-',
    description,
    firstAider: getInChargeWithRole(inCharges, InChargeRole.FIRST_AIDER),
    operationManager: getInChargeWithRole(inCharges, InChargeRole.OPERATION_MANAGER),
    regionalSecurityManagers: securityManagers.regionalSecurityManagers,
    additionalRegionalSecurityManagers: securityManagers.additionalRegionalSecurityManagers,
    securityManager: getInChargeWithRole(inCharges, InChargeRole.SECURITY_MANAGER),
    sideManager: getInChargeWithRole(inCharges, InChargeRole.SIDE_MANAGER),
  };
}

export function mapRiskAssessmentPrecautionInitialValues(
  riskAssessment: RiskAssessmentEditDetail['riskAssessment'],
): PrecautionValues {
  const { precautions, specialPrecautions } = riskAssessment;
  return {
    precautions: precautions.map((precaution) => precaution.id),
    specialPrecautions: specialPrecautions.map((specialPrecaution) => ({
      title: specialPrecaution.title,
      precautionCategory: specialPrecaution.precautionCategory.id,
    })),
  };
}

export function mapRiskAssessmentEquipmentInitialValues(
  riskAssessment: RiskAssessmentEditDetail['riskAssessment'],
): EquipmentsFormValues {
  const { equipments } = riskAssessment;

  return {
    equipments: equipments.map((equipment) => ({
      label: equipment.id,
      value: true,
      required: false,
    })),
  };
}
