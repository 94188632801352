import { useState } from 'react';
import { createPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';
import useDriveDocumentParentFolders from '../../../hooks/useDriveDocumentParentFolders';
import RiskAssessmentDetailHeader from './components/RiskAssessmentDetailHeader';
import RiskAssessmentDetailAlerts from './components/RiskAssessmentDetailAlerts';
import PDFViewer from '../../../components/PDFViewer';
import RiskAssessmentDetailSidebarTop from './components/RiskAssessmentDetailSidebarTop';
import RiskAssessmentDetailSidebarBottom from './components/RiskAssessmentDetailSidebarBottom';
import RiskAssessmentLogs from './components/RiskAssessmentLogs';
import SendRiskAssessmentPdfDialog from './components/SendRiskAssessmentPdfDialog';
import DuplicateRiskAssessmentDialog from './components/DuplicateRiskAssessmentDialog';
import { projectRoutes } from '../../routes';
import { RiskAssessmentRouteParams } from '../RiskAssessment.route.type';
import { riskAssessmentRoutes } from '../riskAssessmentRoutes';
import { RiskAssessmentSignatureType } from '../../../globalTypes';
import { RiskAssessment } from '../graphql/__generated__/RiskAssessment.fragment';
import { RiskAssessmentProject } from '../graphql/__generated__/RiskAssessmentProject.fragment';
import { createRiskAssessmentLocation, hasSignatureInType } from '../RiskAssessment.utils';
import { RiskAssessmentScopes } from './types';

interface RiskAssessmentDetailProps {
  riskAssessment: RiskAssessment;
  project: RiskAssessmentProject;
  scopes: RiskAssessmentScopes;
}

export default function RiskAssessmentDetail({
  riskAssessment,
  project,
  scopes,
}: RiskAssessmentDetailProps): JSX.Element {
  const { riskAssessmentId } = useParams<RiskAssessmentRouteParams>();
  const location = useLocation();
  const navigate = useNavigate();

  const [showDuplicationDialog, setShowDuplicationDialog] = useState(false);
  const [showHasNotSignedDialog, setShowNotSignedDialog] = useState(false);

  const { pdfPath, signatures, viewerCanUpdate } = riskAssessment;
  const downloadPermitted = scopes.downloadRiskAssessmentDocument.isPermitted;

  const { parentFolders } = useDriveDocumentParentFolders(riskAssessment.id);

  function handleDownloadButtonClick() {
    if (!riskAssessment.pdfPath) {
      return;
    }

    window.open(riskAssessment.pdfPath, 'blank');
  }

  function handleDuplicationSuccess(duplicatedRiskAssessmentId: string) {
    setShowDuplicationDialog(false);

    navigate(
      riskAssessmentRoutes.detail.generatePath({
        riskAssessmentId: duplicatedRiskAssessmentId,
      }),
    );
  }

  function handleRemoveSuccess() {
    const removeSearchParams = new URLSearchParams({ removedDocumentName: riskAssessment.name });

    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId: project.id,
        }),
        search: removeSearchParams.toString(),
      }),
    );
  }

  function handleEditButtonClick() {
    if (!viewerCanUpdate) {
      setShowDuplicationDialog(true);
      return;
    }

    navigate(riskAssessmentRoutes.edit.generatePath({ riskAssessmentId }), {
      state: {
        backgroundLocation: location,
      },
    });
  }

  function handleSendButtonClick() {
    const hasManagerSignature = hasSignatureInType(signatures, RiskAssessmentSignatureType.MANAGER);

    if (!hasManagerSignature) {
      setShowNotSignedDialog(true);
      return;
    }

    navigate(riskAssessmentRoutes.sendEmail.generatePath({ riskAssessmentId }), {
      state: createRiskAssessmentLocation(location),
    });
  }

  function handleSendPdfWithSignature() {
    setShowNotSignedDialog(false);

    navigate(riskAssessmentRoutes.sendEmail.generatePath({ riskAssessmentId }), {
      state: createRiskAssessmentLocation(location),
    });
  }

  function handleSignBeforeSend() {
    setShowNotSignedDialog(false);

    navigate(riskAssessmentRoutes.sign.generatePath({ riskAssessmentId }), {
      state: createRiskAssessmentLocation(location),
    });
  }

  return (
    <PageLayoutWithStickySidebar
      header={
        <RiskAssessmentDetailHeader
          project={project}
          name={riskAssessment.name}
          status={riskAssessment.status}
          parentFolders={parentFolders}
        />
      }
      sidebarTop={
        <RiskAssessmentDetailSidebarTop
          riskAssessment={riskAssessment}
          scopes={scopes}
          onEditButtonClick={handleEditButtonClick}
          onDownloadButtonClick={handleDownloadButtonClick}
          onSendButtonClick={handleSendButtonClick}
        />
      }
      sidebarBottom={
        <RiskAssessmentDetailSidebarBottom
          riskAssessment={riskAssessment}
          project={project}
          scopes={scopes}
          onRemoveSuccess={handleRemoveSuccess}
        />
      }
      footer={
        <FlexGrid>
          <FlexGridColumn sm={8}>
            <RiskAssessmentLogs riskAssessmentId={riskAssessment.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <RiskAssessmentDetailAlerts name={riskAssessment.name} />

        {pdfPath && (
          <PDFViewer document={pdfPath} downloadPermitted={downloadPermitted} key={pdfPath} />
        )}
      </Stack>

      <SendRiskAssessmentPdfDialog
        open={showHasNotSignedDialog}
        onSendWithoutSignatureClick={handleSendPdfWithSignature}
        onSignNowClick={handleSignBeforeSend}
        onRequestClose={() => setShowNotSignedDialog(false)}
      />

      <DuplicateRiskAssessmentDialog
        open={showDuplicationDialog}
        riskAssessmentId={riskAssessment.id}
        riskAssessmentNo={riskAssessment.no}
        onRequestClose={() => setShowDuplicationDialog(false)}
        onSubmitSuccess={handleDuplicationSuccess}
      />
    </PageLayoutWithStickySidebar>
  );
}
