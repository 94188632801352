import { useCallback, useEffect, useMemo, useState } from 'react';
import { fabric } from '@hs-baumappe/fabric';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  areStylesAppliedToCharacter,
  areStylesAppliedToSelection,
  hasSelection,
  updateSelectionStyles,
} from '../../utils';

export enum FontDecoration {
  UNDERLINE = 'underline',
  NONE = 'none',
}

export interface UseTextBoxDecoration {
  isUnderline: boolean;
  setFontDecoration: (fontDecoration: FontDecoration) => void;
}

interface UseTextBoxDecorationParameters {
  textBox: fabric.Textbox | undefined;
  onRequestRenderCanvas: () => void;
}

const UNDERLINE_SHORTCUT_KEY = 'u';

function useTextBoxFontDecoration({
  textBox,
  onRequestRenderCanvas,
}: UseTextBoxDecorationParameters): UseTextBoxDecoration {
  const [blockUnderline, setBlockUnderline] = useState(false);
  const [selectionUnderline, setSelectionUnderline] = useState(false);
  const [typingUnderline, setTypingUnderline] = useState(false);
  const isUnderline = useMemo(
    () => typingUnderline || selectionUnderline || blockUnderline,
    [typingUnderline, selectionUnderline, blockUnderline],
  );

  useEffect(() => {
    setBlockUnderline(textBox?.underline === true);
    setSelectionUnderline(false);
    setTypingUnderline(false);
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxSelectionChanged = () => {
      if (hasSelection(textBox) && areStylesAppliedToSelection(textBox, 'underline', true)) {
        setSelectionUnderline(true);
      } else if (areStylesAppliedToCharacter(textBox, 'underline', true)) {
        setSelectionUnderline(true);
      } else {
        setSelectionUnderline(false);
      }
    };

    textBox.on('selection:changed', handleTextBoxSelectionChanged);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('selection:changed', handleTextBoxSelectionChanged);
    };
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxEditingExited = () => {
      setTypingUnderline(false);
    };

    textBox.on('editing:exited', handleTextBoxEditingExited);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('editing:exited', handleTextBoxEditingExited);
    };
  }, [textBox]);

  useEffect(() => {
    if (!textBox) {
      return;
    }

    const handleTextBoxChanged = () => {
      const { selectionEnd } = textBox;

      if (selectionEnd === undefined) {
        return;
      }

      textBox.setSelectionStyles(
        {
          underline: typingUnderline ? true : undefined,
        },
        selectionEnd - 1,
        selectionEnd,
      );
    };

    textBox.on('changed', handleTextBoxChanged);

    // eslint-disable-next-line consistent-return
    return () => {
      textBox.off('changed', handleTextBoxChanged);
    };
  }, [textBox, typingUnderline]);

  const setSelectionFontDecoration = useCallback(
    (fontDecoration: FontDecoration) => {
      if (!textBox) {
        return;
      }

      textBox.setSelectionStyles({
        underline: fontDecoration === FontDecoration.NONE ? undefined : true,
      });

      setSelectionUnderline(fontDecoration === FontDecoration.UNDERLINE);
    },
    [textBox],
  );

  const setBlockFontDecoration = useCallback(
    (fontDecoration: FontDecoration) => {
      if (!textBox) {
        return;
      }

      // Please follow up useTextBoxFontWeight:125
      updateSelectionStyles(textBox, 'underline', undefined);

      textBox.set({
        underline: fontDecoration === FontDecoration.UNDERLINE,
      });

      setBlockUnderline(fontDecoration === FontDecoration.UNDERLINE);
    },
    [textBox],
  );

  const setTypingFontDecoration = useCallback(
    (fontDecoration: FontDecoration) => {
      if (!textBox) {
        return;
      }

      setTypingUnderline(fontDecoration === FontDecoration.UNDERLINE);
    },
    [textBox],
  );

  const setFontDecoration = useCallback(
    (fontDecoration: FontDecoration) => {
      if (!textBox) {
        return;
      }

      if (textBox.isEditing && hasSelection(textBox)) {
        setSelectionFontDecoration(fontDecoration);
      } else {
        setTypingFontDecoration(fontDecoration);
      }

      if (!textBox.isEditing) {
        setBlockFontDecoration(fontDecoration);
      }

      onRequestRenderCanvas();
    },
    [
      onRequestRenderCanvas,
      setBlockFontDecoration,
      setSelectionFontDecoration,
      setTypingFontDecoration,
      textBox,
    ],
  );

  const handleFontDecorationKeyboardShortcut = useCallback(() => {
    if (isUnderline) {
      setFontDecoration(FontDecoration.NONE);
    } else {
      setFontDecoration(FontDecoration.UNDERLINE);
    }
  }, [isUnderline, setFontDecoration]);

  useHotkeys(
    `ctrl+${UNDERLINE_SHORTCUT_KEY}, command+${UNDERLINE_SHORTCUT_KEY}`,
    (event) => {
      event.preventDefault();

      handleFontDecorationKeyboardShortcut();
    },
    [handleFontDecorationKeyboardShortcut],
  );

  useEffect(() => {
    if (!textBox) {
      return;
    }

    /*
     * See useTextBoxFontWeight:238
     */
    const handleDocumentKeyDownEvent = (event: KeyboardEvent) => {
      const { target, ctrlKey, metaKey, key } = event;

      if (
        !target ||
        !(target instanceof Node) ||
        !textBox.hiddenTextarea ||
        !textBox.hiddenTextarea.isSameNode(target)
      ) {
        return;
      }

      if ((ctrlKey || metaKey) && key === UNDERLINE_SHORTCUT_KEY) {
        handleFontDecorationKeyboardShortcut();
      }
    };

    document.addEventListener('keydown', handleDocumentKeyDownEvent);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDownEvent);
    };
  }, [textBox, handleFontDecorationKeyboardShortcut]);

  return {
    isUnderline,
    setFontDecoration,
  };
}

export default useTextBoxFontDecoration;
