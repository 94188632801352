import { AcceptanceReportStatus } from '../../../../../globalTypes';

export function getStatusColor(status: AcceptanceReportStatus): 'muted' | 'success' {
  switch (status) {
    case AcceptanceReportStatus.OPEN:
    case AcceptanceReportStatus.SENT:
    case AcceptanceReportStatus.NOT_AGREED:
      return 'muted';
    default:
      return 'success';
  }
}
