import { FC } from 'react';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { gql, useMutation } from '@apollo/client';
import { differenceInCalendarDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DocumentDownloadQsstForExternalFragment } from './__generated__/DocumentDownload';
import { qsstDocumentDownloadMutation } from './graphql/QsstDocumentDownloadMutation';
import {
  QsstLogDownload,
  QsstLogDownloadVariables,
} from './graphql/__generated__/QsstDocumentDownloadMutation';

export const documentDownloadFragments = {
  qsstForExternal: gql`
    fragment DocumentDownloadQsstForExternalFragment on QsstForExternal {
      id

      pdfInfo {
        url
      }

      accessDueDate
    }
  `,
};

interface DocumentDownloadProps {
  qsstForExternal: DocumentDownloadQsstForExternalFragment;
  externalAuthToken: string;
}

export const DocumentDownload: FC<DocumentDownloadProps> = ({
  qsstForExternal,
  externalAuthToken,
}) => {
  const { t } = useTranslation();

  const [logDownload] = useMutation<QsstLogDownload, QsstLogDownloadVariables>(
    qsstDocumentDownloadMutation,
  );

  const { accessDueDate } = qsstForExternal;

  const remainingDays = differenceInCalendarDays(new Date(accessDueDate), new Date());

  return (
    <div className="u-margin-top-xlarge">
      <div className="u-text-align-center">
        <Text className="u-margin-bottom" component="h2" variant="title-large" color="black">
          {t('qsstSign.customerDocumentDownload.title')}
        </Text>
        <Text component="p" variant="body-medium" color="black">
          {t('qsstSign.customerDocumentDownload.content1', {
            days: remainingDays,
          })}
        </Text>
        <Text className="u-margin-top-2xsmall" component="p" variant="label-small" color="muted">
          {t('qsstSign.customerDocumentDownload.content2')}
        </Text>
      </div>

      <div className="row u-justify-content-center u-margin-top">
        <div className="col col--sm-8 col--lg-7">
          <Button
            fullWidth
            endIcon={<Icon name="cloud-download" />}
            onClick={async () => {
              const response = await logDownload({
                variables: {
                  input: {
                    id: qsstForExternal.id,
                    externalAuthToken,
                  },
                },
              });

              if (!response.data?.qsstLogDownload) return;

              window.open(qsstForExternal.pdfInfo?.url || '', 'blank');
            }}
          >
            {t('qsstSign.customerDocumentDownload.downloadButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};
