import { RefObject } from 'react';
import { RichTextEditorInstance } from '@hs-baumappe/rich-text-editor';
import { useMutation } from '@apollo/client';
import { Icon, IconButton, useSnackbar } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import EmailFormImageUploadMutation from './graphql/EmailFormImageUploadMutation';
import { areMimeTypesMatching, MIME_TYPES } from '../../../../utils/mimeTypes';
import { concat } from '../../../../utils/array';

type ImageUploadHandler = (file: File) => Promise<void>;

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const ACCEPTED_FILE_EXTENSIONS = concat([MIME_TYPES.JPG, MIME_TYPES.PNG]);
const EDITOR_INITIAL_IMAGE_WITH = '250px';

export default function useEmailFormEditorUploadImage(
  editorRef: RefObject<RichTextEditorInstance>,
): ImageUploadHandler {
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const [upload] = useMutation(EmailFormImageUploadMutation);

  function embedImage(imageUrl: string) {
    if (!editorRef.current) return;

    const editor = editorRef.current.getEditor();

    const range = editor.getSelection();
    const rangeIndex = range ? range.index : 0;

    editor.insertEmbed(rangeIndex, 'image', imageUrl);
    editor.formatText(rangeIndex, rangeIndex + 1, 'width', EDITOR_INITIAL_IMAGE_WITH);
  }

  async function uploadImage(file: File) {
    if (!editorRef.current) return;

    if (!areMimeTypesMatching(ACCEPTED_FILE_EXTENSIONS, file.type)) {
      snackbar.open(t('emailForm.fields.content.editor.errors.unexpectedFileType'), {
        type: 'error',
        action: (id) => (
          <IconButton
            color="inherit"
            icon={<Icon name="close" />}
            onClick={() => snackbar.close(id)}
          />
        ),
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      snackbar.open(t('emailForm.fields.content.editor.errors.fileSize'), {
        type: 'error',
        action: (id) => (
          <IconButton
            color="inherit"
            icon={<Icon name="close" />}
            onClick={() => snackbar.close(id)}
          />
        ),
      });
      return;
    }

    try {
      const { data } = await upload({
        variables: {
          file,
        },
      });

      if (!data) return;

      embedImage(data.uploadEditorImage.path);
    } catch (error) {
      snackbar.open(t('emailForm.fields.content.editor.errors.generic'), {
        type: 'error',
        action: (id) => (
          <IconButton
            color="inherit"
            icon={<Icon name="close" />}
            onClick={() => snackbar.close(id)}
          />
        ),
      });
    }
  }

  return uploadImage;
}
